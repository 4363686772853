/**
 * Original filename: "QuestionSetList.js"
 * QuestionSetList React Router Component List.
 * Part of the  application.
 *
 * First Create: 2017/12/27 2:18 PM
 * Added By: user
 **/
import React, {Component} from 'react';
import PropTypes from 'react-proptypes';
import GraphQLTable from '../generic/GraphQLTable';
import { Grid, Card } from '../generic';
import { QuestionSetModel } from "./model";
import {Link} from "react-router-dom";

const config = {
    query: QuestionSetModel.gqlConfig.list,
    dataset: data => data.questionsets,
    fields: {
        description: {label: 'Description'},

    }
};


class QuestionSetList extends Component {

    constructor(props, context) {
        super(props, context);
    }


    render() {
        const {history} = this.props;
        return (
            <Card>
                <div className="content">
                    <Grid container spacing={1} justify="end">
                        <Grid item>
                            <Link className='btn btn-primary' to={'/questions/new'}>
                                    NEW QUESTION SET
                            </Link>
                        </Grid>
                    </Grid>

                    <GraphQLTable
                        config={config}
                        onRowPress={ question => history.push(`/questions/${question.id}`) }
                    />
                </div>
            </Card>
        );
    }

    static propTypes = {
        history: PropTypes.object
    }
}

export default QuestionSetList;
