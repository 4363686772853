
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
import { format } from 'date-fns'

// load a locale
Numeral.register('locale', 'za', {
  delimiters: {
    thousands: ' ',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  currency: {
    symbol: 'R'
  }
});

// switch between locales
Numeral.locale('za')


function printCurrency(amount, decimals = true) {
  return decimals ? Numeral(amount).format('$ 0,0[.]00') : Numeral(amount).format('$ 0,0')
}

const toDecimalPlaces = (value, decimals) => Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)

function printDate(date) {
  if (date && parseInt(date).toString() === date) {
    return format(parseFloat(date)).format("dd-LL-yyyy")
  }

  return date ? format(date).format('dd-LL-yyyy') : ""
}

function prettyText(text, join) {
  console.log('text', text)
  return text.split(/_| /).map(i => i[0] ? i[0].toUpperCase() + i.slice(1).toLowerCase() : '').join(join)
}

function capitalize(s) {
  if (typeof s !== 'string') return ''
  let cap = s.toLowerCase()
  return cap.charAt(0).toUpperCase() + cap.slice(1)
}

export {
  printCurrency,
  toDecimalPlaces,
  printDate,
  prettyText,
  capitalize
}