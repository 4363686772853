import React from 'react'
import Helmet from 'react-helmet'

const images = require('../assets/images')
const faviconForDomain = {
  'http://localhost:3000': images.r4_favicon,
  'https://admin.mymeerkat.co.za': images.meerkat_favicon,
  'https://admin.mymeerkat.com': images.meerkat_favicon,
  'https://admin.meerkat.co.za': images.r4_favicon,
  'https://admin.funisave.com': images.r4_favicon,
  'https://admin-test.mymeerkat.co.za': images.meerkat_favicon,
  'https://admin-test.mymeerkat.com': images.meerkat_favicon,
  'https://admin-test.meerkat.co.za': images.r4_favicon,
  'https://admin-test.funisave.com': images.r4_favicon
}

function browserTitle () {

  let url = window.location.origin
  let title = url.toUpperCase().indexOf('MEERKAT') > -1 ? 'Meerkat Admin' : 'R4 Admin'
  title = title + (url.toUpperCase().indexOf('TEST') > -1 || url.toUpperCase().indexOf('LOCALHOST') > -1 ? ' Test' : ' ')
  return title
  }


const Favicon = () => (
  <Helmet title={browserTitle()}

meta = {
  [
    { name: 'description', content: `${ window.location.origin.indexOf('meerkat')>-1 ? 'Administration for Meerkat Insurance by R4 Systems' : 'R4 Systems Administration' }` },
      { name: 'keywords', content: 'credit life, funeral, insurance' },
  ]}
  link={[
      { rel: 'shortcut icon', type: 'image/png', href: `${faviconForDomain[window.location.origin] || images.r4_favicon}` }
  ]}
  />
)

export default Favicon
