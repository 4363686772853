import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Select, Grid, TextInput, DisplayItem } from '../generic'
import ChangeCurrentBalance from './ChangeCurrentBalance'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 21,
    paddingBottom: 7
  },
  center: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    paddingBottom: 7
  },
  buttonRow: {
    flexGrow: 1,
    justifyContent: 'flex-end',
  }
}));

const CLI_PACKAGES = [
  { value: "NORMAL", label: "NORMAL" },
  { value: "REJECTED", label: "REJECTED" }
]

const AFRICAN_BANK_PACKAGES = [
  { value: "EXTRA", label: "EXTRA" },
  { value: "NORMAL", label: "NORMAL" },
  { value: "REJECTED", label: "REJECTED" }
]


const YALU_PACKAGES = [
  { value: "PLAN_A", label: "PLAN_A" },
  { value: "PLAN_B", label: "PLAN_B" },
  { value: "PLAN_C", label: "PLAN_C" },
  { value: "PLAN_D", label: "PLAN_D" },
  { value: "REJECTED", label: "REJECTED" }
]

const ACCOUNT_HOLDER_OPTIONS = [
  { value: "POLICY_HOLDER", label: "Policy Holder" },
  { value: "SECONDARY_APPLICANT", label: "Spouse" },
  { value: "JOINT", label: "Joint" },
]

const OBLIGATION_INSURANCE_STATUS = [
  { value: "PENDING", label: "PENDING" },
  { value: "COVERED", label: "COVERED" },
]

/* { value: "POLICY_LAPSED", label: "POLICY_LAPSED" },
  { value: "PAID_UP", label: "PAID_UP" },
  { value: "CLAIM_NOTIFIED", label: "CLAIM_NOTIFIED" },
  { value: "CLAIMED", label: "CLAIMED" },
  { value: "IN_WAITING_PERIOD", label: "IN_WAITING_PERIOD" } */


let ChangeLoan = ({ openMarket, creditProviderPolicy, loan, setLoan, changeBalance, changeReference, setShowModal, getQuote }) => {
  const classes = useStyles();
  const [loanChanged, setLoanChanged] = useState()
  const [showCurrentBalance, setShowCurrentBalance] = useState()
  const [changeLoan, setChangeLoan] = useState()
  const [changeObligation, setChangeObligation] = useState(loan.obligation)
  const [loanStatus, setLoanStatus] = useState()
  const [loanPackage, setLoanPackage] = useState()
  const [reference, setReference] = useState()
  const [currentBalance, setCurrentBalance] = useState()
  const [accountHolder, setAccountHolder] = useState()

  useEffect(() => {
    setLoanStatus(loan.status)
    setLoanPackage(loan.package)
    setReference(loan.obligation.reference)
    setCurrentBalance(loan.obligation.currentBalance)
    setChangeLoan(loan)
    setChangeObligation(loan.obligation)
    setAccountHolder(loan.obligationAccountHolder || "POLICY_HOLDER")
  }, [loan]);

  const onRefChange = (e) => {
    // console.log('onRefChange', e)
    setChangeObligation({ ...changeObligation, reference: e.target.value })
    setReference(e.target.value)
    setLoanChanged(true)
  }

  const onAccountHolderChange = (e) => {
    // console.log('onRefChange', e)
    setChangeLoan({ ...changeLoan, obligationAccountHolder: e.target.value })
    setAccountHolder(e.target.value)
    setLoanChanged(true)
  }

  const onStatusChange = (e) => {
    console.log('onStatusChange', e)
    setChangeLoan({ ...changeLoan, status: e.target.value })
    setLoanStatus(e.target.value)
    setLoanChanged(true)
  }

  const onPackageChange = (e) => {
    console.log('onPackageChange', e.target.value)
    setChangeLoan({ ...changeLoan, package: e.target.value, status: e.target.value == "REJECTED" ? "CANCELLED" : changeLoan.status === 'CANCELLED' ? "PENDING" : changeLoan.status })
    setLoanPackage(e.target.value)
    setLoanChanged(true)
  }

  const onSave = () => {
    // console.log('onSave', loanChanged)

    if (loanChanged) {
      const newRow = { ...changeLoan, obligation: changeObligation, currentBalance }

      if (loan != newRow) {
        if (reference != loan.obligation.reference) {
          changeReference(newRow, reference)
        }
        setLoan(newRow)
        getQuote(newRow, loanPackage, loanStatus)
      }
    }
    setShowModal(false)
  }


  return (
    <>
      {/* <Grid container className={classes.root} spacing={1}>
        <Grid item xs={3}>Creditor</Grid>
        {openMarket && <Grid item xs={2}>Status</Grid>}
        <Grid item xs={2}>Reference</Grid>
        <Grid item xs={2}>Package</Grid>
        {openMarket && <Grid xs={2}>Current Balance</Grid>}
      </Grid> */}

      <Grid container className={classes.root} spacing={1}>

        <DisplayItem variant="outlined" margin='normal' label='Creditor' value={loan.obligation.clientCreditor.description} xs={3} />

        {openMarket && <Grid item xs={2}>
          <Select
            label='Status'
            value={loanStatus}
            onChange={onStatusChange}
            options={OBLIGATION_INSURANCE_STATUS}
          />
        </Grid>}

        <Grid item xs={2}>
          <TextInput
            label='Reference'
            onChange={e => onRefChange}
            value={reference}
          />
        </Grid>

        {!creditProviderPolicy && <Grid item xs={2}>
          <Select
            label='Package'
            value={loanPackage}
            onChange={onPackageChange}
            options={openMarket ? YALU_PACKAGES : loan.obligation.clientCreditor.description && loan.obligation.clientCreditor.description.toUpperCase().indexOf('AFRICAN BANK') > -1 ? AFRICAN_BANK_PACKAGES : CLI_PACKAGES}
          />
        </Grid>}

        <Grid item xs={2}>
          <Select
            label='Account Holder'
            value={accountHolder || "POLICY_HOLDER"}
            onChange={onAccountHolderChange}
            options={ACCOUNT_HOLDER_OPTIONS}
          />
        </Grid>

        {(openMarket || creditProviderPolicy) && <>
          <DisplayItem variant="outlined" margin='normal' label='Current Balance' value={currentBalance} xs={3} style={{cursor:"pointer"}}  onClick={() => showCurrentBalance ? '' : setShowCurrentBalance(true)} />
            {showCurrentBalance && <ChangeCurrentBalance currentBalance={currentBalance} obligationId={loan.obligation.id} changeBalance={changeBalance} loan={loan} setCurrentBalance={setCurrentBalance} showCurrentBalance={showCurrentBalance} setShowCurrentBalance={setShowCurrentBalance} />}
        </>}
      </Grid>

      <Grid container className={classes.buttonRow} spacing={2}>
        <Grid item><Button color="secondary" onClick={() => { setShowModal(false) }}>Cancel</Button></Grid>
        <Grid item><Button color="primary" onClick={() => onSave()}>Save</Button></Grid>
      </Grid>
    </>
  )
}

export default ChangeLoan