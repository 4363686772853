import React from 'react'
import ClaimList from './ClaimList'
import Claim from './Claim'
import ClaimAdmin from './ClaimAdmin'
import ClaimBenefit from './ClaimBenefit' 

import { Route, Switch } from 'react-router-dom'

const Claims = () => {
  return (
  <div>
    <Route path='/claims' exact component={ClaimList} />
      <Switch>
        <Route path='/claims/newbenefit/:id/:effectiveDate/:productType/:claimType' component={ClaimBenefit} />
      <Route path='/claims/:id/:edit?' component={ClaimAdmin} />
      <Route path='/claims/start' component={Claim} />
    </Switch>
  </div>
  )
}

export default Claims
