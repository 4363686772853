import React, { useState } from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag'
import { v4 as uuidv4 } from 'uuid';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import { isValidCellphoneNumber } from '../../lib/validations';
import PhoneBluetoothSpeakerIcon from '@material-ui/icons/PhoneBluetoothSpeaker';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Modal } from '.'
import { isStaging } from '../../config'

const DarkerDisabledTextField = withStyles({
  root: {
    minWidth: 120,
    paddingBottom: "3px!important",
    paddingRight: "5px!important",
    "& .MuiInputBase-input": {
      fontSize: props => props.fontSize,
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.99)" // (default alpha is 0.38)
    },
    "& .MuiInputLabel-root": {
      fontSize: 20 // (default is 16)
    },
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: props => props.borderBottomStyle
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.75)!important"
    },
  }
})(TextField);


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const CALL_CLIENT = gql`
  mutation($input: CreateCommunicationInput!) {
    createCommunication(input: $input) {
      id
  }
  }`;

function MobileField(props) {
  const [message, setMessage] = useState()
  const [open, setOpen] = useState(false)
  const [snackOpen, setSnackOpen] = useState(false)

  const Component = props.disabled ? DarkerDisabledTextField : TextField

  const callClient = () => {
    setOpen(false)
    const input = { to: props.value, message, type: "PHONE", clientId: props.clientId }
    props.client
      .mutate({
        mutation: CALL_CLIENT,
        variables: {
          input
        },
      })
      .then(() =>
        setSnackOpen(true)
      )
  }

  let { value } = props;
  if (value && isStaging()) {
    value = '0736017306'
  }

  return (<>
    <Component
      {...props}
      id={uuidv4()}
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            {value && !isValidCellphoneNumber(value) && <PhoneBluetoothSpeakerIcon fontSize="small" style={{ cursor: 'pointer', fontSize: 14, marginRight: 3, marginLeft: -7 }} onClick={() => setOpen(true)} />}
          </InputAdornment>
        )
      }}
    />

    <Modal
      title={`Do you want to call ${value}?`}
      okText="Ok"
      cancelText="Back"
      wrapClassName="vertical-center-modal"
      open={open}
      onClose={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      onOk={callClient}
    >
      <TextField fullWidth variant="outlined" value={message} required label='Message to appear in 1Stream ' onChange={(e) => setMessage(e.target.value)} />
    </Modal>

    <Snackbar open={snackOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={() => setSnackOpen(false)}>
      <Alert onClose={() => setSnackOpen(false)} severity='success' >
        {`1Stream will call ${value} now`}
      </Alert>
    </Snackbar>
  </>
  );
}

export default withApollo(MobileField);
