import React from 'react'
import { LabelContainer } from './Label.style'
import Text from '../Text'

function Label({ title, color, textColor, inverted }) {
  return <LabelContainer color={color} textColor={textColor} inverted={inverted}>
    <Text bold size={12} color={textColor}>{title}</Text>
  </LabelContainer>
}

export default Label
