import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { ActivityIndicator, UploadFile } from './index'
import { trash, pdf } from '../../assets/images'
import styled from '@emotion/styled'

const SmallUploadedFileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row  ;
  flex-grow: 1;
  // border-top: 2px solid #F9F8F8;
  // border-bottom: 2px solid #F9F8F8;
  width:90px;
  height:35px;
  padding-left: 21px;
  padding-top:3px
`

const DeleteIcon = styled.img`
  margin: 0;
  position: absolute;
  right: 0;
  top: 3px;
  height:18px;
  cursor:pointer;

`
const DisplayIcon = styled.img`
  cursor:pointer;
  height:25px
`

const GET_FILE_INFO = gql`
query File ($id: String!) {
  file(id: $id) {
    id
    name
    url
    contentType
  }
}`

function UploadedFile(props) {
  const { id, docId, prompt, onChange, small, setVerify, verified, deleteAllowed = true, disabled = false } = props
  const { data, loading, error } = id ? useQuery(GET_FILE_INFO, { variables: { id } }) : {}
  const { file } = data && data || {}
  const { id: fileId, url, name, contentType } = file || {}
  const [open, setOpen] = useState(false)

  if (loading) {
    return <ActivityIndicator />
  }

  return <>
    <SmallUploadedFileContainer>
      <DisplayIcon src={contentType && contentType.toLowerCase().indexOf('pdf') > -1 ? pdf : url} onClick={() => setOpen(true)} />
      {deleteAllowed && !disabled && <DeleteIcon src={trash} onClick={() => onChange(null)} />}
    </SmallUploadedFileContainer>

    <UploadFile
      {...props}
      {...file}
      setOpen={setOpen}
      open={open}
      verified={verified}
      noButtons={disabled || !setVerify}
      setVerify={setVerify}
      clientInfo={props.clientData}
    />
  </>
}

export default UploadedFile
