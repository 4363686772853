import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    paddingBottom: 14,
  },
  table: {
    minWidth: 650,
    fontSize: 14,
  },
  head: {
    paddingTop: 7,
    paddingBottom: 7,
    fontWeight: 400,
  },
  cell: {
    paddingTop: 4,
    paddingBottom: 4,
  }
});

function Mandates({ mandates }) {
  const classes = useStyles();
  console.log('Mandates are ', mandates)
  if (!mandates) {
    return <h3>No previous mandates</h3>
  }

  return (<>
    <TableContainer size='small' component={Paper}>
      <Table className={classes.table} aria-label="lives table">
        <TableHead >
          <TableRow>
            <TableCell className={classes.head}>Mandate Type</TableCell>
            <TableCell className={classes.head}>Status</TableCell>
            <TableCell className={classes.head}>Authentication Type</TableCell>
            <TableCell className={classes.head}>Code</TableCell>
            <TableCell className={classes.head}>Description</TableCell>
            <TableCell className={classes.head}>Updated At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mandates.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1).map((row) => {
            <TableCell className={classes.cell}>{status}</TableCell>
            const { updatedAt, status, authenticationType, mandateType, code, description } = row
            return (
              <TableRow key={updatedAt} >
                <TableCell className={classes.cell}>{mandateType}</TableCell>
                <TableCell className={classes.cell}>{status}</TableCell>
                <TableCell className={classes.cell}>{authenticationType}</TableCell>
                <TableCell className={classes.cell}>{code}</TableCell>
                <TableCell className={classes.cell}>{description}</TableCell>
                <TableCell className={classes.cell}>{moment(updatedAt).format('DD-MM-YYYY HH:mm:ss')}</TableCell>
              </TableRow>
            )
          }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </>
  );
}

export default Mandates
