import styled from '@emotion/styled'

const toFlex = {
  start: "flex-start",
  end: "flex-end",
  center: "center"
}

const ColBreak = styled.div`
flex-basis: 100%;
width: 0;
`

const Col = styled.div`
display:flex;
${props => props.caps && `text-transform:uppercase;`};
${props => props.big &&  `font-family:"Roboto-Medium";`};
${props => props.heading && `text-transform:uppercase;font-family:"Roboto-Medium"` };
${props => props.size ? `flex: ${props.size}` : `flex: 1`};
${props => props.height && `height: ${props.height}`};
${props => props.centerHorizontal && `align-items: center`};
${props => props.centerVertical && `justify-content: center`};
${props => props.rightVertical && `justify-content: flex-end`};
${props => props.leftVertical && `justify-content: flex-start`};
${props => props.alignVertical && `flex-direction: column; align-items: ${toFlex[props.alignVertical]}`};
${props => props.alignHorizontal && `flex-direction: row;align-items: ${toFlex[props.alignHorizontal]}`};
${props => props.red && `font-family:"Roboto-Medium";color:white; background:red;justify-content:center` };
${props => props.direction && `flex-direction: ${props.direction}`};
${props => props.alignItems && `align-items: ${props.alignItems}`};
${props => props.justifyContent && `justify-content: ${props.justifyContent}`}
`

export {Col, ColBreak}
