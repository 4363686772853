import React, { useState, useRef } from "react";
import { Box, Typography, withStyles, Slider } from '@material-ui/core';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import "react-image-crop/dist/ReactCrop.css";
import { Button } from '..'
import { useDebounceEffect } from './useDebounceEffect'
import { canvasPreview } from './canvasPreview'
import { styles } from './styles'

const myBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const MySlider = withStyles({
  root: {
    color: '#3880ff',
    height: 1,
    padding: '7px 0',
    maxWidth: 200,
    fontSize: 16
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -14,
    marginLeft: -14,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: myBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);


// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 99,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}


function ImageCropper(props) {
  const { imageToCrop, onImageCropped, classes } = props;
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState('')
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState()
  const [height, setHeight] = useState()
  const [width, setWidth] = useState()

  function onImageLoad(e) {
    const { width, height } = e.currentTarget
    console.log('onImageLoad width height', width, height)
    if (!aspect) {
      if (height > width) {
        setAspect()
        setHeight(height)
        setWidth(height)
        setCrop(centerAspectCrop(height, height, 1 / 1))
      }
      else {
        setHeight(height > 600 ? 600 : height)
        setWidth(width > 900 ? 900 : width)
        setAspect(height / width)
        setCrop(centerAspectCrop(width, height, width / height))
      }
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else if (imgRef.current) {
      const { width, height } = imgRef.current
      setAspect(width / height)
      setCrop(centerAspectCrop(width, height, width / height))
    }
  }

  const getImage = (c) => {
    setCompletedCrop(c);
    if (previewCanvasRef.current) {
      console.log('previewCanvasRef.current', previewCanvasRef.current)
      onImageCropped(previewCanvasRef.current.toDataURL('image/jpeg'));

      previewCanvasRef.current.toBlob(
        (blob) => {
          const newImage = new File([blob], blob.name, { type: blob.type, });
          console.log('newImage', newImage)
          // onImageCropped(newImage);
        },
        'image/jpg',
        1
      )

      // const image = getCroppedImage(c)
      // if (image) {
      //   onImageCropped(image);
      // }
    }
  }

  const getCroppedImage = (crop) => {
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    // As Base64 string
    return canvas.toDataURL('image/jpeg');
  }

  return (<>
    <Box
      flexDirection="column"
      display="flex"
      justifyContent="center"
      alignItems="center"
      // maxHeight="85vh"
      // maxWidth="85vw"
      // minWidth="60vw"
    >
      <ReactCrop
        crop={crop}
        onChange={(_, percentCrop) => {
          setCrop(percentCrop);
        }}
        onComplete={getImage}
        aspect={aspect}
        style={{ minWidth: width, minHeight: height }}
      >
        <img
          ref={imgRef}
          alt="Crop me"
          src={imageToCrop}
          style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
          onLoad={onImageLoad}
          crossOrigin="anonymous"
          height={height}
        />
      </ReactCrop>

      <div className={classes.bottomControls}>
        <div className={classes.sliderContainer}>
          <Typography className={classes.sliderLabel}>Zoom</Typography>
          <MySlider
            disabled={!imageToCrop}
            value={scale}
            defaultValue={1}
            valueLabelDisplay="on"
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, scale) => setScale(Number(scale))}
          />
        </div>
        <div className={classes.sliderContainer}>
          <Typography className={classes.sliderLabel}>Rotate</Typography>
          <MySlider
            disabled={!imageToCrop}
            value={rotate}
            defaultValue={0}
            valueLabelDisplay="on"
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            onChange={(e, rotate) =>
              setRotate(Math.min(360, Math.max(-180, Number(rotate))))
            }
          />
        </div>
        <Button className={classes.cropButton} onClick={handleToggleAspectClick}>
          Aspect {aspect ? 'off' : 'on'}
        </Button>
      </div>

      {Boolean(completedCrop) &&
        <div>
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              minWidth: 200,
              width: (completedCrop.width * .5) > 300 ? 300 : (completedCrop.width * .5),
              height: (completedCrop.height * .5) > 200 ? 200 : (completedCrop.height * .5),
            }}
          />
        </div>}
    </Box>
  </>
  );
}

ImageCropper.defaultProps = {
  onImageCropped: () => { }
};

export default withStyles(styles)(ImageCropper);
