import React from 'react'
import styled from '@emotion/styled'
import CircularProgress from '@material-ui/core/CircularProgress'

const CircularProgressContainer = styled.div`
  .MuiCircularProgress-colorPrimary {
    color: ${props => props.white ? "white" : "#229490"} !important;

    ${props => props.small && css`
      width: 20px !important;
      height: 20px !important;
    `}
  }
`

function ActivityIndicator({ white, small }) {
  return <CircularProgressContainer white={white} small={small}><CircularProgress /></CircularProgressContainer>
}

export default ActivityIndicator
