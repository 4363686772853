import React from 'react'
import styled from '@emotion/styled'

const ContentContainer = styled.div`
  padding-left: 1rem;
  margin: 0 auto;
  min-height: 92vh;
`

export {
  ContentContainer
}