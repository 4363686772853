import React from "react";
import TextInput from "../TextInput";
import MenuItem from '@material-ui/core/MenuItem'
import { StyledMenuItem, StyledMenuItemText } from "./Select.style";

const getLabelValue = (option) => {
  if (option instanceof Object) {
    return { label: option.label || option.value, caption: option.caption, value: option.value };
  }
  return { label: option, value: option };
};

function Select({ label, value, options, ...props }) {
  return (
    <TextInput
      label={label}
      value={value}
      select
      fullWidth
      {...props}
      SelectProps={{
        renderValue: (value) => {
          const option = options.find((o) => o.value === value);
          if (option) {
            return option.label;
          }
          return value
        },
      }}
    >
      {options && options.length > 0
        ? options.map((option, i) => {
          const { label, value, caption } = getLabelValue(option);
          return (
            <MenuItem value={value}>
              {label}
            </MenuItem>
          )
        })
          : <MenuItem disabled={true} value={'No options'}>{'no options'}</MenuItem>
        }
    </TextInput>
  );
}

export default Select;

// <StyledMenuItem key={i} value={value}>
//   <StyledMenuItemText primary={label} secondary={caption} />
// </StyledMenuItem>