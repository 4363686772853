import React from 'react';
import gql from 'graphql-tag';
import GraphQLTable from '../generic/GraphQLTable';
import {Card, Button, Grid} from '../generic'
import moment from 'moment';
import 'font-awesome/css/font-awesome.css';

const config = {
  name: 'salesteams',
  query: gql`
    {
      salesteams(limit: 100) {
        id
        name
        managerOfTeam {
          first
          last
        }
        members {
          first
          last
        }
      }
    }
  `,
  dataset: data => data.salesteams,
  fields: {
    name: {
      label: 'Team Name',
    },
    managerOfTeam: {
      label: 'Sales Manager',
      Cell: row => (row.value ? `${row.value.last}, ${row.value.first} ` : '-')
    },
    members: {
      label: 'Number of Members',
      Cell: row => row.value.length || 0
    }
  },
  defaultSorted: [{ id: 'name'}]
};

const SalesTeamList = ({ history }) => (

  <Card> 
    <h2>Sales Teams</h2>
    <Grid container justifyContent={'flex-end'} spacing={1}>
      <Grid xs={1}>
        <Button color="primary" onClick={() => history.push('/sales-teams/new')}>New Team</Button>
      </Grid>
    </Grid>
  <GraphQLTable config={config} onRowPress={row => history.push(`/sales-teams/${row.id}`)} />
</Card>

);

export default SalesTeamList;
