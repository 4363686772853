import React, { useState } from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import Visibility from '@material-ui/icons/Visibility';
import moment from 'moment';
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
import { Grid, Button } from '../generic';
import { ClientDocument } from '../clientDocuments';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    paddingBottom: 14,
  },
  table: {
    minWidth: 650,
    fontSize: 14,
  },
  head: {
    paddingTop: 7,
    paddingBottom: 7,
    fontWeight: 400,
  },
  cell: {
    paddingTop: 4,
    paddingBottom: 4,
  }
});


const UPDATE_OBLIGATION_CLEARANCE_CERTIFICATE = gql`
mutation ($clearanceCertificateId: String!, $input: ObligationClearanceCertificateInput!, $accessCode: String) {
  addOrUpdateObligationClearanceCertificate(clearanceCertificateId:$clearanceCertificateId, input:$input, accessCode:$accessCode) {
    id
    forObligations {
      id
      status
      statusAt
      obligation {
        settlementLetters {
          id
          verified
          fileId
          file {
            id
            url
            contentType
          }
        }
      }
    }
  }
}`

const Prescribed = ({ id, clearanceCertificateId, obligationId, status, client }) => {
  const [open, setOpen] = useState(false)

  const updateObligation = (status) => {
    client
      .mutate({
        mutation: UPDATE_OBLIGATION_CLEARANCE_CERTIFICATE,
        variables: {
          clearanceCertificateId,
          input: {
            id,
            obligationId,
            status,
          }
        },
      })
  }

  return (<>
    <Button size="small" onClick={() => setOpen(true)}>Prescribe</Button>
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Prescribed</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Was the prescription Accepted or Rejected?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {status === 'PRESCRIBED' ?
          <>
            <Button onClick={() => {
              updateObligation('PRESCRIPTION_REJECTED')
              setOpen(false)
            }
            } color="secondary" >
              Rejected
            </Button>
            <Button onClick={() => {
              updateObligation('PRESCRIPTION_ACCEPTED')
              setOpen(false)
            }
            } color="primary" autoFocus>
              Accepted
            </Button>
          </>
          : <>
            <Button onClick={() => {
              updateObligation('PRESCRIPTION_NOTIFIED')
              setOpen(false)
            }
            } color="primary" autoFocus>
              Accepted
            </Button>
          </>}
        {/* <Button onClick={() => setOpen(false)} >
          Cancel
        </Button> */}
      </DialogActions>
    </Dialog>
  </>)
}

const Letters = ({ id, clearanceCertificateId, obligationId, status, settlementLetters, uploadAllowed, client }) => {

  const obligationClearanceCertificate = (fileId) => {
    client
      .mutate({
        mutation: UPDATE_OBLIGATION_CLEARANCE_CERTIFICATE,
        variables: {
          clearanceCertificateId,
          input: {
            id,
            obligationId,
            status,
            proofDocumentIds: [fileId]
          }
        },
      })
  }

  return settlementLetters && settlementLetters.length > 0
    ? settlementLetters.map((f) => {
      return (<Grid container
        direction="row" spacing={1}
        justifyContent="flex-start"
        alignItems="center"
      >
        <ClientDocument imageProp={f && f.file ? f.file.url : undefined} fileId={f && f.fileId || undefined} fileType={f && f.file && f.file.contentType || undefined} clientDocumentId={f && f.id || undefined} setFileId={obligationClearanceCertificate} uploadAllowed={uploadAllowed} />
        {f && f.verified ? <CheckCircle style={{ color: "green" }} /> : f && f.verified === false ? <Cancel style={{ color: "red" }} /> : <Visibility />}
      </Grid>)
    })
    : uploadAllowed ? <ClientDocument imageProp={undefined} fileId={undefined} clientDocumentId={undefined} setFileId={obligationClearanceCertificate} uploadAllowed={uploadAllowed} /> :
      <div></div>
}

function Obligations({ clearanceCertificateId, state, forObligations, clientInfo, client, onStatusChange }) {
  const classes = useStyles();

  const [updateStatus, setUpdateStatus] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, id) => {
    setUpdateStatus(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    console.log("CLOSING ...")
    setUpdateStatus(null);
    setAnchorEl(null);
  };

  console.log(updateStatus)

  if (forObligations && forObligations.length > 0) {

    return (<>
      <h2>Loans</h2>

      <TableContainer size='small' component={Paper}>
        <Table className={classes.table} aria-label="obligations table">
          <TableHead >
            <TableRow>
              <TableCell className={classes.head}>Description</TableCell>
              <TableCell className={classes.head}>Reference</TableCell>
              <TableCell className={classes.head}>Original Balance</TableCell>
              <TableCell className={classes.head}>Current Balance</TableCell>
              <TableCell className={classes.head}>Status Reason</TableCell>
              <TableCell className={classes.head}>Status At</TableCell>
              <TableCell className={classes.head}>Status</TableCell>
              <TableCell className={classes.head}>Settlement Letter</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {forObligations.sort((a, b) => (a.statusAt < b.statusAt) ? 1 : -1).map((row) => {
              <TableCell className={classes.cell}>{status}</TableCell>
              const { obligation, obligationId, id, statusAt, status, statusReason } = row
              const { clientCreditor, reference, currentBalance, originalBalance, settlementLetters } = obligation || {}
              const { description } = clientCreditor || {}

              const uploadAllowed = ["PAID_UP_CLIENT_PROVIDE_PROOF", "PAID_UP_PROOF_REQUESTED"].indexOf(status) > -1

              return (
                <TableRow key={id}>
                  <TableCell className={classes.cell}>{description}</TableCell>
                  <TableCell className={classes.cell}>{reference}</TableCell>
                  <TableCell className={classes.cell}>{Numeral(originalBalance).format('$ ##,###0')}</TableCell>
                  <TableCell className={classes.cell}>{Numeral(currentBalance).format('$ ##,###0')}</TableCell>
                  <TableCell className={classes.cell}>{statusReason}</TableCell>
                  <TableCell className={classes.cell}>{moment(statusAt).format('DD-MM-YYYY HH:mm:ss')}</TableCell>
                  <TableCell className={classes.cell}>
                    {["ACTIVE", "PAID_UP_CLIENT_PROVIDE_PROOF", "PAID_UP_CLIENT_PROOF_PROVIDED", "PAID_UP_REQUEST_PROOF", "PAID_UP_PROOF_REQUESTED", "EXCLUDED"].includes(status) ? <a onClick={(e) => handleClick(e, id)}>{status}</a> : status}
                    <Popover open={updateStatus === id} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center', }}>
                      <List>
                        <ListItem onClick={() => { handleClose(); onStatusChange(id, "ACTIVE"); }}>
                          <ListItemText>Not Paid Up</ListItemText>
                        </ListItem>
                        <ListItem onClick={() => { handleClose(); onStatusChange(id, "PAID_UP_CLIENT_PROVIDE_PROOF"); }} >
                          <ListItemText>Paid Up - Customer Provide Letter</ListItemText>
                        </ListItem>
                        <ListItem onClick={() => {  handleClose(); onStatusChange(id, "PAID_UP_REQUEST_PROOF"); }}>
                          <ListItemText>Paid Up - Meerkat Get Letter</ListItemText>
                        </ListItem>
                        <ListItem onClick={() => {  handleClose(); onStatusChange(id, "PAID_UP_PROOF_REQUESTED"); }}>
                          <ListItemText>Paid Up - Meerkat Obtained Letter</ListItemText>
                        </ListItem>
                        <ListItem onClick={() => {  handleClose(); onStatusChange(id, "EXCLUDED"); }}>
                          <ListItemText>Excluded</ListItemText>
                        </ListItem>
                        <ListItem onClick={() => {  handleClose(); onStatusChange(id, "PRESCRIBED"); }}>
                          <ListItemText>Prescribed</ListItemText>
                        </ListItem>
                      </List>
                    </Popover>
                  </TableCell>
                  <TableCell className={classes.cell}><Letters id={id} obligationId={obligationId} status={status} clearanceCertificateId={clearanceCertificateId} settlementLetters={settlementLetters} client={client} clientInfo={clientInfo} uploadAllowed={uploadAllowed} /></TableCell>
                  <TableCell className={classes.cell}>{(status === "PRESCRIPTION_NOTIFIED" || status === "PRESCRIBED") ? <Prescribed id={id} obligationId={obligationId} status={status} clearanceCertificateId={clearanceCertificateId} client={client} /> : " "}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>)
  }
  else {
    return (<h2>No active loans</h2>)
  }
}

export default withApollo(Obligations)
