export const styles = (theme) => ({
  root: {
    width: 100,
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2%',
    background: '#EBE6EC'
  },
  dialog: {
    padding: '2em',
    background: '#EBE6EC'
  },
  paper: {
    marginBottom: 14,
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  button: {
    width: '100%',
    fontSize: 11,
    background: '#BFBEC2',
  },
  approvalButton: {
    width: '100%',
    fontSize: 11,
  },
  margin: {
    height: theme.spacing(3),
  },
  mainGrid: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2em',
    background: '#EBE6EC'
  },
  leftColumn: {
    marginTop: '-30%',
  },
  rightColumn: {
    marginTop: '-50%',
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 400,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 800,
    },
  },

  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
    minWidth: 75
  },
  topControls: {
    padding: 16,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  bottomControls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    flexShrink: 0,
    marginLeft: 16,
    minWidth: 250
  },
  sliderLabel: {
    minWidth: 50,
    paddingRight: '1em',
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
    image: {
      height: '100%',
    },
  },
  image: {
    alignItems: 'center',
    spacing: 0,
    direction: "column",
    justifyContent: "center",
  },
  container: {
    position: 'relative',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'row',
    minWidth: 90,
    marginBottom: 2,
  },
  fileContainer: {
    padding: 4,
    border: '1px solid #0000003b',
    textAlign: 'center',
    borderRadius: 7,
    cursor: 'pointer',
    minWidth: 50
  },
  valid: {
    fontSize: '30px',
    textShadow: '1px 1px #FF0000',
    color: 'green',
    position: 'absolute',
    top: '0',
    left: '51%',
    // transform: 'rotate(-45deg)'
  },
  inValid: {
    fontSize: '100px',
    textShadow: '1px 1px #FF0000',
    color: 'red',
    position: 'absolute',
    top: '0%',
    left: '51%',
    // transform: 'rotate(45deg)',
  }
})
