import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphql, withApollo } from 'react-apollo'

import {
  Card,
  DatePicker,
	Button
} from '../generic';

const debtCounsellorQuery = gql`
  query DebtCounsellor($id: String!){
  debtcounsellor(id: $id) {
    fullName
  }
 }`

const emailValid = value => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
const simplicityValid = value => value.match(/^(\d{3}-\d{4})$/i);
const phoneValid = value => value.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm);
const isNumeric = value => value.match(/^d{10}$/gm);

const RangePicker = DatePicker.RangePicker;

const newFee = gql `
mutation UpdateDebtCounsellor($input: UpdateDebtCounsellorInput!, $id: String!) {
  updateDebtCounsellor(id: $id, input: $input) {
      id
      fees {
        feeType
        startDate
        endDate
        fixedFee
        percentageFee
        minimumValue
        maximumValue
      }
  }
}
`

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}


const feeTypes = [
	{
		value: 'BINDER_FEE',
		label: 'Binder Fee'
	},
	{
		value: 'COMMISSION',
		label: 'Commission'
  },
	{
		value: 'LICENSE',
		label: 'License'
  },
	{
		value: 'BASE_LEADS',
		label: 'Base Lead'
  },
	{
		value: 'SPECIAL_LEADS',
		label: 'Special Lead'
  }

];

class NewLicenseFee extends Component {
  state = {
    confirmDirty: false
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }


      // Should format date value before submit.
      const values = {
        ...fieldsValue,
        'startDate': fieldsValue['startDate'].format('DD-MM-YYYY'),
        'endDate': fieldsValue['endDate'].format('DD-MM-YYYY')
      };
      console.log('Received values of form: ', values);

      this.props.client
        .mutate({
          mutation: newFee,
          variables: {
            id: this.props.id,
            input: {
                'fees.feeType': fieldsValue['feeType'],
                'fees.startDate': fieldsValue['startDate'],
                'fees.endDate': fieldsValue['endDate'],
                'fees.fixedFee': fieldsValue['fixedFee'],
                'fees.percentageFee': fieldsValue['percentageFee'],
                'fees.minimumValue': fieldsValue['minimumValue'],
                'fees.mmaximumValue': fieldsValue['maximumValue']
              }
            }
        })
        .then(() => this.props.history.push(`/debt-counsellors/${this.props.id}/fees`))
       })

    };

	handleConfirmBlur = e => {
		const value = e.target.value;
		this.setState({ confirmDirty: this.state.confirmDirty || !!value });
	};


  validatePhone = (rule, value, callback) => {
    const form = this.props.form;
    if (value && !phoneValid(value)) {
      callback("Number doesn't look like a phone number!");
    } else {
      callback();
    }
  }

  validatePercentageFee = (rule, value, callback) => {
    const form = this.props.form;
    console.log('form fixedFee is ', form.getFieldValue('fixedFee'))
    console.log('form percentageFee is ', form.getFieldValue('percentageFee'))

		if (!value && (form.getFieldValue('percentageFee') == null)) {
			callback('Either a Fixed Fee or Commission is required');
		} else {
			callback();
		}
	};

	validateFixedFee = (rule, value, callback) => {
    const form = this.props.form;
    console.log('form Fixed Fee is ', form.getFieldValue('fixedFee'))
    console.log('form percentage Fee is ', form.getFieldValue('percentageFee'))

    if (!value && (form.getFieldValue('percentageFee') == null)) {
			callback('Either a Fixed Fee or Commission is required');
		} else {
			callback();
		}

  };

  render() {

		const { getFieldDecorator, getFieldsError, getFieldError } = this.props.form;
    console.log('props are ', this.props)
    const { debtcounsellor } = this.props.data;

    const datePicker = {
      rules: [{ type: 'array', required: true, message: 'Please select date!' }],
    };


    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
    };

    const buttonItemLayout = {
      wrapperCol: { span: 14, offset: 4 },
    }

		return (
			<Card>
        <h1>Debt Counsellor: {debtcounsellor.fullName}</h1>
				<br />

        <Form onFinish={this.handleSubmit}>

          <FormItem label="Fee Type"
            {...formItemLayout}
          >
                {getFieldDecorator('feeType', {
                  rules: [{ required: true, message: 'Please select the fee type!' }]
                })(<Cascader options={feeTypes} />)}
              </FormItem>

              <FormItem label='* Start'
              {...formItemLayout}
              >
              <Col span={9}>
              <FormItem >
              {getFieldDecorator('startDate', {
                  rules: [{
                    required: true,
                    message: 'Please select a start date!'
              }]
              })(<DatePicker />)}

              </FormItem>
            </Col>
            <Col span={2}>
              <span style={{ display: 'inline-block', width: '100%', textAlign: 'center', fontWeight:'bold' }}>
                * End:
              </span>
            </Col>
            <Col span={9}>
              <FormItem>
              {getFieldDecorator('endDate', {
                  rules: [{
                    required: true,
                    message: 'Please select an end date!'
                  }]
                })( <DatePicker / > )
              }
              </FormItem>
            </Col>
            </FormItem>

              <FormItem label="Fixed Fee" {...formItemLayout}>
              {getFieldDecorator('fixedFee', {
              rules: [{
                validator: this.validateFixedFee,
                initialValue: 0
              }]
              })(<input min={0} />)}
              </FormItem>

              <FormItem label="Commission" {...formItemLayout}>
              {getFieldDecorator('percentageFee', {
              rules: [{
                validator: this.validatePercentageFee,
                initialValue: 0
              }]
              })(<input min={0} onBlur={this.handleConfirmBlur} />)}
              </FormItem>

          <FormItem label="Minimum value" {...formItemLayout}>
              {getFieldDecorator('minimumValue', {
              rules: [{
                type: 'number', type: 'number',message: 'Please enter a number',
                initialValue: 0
              }]
              })(<input min={0} />)}
          </FormItem>

          <FormItem label="Maximum value" {...formItemLayout}>
              {getFieldDecorator('maximumValue', {
              rules: [{
                type: 'number',message: 'Please enter a number',
                initialValue: 0
              }]
              })(<input min={0} />)}
              </FormItem>

					<FormItem {...buttonItemLayout}>
            <Button color="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
							Add Fee
						</Button>
					</FormItem>
				</Form>
			</Card>
		);
	}
}

const graphqlLoadingHOC = Comp => props => {
  if (props.data.loading) {
    return <h1>Loading ...</h1>
  }
  if (props.data.error) {
    return <h1>An error occurred</h1>
  }

  return <Comp {...props} />
}

NewLicenseFee = graphqlLoadingHOC(NewLicenseFee)
NewLicenseFee = withApollo(NewLicenseFee)
NewLicenseFee = graphql(debtCounsellorQuery, {
  options: ({ id }) => ({ variables: { id } })
})(NewLicenseFee)

export default NewLicenseFee;