import React, { useState, useEffect } from 'react';
import { withApollo } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { EditModal } from './FuneralInfo'
import Member from './Member'
import { Confirm, DisplayItem } from '../generic';
import { capitalize } from '../../lib/utils'
import { getAgeFromDOB } from '../../lib/validations'
import { getQuoteAmount } from '../../lib/calculatePrice'
import Numeral from 'numeral'
import moment from 'moment';
import 'numeral/locales/en-za.js'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    paddingBottom: 14,
  },
  table: {
    minWidth: 650,
    fontSize: 15,
  },
  head: {
    paddingTop: 7,
    paddingBottom: 7,
    fontWeight: 400,
  },
  addButton: {
    '&:hover': {
      backgroundColor: '#303F9F',
      color: '#fff',
    }
  },
  removeButton: {
    '&:hover': {
      backgroundColor: '#f00f15',
      color: '#fff',
    }
  },
  cell: {
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 13,
    flexGrow: 1,
    alignContent: 'flex-start'
  }
});

export const actualCover = (memberType, coverAmount, DOB, funeralCoverAmount, ageAtSignup = 0, createdAt) => {

  let amount = funeralCoverAmount

  if (memberType === "PARENT") { amount = amount / 2 }
  if (memberType === "EXTENDED") { amount = amount / 2 }
  if (memberType === "CHILD") {
    let currentAge
    if (DOB) {
      currentAge = moment().diff(DOB, 'years')
    }
    else {
      currentAge = ageAtSignup + moment().diff(createdAt, 'years')
    }

    if (currentAge <= 5) amount = amount / 4
    if (currentAge > 5 && currentAge <= 13) amount = amount / 2
    if (currentAge > 13 && currentAge <= 23) amount = amount
    if (currentAge > 23) amount = 0
  }
  return amount
}

const validChildrenAge = (dob) => {
  if (getAgeFromDOB(dob) < 18) {
    return true
  }
  else return false
}

function QuoteLivesCovered({ funeral, history, client, coverAmount, setQuoteAmount, mode, livesCovered, setLivesCovered }) {
  const classes = useStyles();
  // const { data, loading, error } = useQuery(GET_LIVES_COVERED, { variables: { id: funeral.id } }) || {}
  const [open, setOpen] = useState(false)
  const [member, setMember] = useState()

  useEffect(() => {
    getQuoteAmount(client, funeral, livesCovered.filter(m => m.state === 'YES'), coverAmount, setQuoteAmount)
    console.log('quote lives ', coverAmount)
  }, [coverAmount]);

  const { id: funeralId, coverAmount: funeralCover, createdAt, clientId } = funeral;

  const canAddSpouse = livesCovered && livesCovered.filter(l => l.memberType === 'SPOUSE' && l.state !== 'NO_COVER_MEMBER_REMOVED').length === 0
  const canAddChild = livesCovered && livesCovered.filter(l => l.memberType === 'CHILD' && (!l.DOB || validChildrenAge(l.DOB))).length < 5

  let options = ['EXTENDED', 'PARENT']

  if (canAddSpouse) {
    options.push('SPOUSE')
  }

  if (canAddChild) {
    options.push('CHILD')
  }

  const removeMember = (id) => {
    setLivesCovered(livesCovered.map(member => {
      if (member.id === id) {
        member.state = ' '
      }
      return member
    }))
    getQuoteAmount(client, funeral, livesCovered.filter(m => m.state === 'YES'), coverAmount, setQuoteAmount)
  }

  const addMember = (id) => {
    setLivesCovered(livesCovered.map(member => {
      if (member.id === id) {
        member.state = 'YES'
      }
      return member
    }))
    getQuoteAmount(client, funeral, livesCovered.filter(m => m.state === 'YES'), coverAmount, setQuoteAmount)
  }

  return (<>
    {(canAddChild || canAddSpouse)
      ? <EditModal Content={Member} fontSize={18} noButton heading={"Add Life Covered"} text="Lives Covered" options={options} mode='add' funeralId={funeralId} icon='add' />
      : <h3>Lives Covered</h3>}

    <TableContainer size='small' component={Paper}>
      <Table className={classes.table} aria-label="lives table">
        <TableHead >
          <TableRow>
            <TableCell className={classes.head}>Member</TableCell>
            <TableCell className={classes.head}>Type</TableCell>
            <TableCell className={classes.head}>ID / DOB</TableCell>
            <TableCell className={classes.head}>Relationship</TableCell>
            <TableCell className={classes.head}>SignupAge</TableCell>
            <TableCell className={classes.head}>Income</TableCell>
            <TableCell className={classes.head}>Coverage</TableCell>
            <TableCell className={classes.head}>Include</TableCell>
            <TableCell className={classes.head} />

          </TableRow>
        </TableHead>
        <TableBody>
          {livesCovered && livesCovered.map((row) => {
            const { id, memberType, firstNames, surname, relationship, cellNumbers, ageAtSignup, smoker, income, idNumber, coverAmount, DOB, currentPremium, state, } = row
            if (memberType === 'BENEFICIARY') {
              return <div />
            }
            else return (
              <TableRow key={id} >
                <TableCell className={classes.cell} component="th" scope="row"><EditModal Content={Member} fontSize={13} fontWeight={400} noButton heading={`Edit Member of type ${capitalize(memberType)}`} size="xsmall" mode='edit' text={`${firstNames} ${surname}`} funeralId={funeralId} id={id} memberType={memberType} /></TableCell>
                <TableCell className={classes.cell}>{memberType}</TableCell>
                <TableCell className={classes.cell}>{
                  idNumber
                    ? history
                      ? <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => { localStorage.setItem('searchString', idNumber); history.push(`/products/${idNumber}`) }}> {idNumber} </span>
                      : idNumber
                    : DOB
                      ? moment(DOB).format("DD-MM-YYYY") : ''}
                </TableCell>
                <TableCell className={classes.cell}>{relationship}</TableCell>
                <TableCell className={classes.cell}>{ageAtSignup >= 0 ? ageAtSignup : "ERROR"}</TableCell>
                <TableCell className={classes.cell}>{memberType == "POLICY_HOLDER" ? Numeral(income).format('$ ##,###0') : " "} </TableCell>
                <TableCell className={classes.cell}> {Numeral(actualCover(memberType, coverAmount, DOB, funeralCover, ageAtSignup, createdAt)).format('$ ##,###0')}</TableCell>
                <TableCell className={classes.cell}>{state}</TableCell>
                <TableCell className={classes.cell}>
                  {memberType !== 'POLICY_HOLDER' && <>
                    {state === 'YES'
                      ? <IconButton aria-label="edit" className={classes.removeButton} onClick={() => { removeMember(id) }} >
                        <RemoveCircleOutlineIcon style={{ color: "#D5D5D5", size: 14 }} />
                      </IconButton>
                      : <IconButton aria-label="edit" className={classes.addButton} onClick={() => { addMember(id) }} >
                        <AddCircleOutlineIcon style={{ color: "#D5D5D5", size: 14 }} />
                      </IconButton>
                    }
                  </>}
                </TableCell>
              </TableRow>
            )
          }
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <Confirm
      open={open}
      title='Remove life covererd'
      description={member ? `Press OK to remove ${member.firstNames} ${member.surname} from the policy` : 'Press OK to remove'}
      onOk={() => {
        setOpen(false);
        removeMember()
      }} onCancel={() => setOpen(false)} />
  </>
  );
}

export default withApollo(QuoteLivesCovered)
