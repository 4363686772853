import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

const SalesInteractionResultIcon = ({ result }) => (
  <i
    className={`fa fa-thumbs-${result === 'SIGNEDUP' ? 'up' : 'down'}`}
    style={{ fontSize: 20, color: result === 'SIGNEDUP' ? 'green' : 'red' }}
  />
)

// TODO: Update from now (see mobile-sales-client InfoItem.js)
// TODO: Use more descriptive location from the geolocation
const SalesInteractionItem = ({ salesInteraction, onSalesPersonPress, onLocationPress, onPlaybackPress }) => {
  const { salesPerson, location, result, timestamp } = salesInteraction
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

      <span style={{ fontSize: 14, paddingLeft: 7, color: '#c8c8c8' }}>
        <SalesInteractionResultIcon result={result} />&nbsp;&nbsp;
        {salesPerson ? (
          <a href="#" onClick={() => onSalesPersonPress && onSalesPersonPress(salesPerson.id)}>{`${salesPerson.first} ${
            salesPerson.last
          }`}</a>
        ) : (
          'Unknown Sales Person'
        )}{' '}
        &nbsp;at&nbsp;
        {location ? (
          <a
            href="#"
            onClick={() =>
              onLocationPress &&
              onLocationPress({ latitude: parseFloat(location.latitude), longitude: parseFloat(location.longitude) })
            }
          >{` location ${parseFloat(location.latitude).toFixed(3)}, ${parseFloat(location.longitude).toFixed(3)}`}</a>
        ) : (
          ' an unknown location'
        )}
        &nbsp;{moment(timestamp).fromNow()}
      </span>
      { salesInteraction.recording && <a href="#" onClick={() => onPlaybackPress(salesInteraction, timestamp)}><i className={'fa fa-play-circle'} /></a> }
    </div>
  )
}

SalesInteractionItem.propTypes = {
  salesInteraction: PropTypes.object
}

export default SalesInteractionItem
