import React, { useState } from 'react'
import gql from 'graphql-tag'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Search from './Search'
import GraphQLTable from '../generic/GraphQLTable'
import { Card, Button } from '../generic'
import { printCurrency } from '../../lib/helpers';
import { translateState } from './FuneralInfo'
import moment from 'moment';
import Numeral from 'numeral'
import { AuthConsumer } from 'react-auth-helper';
import 'numeral/locales/en-za.js'

// switch between locales
Numeral.locale('en-za')

const useStyles = makeStyles((theme) => ({
  footer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingTop: 21,
    paddingBottom: 21
  },
  button: {
    minWidth: 200,
    maxWidth: 200,
    textAlign: 'center',
    marginRight: 14
  },
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
}));

const config = {
  query: gql`
    query funerals {
      funerals(limit: 10000, filter: {state:"INFO_MISSING"}) {
        id
        policyNumber
        paymentMethod
        paymentDueDay
        currentPremiumTotal
        coverAmount
        firstActivated
        pricingVersion
        paymentBankAccount {
          id
          bankName
          accountNo
        }
        client {
          id
          firstNames
          surname
          idNumber
          email
          cellNumbers
          createdAt
        }
        beneficiaries {
          payTo {
            id
            firstNames
            surname
          }
        }
        livesCoveredIds
        state
        createdAt
        updatedAt
        policyType
        createdBy {
          description
          }
        accountingIdentifier
        updatedBy {
          description
          }
        signupSalesInteraction {
          id
          salesPerson {
            id
            first
            last
            email
          }
        }
      }
    }
  `,
  dataset: data => data.funerals,
  fields: {
    policyNumber: { label: 'Policy#' },
    'client.firstNames': {
      label: 'First_Names',
    },
    'client.surname': {
      label: 'Surname'
    },
    'client.idNumber': { label: 'Id_Number' },
    'client.cellNumbers[0]': { label: 'Mobile' },
    'client.email': { label: 'Email' },
    policyType: {
      label: 'Product',
      Cell: row => row.value && row.value.replace(/FAMILY/g, '').replace(/_/g, ' ').replace(/WITH/g, '+').replace(/AND/g, '+')
    },
    // pricingVersion: {
    //   label: 'Version',
    //   Cell: row => row.value ? row.value.indexOf('FAMFUN') > -1 ? 'FAMFUN' : row.value.indexOf('INDFUN') > -1 ? 'INDFUN' : ' ' : ' '
    // },
    livesCoveredIds: {
      label: 'Covered',
      align: 'center',
      Cell: row => (row.value ? row.value.length : 0)
    },
    'beneficiaries[0].payTo.firstNames': {
      label: 'Beneficiary',
    },
    'beneficiaries[0].payTo.surname': {
    },
    coverAmount: {
      label: 'Sum_Assured',
      align: 'left',
      accessor: row => row.coverAmount ? Numeral(row.coverAmount).format('$ 0,0') : '0',
      filter: 'progress'
    },
    currentPremiumTotal: {
      label: 'Premium',
      align: 'left',
      accessor: row => (row.currentPremiumTotal ? printCurrency(row.currentPremiumTotal) : '-')
    },

    paymentMethod: {
      label: 'Pay_Method'
    },
    firstActivated: {
      label: 'Activated',
      type: 'date',
      Cell: ({ value }) => value ? moment(value).format('DD-MM-YYYY') : '',
      filter: 'daterange'
    },
    createdAt: {
      label: 'Created_At',
      filter: 'daterange',
      type: 'date',
      Cell: ({ value }) => value ? moment(value).format('DD-MM-YYYY') : '',
    },
    'signupSalesInteraction.salesPerson': {
      label: 'Sold_By',
      filter: 'select',
      accessor: d => {
        return (d.signupSalesInteraction ? d.signupSalesInteraction.salesPerson ? `${d.signupSalesInteraction.salesPerson.first} ${d.signupSalesInteraction.salesPerson.last}` : 'DIGITAL' : d.accountingIdentifier ? d.accountingIdentifier : d.createdBy ? d.createdBy.description.includes('API') ? 'MoneyMarket' : d.createdBy.description : 'DIGITAL SALE')
      },
    },
    state: {
      label: 'State',
      filter: 'select',
      Cell: ({ value }) => (value ? translateState(value) : '-')
    },

    // updatedAt: {
    //   label: 'Updated_At',
    //   type: 'date',
    //   Cell: ({ value }) => value ? moment(value).format('DD-MM-YYYY') : '',
    //   filter: 'daterange'
    // },
    'updatedBy.description': {
      label: 'Last_Update',
      filter: 'select',
    },
    // Updated: {
    //   label: 'Relative',
    //   accessor: d => moment(d.updatedAt).fromNow()
    // },
  },
  defaultSorted: [{ id: 'createdAt', desc: true }]
}

/*
,
livesCovered: {
  label: '# Members',
  accessor: row => (row.livesCovered ? row.livesCovered.length : 0),
  filterType: 'dropdown'
}
  */

function IncompleteFunerals({ history }) {
  const classes = useStyles();
  const [open, setOpen] = useState()

  return (
    <AuthConsumer>
      {({ inGroup }) => (
        <Card>
          {false && <div style={{ position: 'absolute', paddingTop: 5, left: '48em' }} >
            <Button color="primary" size="small" onClick={() => setOpen(true)}>Advanced Search</Button>
          </div>}

          <GraphQLTable config={config} onRowPress={funeral => history.push(`/funeral/${funeral.id}/edit`)} />

          <Dialog
            title="Enter Policy Number & Effective date (optional)"
            open={open}
            onClose={() => setOpen(false)} 
            scroll="paper"
            maxWidth='md'
            classes={{
              scrollPaper: classes.topScrollPaper,
              paperScrollBody: classes.topPaperScrollBody,
            }}
            aria-labelledby="funeral-dialog-title"
          >
            <DialogTitle id="simple-dialog-title">Enter Policy Number & Effective Date</DialogTitle>
            <Search onOk={(funeralId, effectiveDate) => history.push(`/funeral/${funeralId}/edit/${effectiveDate}`)} />
          </Dialog>
        </Card>
      )}
    </AuthConsumer>
  );
}

export default IncompleteFunerals
