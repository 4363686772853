import React from 'react';
import gql from 'graphql-tag';
import GraphQLTable from '../generic/GraphQLTable';
import moment from 'moment';
import {Card} from '../generic'
import { nameCase } from '@foundernest/namecase';

const productTypes = (productType) => {

  switch (productType) {
    case "OPEN_MARKET": return "Open_Market"
    case "THIRD_PARTY_DC": return "Third_Party"
    default: return "Meerkat_DC"
  }
}

const paymentTypes = (paymentType) => {

  switch (paymentType) {
    case "HYPHEN": return "HYPHEN"
    case "DEBI_CHECK": return "DEBI_CHECK"
    case "DO": return "Debit Order"
    case "PERSAL": return "Salary"
    case "PAY_AT": return "Pay@"
    case "FROM_SAVINGS": return "SAVINGS"
    default: return ""
  }
}

const config = {
  query: gql`
		{
			leads(limit: 10000) {
    id
    idNumber
    firstName
    surname
    cellNumber
    email
    campaignDescription
    leadSourceDescription
    leadServiceId
    leadMessageSent
    contacted
    updatedAt
    clientId
  }
		}
	`,
  dataset: data => data.leads,
  fields: {
    campaignDescription: {label: 'Campaign',filter: 'select',},
    leadSourceDescription: { label: 'Source',filter: 'select',},
    leadServiceId: { label: 'Service', filter: 'select',},
    idNumber: { label: 'ID Number' },
    leadName: {
      label: 'Name',
      width:'125',
      accessor: row => (nameCase(`${row.firstName} ${row.surname}`))
    },
    cellNumber: {
      label: 'Cell'
    },
    leadMessageSent: {
      label: 'Message', align: 'left',
      Cell: row => (row.value ? row.value.substring(0, 100) : ''),
      width:'250'
    },
    Updated: {
      label: 'Created',
      Cell: ({ value }) => moment(value).format('DD-MM-YYYY hh:mm a'),
      accessor: row => row.updatedAt,
      filter:'daterange',
    },
    updatedAt: { label: 'Relative', Cell: row => moment(row.value).fromNow() }
  },
  defaultSorted: [{id: 'campaignDescription'}]
};

const LeadsList = ({ history }) => (
  <Card title="Leads">
      <GraphQLTable config={config} onRowPress={lead => history.push(`/leads/${lead.id}/edit`)} />
  </Card>
);

export default LeadsList;
