/**
 * Original filename: "model.js"
 * QuestionSet Model Class and Types.
 * Part of the  application.
 *
 * First Create: 2017/12/27 3:22 PM
 * Added By: user
 **/
import gql from 'graphql-tag';
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import client from '../../client';
import {nil} from "../../Helpers";
import {ValidationReport, BaseModel, defaultFormatter, defaultValidator} from "../../model/index";

//export const QuestionSetTypes = {
//    QuestionSet_01: {key: '', title: '', icon: '', tooltip: ''},   
//};

export const QuestionTypes = {
    YESNO : { key: 'YESNO', title: 'Yes / No', icon: 'fa fa-check-circle-o', tooltip: 'Use this question type for yes / no type questions' },
    MULTIPLE_CHOICE: {key: 'MULTIPLE_CHOICE', title: 'Multiple Choice', icon: 'fa fa-check-square', tooltip: 'Use this question when allowing a multiple choice selection' },
    TEXT: {key: 'TEXT', title: 'Text', icon: 'fa fa-paragraph', tooltip: 'Use this question type for text based answers'}
};


export class QuestionModel extends BaseModel {
    constructor(props = QuestionModel.defaultProps){
        super(props);
        props = { ...QuestionModel.defaultProps, ...props };
        this._id = props.id;
        this._question = props.question || '';
        this._questionType = props.questionType || QuestionTypes.YESNO.key;
        this._answers = props.answers || [];
        this._answerAttempts = props.answerAttempts || 0;
        this._correctAnswerIndex = props.correctAnswerIndex || -1;
        this._correctAnswerText = props.correctAnswerText || "";
        this._requiresCorrectAnswer = props.requiresCorrectAnswer || false;
        this._score = props.score || 0;
    }


    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get question() {
        return this._question;
    }

    set question(value) {
        this._question = value;
    }

    get questionType() {
        return this._questionType;
    }

    set questionType(value) {
        this._questionType = value;
    }

    get answers() {
        return this._answers;
    }

    set answers(value) {
        this._answers = value;
    }

    get answerAttempts() {
        return this._answerAttempts;
    }

    set answerAttempts(value) {
        this._answerAttempts = value;
    }

    get correctAnswerIndex() {
        return this._correctAnswerIndex;
    }

    set correctAnswerIndex(value) {
        this._correctAnswerIndex = value;
    }

    get correctAnswerText() {
        return this._correctAnswerText;
    }

    set correctAnswerText(value) {
        this._correctAnswerText = value;
    }

    get requiresCorrectAnswer() {
        return this._requiresCorrectAnswer;
    }

    set requiresCorrectAnswer(value) {
        this._requiresCorrectAnswer = value;
    }

    get score() {
        return this._score;
    }

    set score(value) {
        this._score = value;
    }

    static propTypes = {
        id: PropTypes.string.isOptional,
        question: PropTypes.string.isOptional,
        questionTypes: PropTypes.oneOf(['YESNO', 'MULTIPLE_CHOICE', 'TEXT']),
        answers:PropTypes.arrayOf(PropTypes.string),
        answerAttempts: PropTypes.number,
        correctAnswerIndex: PropTypes.number,
        correctAnswerText: PropTypes.string,
        correctAnswerYesNo: PropTypes.bool,
        requiresCorrectAnswer: PropTypes.bool,
        score: PropTypes.number
    };

    static defaultProps = {
        id: null,
        question: '',
        questionTypes: QuestionTypes.YESNO.key,
        answers: [],
        answerAttempts: 0,
        correctAnswerIndex: -1,
        correctAnswerYesNo: false,
        requiresCorrectAnswer: false,
        score: 0
    };

    static gqlConfig = {
        list: gql`
        query QuestionsQuery($lastCreatedAt: Float, $limit: Int){
            questions(lastCreatedAt: $lastCreatedAt, limit: $limit) {
                id
                question
                questionType
                answers
                answerAttempts
                correctAnswerIndex
                correctAnswerText
                correctAnswerYesNo
                requiresCorrectAnswer
                score
            }
        }`,
        query: gql`query QuestionItem($id: String!){
            question(id: $id){
                id
                question
                questionType
                answers
                answerAttempts
                correctAnswerIndex
                correctAnswerText
                correctAnswerYesNo
                requiresCorrectAnswer
                score
            }
        }`,
        create: gql`mutation CreateQuestion($input: CreateQuestionInput!){
            createQuestion(input: $input){
                id
            }
        }`,
        update: gql`mutation UpdateQuestion($id: String!, $input: UpdateQuestionInput!){
            updateQuestion(id: $id, input: $input){
                id
            }
        }`,
        search: gql`query QuestionSearch($searchFor: String){
            searchQuestion(searchFor: $searchFor){
                id
                question
                questionType
                answers
                answerAttempts
                correctAnswerIndex
                correctAnswerText
                correctAnswerYesNo
                requiresCorrectAnswer
                score                     
            }
        }`
    };

    static formatters = {
        _toJSON: {
            create: ( question ) => ({
                question: question.question,
                questionType: question.questionType,
                answers: question.answers,
                answerAttempts: question.answerAttempts,
                correctAnswerIndex: question.correctAnswerIndex,
                correctAnswerText: question.correctAnswerText,
                correctAnswerYesNo: question.correctAnswerYesNo,
                requiresCorrectAnswer: question.requiresCorrectAnswer,
                score: question.score
            }),
            update: ( question ) => ({
                question: question.question,
                questionType: question.questionType,
                answers: question.answers,
                answerAttempts: question.answerAttempts,
                correctAnswerIndex: question.correctAnswerIndex,
                correctAnswerText: question.correctAnswerText,
                correctAnswerYesNo: question.correctAnswerYesNo,
                requiresCorrectAnswer: question.requiresCorrectAnswer,
                score: question.score
            })
        },
        _toString: {
            default: (question) => ( `${question.questionType}: ${question.question}` )
        }
    };

    static fromPropsArray = ( propsArray = [] ) => {
        let questions = [];
        propsArray.map((props) => (questions.push(new QuestionModel(props))));
        return questions;
    };
}

export class QuestionSetModel extends BaseModel {
    constructor(props = QuestionSetModel.defaultProps) {
        super(props);
        props = {...QuestionModel.defaultProps, ...props};
        this._id = props.id;
        this._description = props.description;
        this._questions = _.isArray( props.questions ) === true ? QuestionModel.fromPropsArray( props.questions )  : [];
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get description() {
        return this._description;
    }

    set description(value) {
        this._description = value;
    }


    get questions() {
        return this._questions;
    }

    set questions(value) {
        this._questions = value;
    }

    get validationReport() {
        return QuestionSetModel.validate(this);
    }

    static propTypes = {
        description: PropTypes.string.isRequired,
        questions: PropTypes.arrayOf(QuestionModel).isOptional
    };

    static defaultProps = {
        id: null,
        description: null
    };

    static gqlConfig = {
        list: gql`query QuestionSetList($limit: Int){
                    questionsets(limit: $limit){
                        id
                        description
                        questions {
                            id
                            question
                            questionType                       
                        }
                    }                
                }`,
        query: gql`query QuestionSetQuery($id: String!) {
                    questionset(id: $id) {
                      id
                      description
                      questions {
                        id
                        question
                        questionType                       
                      }
                    }                 
                 }`,
        create: gql`mutation CreateQuestionSet($input: CreateQuestionSetInput!) {
                    createQuestionSet(input: $input) {
                        id
                    }
                }`,
        update: gql`mutation UpdateQuestionSet($input: UpdateQuestionSetInput!, $id: String!) {
                updateQuestionSet(id: $id, input: $input) {
                        id            
                    }
                }`,
        search: ``
    };

    static formatters = {
        _toJSON: {
            create: ( questionSet ) => ({
                description: questionSet.description,
                questionsIds: questionSet.questions.map(( question ) => question.id)
            }),
            update: ( questionSet ) => ({
                description: questionSet.description,
                questionsIds: questionSet.questions.map(( question ) => question.id)
            }),
        },
        _toString: {
            default: (questionSet) => ( questionSet.description || '' )
        }
    };


    static queries = {
        listAll : () => {
            return new Promise((resolve, reject)=>{

                client.query({query: QuestionSetModel.gqlConfig.list, fetchPolicy:'network-only'}).then((response) => {
                    resolve(response.data.questionsets);
                }).catch((queryError) => {
                    console.error(`Could not execute the query due to an error ${queryError.message}`);
                    reject(queryError);
                })
            });

        }
    };

    static validators = {
        description: (descriptionValue, reportInstance, context) => {
            //validate field value  
        }
    };

    static validate = (questionSet, context = 'default') => {
        let report = new ValidationReport();
        //add validation
        defaultValidator(questionSet, report, 'QuestionSetModel');
        QuestionSetModel.validators.description(questionSet.description, report, context);

        return report;
    }
}