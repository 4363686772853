import React from 'react'
import CompanyList from './CompanyList'
import { Route, Switch } from 'react-router-dom'

const Companies = () => (
  <div>
    <Route path='/company' exact component={CompanyList} />
  </div>
)

export default Companies
