import React, { useContext, useState, useEffect, useRef } from 'react';
import { withApollo, useQuery } from 'react-apollo';
import gql from 'graphql-tag'
import { useConfirm } from "material-ui-confirm";
import { makeStyles } from '@material-ui/core/styles';
import { MaterialTable as MaterialTable } from '../generic'
import { Card, Button, Grid, Switch, Select, TextField } from '../generic';
import { withRouter } from 'react-router-dom'
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
import { claimInformation } from './ClaimAdmin'
const PaymentTypes = ["INCOME_BASED", "LUMP_SUM"]
const OtherPaymentTypes = ["EX_GRATIA_INCOME_BASED", "EX_GRATIA_LUMP_SUM"]

const useStyles = makeStyles((theme) => ({
  even: {
    flexGrow: 1,
    justifyContent: 'center',
    paddingBottom: 7
  },
  start: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 7
  },
  end: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 7
  },
}));

const UpdateClaimBreakDown = gql`mutation updateFutureClaimBreakdown($id: String!, $input: [ClaimBreakdownInput]) {
  updateFutureClaimBreakdown(id: $id, input: $input) {
    id
    amountClaimed
    claimBreakdown {
      amount
      type
      id
      month
      obligation {
        id
        clientCreditor {
          id
          description
          }
        reference
        balances{
          balance
          }
        payments{
          amountPaid
          }
      }
      claimPaymentType
    }
  }
}`

const GET_OBLIGATIONS = gql`
  query claim($id: String!) {
    claim(id: $id) {
      id
      claimBreakdown {
        amount
        type
        id
        month
        obligation {
          id
          clientCreditor {
            id
            description
            }
          reference
          balances{
            balance
            }
          payments{
            amountPaid
            }
        }
        claimPaymentType
      }
    }
  }`

const maxMonth = (payments) => {
  let max = 1
  payments && payments.forEach(claim => {
    if (claim.month > max) {
      max = claim.month
    }
  })
  return max
}

// Create an editable cell renderer
const EditableCell = (props) => {
  const {
    value: initialValue,
    row: { index },
    column: { id, type, options },
    updateMyData, // This is a custom function that we supplied to our table instance
  } = props

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = e => {
    // console.log('EditableCell onChange ', type === 'select' ? e : e.target.value)
    setValue(type === 'select' ? e : e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = (e) => {
    // run mutation to change description
    updateMyData(index, id, e.target.value)
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // Check to make sure not all columns are editable (only the one called ID)
  if (type === 'select') {
    return (
      <Select noLabel value={value} options={options.map(o => ({ value: o, label: o }))} onChange={onChange} onBlur={onBlur} />
    );
  }
  else {
    return (
      <TextField noLabel value={value} onChange={onChange} onBlur={onBlur} />
    );
  }
};

let Schedule = (props) => {
  const confirm = useConfirm();
  const classes = useStyles();
  const [showAll, setShowAll] = useState()
  const [claimBreakdown, setClaimBreakdown] = useState(props.claimBreakdown)
  const [dataSource, setDataSource] = useState(claimBreakdown && claimBreakdown.slice(0).filter(payment => payment.month > props.claimPaymentCounter).sort((a, b) => a.month < b.month ? -1 : 1))
  const [amountClaimed, setAmountClaimed] = useState(props.amountClaimed)
  const [claimPaymentCounter, setClaimPaymentCounter] = useState(props.claimPaymentCounter)

  const columns = [
      {
      Header: 'Creditor',
      accessor: 'obligation.clientCreditor.description',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Month',
        accessor: "month",
      },
      {
        Header: ' Payment Type',
        accessor: "claimPaymentType",
        Cell: EditableCell,
        type: 'select',
        options: props.claimType === 'OTHER' ? OtherPaymentTypes : PaymentTypes,
        editable: true,
      },
      {
        Header: 'Amount',
        accessor: "amount",
        Cell: EditableCell,
        editable: true,
      }
  ];

  const displayPayments = () => {
    // console.log('datasource ', dataSource)
      if (showAll) {
        return dataSource
    }
      else {
        return dataSource && dataSource.filter(payment => payment.month == claimPaymentCounter + 1).sort((a, b) => a.month < b.month ? -1 : 1)
      }
  }

  const claimTotal = (display) => {
      if (display && display.length > 0) {
        return display.reduce((total, p) => total + (p.amount || 0), 0)
        }
      else return 0
  }

  const obligationOptions = (forObligations) => {
    let options = []
    forObligations && forObligations.forEach(ob => {
      options.push({ value: ob.id, label: `${ob.clientCreditor.description} ${ob.reference} ` })
  })
    return options
}

  const updateAllChangedRows = () => {

      claimBreakdown.forEach((row, index) => {
        const newIndex = dataSource.findIndex(item => row.id === item.id && row.month === item.month);

        if (newIndex !== -1) {
          // change breakdown

          const item = dataSource[newIndex];
          if (item != row) {
            const breakdown = { ...row, ...item }
            updateClaimBreakDowns(breakdown)
          }
        }
      })
    setShowAll(false)
    props.afterSubmit()
  }

  const updateClaimBreakDowns = (breakdown) => {

    //    const dataSource = [...state.dataSource];
    //    const breakdowns = []
    //    dataSource.forEach(row => {
    //      const { __typename, obligation, ...input } = row;
    //      breakdowns.unshift(input)
    //    })

    const { __typename, obligation, ...input } = breakdown;

    props.client.mutate({
      mutation: UpdateClaimBreakDown,
      variables: {
        id: props.id,
        input,
      },
      // refetchQueries: [{ query: claimInformation, variables: { id: props.id } }]
    })
      .then((result) => {
        // message.success('updateClaimBreakDowns Payment schedule updated. ', 3);
        setAmountClaimed(result.data.updateFutureClaimBreakdown.amountClaimed)
        setClaimBreakdown(result.data.claimBreakdown)

      });
    }

  const confirmAddMonth = () => {
    confirm({
      title: 'Add Month To Payment Schedule',
      description: "Do you want to duplicate the last payment schedule month?",
    })
      .then(() => addPaymentMonth())
  }

  const addPaymentMonth = () => {
    const lastMonth = maxMonth(dataSource)
    let amountClaimed
    let newData = []
    let newClaimBreakDown = [...claimBreakdown]

    dataSource && dataSource.forEach(b => {
      let { __typename, obligation, obligationId, ...rest } = b
      newData.push(b)
      amountClaimed += b.amount
      })

    dataSource && dataSource.forEach(b => {
      if (b.month == lastMonth && b.amount > 0) {
        let { month, ...rest } = b
        const newMonth = { ...rest, month: lastMonth + 1 }
        newData.push(newMonth)
        const newBreak = { ...rest, month: lastMonth + 1, amount: 0 }
        newClaimBreakDown.push(newBreak)
        amountClaimed += b.amount
        }
      })

    setDataSource(newData)
    setClaimBreakdown(newClaimBreakDown)
    setShowAll(true)
  }

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    console.log('Editable cell updateMyData ', value)
    setDataSource(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }

  const { cancel, claimType } = props
  const filteredSource = displayPayments()
  console.log('filtered source ', filteredSource)

  return (
    <Card>
      <h2>{`Total Payments: ${Numeral(claimTotal(filteredSource)).format('$ ##,###0.00')}`}</h2>

      {(claimType === 'OTHER') &&
        <Grid container justifyContent='flex-end' alignItems='center' spacing={1}> All upcoming payments &nbsp;
          <Grid item xs={4}>
            <Switch value={showAll} onChange={() => { setShowAll(!showAll) }} />
          </Grid>
        </Grid>}
        <div style={{ marginTop: 7, marginBottom: 7 }}>


        <MaterialTable columns={columns} data={filteredSource} updateMyData={updateMyData} onRowPress={(row) => console.log('row was pressed')} />

        </div>
      {(claimType === 'OTHER') &&
        <Grid container className={classes.end} spacing={1}>
          <Grid item xs={2}><Button onClick={() => addPaymentMonth()} color="primary" > Add Month </Button></Grid>
        </Grid>}
      <Grid container spacing={1} className={classes.end}>
        <Grid item xs={1}><Button color="secondary" onClick={() => cancel()}>Cancel</Button></Grid>
        <Grid item xs={1}><Button color="primary" disabled={false} onClick={() => { updateAllChangedRows() }}>Save</Button></Grid>
      </Grid>
    </Card>
  );
}

Schedule = withApollo(Schedule)
Schedule = withRouter(Schedule)

const PaymentSchedule = (props) => {
  const { data, loading, error } = useQuery(GET_OBLIGATIONS, { variables: { id: props.id } })
  console.log('Payment Schedule', data, loading, error, props)
  if (loading || error) {
    return <div />
  }
  else return <Schedule {...props} claimBreakdown={data.claim.claimBreakdown} />

}
export default withApollo(PaymentSchedule)
