import React, { useState, useEffect, Fragment } from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import styled from "@emotion/styled";
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
// import Logo from '../Logo';
import Favicon from '../Favicon';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Sidebar, sidebarOptions } from '../Sidebar';
import { bear } from '../../assets/images'
import CommunicationsAlert from '../communication/CommunicationsAlert';

const USER_INFO = gql`{
	me {
		id
		first
    last
	}
  currentUser {
    id
    roles
		email
    userGroups {
      id
      name
      identifier
    }
  }
}`

const useStyles = makeStyles((theme) => ({
	iconButton: {
		// color: '#fff',
		fontSize: 20,
	},
	menuButton: {
		color: '#fff',
	}
}));

const navStyle = { cursor: 'pointer', fontSize: 24, color: '#fff' }
const exitStyle = { position: 'absolute', right: 0 }

const TitleText = styled.div`
	// color:   #404357; 
	color:   #fff; 
	font-size: 21px;
	padding-left:3px;
	font-variant: small-caps;
	padding-right: 7px;
	padding-left: 7px;
`;

const Search = styled.div`
	color:   #404357; 
	font-size: 13px;
	font-variant: small-caps;
	margin-left:30px;
	margin-top: 3px;
`;

const LogoImage = styled.div`
	border-left: 1px solid #fff;
	border-right: 1px solid #fff;
	padding-left: 7px;
	padding-right: 7px;
`;

const UserText = styled.div`
	color:  #fff; 
	font-size: 14px;
	position:absolute;
	right:3em;
	border-left: 1px solid #fff;
	border-right: 1px solid #fff;
	padding-left: 7px;
	padding-right: 7px;
`;

const HeaderBar = styled.div`
	background:   #229490; 
	height: 40px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 1px solid #404357;
	padding-bottom:3px;
	padding-top:3px;
`;

const SidebarWrap = styled.div`
	width: 100%;
`;

function NotEmployee({ currentPath, history }) {
	const hide = currentPath && currentPath.includes('login')
	return (<>
		{hide
			? <div />
			: <div style={{ marginTop: '35vh' }}>
				<center>
					<h1> Unable to read your employee data. Please refresh your screen</h1>
					<br /><br />
					<h2>(If this doesn't work call support)</h2>
					<br /><br /> <br /><br />
				</center>
			</div >}
	</>)
}

function User({ me, currentUser }) {
	return <UserText> {currentUser ? currentUser.email : 'NOT LOGGED IN'} - {me ? `${me.first} ${me.last}` : 'NOT AN EMPLOYEE'} </UserText>
}

function Title({ currentPath }) {
	const active = sidebarOptions.find(option => currentPath && currentPath.indexOf(option.key) === 1)
	return <TitleText>{active ? active.title ? active.title : active.label : ''}</TitleText>
}

let Layout = ({ history, location }) => {
	const classes = useStyles()
	const [searchString, setSearchString] = useState()
	const [sidebar, setSidebar] = useState(false);
	const [currentPath, setCurrentPath] = useState(location.pathname);
	const { data, loading, error } = useQuery(USER_INFO) || {}
	const { me, currentUser } = data || {}

	useEffect(() => {
		setCurrentPath(location.pathname);
		if (location.pathname.includes('/products/')) {
			setSearchString(location.pathname.split('/products/')[1])
		}
	}, [location, me, currentUser]);

	const showSidebar = () => setSidebar(!sidebar);

	const inGroup = (groupName) => {
		return currentUser && currentUser.userGroups && currentUser.userGroups.map(g => g.identifier).includes(groupName)
	}

	if (loading) {
		return <div />
	}
	else if (error) {
		console.log('Layout error', { ...error })
		if (!window.location.hostname.includes('localhost') && error.message.includes('Network error')) {
			window.location.reload()
			return <center>Error loading user data .. retrying</center>
		} else {
			return <center>An unexpected error occurred .. please reload. Error is {error.message} </center>
		}
	}

	else if (me) {
		if (!currentPath.includes('login') && currentUser && (!currentUser.userGroups || currentUser.userGroups.length === 0)) {
			if (!currentPath.includes('chatadmin')) {
				history.push("/chatadmin")
			}
		}
		return (
			<>
				<Favicon />
				<HeaderBar>
					<IconButton onClick={showSidebar} >
						<MenuIcon className={classes.menuButton} />
					</IconButton>
					<LogoImage>
						<img src={bear} height="28px" />
					</LogoImage >
					{(inGroup('chatadmin') || inGroup('replies')) && <CommunicationsAlert user={currentUser} />}
					<Title currentPath={currentPath} />

					{!(currentPath.indexOf('credit') > -1 || currentPath.indexOf('funeral') > -1) && <Search>
						<IconButton disabled={!searchString}
							onClick={() => {
								setSearchString(' ');
								if (location.pathname.includes('/products/')) {
									history.push('/products')
								}
							}} >
							<ClearIcon className={classes.iconButton} />
						</IconButton>
						<input style={{ width: 300 }} value={searchString} placeholder="Global Client search" id="searchString"
							inputProps={{ style: { textTransform: "uppercase" } }}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									// localStorage.setItem('searchString', searchString);
									history.push(`/products/${searchString}`)
								}
							}}
							onChange={e => setSearchString(e.target.value)} />

						<IconButton disabled={!searchString} onClick={() => {
							// localStorage.setItem('searchString', searchString);
							history.push(`/products/${searchString}`)
						}} >
							<SearchIcon className={classes.iconButton} />
						</IconButton>
					</Search>}

					<User me={me} currentUser={currentUser} />
					<IconButton style={{ ...navStyle, ...exitStyle }} onClick={() => history.push("/logout")} >
						<ExitToAppIcon />
					</IconButton>
				</HeaderBar>
				<Sidebar sidebar={sidebar} showSidebar={showSidebar} currentUser={currentUser} location={location} />
			</>)
	}
	else {
		return (
			<>
				<Favicon />
				<HeaderBar>
					<LogoImage>
						<img src={bear} height="28px" />
					</LogoImage >
					<Title currentPath={currentPath} />
				</HeaderBar>
				<NotEmployee currentPath={currentPath} history={history} />
			</>)
	}
}

export default withRouter(Layout) 