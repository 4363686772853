/**
 * Original filename: "index.js"
 * QuestionSet React Router Higher Order Component Wrapper.
 * Part of the  application.
 *
 * First Create: 2017/12/27 2:16 PM
 * Added By: user
 **/
import React from 'react'
import QuestionSetList from './QuestionSetList'
import QuestionSet from './QuestionSet';
import {Route, Switch} from 'react-router-dom'

const QuestionSets = () => (
    <div>
        <Route path='/questions' exact component={QuestionSetList}/>
        <Switch>
            <Route path='/questions/new' exact
                   render={() =>
                       <QuestionSet mode={'new'} id={null}/>
                   }
            />
            <Route path='/questions/:id/:edit?'
                   render={({match}) => <QuestionSet mode={match.params.edit ? 'edit' : 'display'}
                                                     id={match.params.id}/>}/>
        </Switch>
    </div>
);

export default QuestionSets