import React, { useState, useEffect } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core/styles';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useCollapse from 'react-collapsed';
import Terms from './Terms';
import BankAccount from '../bankAccount/BankAccount';
import { ClientInformation, ClientNotes } from '../shared'
import * as FaIcons from "react-icons/fa";
import { GraphQLFormProvider, FormField, FieldValue } from 'react-form-helper'
import { ActivityIndicator, Button, Card, FormModal, Select, Modal, Grid, DisplayItem, TextField, renderInput, Confirm, renderDisplay } from '../generic'
import SubmitFormButton from 'react-form-helper/dist/SubmitFormButton';
import QuoteLivesCovered from './QuoteLivesCovered'
import Beneficiaries from './Beneficiaries'
import { CLI_PAYMENT_TYPES } from '../../variables/paymentTypes'
import { API_URL } from '../../config';
import { getAgeFromDOB, getAgeFromID, getDOBFromID, isInteger } from '../../lib/validations';
import moment from 'moment'
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    // position: 'absolute',
    // top: 9, left: '8em'
    // color: '#fff',
    fontSize: 21,
    fontWeight: "bold"
  },
  center: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingTop: 7,
    paddingBottom: 7
  },
  footer: {
    paddingRight: 0,
    paddingBottom: 21
  },
}));

// switch between locales
Numeral.locale('en-za')

export const EditModal = (props) => {
  return (
    <FormModal
      componentProps={props}
    />
  )
}

const getSalesPerson = gql` {
  me { 
    id
    first
    last
  }
}`;

const funeralFragment = gql`
  fragment FuneralFragment on Funeral {
    id
    policyNumber
    state
    policyType
    pricingVersion
    paymentMethod
    paymentDueDay
    currentPremiumTotal
    coverAmount
    doMandate
    debitOrderId
    easyPayNumber
    clientId
    client{
      id
      income
      estimatedIncome
    }
    livesCovered {
      id
      age
      clientId
      firstNames
      surname
      idNumber
      idType
      memberType
      relationship
      currentPremium
      coverAmount
      DOB
      gender
      ageAtSignup
      cellNumbers
      email
      income
      state
      smoker
    }
    beneficiaries {
      id
      payoutType
      percentage
      payToId
      payTo {
          id
          firstNames
          surname
          idNumber
          idType
          cellNumbers
          email
          relationship
        }
      bankAccount {
        id
        bankName
        accountNo
        associatedWith {
          id
          type
          reference
        }
      }
    }
    paymentBankAccount {
      id
      bankName
      branchCode
      accountNo
      ownAccount
      accountHolderName
      accountHolderContactNumber
      accountHolderIdNumber
      accountHolderEmail
      idNumberMatch
      nameMatch
      phoneMatch
      accountExists
      avsRawData
      }
  }
`
const CREATE_NOTE = gql`
	mutation($input: CreateNoteInput! ) {
		createNote(input: $input) {
      id
      text
      filesIds
      associatedWith {
        type
        id
      }
      createdAt
      createdBy {
          description
        }
      updatedAt
      updatedBy {
        email
        description
      }
		}
	}
`;

const funeralInformation = gql`
  query FuneralInformation($id: String!) {
    funeral(id: $id)
      {
      ...FuneralFragment
      }
  }
  ${funeralFragment}
`;

const createSalesInteractionPolicyWithMembers = gql`
mutation CreateSalesInterationPolicyWithMembers($funeral: CreateFuneralInput!, $beneficiary:AddBeneficiaryInput, $bankAccount: CreateBankAccountInput, $members: [CreateMemberInput!], $salesInteraction: CreateSalesInteractionInput!) {
  createSalesInteractionPolicyWithMembers(funeral: $funeral, bankAccount: $bankAccount, beneficiary: $beneficiary, members: $members, salesInteraction: $salesInteraction) {
    payAtNumber
    policyNumber
    id
    clientId
  }
}
`

const updateSalesInteractionPolicyWithMembers = gql`
mutation UpdateSalesInterationPolicyWithMembers($id: String!, $funeral: CreateFuneralInput!, $beneficiary: UpdateBeneficiaryInput, $bankAccount: AddOrUpdateBankAccountInput, $members: [AddOrUpdateMemberInput!], $salesInteraction: CreateSalesInteractionInput!) {
  updateSalesInteractionPolicyWithMembers(id: $id, funeral: $funeral, beneficiary: $beneficiary, bankAccount: $bankAccount, members: $members, salesInteraction: $salesInteraction) {
    payAtNumber
    policyNumber
    id
  }
}
`

const UPDATE_FUNERAL = gql`
mutation UpdateFuneral($id: String!, $input: UpdateFuneralInput!) {
  updateFuneral(id: $id, input: $input) {
    payAtNumber
    policyNumber
    id
  }
}
`

const config = {
  name: 'funeralquote',
  fields: {
    'funeral.coverAmount': {
      type: 'select',
      label: 'Coverage Amount',
      options: [10000, 20000, 30000, 40000, 50000, 60000].map(a => ({
        value: a, label: Numeral(a).format('$ ##,###0.')
      })),
      disabled: false,
      validate: (value) => {
        if (!value) {
          return 'Amount is required'
        }
      }
    },
    'paymentMethod': {
      type: 'select',
      label: 'Payment Method',
      options: CLI_PAYMENT_TYPES.map(e => ({ label: e, value: e })).sort((a, b) => (a <= b ? -1 : 1))
    },
    'funeral.paymentDueDay': {
      type: 'text',
      label: 'Payment Day ',
      validate: (value) => {
        if (value && isInteger(value)) {
          return 'Must be a day of the month'
        } else if (value && (value > 31 || value < 1)) {
          return 'Must be a day of the month'
        }
      }
    },
  }
}

const requestDoc = async (id, template) => {
  const accessToken = localStorage.getItem('accessToken');
  const response = await fetch(`${API_URL}funeral/${id}/${template}`, { headers: { 'Authorization': accessToken ? `JWT ${accessToken}` : null } })
  return await response.blob()
}

const findDocumentOfType = (documents, type) => {
  if (documents) {
    const doc = documents && documents.filter(d => (d.type && d.type.toUpperCase() === type)).slice(-1)[0]
    return doc ? { url: doc.file.url, id: doc.id, name: doc.name } : null
  }
  else return null
}

const friendlyType = (type) => type ? type.replace(/_/g, "").replace(/AND/g, "+").replace(/WITH/g, "+") : ''

let FormLayout = (layoutProps) => {
  const { history, data, id, cancel, mode, changeFieldValue, ...props } = layoutProps
  const { funeral } = data
  // console.log('Quote FormLayout props', layoutProps)
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [quoteAmount, setQuoteAmount] = useState(props ? props.quoteAmount : 0)
  const [showBank, setShowBank] = useState(false)
  const [reason, setReason] = useState()
  const [fileId, setFileId] = useState()
  const [text, setText] = useState()
  const [cancelUnCancel, setCancelUnCancel] = useState()
  const [disablePDF, setDisablePDF] = useState(false)
  const [spin, setSpin] = useState(false)
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [livesCovered, setLivesCovered] = useState()
  const [coverAmount, setCoverAmount] = useState()

  useEffect(() => {
    setLivesCovered(covered(data && data.funeral ? data.funeral.livesCovered : undefined))
    // setFuneral(data && data.funeral ? data.funeral : {})
  }, [data]);

  const covered = (lives) => lives && lives.map(m => {
    console.log('covered is ', m)
    if (m.memberType !== 'POLICY_HOLDER' && (m.state.indexOf('NO_COVER') > -1 || m.state === ' ')) {
      m.state = ' '
    }
    else {
      m.state = 'YES'
    }
    if (m.memberType === 'POLICY_HOLDER') {
      m.sortOrder = 0
    }
    else if (m.memberType === 'SPOUSE') {
      m.sortOrder = 2
    }
    else if (m.memberType === 'PARENT') {
      m.sortOrder = 3
    }
    else if (m.memberType === 'CHILD') {
      m.sortOrder = 4
    }
    else {
      m.sortOrder = 5
    }
    m.ageAtSignup = (m.idNumber && m.idType === 'rsa_id') ? getAgeFromID(m.idNumber, m.idType) : getAgeFromDOB(m.DOB)
    m.currentPremium = 0
    return m
  }).sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1)

  const updateFuneral = (input) => {
    props.client
      .mutate({
        mutation: UPDATE_FUNERAL,
        variables: {
          id,
          input
        },
        refetchQueries: [{ query: funeralInformation, variables: { id } }]
      })
  }

  function handleOnClick() {
    // Do more stuff with the click event!
    // Or, set isExpanded conditionally 
    setExpanded(!isExpanded);
  }

  const { paymentDueDay, paymentMethod, clientId, client, policyNumber, paymentBankAccount, easyPayNumber } = funeral || {};
  const { acceptedTerms } = client || {}

  if (!(funeral && funeral.clientId && livesCovered)) {
    return <ActivityIndicator />
  }

  return (<>
    <Card>
      <h2>Policy: {policyNumber}</h2>
      <br />

      <ClientInformation id={clientId} refetchQuery={funeralInformation} cancel={cancel} history={history} funeralPolicies={[funeral.id]} productId={funeral.id} />
      <br />
      {mode === 'add' && <>
        <h2>Freedom Product Information</h2>
      <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
        <DisplayItem label="Policy Type" value={friendlyType(funeral.policyType)} xs={4} />
          <DisplayItem label="Previous Cover" value={Numeral(funeral.coverAmount).format('$ ##,###0')} />
          <DisplayItem label="Previous Premium" value={Numeral(funeral.currentPremiumTotal).format('$ ##,###0.00')} />
        <DisplayItem label="PayMethod" value={paymentMethod ? paymentMethod : ' '} />
          {easyPayNumber && <DisplayItem type="easypay" clientId={clientId} label="EasyPay" value={easyPayNumber} />}
        </Grid>
      </>}
      {spin && <center><ActivityIndicator /></center>}

      <Card>
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={2} >
            <FormField noLabel name="funeral.paymentDueDay" />
          </Grid>
          <Grid item xs={2} >
            <FormField noLabel name="funeral.coverAmount" />
          </Grid>
          <Grid item xs={2} >
            {mode === 'add' ? 'Quote' : 'Premium'}: {quoteAmount ? Numeral(quoteAmount).format('$ ##,###0.00') : ' '}
          </Grid>
        </Grid>
      </Card>
      <br />

      <SubmitFormButton renderSubmitButton={({ invalid, submit, submitting }) => (
        <Grid container className={classes.center}>
          {!acceptedTerms && <Grid item xs={2} >
            <EditModal Content={Terms} noModalButtons text="Accept T&C" heading="Terms & Conditions" dialogText="Has the client accepted?" id={clientId} />
          </Grid>}
          <Grid item className={classes.button}>
            <Button fullWidth color='secondary' variant='contained' onClick={cancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item className={classes.button}> <>
            <Button fullWidth variant='contained' color='primary' disabled={invalid || submitting || spin} onClick={() => { changeFieldValue('livesCovered', livesCovered); setShowConfirm(true) }}>
              {mode === 'add' ? 'Accept' : 'Save'}
            </Button>
            <Confirm open={showConfirm}
              title={mode === 'add' ? 'Create Policy' : 'Update Policy'}
              description={mode === 'add' ? 'Press OK to create a new policy' : 'Press OK to update the policy'}
              onOk={() => { setSpin(true); setShowConfirm(false); submit() }}
              onCancel={() => setShowConfirm(false)} />
          </>
          </Grid>
        </Grid>)} />
      <FieldValue noLabel name="funeral.coverAmount" renderField={({ input, value }) => {
        console.log('coverAmount field value', input.value || value)
        if (coverAmount !== (input.value)) {
          setCoverAmount(input.value || value)
        }
        return (<QuoteLivesCovered funeral={funeral} livesCovered={livesCovered} setLivesCovered={setLivesCovered} history={history} coverAmount={input.value || value} setQuoteAmount={setQuoteAmount} mode={mode} />
        )
      }
      } />
      <br />
      <Beneficiaries funeral={funeral} history={history} />
      <br />

      <div className="collapsible">
        <div className="header" {...getToggleProps({ onClick: handleOnClick })}>
          <h2>Payment Details {isExpanded ? <RemoveCircleOutlineIcon className={classes.button} /> : <AddCircleOutlineIcon className={classes.button} />}</h2>
        </div>
        <div {...getCollapseProps()}>
          <BankAccount {...funeral} paymentBankAccount={paymentBankAccount} paymentDay={funeral.paymentDueDay} clientId={clientId} productId={funeral.id} productType="FUNERAL" amount={funeral.currentPremiumTotal} updateProduct={updateFuneral} />
        </div>
      </div>


      <br />
      <ClientNotes clientId={clientId} type="Client" productId={funeral.id} query={funeralInformation} variables={{ id: funeral.id }} />
      <br />

    </Card>
  </>
  );
}

const sanitizeMembers = (members) => {
  const coveredMembers = members.filter((m => m.state === 'YES')).map(member => {
    return (
      member.idType === 'rsa_id' && member.idNumber
        ? ({ ...member, DOB: moment(getDOBFromID(member.idNumber, member.idType)).valueOf() })
        : ({ ...member, DOB: member.DOB && moment(member.DOB).valueOf() })
    )
  })

  coveredMembers.forEach(member => {
    delete member.state
    delete member.id
    delete member.sortOrder
  })

  // make sure that the client is the first member
  const orderedMembers = []
  coveredMembers.forEach(m => {
    let i = 1
    if (m.memberType === 'POLICY_HOLDER') {
      orderedMembers[0] = m
    } else {
      orderedMembers[i] = m
      i++
    }
  })
  return orderedMembers
}

function getPolicyType(members) {
  const spouse = members.filter(m => m.memberType === "SPOUSE")
  const children = members.filter(m => m.memberType === "CHILD")
  const parents = members.filter(m => m.memberType === "PARENT")
  const extended = members.filter(m => m.memberType === "EXTENDED")

  //console.log('get policy type members are ', spouse, children, parents )

  let policyType

  if (spouse.length > 0) {
    if (children.length > 0) {
      policyType = "COUPLE_WITH_CHILDREN"
    } else {
      policyType = "COUPLE"
    }
  } else {
    if (children.length > 0) {
      policyType = 'SINGLE_WITH_CHILDREN'
    } else {
      policyType = 'SINGLE'
    }
  }

  if (parents.length > 0 && policyType.indexOf('CHILDREN') === -1) {
    policyType += "_WITH_PARENTS"
  } else if (parents.length > 0) {
    policyType += "_AND_PARENTS"
  }

  if (extended.length > 0 && parents.length === 0 && policyType.indexOf('CHILDREN') === -1) {
    policyType += "_WITH_EXTENDED_FAMILY"
  } else if (extended.length > 0) {
    policyType += "_AND_EXTENDED_FAMILY"
  }

  return policyType
}

const sanitizeBeneficiary = (beneficiaries) => {
  if (beneficiaries && beneficiaries.length > 0) {
    const { payTo, ...rest } = beneficiaries[0]
    const { id, ...beneficiary } = payTo
    return beneficiary
  }
  else return {}
}


let Quote = (props) => {
  let { cancel, id, mode, funeral, data, history, client } = props

  console.log('Quote props', props)

  const { loading, error, me } = data | {}
  if (loading) {
    return <ActivityIndicator />
  }

  const createNote = (input) => {
    client
      .mutate({
        mutation: CREATE_NOTE,
        variables: {
          input: { ...input },
        }
      })
  }

  let initialValues
  if (mode === 'add') {
    id = null
    initialValues = { funeral }
  }

  return (
    <div>
      <GraphQLFormProvider
        mode={mode}
        id={id}
        {...config}
        initialValues={initialValues}
        initialValuesFromData={data => data}
        addMutation={createSalesInteractionPolicyWithMembers}
        updateMutation={UPDATE_FUNERAL}
        query={funeralInformation}
        afterSubmit={({ data }) => {
          console.log('afterSubmit', data)
          if (data.createSalesInteractionPolicyWithMembers) {
            console.log('afterSubmit new policy is ', data.createSalesInteractionPolicyWithMembers.policyNumber)
            createNote({
              associatedWith: [{ type: 'Client', id: data.createSalesInteractionPolicyWithMembers.clientId }, { type: 'Funeral', id: data.createSalesInteractionPolicyWithMembers.id }],
              text: `New Policy ${data.createSalesInteractionPolicyWithMembers.policyNumber} created from Freedom policy ${funeral.policyNumber}`
            })
            history.push(`/funeral/${data.createSalesInteractionPolicyWithMembers.id}/edit`)
          }
          else {
            console.log('afterSubmit policy is', data)
            cancel()
          }
        }}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(layout) => {
          if (mode === 'add') {
            let props = { ...layout, data: initialValues }
            return <FormLayout {...props} mode={mode} cancel={cancel} history={history} />
          }
          else return <FormLayout {...layout} mode={mode} cancel={cancel} history={history} />
        }}
        DisplayFormLayout={FormLayout}
        refetchQueries={[{ query: funeralInformation, variables: { id: funeral.id } }]}
        keepInitialValues

        mapInputVariables={input => {
          console.log('mapInputVariables  ', input);
          const { funeral } = input;
          const { id, policyNumber, livesCovered, beneficiaries, paymentBankAccount, debitOrderId, policyStatusChanges, payments, client, currentPremiumTotal, numFullPayments, createdAt, createdBy, accountingIdentifier, pricingVersion, versionOnDate, state, doMandate, signupSalesInteraction, ...funeralInput } = funeral;
          const { id: bankId, idNumberMatch, nameMatch, phoneMatch, accountExists, ...bankAccount } = paymentBankAccount || {};

          if (mode === 'add') {
            const values = ({
              funeral: { paymentMethod: "DEBI_CHECK", policyType: getPolicyType(livesCovered), ...funeralInput },
              bankAccount: bankAccount && bankAccount.accountNo ? bankAccount : null,
              members: sanitizeMembers(livesCovered),
              beneficiary: sanitizeBeneficiary(beneficiaries),
              salesInteraction: {
                salesPersonId: me ? me.id : undefined,
                timestamp: new Date().getTime(),
                result: 'SIGNEDUP',
              }
            });
            console.log('mapInputVariables transformed=', values);
            return values;
          }
          else {
            const { clientId, livesCovered, easyPayNumber, ...rest } = funeralInput
            console.log('mapInputVariables transformed=', rest);
            return { input: rest }
          }
        }}
      // defining the input to use the input needed in the update mutation.
      />
    </div>
  )
}

export default graphql(getSalesPerson)(Quote)