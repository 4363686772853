/**
 * Original filename: "model.js"
 * Member Model Class and Types.
 * Part of the  application.
 *
 * First Create: 2017/12/23 4:05 PM
 * Added By: user
 **/
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {nil} from "../Helpers";
import { Alert, Card } from "../components/generic";

export const newBaseModel = {
    id: null,
    displayName: null
};

export const defaultFormatter = ( input ) => {
    let jsonObject = {};
    Object.keys(input).map( ( property ) => {
        if(property.indexOf("_") === 0){
            //use getter
            if(property instanceof moment)
                jsonObject[property.substring(1,property.length)]=moment(input[property]).unix();
            else jsonObject[property.substring(1,property.length)]=input[property];
        }
    });

    return jsonObject;
};


/**
 * Function that reports an alert object using report data
 * @param reportData
 * @param children
 * @returns {XML}
 */
const defaultErrorReport  = ( reportData = new ValidationReport(), children = null ) => {
    let errorPanel = null;
    let warningPanel = null;
    let accordion = null;

    if(reportData.errors.length > 0){
        errorPanel = (
            <Panel header={'Validation Errors'} eventKey={'errors'}>
                <ol>{reportData.errors.map((error) =>
                    (<li>{error.message || error}</li>))}
                </ol>
            </Panel>
        )
    }

    if(reportData.warnings.length > 0){
        warningPanel = (
            <Card title='Validation Warnings' >
                <ol>{reportData.warnings.map((warning) =>
                    (<li>{warning.message || warning}</li>))}
                </ol>
            </Card>
        )
    }

    if( nil( errorPanel ) === false || nil( warningPanel ) === false) {
        accordion = (
            <Panel>
                {errorPanel}
                {warningPanel}
            </Panel>
        );
    }

    return (
        <Alert message={`${reportData.title} ${reportData.message}`} >
        {accordion}
        {children}
    </Alert>
)};

/**
 * Validation Report class used to collate object validation data
 */
export class ValidationReport {

    constructor() {
        this._show = false;
        this._bsStyle = "default";
        this._title = "Report Title";
        this._message = "This contains your error report";
        this._valid = true;
        this._errors = [];
        this._warnings = [];
        this._topics = [];
    }


    get show() {
        return this._show;
    }

    set show(value) {
        this._show = value;
    }

    get valid() {
        return this._valid;
    }

    set valid(value) {
        this._valid = value;
    }

    get errors() {
        return this._errors;
    }

    set errors(value) {
        this._errors = value;
    }

    get warnings() {
        return this._warnings;
    }

    set warnings(value) {
        this._warnings = value;
    }

    get topics() {
        return this._topics;
    }

    set topics(value) {
        this._topics = value;
    }


    get bsStyle() {
        return this._bsStyle;
    }

    set bsStyle(value) {
        this._bsStyle = value;
    }

    render(renderer = defaultErrorReport){
        return this.show === true ? renderer(this) : null;
    }
}

export const defaultValidator = ( input, report = new ValidationReport(), componentTypeName = 'BaseModel') => {
    if(input instanceof BaseModel){
        let checkResult = PropTypes.checkPropTypes(input.propTypes, input, componentTypeName)
        if(checkResult !== null){
            report.valid = false;
            report.errors.push(checkResult);
        }
    }
    return report;
};

export class BaseModel {
    constructor(props = newBaseModel) {
        props = {...newBaseModel, ...props};
        this._id = props.id;
    }


    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    validationReport( validator = defaultValidator ) {
        return validator(this);
    }

    toJSON(formatter = defaultFormatter){
        return formatter(this);
    }

    toString(){
        return `${this.constructor.name}: [${this.id}]`
    }

    static gqlConfig = {
        list: null,
        query: null,
        update: null,
        create: null,
        search: null
    };

    static propTypes = {
        id: PropTypes.string.isOptional
    };

    static defaultProps = {

    };


    /**
     * Used for enum to string representations. Enums must be object that implements a title and key property
     * @param EnumObject
     * @param Key
     * @returns {String | null}
     */
    static makeReadableEnum(EnumObject, Key) {
        if ( nil(EnumObject) === true ) return null;
        else return nil( EnumObject[Key] ) === true ? 'NOT READABLE' : EnumObject[Key].title;
    }
}