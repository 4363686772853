import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { HashRouter as Refresh, Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import App from './App.js';
import createStore from './store'

const history = createBrowserHistory();
const store = createStore();

ReactDOM.render(
  <Refresh>
  <Router history={history}>
    <Provider store={store}>
        <Switch>
          <Route path="/" name="Home" component={App} />
      </Switch>
    </Provider>
  </Router>
  </Refresh>
,
  document.getElementById('root') || document.createElement('div') // for testing purposes
);
