import React, { useState } from 'react'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import { touch } from 'redux-form'
import { useConfirm } from "material-ui-confirm";
import { makeStyles } from '@material-ui/core/styles';
import SubmitFormButton from 'react-form-helper/dist/SubmitFormButton';
import { GraphQLFormProvider, FormField, FieldValue } from 'react-form-helper'
import { withRouter } from 'react-router-dom'
import { ActivityIndicator, TinyMCE } from '../../generic'
import { Card, Grid, Button, TextInput, Text, Switch, renderInput, renderDisplay } from '../../generic'
import { AuthConsumer } from 'react-auth-helper';
import { prettyText } from '../../../lib/utils'

import PRODUCT_TYPES from '../../../variables/productTypes'

const useStyles = makeStyles((theme) => ({
  end: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingTop: 7,
    paddingBottom: 7
  },
}));

// const capitalize = (s) => {
//   if (typeof s !== 'string') return ''
//   let cap = s.toLowerCase()
//   return cap.charAt(0).toUpperCase() + cap.slice(1)
// }


const UNIQUE = gql`query Template($name: String!){
  uniqueTemplate(name: $name)
}`;

const GET_TEMPLATES = gql`query Templates {
  communicationtemplates {
    id
    name
    friendlyName
    description
    template
    templateType
    type
    paramKeys
    paramKeys
  }
}`;

const fields = (templateOptions = [{ value: 'NONE', label: 'None' }]) => ({
  name: {
    type: 'text',
    label: 'Unique Name*',
    helperText: "Please enter a unique name",
    filter: 'fuzzyText',
    validate: (value) => {
      if (!value) {
        return 'name is Required'
      }
      else if (value.indexOf(' ') > -1) {
        return 'No spaces allowed in the name'
      }
    },
    addOnly: true,
  },
  friendlyName: {
    type: 'text',
    label: 'Name *',
    validate: (value) => {
      if (!value) {
        return 'name is Required'
      }
    },
    EditOnly: true,
  },
  description: {
    type: 'text',
    label: 'Description',
  },
  subject: {
    type: 'text',
    label: 'Email Subject*',
    validate: (value, Grid) => {
      if (Grid.type === 'EMAIL' && !value) {
        return 'Subject is required for emails'
      }
    }
  },
  from: {
    type: 'email',
    label: 'From Email Address*',
    validate: (value, Grid) => {
      if (Grid.type === 'EMAIL' && !value) {
        return 'From address is required for emails'
      }
    }
  },
  headerId: {
    type: 'select',
    label: 'Header Template',
    options: templateOptions,
  },
  footerId: {
    type: 'select',
    label: 'Footer Template',
    options: templateOptions,
  },
  type: {
    type: 'select',
    options: ['EMAIL',
      'PHONE',
      'WEB_PAGE',
      'EMPLOYEE_CONTACT',
      'POSTAGE_LETTER'].map(e => ({ value: e, label: e })),
    label: 'Delivery Type *',
  },
  product: {
    type: "select",
    options: PRODUCT_TYPES,
    label: "Products"
  },
  templateType: {
    type: 'select',
    options: ['EJS', 'HANDLEBARS'].map(e => ({ value: e, label: e })),
    label: 'Template Type *',
  },
  template: {
    type: 'text',
    label: 'Template',
    validate: (value) => {
      if (!value) {
        return 'Text is Required'
      }
    }
  },
  isReadOnly: {
    label: "Disable Edit",
    type: 'boolean'
  },
  paramKeys: {
    type: 'fieldarray',
    label: 'Parameters',
  }

})


const config = {
  name: 'htmlTemplate',
  query: gql`
    query TemplateData($id: String!) {
      communicationtemplate(id: $id){
        id
        name
        friendlyName
        description
        from
        subject
        type
        product
        templateType
        template
        headerId
        footerId
        isReadOnly
        paramKeys
      }
    }`,
  updateMutation: gql`
    mutation UpdateCommunicationTemplate($id: String!, $input: UpdateCommunicationTemplateInput!) {
      updateCommunicationTemplate(id: $id, input: $input){
        id
        name
        friendlyName
        description
        from
        subject
        type
        product
        templateType
        template
        headerId
        footerId
        isReadOnly
        paramKeys
    }
  }`,
  addMutation: gql`
  mutation CreateCommunicationTemplate($input: CreateCommunicationTemplateInput!){
    createCommunicationTemplate(input: $input){
        id
        name
        friendlyName
        description
        from
        subject
        type
        product
        templateType
        template
        headerId
        footerId
        isReadOnly
        paramKeys
    }
  }
  `
}

function FormLayout(props) {
  const classes = useStyles()
  const confirm = useConfirm();
  const { setTouched, formName, history, mode, changeFieldValue, data, fields } = props
  const { description } = data && data.communicationtemplate || {}
  const [isReadOnly, setIsReadOnly] = useState()
  const [body, setBody] = useState()

  console.log('FormLayout', props)
  const confirmCancel = (history) => {
    confirm({
      title: 'Cancel without Save?',
      description: "Are you sure you want to cancel this?",
    })
      .then(() => history.push(`/communication-templates`))
  }

  const confirmSave = (submit) => {
    confirm({
      title: 'Save the template?',
      description: "This will replace the current template. Are you sure?",
    })
      .then(() => submit())
  }

  const onDisabledClick = () => {

  }

  return (<div style={{ width: '100%' }}>
    <Card>
      <h2>{mode === 'add' ? 'New Web Page Template' : `Edit ${description}`}</h2>
      <br />
      <Grid container justifyContent='flex-start' alignItems='flex-start' spacing={1}>
        <Grid item xs={3}>
          <FieldValue name='name' key="name" renderField={({ input }) => {
            const { value, onChange } = input
            const formatChange = (e) => {
              let otherValue = prettyText(e.target.value.replace(/_/g, ' '), ' ')
              changeFieldValue('friendlyName', otherValue)
              changeFieldValue('description', otherValue)
              onChange(e.target.value.replace(/ /, '_'), '_')
            }
            return <>
              <TextInput fullWidth required label="Unique name" helperText="Please enter a unique name" value={value} onChange={formatChange} />
            </>
          }} />
        </Grid>
        <Grid item xs={3}><FormField key="description" name="description" noLabel /></Grid>
        <Grid item xs={2}><FormField key="templateType" name="templateType" noLabel /></Grid>
        <Grid item xs={2}><FormField key="type" name="type" noLabel /></Grid>
        <Grid item xs={2}><FormField key="subject" name="subject" noLabel /></Grid>
        <Grid item xs={2}><FormField key="from" name="from" noLabel /></Grid>
        <Grid item xs={2} ><FormField key="product" name="product" noLabel /></Grid>
        <Grid item xs={2} ><FormField key="headerId" name="headerId" noLabel /></Grid>
        <Grid item xs={2} ><FormField key="footerId" name="footerId" noLabel /></Grid>
      </Grid>
      <br />
      <FormField key="paramKeys" name="paramKeys" noLabel />
      <br />
      <SubmitFormButton renderSubmitButton={({ invalid, submit, submitting, hasChanges }) => (
        <Grid container justifyContent='flex-end' alignItems='flex-start' spacing={2} className={classes.end}>
          <AuthConsumer>
            {({ inGroup }) => {
              if (inGroup('template-super-users')) {
                return <Grid xs={2} >
                  <FieldValue name='isReadOnly' key='isReadOnly' renderField={({ input }) => {
                    let { value, onChange } = input
                    const switchChange = (v) => {
                      setIsReadOnly(v)
                      onChange(v)
                    }
                    return <>
                      Lock text {' '}
                      <Switch checked={value} onChange={switchChange} />
                    </>
                  }} />
                </Grid>
              }
              else return <div />
            }}
          </AuthConsumer>
          <Grid item><Button color="secondary" onClick={() => { mode === 'edit' && hasChanges ? confirmCancel(history) : history.push("/communication-templates") }}>Cancel</Button></Grid>
          <Grid item onClick={() => {
            if ((invalid || submitting)) {
              console.log('set touched to ', config.fields, Object.keys(fields))
              setTouched(Object.keys(fields));
            }
          }}>
            <Button color="primary" disabled={invalid || submitting} onClick={() => { mode === 'edit' && hasChanges ? confirmSave(submit) : submit(); }}>Save</Button>
          </Grid>
        </Grid>
      )
      } />

      < br />
      {`Note: Define EJS variables as <%= XXX %> and Handelbars as {{ xxx }} (XXX can be: firstNames, surname, idNumber, policyNumber, premium, coverAmount, livesCovered, beneficiary, etc)`}
      <br />
      <FieldValue name={`template`} renderField={({ input }) => {
        const { value, onChange } = input
        const bodyChange = (v) => {
          setBody(v)
          onChange(v)
        }
        return <TinyMCE value={value} isReadOnly={isReadOnly} onChange={bodyChange} />
      }} />

      <a href="https://word2cleanhtml.com/" target="_blank" rel="noreferrer">Try this for good Word copying </a>

    </Card >
  </div>);
}

const mapDispatchToProps = (dispatch, { formName }) => ({
  setTouched: fieldNames => dispatch(touch(formName, ...fieldNames))
})

// Layout = withRouter(Layout) 
FormLayout = connect(() => ({}), mapDispatchToProps)(FormLayout)

let EjsTemplate = ({ id, cancel, history, data }) => {

  if (data.loading) {
    return <ActivityIndicator />
  }

  const { communicationtemplates } = data
  const templates = communicationtemplates && communicationtemplates.filter(temp => ['EJS', 'HANDLEBARS'].includes(temp.templateType) && temp.description && (temp.description.toLowerCase().includes('header') || temp.description.toLowerCase().includes('footer'))).sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)

  let templateOptions = templates.map(a => ({ "value": a.id, "label": a.description }))
  templateOptions.push({ "value": null, "label": 'None' })
  // console.log('EjsTemplate ', templateOptions)

  return <GraphQLFormProvider
    mode={id ? "edit" : "add"}
    initialValues={{ templateType: "EJS", type: "EMAIL", from: "insurance@meerkat.co.za" }}
    initialValuesFromData={data => {
      // console.log('initialValuesFromData ', data)
      const { communicationtemplate } = data || {}
      const { paramKeys, ...rest } = communicationtemplate || {}
      return { ...rest, paramKeys: paramKeys && paramKeys.join(',') }
    }}
    id={id}
    {...config}
    fields={fields(templateOptions)}
    renderDisplay={renderDisplay}
    renderInput={renderInput}
    InputFormLayout={(props) => <FormLayout  {...props} formName={config.name} cancel={cancel} history={history} />}
    DisplayFormLayout={FormLayout}
    mapInputVariables={async (input) => {
      if (input.paramKeys) {
        const arr = input.paramKeys.replace(/ /g, '').split(',')
        input.paramKeys = arr
      }
      // console.log('mapInputVariables ', { ...input })

      return { input }
    }}
    afterSubmit={() => history.push("/communication-templates")}
  />

}
EjsTemplate = graphql(GET_TEMPLATES)(EjsTemplate)
export default EjsTemplate
