import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { EditModal } from './FuneralInfo'
import Member from './Member'
import { DisplayItem } from '../generic';
import { capitalize } from '../../lib/utils'
import { ActivityIndicator } from '../generic'
import Beneficiary from './Beneficiary';
import 'numeral/locales/en-za.js'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    paddingBottom: 14,
  },
  table: {
    minWidth: 650,
    fontSize: 15,
  },
  head: {
    paddingTop: 7,
    paddingBottom: 7,
    fontWeight: 400,
  },
  cell: {
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 13,
    alignContent: 'flex-start'
  }
});

export const BENEFICIARIES = gql`
  query Funeral($id: String!) {
    funeral(id: $id) {
      id
      beneficiaries {
        id
        payoutType
        percentage
        payToId
        payTo {
            id
            firstNames
            surname
            idNumber
            idType
            cellNumbers
            email
            relationship
          }
        bankAccount {
          id
          bankName
          accountNo
          associatedWith {
            id
            type
            reference
          }
        }
      }
    }
  }`

function Beneficiaries({ funeral, history }) {
  const classes = useStyles();
  const { data, loading, error } = useQuery(BENEFICIARIES, { variables: { id: funeral.id } }) || {}

  if (loading) {
    return <ActivityIndicator />
  }

  const { funeral: funeralData } = data || {};
  const { beneficiaries } = funeralData || [];
  console.log('beneficiaries', beneficiaries)

  return (
    <>
      <EditModal Content={Beneficiary} fontSize={18} fontWeight={400} noButton heading="Define or Change the Beneficiary" text="Beneficiary" mode={beneficiaries ? "edit" : "add"} id={funeral.id} memberId={beneficiaries && beneficiaries[0] && beneficiaries[0].payTo ? beneficiaries[0].payTo.id : undefined} beneficiaries={beneficiaries}
        icon={(beneficiaries && beneficiaries[0]) ? 'edit' : 'add'}
      />

      {(!(beneficiaries && beneficiaries[0] && beneficiaries[0].payTo )) ?
        <div>No beneficiary defined</div> :
        <>
          <TableContainer size='small' component={Paper}>
            <Table className={classes.table} aria-label="lives table">
              <TableHead >
                <TableRow>
                  <TableCell className={classes.head}>Name</TableCell>
                  <TableCell className={classes.head}>ID</TableCell>
                  <TableCell className={classes.head}>Relationship</TableCell>
                  <TableCell className={classes.head}>Mobile</TableCell>
                  <TableCell className={classes.head}>Email</TableCell>
                  <TableCell className={classes.head}>Bank Account</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {beneficiaries && beneficiaries.map((row) => {
                  const { id, payTo, bankAccount } = row
                  const { firstNames, surname, idNumber, idType, cellNumbers, email, relationship } = payTo || {}
                  const { bankName, accountNo } = bankAccount || {}
                  if (!payTo) {
                    return <div />
                  }
                  else return (
                    <TableRow key={id} >
                      <TableCell className={classes.cell} component="th" scope="row"><EditModal Content={Member} fontSize={13} fontWeight={400} noButton heading={`Edit Beneficiary`} size="xsmall" mode='edit' text={`${firstNames} ${surname}`} funeralId={funeral.id} id={payTo.id} /></TableCell>
                      <TableCell className={classes.cell}>{
                        idNumber
                          ? history
                            ? <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => { history.push(`/products/${idNumber}`) }}> {idNumber} </span>
                            : idNumber
                          : ''}
                      </TableCell>
                      <TableCell className={classes.cell}>{relationship}</TableCell>
                      <TableCell className={classes.cell}>{cellNumbers && cellNumbers[0] ? <DisplayItem fontSize={14} borderBottomStyle='none' type='mobile' value={cellNumbers[0]} clientId={funeral.clientId} /> : ' '}</TableCell>
                      <TableCell className={classes.cell}>{email}</TableCell>
                      <TableCell className={classes.cell}>{bankName} {accountNo}</TableCell>
                    </TableRow>
                  )
                }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>}
    </>
  );
}

export default Beneficiaries
