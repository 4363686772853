import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
import { DisplayFile, Card, Grid } from '../generic';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    paddingBottom: 14,
  },
  table: {
    minWidth: 650,
    fontSize: 14,
  },
  head: {
    paddingTop: 7,
    paddingBottom: 7,
    fontWeight: 400,
  },
  cell: {
    paddingTop: 4,
    paddingBottom: 4,
  }
});

const CheckRow = ({ checked, name }) => (
  <Grid container spacing={1}>
    <Grid item style={{ color: checked ? 'green' : 'red' }} xs={12}>
      <i className={`fa fa-${checked ? 'check' : 'times'}-circle`} />
      &nbsp;
      {name}
    </Grid >
  </Grid >
);

function OutstandingRequirements({ outstandingRequirements, forObligations, amount, amountPaid }) {
  const classes = useStyles();
  const [outstanding, setOutstanding] = useState(outstandingRequirements && outstandingRequirements.length > 0);

  console.log('OutstandingRequirements', forObligations, outstandingRequirements);
  if (!outstanding) {
    return (  
      <h2>All Requirements Fulfilled</h2>
    )
  } 
  else { 
    return (
      <Card>
      <h2>Outstanding Requirements</h2>

      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={8}> <Card>
          {outstandingRequirements.map((r, i) => (
            <CheckRow key={r + i} checked={false} name={r} />
          ))}
        </Card>
        </Grid>
      </Grid>
    </Card>
  );}
}

export default OutstandingRequirements
