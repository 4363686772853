import React, { useState, useEffect } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import useCollapse from 'react-collapsed';
import matchSorter from 'match-sorter'
import gql from 'graphql-tag';
import { withApollo, graphql, useQuery } from 'react-apollo';
import { ActivityIndicator, Button, Select, DisplayFile, MaterialTable, Modal, Grid } from '../../generic'
import moment from 'moment'
import { EditClientDocument } from './'
import { DOCUMENT_TYPES } from '../../../variables/documentTypes'
import { pdf } from '../../../assets/images'

const useStyles = makeStyles({
  button: {
    '&: hover': {
      backgroundColor: '#f00f15',
      color: '#fff',
    }
  }
})

export const CLIENT_DOCUMENTS = gql`
	query ClientDocuments( $id: String! ) {
		client( id: $id ) {
      id
      firstNames
      surname
      idNumber
      documents {
        id
        name
        type
        description
        file {
          id
          url
          contentType
          name
        }
        verified
        verifiedBy {
          id
          description
        }
        updatedAt
      }
    }
  }`;

  export const GROUP_PRODUCT_DOCUMENTS = gql`
	query GroupProductDocuments( $id: String! ) {
		groupproduct( id: $id ) {
      id
      documents {
        id
        name
        type
        description
        file {
          id
          url
          contentType
          name
        }
        verified
        verifiedBy {
          id
          description
        }
        updatedAt
      }
    }
  }`;

// Select for editing the document type
const SelectCell = ({ row }) => {
  const { original, index } = row
  const { id } = original || {}

  // We need to keep and update the state of the cell normally
  const [open, setOpen] = useState(false)

  const save = ({ type, verified, description, verifiedBy }) => {
    setOpen(false)
  }

  return (<>
    <a onClick={() => setOpen(true)} style={{ cursor: 'pointer' }} >
      <EditIcon style={{ fontSize: 14, color: '#404357' }} />
    </a>

    <Modal
      title='Edit Client Document'
      noButtons
      open={open}
      onClose={() => setOpen(false)}
      maxWidth='md'
    >
      <EditClientDocument id={id} cancel={() => setOpen(false)} save={(row) => save(row)} />
    </Modal>

  </>)
}

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {


  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = (e) => {
    // run mutation to change description
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return <input value={value} onChange={onChange} onBlur={onBlur} />;
}


// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-Select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

function getUniqueFiles(documents) {
  const uniqueFiles = {};
  return (documents || []).filter(doc => {
      if (!uniqueFiles[doc.file.id]) {
          uniqueFiles[doc.file.id] = true
          return true
      }
      return false
  })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

const showFooter = (footerGroups) => {
  let footers = 0
  footerGroups.map(group => (
    group.headers.map(column => {
      if (typeof column.Footer === 'string') footers++
    }))
  )
  return footers > 0
}

const isPdf = (name) => name && name.toLowerCase().indexOf('pdf') > -1
const isTiff = (name) => name && name.toLowerCase().indexOf('tif') > -1

const columns = [
  {
    Header: 'Description',
    accessor: 'description',
    filter: 'fuzzyText',
    //Cell: EditableCell
  },
  {
    Header: 'Type',
    accessor: 'type',
    Filter: SelectColumnFilter,
    filter: 'Select'
  },
  {
    Header: 'Valid',
    id: 'verified',
    accessor: d => { return (d.verified || d.verified === false ? d.verified.toString() : '-') },
    Filter: SelectColumnFilter,
    filter: 'Select'
  },
  {
    Header: 'Verified By',
    id: 'verifiedBy',
    accessor: 'verifiedBy.description',
    Filter: SelectColumnFilter,
    filter: 'Select'
  },
  {
    Header: 'Uploaded',
    id: 'updatedAt',
    accessor: d => d.updatedAt ? moment(d.updatedAt).format('DD-MM-YYYY') : '-'
  },
  {
    Header: 'Image',
    id: 'file',
    assessor: 'file',
    Cell: ({ clientInfo, row: { original } }) => {
      return original.file && original.file.id ? <DisplayFile fileId={original.file && original.file.id} isPdf={isPdf(original.name)} name={original.name} isTiff={isTiff(original.name)} noButtons={true} clientDocumentId={original.id} verified={original.verified} verifiedBy={original.verifiedBy && original.verifiedBy.description} noDelete={true} /> : ''
    },
  },
  {
    Header: ' ',
    id: 'edit',
    Cell: SelectCell,
    disableFilters: true
  },
]


let Documents = (props) => {
  const{ data, clientId, client } = props
  
  const classes = useStyles();
  const { client: clientDocumentData, groupproduct: groupProductDocumentData  } = data || {} 
  const clientDocs = clientDocumentData ? clientDocumentData : groupProductDocumentData
  const { documents, ...clientData } = clientDocs || []
  const [tableData, setTableData] = useState([])
  const [clientInfo, setClientInfo] = useState(false)
  const [open, setOpen] = useState(false)
  const [description, setDescription] = useState()
  const [documentType, setDocumentType] = useState()
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  useEffect(() => {
    if (documents && !groupProductDocumentData) {
      setTableData(documents.sort((a, b) => (a.id > b.id ? -1 : 1)))
    }
    if (documents && groupProductDocumentData) {
      setTableData(getUniqueFiles(documents).sort((a, b) => (a.id > b.id ? -1 : 1)))
    }
    if (data && data.client) {
      const { id, firstNames, surname, idNumber } = data.client
      setClientInfo({ id, firstNames, surname, idNumber })
    }
  }, [data]);

  useEffect(() => {
    if (tableData.length > 0) {
      setExpanded(true)
    }
  }, [tableData]);

  function handleOnClick() {
    // Do more stuff with the click event!
    // Or, set isExpanded conditionally 
    setExpanded(!isExpanded);
  }

  return (<>
    <div className="collapsible">
      <div className="header" {...getToggleProps({ onClick: handleOnClick })}>
        <Grid container spacing={1}>
          <Grid item xs={11} style={{ fontVariant: 'small-caps', fontSize: 21, fontWeight: 400 }}>
            Documents <Button style={{ marginLeft: 30 }} onClick={() => setOpen(true)}>New Doc</Button>
          </Grid>
          <Grid item xs={1} style={{ fontSize: 14, fontWeight: 400, color: 'blue' }} >
            {isExpanded ? 'Hide' : 'Show'}
          </Grid>
        </Grid>
      </div>
      <div {...getCollapseProps()} style={{ overflow: 'scroll' }}>
        {tableData.length === 0
          ? "No documents"
          : <MaterialTable
            columns={columns}
            data={tableData}
            clientInfo={clientInfo}
          />}
      </div>
    </div>

    <Modal
      title='Upload A New Client Document'
      noButtons
      open={open}
      onClose={() => setOpen(false)}
      maxWidth='md'
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
        <Select fullWidth name="documentType" label="Document Type" value={documentType} options={DOCUMENT_TYPES} onChange={(value) => {
            setDocumentType(value.target.value);
            setDescription(DOCUMENT_TYPES.filter(d => d.value === value.target.value)[0].label);
          }} />
        </Grid>
        <Grid item xs={6}>
          {documentType && <DisplayFile {...props} disabled={!description || !documentType} documentType={documentType} clientInfo={clientInfo} noDelete={true} displayIfValid={true} description={description}
            onChange={() => {
              setExpanded(true);
              setOpen(false)
            }} />}
        </Grid>
        <br />
      </Grid>
      <br />
      <br />
    </Modal>
  </>
  )
}

Documents = withApollo(Documents)

const ClientDocuments = (props) => {
  const runQuery = props.clientId ? CLIENT_DOCUMENTS : GROUP_PRODUCT_DOCUMENTS
  const {loading, data, error} = useQuery(runQuery, { variables: { id: props.clientId ? props.clientId : props.groupInfo.groupProductId } })
  if (loading || error) {    
    return <ActivityIndicator /> 
  } else {
  console.log('ClientDocuments data', data)
   return <Documents {...props} data={data} />
  }
}

export default withApollo(ClientDocuments)