import React from 'react'
import { Route } from 'react-router-dom'
import TargetList from './TargetList'

const Targets = () => (
  <div>
    <Route path="/targets" exact component={TargetList} />
  </div>
)

export default Targets
