import React from 'react'
import { TextInput, ImageGridSelect, DateField, BooleanField, Select, SelectField, Checkbox, Selector, DatePicker, Button, Grid, DisplayFile, Slider, Switch, Autocomplete, AutocompleteWithAdd, MobileField } from './index'

const YesNo = props => <Grid container justify='center' spacing={2}>
  <Button color="primary" onClick={() => props.input.onChange(true)}>Yes</Button>
  <div style={{ height: "7px" }} />
  <Button onClick={() => props.input.onChange(false)} >No</Button>
</Grid>


const ButtonSelectOptions = ({ field, input }) => {
  return <Grid>
    {field.options.map(option => <Button style={{ marginRight: "14px" }} onClick={() => input.onChange(option.value)}>{option.label}</Button>)}
  </Grid>
}

const renderInput = (type) => (props) => {
  const { required, input, fullWidth = true, field, label, disabled, meta, disableFuture, autoOk, variant = "outlined", options, InputProps, ...rest } = props

  if (type === "mobile") {
    return <MobileField fullWidth={fullWidth} variant={variant} InputProps={InputProps} required={required || (field && field.required)} error={meta.touched && !!meta.error} label={field && field.label || label} {...input} disabled={disabled || (field && field.disabled)} helperText={meta.touched && meta.error} />
  }
  if (type === "options") {
    return <ButtonSelectOptions field={field} input={input} />
  }
  if (type === "switch") {
    return <BooleanField  {...input} {...field} />
  }
  if (type === "yesno") {
    return <YesNo column={field.column} input={input} />
  }
  if (type === "multiselect") {
    const { onChange, ...rest } = input
    return <SelectField {...input} {...field} required={required || field.required} error={meta.touched && !!meta.error} disabled={disabled || field.disabled} helperText={meta.touched && meta.error} />
  }
  if (type === "select") {
    const { onChange, ...rest} = input
    return <Select
      {...rest}
      {...field}
      onChange={(e) => onChange(e.target.value)}
      required={required || field.required}
      error={meta.touched && !!meta.error}
      disabled={disabled || field.disabled}
      helperText={meta.touched && meta.error} />
  }

  if (type === "selector") {
    return <Selector label={field.label} required={required || field.required} {...input} options={field.options} error={meta.touched && !!meta.error} disabled={disabled || field.disabled} helperText={meta.touched && meta.error} />
  }
  if (type === "addselect") {
    console.log('automcomplete render', input, props)
    return <AutocompleteWithAdd {...field}  {...input} />
  }
  if (type === "autocomplete") {
    console.log('automcomplete render', input, props)
    if (field && field.options) {
      return <Autocomplete required={required || field.required} label={field.label} {...input} options={field.options} error={meta.touched && !!meta.error} disabled={disabled || field.disabled} helperText={meta.touched && meta.error} />
    }
    else return <TextInput fullWidth={fullWidth} required={required || (field && field.required)} error={meta.touched && !!meta.error} label={field && field.label} {...input} disabled={disabled || (field && field.disabled)} helperText={meta.touched && meta.error} />
  }
  if (type === "checkbox") {
    return <Checkbox label={field.label} {...input} />
  }
  if (type === "date") {
    return <DateField {...field} {...input} disableFuture={disableFuture || field.disableFuture} autoOk={autoOk || field.autoOk} required={required || field.required} error={meta && meta.touched && !!meta.error} disabled={disabled || field.disabled} />
  }
  if (type === "fileupload") {
    return <DisplayFile {...rest} fullWidth={fullWidth} label={field.label} onChange={input.onChange} value={input.value} prompt={field.prompt || field.label} accept={field.fileTypes} capture={field.capture} smallIcon={field.smallIcon} />
  }
  if (type === "slider") {
    return <Slider label={field.label} required={required || field.required} minValue={field.min} maxValue={field.max} step={field.step} value={input.value} onChange={input.onChange} startText={field.startText} endText={field.endText} error={meta.touched && !!meta.error} helperText={meta.touched && meta.error} />
  }
  if (type === "imagegridselect") {
    return <ImageGridSelect required={field && field.required} label={field.label} value={input.value} onChange={input.onChange} options={field.options} error={meta.touched && !!meta.error} disabled={disabled} helperText={meta.touched && meta.error} />
  }

  return <TextInput {...field} fullWidth={fullWidth} variant={variant} InputProps={InputProps} required={required || (field && field.required)} error={meta.touched && !!meta.error} label={field && field.label || label} {...input} disabled={disabled || (field && field.disabled)} helperText={meta.touched && meta.error} />
}

export default renderInput