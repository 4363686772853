import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import gql from 'graphql-tag'
import { makeStyles } from '@material-ui/core/styles';
import { GraphQLFormProvider } from 'react-form-helper'
import { isValidCellphoneNumber, getAgeFromDOB, getAgeFromID, isValidEmail, isValidRsaIdNumber, isInteger } from '../../lib/validations'
import { IdTypes, RELATIONSHIPS } from '../../variables'
import { renderInput, renderDisplay, ActivityIndicator, Grid, Select, } from '../generic';
import TextField from '@material-ui/core/TextField';
import { BuildLayout } from '../generic';
import { claimInformation } from './ClaimAdmin'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // justifyContent: 'space-evenly',
    paddingBottom: 7
  },
  footer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingBottom: 21
  },
  paper: {
    minWidth: '80%',
    maxWidth: '80%',
  },
  button: {
    minWidth: 100,
    maxWidth: 100,
    textAlign: 'center',
    marginRight: 14
  }
}));

const UPDATE_CLIENT = gql`
	mutation($input: UpdateClientInput!, $id: String!) {
		updateClient(id: $id, input: $input) {
      id
      firstNames
      surname
      idType
      passportExpiresDate
      email
      cellNumbers
		}
	}
`;

const GET_CLIENT = gql`
  query GetClientDetails( $filter: ClientFilter!) {
    clients(filter: $filter) {
      id
      firstNames
      surname
      idType
      idNumber
      email
      cellNumbers
		}
	}
`;

const UPDATE_CLAIM = gql`
	mutation($input: UpdateClaimInput!, $id: String!) {
		updateClaim(id: $id, input: $input) {
      id
		}
	}
`;

const config = {
  name: 'claimmember',
  fields: {
    clientId: {
      hide: true,
    },
    idNumber: {
      label: 'Id Number',
      type: 'text',
      label: 'Id Number',
      hide: true,
      validate: (value, row) => {
        if (!row.DOB && !value) {
          return 'Either ID Number or Date of Birth is required'
        }
        else if (row.idType && row.idType === 'rsa_id' && isValidRsaIdNumber(value)) {
          return 'ID Number is invalid'
        }
      }
    },
    idType: {
      type: 'select',
      options: IdTypes,
      hide: true,
      label: 'Nationality',
      validate: (value, row) => {
        if (!row.DOB && row.idNumber && !value) {
          return 'ID type is Required'
        }
      }
    },
    firstNames: {
      type: 'text',
      label: 'First Name *',
      nullOnly: false,
      validate: (value) => {
        if (!value) {
          return 'First name is Required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    },
    surname: {
      type: 'text',
      label: 'Last Name *',
      nullOnly: false,
      validate: (value) => {
        if (!value) {
          return 'Last name is required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    },
    'cellNumbers[0]': {
      type: 'text',
      label: 'Mobile Number',
      nullOnly: false,
      validate: (value) => {
        if (value && isValidCellphoneNumber(value)) {
          return 'enter a valid cell number'
        }
      }
    },
    email: {
      type: 'email',
      label: 'Email ',
      nullOnly: false,
      validate: (value) => {
        if (!value || isValidEmail(value)) {
          return 'Email not valid'
        }
      }
    },
    relationship: {
      label: "Relationship*",
      type: "select",
      memberTypes: ['BENEFICIARY'],
      options: RELATIONSHIPS.sort((a, b) => (a > b) ? 1 : -1).map(a => ({ value: a, label: a })),
      validate: (value, row) => {
        if (row.memberType === 'BENEFICIARY' && !value) {
          return 'Relationship is Required'
        }
      }
    },
  },
  query: gql`
    query MemberData($id: String!) {
      member(id: $id) {
        id
        firstNames
        surname
        idNumber
        idType
        clientId
        memberType
        relationship
      }
    }`,
  updateMutation: gql`
    mutation UpdateMember($input: UpdateMemberInput!, $id: String!) {
      updateMember(id: $id, input: $input) {
        id
        clientId 
      }
  }`,
  addMutation: gql`
  mutation CreateMember($input: CreateMemberInput!) {
    createMember(input: $input) {
      id
      clientId 
      firstNames
      surname
      idType
      idNumber
      email
      cellNumbers   
    }
  }`
}

const getClientValues = ({ idType, idNumber, client, setSpin, setMember, setClientId, changeFieldValue }) => {
  if (idNumber.length > 10) {
    setSpin(true)
    client
      .query({
        query: GET_CLIENT,
        variables: { filter: { idType, idNumber } }
      })
      .then(result => {
        setSpin(false)
        if (result.data.clients && result.data.clients[0]) {
          const { id, idType, firstNames, surname, email, cellNumbers, documents } = result.data.clients[result.data.clients.length - 1]
          setMember({ clientId: id, idType, idNumber, firstNames, surname, email, cellNumbers: cellNumbers[0], documents })
          setClientId(id)
        }
        else {
          changeFieldValue('idType', idType)
          changeFieldValue('idNumber', idNumber)
        }
      })
  }
}

const IdNumber = ({ setMember, client, setSpin, spin, setClientId, changeFieldValue }) => {
  const classes = useStyles();
  const [idNumber, setIdNumber] = useState()
  const [helperText, setHelperText] = useState('')
  const [idType, setIdType] = useState('rsa_id')

  const onChange = (e) => {
    setIdNumber(e.target.value)
    setHelperText('')
    if ((!idType || idType === 'rsa_id') && isValidRsaIdNumber(e.target.value)) {
      setHelperText('Invalid Id Number')
    }
    else {
      getClientValues({ idType, idNumber: e.target.value, client, setMember, setSpin, setClientId, changeFieldValue })
    }
  }

  return (<>
    <Grid item className={classes.paper}><Select
      disabled={spin}
      fullWidth id="idtypeselect"
      required value={idType} options={IdTypes} label='Identity'
      onChange={(e) => setIdType(e.target.value)} /></Grid>
    <Grid item className={classes.paper}><TextField fullWidth disabled={spin} id="idnumberinput" variant="outlined" required label="Id Number" name="idNumber" value={idNumber} onBlur={onChange} onChange={onChange} error={helperText.length === 0 ? false : true} helperText={helperText} /></Grid>
  </>
  )
}

const FormLayout = (props) => {
  let { mode, submit, cancel, apolloClient: client, changeFieldValue, formName } = props;
  const [spin, setSpin] = useState(false)
  const [clientId, setClientId] = useState(false)
  const [gotAllInfo, setGotAllInfo] = useState(false) // complete this

  const setMember = (input) => {

    if (!input) {
      return
    }

    Object.keys(input).map(f => {
      if (f === 'cellNumbers') {
        changeFieldValue(`cellNumbers[0]`, input[f])
      }
      else {
        changeFieldValue(`${f}`, input[f])
      }
    })
  }


  const fieldNames = Object.keys(config.fields).filter(f => !config.fields[f].hide)
  // allowNull = {!clientId} add logic here
  return <>
    <Grid container direction='column' justifyContent='center' alignItems='center' spacing={1}  >
      <IdNumber setMember={setMember} client={client} setSpin={setSpin} spin={spin} setClientId={setClientId} changeFieldValue={changeFieldValue} />
    </Grid>
    <br />
    {spin && <ActivityIndicator />}
    <BuildLayout formName={formName} fieldNames={fieldNames} fieldConfig={config.fields} direction='column' mode={mode} spacing={2} align='center' justify='center' cancel={cancel} submit={submit} allowNull={!clientId && gotAllInfo} />
  </>
}

let ClaimBeneficiary = (props) => {
  const { mode, history, claimId, cancel, afterSubmit, apolloClient: client } = props
  let { beneficiaries } = props

  const updateClient = (id, input) => {
    client
      .mutate({
        mutation: UPDATE_CLIENT,
        variables: {
          input: { ...input },
          id
        }
      })
  }

  const updateClaim = (claimId, props) => {
    // add the member as a beneficiary

    const { id: payToId, ...rest } = props
    const uuid = uuidv4()

    // let cleanBeneficiaries = []
    // if (beneficiaries) {
    //   cleanBeneficiaries = beneficiaries.map(b => {
    //     const { payTo, __typename, ...clean } = b
    //     return clean
    //   })

    //   cleanBeneficiaries.push({ payToId, id: uuid })
    // }
    // else {
    //   cleanBeneficiaries = [{ payToId, id: uuid }]
    // }

    client
      .mutate({
        mutation: UPDATE_CLAIM,
        variables: {
          input: {
            beneficiaries: { percentage: 100, payToId, id: uuid }
          },
          id: claimId
        }
      })
  }

  return (
    <div>
      <GraphQLFormProvider
        initialValues={{ idType: 'rsa_id', memberType: 'BENEFICIARY' }}
        mapInputVariables={async input => {
          // console.log('mapInputVariables Member', input, mode, claimId)
          return ({ input })
        }}  // defining the input to use the input needed in the update mutation. 
        mode={'add'}
        {...config}
        afterSubmit={({ data }) => {
          // console.log('after submit', data)
          const memberData = data.updateMember ? data.updateMember : data.createMember ? data.createMember : {}
          if (afterSubmit) {
            afterSubmit(memberData)
          }
          const { clientId, firstNames, surname, DOB, email, cellNumbers } = memberData

          if (clientId && (firstNames || surname || cellNumbers || email || DOB)) {
            const input = { firstNames, surname, DOB, email, cellNumbers }
            updateClient(clientId, input)
          }
          if (mode === 'add') {
            updateClaim(claimId, memberData)
          }
          cancel()
        }
        }
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(inputProps) => <FormLayout {...props} {...inputProps} formName={config.name }  cancel={cancel} history={history} />}
        DisplayFormLayout={FormLayout}
        refetchQueries={[{ query: claimInformation, variables: { id: claimId } }]}
      />
    </div>
  )
}

export default ClaimBeneficiary
