import React from 'react';
import gql from 'graphql-tag';
import GraphQLTable from '../generic/GraphQLTable';
import { Card } from '../generic';
import { printCurrency } from '../../lib/helpers';
import moment from 'moment';


const config = {
  query: gql`
    {
      paymentstatuss(limit: 10000) {
        creditLife {
          policyNumber
          policyStatus
          client {
            idNumber
            firstNames
            surname
            debtCounselling {
              debtCounsellor {
                shortName
              }
            }
          }
        }
        info
        action
        status
        error
        done
        payment {
          amount
        }
        updatedAt
        expectedDate
      }
    }
  `,
  dataset: data => data.paymentstatuss,
  fields: {
    'creditLife.policyNumber': { label: 'Policy', width: 30 },
    'creditLife.policyStatus': { label: 'Status', width: 50 },
    'creditLife.client.idNumber': { label: 'ID Number', width: 50 },
    clientName: {
      label: 'Name',
      width: 90,
      accessor: row =>
        row.creditLife.client ? `${row.creditLife.client.firstNames} ${row.creditLife.client.surname}` : null
    },
    'creditLife.client.debtCounselling.debtCounsellor.shortName': {
      label: 'DC',
      width: 40
      //Cell: row => productTypes(row.value)
    },
    action: { label: 'Action', width: 50, align: 'center' },
    status: {
      label: 'Status',
      width: 30,
      align: 'right'
    },
    info: {
      label: 'Nedbank Message',
      width: 150,
      align: 'right'
    },
    expectedDate: { label: 'Expected', width: 50, align: 'center', Cell: row => row.value ? moment(row.value).format('DD-MM-YYYY') : '-' },

    'payment.amount': { label: 'Amount', width: 35,
      Cell: row => (row.value ? printCurrency(row.value) : '-')
    },
    Updated: {
      label: 'Update',align: 'center',
      width: 35,
      accessor: row => moment(row.updatedAt).format('DD-MM-YYYY')
    },
    updatedAt: { label: 'Relative', width: 50, Cell: row => moment(row.value).fromNow() },
  },
  defaultSorted: [{ id: 'updatedAt', desc: true }]
}

/*
 const handleRowPress = (history, { id, policyStatus }) => {
  history.push(`/credit-life/${id}`);
};

<GraphQLTable config={config} onRowPress={creditLife => handleRowPress(history, creditLife)} />

*/

const History = ({ history }) => (
  <Card>
    <h2>Nedbank Communication History"</h2>

      <GraphQLTable config={config}  />

  </Card>
);


export default History;
