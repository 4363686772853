import React, { useState } from 'react'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { GraphQLFormProvider } from 'react-form-helper'
import { creditLifeInformation } from './CreditLife'
import { renderInput, BuildLayout, renderDisplay } from '../generic';
import { isInteger } from '../../lib/validations'


const EmploymentStatus = [
  "PERMANENT_EMPLOYMENT",
  "UNEMPLOYED",
  "PENSIONER",
  "OTHER",
]

const fields = {
  'employers[0].companyName': {
    label: 'Employer *',
    type: 'autocomplete',
    options: [],
    validate: (value) => {
      if (!value) {
        return 'Employer is Required'
      }
    }
  },
  'employers[0].occupation': {
    label: 'Occupation *',
      type: 'text',
    validate: (value) => {
      if (!value) {
        return 'Occupation is Required'
      }
    }
  },
  'employers[0].isPartTime': {
    label: 'isPartTime',
    type: 'switch',
    },
  employmentStatus: {
    label: 'Employment Status',
    type: 'select',
    options: EmploymentStatus.map(e => ({ label: e, value: e }))
  },
  'workPhone': {
    label: 'Work Phone',
      type: 'text',
    validate: (value) => {
      if (!value) {
        return 'Please enter a work phone'
      }
      else if (isInteger(value)) {
        return 'Enter a number'
      }
    }
  }
}

const config = {
  name: 'employer',
  query: gql`
    query CreditLifeData($id: String!) {
      client(id: $id) {
        id
        workPhone
        employmentStatus
        employers {   
          companyName
          occupation
          isPartTime
        }
      }
    }`,
  updateMutation: gql`
    mutation UpdateClient($input: UpdateClientInput!, $id: String!) {
      updateClient(id: $id, input: $input) {
        id
        workPhone
        employmentStatus
        employers {
          companyName
          occupation
          isPartTime
        }
      }
  }`,
  addMutation: gql`
  mutation CreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      id
      workPhone
      employmentStatus
      employers {
        companyName
        occupation
        isPartTime
      }
    }
  }`
}


const FormLayout = (props) => {

  let { cancel, mode, formName } = props;

  return (
    <BuildLayout formName={formName} fieldNames={Object.keys(fields)} mode={mode} fieldConfig={fields} direction='column' spacing={2} align='center' justify='center' cancel={cancel} />
  );
}


let EditEmployer = (props) => {

  const { employers, cancel, id, history, creditLifeId } = props

  fields[`employers[0].companyName`].options = employers.map(e => ({ label: e, value: e }))

  return (
    <div>
      <GraphQLFormProvider
        mode="edit"
        id={id}
        fields={fields}
        {...config}
        afterSubmit={() => cancel()}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout {...props} formName={config.name} cancel={cancel} history={history} employers={employers} />}
        DisplayFormLayout={FormLayout}
        initialValuesFromData={data => {
          // console.log('initialValuesFromData', data)
          return data.client
        }
        }
        mapInputVariables={props => {
          const { employmentStatus, workPhone, employers, ...input } = props
          // console.log('mapInputVariables', props)
          // const { companyName, occupation, isPartTime } = employers && employers[0] || {}
          return ({ input: { employmentStatus, workPhone, employers } })
        }}  // defining the input to use the input needed in the update mutation. 
        refetchQueries={[{ query: creditLifeInformation, variables: { id: creditLifeId } }]}
      />
    </div>
  )
}

EditEmployer = withApollo(EditEmployer);
export default EditEmployer
