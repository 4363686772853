import React, { useState } from 'react'
import { useConfirm } from "material-ui-confirm";
import { withApollo } from 'react-apollo'
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag'
import moment from 'moment'
import { Button, Card, DatePicker, ActivityIndicator, TextField } from '../generic'

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 200,
    maxWidth: 200,
    textAlign: 'center',
    marginRight: 14
  }
}));

const FUNERAL = gql`
  query funerals($filter: FuneralFilter!) {
    funerals(filter: $filter) {
      id
      createdAt
    }
  }`

let Search = ({ client, onOk }) => {
  const confirm = useConfirm();
  const classes = useStyles();
  const [spin, setSpin] = useState(false)
  const [policyNumber, setPolicyNumber] = useState()
  const [policyText, setPolicyText] = useState()
  const [effectiveDate, setEffectiveDate] = useState(new Date().valueOf())

  const getFuneral = (policyNumber) => {
    setSpin(true)
    client
      .query({
        query: FUNERAL,
        variables: { filter: { policyNumber } }
      })
      .then(result => {
        setSpin(false)
        if (result.data.funerals && result.data.funerals[0]) {
          const { id, createdAt, ...policy } = result.data.funerals[result.data.funerals.length - 1]
          if (effectiveDate < createdAt) {
            onOk(id, createdAt)
          }
          else {
            onOk(id, effectiveDate)
          }
        }
        else {
          setPolicyText(`Funeral policy ${policyNumber} does not exist`)
        }
      })
  }

  const handleChange = (e) => {
    setPolicyNumber(e.target.value ? e.target.value.toUpperCase() : '')
    setPolicyText()
  }

  return (
    <Card>
      <div style={{ minWidth: 600, padding: 14, paddingRight: 21 }}>
      {spin && <ActivityIndicator />}
        <TextField fullWidth variant="outlined" value={policyNumber} required label='Policy Number' onChange={handleChange} inputProps={{ style: { textTransform: "uppercase" } }} helperText={policyText} />
        <br />
        <br />
        <DatePicker value={effectiveDate} disableFuture autoOk required label="Funeral Effective Date" onChange={(v) => { setEffectiveDate(v) }} />
        <br />
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button disabled={!(policyNumber)} onClick={() => getFuneral(policyNumber)} >
            Search
          </Button>
        </div>
      </div >
    </Card>
  )
}

export default Search = withApollo(Search);
