
import React from 'react'
import gql from 'graphql-tag'
import { withApollo, graphql } from 'react-apollo'
import { GraphQLFormProvider } from 'react-form-helper'
import { renderInput, renderDisplay, FormModal, Card, ActivityIndicator, BuildLayout } from '../generic'
import { isValidEmail } from '../../lib/validations';

const EMPLOYEES = gql`
	query Employees {
		employees(limit:2000) {
      id
      first
      last
      email
  	}
  }`;

const EditModal = (props) => {
  const { size, width } = props
  return (
    <FormModal
      okText="Ok"
      cancelText="Back"
      width={width}
      disableCancel={true}
      disableOk={true}
      size={size}
      componentProps={props}
    />
  )
}

const PROVIDERS = [
  "GOOGLE",
  "FACEBOOK",
  "MICROSOFT"
]

const ROLES = [
  "MANAGER",
  "CLIENT",
  "GRAPHIQL",
  "SALESREP",
  "KEYINDIVIDUAL",
  "CUSTOMERSUPPORT",
  "CLAIMS",
  "MARKETING",
  "SALESTRAINEE",
  "CUSTOMERSUPPORTTRAINEE",
  "ACTUARY",
  "OFFICESTAFF",
  "EMPLOYER"]

const fields = (EMPLOYEES) => ({
    email: {
    type: 'email',
    required: true,
    label: 'Email',
      validate: value => {
        if(!value) {
          return 'email is Required';
        }
        else if (isValidEmail(value)) {
          return 'email invalid';
        }
      }
    },
    username: {
      type: 'text',
      label: 'User Name',
      required: true,
      validate: value => {
        if(!value) {
          return 'Username is Required';
        }
      }
    },
  forEmployeeId: {
    label: "For Employee",
    type: "select",
    freeSolo: true,
    isClearable: true,
    autocomplete: true,
    options: EMPLOYEES.sort((a, b) => (a.label > b.label) ? 1 : -1),
    editable: true,
  },
    authProvider:{
      label:"AuthProvider",
      type:"select",
      options: PROVIDERS.sort((a, b) => (a > b) ? 1 : -1).map(a => ({ value: a, label: a })),
      required: true,
      validate: value => {
        if(!value) {
          return 'This is Required';
        }
      }
    },
    roles:{
      label:'Roles',
      type: 'multiselect',
      freeSolo: true,
      autocomplete: true,
      multiple: true,
      editable: true,
      options: ROLES.sort((a, b) => (a > b) ? 1 : -1).map(a => ({ value: a, label: a })),
      validate: value => {
        if (!value) {
          return 'Role is Required';
        }
      },
      required: true,
    }
})

function FormLayout(props) {

  const { mode, theFields, history, formName } = props
  const fieldNames = Object.keys(theFields)

    return (
      <Card>
        <h1>{mode === "edit" && "Change"} User Information</h1>
        <br />

        <BuildLayout formName={formName} fieldNames={fieldNames} mode={mode} fieldConfig={theFields} direction='column' spacing={2} cancel={() => history.push('/users')} />

      </Card>
    );
  }

let User = (props) => {

  const { id, history, data } = props

  if (data.loading) {
    return <ActivityIndicator />
  }

  const EMPLOYEES = data && data.employees ? data.employees.map(e => ({ label: `${e.first} ${e.last} (${e.email} )`, value: e.id })) : {}
  const theFields = fields(EMPLOYEES) 

  return (
    <div>
      <GraphQLFormProvider
        name="user"
        mode={id ? "edit" : "add"}
        id={id}
        fields={fields(EMPLOYEES)}
        query={gql`
        query User($id: String!) {
          user(id: $id) {
            id
            username
            email
            authProvider
            providerId
            lastLogin
            roles
            forEmployeeId
            forEmployee {
              id
              first
              last
              email
            }
          }
        }`}
        updateMutation={gql`
        mutation UpdateUser( $id: String!, $input: UpdateUserInput!) {
            updateUser(id: $id, input: $input) {
              id
              username
              email
              authProvider
              roles
              forEmployeeId
          }
        }`}
        createMutation={gql`
        mutation CreateUser( $input: CreateUserInput!) {
            createUser(input: $input) {
              id
              username
              email
              authProvider
              roles
              forEmployeeId
            }
          }`
        }
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout {...props} formName={'user'} history={history} theFields={theFields} />}
        DisplayFormLayout={FormLayout}
        afterSubmit={() => history.push('/users')}
      />
    </div>
  )
}

User = withApollo(User)
export default graphql(EMPLOYEES)(User);
