import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { ActivityIndicator, UploadFile } from '../index'

const FILE = gql`
query File ($id: String!) {
  file(id: $id) {
    id
    name
    url
    contentType
  }
}`

const CLIENT_DOCUMENT = gql`
query ClientDocument ($id: String!) {
  clientdocument(id: $id) {
    id
    description
    client {
      id
      idNumber
      firstNames
      surname
    }
    type
    verified
    verifiedBy {
      id
      description
    }
    file {
      id
      name
      url
      contentType
    }
  }
}`

const CLAIM_DOCUMENT = gql`
query ClaimDocument ($id: String!) {
  claimdocument(id: $id) {
    id
    description
    month
    waived
    required
    verified
    file {
      id
      name
      url
      contentType
    }
    requirementId
    requirement {
      id
      requirementId
      documentType
      description
      required
    }
  }
}`

const ADD_DOCUMENT_TO_ROLE_HOLDERS = gql`
  mutation addOrUpdateClientDocuments($input: [AddOrUpdateClientDocumentInput!]) {
    addOrUpdateClientDocuments(input: $input) {
      id
    }
  }`

const CREATE_CLIENT_DOCUMENT = gql`
mutation ($input: CreateClientDocumentInput!) {
  createClientDocument(input: $input) {
    id
    type
    description
    verified
    verifiedBy {
      id
      description
    }
    file {
      id
      name
      url
      contentType
    }
  }
}`

const UPDATE_CLIENT_DOCUMENT = gql`
mutation ($id: String!, $input: UpdateClientDocumentInput!) {
  updateClientDocument(id: $id, input: $input) {
    id
    type
    description
    verified
    verifiedBy {
      id
      description
    }
    file {
      id
      name
      url
      contentType
    }
  }
}`

const UPDATE_CLAIM_DOCUMENT = gql`
mutation ($id: String!, $input: UpdateClaimDocumentInput!) {
  updateClaimDocument(id: $id, input: $input) {
    id
    description
    month
    waived
    required
    verified
    file {
      id
      name
      url
      contentType
    }
    requirementId
    requirement {
      id
      requirementId
      documentType
      description
      required
    }
  }
}`

const addGroupDocument = (groupInfo, fileId, documentType) => {
  if (!groupInfo.roleHolders || groupInfo.roleHolders.length === 0) {
    return [{
      fileId,
      groupId: groupInfo.groupId,
      groupProductId: groupInfo.groupProductId,
      type: documentType
    }]
  }

  return groupInfo.roleHolders.map((roleHolder) => ({
    fileId,
    groupId: groupInfo.groupId,
    groupProductId: groupInfo.groupProductId,
    clientId: roleHolder.clientId,
    type: documentType
  }))
}

function LoadedFile(props) {
  const { file: fileProp, fileId, clientDocumentId, claimDocumentId, description: desc, documentType, onChange, clientInfo, showLarge, setVerify, onClose, groupInfo } = props
  const query = clientDocumentId ? CLIENT_DOCUMENT : claimDocumentId && fileId ? CLAIM_DOCUMENT : FILE
  const id = clientDocumentId ? clientDocumentId : claimDocumentId ? claimDocumentId : fileId
  const { data: fileData, loading, error } = fileProp ? { data: fileProp } : id ? useQuery(query, { variables: { id } }) : {}  // will give an error if no fileId passed in. Can be ignored

  const mutation = clientDocumentId ? UPDATE_CLIENT_DOCUMENT : claimDocumentId ? UPDATE_CLAIM_DOCUMENT : groupInfo ? ADD_DOCUMENT_TO_ROLE_HOLDERS : CREATE_CLIENT_DOCUMENT
  const [theMutation] = mutation && useMutation(mutation) || [{}]
  const [data, setData] = useState()

  useEffect(() => {
    if (fileData) {
      setData(fileData)
    }
    else {
      setData({})
    }
  }, [fileData]);

  if (error) {
    console.log(`Display file error: ${error.toString().indexOf('Response not successful') > -1 ? "Can't upload, no file Id" : error}`)
  }

  if (loading) {
    return <ActivityIndicator />
  }

  const onApproval = async (verified) => {
    console.log('onFileApproval', verified, mutation, clientDocumentId)
    if (mutation) {
      if (clientDocumentId) { // claim docs updated in Claims on save
        console.log('create clientDocument', clientDocumentId )
        const variables = { id: clientDocumentId, input: { verified } }
        const response = await theMutation({ variables })
        setData(response)
      }
      setVerify && setVerify(verified)
    }
  }

  const onUpdate = async (fileId) => {
    // claim docs updated in Claims on save
    if (!claimDocumentId && !groupInfo && clientInfo) {
      const variables = clientDocumentId
        ? { id: clientDocumentId, input: { type: documentType, verified: true, fileId } }
        : { input: { clientId: clientInfo ? clientInfo.id : undefined, type: documentType, fileId, description: props.description || desc } }
      console.log('onUpdate create clientDocument', variables)
      const response = await theMutation({ variables })
      setData(response)
    } else if (groupInfo) {
      const variables = { input: addGroupDocument(groupInfo, fileId, documentType) }
      const response = await theMutation({ variables })
      setData(response)
    }
    console.log('Loaded file fileId is ', fileId)
    onChange && onChange(fileId);
  }

  const theUrl = fileProp.url || data && data.claimDocument && data.claimDocument.file && data.claimDocument.file.url || data && data.clientDocument && data.clientDocument.file && data.clientDocument.file.url
  const theVerified = props.verified || data && data.claimDocument && data.claimDocument.file && data.claimDocument.file.Verified || data && data.clientDocument && data.clientDocument.file && data.clientDocument.file.Verified
  const theContentType = fileProp.contentType || data && data.file && data.file.contentType || props.contentType || data && data.claimDocument && data.claimDocument.file && data.claimDocument.file.contentType || data && data.clientDocument && data.clientDocument.file && data.clientDocument.file.contentType

  return <UploadFile {...props} url={theUrl} onApproval={onApproval} contentType={theContentType} documentType={documentType} onChange={onUpdate || onChange} onClose={onClose} verified={theVerified} />
}

export default LoadedFile

//<FilePreview url={url} documentType={type || documentType} contentType={contentType} name={name} disabled={disabled} onChange={onChange} onClose={() => setEnlarge(true)} />
