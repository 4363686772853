import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import * as FaIcons from "react-icons/fa";
import { withRouter } from 'react-router-dom'
import { API_URL, API_AUTH_ID } from '../config'
import { Button } from '../components/generic'
const images = require('../assets/images')

const Login = (props) => {
  // TODO: Use the redux store for the token ...
  // TODO: Check security of browser token storage

  useEffect(() => {
    let accessToken = localStorage.getItem('accessToken')
    if (props.location.pathname === '/login/callback') {
      console.log('IT IS A CALLBACK')
      accessToken = new URLSearchParams(props.location.search).get('token')
      localStorage.setItem('accessToken', accessToken)
      const redirect = localStorage.getItem('redirectUrl') || "/"
      localStorage.removeItem('redirectUrl')
      props.history.push(redirect)
    }
    else if (accessToken) {
      const redirect = localStorage.getItem('redirectUrl') || "/"
      localStorage.removeItem('redirectUrl')
      props.history.push(redirect)
    }
  }, [])

  return (
    <div style={{ paddingLeft: '5%', paddingTop: '25%', height: '100vh', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url(${images.bear})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right', backgroundSize: 'contain' }} >

      <Helmet>
        <title>Meerkat Admin</title>
        <meta name="Meerkat Admin" />
        <link rel="icon" type="image/png" href="https://www.meerkat.co.za/wp-content/uploads/2017/10/meerkat_debt_favicon-1.png" sizes="16x16" />
      </Helmet>

      <div style={{ maxWidth: 600 }}>
        <Button color="primary" href={`${API_URL}auth/google?clientId=${API_AUTH_ID}&redirect=${window.location.origin}`} >
          <FaIcons.FaGoogle />&nbsp; Sign in with Google
        </Button>
        <p />
        <Button color="secondary" href={`${API_URL}auth/azure_ad?clientId=${API_AUTH_ID}&redirect=${window.location.origin}`} >
          <FaIcons.FaMicrosoft />&nbsp; Sign in with Office365
        </Button>
      </div>

      {/* <div style={{ maxWidth: 600 }}>
            <Button block  color="primary" href={`${API_URL}auth/facebook?clientId=${API_AUTH_ID}&redirect=${window.location.origin}`} style={{ marginTop: '7px', marginRight: '7px' }} icon={<FacebookOutlined /> } >
              Sign in with Facebook
            </Button>
        </div> */}
    </div>
  )
}

export default withRouter(Login)