import React from 'react';
import { MaterialTable } from '../generic'
import { withRouter } from 'react-router-dom'

let ClientGroup = ({ groups, history }) => {

  const columns = [{
    Header: 'Group Name',
    accessor: 'name',
    filter: 'fuzzyText'
  }]

  return (
    <MaterialTable columns={columns} data={groups} onRowPress={(row) => { console.log('group pressed row', row); history.push(`/groups/${row.id}`) }} />
  )
}
ClientGroup = withRouter(ClientGroup)
export default ClientGroup