import React, { useState, useRef, useEffect } from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from 'axios'
import { API_URL } from '../../../config'
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Typography, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import { Button, PdfViewer, ImageCropper } from '..'
import { styles } from './styles'
import { getAccessToken } from '../../../services/auth'
import { Grid } from '../';

import 'react-image-crop/dist/ReactCrop.css'
import { IdTypes } from '../../../variables';

const prettyId = (id) => {
  if (id && id.length === 13) {
    return id.substr(0, 6) + ' ' + id.substr(6, 4) + ' ' + id.substr(10, 2) + ' ' + id.substr(12, 1)
  }
  else return id
}

const CLIENTDOCUMENT = gql`
query ClientDocument ($id: String!) {
  clientdocument(id: $id) {
    type
    verified
    verifiedBy {
      description
    }
    file {
      id
      name
      url
      contentType
    }
  }
}`

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const accessToken = localStorage.getItem('accessToken') || getAccessToken()

function Transition(props) {
  return <Slide direction="up" {...props} />
}

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

function FileUploadCrop({ file: fileData, id, noButtons, onChange, onClose, onApprove, onReject, clientData, prompt, classes }) {
  console.log('FileUploadCrop', fileData, id)
  // id is the clientDocumentId
  const { data, loading, error } = id ? useQuery(CLIENTDOCUMENT, { variables: { id } }) : {}
  const [clientInfo, setClientInfo] = useState()
  const [imgSrc, setImgSrc] = useState()
  const [pdfSrc, setPdfSrc] = useState()
  const [file, setFile] = useState('')
  const [type, setType] = useState('')
  const [verified, setVerified] = useState('')
  const [verifiedBy, setVerifiedBy] = useState('')
  const [pdfFile, setPdfFile] = useState('')
  const imgRef = useRef(null)
  const [aspect, setAspect] = useState(16 / 9)
  const [uploading, setUploading] = useState(false)
  const [allowCrop, setAllowCrop] = useState()
  const [progress, setProgress] = useState(0)
  const [croppedImage, setCroppedImage] = useState(undefined);

  useEffect(() => {
    console.log('useEffect clientData', clientData)

    if (clientData) {
      setClientInfo(clientData)
    }
  }, [clientData])

  useEffect(() => {
    console.log('useEffect fileData', fileData)
    console.log('useEffect data', data)
    const { file, type, verified, verifiedBy } = data ? data.clientdocument : {}
    const { url, contentType } = file ? file : fileData ? fileData : {}
    if (url) {
      const isPdf = contentType.includes('pdf')
      console.log('set verified', verified)
      setType(type)
      setVerified(verified)
      setVerifiedBy(verifiedBy ? verifiedBy.description : '')
      if (isPdf) {
        setPdfSrc(url)
      } else {
        setImgSrc(url)
      }
    }
  }, [fileData, data])


  if (loading) {
    return <div />
  }

  const uploadFile = async (src) => {

    let data = new FormData();

    if (pdfFile) {
      data.append('file', pdfFile);
    }
    else {
      const base64Response = await fetch(src);
      const blob = await base64Response.blob();

      const MB = 1000000;
      if (blob && blob.size > MB) return new Error('File size is to big');

      const { name, type } = file || {}
      data.append('file', blob, name);
    }

    const config = {
      method: "POST",
      crossDomain: true,
      headers: {
        'Authorization': `JWT ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setProgress(percentCompleted)
      }
    }
    setUploading(true)
    onClose && onClose()

    axios.put(`${API_URL}upload`,
      data,
      config)
      .then(result => {
        console.log('uploadedFile', result)
        setUploading(false)
        setProgress(0)
        setImgSrc('');
        if (onChange) {
          onChange(result.data[0].id)
        }
      })
      .catch(console.error)
  }

  const onUploadFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      const isPdf = event.target.files[0].type.includes('pdf')
      if (isPdf) {
        setPdfFile(event.target.files[0])
      } else {
        setFile(event.target.files[0])
      }
      console.log('uploaded file is', isPdf ? pdfFile : file)

      reader.addEventListener("load", () => {
        const image = reader.result;
        isPdf ? setPdfSrc(image) : setImgSrc(image);
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const title = `${verified === true ? 'APPROVED' : verified === false ? 'REJECTED' : noButtons ? ' ' : `Please approve or reject the document`} ${clientInfo ? `${type} for ${prettyId(clientInfo.idNumber)} ${clientInfo.firstNames} ${clientInfo.surname}` : ''} ${verifiedBy ? `by ${verifiedBy}` : ''}`
  return (
    <div className={classes.fileUpload}>
      {!id && !fileData && <div className={classes.uploadControl}>
        <label for="fileUpload"><div style={{ cursor: 'pointer' }}><CloudUploadIcon /></div></label>
        <input type="file" id='fileUpload' name='fileUpload' accept="image/png, image/jpeg, .pdf" onChange={onUploadFile} style={{ opacity: 0 }} />
      </div>}
      <Dialog
        fullWidth
        maxWidth='xl'
        open={Boolean(imgSrc || pdfSrc)}
        TransitionComponent={Transition}
      >
        <Grid container className={classes.mainGrid} spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <h2>{title}</h2>
              </Grid>

              <Grid item xs={1}>
                {!noButtons && !allowCrop && !croppedImage && onReject && <div className={classes.paper}>
                  <Button className={classes.approvalButton}
                    onClick={onReject}
                    variant="contained"
                    color="secondary"
                  >
                    Reject
                  </Button></div>}
              </Grid>
              <Grid item xs={1}>
                {!noButtons && !allowCrop && !croppedImage && onApprove && <div className={classes.paper}>
                  <Button className={classes.approvalButton}
                    onClick={onApprove}
                    variant="contained"
                    color="primary"
                  >
                    Approve
              </Button>
                </div>}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} >
            <Grid container spacing={1} >
              {!allowCrop && (croppedImage || (pdfSrc && !fileData)) && <div className={classes.paper}>
                <Button
                  className={classes.button}
                  onClick={() => uploadFile(croppedImage || imgSrc || setPdfSrc)}
                  variant="contained"
                  color="primary"
                  disabled={uploading}
                >
                  Upload
                </Button>
              </div>}
              {allowCrop && <div className={classes.paper}>
                <Button
                  className={classes.button}
                  onClick={() => {
                    setImgSrc(croppedImage)
                    setAllowCrop(false)
                  }}
                  variant="contained"
                  color="primary"
                  disabled={!imgSrc || uploading}
                >
                  Save
                </Button>
              </div>}

              <div className={classes.paper}>
                <Button
                  className={classes.button}
                  onClick={() => {
                    if (allowCrop) {
                      setAllowCrop(false)
                    } else {
                      setImgSrc('');
                      setPdfSrc('');
                      onClose && onClose()
                    }
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </Button>
              </div>

              {!allowCrop && !pdfSrc && <div className={classes.paper}>
                <Button
                  color='primary'
                  className={classes.button}
                  onClick={() => setAllowCrop(true)}
                  variant="contained"
                  disabled={!imgSrc || uploading}
                >
                  Crop
                </Button>
              </div>}
              {Boolean(imgSrc) && <div className={classes.paper}>
                <Button color="primary" className={classes.button}>
                  <a href={imgSrc} style={{ color: 'white' }} target="_blank" rel="noopener noreferrer" download>
                    Download
                  </a>
                </Button>
              </div>}

              {progress && progress > 0 ? <div className={classes.paper}>
                {Boolean(imgSrc || pdfSrc) && (
                  <Box className="mb25" display="flex" alignItems="center">
                    <Box width="100%" mr={1}>
                      <BorderLinearProgress variant="determinate" value={progress} />
                    </Box>
                    <Box minWidth={35}>
                      <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                    </Box>
                  </Box>)
                }
              </div> : <div />}

            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="flex-start"
              // minHeight="90vh"
              // maxWidth="70vw"
              // minWidth="60vw"
            >
              {Boolean(imgSrc) && !allowCrop && <div style={{ paddingBottom: 82 }}>
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  height={'100%'}
                  crossOrigin="anonymous"
                />
              </div>}

              {Boolean(pdfSrc) && !allowCrop && (fileData ? <PdfViewer file={pdfSrc} /> : <iframe src={pdfSrc} height={800} width={600} />)}

              {Boolean(imgSrc) && allowCrop && (
                <>
                  <ImageCropper
                    imageToCrop={imgSrc}
                    onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
                  />
                </>)}
            </Box>

          </Grid>
          {/* <Grid item xs={1} >
            <Grid container spacing={1}>
              {!allowCrop && !croppedImage && onReject && <div className={classes.paper}>

                <Button className={classes.approvalButton}
                  onClick={onReject}
                  variant="contained"
                  color="secondary"
                >
                  Reject
                </Button></div>}
              {!allowCrop && !croppedImage && onApprove && <div className={classes.paper}>
                <Button className={classes.approvalButton}
                  onClick={onApprove}
                  variant="contained"
                  color="primary"
                >
                  Approve
                </Button>
              </div>}
            </Grid>
          </Grid> */}
        </Grid>
      </Dialog>
    </div>
  )
}
export default withStyles(styles)(FileUploadCrop)
