import client from '../client';
import gql from 'graphql-tag';

const MemberFragment  = `
    ... on Member {
      id
      firstNames
      surname
      memberType
      policys {
        id
        policyNumber
      }
    }`;

const CommunicationFragment = `
   ... on Communication {
      id
      to
      type
      message
      policy {
        id
      }
    }`;

const PolicyFragment = `
    ... on Policy {
      id
      covers {
        id
      }
      proposer {
        firstNames
        surname
      }
      policyType
      policyNumber
    }
`;

const EmployeeFragment = `
    ... on Employee {
      id
      first
      last
    }
`;

const QuestionFragment = `... on Question {
    id
    questionType
    question
}`;

/**
 *
 * @param members - boolean
 * @param communication - boolean
 * @param policy - boolean
 * @param employee - boolean
 * @param question - boolean
 */
const query = (members = true, communication = true, policy = true, employee = true, question = true) => {

    return gql`
        query Search($searchFor: String) {
          search(searchFor: $searchFor) {
            __typename
            ${ members === true ? MemberFragment : '' }
            ${ communication === true ? CommunicationFragment : '' }
            ${ policy === true ? PolicyFragment : '' }
            ${ employee === true ? EmployeeFragment : '' }
            ${ question === true ? QuestionFragment : '' }
          }
        }
        `;
};

const initialState = {
    searchFor: '',
    results: [],
    showResult: false,
    include: {
        members: true,
        communication: true,
        policy: true,
        employee: true,
        question: true
    },
    queryError: undefined
};

export const updateSearch = ( searchFor ) => ({type: 'setSearchFor', searchFor });
export const displayResults =  ( results, error ) => ({type: 'setResults', results, showResult : true, error });
export const clearResults = () => ({ type:'setResults', results: [] });
export const hideResult = () => ({ type:'setShowResults', showResult: false });
export const showResult = () => ({ type:'setShowResults', showResult: true });
export const searchOptions = ( include = initialState.include ) => ({ type: "setIncludes", include });
export const doSearch = ( searchFor = null ) => (dispatch, getState) => {
    const { search } = getState();
    let results = [];

    let {
        members,
        communication,
        policy,
        employee,
        question
    } = search.include;

    let variables = {
        searchFor: searchFor || search.searchFor
    };

    client.query({
        query: query( members, communication, policy, employee, question),
      variables
    }).then((data) => {
        results = [...results, ...data.data.search];
        dispatch(displayResults(results));
    }).catch( ( error ) => {
        dispatch(displayResults(results, error));
    });
};

const search = (state = initialState, action) => {
    switch(action.type){
        case "setSearchFor": return { ...state, searchFor: action.searchFor };
        case "setResults": return {...state, results: action.results, showResult: action.showResult === true, queryError: action.error };
        case "setShowResults": return {...state, showResult: action.showResult === true };
        case "setIncludes": return {...state, include: { ...state.include, ...action.include }};
        default: return state;
    }
};

export default search;