import React, { Component, Fragment } from 'react'
// import FilterItem from './FilterItem'
import moment from 'moment'

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../../assets/css/react-dates-overides.css';

import { DateRangePicker } from 'react-dates';

// import { DateRangePicker } from "materialui-daterange-picker";
import { FaTemperatureLow } from 'react-icons/fa';

class DateFilterItem extends Component {
  state = {
    open: FaTemperatureLow
  }
  render() {
    const { name, icon = "fa-clock-o" } = this.props
    console.log('DateFilterItem', this.props)

    return (
      <Fragment>

        {/* <DateRangePicker
          open={true}
          format="DD-MM-YYYY"
          value={this.state.rangeValue}
          onChange={({ startDate, endDate }) => { this.props.onDatesChange && this.props.onDatesChange(startDate, endDate); this.setState({ focusedInput: false }) }}
          minDate={this.props.startDate || moment().subtract(1, 'year')}
          maxDate={this.props.endDate || moment()}
        /> */}

        <DateRangePicker
          startDate={this.props.startDate || moment().subtract(1, 'year')}
          endDate={this.props.endDate || moment()}
          onDatesChange={({ startDate, endDate }) => { this.props.onDatesChange && this.props.onDatesChange(startDate, endDate) }}
          focusedInput={this.state.focusedInput}
          onFocusChange={(focusedInput) => { this.setState({ focusedInput: false })}}
          displayFormat="D MMMM YYYY"
          customInputIcon={<i className={`fa ${icon}`} />}
          isOutsideRange={() => false}
          small
          noBorder
        />
      </Fragment>
    )
  }
}

export default DateFilterItem
