import React, { useState } from 'react'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { GraphQLFormProvider } from 'react-form-helper'
import { isValidCellphoneNumber, isValidEmail, isValidRsaIdNumber } from '../../lib/validations'
import { creditLifeInformation } from './CreditLife'
import { IdTypes } from "../../variables/IdTypes"
import { RELATIONSHIPS } from "../../variables"
import { BuildLayout, renderInput, renderDisplay } from '../generic';
import { nameCase } from '@foundernest/namecase';


const UPDATE_CREDIT_LIFE = gql`
  mutation UpdateCreditLife( $id: String!, $input: UpdateCreditLifeInput!) {
      updateCreditLife(id: $id, input: $input) {
        id
    }
  }`

const config = {
  name: 'member',
  fields: {

    'firstNames': {
      type: 'text',
      label: 'First Name *',
      validate: (value) => {
        if (!value) {
          return 'First name is Required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    },
    'surname': {
      type: 'text',
      label: 'Last Name *',
      validate: (value) => {
        if (!value) {
          return 'Last name is required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    },
    'relationship': {
      label: 'Relationship Type *',
      type: "select",
      options: RELATIONSHIPS.sort((a, b) => (a > b) ? 1 : -1).map(a => ({ value: a, label: a })),
      validate: (value) => {
        if (!value) {
          return 'Relationship is Required'
        }
      }
    },
    'cellNumbers[0]': {
      type: 'text',
      label: 'Mobile Number*',
      memberTypes: ["BENEFICIARY"],
      validate: (value) => {
        if (!value) {
          return 'Cell is required'
        }
        if (isValidCellphoneNumber(value)) {
          return 'enter a valid cell number'
        }
      }
    },
    'idType': {
      type: 'select',
      options: IdTypes,
      label: 'ID Country',
      validate: (value, row) => {
        if (!row.DOB && row.idNumber && !value) {
          return 'ID type is Required'
        }
      }
    },
    'idNumber': {
      label: 'Id Number',
      type: 'text',
      validate: (value, row) => {
        if (value && row.idType && row.idType === 'rsa_id' && isValidRsaIdNumber(value)) {
          return 'ID Number is invalid'
        }
      }
    },
    'email': {
      type: 'email',
      label: 'Email ',
      validate: (value) => {
        if (value && isValidEmail(value)) {
          return 'Email not valid'
        }
      }
    }
  },
  query: gql`
    query MemberData($id: String!) {
      member(id: $id) {
        id
        firstNames
        surname
        idType
        idNumber
        cellNumbers
        email
        relationship
      }
    }`,
  updateMutation: gql`
    mutation UpdateMember($input: UpdateMemberInput!, $id: String!) {
      updateMember(id: $id, input: $input) {
        id
        firstNames
        surname
        idType
        idNumber
        cellNumbers
        email
        relationship
      }
  }`,
  addMutation: gql`
  mutation CreateMember($input: CreateMemberInput!) {
    createMember(input: $input) {
      id
      firstNames
      surname
      idType
      idNumber
      cellNumbers
      email
      relationship
    }
  }`
}


const FormLayout = (props) => {

  let { mode, submit, cancel, data, history, formName } = props;

  const { creditlife } = data || {}
  const { beneficiaries } = creditlife || {}

  console.log('CreditLife beneficiary props', props, beneficiaries)

  return (
    < >
      <span style={{ fontSize: "24px", fontWeight: 400, margin: 0, textTransform: "uppercase" }}> {mode == "edit" ? `Edit Next Of Kin` : `Define the Next Of Kin`} </span>
      <br /><br /><br />

      <BuildLayout formName={formName} fieldNames={Object.keys(config.fields)} mode={mode} fieldConfig={config.fields} direction='column' spacing={2} align='center' justify='center' cancel={cancel} />

    </>
  );
}


let NextOfKin = (props) => {

  const { cancel, creditLifeId, id, history, client } = props

  const updateCreditLife = ({ createMember, updateMember }) => {
    if (creditLifeId) {

      let input = { payToId: createMember ? createMember.id : updateMember.id }

      if (input) {
        input = { beneficiaries: [{ isNextOfKin: true, ...input }] }
        client.mutate({
          mutation: UPDATE_CREDIT_LIFE,
          variables: {
            id: creditLifeId,
            input,
          },
          refetchQueries: [{ query: creditLifeInformation, variables: { id: creditLifeId } }]
        })
          // .then(result => cancel())
      }
    }
    cancel()
  }

  return (
    <div>
      <GraphQLFormProvider
        mode={id ? "edit" : "add"}
        id={id}
        {...config}
        afterSubmit={({ data }) => { updateCreditLife({ ...data }) }}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout {...props} formName={config.name}  cancel={cancel} history={history} />}
        DisplayFormLayout={FormLayout}
        initialValuesFromData={data => data.member}
        mapInputVariables={props => {
          const { isNextOfKin: nextOfKin, firstNames, surname, ...input } = props
          if (firstNames) {
            input.firstNames = nameCase(firstNames)
          }
          if (surname) {
            input.surname = nameCase(surname)
          }
          console.log('mapInputVariables', props)
          return ({ input })
        }}  // defining the input to use the input needed in the update mutation. 
      />
    </div>
  )
}

NextOfKin = withApollo(NextOfKin);
export default NextOfKin