import styled from '@emotion/styled'
import { css } from '@emotion/core'
import theme, { device } from '../../../theme.style'
import { getPaddingFromProps } from '../../../lib/styles'

const textStyles = {
  heading: `
    font-weight: 900;
    font-size: 40px;
    line-height: 53px;

    ${device.desktopS`
      font-size: 30px !important;
      line-height: 41px !important;
    `}

    ${device.laptopL`
      font-size: 24px !important;
      line-height: 34px !important;
    `}

    ${device.laptop`
      font-size: 16px !important;
      line-height: 25px !important;
    `}

    ${device.tablet`
      font-size: 12px !important;
      line-height: 16px !important;
    `}
  `,
  uppercase: `
    text-transform: uppercase;
  `,
  primary: `
    color: ${theme.colors.primary};
  `,
  bold: `
    font-weight: 900;
  `,
  alignLeft: `
    text-align: left;
  `,
  white: `
    color: ${theme.colors.white};
  `,
  red: `
    color: ${theme.colors.red};
  `,
  medium: `
    font-size: 26px !important;
    line-height: 33px !important;

    ${device.desktopS`
      font-size: 23px !important;
      line-height: 29px !important;
    `}

    ${device.laptopL`
      font-size: 19px !important;
      line-height: 24px !important;
    `}

    ${device.laptop`
      font-size: 15px !important;
      line-height: 20px !important;
    `}

    ${device.tablet`
      font-size: 14px !important;
      line-height: 16px !important;
    `}
  `,
  large: `
    font-size: 40px !important;
    line-height: 51px !important;

    ${device.desktopS`
      font-size: 30px !important;
      line-height: 39px !important;
    `}

    ${device.laptopL`
      font-size: 24px !important;
      line-height: 32px !important;
    `}

    ${device.laptop`
      font-size: 16px !important;
      line-height: 23px !important;
    `}

    ${device.tablet`
      font-size: 14px !important;
      line-height: 18px !important;
    `}
  `,
  small: `
    font-size: 15px !important;
    line-height: 18px !important;

    ${device.desktopS`
      font-size: 14px !important;
      line-height: 17px !important;
    `}

    ${device.laptopL`
      font-size: 12px !important;
      line-height: 15px !important;
    `}

    ${device.laptop`
      font-size: 10px !important;
      line-height: 13px !important;
    `}

    ${device.tablet`
      font-size: 10px !important;
      line-height: 13px !important;
    `}
  `,
  xsmall: `
    font-size: 10px !important;
    line-height: 13px !important;

    ${device.tablet`
      font-size: 8px !important;
      line-height: 10px !important;
    `}
  `,
  xxlarge: `
    line-height: 101px !important;
    font-size: 90px !important;

    ${device.desktopS`
      font-size: 70px !important;
      line-height: 80px !important;
    `}

    ${device.laptopL`
      font-size: 50px !important;
      line-height: 59px !important;
    `}

    ${device.laptop`
      font-size: 30px !important;
      line-height: 36px !important;
    `}

    ${device.tablet`
      font-size: 20px !important;
      line-height: 25px !important;
    `}
  `,
  xlarge: `
    line-height: 70px !important;
    font-size: 60px !important;

    ${device.desktopS`
      font-size: 50px !important;
      line-height: 60px !important;
    `}

    ${device.laptopL`
      font-size: 40px !important;
      line-height: 45px !important;
    `}

    ${device.laptop`
      font-size: 30px !important;
      line-height: 35px !important;
    `}

    ${device.tablet`
      font-size: 20px !important;
      line-height: 25px !important;
    `}
  `,
  underline: `
    text-decoration: underline;
  `,
  link: `
    cursor: pointer;
  `
}
let StyledText = styled.div`
  opacity: ${props => props.opacity || 1.0};
  color: ${props => props.theme && props.theme.colors && props.theme.colors[props.color || "text"]};
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  font-family: ${props => props.theme && props.theme.fontFamily};

  ${props => props.clickable && css`
    cursor: pointer;
  `}

  ${device.desktopS`
    font-size: 18px;
    line-height: 23px;
  `}

  ${device.laptopL`
    font-size: 16px;
    line-height: 21px;
  `}

  ${device.laptop`
    font-size: 14px;
    line-height: 18px;
  `}

  ${device.tablet`
    font-size: 12px;
    line-height: 15px;
  `}

  ${props => css`${Object.keys(textStyles).map(style => props[style] ? textStyles[style]: "").join("\n")}`}

  ${props => props.size && css`
    font-size: ${props.size}px !important;
    line-height: ${props.size * 1.31}px !important
  `}

  ${props => getPaddingFromProps(props)};
`

let StyledSpan = styled.span`
  font-family: ${props => props.theme.fontFamily};
  ${props => props.color && css`color: ${props.theme.colors[props.color]}`};
  ${props => css`${Object.keys(textStyles).map(style => props[style] ? textStyles[style]: "").join("\n")}`}

  ${props => props.size && css`
    font-size: ${props.size}px !important;
    line-height: ${props.size * 1.31}px !important
  `}

  ${props => getPaddingFromProps(props)};
`

export {
  StyledText,
  StyledSpan
}