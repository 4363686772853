import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'

const fadeIn = keyframes`
  from {
    top: 99vh
  }
  to {
    top: 0
  }
`

const fadeOut = keyframes`
  from {
    top: 0
  }
  to {
    top: 100vh
  }
`

const ModalBackdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => `#${props.theme.colors.dark.replace("#","")}68`};
  z-index: 1000;
  
  ${props => props.animating && (props.open
    ? css`animation: ${fadeOut} 0.6s ease`
    : css`animation: ${fadeIn} 0.5s ease`)}

  ${props => !props.animating && (!props.open 
    ? css`display: none; opacity: 0`
    : css`opacity: 1`)}  
`

const ModalContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: white;
    border-radius: 8px 8px 0px 0px;
    box-shadow: 0px 4px 4px 2px rgba(230, 230, 230, 0.5);
`

const ModalContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 75px);
  padding: 8px;
`


const ModalTitleBarContainer = styled.div`
  position: relative;
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const ModalTitleContainer = styled.div`
`

const CloseModalContainer = styled.div`
  position: absolute;
  right: 19px;
  top: 32px;
`

export {
  ModalBackdrop,
  ModalContainer,
  ModalContentsContainer,
  ModalTitleBarContainer,
  ModalTitleContainer,
  CloseModalContainer
}