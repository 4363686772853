import styled from '@emotion/styled'

const toFlex = {
  start: "flex-start",
  end: "flex-end",
  center: "center"
}

const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`

const Row = styled.div`
  display:flex;
  flex-wrap:wrap;
  ${props => props.size && `flex: ${props.size}`};
  ${props => props.alignHorizontal && `flex-direction: row; justify-content: ${toFlex[props.alignHorizontal]}`};
  ${props => props.alignVertical && `flex-direction: column; align-items: ${toFlex[props.alignVertical]}`};
  ${props => props.height ? `height: ${props.height}` : `height:21px`};
  ${props => props.direction && `flex-direction: ${props.direction}`};
  ${props => props.alignItems && `align-items: ${props.alignItems}`};
  ${props => props.justifyContent && `justify-content: ${props.justifyContent}`}
`

export { Row, Break}