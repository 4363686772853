import React, { useState } from 'react'
import { useConfirm } from "material-ui-confirm";
import { graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Button, Modal, Select } from '../generic'

//const client = new ApolloClient();

const rejectReasons = [
  { value: 'missingInfo', label: 'Incomplete application' },
  { value: 'timeExpired', label: "Claimant didn't provide all information in time" },
  { value: 'fraud', label: 'Suspected fraud' },
  { value: 'noSupport', label: 'Information does not support a claim' }
]

const fraudReasons = [
  { value: 'notFraud', label: 'It is not fraud' }
]

const friendlyReasons = (value) => {
  switch (value) {
    case 'missingInfo':  return 'Incomplete application'
    break
    case 'timeExpired': return "Claimant didn't provide all information in time"
    break
    case 'fraud': return 'Suspected fraud'
    break
    case 'noSupport': return 'Information does not support approval.'
    break
    default: return value
    break
  }
}

const approvalInfo = gql`
  fragment ApprovalInfo on Approval {
    id
    approvalType
    description
    cancelled
    approved
    approvedBy {
      description
    }
    rejected
    reason
    rejectedBy {
      description
    }
    approvalGroups {
      name
    }
    canApprove
  }
`

const APPROVE = gql`
  mutation Approve($id: String!, $input: UpdateApprovalInput!) {
    updateApproval(id: $id, input: $input)
   {
      ...ApprovalInfo
    }
  }
  ${approvalInfo}
`

const GET_APPROVAL = gql`
  query GetApproval($id: String!) {
    approval(id: $id) {
      ...ApprovalInfo
    }
  }
  ${approvalInfo}
`
// add modals to confirm approval and to take reject reason

const extractDescription = (description) => description ? description.indexOf('payment') > -1 ? description.substr(description.indexOf('payment'), description.length).toLowerCase() : description : ''

function Approve({ client, approve, id, approvalDescription, data, refetchQueries }) {
  const confirm = useConfirm();

  const approveOrRejectClaim = (id, approved, rejected, reason) => {
    client.mutate({ mutation: APPROVE,
      variables: {
        id,
        input: { approved, rejected, reason: friendlyReasons(reason) } 
      },
      refetchQueries
      })
  }

  const confirmPrompt = (id, reason, extractDescription) => {
    const accepted = true
    const rejected = false
    confirm({
      title: `${extractDescription}`,
      description: "Are you sure?"
    })
      .then(() => approveOrRejectClaim(id, accepted, rejected, reason))
  }

  const rejectClaim = (id, reason) => {
    const accepted = false
    const rejected = true
    approveOrRejectClaim(id,accepted,rejected,reason)
  }

  const { loading, error, approval } = data
  const [approvalReason, setApprovalReason] = useState("noSupport")
  const [cancelVisible, setCancelVisible] = useState(false)

  if (loading) { return (<div />) }
  if (error) { return (<div />) }

  return (
  <>

  {approval.cancelled &&  <div style={{textAlign:'left',fontWeight: 700, color: '#333', fontSize: 16}}> Cancelled </div> }

  {approval.approved && !approval.rejected && 
        <div style={{ textAlign: 'left', fontWeight: 700, color: '#333', fontSize: 16 }}>
          {approval.approvedBy ? approval.approvedBy.description : 'Unknown'} <span style={{ fontWeight: 400 }}>approved</span> {extractDescription(approvalDescription)}
    </div>}

  {approval.rejected &&
      <div style={{textAlign:'left',fontWeight: 700, color: '#333', fontSize: 16}}>
          {approval.description} <span style={{ fontWeight: 400 }}>was rejected by</span> {approval.rejectedBy ? approval.rejectedBy.description : ' '} <span style={{ fontWeight: 400 }}>because</span> {approval.reason}
      </div>}

  {!approval.approved && !approval.rejected && !approval.cancelled && <div style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
    
        <div style={{ border: "1px solid #333", borderRadius: 15, width: '50%', padding: 14, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

        {approval.canApprove &&
            <div style={{ textAlign: 'left', fontWeight: 700, color: '#333', fontSize: 16 }}>
              <div> 
            {approvalDescription}
          </div>
          <div style={{textAlign:"center", marginTop:14}}>
                <Button style={{ width: 100, height: 30 }} color="secondary" onClick={() => setCancelVisible(true)}>No</Button>
                <Button style={{ width: 100, height: 30, marginLeft: 25 }} color="primary" onClick={() => confirmPrompt(id, "Approved by user", extractDescription(approvalDescription))}>Yes</Button>
          </div>
          <br/>
          <Modal
                title="Do you want to decline this?"
                wrapClassName="vertical-center-modal"
                open={cancelVisible}
                onOk={() => { setCancelVisible(false); rejectClaim(id, approvalReason) }}
                onCancel={() => setCancelVisible(false)}
                onClose={() => setCancelVisible(false)}
          >
                <h2>Why are you rejecting this?</h2>

                <br />
                <Select
                  options={approvalDescription.indexOf('fraud') > -1 ? fraudReasons : rejectReasons}
                  label="Why are you rejecting this?"
                  onChange={(v) => setApprovalReason(v.target.value)}
                  placeholder="Select a reason"
                  value={approvalReason}
            />
          </Modal>
        </div>
          }
          <div style={{ textAlign: 'left', fontWeight: 700, color: '#333', fontSize: 16 }}> Waiting for {approval.approvalGroups.map(g => g.name).join(', ')}
          </div>
    </div>
  </div>}
  </>
  )
}
Approve = withApollo(Approve)
export default graphql(GET_APPROVAL, { options: ({ id}) => ({ variables: { id } }) })(Approve)