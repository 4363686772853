import React, { Fragment, Component, useState } from 'react'
import gql from 'graphql-tag'
import { GraphQLFormProvider, FormField, FieldValue } from 'react-form-helper'
import { isValidEmail, isValidRsaIdNumber } from '../../lib/validations'
import { BENEFICIARIES } from './Beneficiaries'
import { IdTypes, RELATIONSHIPS } from '../../variables'

import BANKS from "../../variables/banks"

import { Card, renderInput, renderDisplay, BuildLayout } from '../generic';


const config = {
  name: 'funeralbeneficiary',
  fields: {
    'beneficiaries[0].payTo.firstNames': {
      type: 'name',
      label: 'First Name *',
      validate: (value) => {
        if (!value) {
          return 'First name is Required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    },
    'beneficiaries[0].payTo.surname': {
      type: 'name',
      label: 'Last Name *',
      validate: (value) => {
        if (!value) {
          return 'Last name is required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    },
    'beneficiaries[0].payTo.idType': {
      type: 'select',
      label: 'ID Type*',
      nullOnly: true,
      options: IdTypes,
      validate: (value) => {
        if (!value) {
          return 'ID type is Required'
        }
      }
    },
    'beneficiaries[0].payTo.idNumber': {
      type: 'text',
      label: 'ID Number*',
      nullOnly: true,
      validate: (value, row) => {
        if (!value) {
          return 'ID type is Required'
        }
        else if (row.idType && row.idType === 'rsa_id' && isValidRsaIdNumber(value)) {
          return 'ID Number is invalid'
        }
      }
    },

    'beneficiaries[0].payTo.cellNumbers[0]': {
      type: 'text',
      label: 'Mobile Number*',
      validate: (value) => {
        if (!value) {
          return 'Mobile is Required'
        }
        if (value.length > 10) {
          return 'Phone numbers must be 10 long'
        }
      }
    },
    'beneficiaries[0].payTo.email': {
      type: 'email',
      label: 'Email*',
      validate: (value) => {
        if (value && isValidEmail(value)) {
          return 'Email not valid'
        }
      }
    },
    // 'beneficiaries[0].bankAccount.branchCode': {
    //   type: 'select',
    //   label: 'Bank',
    //   options: BANKS,
    //   addOnly: true
    // },
    // 'beneficiaries[0].bankAccount.accountNumber': {
    //   type: 'text',
    //   label: 'Account Number',
    //   addOnly: true
    // },
    relationship: {
      label: "Relationship*",
      type: "select",
      options: RELATIONSHIPS.sort((a, b) => (a > b) ? 1 : -1).map(a => ({ value: a, label: a })),
      validate: (value) => {
        if (!value) {
          return 'Relationship is Required'
        }
      }
    },
  },
  query: gql`
  query Member($id: String!) {
    funeral(id: $id) {
      id
      beneficiaries {
        id
        payTo {
          id
          firstNames
          surname
          idType
          idNumber
          cellNumbers
          clientId
        }
        bankAccount {
          id
          branchCode
          accountNo
        }
      }
    }
  }`,
  addMutation: gql`
  mutation AddFuneralBeneficiary($funeralId: ObjID!, $beneficiary: AddBeneficiaryInput) {
    addFuneralBeneficiary(funeralId:$funeralId, beneficiary: $beneficiary) {
      id
      beneficiaries {
      id
      payTo {
        id
        firstNames
        surname
        idType
        idNumber
        cellNumbers
        clientId
      }
      bankAccount {
        id
        branchCode
        accountNo
        }
      }
    }
  }`,
  updateMutation: gql`
    mutation UpdateBeneficiary($input: UpdateMemberInput!, $id: String!) {
    # mutation UpdateFuneral($input: UpdateFuneralInput!, $id: String!, $memberId: String!,$memberInput: UpdateMemberInput!) {
      updateMember(id: $id, input: $input) {
        id
        beneficiaries {
        id
        payTo {
          id
          firstNames
          surname
          idType
          idNumber
          cellNumbers
          clientId
        }
        bankAccount {
          id
          branchCode
          accountNo
          }
        }
      }
      # updateMember(id: $memberId, input: $input) {
      #   id
      # }
  }`,
}

function FormLayout({ mode, cancel, data, formName }) {

  console.log('beneficiary layout data', data)
  const { funeral } = data || {}
  const { beneficiaries } = funeral || {}
  const { payTo } = beneficiaries && beneficiaries.length > 0 ? beneficiaries[0] : {}
  const { clientId } = payTo || {}

  return (
    <Card>
      <BuildLayout formName={formName} fieldNames={Object.keys(config.fields).filter(f => !f.hide)} fieldConfig={config.fields} mode={mode} direction='column' spacing={2} align='center' justify='center' cancel={cancel} allowNull={!clientId} />
    </Card>
  );
}

let NewBeneficiary = (props) => {
  const { id, funeralId, cancel, mode } = props

  return (
    <div>
      <GraphQLFormProvider
        id={id}
        {...config}
        mode={mode}
        initialValues={{ 'beneficiaries[0].percentage': 100 }}
        initialValuesFromData={(data) => data.funeral}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout formName={config.name }  cancel={cancel} {...props} />}
        mapInputVariables={async (props) => {
          console.log('mapInputVariables ', props)
          if (mode === 'edit') {
            const { beneficiaries, ...inputProps } = props
            console.log('mapInputVariables ben', beneficiaries.length > 0 && beneficiaries[0]);
            const { id, payTo, bankAccount, ...rest } = beneficiaries[0]
            //const { id, ...input } = payTo
            console.log('edit transformed=', payTo);
            return { input: payTo }
          }
          else {
            const { beneficiaries, relationship } = props
            const { payTo, ...rest } = beneficiaries[0]
            const values = ({
              funeralId: id,
              beneficiary: { ...payTo, relationship }
            });
            console.log('mapInputVariables values=', values);
            return values
          }
        }}
        afterSubmit={() => { cancel() }}
        refetchQueries={[{ query: BENEFICIARIES, variables: { id: funeralId } }]}
      />
    </div>
  )
}

export default NewBeneficiary