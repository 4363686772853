import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery, withApollo } from 'react-apollo'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Button, TinyMCE, TextInput, Select, Grid } from '../generic'
import { R4_WHATSAPP_NUMBER } from '../../config'
import { CLIENT_COMMUNICATIONS } from '../shared/client/ClientCommunications'

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingRight: 0,
    paddingBottom: 21
  },
  button: {
    "& .MuiButton-label": {
      minWidth: "150px!important",
    }
  },
}));

const GET_CLIENT = gql`
  query Client($id: String!) {
    client(id: $id) {
      id
      email
      firstNames
      surname
    }
    me {
      id
      first
      last
      mobileNumber
      email
    }
  }
`

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CREATE_COMMUNICATION = gql`
	mutation CreateCommunication($input: CreateCommunicationInput!) {
		createCommunication(input: $input) {
			id
		}
	}
`

const toHHMMSS = (value) => {
  var sec_num = parseInt(value, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes + ':' + seconds;
}

const prettyPhone = (number) => {

  if (number.startsWith('+')) {
    return number.substr(0, 2) + ' ' + number.substr(2, 3) + ' ' + number.substr(6, 3) + ' ' + number.substr(9, 4)
  }
  else return number
}

const SendEmail = ({ clientId, funeralId, creditLifeId, groupProductId, groupId, history, ...props }) => {
  const { data, loading, error } = clientId && useQuery(GET_CLIENT, { variables: { id: clientId } }) || {}
  const [open, setOpen] = useState(false)
  const [showSMS, setShowSMS] = useState(false)
  const classes = useStyles();
  const [message, setMessage] = useState()
  const [to, setTo] = useState()
  const [from, setFrom] = useState()
  const [subject, setSubject] = useState(`With reference to your ${funeralId ? 'Funeral policy' : creditLifeId ? 'Credit Life policy' : 'account'} `)
  const { client, me } = data || {}
  const { firstNames, surname, email } = client || {}
  const { first, last, mobileNumber, email: myEmail } = me || {}
  const [fromEmails, setFromEmails] = useState();

  useEffect(() => {
    setTo(email)
    setFrom(funeralId ? 'funeral@meerkat.co.za' : creditLifeId ? 'insurance@meerkat.co.za' : '')
    setFromEmails(['claims@meerkat.co.za', 'insurance@meerkat.co.za', 'funeral@meerkat.co.za', 'savings@meerkat.co.za'])
  }, [client]);

  useEffect(() => {
    setMessage(`Dear ${firstNames && firstNames.split(' ')[0]},
    <br /><br /><br /><br />
    Regards,
      <br /><br />
      ${first && first.split(' ')[0]} ${last}</span> <br />
      <strong>Tel &nbsp;     </strong>: +27 21 003 4521<br />
      <strong>WhatsApp &nbsp;</strong>: ${prettyPhone(R4_WHATSAPP_NUMBER)}</span> <br />
      www.meerkat.co.za</span>
    `)
  }, [client, me]);


  const createCommunication = (input) => {
    props.client.mutate({
      mutation: CREATE_COMMUNICATION,
      variables: {
        input
      },
      refetchQueries: [{ query: CLIENT_COMMUNICATIONS, variables: { id: clientId } }]
    })
      .then(response => {
        setOpen(false);
      })
      .catch(error => {
        console.log('there was an error sending the query', error)
      })
  }

  const onSend = () => {
    <Snackbar open={true} autoHideDuration={6000} >
      <Alert severity="info">
        `Sending email to ${email}`
      </Alert>
    </Snackbar >

    const input = { clientId, type: 'EMAIL', message, to, from, subject }

    if (funeralId) {
      input.funeralId = funeralId
    }
    if (creditLifeId) {
      input.creditLifeId = creditLifeId
    }
    if (groupProductId) {
      input.groupProductId = groupProductId
    }
    if (groupId) {
      input.groupId = groupId
    }
    createCommunication(input)
  }

  if (loading) { return <div>Loading...</div> }

  return (
    <>
      <div style={{ fontSize: 14, fontWeight: 400, color: 'blue' }} onClick={(data) => setOpen(true)} >
        Email
      </div >

      <Dialog aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xl"
        onClose={() => setOpen(false)}
        open={open}
      >
        <DialogContent>
          <Grid container spacing={2} justifyContent='flex-start'>
            <Grid item xs={3}>
              <TextInput label={`To`} fullWidth value={to} placeholder={`Email`} id="email"
                onChange={e => setTo(e.target.value)} />
            </Grid>
            <Grid item xs={3}>
              <Select options={fromEmails && fromEmails.map(f => ({ 'value': f, 'label': f }))} label={`From`} fullWidth value={from} placeholder={`From Email`} id="fromEmail"
                onChange={e => setFrom(e.target.value)} />
            </Grid>
          </Grid>
          <br />
          <TextInput label={`Subject`} fullWidth value={subject} placeholder={`Subject`} id="subject"
            onChange={e => setSubject(e.target.value)} />
          <br /> <br />
          <TinyMCE value={message} onChange={e => setMessage(e)} />
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button className={classes.button} onClick={() => setOpen(false)} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button disabled={!to || !message} className={classes.button} onClick={() => {
            onSend();
            setOpen(false);
          }}
            color="primary" variant="contained" style={{ marginRight: 20 }}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default withApollo(SendEmail);