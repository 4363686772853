import React, { Component } from 'react'
import { ResponsiveContainer, ReferenceLine, LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip, Legend } from 'recharts'
import { Modal } from '../../generic'
import DashboardCard from '../DashboardCard'

const seriesColors = ['#8884d8', '#82ca9d', '#0084d8']

const ResponsiveLineChart = (props) => {
  console.log(props)
  const { data, label, x, y, goalLine } = props
  return (
    <ResponsiveContainer aspect={2}>
      <LineChart data={data} margin={{ top: 5, right: 20, left: 0, bottom: 5 }}>
        <XAxis dataKey={x} />
        <YAxis width={40} />
        <Legend wrapperStyle={{ bottom: '-7px' }} style={{ paddingTop: 7 }} />
        <Tooltip />
        { goalLine && <ReferenceLine alwaysShow y={goalLine} label="Target" stroke="red" strokeWidth="2" Dasharray="3 3" /> }
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        {y.map((d, i) => (
          <Line
            key={d}
            type="monotone"
            dataKey={d}
            name={label ? label(d) : d}
            stroke={seriesColors[i]}
            connectNulls
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}

// TODO: Responsive container / measure width ...
class DashboardGraph extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false
    }
  }
  render() {
    return (
      <React.Fragment>

        <Modal open={this.state.expanded}
          onClose={() => this.setState({ expanded: !this.state.expanded })}
          noButtons={true}
        >
          {this.props.title}
          <ResponsiveLineChart data={this.props.data} label={this.props.label} x={this.props.x} y={this.props.y} goalLine={this.props.goalLine} />
        </Modal>

        <DashboardCard title={this.props.title} size={this.props.size} onExpandCard={() => this.setState({ expanded: !this.state.expanded })}>
          <ResponsiveLineChart data={this.props.data} label={this.props.label} x={this.props.x} y={this.props.y} goalLine={this.props.goalLine} />
        </DashboardCard>
      </React.Fragment>
    )
  }
}

export default DashboardGraph
