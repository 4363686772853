import React, { useState } from 'react';
import gql from 'graphql-tag';
import { withApollo, useQuery } from 'react-apollo'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import { Button, Card, Switch, TextInput, Modal, MaterialTable } from '../generic'
import Products from '../products/Product';

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingRight: 0,
    paddingBottom: 21
  },
  button: {
    "& .MuiButton-label": {
      minWidth: "150px!important",
    }
  },
}));

const prettyPhone = (number) => {
  let cell = number.replace('+27', '0').replace('+', '')
  if (cell && cell.length === 10) {
    return '(' + cell.substr(0, 3) + ') ' + cell.substr(4, 3) + '-' + cell.substr(6, 4)
  }
  else return cell
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GET_COMMUNICATIONS = gql`
	query Communications {
    communications(filter: { status: REPLY, type:SMS }, limit: 1000) {
      id
      funeral {
        id
        policyNumber
      }
      creditLife {
        id
        policyNumber
      }
      savingsId
      clientId
      client {
        idNumber
        firstNames
        surname
      }
      type
      to
      from
      message
      when
      createdAt
      deliveredAt
      status
      statusReason
      deliveredRawInfo
      rawInfo
    }
  }`

const CREATE_COMMUNICATION = gql`
	mutation CreateCommunication($input: CreateCommunicationInput!) {
		createCommunication(input: $input) {
			id
		}
	}
`
const MARK_REPLIED = gql`
	mutation MarkReplied($id:  String!, $input: UpdateCommunicationInput!) {
		updateCommunication(id: $id, input: $input) {
        id
        statusReason
      }
    }
`;


const columns = [
  {
    Header: 'Message',
    accessor: 'message',
    Cell: ({ value }) => (typeof value === 'string' && value.match(/<\/?[a-z][\s\S]*>/)) ? <div dangerouslySetInnerHTML={{ __html: value }} /> : value
  },
  {
    Header: 'from',
    accessor: 'from',
    Cell: ({ value }) => value ? prettyPhone(value) : ''
  },

  {
    accessor: 'createdAt',
    Header: 'Received',
    Cell: ({ value }) => value ? moment(value).format('DD-MM-YYYY hh:mm:ss') : ''
  },
  {
    Header: 'Client',
    accessor: 'client',
    Cell: ({ value }) => value ? `${value.idNumber} ${value.firstNames} ${value.surname}` : 'Not a client'
  },
  {
    accessor: 'id',
    Header: 'Policy',
    Cell: ({ row }) => { return row.original.funeral ? row.original.funeral.policyNumber : row.original.creditLife ? row.original.creditLife.policyNumber : ' ' }
  },
  {
    accessor: 'statusReason',
    Header: 'Status',
  },
];

const SmsReplies = ({ client, history }) => {
  const { data, loading, error } = useQuery(GET_COMMUNICATIONS) || {}
  const [open, setOpen] = useState(false)
  const [showSMS, setShowSMS] = useState(false)
  const [clientId, setClientId] = useState()
  const [id, setId] = useState()
  const [row, setRow] = useState()
  const classes = useStyles();
  const [message, setMessage] = useState()
  const [unreadOnly, setUnreadOnly] = useState(true)

  const markReplied = (input) => {
    client
      .mutate({
        mutation: MARK_REPLIED,
        variables: {
          id,
          input
        },
        refetchQueries: [{ query: GET_COMMUNICATIONS }]
      })
  }

  const createCommunication = (input) => {
    client.mutate({
      mutation: CREATE_COMMUNICATION,
      variables: {
        input
      }
    })
      .then(response => {
        markReplied({ statusReason: 'replied' });
        setOpen(false);
      })
      .catch(error => {
        console.log('there was an error sending the query', error)
      })
  }

  const onSend = (row) => {
    <Snackbar open={true} autoHideDuration={6000} >
      <Alert severity="info">
        `Sending ${row.type} to ${row.from}`
      </Alert>
    </Snackbar >
    const { id, replyToId, clientId, creditLifeId, savingsId, funeralId, from, type, message, ...rest } = row
    createCommunication({ clientId, creditLifeId, savingsId, funeralId, type, message, to: from })
  }

  if (loading) {
    return <div>Loading...</div>
  }

  const unreadData = data.communications.filter(x => x.statusReason !== 'replied')

  return (
    <Card>
      <Switch value={unreadOnly} onChange={() => setUnreadOnly(!unreadOnly)} label={unreadOnly ? `Unreplied SMS's (${data.communications.length})` : `All SMS's (${unreadData.length})`} />
      <MaterialTable
        columns={columns}
        data={unreadOnly ? unreadData : data.communications}
        initialPageSize={25}
        download={true}
        onRowPress={(data) => {
          setClientId(data.clientId);
          setRow(data);
          setId(data.id);
          setOpen(true);
        }}
      />

      <Dialog aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xl"
        onClose={() => setOpen(false)}
        open={open}
      >
        <DialogContent>
          {clientId ? <Products clientId={clientId} /> : <div style={{ position: 'fixed', left: '5%', top: '49vh' }}><h1>No client found for this number</h1></div>}
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button className={classes.button} onClick={() => setOpen(false)} color="secondary" variant="contained">
            Back
          </Button>
          <Button className={classes.button} color="default" onClick={() => setShowSMS(true)}>
            {row && row.type === 'SMS' ? 'Send SMS' : 'WhatsApp'} </Button>
          <Button className={classes.button} onClick={() => {
            setOpen(false);
            markReplied({ statusReason: 'replied' });
          }}
            color="primary" variant="contained" style={{ marginRight: 20 }}>
            Mark As Done
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={showSMS}
        title={`Reply to ${row ? row.from : ''}`}
        okText="Send"
        cancelText="Back"
        onOk={() => {
          onSend({ ...row, message })
          setShowSMS(false)
        }}
        disabled={!message}
        onCancel={() => setShowSMS(false)}
        onClose={() => setShowSMS(false)}
      >
        <TextInput label={`${row && row.type === ' SMS' ? 'SMS' : 'WhatsApp'} Message text`} fullWidth value={message} placeholder={`Enter ${row && row.type === 'SMS' ? 'SMS' : 'WhatsApp'} message text`} id="messageText"
          onChange={e => setMessage(e.target.value)} />
      </Modal>
    </Card>
  );
}
export default withApollo(SmsReplies);