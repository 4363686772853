import React from 'react';
import gql from 'graphql-tag';
import { GraphQLTable, Card, Button } from '../generic';
import moment from 'moment';

import {withRouter} from 'react-router'

const config = (id) => ({
  query: gql`
 {
   debtcounsellor(id:"${id}") {
     id
     fullName
     externalId
     fees {
      feeType
      startDate
      endDate
      fixedFee
      percentageFee
      minimumValue
      maximumValue
    }
  }
 }`,
  dataset: data => data.debtcounsellor,
  fields: {
    fullName: {label: 'Full Name'},
    externalId: { label: 'DC Nbr' },
    'fees.feeType': { label: 'License Fee' },
    'fees.startDate': {
      label: 'Start',
      accessor: row => (row.fees ? `${moment(row.fees.startDate).format('DD-MM-YYYY')}` : null)
    },
    'fees.endDate': {
      label: 'End',
      accessor: row => (row.fees ? `${moment(row.fees.endDate).format('DD-MM-YYYY')}` : null)
    },
    'fees.fixedFee': { label: 'Fixed Fee' },
    'fees.percentageFee': { label: 'Percentage' }
  }
})

const LicenseFees = ({ match, history }) => (

  <Card>
{console.log('match.params.id ', match.params.id)}
      <h1>
        License Fees

      <Button style={{ marginLeft: '80%', marginTop: 7 }} color="primary" onClick={() => history.push(`/debt-counsellors/${match.params.id}/fees/new`)}>
        Add License Fee
      </Button>

      </h1>

    <GraphQLTable
      config={config(match.params.id)}
      onRowPress={debtcounsellors => history.push(`/debt-counsellors/${debtcounsellors.fees.feeType}`)}
    />

  </Card>
);

export default withRouter(LicenseFees);