import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => ({
  root: {
    marginTop: 8,
    marginBottom: 6,
    "& .MuiInputBase-root": {
      paddingTop: 4,
      paddingBottom: 3,
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingLeft: 10
      },
      "& .MuiInputBase-input": {
        padding: 15,
        paddingLeft: 10
      }
    }
  }
});

class DateField extends React.PureComponent {
  handleDateChange = (date) => {
    date
      ? this.props.onChange(date.valueOf())
      : this.props.onChange()
      ;
  };

  render() {
    const {
      label,
      classes,
      value,
      includeTime,
      minDate,
      maxDate,
      disableFutureDates,
      fullWidth,
      disabled,
      accessEditable,
      format = 'dd MMMM yyyy',
    } = this.props;

    const dis = accessEditable === false || disabled;

    let shrinkLabel = false;

    const Component = includeTime ? DateTimePicker : DatePicker;

    // The picker doesn't play well with the label from Material-UI so we need to manually shrink
    // the label when there is a value.
    shrinkLabel = !!value;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Component
          // Value cannot be undefined or else picker will default to today
          value={value ? parseInt(value) : null}
          label={label}
          onChange={this.handleDateChange}
          clearable
          className={classes.root}
          minDate={minDate}
          maxDate={disableFutureDates ? new Date() : maxDate}
          fullWidth={fullWidth}
          disabled={dis}
          id={uuidv4()}
          format={format}
          variant="dialog"
          inputVariant="outlined"
        />
      </MuiPickersUtilsProvider>
    );
  }
}

export default DateField = withStyles(styles)(DateField);
