import React from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag'
import { GraphQLFormProvider, FormField, FieldValue, FieldLabel } from 'react-form-helper';
import { renderInput, renderDisplay, Card, BuildLayout } from '../generic';
import { withRouter } from 'react-router'
import BANKS from '../../variables/banks'
import { claimInformation } from '../claims/ClaimAdmin'
import '../../'

const findBank = async (branchCode) => {
  const bank = BANKS.filter(bank => bank.value === branchCode)
  return bank.length > 0 ? bank[0].label : ''
}


const UpdateClientObligation = gql`
	mutation($id:  String!,  $input:UpdateClientObligationInput! ) {
		updateClientObligation(id: $id, input: $input) {
      id
    }
  }
`;

const masonryStyle = {
  display: "flex",
  flexFlow: "row wrap",
  width: "100%"
}

const masonryBrick = {
  flex: "auto",
  minWidth: "250px",
  margin: "0 8px 8px 0", /* Some gutter */
  alignSelf: "flex-end"
}

const config = {
  name: 'clientobligation',
  fields: {
    'branchCode': {
      type: 'text',
      label: 'Bank Name *',
      type: 'select',
      label: 'Bank Name *',
      options: BANKS,
      validate: (value) => {
        if (!value) {
          return 'Bank is required';
        }
      }
    },
    'accountNo': {
      type: 'text',
      label: 'Account Number *',
      validate: (value) => {
        if (!value) {
          return 'Account is required';
        }
      }
    },
    'accountHolderName': {
      type: 'text',
      label: 'Account Holder Name *',
      validate: (value) => {
        if (!value) {
          return 'Account holder name is required';
        }
      }
    },
    'claimPaymentReference': {
      type: 'text',
      label: 'Payment reference *',
      validate: (value) => {
        if (!value) {
          return 'Reference is required';
        }
      }
    }
  },
  query: gql`
		query BankAccount($id: String!) {
			bankaccount(id: $id) {   
        id
        bankName
        branchCode
        accountNo
        accountHolderName
      }
    }
  `,
  updateMutation: gql`
		mutation UpdateBankAccount( $id: String!, $input: UpdateBankAccountInput!) {
			updateBankAccount(id: $id, input: $input) {
          id
          bankName
          branchCode
          accountNo
          accountHolderName
      }
    }`,
  addMutation: gql`
		mutation CreateBankAccount($input: CreateBankAccountInput!) {
			createBankAccount(input: $input) {
          id
          bankName
          branchCode
          accountNo
          accountHolderName
      }
    }`
};

let FormLayout = (props) => {
  const { mode, id, setMode, invalid, submit, history, cancel, fields, changeFieldValue, formName } = props

  const filtered = (Object.keys(config.fields).filter(fieldName => !config.fields[fieldName].hide && ((mode) === 'edit' || !config.fields[fieldName].noAdd)))

  return (
    <Card>
      <h1>{mode === "edit" && "Edit"} Creditor Payment Info</h1>

      <BuildLayout formName={formName} fieldNames={filtered} mode={mode} fieldConfig={config.fields} direction='column' spacing={2} align='center' justify='center' cancel={cancel} push={() => history.push('/creditors')} />
    </Card>
  );
}

FormLayout = withRouter(FormLayout)

let ClientObligation = (props) => {

  const { mode, claimId, id, clientObligationId, history, afterSubmit, cancel, client, loan } = props;
  const { claimPaymentReference, claimPaymentBankAccount, claimPaymentBankAccountId } = loan

  let reference

  const updateClientObligation = (props) => {
    const { data } = props

    const { id } = data.createBankAccount || data.updateBankAccount

    client.mutate({
      mutation: UpdateClientObligation,
      variables: {
        id: clientObligationId,
        input: { claimPaymentBankAccountId: id, claimPaymentReference: reference }
      },
      refetchQueries: [{ query: claimInformation, variables: { id: claimId } }]
    })
      .then((result) => {
        if (afterSubmit) {
          afterSubmit()
        }
        else {
          history.push('/creditors');
        }
      });
  }

  let initialValues
  if (!claimPaymentBankAccountId) {
    initialValues = {
      claimPaymentReference, ...claimPaymentBankAccount,
    }
  }


  return (
    <div>
      <GraphQLFormProvider
        name="ClientObligation"
        mode={claimPaymentBankAccountId ? "edit" : "add"}
        id={claimPaymentBankAccountId}
        {...config}
        initialValues={initialValues}
        initialValuesFromData={(data) => {
          return ({ ...data.bankaccount, claimPaymentReference })
        }}

        mapInputVariables={async (props) => {
          //console.log('clientObligation mapInputVariables', props)
          const { branchCode, clientobligation, clientCreditor, claimPaymentBankAccount, claimPaymentReference, ...rest } = props
          reference = claimPaymentReference

          let input = { ...rest }

          if (branchCode) {
            input.bankName = await findBank(branchCode)
            input.branchCode = branchCode
          }

          // console.log('clientObligation mapInputVariables return ', { input })
          return { input }
        }}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout {...props} formName={config.name} cancel={cancel} />}
        DisplayFormLayout={FormLayout}
        afterSubmit={props => updateClientObligation(props)}
      />
    </div>
  );
}

export default withApollo(ClientObligation);