import React, { Component, Children } from 'react'
import 'font-awesome/css/font-awesome.css'
import FilterItem from './FilterItem'

// Children must be FilterItems ...
class FilterBar extends Component {
  render() {
    const { children } = this.props

    return (
      <div style={{ flexGrow: 1, backgroundColor: 'white', flexDirection: 'row', display: 'flex', borderBottom: "1px solid #c8c8c8" }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
        {
          Children.map(children, child => {
            if (!child.props.right) {
              return child
            }
          })
        }
        </div>
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
        {
          Children.map(children, child => {
            if (child.props.right) {
              return child
            }
          })
        }
        </div>
      </div>
    )
  }
} 
 
export default FilterBar
