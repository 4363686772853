import React, { useState } from 'react'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { GraphQLFormProvider, FormField } from 'react-form-helper'
import { isInteger } from '../../lib/validations'
import { renderInput, BuildLayout, renderDisplay } from '../generic';
import { FUNERAL_PAYMENT_TYPES } from '../../variables/paymentTypes'
import { funeralInformation } from './FuneralInfo'
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'

// switch between locales
Numeral.locale('en-za')

const config = {
  name: 'funeral',
  fields: {
    coverAmount: {
      type: 'select',
      label: 'Coverage Amount',
      options: [],
      disabled: false,
      validate: (value) => {
        if (!value) {
          return 'Amount is required'
        }
      }
    },
    'paymentMethod': {
      type: 'select',
      label: 'Payment Method',
      options: FUNERAL_PAYMENT_TYPES.map(e => ({ label: e, value: e })).sort((a, b) => (a <= b ? -1 : 1))
    },
    'paymentDueDay': {
      type: 'text',
      label: 'Payment Due Day ',
      validate: (value) => {
        if (!value) {
          return 'Day of the month is required'
        }
        else if (value && isInteger(value)) {
          return 'Must be a day of the month'
        } else if (value && (value > 31 || value < 1)) {
          return 'Must be a day of the month'
        }
      }
    },
    'premiumCreditorEnabled': {
      type: 'switch',
      label: "Premium Creditor Enabled"
    }
  },
  query: gql`
    query FuneralData($id: String!) {
      funeral(id: $id) {
        id
        paymentDueDay
        paymentMethod
        coverAmount
        premiumCreditorEnabled
      }
    }`,
  updateMutation: gql`
    mutation UpdateFuneral($input: UpdateFuneralInput!, $id: String!) {
      updateFuneral(id: $id, input: $input) {
        id
        paymentDueDay
        paymentMethod
        coverAmount
        premiumCreditorEnabled
      }
  }`,
  addMutation: gql`
  mutation CreateFuneral($input: CreateFuneralInput!) {
    createFuneral(input: $input) {
      id
      paymentDueDay
      paymentMethod
      coverAmount
      premiumCreditorEnabled
    }
  }`
}

const FormLayout = (props) => {
  console.log('edit funeral props', props)
  let { cancel, history, data, formName } = props;
  let { funeral } = data || {};
  let { coverAmount } = funeral || 0;

  const coverage = []
  let cover = 10000;
  while (cover <= coverAmount) {
    coverage.push(cover);
    cover += 10000;
  }
  config.fields['coverAmount'].options = coverage.map(a => ({
    value: a, label: Numeral(a).format('$ ##,###0.')
  }))

  return (<BuildLayout formName={formName }  fieldNames={Object.keys(config.fields)} fieldConfig={config.fields} direction='column' spacing={2} align='center' justify='center' cancel={cancel} push={() => history.push('/bankaccounts')} />);
}

let EditFuneral = (props) => {

  const { cancel, id, history, coverageChanges } = props

  if (!coverageChanges) {
    config.fields['coverAmount'].disabled = true
  }

  return (
    <div>
      <GraphQLFormProvider
        mode="edit"
        id={id}
        {...config}
        afterSubmit={({ data }) => { cancel() }}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout {...props} formName={config.name} cancel={cancel} history={history} />}
        DisplayFormLayout={FormLayout}
        initialValuesFromData={data => data.funeral}
        refetchQueries={[{ query: funeralInformation, variables: { id } }]}
        mapInputVariables={props => {
          console.log('map input variables', props)
          let { firstNames, surname, ...input } = props
          if (firstNames) {
            input.firstNames = nameCase(firstNames)
          }
          if (surname) {
            input.surname = nameCase(surname)
          }
          return ({ input })
        }}
      // defining the input to use the input needed in the update mutation.
      />
    </div>
  )
}

EditFuneral = withApollo(EditFuneral);
export default EditFuneral