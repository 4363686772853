import React, { Component } from 'react'
import { Modal } from '../generic'
import Approve from './Approve'

class ApprovalInfo extends Component {
  render() {
    const { approvalType } = this.props.approval

    if (approvalType === "CLAIM_VERIFICATION") {
      // Credit Life / Funeral
    }

    return <div />
  }
}

class ApprovalModal extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { approval, visible, closeModal } = this.props

    return (
      <Modal
        title="Approval"
        open={visible}
        onClose={() => closeModal()}
        onOk={() => closeModal()}
        onCancel={() => closeModal()}
        footer={null}
      >
        <ApprovalInfo approval={approval} />
        <Approve id={approval && approval.id} />
      </Modal>
    )
  }
}

export default ApprovalModal
