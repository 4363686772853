import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { GraphQLFormProvider, FormField } from 'react-form-helper'
import { Button, BuildLayout } from '../generic';

import {withRouter} from 'react-router'
import { connect } from 'react-redux'
import { isInvalid } from 'redux-form'
import { isValidCellphoneNumber, isValidEmail, isValidIdNumber, isInteger } from '../../lib/validations'

import { renderInput, renderDisplay } from '../generic';

const images = require('../../assets/images/index.js')
let profileImage = images.blankProfile

function myRenderInputField(type) {
  if (type === 'file' || type === 'image') {
    return function (props) {
      // delete props.input.value
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            className="img-circle img-responsive center-block"
            src={props.input && props.input.value.url}
            style={{ height: 100, margin: 7 }}
          />
        </div>
      )
    }
  } else {
    return renderInput(type)
  }
}

const config = {
  name: 'client',
  fields: {
    acceptedTerms: {
      type: 'switch',
      label: 'Accepted'
    }
  },
  query: gql`
    query ClientData($id: String!) {
      client (id: $id) {
        id
        acceptedTerms
      }
    }`,
  updateMutation: gql`
    mutation UpdateClient($input: UpdateClientInput!, $id: String!) {
      updateClient(id: $id, input: $input) {
        id
        acceptedTerms
      }
  }`
}
const FormLayout = (props) => {
  const { submit, cancel,formName } = props;
    return (
      <BuildLayout formName={formName} fieldNames={Object.keys(config.fields)} fieldConfig={config.fields} direction='column' spacing={2} align='center' justify='center' submit={submit} cancel={cancel} />
    );
}



class Terms extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.id !== nextProps.id || this.props.mode !== nextProps.mode
  }

  render() {
    const { mode, id } = this.props

    return (
      <div>
        <GraphQLFormProvider
          mode={mode==="display"?"display":"edit"}
          id={id}
          {...config}
          afterSubmit={() => { this.props.afterSubmit(false) }}
          renderDisplay={renderDisplay}
          renderInput={myRenderInputField}
          InputFormLayout={(props) => <FormLayout formName={config.name} cancel={this.props.cancel} /> }
          DisplayFormLayout={FormLayout}
        />
      </div>
    )
  }
}

export default Terms
