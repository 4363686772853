import styled from '@emotion/styled'

const ValueItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 4px;
`

const ValueItemLabel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  padding: 4px;
`

const ValueItemAction = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  padding: 4px;
`

const ValueItemValue = styled.div`
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  background: ${props => props.theme.colors.grey};
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 4px;
`

export {
  ValueItemContainer,
  ValueItemLabel,
  ValueItemAction,
  ValueItemValue
}
