import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Button, Select, DisplayFile, Grid } from '../generic';

const CREATE_DOCUMENT = gql`
  mutation createClaimDocument($input:CreateClaimDocumentInput!) {
    createClaimDocument(input:$input  ) {
        id
        description
        required
    }
  }
`

const DOC_TYPES = [
  {
    value: 'Birth certificate',
    label: 'Birth certificate'
  },
  {
    value: 'Certificate of Estate',
    label: 'Certificate of Estate'
  },
  {
    value: 'Agon report',
    label: 'Agon report'
  },
  {
    value: 'Marriage Certificate',
    label: 'Marriage Certificate'
  },
  {
    value: 'Police report',
    label: 'Police report'
  },
  { value: 'Letter of Authority', label: 'Letter of Authority' },
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    direction: 'column',
    alignItems: 'flex-end',
    paddingBottom: 7
  },
  footer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingBottom: 21,
    paddingTop: 21
  },
  paper: {
    flexGrow: 1,
    minWidth: '200',
  },
  button: {
    minWidth: 100,
    maxWidth: 100,
    textAlign: 'center',
    marginRight: 14
  }
}));

let NewClaimDocument = ({ documents, setDocuments, cancel, clientInfo, claimId, client }) => {
  const classes = useStyles();
  const [type, setType] = useState()
  const [fileId, setFileId] = useState()

  const handleSave = () => {

    client.mutate({
      mutation: CREATE_DOCUMENT,
      variables: {
        input: {
          fileId,
          description: type || 'Admin requested',
          associatedClaimsIds: [claimId]
        }
      }
    })
      .then((result) => {
        let newDocuments = documents
        newDocuments.push({ id: result.data.createClaimDocument.id, fileId, description: type })
        setDocuments(newDocuments)
        console.log('NewClaimDocument documents', newDocuments, result)
      })
  }

  console.log('NewClaimDocument', clientInfo)

  return (<>
    <Grid container alignItems='center' spacing={2} className={classes.root}>
      <Grid item xs={6} className={classes.paper}>
        <Select variant="outlined" label='Document Type' value={type} options={DOC_TYPES} onChange={(v) => { setType(v.target.value) }} />
      </Grid>
      {/* <Grid item className={classes.paper}>
        <TextField className={classes.paper} variant="outlined" label='Description' value={doc && doc.description} onChange={(v) => setDoc({ ...doc, description: v.target.value })} />
      </Grid> */}
      <Grid item xs={2} className={classes.paper}>
      </Grid>
      {/* <Grid item xs={2} className={classes.paper}>
        <Switch label='Required' value={doc && doc.required} onChange={(v) => setDoc({ ...doc, required: v.target.checked })} />
      </Grid> */}
      <Grid item xs={1} className={classes.paper}>
        <DisplayFile fileId={fileId} clientInfo={clientInfo} documentType={type} noFileLookup={true} noApproval={true} noLabel={true} onChange={(v) => { console.log('newClaimDocument added', v); setFileId(v); }} />
      </Grid>
    </Grid>
    <Grid container className={classes.footer}>
      <Grid item className={classes.button}>
        <Button fullWidth variant='contained' color='default' onClick={cancel}>
          Cancel
        </Button>
      </Grid>
      <Grid item className={classes.button}>
        <Button fullWidth variant='contained' color='primary' onClick={handleSave}>
          Save
        </Button>
      </Grid>
    </Grid>
  </>)
}

export default withApollo(NewClaimDocument)