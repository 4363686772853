const PRODUCT_TYPES = [
  { "label": "CLEARANCE_CERTIFICATE", "value": "CLEARANCE_CERTIFICATE" },
  { "label": "CREDIT_LIFE", "value": "CREDIT_LIFE" },
  { "label": "SAVINGS", "value": "SAVINGS" },
  { "label": "FUNERAL", "value": "FUNERAL" }
]

// enum ProductType {
//   FUNERAL
//   CREDIT_LIFE
//   SAVINGS
//   SAVINGS_BUCKET
//   MULTIPLE
//   SNOWBALL
//   DEBT_COUNSELLING
// }

export default PRODUCT_TYPES