import React, { useState, Fragment } from 'react'
import { useConfirm } from "material-ui-confirm";
import ClaimPayments from './ClaimPayments'
import PaymentSchedule from './PaymentSchedule'
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { withRouter } from 'react-router-dom'
import { GraphQLFormProvider, FormField, FormFieldArray, FieldValue, SubmitButton } from 'react-form-helper'
import { ActivityIndicator, Modal, FormModal, Button, Select, renderInput, DisplayFile, renderDisplay, DisplayItem, Grid, Switch, TextInput } from '../generic'
import Approval from '../approvals/Approve'
import { claimInformation } from './ClaimAdmin'
import { loadList } from './ClaimList'
import NewClaimBeneficiary from './NewClaimBeneficiary';
import BankAccount from '../bankAccount/BankAccount';
import Member from '../funeral/Member';
import NewClaimDocument from './NewClaimDocument'
import moment from "moment";

const DOCTORS = [{ label: 'Quack', value: 1 }, { label: 'David O', value: 2 }]
const PARLOURS = [{ label: 'Birds of Eden', value: 1 }, { label: 'Meerkaat', value: 2 }]

const approvalTypes = {
  'CLAIM_VERIFICATION': 'Claim Approval/Rejection'
}

const useStyles = makeStyles((theme) => ({
  even: {
    flexGrow: 1,
    justifyContent: 'center',
    paddingBottom: 7
  },
  start: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 7
  },
  documents: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 7,
    paddingLeft: 7,
  },
  column: {
    minWidth: 300,
    padding: 0,
  },
  paper: {
    flex: 1,
    padding: theme.spacing(1),
    textAlign: 'center',
  },
}));

const cancelReasons = [
  { value: "noResponse", label: "No response from client" },
  { value: "missingInfo", label: "Incomplete application" },
  {
    value: "timeExpired", label: "Claimant didn't provide all information"
  },
  { value: "fraud", label: "Suspected fraud" },
  { value: "noSupport", label: "Information does not support a claim." }
]

const EditModal = (props) => {
  const { size } = props || 'small'
  return (
    <FormModal
      okText='Ok'
      cancelText='Back'
      width='100%'
      style={{ top: 10 }}
      disableCancel={true}
      disableOk={true}
      size={size}
      componentProps={props}
    />
  )
}

export const getRequirements = gql`
  query getRequirements(
    $claimType: ClaimType
    $products: [ProductType]
    $clientId: String!
  ) { getClaimRequirements(claimType: $claimType, products: $products) {
      id
      documents {
        id
        requirementId
        documentType
        description
        required
        requireCertified
        maximumAgeDays
      }
      info {
        name
        key
        type
        options
      }
    }
    client(id: $clientId) {
      id
      documents {
        id
        name
        type
        description
        file {
          id
          url
        }
        certified
        verified
      }
    }
  }
`

export const claimInfo = gql`
  fragment ClaimInfo on Claim {
    id
    state
    relationship
    amountClaimed
    firstPaymentDate
    endsPolicy
    claimType
    productType
    paymentsIds
    cancelReason
    claimReason
    info {
      key
      value
    }
    beneficiaries {
      id
      payoutType
      percentage
      payToId
      payTo {
        id
        clientId
        firstNames
        surname
        idNumber
        idType
        cellNumbers
      }
      bankAccountId
      bankAccount {
        id
        clientId
        bankName
        branchCode
        accountNo
        ownAccount
        accountHolderName
        accountHolderIdNumber
        accountHolderContactNumber
        accountHolderEmail
        idNumberMatch
        nameMatch
        phoneMatch
        accountExists
        avsRawData
        } 
    }
    approvalsIds
    approvals {
      id
      approvalType
      cancelled
      description
    }
    claimPaymentCounter
    documents {
      id
      description
      month
      waived
      required
      verified
      fileId
      file {
        id
        url
        contentType
      }
      requirementId
      requirement {
        id
        requirementId
        documentType
        description
        required
      }
    }
    requirements {
      id
      documents {
        id
        requirementId
        documentType
        description
        required
        requireCertified
        maximumAgeDays
      }
      info {
        name
        key
        type
        options
      }
    }
  }
`

const SubmitClaim = gql`
  mutation SubmitClaim($id: String!) {
    submitClaim(id: $id) {
      ...ClaimInfo
    }
  }
  ${claimInfo}
`
const CompleteClaim = gql`
  mutation CompleteClaim($id: String!) {
    completeClaim(id: $id) {
      ...ClaimInfo
    }
  }
  ${claimInfo}
`

const CancelClaim = gql`
  mutation CancelClaim($id: String!, $cancelReason: String) {
    cancelClaim(id: $id, cancelReason: $cancelReason) {
      ...ClaimInfo
    }
  }
  ${claimInfo}
`
const UndoClaimRejection = gql`
  mutation UndoClaimRejection($id: String!, $reason: String) {
    undoClaimRejection(id: $id, reason: $reason) {
      ...ClaimInfo
    }
  }
  ${claimInfo}
`

const RejectClaim = gql`
  mutation RejectClaim($id: String!, $reason: String) {
    rejectClaim(id: $id, reason: $reason) {
      ...ClaimInfo
    }
  }
  ${claimInfo}
`

const FraudReview = gql`
  mutation FraudReview($id: String!) {
    sendForFraudReview(id: $id) {
      ...ClaimInfo
    }
  }
  ${claimInfo}
`

const ClaimInfoNotComplete = gql`
  mutation ClaimInfoNotValid($id: String) {
    claimInfoNotValid(id: $id) {
      ...ClaimInfo
    }
  }
  ${claimInfo}
`

const recurringClaimCounterMax = {
  RETRENCHMENT: 12,
  TTD: 6,
  SHORT_TIME: 36,
  COMPULSORY_UNPAID_LEAVE: 36
}

const fields = {
  firstPaymentDate: {
    type: 'date',
    label: 'First Payment Date'
  },
  claimReason: {
    type: 'text',
    label: 'Claim Reason'
  },
  cancelReason: {
    type: 'text',
    label: 'Claim Reason'
  },
  info: {
    type: 'keyvalue',
    required: true,
    config: {
    }
  },
  state: {
    strip: true
  },
  approvals: {
    type: 'fieldarray',
    strip: true
  },
  requirements: {
    strip: true
  },
  approvalsIds: {
    type: 'hidden'
  },
  claimNumber: {
    strip: true
  },
  'beneficiaries[0].bankAccountId': {
    type: 'text'
  },
  documents: {
    label: 'Documents',
    type: 'fieldarray',
    config: {
      description: {
        type: 'text',
        validate: (value) => {
          if (!value) {
            return 'Document description is required'
          }
        }
      },
      'required': {
        label: 'Required',
        type: 'checkbox'
      },
      month: {
        label: 'Month',
        type: 'text'
      },
      verified: {
        label: 'Verified',
        type: 'checkbox'
      },
      waived: {
        label: 'Waived',
        type: 'checkbox'
      },
      fileId: {
        type: 'fileupload',
        prompt: 'Upload',
        smallIcon: true,
      },
      file: {
        strip: true
      },
      'requirement.documentType': {
        type: 'text',
        displayOnly: true
      },
      'requirement.description': {
        label: 'Type',
        type: 'text',
        displayOnly: true,
        strip: true
      },
      requirementId: {
        strip: true
      },
    }
  }
}

const expandDocumentDescription = (value) => {
  switch (value) {
    case 'deathCertificate': return 'Certified Death certificate or BI-1163 or BI-1680'
      break
    case 'deceasedIDDocument': return "Certified Identification Document of the Deceased"
      break
    case 'BI1663': return 'Certified Death certificate or BI-1163 or BI-1680'
      break
    case 'claimerIDDocument': return 'Certified Identification Document of the Claimant'
      break
    case 'principalIDDocument': return 'Certified Identification Document of the Policy Holder'
      break
    case 'bankingDetails': return "Certified copy of Claimant's bank statement with bank stamp"
      break
    case 'policyDocument': return 'The policy document'
      break

    default: return value
      break
  }
}

const warn = ({ info, documents }) => {

  let warnings = {}

  const infoConfig = fields.info.config

  warnings.info = infoConfig && Object.keys(infoConfig).map((field, index) => {
    const config = infoConfig[field]

    if (!config.required) {
      return undefined
    }
    else if (!info || !info[field]) {
      return { field: `${field} is required` }
    }
    else return undefined
  })

  const anyInfoWarnings = warnings.info && warnings.info.filter(w => w !== undefined)

  warnings.documents = documents && documents.length > 0 && documents.map((doc, index) => {
    console.log('warnings doc', doc)
    if (doc.waived) {
      return undefined
    }
    else if (doc.verified) {
      return undefined
    }
    else if (doc.verified === false) {
      return { fileId: 'A document is invalid' }
    }
    else if (doc.fileId) {
      return { fileId: 'Please verify the document' }
    }
    else if (doc.required && !doc.fileId) {
      return { fileId: 'This document is required' }
    }
    else if (!doc.required) {
      return undefined
    }
  })
  // check if there were warnings

  const anyDocWarnings = warnings.documents && warnings.documents.filter(w => w !== undefined)

  if ((anyInfoWarnings && anyInfoWarnings.length > 0) || (anyDocWarnings && anyDocWarnings.length > 0)) {
    return warnings
  }
  else return undefined
}

let Layout = (props) => {
  const confirm = useConfirm();
  const classes = useStyles();

  // console.log('claim props', props)
  const { id, productType, forFuneral, claimState, claimType, clientInfo, clientId, mode, clientDocuments, claimRequirements, changeFieldValue, data, forObligations, client, query, documentData, ...rest } = props

  let { claimPaymentCounter = 0, requirements, firstPaymentDate, claimReason, cancelReason: reason } = data.claim || {}

  const creditLifeClaim = productType !== 'FUNERAL'
  const [latestOnly, setLatestOnly] = useState((claimState === 'COMPLETED' || (claimType === 'DEATH' || claimType === 'STILLBORN')) ? false : true)
  const [cancelReason, setCancelReason] = useState(reason)
  const [spin, setSpin] = useState(false)
  const [cancelOrReject, setCancelOrReject] = useState()
  const [cancelVisible, setCancelVisible] = useState(false)
  const [addDocument, setAddDocument] = useState(false)
  const [beneficiaries, setBeneficiaries] = useState(data.claim && data.claim.beneficiaries || [])
  const { id: forFuneralId, ...strip } = forFuneral || {}
  let paymentBank = beneficiaries && beneficiaries[0] ? beneficiaries[0].bankAccount : undefined
  const payToClientId = beneficiaries && beneficiaries[0] && beneficiaries[0].payTo ? beneficiaries[0].payTo.clientId : undefined
  const [documents, setDocuments] = useState(data.claim && data.claim.documents)

  const MemberDocuments = ({ field, index, claimPaymentCounter, changeFieldValue, mode, fields }) => {
    let verified
    let description
    return (
      <Grid container className={classes.documents} spacing={1}>
        {claimPaymentCounter > 0 && <Grid item xs={1} >
          <FieldValue noLabel mode='display' name={`${field}.month`} renderField={({ input, value }) => {
            const month = input ? input.value : value
            return (month ? month : latestMonth)
          }}
          />
        </Grid>}

        <Grid justifyContent='flex-start' item xs={7} >
          <FieldValue noLabel mode='display' name={`${field}.requirementId`} renderField={({ input, value }) => {
            const requirementId = input ? input.value : value
            return <>
              <FieldValue variant="outlined" noLabel mode={requirementId ? 'display' : 'edit'} name={`${field}.description`} renderField={({ input, value }) => {
                description = input ? input.value : value
                return expandDocumentDescription(description)
              }} />
              <FieldValue noLabel variant="outlined" mode={requirementId ? 'display' : 'edit'} name={`${field}.requirement.documentType`} renderField={({ input, value }) => {
                const documentType = input ? input.value : value
                return documentType ? ` - ${documentType}` : ''
              }} />
            </>
          }} />
        </Grid>

        <Grid item xs={1}>
          <FieldValue noLabel mode='display' name={`${field}.verified`} renderField={({ input, value }) => {
            verified = input ? input.value : value
            return (verified === true ? 'APPROVED' : verified === false ? 'REJECTED' : ' ')
          }}
          />
        </Grid>

        <Grid item xs={1} >
          <FieldValue noLabel mode='display' name={`${field}.id`} renderField={({ input, value }) => {
            const claimDocumentId = input ? input.value : value
            return <FieldValue noLabel variant="outlined" mode='display' name={`${field}.fileId`} renderField={({ input, value }) => {
              let fileId = input ? input.value : value
              return <DisplayFile key={claimDocumentId} disabled={mode === 'display'} fileId={fileId} claimDocumentId={claimDocumentId} clientInfo={clientInfo} verified={verified} description={description}
                onChange={(id) => {
                  input.onChange(id);
                  if (!id) {
                    changeFieldValue(`${field}.verified`, null)
                  } else {
                    changeFieldValue(`${field}.verified`, true)
                  }
                }}
                setVerify={(result) => changeFieldValue(`${field}.verified`, result)}
              />
            }} />
          }} />
        </Grid>

        {mode !== 'display' && (
          <Grid item >
            <FieldValue name={`${field}.fileId`} renderField={({ meta }) => {
              if (meta && meta.error) {
                return <span style={{ color: 'red', fontWeight: 700 }}>{meta.error}</span>
              }
              else if (meta && meta.warning) {
                return <span >{meta.warning}</span>
              }
              else return <div />
            }}
            />
          </Grid>
        )}

      </Grid>)
  }

  const confirmUndoClaimRejection = () => {
    confirm({
      description: 'Are you sure you want to undo the claim rejection'
    })
      .then(() => undoClaimRejection())
  }

  const confirmReview = () => {
    confirm({
      description: 'Are you sure you want to send this claim for Fraud Review'
    })
      .then(() => sendForFraudReview())
  }

  const confirmSubmit = () => {
    confirm({
      description: 'Are you sure you want to submit this claim for approval'
    })
      .then(() => submitClaim())
  }

  const submitClaim = () => {
    client.mutate({
      mutation: SubmitClaim,
      variables: {
        id
      }
    })
  }

  const confirmComplete = () => {
    confirm({
      description: 'Claim Complete?', decription: 'Are you sure you want to set this claim as Complete',
    })
      .then(() => completeClaim())
  }

  const completeClaim = () => {
    client.mutate({
      mutation: CompleteClaim,
      variables: {
        id
      },
      refetchQueries: [{ query, variables: { id } }, { query: claimInformation, variables: { id } }, { query: getRequirements, variables: { clientId, claimType: claimType, products: [productType] } }]
    })
  }

  const confirmCancel = (claimOrMonth) => {
    setCancelVisible(true)
    // setClaimOrMonth(claimOrMonth)
    setCancelOrReject('cancel')
  }

  const confirmReject = () => {
    setCancelOrReject('reject')
    setCancelVisible(true)
  }

  const sendForFraudReview = () => {
    setSpin(true)
    client.mutate({
      mutation: FraudReview,
      variables: {
        id
      },
      refetchQueries: [{ query: query, variables: { id } }, { query: claimInformation, variables: { id } }, { query: getRequirements, variables: { clientId, claimType: claimType, products: [productType] } }]
    })
      .then(() => setSpin(false))
  }


  const rejectClaim = () => {
    client.mutate({
      mutation: RejectClaim,
      variables: {
        id,
        reason: cancelReason
      },
      refetchQueries: [{ query: query, variables: { id } }, { query: claimInformation, variables: { id } }, { query: getRequirements, variables: { clientId, claimType: claimType, products: [productType] } }]
    })
  }


  const cancelClaim = () => {
    client.mutate({
      mutation: CancelClaim,
      variables: {
        id,
        cancelReason
      },
      refetchQueries: [{ query: query, variables: { id } }, { query: claimInformation, variables: { id } }, { query: getRequirements, variables: { clientId, claimType: claimType, products: [productType] } }]
    })
  }

  const undoClaimRejection = () => {
    client.mutate({
      mutation: UndoClaimRejection,
      variables: {
        id,
        reason: 'Admin requested'
      },
      refetchQueries: [{ query: query, variables: { id } }, { query: claimInformation, variables: { id } }, { query: getRequirements, variables: { clientId, claimType: claimType, products: [productType] } }]
    })
  }

  const recheck = () => {
    client.mutate({
      mutation: ClaimInfoNotComplete,
      variables: {
        id
      },
      refetchQueries: [{ query: query, variables: { id } }, { query: claimInformation, variables: { id } }, { query: getRequirements, variables: { clientId, claimType: claimType, products: [productType] } }]
    })
  }

  const confirmRecheck = () => {
    confirm({
      description: 'Are you sure you want to Recheck the claim?'
    })
      .then(() => recheck())
  }

  if (!claimReason && cancelReason) {
    changeFieldValue(`claimReason`, cancelReason)
    claimReason = cancelReason
  }

  let latestMonth = claimPaymentCounter + 1

  return (
    <>{forFuneral && <>
      <br />
      {beneficiaries && beneficiaries[0] && beneficiaries[0].payTo
        ? <EditModal noButton Content={Member} text="Beneficiary" heading="Edit Beneficiary" mode={'edit'} id={beneficiaries && beneficiaries[0] && beneficiaries[0].payTo && beneficiaries[0].payTo.id} memberType="BENEFICIARY" />
        : <div style={{ fontVariant: 'small-caps', fontSize: 21, fontWeight: 400 }}>
          Beneficiary
        </div>
      }
      {beneficiaries && beneficiaries[0] && beneficiaries[0].payTo ? beneficiaries.map((beneficiary, index) => (
        <Grid key='funeralCard' className={classes.start} container spacing={1}>
          <DisplayItem label="First Name" value={beneficiary.payTo ? beneficiary.payTo.firstNames : ''} xs={2} />
          <DisplayItem label="Surname" value={beneficiary.payTo ? beneficiary.payTo.surname : ''} xs={2} />
          <DisplayItem label="Id Number" value={beneficiary.payTo ? beneficiary.payTo.idNumber : ''} xs={2} />
          <DisplayItem type='mobile' label="Mobile" value={beneficiary.payTo && beneficiary.payTo.cellNumbers ? beneficiary.payTo.cellNumbers[0] : ''} xs={2} />
          <Grid item xs={2} className={classes.paper}>
            <EditModal Content={NewClaimBeneficiary} disabled={!['INITIATED', 'NEEDS_INFORMATION', 'AWAITING_VALIDATION', 'CLIENT_INFO_REQUIRED', null].includes(claimState)} text="Change Beneficiary" heading="Change Beneficiary" mode={'edit'} memberType="BENEFICIARY" id={id} funeralId={forFuneral.id} setBeneficiaries={setBeneficiaries} />
          </Grid>
        </Grid>))
        :
        <Grid container spacing={1}>
          <Grid item>
            <EditModal Content={NewClaimBeneficiary} text="New Beneficiary" heading="Edit Beneficiary" mode={'edit'} memberType="BENEFICIARY" id={id} funeralId={forFuneral.id} />
          </Grid>
        </Grid>
      }

      {beneficiaries && beneficiaries[0] && beneficiaries[0].payTo &&
        <FieldValue name={`beneficiaries[0].bankAccountId`} renderField={({ input, value }) => {
          const paymentBankId = input.value || value
          const handleChange = ({ paymentBankAccountId }) => {
            input.onChange(paymentBankAccountId)
            // changeFieldValue(`beneficiaries[0].bankAccountId`, paymentBankAccountId)
            paymentBank.id = paymentBankAccountId
          }
        console.log('BankAccount Bank account is ', paymentBank, payToClientId )
        return (
          <BankAccount
            {...forFuneral}
            productType={'CLAIM'}
            paymentBankAccount={paymentBank}
            clientId={payToClientId}
            updateProduct={handleChange}
            // disabled={!['INITIATED', 'NEEDS_INFORMATION', 'AWAITING_VALIDATION', 'CLIENT_INFO_REQUIRED', null].includes(claimState)}
            disabled={false}
          />)
        }} />
      }

    </>}
      <br />
      <h2>Claim Required Information</h2>

      <Grid key="claimRequirements" container className={classes.start} spacing={1}>

        {mode !== 'add' && productType === 'CREDIT_LIFE' && <Grid item ><FormField noLabel name={`firstPaymentDate`} /></Grid>}

        {mode === 'edit' && requirements && requirements[0] &&
          requirements && requirements[0] && requirements[0].info.map(field => {
            return (
              <Grid key={`${field.key}.grid`} item xs={2} >
                <FormField fullWidth key={field.key} noLabel label={field.name || field.label} name={`info.${field.key}`} type={field.type} />
              </Grid>
            )
          })}

        {mode === 'display' && requirements && requirements[0] && requirements[0].info.map(field => {
          return (<FieldValue name={`info.${field.key}`} renderField={({ input, value }) => {
            field.type == "date" ? input.value = moment(parseInt(input.value)).format('YYYY-MM-DD') : null
            return <Grid item className={classes.column}><TextInput disabled={true} key={field.key} fullWidth noLabel label={field.name || field.label} name={`info.${field.key}`} type={field.type} value={value || input.value} /> </Grid>
          }} />)
        })}

        {false && <Grid item xs={2}><EditModal Content={ClaimPayments} small heading='Edit Claim Payments' text='Claim Payments' mode='edit' id={id} /></Grid>}

        {claimState !== 'COMPLETED' && <Grid item xs={2}><EditModal Content={PaymentSchedule} small heading='Edit Payment Schedule' text='Payment Schedule' mode='edit' id={id} claimType={claimType} forObligations={forObligations} claimPaymentCounter={claimPaymentCounter} /></Grid>}
      </Grid>

      <br />
      <h2>{`Supporting Documents ${(latestMonth && productType === 'CREDIT_LIFE') ? 'recurring claim #' + latestMonth : ''}`}</h2>

      {claimState !== 'COMPLETED' && productType === 'CREDIT_LIFE' && <SubmitButton renderSubmitButton={({ hasWarnings }) => {
        return (
          <div style={{ float: 'right', marginRight: 21 }}><Switch label="Latest docs only" value={latestOnly} onChange={() => setLatestOnly(!latestOnly)} />
          </div>)
      }}
      />}

      <h3><strong>Claimant Documents</strong></h3>
      <FormFieldArray name='documents' renderFieldArray={({ fields }) => {
        return fields.map((field, index) => <FieldValue mode='display' name={`${field}.description`} renderField={({ input, value }) => {
          const description = expandDocumentDescription(input ? input.value : value)
          if (latestOnly && documents && documents[index] && documents[index].month != latestMonth) {
            return <div />
          }
          else if (description && description.toUpperCase().includes('CLAIMANT')) {
            return <MemberDocuments claimantDocs={true} index={index} field={field} claimPaymentCounter={claimPaymentCounter} changeFieldValue={changeFieldValue} mode={mode} documents={documents} />
          }
          else
            return <div />
        }} />
        )
      }} />

      <h3><strong> Other Documents</strong> </h3>
      <FormFieldArray key='documents' name='documents' renderFieldArray={({ fields }) => {
        return (<>
          {fields.map((field, index) => <FieldValue key={field} mode='display' name={`${field}.description`} renderField={({ input, value }) => {
            const description = expandDocumentDescription(input ? input.value : value)
            if (latestOnly && documents && documents[index] && documents[index].month != latestMonth) {
              return <div />
            }
            else if (!description || !description.toUpperCase().includes('CLAIMANT')) {
              return <MemberDocuments claimantDocs={true} field={field} index={index} claimPaymentCounter={claimPaymentCounter} changeFieldValue={changeFieldValue} mode={mode} documents={documents} fields={fields} />
            }
            else
              return <div />
          }} />)}
          {['INITIATED', 'NEEDS_INFORMATION', 'AWAITING_VALIDATION', 'CLIENT_INFO_REQUIRED', 'APPROVED_RECURRING_PAYMENT', null].includes(claimState) && (claimType === "DEATH" || claimType === "STILLBORN") && mode !== 'display' &&
            <Grid key="additonalDocs" container className={classes.start} spacing={1}>
              <Grid item xs={2}>
                <Button size='small' onClick={() => {
                  setAddDocument(true);
                  // setAddedDocCount(addedDocCount + 1)
                  // fields.push({ description: 'Check document for type', required: false })
                }}> Add New Document </Button>
              </Grid>
            </Grid>}
        </>)
      }} />
      <br />
      {mode !== 'add' ? <FieldValue name='state' mode='display' renderField={({ input, value }) => {
        //const state = value || input.value; 
        return (
          <Fragment>
            <Grid container className={classes.even} spacing={1}>
              {creditLifeClaim && ['INITIATED', 'NEEDS_INFORMATION', 'AWAITING_VALIDATION', 'CLIENT_INFO_REQUIRED', 'VALIDATED', 'PENDING_REJECTED', 'REINSURER_APPROVAL'].includes(claimState) &&
                <Grid item>
                  <SubmitButton
                    renderSubmitButton={({ submit }) => {
                      return (
                        <Button size='large' onClick={() => { confirmCancel('POLICY') }}>
                          Cancel Claim
                        </Button>
                      )
                    }}
                  />
                </Grid>
              }

              {creditLifeClaim && ['APPROVED_PENDING_PAYMENT', 'AWAITING_VALIDATION', 'APPROVED_RECURRING_PAYMENT', 'CLIENT_INFO_REQUIRED'].includes(claimState) &&
                <Grid item>
                  <SubmitButton renderSubmitButton={({ invalid }) => {
                    return <Button size='large' disabled={invalid} onClick={() => { confirmComplete() }}>
                      Complete Claim
                    </Button>
                  }}
                  />
                </Grid>
              }

              {['INITIATED', 'NEEDS_INFORMATION', 'AWAITING_VALIDATION', 'CLIENT_INFO_REQUIRED', null].includes(claimState) || (claimState.indexOf('APPROVED') > -1 && claimType == 'OTHER') ?
                <Grid item>
                  <SubmitButton
                    renderSubmitButton={({ submit, submitting, hasChanges, invalid }) => {
                      return (
                        <Fragment>
                          <Button size='large' color='primary' disabled={submitting || !hasChanges || invalid} onClick={() => { submit() }}>
                            Update & SMS Client
                          </Button>
                        </Fragment>
                      )
                    }}
                  />
                </Grid>

                : <div />}

              {!creditLifeClaim && ['INITIATED', 'NEEDS_INFORMATION', 'AWAITING_VALIDATION', 'VALIDATED', null].includes(claimState) &&
                <Grid item>
                  <SubmitButton renderSubmitButton={({ invalid }) => {
                    return (
                      <Button size='large' color='secondary' onClick={() => confirmReview()}>
                        Send Fraud Review
                      </Button>
                    )
                  }} />
                </Grid>}

              {['INITIATED', 'NEEDS_INFORMATION', 'AWAITING_VALIDATION', 'CLIENT_INFO_REQUIRED', null].includes(claimState) &&
                <Grid item>
                  <SubmitButton renderSubmitButton={({ invalid, hasWarnings }) => {
                    return (
                      <Button size='large' color='primary' disabled={invalid || hasWarnings} onClick={() => confirmSubmit()}>
                        Send for Approval
                      </Button>
                    )
                  }} />
                </Grid>}

              {['VALIDATED'].includes(claimState) &&
                <Grid item>
                  <Button size='large' onClick={() => confirmRecheck()}>
                    Flag for Re-check
                  </Button>
                </Grid>}

              {['REJECTED'].includes(claimState) &&
                <Grid item>
                  <Button size='large' onClick={() => confirmUndoClaimRejection()}>
                    Undo Rejection
                  </Button>
                </Grid>}


              {['NEEDS_INFORMATION', 'CLIENT_INFO_REQUIRED'].includes(claimState) &&
                <Grid item>
                  <Button size='large' color='secondary' onClick={() => { confirmReject() }}>
                    Reject Claim
                  </Button>
                </Grid>
              }

              {1 === 2 && ['INITIATED', 'NEEDS_INFORMATION', 'AWAITING_VALIDATION', 'CLIENT_INFO_REQUIRED', 'REINSURER_APPROVAL'].includes(claimState) &&
                <Grid item>
                  <SubmitButton
                    renderSubmitButton={({ submit }) => {
                      return (
                        <Button size='large' color='secondary' onClick={() => { confirmCancel('MONTH') }}>
                          Cancel This Payment
                        </Button>
                      )
                    }}
                  />
                </Grid>
              }
              {spin && <ActivityIndicator />}
            </Grid>


            {/* !['INITIATED', 'NEEDS_INFORMATION','AWAITING_VALIDATION', 'CLIENT_INFO_REQUIRED'].includes(claimState) &&  */}
            {!['INITIATED'].includes(claimState) && <>
              <h2>Approvals</h2>
              <Grid container className={classes.start} >
                <Grid item xs={12}>
                  <FieldValue name='approvals' mode='display'
                    renderField={(props) => {
                      const { input, value: propsValue } = props
                      const value = propsValue ? propsValue : input ? input.value : null
                      return value ? value.filter(approval => approval && !approval.cancelled).sort((a, b) => (a.value > b.value) ? 1 : -1).map(approval => {
                        return (<>
                          <Approval id={approval.id} approvalDescription={approval.description} refetchQueries={[{ query: loadList }, { query: claimInformation, variables: { id } }, { query: getRequirements, variables: { clientId, claimType, products: [productType] } }]} />
                          <br />
                        </>
                        )
                      }) : <div>Not approved yet</div>
                    }} />
                </Grid>
              </Grid>
            </>}
          </Fragment>
        )
      }} /> :
        < Grid container justifyContent='flex-start' alignItems='center' spacing={1} >
          <Grid item>
            <SubmitButton
              renderSubmitButton={({ submit, hasChanges, invalid }) => {
                return (
                  <Button size='large' disabled={invalid} onClick={() => submit()}>
                    Initiate Claim
                  </Button>
                )
              }}
            />
          </Grid>
        </Grid>
      }

      <Modal
        title={`Do you want to ${cancelOrReject === 'cancel' ? 'Cancel' : 'Reject'} the Claim`}
        open={cancelVisible}
        onOk={() => { console.log('setCancelVisible', cancelOrReject); setCancelVisible(false); cancelOrReject === 'cancel' ? cancelClaim() : rejectClaim() }}
        onCancel={() => setCancelVisible(false)}
        onClose={() => setCancelVisible(false)}
        maxWidth='sm'
      >
        {cancelOrReject === 'cancel' && <div><strong>WARNING: THIS WILL CANCEL THIS AND ANY RECURRING CLAIMS</strong> <br /><br /></div>}

        <Select
          options={cancelReasons}
          onChange={(v) => setCancelReason(v.target.value)}
          value={cancelReason}
          label=' Why are you cancelling the Policy?'
        />

      </Modal>

      <Modal
        title={`Add a New Claim Requirements Document`}
        open={addDocument}
        noButtons
        maxWidth='md'
      >
        <NewClaimDocument documents={documents} clientInfo={clientInfo} claimId={id} setDocuments={(docs) => {
          setAddDocument(false);
          setDocuments(docs);
        }} cancel={() => setAddDocument(false)} />
      </Modal>

    </>
  )
}
Layout = withApollo(Layout)

let Claim = (props) => {
  const { id, claimantId, mode, creditLifeId, funeralId, clientId, productType, claimType, claimState, effectiveDate, forObligations, history, client: apolloClient } = props
  const { loading, error } = props.data || {}

  if (loading) {
    return <ActivityIndicator />
  }
  if (error) {
    return <div>Oh dear, an error has occurred ... {error}</div>
  }

  const { getClaimRequirements: claimRequirements, client: clientData } = props.data || {}
  const { documents: clientDocuments } = clientData || [{}]

  let initialValues
  if (mode === 'add') {
    initialValues = {
      clientId,
      claimantId,
      productType,
      effectiveDate: effectiveDate.valueOf(),
      claimType,
      requirementsIds: claimRequirements[0].id,
      documents: claimRequirements[0].documents.map(d => ({
        description: d.description,
        requirementId: d.requirementId,
        requirement: {
          requirementId: d.requirementId,
          documentType: d.documentType,
          claimMemberType: d.claimMemberType,
          required: d.required,
          description: d.description
        }
      }))
    }

    if (creditLifeId) {
      initialValues.forCreditLifeId = creditLifeId
    }
    if (funeralId) {
      initialValues.forFuneralId = funeralId
    }
    fields.info.config = {}
    claimRequirements && claimRequirements[0].info &&
      claimRequirements[0].info.forEach(d => {
        fields.info.config[d.key] = {
          key: d.key,
          label: d.name,
          type: d.type,
          warn: value => value ? null : `'${d.name}' is required`
        }
      })
  } else {
    fields.info.config = {}
    claimRequirements && claimRequirements[0] && claimRequirements[0].info &&
      claimRequirements[0].info.forEach(d => {
        fields.info.config[d.key] = {
          key: d.key,
          label: d.name,
          type: d.type,
          required: d.required || true,
          options: d.options && d.options === 'DOCTORS' ? DOCTORS : d.options && d.options === 'PARLOURS' ? PARLOURS : [],
          warn: value => value ? null : `'${d.name}' is required`
        }
      })
  }

  return (
    <GraphQLFormProvider
      id={id}
      name='claim'
      mode={mode}
      fields={fields}
      // validate={validate}
      warn={warn}
      //client={data.client}
      initialValues={initialValues}
      query={gql`
      query GetClaim($id: String!) {
        claim(id: $id) {
          ...ClaimInfo
        }
      }
      ${claimInfo}
    `}
      addMutation={gql`
        mutation CreateClaim($input: CreateClaimWithDocumentsInput) {
          createClaimWithDocuments(input: $input) {
            ...ClaimInfo
          }
        }
        ${claimInfo}
      `}

      updateMutation={gql`
        mutation UpdateClaim($id: String!, $input: UpdateClaimWithDocumentsInput!) {
          updateClaimWithDocuments(id: $id, input: $input) {
            ...ClaimInfo
          }
        }
        ${claimInfo}
      `}
      initialValuesFromData={data => {
        if (mode === 'edit' && data && !data.loading) {
          const { documents, ...rest } = data.claim || []
          const updatedDocs = []
          console.log('initialValuesFromData documents', documents);
          claimRequirements && claimRequirements[0] && claimRequirements[0].documents.forEach((reqDoc) => {
            let found = documents.find(d => d.requirementId === reqDoc.requirementId || expandDocumentDescription(d.description).toLowerCase() === expandDocumentDescription(reqDoc.description).toLowerCase())
            if (found) {
              // console.log('initialValuesFromData new doc', reqDoc);
              const { id, documentType, maximumAgeDays, requireCertified, ...newDoc } = reqDoc
              if (!found.fileId) {
                found.verified = null
              }
              updatedDocs.push({ ...found, ...newDoc })
            }
            else {
              const { id, documentType, maximumAgeDays, requireCertified, ...newDoc } = reqDoc
              console.log('initialValuesFromData new doc', newDoc);
              updatedDocs.push({ ...newDoc })
            }
          })
          documents.forEach(d => {
            if (!updatedDocs.filter(d => d.requirementId).find(doc => doc.requirementId === d.requirementId || doc.description === d.description)) {
              if (!d.fileId) {
                d.verified = null
              }
              updatedDocs.push(d)
            }
          })
          console.log('initialValuesFromData updated documents', updatedDocs);
          return { ...rest, documents: updatedDocs }
        } else {
          return data.claim
        }
      }}
      InputFormLayout={(formProps) => <Layout {...props} {...formProps} clientDocuments={clientDocuments} claimRequirements={claimRequirements} claimType={claimType} claimState={claimState} productType={productType} forObligations={forObligations} />}
      DisplayFormLayout={(formProps) => <Layout {...props} {...formProps} clientDocuments={clientDocuments} claimType={claimType} claimState={claimState} productType={productType} />}
      // refetchQueries={[{ query: loadList }, { query: claimInformation, variables: { id } }, { query: getRequirements, variables: { clientId, claimType, products: [productType] } }]}
      renderInput={renderInput}
      renderDisplay={renderDisplay}
      afterSubmit={({ data }) => {
        if (data.createClaim) {
          history.push(`/claims/${data.createClaim.id}/edit`)
        }
        // else {
        //   history.push(`/claims`)
        // }
      }}
      //keepDirtyOnReinitialize={true}
      mapInputVariables={props => {
        console.log('mapinput ', props)
        let { documents, beneficiaries, ...input } = props
        if (documents) {
          documents.map(doc => {
            delete doc.requirement;
            delete doc.requirementId
          })
          input.documents = documents
        }
        if (beneficiaries) {
          input.beneficiaries = beneficiaries.map(b => {
            const { bankAccountId, id, payToId, ...rest } = b
            return ({ bankAccountId, id, payToId })
          })
        }
        console.log('mapinput out', input)
        return ({ input: { ...input } })
      }}  // defining the input to use the input needed in the update mutation. 
    />
  )
}

export default withRouter(graphql(getRequirements, {
  options: ({ claimType, productType, claimantId }) => ({
    variables: { clientId: claimantId, claimType, products: [productType] }
  })
})(Claim))