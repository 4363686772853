import React from 'react'
import { MaterialTable } from '../generic'
import { format } from 'date-fns'
import { printCurrency, printDate } from '../../lib/helpers'

const cols = [
  {
    Header: 'Payment For',
    accessor: 'paymentFor',
  },
  {
    Header: 'When',
    accessor: 'timestamp',
    Cell: ({ value }) => value ? format(value, 'yyyy-LL-dd hh:mm bb') : '', 
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value }) => printCurrency(value)
  },
  {
    Header: 'Payment Type',
    accessor: 'paymentType',
  },
  {
    Header: 'Reversal Reason',
    accessor: 'reversalReason',
  }, 
]

const PaymentHistory = ({ history }) => {
  const pagination = history && history.length > 10 ? { showSizeChanger: true, responsive: true } : false
  const data = history && history.length > 0 ? history.slice().sort((a, b) => (a.paymentFor < b.paymentFor) ? 1 : -1) : history
  // console.log('history is', history)

  return (
    <>
      <div style={{ fontVariant: 'small-caps', fontSize: 21, fontWeight: 400 }}>
        Payment History
      </div>
      {data && <MaterialTable columns={cols} data={data} download={true} />}
    </>
  )}

export default PaymentHistory
