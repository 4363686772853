import React from 'react'
import BankAccountList from './BankAccountList'
import BankInfo from './BankInfo'

import { Route, Switch } from 'react-router-dom'

const BankAccounts = () => (
  <div>
    <Route path='/bankaccounts' exact component={BankAccountList} />
    <Switch>
      <Route path='/bankaccounts/:id/:edit?' render={({ match, history }) => <BankInfo history={history} id={match.params.id} /> } />
    </Switch>
  </div>
)

export default BankAccounts
