import React from 'react';
import gql from 'graphql-tag'

const UPDATE_CLIENT_DOCUMENT = gql`
mutation ($id: String!, $input: UpdateClientDocumentInput!) {
  updateClientDocument(id: $id, input: $input) {
    id
    type
    description
    verified
    verifiedBy {
      id
      description
    }
    file {
      id
      name
      url
      contentType
    }
  }
}`

export { UPDATE_CLIENT_DOCUMENT }