import React, { useState, useEffect } from 'react';
import { withApollo } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ImagePreview from '@material-ui/icons/Image';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { PdfViewer } from '../generic'
import {  FileUpload } from '.'
import { UPDATE_CLIENT_DOCUMENT } from './clientGql';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
  },
  imagePreview: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  imageInput: {
    display: 'none'
  }
}));

const ClientDocument = ({ imageProp, clientDocumentId, uploadAllowed, fileId, setFileId, fileType = "", client, ...props }) => {
  const classes = useStyles();
  const [image, setImage] = useState(imageProp || null);
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState((imageProp || image) && !clientDocumentId ? true : false);
  const [isPdf, setIsPdf] = useState(fileType.includes("pdf"));
  const [progress, setProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)

  useEffect(() => {
    setOpen((imageProp || image) && !clientDocumentId ? true : false);
  }, [image]);

  const updateClientDocument = (input) => {
    if (clientDocumentId) {
      client
        .mutate({
          mutation: UPDATE_CLIENT_DOCUMENT,
          variables: {
            id: clientDocumentId,
            input
          },
        })
    } else {
      return "No client document id"
    }
    setOpen(false)
  };

  const handleImageChange = event => {
    const file = event.target.files[0];
    setFile(file);
    if (file.type === 'application/pdf') {
      setIsPdf(true);
      setImage(URL.createObjectURL(file));
    } else {
      setImage(URL.createObjectURL(file));
      setIsPdf(false);
    }
  };

  const handleImageClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (!fileId) {
      setImage(null)
    };
  };

  return (
    <div>
      {image ? (
        <div>
          {isPdf ? (
            <PictureAsPdfIcon onClick={handleImageClick} />
          ) : (
            <img
              className={classes.imagePreview}
              src={image}
              alt="Preview"
              onClick={handleImageClick}
              style={{ width: '50px', height: '50px' }}
            />
          )}
        </div>
      ) : uploadAllowed ? <div>
          <Button
            variant="contained"
            color="default"
            startIcon={<ImagePreview fontSize="small" />}
            component="label"
            htmlFor="contained-button-file"
          >
            Upload
          </Button>
          <input
            accept="image/*, application/pdf"
            className={classes.imageInput}
            id="contained-button-file"
            type="file"
            onChange={handleImageChange}
          />
      </div> : 
        <div />
      }

      <Dialog open={open} onClose={handleClose} PaperProps={{
        style: {
          minWidth: '90%',
          maxWidth: '90%',
        }
      }}>
        <DialogContent>
          {isPdf ? (
            <PdfViewer file={image} />
          ) : (
            <img className={classes.imagePreview} src={image} alt="" />
          )}
        </DialogContent>

        <DialogActions>
          {clientDocumentId ? <>
            <Button variant="contained" onClick={() => updateClientDocument({ verified:false} )} color="secondary">
              Reject
            </Button>
            <Button variant="contained" onClick={() => updateClientDocument({ verified: true } )} color="primary">
              Approve
            </Button>
            <Button variant="contained" onClick={handleClose} >
              Cancel
            </Button>
          </>
            : <>
              <FileUpload file={file} setIsUploading={setIsUploading} setFile={setFile} setFileId={setFileId} />
              <Button variant="contained" onClick={handleClose} >
                Cancel
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withApollo(ClientDocument);
