import styled from '@emotion/styled'

const ImageGridSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

export {
  ImageGridSelectContainer
}
