import React from 'react';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Numeral from 'numeral'
import moment from 'moment';
import 'numeral/locales/en-za.js'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    paddingBottom: 14,
  },
  table: {
    minWidth: 650,
    fontSize: 14,
  },
  head: {
    paddingTop: 7,
    paddingBottom: 7,
    fontWeight: 400,
  },
  cell: {
    paddingTop: 4,
    paddingBottom: 4,
  }
});

const MEMBERS = gql`
  query Members($filter: MemberFilter!) {
    members(filter: $filter) {
      id
      firstNames
      surname
      idType
      idNumber
      cellNumbers
      state
      coverAmount
      memberType
      createdAt
      funerals {
        id
        policyNumber
        state
        coverAmount
      }
      client {
        claims {
          claimNumber
          forFuneralId
          forCreditLifeId
          state
          effectiveDate
          createdAt
        }
      }
    }
  }`

export const actualCover = (memberType, coverAmount, DOB, funeralCoverAmount, ageAtSignup = 0, createdAt) => {

  if (coverAmount) {
    return coverAmount
  }

  let amount = funeralCoverAmount

  if (memberType === "PARENT") { amount = amount / 2 }
  if (memberType === "EXTENDED") { amount = amount / 2 }
  if (memberType === "CHILD") {
    let currentAge
    if (DOB) {
      currentAge = moment().diff(DOB, 'years')
    }
    else {
      currentAge = ageAtSignup + moment().diff(createdAt, 'years')
    }

    if (currentAge <= 5) amount = amount / 4
    if (currentAge > 5 && currentAge <= 13) amount = amount / 2
    if (currentAge > 13 && currentAge <= 23) amount = amount
    if (currentAge > 23) amount = 0
  }
  return amount
}

const getClaim = (client, funerals) => {

  if (!client || client.claims) {
    return ''
  }
  const result = client.claims.find(c => c.forFuneralId == funerals.id)
  return result
}

function FraudChecks({ fraudChecks }) {
  const classes = useStyles();

  const checks = fraudChecks ? fraudChecks.sort((a, b) => (a.checkedAt < b.checkedAt) ? 1 : -1) : []

  // const showVersion = effectiveDate && versionOnDate && versionOnDate.state && state !== versionOnDate.state ? true : false

  if (checks && checks.length <= 1) {
    return (
      <>
        <h2>Fraud Checks have <strong>not</strong> been run</h2 >
      </>
    )
  }

  return (<>
    <br />
    {<h2>{`Funeral Fraud Check results`}</h2>}

    <TableContainer size='small' component={Paper}>
      <Table className={classes.table} aria-label="members table">
        <TableHead >
          <TableRow>
            <TableCell className={classes.head}>Type</TableCell>
            <TableCell className={classes.head}>Description</TableCell>
            <TableCell className={classes.head}>Outcome</TableCell>
            <TableCell className={classes.head}>Check At #</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {checks.map((row) => {
            const { id, type, description, outcome, checkedAt } = row
            return (
              <TableRow key={id}>
                <TableCell className={classes.cell}>{type}</TableCell>
                <TableCell className={classes.cell}>{description}</TableCell>
                <TableCell className={classes.cell}>{outcome}</TableCell>
                <TableCell className={classes.cell}>{moment(parseInt(checkedAt)).format('DD-MM-YYYY HH:mm:ss')} </TableCell>
              </TableRow>
            )
          }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </>)

}

export default FraudChecks;
