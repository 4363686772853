import React from 'react';
import { withRouter } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import { AuthProvider } from 'react-auth-helper';
import { FormRenderProvider } from 'react-form-helper'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Layout from './components/Layout/Layout.js';
import Login from './components/Login';
import { renderInput } from './components/generic'
import client from './client'
import {loginIfNeeded} from './services/auth'
import { ContentContainer } from './App.style'
import PrivateRoutes from './PrivateRoutes';

import './App.css'
import './index.css'

const App = ({history}) => {
  const location = useLocation().pathname

  const token = localStorage.getItem('accessToken') || ""
  const loggedIn = loginIfNeeded({ token, history, location })
  return (
    <ApolloProvider client={client}>
    <AuthProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ConfirmProvider>
          <FormRenderProvider renderInput={renderInput}>
              {loggedIn && <Layout />}
              {(!loggedIn)
              ? <ContentContainer>
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route
                    path="/logout"
                    render={({ history }) => {
                      localStorage.removeItem('accessToken');
                      history.push('/login');
                      return <div />;
                    }}
                  />
                  <Redirect path="/" to="/login" />
                </Switch>
              </ContentContainer>
              : <ContentContainer>
              <PrivateRoutes></PrivateRoutes>
              </ContentContainer>}
            <div style={{ float: 'right' }}>
              &copy; {new Date().getFullYear()} <a href="https://www.meerkat.co.za">My Meerkat</a>
              (Pty) Ltd - Client Version: {process.env.REACT_APP_VERSION || 'Development'}
            </div>
          </FormRenderProvider >
        </ConfirmProvider>
      </MuiPickersUtilsProvider >
      </AuthProvider >
    </ApolloProvider>)
};

export default withRouter(App)
