import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
// import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useCollapse from 'react-collapsed';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useQuery, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { format } from 'date-fns'
import { Button, Modal, MaterialTable, TextInput, Grid } from '../generic'
import ClientWhatsApp from '../shared/client/ClientWhatsApp'
import SendEmail from './SendEmail'
import SendSms from './SendSms'
import Communication from './Communication'

// import WhatsAppHistory from '../communication/chat'

const useStyles = makeStyles({
  button: {
    '&: hover': {
      backgroundColor: '#f00f15',
      color: '#fff',
    }
  }
})

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MARK_REPLIED = gql`
	mutation MarkReplied($id:  String!, $input: UpdateCommunicationInput!) {
		updateCommunication(id: $id, input: $input) {
        id
        statusReason
      }
    }
`;


//"react-apollo": "^1.4.15",
const NEW_COMMUNICATION = gql`
  subscription newCommunication {
    communicationCreated {
      clientId
      id
      # name
      attachments {
        fileName
        fileId
        file {
          url
          contentType
        }
      }
      creditLife {
        id
        policyNumber
      }
      funeral {
        id
        policyNumber
      }
      clearanceCertificate {
        id
        certificateNumber
      }
      type
      to
      from
      cc
      replyToId
      message
      createdAt
      status
      statusReason
      updatedAt
      deliveredAt
      rawInfo
      deliveredRawInfo
    }
  }
`

export const CLIENT_COMMUNICATIONS = gql`
	query Communications( $id: String! ) {
		client( id: $id ) {
      id
      idNumber
      firstNames
      surname
      cellNumbers
      email
      whatsAppNumber
      relatedCommunication {
        clientId
        id
        # name
        attachments {
          fileName
          fileId
          file {
            url
            contentType
          }
        }
        creditLife {
          id
          policyNumber
        }
        funeral {
          id
          policyNumber
        }
        clearanceCertificate {
          id
          certificateNumber
        }
        type
        to
        from
        cc
        replyToId
        message
        createdAt
        status
        statusReason
        updatedAt
        deliveredAt
        rawInfo
        deliveredRawInfo
      }
    }
  }`;

export const GROUP_PRODUCT_COMMUNICATIONS = gql`
	query GroupProductCommunications( $id: String! ) {
		groupproduct( id: $id ) {
      id
      group {
        id
        name
        roleHolders {
          client {
            id
            firstNames
            surname
            cellNumbers
            email
          }
        }
      }
      communicationHistory {
        id
        attachments {
          fileName
          fileId
          file {
            url
            contentType
          }
        }
        groupProduct {
          id
          groupProductNumber
        }
        group {
          id
          name
        }
        groupId
        groupProductId
        clientId
        type
        to
        from
        cc
        replyToId
        message
        createdAt
        status
        statusReason
        updatedAt
        deliveredAt
        rawInfo
        deliveredRawInfo
      }
    }
}`;
  
export const CLI_COMMS = gql`
	query CREDITLIFE( $id: String! ) {
		creditlife( id: $id ) {
      id
      policyNumber
      client {
        id
        idNumber
        firstNames
        surname
        cellNumbers
        email
        whatsAppNumber
      }
      relatedCommunication {
        id
        # name
        attachments {
          fileName
          fileId
          file {
            url
            contentType
          }
        }
        type
        to
        from
        cc
        replyToId
        message
        createdAt
        status
        statusReason
        updatedAt
        deliveredAt
        rawInfo
        deliveredRawInfo
      }
    }
  }`;

export const FUNERAL_COMMS = gql`
	query FUNERAL( $id: String! ) {
		funeral( id: $id ) {
      id
      policyNumber
      client {
        id
        idNumber
        firstNames
        surname
        cellNumbers
        email
        whatsAppNumber
      }
      communicationHistory {
        id
        # name
        attachments {
          fileName
          fileId
          file {
            url
            contentType
          }
        }
        type
        to
        from
        cc
        replyToId
        message
        createdAt
        status
        statusReason
        updatedAt
        deliveredAt
        rawInfo
        deliveredRawInfo
      }
    }
  }`;

export const SAVINGS_COMMS = gql`
	query SAVING( $id: String! ) {
		saving( id: $id ) {
      id
      client {
        id
        idNumber
        firstNames
        surname
        cellNumbers
        email
        whatsAppNumber
      }
      communicationHistory {
        id
        # name
        attachments {
          fileName
          fileId
          file {
            url
            contentType
          }
        }
        type
        to
        from
        cc
        replyToId
        message
        createdAt
        status
        statusReason
        updatedAt
        deliveredAt
        rawInfo
        deliveredRawInfo
      }
    }
  }`;

export const CLEARANCE_COMMS = gql`
	query CLEARANCE( $id: String! ) {
		clearancecertificate( id: $id ) {
      id
      certificateNumber
      client {
        id
        idNumber
        firstNames
        surname
        cellNumbers
        email
        whatsAppNumber
      }
      communicationHistory {
        id
        # name
        attachments {
          fileName
          fileId
          file {
            url
            contentType
          }
        }
        type
        to
        from
        cc
        replyToId
        message
        createdAt
        status
        statusReason
        updatedAt
        deliveredAt
        rawInfo
        deliveredRawInfo
      }
    }
  }`;


const CREATE_COMMUNICATION = gql`
	mutation CreateCommunication($input: CreateCommunicationInput!) {
		createCommunication(input: $input) {
			id
		}
	}
`

function collectRoleHoldersCellNumbers(roleHolders = []) {
  return roleHolders.flatMap(roleHolder => roleHolder.client.cellNumbers.filter(Boolean))
}

const phoneNumber = (to, myMobileNumber) => {
  //console.log('phone numbers are ', to, myMobileNumber )
  let myNumber = '0735555' // Set to Guy's just in case there is no number

  if (myMobileNumber) { myNumber = myMobileNumber }

  const testSite = window.location.origin.indexOf('test') > -1 || window.location.origin.indexOf('localhost') > -1

  //If URL is a test site then send sms to employee phone number
  let toNumber = to
  if (testSite) { toNumber = myNumber }

  return toNumber.replace('+27', '0')
}

const fixPhoneNbr = (nbr) => {
  if (nbr) {
    let fixedNumber = nbr.replace('+27', '0')
    if (nbr.indexOf('27') === 0) {
      fixedNumber = nbr.replace('27', '0')
    }
    return fixedNumber
  }
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-Select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

let Communications = (props) => {
  const { whatsAppOnly, relatedCommunication, clientId, clientDetails, productId, groupProductId, groupId, roleHoldersCellNumbers, type, ...rest } = props
  const clientInfo = { ...clientDetails, relatedCommunication }
  const { whatsAppNumber, cellNumbers, email } = clientDetails
  const [productComms, setProductComms] = useState([]);
  const [waMessages, setWaMessages] = useState([]);
  const [isExpanded, setExpanded] = useState(true);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [open, setOpen] = useState(false);
  const [communication, setCommunication] = useState({});
  
  // useEffect(() => {
  //   setRelatedCommunication(clientComms && clientComms.relatedCommunication ? clientComms.relatedCommunication : [])
  // }, [clientComms])

  useEffect(() => {
    setWaMessages(relatedCommunication && relatedCommunication.filter(m => m.type === 'WHATSAPP'))
    setProductComms(relatedCommunication.slice(0).filter(c => c.type !== 'WHATSAPP'))
  }, [relatedCommunication])

  useEffect(() => {
    setExpanded(relatedCommunication.length > 0)
  }, [relatedCommunication])

  function handleOnClick() {
    // Do more stuff with the click event!
    // Or, set isExpanded conditionally 
    setExpanded(!isExpanded);
  }

  const linkClicked = (params) => {

    let result = true

    params && params.forEach(param => {

      if (this.props.links) {

        this.props.links.forEach(link => {

          if (link.code == param.value && !link.timesUsed) {
            result = false
          }
        })
      }
    })
    return result
  }

  const markReplied = (id, input) => {
    props.client
      .mutate({
        mutation: MARK_REPLIED,
        variables: {
          id,
          input
        }
      })
  }

  const createCommunication = (id, input, replyToId) => {
    props.client.mutate({
      mutation: CREATE_COMMUNICATION,
      variables: {
        input
      }
    })
      .then(response => {
        if (id && replyToId) {
          markReplied(id, { statusReason: 'replied' });
        }
      })
      .catch(error => {
        console.log('there was an error sending the query', error)
      })
  }

  const onSend = (to, row) => {
    <Snackbar open={true} autoHideDuration={6000} >
      <Alert severity="info">
        `Sending ${row.type} to ${to}`
      </Alert>
    </Snackbar >

    const { id, replyToId, clientId, creditLifeId, savingsId, funeralId, groupProductId, groupId, from, type, message, ...rest } = row    
    createCommunication(id, { clientId, creditLifeId, savingsId, funeralId, from, type, message, groupProductId, groupId, to }, replyToId)
    setReload()
  }

  const toHHMMSS = (value) => {
    var sec_num = parseInt(value, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
  }

  // const returnedInfo = (info) => JSON.parse(info)

  // const sendEvents = {
  //   "processed": "Preparing for delivery",
  //   "dropped": "Error: Couldn't deliver message",
  //   "deferred": "Still trying",
  //   "bounce": "Error: Undeliverable, check address",
  //   "delivered": "Delivered to remote server",
  //   "open": "Mail was opened",
  //   "click": "Link was clicked",
  //   "spam": "Error: Mail was flagged as SPAM",
  //   "unsubscribe": "Error: Recipient unsubscribed",
  //   "pending": "PENDING",
  //   "blist": "Error: Number is black listed",
  //   "delivrd": "Message delivered",
  //   "expired": "Error: Delivery timed-out",
  //   "undeliv": "Error: Undeliverable, check number",
  //   "read": "Read"
  // }

  const trimStringOnSpace = (string, length) => {
    //trim the string to the maximum length
    let yourString = string + " "
    var trimmedString = yourString.substr(0, length);

    //re-trim if we are in the middle of a word
    return `Error: ${trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))}`
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
        Filter: SelectColumnFilter,
        filter: 'Select',
        className: 'narrow',
      },
      {
        Header: 'To',
        accessor: 'to',
        Filter: SelectColumnFilter,
        filter: 'Select',
        className: 'medium'
      },
      {
        Header: 'Message_Sent',
        id: 'createdAt',
        sortType: "basic",
        Filter: SelectColumnFilter,
        filter: 'Select',
        accessor: d => d.createdAt ? format(d.createdAt, 'yyyy-LL-dd HH:mm') : '',
        className: 'wide'
      },
      {
        Header: 'Elapsed',
        id: 'elapsed',
        accessor: d => d.deliveredAt && d.createdAt ? toHHMMSS((d.deliveredAt - d.createdAt) / 1000) : '',
        className: 'wide',
        width: 100,
        minWidth: 100
      },
      {
        Header: 'Status',
        accessor: 'status',
        // accessor: d => { console.log(d); return (d.statusReason ? d.statusReason : d.deliveredRawInfo ? sendEvents[returnedInfo(d.deliveredRawInfo).Status] : d.rawInfo && returnedInfo(d.rawInfo).errors ? trimStringOnSpace(returnedInfo(d.rawInfo).errors[0].errorMessage, 75) : d.status ? sendEvents[d.status.toLowerCase()] : d.status) },
        // // Cell: ({ row }) => {
        //   const { values } = row || {}
        //   const { status } = values || {}
        //   return (
        //     <span className={status && ['error', 'pending', 'message delivered', 'preparing for delivery', 'delivered to remote serve', 'mail was opened', 'link was clicked'].indexOf(status.toLowerCase()) > -1 ? "medium" : "bold medium"}>
        //       {status}
        //     </span>
        //   )
        // },
      },
      // {
      //   Header: 'StatusAt',
      //   id: 'deliveredAt',
      //   sortType: "basic",
      //   Filter: SelectColumnFilter,
      //   filter: 'Select',
      //   accessor: d => d.deliveredAt ? moment(d.deliveredAt).format('hh:MM:ss') : '',
      //   className: 'wide',
      //   width: 100,
      //   minWidth: 100
      // },
      {
        Header: 'Product',
        id: 'policyNumber',
        accessor: d => d.creditlife ? d.creditLife.policyNumber : d.funeral ? d.funeral.policyNumber : d.clearanceCertificate ? d.clearanceCertificate.certificateNumber :'',
        className: 'wide',
        width: 100,
        minWidth: 100
      },
      {
        Header: 'Message Body',
        id: 'message',
        width: 600,
        minWidth: 600,
        Cell: ({ value }) => (typeof value === 'string' && value.match(/<\/?[a-z][\s\S]*>/)) ? <div dangerouslySetInnerHTML={{ __html: value }} /> : value,
        accessor: row => !(row.from && row.replyToId) ? row.message : ` Replied: "${row.message}"`,
      }
    ])

  return (<>
    
    {!whatsAppOnly &&
    <>
      <div className="collapsible" >
        <div className="header" {...getToggleProps({ onClick: handleOnClick })}>
          <Grid container spacing={1}>
            <Grid item xs={9} style={{ fontVariant: 'small-caps', fontSize: 21, fontWeight: 400 }}>
              {`${productId ? type ? `${type === 'CLAIM' ? 'Claims' : 'Policy'} Communications` : 'Product Communications' : 'Client Communications'}`}
            </Grid>
            <Grid item xs={1} >
              {<SendSms clientId={clientId} funeralId={type === "FUNERAL" ? productId : ''} roleHoldersCellNumbers={roleHoldersCellNumbers} groupProductId={groupProductId} groupId={groupId} creditLifeId={type === "CREDITLIFE" ? productId : ''} />}
            </Grid>
            <Grid item xs={1} >
              {<SendEmail clientId={clientId} funeralId={type === "FUNERAL" ? productId : ''} creditLifeId={type === "CREDITLIFE" ? productId : ''} groupProductId={groupProductId}  groupId={groupId} />}
            </Grid>
            <Grid item xs={1} style={{ fontSize: 14, fontWeight: 400, color: 'blue' }} >
              {isExpanded ? 'Hide' : 'Show'}
            </Grid>
          </Grid>
        </div>

        <div {...getCollapseProps()} style={{ overflow: 'scroll'}} >
          <MaterialTable
            columns={columns}
            download={true}
            data={productComms.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1)}
            onRowPress={(data) => {
              setCommunication(data)
              setOpen(true)
            }} />
          <br />
        </div>
      </div>
    <Communication communication={communication} open={open} setOpen={setOpen} email={email} cellNumbers={cellNumbers} onSend={onSend} /> 
    </>
    }
    <ClientWhatsApp whatsAppOnly={whatsAppOnly} messages={waMessages} clientId={clientId} from={whatsAppNumber || (cellNumbers && cellNumbers[0])} clientInfo={clientInfo} /> 
  </>)
}

Communications = withApollo(Communications)

const ProductCommunication = ({ clientId, productId, groupProductId, groupId, type, whatsAppOnly }) => {
  
  const commsQuery = clientId ? CLIENT_COMMUNICATIONS : GROUP_PRODUCT_COMMUNICATIONS
  //   type === 'FUNERAL' ? FUNERAL_COMMS : 
  //   type === 'CREDITLIFE' ? CLI_COMMS :   
  //   type === 'SAVINGS' ? SAVINGS_COMMS : 
  //   type === 'CC' ? CLEARANCE_COMMS : CLIENT_COMMUNICATIONS : CLIENT_COMMUNICATIONS

  const {
    data,
    loading,
    error,
    subscribeToMore
  } = useQuery(commsQuery, { variables: { id: clientId ? clientId : groupProductId } });

  useEffect(() => {
    subscribeToMore({
      document: NEW_COMMUNICATION,
      // variables: { id: clientId },
      updateQuery: (prev, { subscriptionData }) => {
        console.log('subscribeToMore prev', prev, subscriptionData)
        if (!subscriptionData.data) return prev;
        const newChat = subscriptionData.data.communicationCreated;
        console.log('ClientCommunications subscribeToMore newChat', newChat, clientId)

        if (!(newChat && newChat.clientId && newChat.clientId === clientId)) return prev;
        return {
          // client: [...prev.client, newChat],
          client: {...prev.client, relatedCommunication: [...prev.client.relatedCommunication, newChat] }
        };
      },
    });
  }, []);

  if (loading) return <p>Communications Loading...</p>;
  if (error) return <p>`Error! ${error.message}`</p>;

  const { communicationHistory, relatedCommunication, group } = data.client || data.funeral || data.creditlife || data.clearancecertificate || data.savings || data.groupproduct || {}  
  const { client } = data.funeral || data.creditlife || data.clearancecertificate || data.savings || {}
  const { ...clientDetails } = data.client || client
  const { roleHolders } = group || {}
  const roleHoldersCellNumbers = collectRoleHoldersCellNumbers(roleHolders || [])

  let displayedCommunications = relatedCommunication ? relatedCommunication : communicationHistory
  if(type === 'FUNERAL'){
    displayedCommunications = relatedCommunication.filter(
      (c) => c.type === "WHATSAPP" || (!c.funeral && !c.creditLife && !c.savings && !c.clearanceCertificate) || (c.funeral && c.funeral.id === productId))
  } 
  if(type === 'CREDITLIFE'){
        displayedCommunications = relatedCommunication.filter(
          (c) =>
            c.type === 'WHATSAPP' ||
            (!c.funeral && !c.creditLife && !c.savings && !c.clearanceCertificate) ||
            (c.creditLife && c.creditLife.id === productId)
        )
  } 
  if(type === 'SAVINGS'){
    displayedCommunications = relatedCommunication.filter(
      (c) =>
        c.type === 'WHATSAPP' ||
        (!c.funeral && !c.creditLife && !c.savings && !c.clearanceCertificate) ||
        (c.savings && c.savings.id === productId)
    )
  } 
  if(type === 'CC'){
    displayedCommunications = relatedCommunication.filter(
      (c) =>
        c.type === 'WHATSAPP' ||
        (!c.funeral && !c.creditLife && !c.savings && !c.clearanceCertificate) ||
        (c.clearanceCertificate && c.clearanceCertificate.id === productId)
    )
  }
  return ( 
    <div>
      <Communications whatsAppOnly={whatsAppOnly} relatedCommunication={ displayedCommunications || []} clientDetails={clientDetails} clientId={clientId} productId={productId} groupProductId={groupProductId} groupId={groupId} roleHoldersCellNumbers={roleHoldersCellNumbers} type={type} />
    </div>
  );
};

export default ProductCommunication