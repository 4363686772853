import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useQuery, withApollo } from 'react-apollo';
import gql from 'graphql-tag'
import { getAccessToken } from '../../../services/auth'
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Typography, withStyles } from '@material-ui/core';
import { API_URL } from '../../../config'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ImagePreview from '@material-ui/icons/Image';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { PdfViewer } from '..'

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
  },
  imagePreview: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  imageInput: {
    display: 'none'
  }
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
  button: {
    minWidth: 130,
  }
}))(LinearProgress);

const UPDATE_CLIENT_DOCUMENT = gql`
mutation ($id: String!, $input: UpdateClientDocumentInput!) {
  updateClientDocument(id: $id, input: $input) {
    id
    type
    description
    verified
    verifiedBy {
      id
      description
    }
    file {
      id
      name
      url
      contentType
    }
  }
}`

const ImageUpload = ({ imageProp, clientDocumentId,client, ...props }) => {
  const classes = useStyles();
  const [image, setImage] = useState(imageProp || null);
  const [open, setOpen] = useState((imageProp || image) && !clientDocumentId ? true : false);
  const [isPdf, setIsPdf] = useState(false);
  const [progress, setProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [fileId, setFileId] = useState(props.fileId)

  useEffect(() => {
    setOpen((imageProp || image) && !clientDocumentId ? true : false);
  }, [image]);

  let updateClientDocument = (verified) => {
    // toggle the document verified
    setOpen(false)
    if (clientDocumentId) {
      client
        .mutate({
          mutation: UPDATE_CLIENT_DOCUMENT,
          variables: {
            id: clientDocumentId,
            input: {
              verified
            }
          },
        })
    }
  };


  const uploadImage =  () => {
    setIsUploading(true);
    const accessToken = localStorage.getItem('accessToken') || getAccessToken()

    // // API call to upload the image
    // const response = await fetch('https://your-api.com/upload-image', {
    //   method: 'POST',
    //   body: image
    // });
    // const data = await response.json();

    setIsUploading(false);

    const config = {
      method: "POST",
      crossDomain: true,
      headers: {
        'Authorization': `JWT ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        console.log('upload percentCompleted', percentCompleted)
        setProgress(percentCompleted)
      }
    }

    const formData = new FormData();
    formData.append('file', image);

    console.log('upload config', config)
    console.log('upload data', image)
    console.log('upload url', API_URL)

    axios.put(`${API_URL}upload`,
      formData,
      config)
      .then(result => {
        console.log('upload result', result)
        // result.data[0].id
        setIsUploading(false)
        setOpen(false)
        setProgress(0)
        setFileId(result && result.data && result.data[0] ? result.data[0].id : undefined)
      })
      .catch(error => {
        console.log('Error uploading image', error)
      })
  }

  const handleImageChange = event => {
    const file = event.target.files[0];
    if (file.type === 'application/pdf') {
      setIsPdf(true);
      setImage(URL.createObjectURL(file));
    } else {
      setImage(URL.createObjectURL(file));
      setIsPdf(false);
    }
  };

  const handleImageClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (!fileId) {
      setImage(null)
    };
  };

  console.log('image upload image and client and open', imageProp, image, open)

  return (
    <div>
      {image ? (
        <div>
          {isPdf ? (
            <PictureAsPdfIcon onClick={handleImageClick} />
          ) : (
            <img
              className={classes.imagePreview}
              src={image}
              alt="Preview"
                onClick={handleImageClick}
                style={{ width: '50px', height: '50px' }}
            />
          )}
        </div>
      ) : (
        <div>
          <Button
            variant="contained"
            color="default"
            startIcon={<ImagePreview fontSize="small" />}
            component="label"
            htmlFor="contained-button-file"
          >
            Upload
          </Button>
          <input
            accept="image/*, application/pdf"
            className={classes.imageInput}
            id="contained-button-file"
            type="file"
            onChange={handleImageChange}
            />
        </div>
      )}

      {(isUploading && progress > 0) &&
        <Box className="mb25" display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <BorderLinearProgress variant="determinate" value={progress} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
          </Box>
        </Box>}

      <Dialog open={open} onClose={handleClose} PaperProps={{
        style: {
          minWidth: '90%',
          maxWidth: '90%',
        }
      }}>
        <DialogContent>
          {isPdf ? (
            <PdfViewer file = { image } />
          ) : (
            <img className={classes.imagePreview} src={image} alt="Preview" />
          )}
        </DialogContent>

        <DialogActions>
          {fileId ? <>
            <Button variant="contained" onClick={() => updateClientDocument(false)} color="secondary">
              Reject
            </Button>
            <Button variant="contained" onClick={() => updateClientDocument(true)} color="primary">
              Approve
            </Button>
            <Button variant="contained" onClick={handleClose} >
              Cancel
            </Button>
          </>
            : <>
              <Button variant="contained" onClick={() => uploadImage()} color="primary">
                Upload
              </Button>
              <Button variant="contained" onClick={handleClose} >
                Cancel
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withApollo(ImageUpload);
