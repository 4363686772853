import React from 'react'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Switch as MuiSwitch } from '@material-ui/core';

function Switch(props) {
  let { disabled, field, input, onChange, value, label, noLabel } = props

  if (!onChange && input) {
    onChange = input.onChange
    value = input.value
  }

  if (!label && field) {
    label = field.label
    disabled = field.disabled
    label = field.label
    noLabel = input.noLabel
  }

  if (value !== true && value !== false) { value = false }

  return (
    <FormGroup row>
      <FormControlLabel
        label={label}
        labelPlacement='start'
        control={
          <MuiSwitch
            disabled={disabled}
            checked={value}
            onChange={onChange}
            color="primary"
          />
        }

      />
    </FormGroup>
  );
}

export default Switch
