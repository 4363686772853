import React, { useState } from 'react'
import TextField from '../TextInput';
import { default as Auto } from '@material-ui/lab/Autocomplete';

function Autocomplete({ options, onChange, value = ' ', ...props }) {
  const [selected, setSelected] = useState(value.value)

  const change = (c, value) => {
    onChange(value.value)
    setSelected(value.value)
  }
  return (
    <Auto
      disablePortal
      freeSolo
      getOptionLabel={(option) => option ? option.label : ' '}
      onChange={change}
      value={selected}
      options={options}
      renderInput={(params) => <TextField {...params} {...props} />}
    />
  );
}

export default Autocomplete
