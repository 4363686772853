import React, { Component } from 'react'
import { OverlayView } from 'react-google-maps'
import PropTypes from 'prop-types'
/* global google */

// Don't ask .... (and don't change :))
const MARKER_CLUSTERER = '__SECRET_MARKER_CLUSTERER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED'

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2)
})

export default class OverlayViewMarker extends Component {
  constructor(props, context) {
    super(props, context)
    const marker = new google.maps.Marker({
      position: props.position,
      icon: require('../../assets/images/markers/blank.png')
    })

    this.state = {
      shown: false
    }

    this.marker = marker
    google.maps.event.addListener(this.marker, 'map_changed', this.mapChanged)
  }

  componentWillMount() {
    const markerClusterer = this.context[MARKER_CLUSTERER]
    if (markerClusterer) {
      markerClusterer.addMarker(this.marker)
    }
  }

  mapChanged = () => {
    setTimeout(() => {
      if (this.marker.getMap() && !this.state.shown) {
        this.setState({ shown: true })
      } else if (!this.marker.getMap() && this.state.shown) {
        this.setState({ shown: false })
      }
    }, 100)
  }

  componentWillUnmount() {
    google.maps.event.clearListeners(this.marker, 'map_changed')
    const markerClusterer = this.context[MARKER_CLUSTERER]
    if (markerClusterer) {
      markerClusterer.removeMarker(this.marker)
    }
    this.marker.setMap(null)
  }

  render() {
    if (this.state.shown) {
      return (
        <OverlayView
          position={this.props.position}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          {this.props.children}
        </OverlayView>
      )
    } else {
      return null
    }
  }
}

OverlayViewMarker.contextTypes = {
  [MARKER_CLUSTERER]: PropTypes.object
}
