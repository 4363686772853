import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import GraphQLTable from '../generic/GraphQLTable';
import { Card, Button } from '../generic';
import { sidebarOptions } from '../Sidebar';

const USER_INFO = gql`{
	me {
		id
		first
    last
	}
  currentUser {
    id
    roles
		email
    userGroups {
      id
      name
      identifier
    }
  }
}`

const config = {
  query: gql` {
      usergroups {
			  id
    		name
        identifier
    		description
    		users {
				  email
				  username
			}
    }
  }`,
  dataset: data => data.usergroups,
  fields: {
    name: { label: 'Menu Item' },
    identifier: { label: 'Route' },
    description: { label: 'Description'},
    // users: { label: 'Users with Permission', Cell: row => (row.value ? row.value.length : '-'), align: "center" },
    users: {
      label: 'Authorised Users', Cell: ({ value }) => (value && value.length > 0 ? value.map((u, i) => {
        if (u) {
          let name
          if (u.username) {
            name = u.username
          }
          else {
            name = u.email
          }
          if (i < value.length - 1) {
            name = name + ', '
          }
          return name
        }
      }) : '-')
    },
    menuOption: { label: "Menu Option", align:"center", accessor: row => {return menuItem(row.identifier)}  },
  },
  defaultSorted: [{ id: 'name' }]
};

const menuItem = (identifier) => {
  // console.log('result is identifier', identifier)

		const usersIds = []
  const menuItems = sidebarOptions && sidebarOptions.filter(u => u.key === identifier)
    const result = (Array.isArray(menuItems) && menuItems.length && menuItems[0] != [] ? 'True' : 'False' )
    // console.log('result is ', result, menuItems)
    return result
}


const UserGroupList = ({ data, history }) => {

  if (data.loading) return <div />

  const inGroup = (groupName) => {
    return data.currentUser && data.currentUser.userGroups.map(g => g.identifier).includes(groupName)
  }

  if (inGroup('usergroups')) {
    return (
      <Card>
        <GraphQLTable
          config={config}
          onRowPress={usergroups => history.push(`/usergroups/${usergroups.id}`)}
        />
      </Card>
    )
  }
  else {
    // Not authorised
    console.log('employee is not authorised')
    //setTimeout(() => { history.push('/') }, 2000)
    return <div style={{ position: 'absolute', left: '40%', top: '30vh' }}>
      <h2>NOT AUTHORISED</h2>
      <br />
      <h2>Try refresh the page</h2>
      <br />
    </div >
  }
}

export default graphql(USER_INFO)(UserGroupList);


// <AuthConsumer>
//   {({ inGroup }) => {
//     if (inGroup('usergroups')) {
//       return (
//         <Card>
//           <GraphQLTable
//             config={config}
//             onRowPress={usergroups => history.push(`/usergroups/${usergroups.id}`)}
//           />
//         </Card>
//       )
//     }
//     else {
//       // Not authorised
//       console.log('employee is not authorised')
//       //setTimeout(() => { history.push('/') }, 2000) 
//       return <center><br /><br /><br /><br /><h1>NOT AUTHORISED - REFRESH IF YOU THINK YOU ARE</h1><br /><br /></center>
//     }
//   }}
// </AuthConsumer>