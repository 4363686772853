import React, { useState } from 'react'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Button, ActivityIndicator, Grid, Select, BuildLayout } from '../generic'
import { IdTypes } from '../../variables/IdTypes'
import TextField from '@material-ui/core/TextField';
import { isValidEmail, isValidRsaIdNumber } from '../../lib/validations'
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // justifyContent: 'space-evenly',
    paddingBottom: 7,
    // gap: '100px'
  },
  footer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingBottom: 21
  },
  column: {
    minWidth: '80%',
    maxWidth: '80%',
    padding: 0
  },
  row: {
    alignContent: 'flex-start',
    justifyContent: 'centre',
    padding: 15
  },
  button: {
    minWidth: 100,
    maxWidth: 100,
    textAlign: 'center',
    marginRight: 14
  },
  table: {
    border: 'none', 
    width: '80%',   
    margin: 'auto',  
  },
}));


const GET_CLIENT = gql`
  query clients($filter: ClientFilter!) {
    clients(filter: $filter) {
      id
      idNumber
      idType
      firstNames
      surname
      cellNumbers
      email
    }
  }
`

const CREATE_CLIENT = gql`
mutation CreateClient($input: CreateClientInput!) {
  createClient(input: $input) {
    id
    firstNames
    surname
  }
}`

const initialClient = {
  firstNames: '',
  surname: '',
  cellNumbers: [],
  email: '',
  idType: '',
  idNumber: ''
}

let Client = (props) => {
  const { afterSubmit, cancel, id, mode, setSecondaryApplicant, apolloClient, direction = 'row', justify = 'flex-start', align = 'left', spacing = 2 } = props

  const [client, setClient] = useState(initialClient)
  const [helperText, setHelperText] = useState('')
  const [emailHelper, setEmailHelper] = useState('')
  const classes = useStyles();
  const [spin, setSpin] = useState(false)


  const handleEmailBlur = (value) => {
    setClient({ ...client, email: value })

    if (!isValidEmail(client.email)) {
      setEmailHelper('')
    }
    else {
      setEmailHelper('Invalid email')
    }
  }


  const onChange = (e) => {
    // setIdNumber(e.target.value)
    setClient({ ...client, idNumber: e.target.value })
    setHelperText('')
    if ((!client.idType || client.idType === 'rsa_id') && isValidRsaIdNumber(e.target.value)) {
      setHelperText('Invalid Id Number')
    }
    else {
      getClIENT({ idType: client.idType, idNumber: e.target.value, client, setClient })
    }
  }

  let getClIENT = ({ idType, idNumber, client, setClient }) => {
    if (idNumber.length > 10) {
      setSpin(true)
      apolloClient
        .query({
          query: GET_CLIENT,
          variables: { filter: { idType, idNumber } }
        })
        .then(result => {
          setSpin(false)
          console.log("results", result.data.clients);
          if (result.data.clients && result.data.clients[0]) {
            const { id, idType, firstNames, surname, email, cellNumbers } = result.data.clients[result.data.clients.length - 1]
            setClient({ id, idType, idNumber, firstNames, surname, email, cellNumbers: cellNumbers[0] })
          }
          else {
            setClient({ ...initialClient, idType, idNumber })
          }
        })
    }
  }

  let createClIENT = () => {
    apolloClient
      .mutate({
        mutation: CREATE_CLIENT,
        variables: { input: client }
      })
      .then(result => {
        const { id } = result.data.createClient
        setSecondaryApplicant ? setSecondaryApplicant(id) : null
        afterSubmit()
      })
  }

  const noErrors = (client && client.firstNames && client.idType && client.email && client.surname && client.idNumber && client.cellNumbers && emailHelper.length == 0 && helperText.length == 0) ? true : false

  return (<>
    <Table className={classes.table} >
      <TableBody>
        <TableRow>
          <TableCell className={direction === 'row' ? classes.row : classes.column}>
            <Select fullWidth id="idtypeselect" required value={client.idType} options={IdTypes} label='Identity' onChange={(e) => setClient({ ...client, idType: e.target.value })} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={direction === 'row' ? classes.row : classes.column}>
            <TextField fullWidth InputLabelProps={{ shrink: true }} id="idnumberinput" variant="outlined" required label="Id Number" name="idNumber" value={client.idNumber} onBlur={onChange} onChange={onChange} error={helperText.length === 0 ? false : true} helperText={helperText} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={direction === 'row' ? classes.row : classes.column}><TextField fullWidth id="nameinput" variant="outlined" required label="First Name" name="firstNames" value={client.firstNames} InputLabelProps={{ shrink: true }} onChange={(e) => setClient({ ...client, firstNames: e.target.value })} /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={direction === 'row' ? classes.row : classes.column}><TextField fullWidth id="surnameinput" variant="outlined" required label="Surname" name="surname" value={client.surname} InputLabelProps={{ shrink: true }} onChange={(e) => setClient({ ...client, surname: e.target.value })} /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={direction === 'row' ? classes.row : classes.column}><TextField fullWidth id="mobileinput" variant="outlined" required label="Mobile" name="mobile" value={client.cellNumbers} InputLabelProps={{ shrink: true }} onChange={(e) => setClient({ ...client, cellNumbers: e.target.value })} /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={direction === 'row' ? classes.row : classes.column}><TextField fullWidth id="emailinput" variant="outlined" helperText={emailHelper} label="Email" name="email" value={client.email} InputLabelProps={{ shrink: true }} onChange={(e) => handleEmailBlur(e.target.value)} /></TableCell>
        </TableRow>
      </TableBody>
    </Table>

    <Grid container className={classes.footer}>
      <Grid item className={classes.button}>
        <Button fullWidth variant='contained' color='default' onClick={() => afterSubmit()}>Cancel</Button>
      </Grid>
      <Grid item className={classes.button}>
        <Button fullWidth variant='contained' color='primary' disabled={!noErrors} onClick={() => {
          if (client.id) {
            setSecondaryApplicant ? setSecondaryApplicant(client.id) : null
            afterSubmit()
          }
          else {
            createClIENT()
          }
        }}>Save</Button>
      </Grid>
      <Grid item className={classes.button}>
        {spin && <center><ActivityIndicator /></center>}
      </Grid>
    </Grid>
  </>
  )

}
Client = withApollo(Client);
export default Client
