import React, { useState, useEffect, useRef } from "react";
import gql from 'graphql-tag'
import { v4 as uuidv4 } from 'uuid';
import { withApollo } from 'react-apollo';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, MessageSeparator, Avatar, VoiceCallButton, InputToolbox, Button } from '@chatscope/chat-ui-kit-react';
import { format, differenceInDays } from 'date-fns'
import { DisplayFile, Confirm, AudioPlayer } from "../generic";
import { R4_WHATSAPP_NUMBER } from '../../config'
const images = require('../../assets/images')
import { Grid } from '../generic'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingRight: 10,
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    "& .MuiItem-root": {
      padding: "0!important",
      paddingLeft: "7px!important",
    },
    "& .MuiListItem-root": {
      padding: "0!important",
      paddingLeft: "7px!important",
    },
  },
  list: {
    minHeight: '80vh',
    width: '100%',
    // maxWidth: 360,
    border: '1px solid #D1DBE3!important',
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    width: '100%',
    marginTop: 10,
    border: '1px solid #D1DBE3',
    backgroundColor: '#F6FBFF',
  },
  needsReply: {
    "& .MuiTypography-root": {
      fontWeight: '600!important',
    }
  },
  bold: {
    "& .MuiTypography-root": {
      fontWeight: '600!important',
    }
  },
  active: {
    backgroundColor: '#C6E3FA!important'
  },
  read: {
    backgroundColor: theme.palette.background.paper,
    fontWeight: '400!important',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    padding: 3
  },
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function WhatsappMessageTemplate({ templates, onClick, values }) {
  
  templates.forEach(obj => {
    obj.convertedText = convertTemplate(obj.template, values )
  });

  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      {templates.map((template) => <Grid key={uuidv4()} item xs={9} > <Button onClick={() => { onClick(template.name) }} style={{ textAlign: "left" }} border> {template.convertedText}</Button ></Grid>)}
    </Grid >
  )
}


function capitalizeWords(inputString) {
 if(inputString){
  return inputString.toLowerCase().replace(/\b\w/g, function(char) {
    return char.toUpperCase();
});
 } 
}

function convertTemplate(template, values) {
  var cache = {};
  var fn = cache[template];

  if (!fn) {
    var sanitized = template
      .replace(/\$\{([\s]*[^;\s\{]+[\s]*)\}/g, function (_, match) {
        return `\$\{map.${match.trim()}\}`;
      })
      .replace(/(\$\{(?!map\.)[^}]+\})/g, '');

    fn = Function('map', `return \`${sanitized}\``);
  }
  return fn(values);
}



const CREATE_COMMUNICATION = gql`
  mutation CreateCommunication($input: CreateCommunicationInput!) {
    createCommunication(input: $input) {
      id
      client {
        id
        firstNames
        surname
        idNumber
      }
      type
      to
      from
      replyToId
      message
      createdAt
      statusReason
      updatedAt
      deliveredAt
    }
  }`;

const COMMUNICATION_TEMPLATES = gql`
	query CommunicationTemplates($filter: CommunicationTemplateFilter!) {
		communicationtemplates(filter: $filter) {
      id
      name
      type
      friendlyName
      template
      paramKeys
    }
  }`

const CREATE_COMMUNICATION_WITH_TEMPLATE = gql`
  mutation createNewCommunicationUsingTemplate($to: String!, $clientId: String, $name: String!, $type: CommunicationType!, $params: [KeyValueInput]) {
    createNewCommunicationUsingTemplate(to: $to, clientId: $clientId, name: $name, type: $type, params: $params) {
      id
  }
  }`;

const WhatsAppChat = ({ from, messages, clientId, clientInfo, client, clientName }) => {
  const [msg, setMsg] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [open, setOpen] = useState()
  const [onAttach, setOnAttach] = useState()
  const [fileId, setFileId] = useState()
  const [attachment, setAttachment] = useState()
  const [snackText, setSnackText] = useState()
  const [snackSeverity, setSnackSeverity] = useState()
  const [snackOpen, setSnackOpen] = useState(false)
  const [showTemplate, setShowTemplate] = useState(false)
  const [optedIn, setOptedIn] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [optInText, setOptInText] = useState('SEND WHATSAPP TEMPLATE')
  const [disableOptIn, setDisableOptIn] = useState(false)
  const [whatsAppTemplate, setWhatsAppTemplate] = useState([])

  const WHATSAPP_CLOUD_NUMBER = "+27606337528" 
  // const ourNumber = R4_WHATSAPP_NUMBER //.replace('+1', '').replace('+27', '')

  useEffect(() => {

    setMsg(messages && messages.length > 0 ? messages.map(m => {
      return ({
        message: m.message,
        sentTime: format(m.createdAt < 10000000000 ? m.createdAt * 1000 : m.createdAt, 'dd-MM-yyyy HH:mm:ss'),
        sender: m.from && (m.from.includes(R4_WHATSAPP_NUMBER) || m.from.includes(WHATSAPP_CLOUD_NUMBER)) ? `R4 ${m.from.includes(R4_WHATSAPP_NUMBER) ? R4_WHATSAPP_NUMBER : WHATSAPP_CLOUD_NUMBER}` : m.from,
        attachments: m.attachments,
        type: m.attachments && m.attachments.length && m.attachments[0].file && m.attachments[0].file.contentType ? (m.attachments[0].file.contentType.includes('image') ? 'image' : 'custom') : 'text',
        direction: !m.from || m.from.startsWith('R4') || m.from.includes(R4_WHATSAPP_NUMBER) || m.from.includes(WHATSAPP_CLOUD_NUMBER) ? 'incoming' : 'outgoing'
      })
    }).sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1) : [])

    setDisabled(checkDisabled())
  }, [messages])


  useEffect(() => {
    setShowTemplate(false)
    setDisableOptIn(false) 
    setDisabled(checkDisabled())
    setTimeout(() => {
      scrollToBottom()
    }, 100)
  }, [msg])

  useEffect(() => {
    setDisabled(checkDisabled())
  }, [from])

  useEffect(() => {
    setOptedIn(checkOptedIn)
  }, [])


  const getFileType = (attachments) => {
    if (attachments && attachments.length && attachments[0].file && attachments[0].file.contentType) {
      if (attachments[0].file.contentType.includes("audio")) {
        return (
          <Message.CustomContent>
            <AudioPlayer file={attachments[0].file} > </AudioPlayer>
          </Message.CustomContent>
        )
      }
      if (attachments[0].file.contentType.includes("image")) {
        return {
          src: attachments[0].file && attachments[0].file.url,
          alt: "whatsapp image",
          width: 190
        }
      }
      // if (attachments[0].file.contentType.includes("pdf")) {
      //   return {
      //     src: pdf,
      //     alt: "PDF File",
      //     width: 70
      //   }
      // }
    }
    return undefined
  }
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onSend = (htmlContent, text, message) => {
    const input = { to: from, message, type: "WHATSAPP", clientId, from: R4_WHATSAPP_NUMBER }
    if (attachment) {
      input.attachments = [{ fileId: attachment }]
    }
    client
      .mutate({
        mutation: CREATE_COMMUNICATION,
        variables: {
          input
        },
      })
      .then((result) => {
        setAttachment()
        // setMsg([...msg, { message, sentTime: format(new Date(), 'dd-MM-yyyy HH:mm:ss'), sender: "R4", direction: 'incoming' }]);
      })
  }

  const getWhatsAppTemplates = () => {
    client
      .query({
        query: COMMUNICATION_TEMPLATES,
        variables: { filter: { type: "WHATSAPP" } }
      })
      .then((result) => {
        setShowTemplate(true)
        setWhatsAppTemplate(result.data.communicationtemplates)
      })
  }

  const sendWhatsAppTemplate = (templateName) => {
    setDisableOptIn(true)
    let input = {
      to: from,
      name: templateName,
      type: 'WHATSAPP',
      params: [{ key: 'name', value: clientName && clientName.length ? capitalizeWords(clientName) : 'there' }]
    }
    if (clientId) {
      input.clientId = clientId
    }
    client
      .mutate({
        mutation: CREATE_COMMUNICATION_WITH_TEMPLATE,
        variables: {
          ...input
        }
      })
      .then(() => {
        setSnackSeverity('success')
        setSnackOpen(true)
        setSnackText('WhatsApp template sent')
        // setOptInText('Waiting for client to respond to message prompt')
        setShowTemplate(false)
      })
      .catch((error) => {
        console.error('GQL Error:', error)
        setSnackSeverity('error')
        setSnackOpen(true)
        setSnackText(error.message)
      })
  }

const optIn = () => {
    setDisableOptIn(true)
    const input = {
      to: clientInfo ? clientInfo.cellNumbers[0] : '0736017306',
      clientId: clientId ? clientId : " ",
      name: 'WHATSAPP_OPT_IN',
      type: "SMS",
      params: [{ key: "name", value: clientInfo ? clientInfo.firstNames : ' ' }, { key: "idNumber", value: clientInfo ? clientInfo.idNumber : ' ' }]
    }
    client
      .mutate({
        mutation: CREATE_COMMUNICATION_WITH_TEMPLATE,
        variables: {
          ...input
        },
      })
      .then(() => {
        setSnackSeverity('success')
        setSnackOpen(true);
        setSnackText('WhatsApp opt-in SMS sent');
        setOptInText('Waiting for client to respond to opt-in SMS')
      })
      .catch(response => {
        setSnackSeverity('error')
        setSnackOpen(true);
        setSnackText(response);
      })
  }

  
  // "+27600505651"
  const checkDisabled = () => {
    const received = messages && messages.filter(m => m.from && !m.from.includes(R4_WHATSAPP_NUMBER) && !m.from.includes(WHATSAPP_CLOUD_NUMBER))
    if (!received || received.length < 1) { return true }
    if (differenceInDays(received.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1)[0].createdAt, new Date()) !== 0) { // if last reply is more than 24 hours old)
      return true
    }
    else return false
  }

  
  const checkOptedIn = () => {
    const received = messages && messages.filter((m) => m.from && !m.from.includes(R4_WHATSAPP_NUMBER) && !m.from.includes(WHATSAPP_CLOUD_NUMBER))
    if ((clientInfo && clientInfo.whatsAppNumber) || (received && received.length>0)) {
      return true
    } else {
      setOptInText('SEND OPT-IN SMS')
      return false
    }
  }

  return (
    <div style={{ maxHeight: '80vh', paddingBottom: 14 }}>
      <MainContainer responsive style={{ maxHeight: '75vh' }}>
        <ChatContainer>
          {msg && msg.length > 0 && (
            <MessageList autoScrollToBottomOnMount={true} autoScrollToBottom={true} scrollBehavior={'smooth'}>
              {msg.map((m) => (
                <Message
                  key={uuidv4()}
                  type={m.type}
                  model={{
                    message: m.message,
                    sentTime: m.sentTime,
                    sender: m.sender,
                    direction: m.direction,
                    payload: getFileType(m.attachments)
                  }}
                  onClick={() => {
                    if (m.attachments && m.attachments.length > 0) {
                      setFileId(m.attachments[0].fileId)
                      setOpen(true)
                    }
                  }}
                  avatarPosition="tl"
                >
                  {/* <Avatar src={m.direction === 'outgoing' ? images.bear : images.a_selfie} name={m.sender} size="sm" /> */}
                  <Message.Header>
                    {m.sender} {m.sentTime}
                  </Message.Header>
                  {m.attachments &&
                    m.attachments.length &&
                    m.attachments[0].file &&
                    m.attachments[0].file.contentType &&
                    m.attachments[0].file.contentType.includes('pdf') && (
                      <Message.ImageContent src={images.pdf} alt="PDF View" width={70} />
                    )}
                </Message>
              ))}
              <div ref={messagesEndRef} />
            </MessageList>
          )}
          <MessageInput
            disabled={disabled}
            placeholder={
              disabled ? 'Can only send template message or waiting for client response' : 'Type message here'
            }
            onSend={(htmlContent, text, message) => onSend(htmlContent, text, message)}
            onAttachClick={() => setOnAttach(true)}
          />
        </ChatContainer>
      </MainContainer>

      {(onAttach || Boolean(attachment)) && (
        <div style={{ paddingBottom: 7 }}>
          <DisplayFile
            fileId={attachment}
            description={'Sent from Meerkat'}
            clientInfo={clientInfo}
            noApproval={true}
            noDelete={true}
            autoLoad={!attachment && onAttach}
            onChange={(id) => {
              setAttachment(id)
              setOnAttach(false)
            }}
            onClose={() => setOnAttach(false)}
          />{' '}
        </div>
      )}
      <Button style={{ float: 'right' }} border onClick={() => scrollToBottom()}>
        {' '}
        Scroll to bottom
      </Button>

      {disabled && !showTemplate && (
        <>
          {' '}
          <Button disabled={disableOptIn} border onClick={() => optedIn? getWhatsAppTemplates() : setShowConfirm(true)}>
            {' '}
            {optInText}
          </Button>
          <br />{' '}
        </>
      )}

      {open && (
        <DisplayFile
          description={'Sent via WhatsApp'}
          fileId={fileId}
          clientInfo={clientInfo}
          noApproval={true}
          noDelete={true}
          showLarge={true}
          onClose={() => setOpen(false)}
        />
      )}
      {disabled && showTemplate && (
        <WhatsappMessageTemplate
          templates={whatsAppTemplate}
          onClick={sendWhatsAppTemplate}
          values={{ name: clientName && clientName.length ? capitalizeWords(clientName) : 'there' }}
        />
      )}

      <Confirm
        open={showConfirm}
        title="Send opt-in SMS"
        description="Press OK to SMS a WhatsApp opt-in request to the client"
        onOk={() => {
          setShowConfirm(false)
          optIn()
        }}
        onCancel={() => setShowConfirm(false)}
      />
      {/* <Search placeholder="Search..." style={{ height: 31, marginBottom: "1em" }} /> */}

      <Snackbar
        open={snackOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert onClose={() => setSnackOpen(false)} severity={snackSeverity}>
          {snackText}
        </Alert>
      </Snackbar>
    </div>
  )
};

export default withApollo(WhatsAppChat);