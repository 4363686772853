import React from 'react'
import { Grid } from '../../generic'

const DashboardRow = ({ children, justify = "start" }) => (
  <Grid container spacing={1}>
    <Grid item>
      {children}
    </Grid>
  </Grid>
)

export default DashboardRow