import React, { useState, Component, Fragment } from 'react'
import moment from 'moment'
import { Card, MaterialTable, Grid, Switch, Button } from '../generic'
import { printCurrency } from '../../lib/helpers'

const getAllocationTypes = (summary) => {
  let allocationTypes = new Set()
  let months = new Set()
  const cells = {}
  allocationTypes.add('BUFFER_BALANCE')
  summary.forEach((item) => {
    allocationTypes.add(item.allocationType)
    months.add(item.forMonth)
    if (!cells[item.forMonth]) {
      cells[item.forMonth] = { 'BUFFER_BALANCE': 0 }
    }
    if (!cells[item.forMonth][item.allocationType]) {
      cells[item.forMonth][item.allocationType] = 0
    }
    cells[item.forMonth][item.allocationType] += item.net
    if (item.allocationType === 'RETURN_ON_PREMIUM_BENEFIT') {
      cells[item.forMonth]['BUFFER_BALANCE'] += item.net
    }
  })
  months = Array.from(months).sort((a, b) => a < b ? -1 : 1)
  let prevMonth
  months.forEach(month => {
    if (prevMonth) {
      cells[month]['BUFFER_BALANCE'] += cells[prevMonth]['BUFFER_BALANCE']
    }
    prevMonth = month
  })

  //allocationTypes = Array.from(allocationTypes).sort((a, b) => a < b ? -1 : 1)
  allocationTypes = Array.from(allocationTypes)
  return { allocationTypes, cells, months }
    //allocationTypes = allocationTypes.map(type => type.replace(/_/g, ' '))
}

const summaryToTableFUll = (allocationTypes, cells, months) => {

  let cols = []
   cols.push({ Header: 'Month', accessor: 'Month', })

   // build columns
   allocationTypes.map(a => cols.push({ Header: a.replace(/_/g, ' '), accessor: a }))

  // build data
  let fullData = []
  months.map(month => {
    let row = {}
    row['Month'] = month
    allocationTypes.map(a => { row[a] = cells[month][a] ? printCurrency(cells[month][a]).replace(/ /g, '') : '-' })
    fullData.push(row)
  })

  return <MaterialTable onRowPress={() => console.log('I was clicked')} columns={cols} data={fullData.sort((a, b) => (a.month < b.month) ? 1 : -1)} />
}

const cliToTable = (allocationTypes, cells, months) => {

  // build data
  let data = []
  months.map(month => {
    let row = {}
    row['MONTH'] = month
    allocationTypes.map(a => { row[a] = cells[month][a] ? cells[month][a] : 0 })
    data.push(row)
  })

  //console.log('Allocation summary', data)

  //rename and reduce columns
  let reducedData = []
  let CLI = false

  data.sort((a, b) => (a.month < b.month) ? 1 : -1).forEach(row => {

    const { MONTH, BUFFER_PAYMENT_CONTRIBUTION, BUFFER_BALANCE, PAYMENT_AMOUNT, HYPHEN_CONTRIBUTION, RISK_PREMIUM, BINDER_FEE, BINDER_FEE_VAT, COMMISSION, COMMISSION_VAT, OFFICE_PREMIUM, RETURN_ON_PREMIUM_BENEFIT, OMART_REINSURANCE, RGA_REINSURANCE, PTD_REINSURANCE, RETRENCHMENT_REINSURANCE, TTD_REINSURANCE, YALU_REINSURANCE } = row || {}

    const fees = BINDER_FEE + BINDER_FEE_VAT + COMMISSION + COMMISSION_VAT
    const payment = HYPHEN_CONTRIBUTION ? HYPHEN_CONTRIBUTION : PAYMENT_AMOUNT
    const toBuffer = BUFFER_PAYMENT_CONTRIBUTION ? BUFFER_PAYMENT_CONTRIBUTION > 0 ? (payment - BUFFER_PAYMENT_CONTRIBUTION - fees) : (payment - RISK_PREMIUM - (payment > OFFICE_PREMIUM ? fees / 2 : 0)) : RETURN_ON_PREMIUM_BENEFIT

    const premium = RISK_PREMIUM ? RISK_PREMIUM : payment * 0.90
    if (!CLI && HYPHEN_CONTRIBUTION) {
      CLI = true
    }

    reducedData.push(
      {
        'MONTH': MONTH,
        'PAYMENT': printCurrency(payment),
        'PREMIUM': printCurrency(premium),
        'TO_BUFFER': printCurrency(toBuffer),
        'FEES': payment > OFFICE_PREMIUM ? printCurrency(fees / 2) : ' ',
        'BUFFER_CONTRIBUTION': printCurrency(BUFFER_PAYMENT_CONTRIBUTION),
        'BUFFER_BALANCE': printCurrency(BUFFER_BALANCE)
      })
  }
  )

  let cols = []
  cols.push({ header: 'MONTH', accessor: 'MONTH' })
  cols.push({ header: 'PAYMENT', accessor: 'PAYMENT' })
  cols.push({ header: 'PREMIUM', accessor: 'PREMIUM' })
  cols.push({ header: 'FOR BUFFER', accessor: 'TO_BUFFER' })
  
  if (CLI) {
    cols.push({ header: 'EARLY FEES', accessor: 'FEES' })
    cols.push({ header: 'BUFFER CONTRIBUTION', accessor: 'BUFFER_CONTRIBUTION' })
  }
  
  cols.push({ header: 'BUFFER BALANCE', accessor: 'BUFFER_BALANCE' })

  return <MaterialTable data={reducedData} columns={cols} />
}
const AllocationSummary = ({ summary, product }) => {
  const [brief, setBrief] = useState(true)
  console.log('AllocationSummary', summary, product)
  if (summary && summary.length > 0) {
    const { allocationTypes, cells, months } = getAllocationTypes(summary)
    const expanded = summaryToTableFUll(allocationTypes, cells, months)
    const condensed = cliToTable(allocationTypes, cells, months)
    return (
      <>
        <h2>Allocation Summary <Button shape='round' onClick={() => setBrief(!brief)}>{brief ? '+' : '-'}</Button></h2>
        <div style={{ minWidth: 1024 }} >
          {brief ? condensed : expanded} 
        </div>
      </>
    )
  }
  else {
    return (
      <h3>Allocation summary not available</h3>
    )
  }
}
{/* <div style={{ minWidth: 1024 }} >{product && product === 'FUNERAL' ? funeralToTable(summary) : expand ? cliToTable(summary) : cliToTable(summary)} </div> */}

export default AllocationSummary

const paymentGrids = [
  { header: 'Date', accessor: 'timestamp', width: 100, render: (date) => moment(date).format('DD-MM-YYYY') },
  { header: 'Amount', accessor: 'amount', align: 'right', width: 100, render: amount => printCurrency(amount) }
]

const unitGrids = [
  { header: 'Date', accessor: 'date', width: 100, render: (date) => moment(date).format('DD-MM-YYYY') },
  { header: 'Unit Type', accessor: 'unitType', width: 100 },
  { header: 'Amount', accessor: 'amount', align: 'right', width: 100, render: amount => printCurrency(amount) },
  { header: 'Units', accessor: 'numberOfUnits', width: 100 }
]

class AllocationTable extends Component {
  state = {
    activeOnly: true,
  }
  handleToggle = prop => enable => {
    this.setState({ [prop]: enable })
  }

  render() {
    const { loading, error, savings } = this.props.data

    if (loading) { return 'Loading ...' }
    if (error) { return `An error has occurred: ${error}` }

    let bucketsData = []
    if (savings) {
      if (this.state.activeOnly) {
        bucketsData = savings.bucketInfo.filter(row => row.targetAmountount > 0 || row.amount > 0)
      }
      else bucketsData = savings.bucketInfo
    }
    return (
      <Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card title='Savings Product Information'>
              <Card title='Bucket Information' >
                <MaterialTable
                  data={bucketsData}
                  columns={[
                    { header: 'Type', accessor: 'bucketType', width: 100 },
                    { header: 'Amount', accessor: 'amount', align: 'right', width: 100, render: amount => amount && amount > 1 ? printCurrency(amount) : '-' },
                    { header: 'Target Amount', accessor: 'targetAmount', align: 'right', width: 100, render: targetAmount => targetAmount ? printCurrency(targetAmount) : '-' },
                    { header: 'Target Date', accessor: 'targetDate', width: 100, render: targetDate => targetDate ? moment(targetDate).format('DD-MM-YYYY') : '-' },
                    { header: 'Percent Full', accessor: 'percentageFull', align: 'right', width: 100, render: percentageFull => percentageFull && percentageFull > 1 ? `${percentageFull}%` : '-' },
                  ]}
                />
              </Card>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Switch label='Show active buckets only' checked={this.state.activeOnly} onChange={this.handleToggle('activeOnly')} />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Card title='Payments' >
              <MaterialTable data={savings && savings.payments} columns={paymentGrids} />
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid itemxs={6}>
            <Card title='Unit Transactions' >
              <MaterialTable data={savings && savings.unitTransactions} columns={unitGrids} />
            </Card>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}