import React, { useState, useEffect } from 'react';
import { Card, Button, Grid, ActivityIndicator, TextInput } from '../generic'
import { withApollo } from 'react-apollo'
// import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag'
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
import { ClientInformation} from '../shared/client'
import ProductCommunication from '../communication/ProductCommunication'

// switch between locales
Numeral.locale('en-za')

export const GET_USER = gql`
	query UserSession($filter: UserSessionFilter) {
		usersessions(filter: $filter) {
      id
      uuid
      aud
      expires
      refreshToken
      user {
        id
        username
        email
        authProvider
        forClient {
          id
          firstNames
          surname
          idType
          idNumber
        }
      }
      latest
      expired
    }
  }
	`

const getDetails = (client, searchString, setClientData, setSpinner, setErrorText) => {
  if (searchString && searchString != null) {
    setSpinner(true)
    client
      .query({ query: GET_USER, variables: { filter: { uuid: searchString, latest: true } } })
      .then(data => {
        console.log('GET_USER returned the following ', data.data.usersessions)
        setSpinner(false)
        if (data.data.usersessions && data.data.usersessions.length > 0 && data.data.usersessions[0].user && data.data.usersessions[0].user.forClient) {
          const clientData = data.data.usersessions[0].user.forClient
          setClientData({ ...clientData })
          setErrorText(null)
          //if (data.data.clientProducts.idNumber) {
          //  getCoverTotal(client,data.data.clientProducts.idNumber,setTotalCover)
          //}
        } else {
          setClientData()
          setErrorText("No client found")
        }
      })
  }
}

const UuidSearch = ({ client, uuid, history }) => {
  console.log('UuidSearch props', uuid)

  const [clientData, setClientData] = useState()
  const [searchString, setSearchString] = useState(uuid)
  // const [searchString, setSearchString] = useState(match && match.params && match.params.idNumber ? match.params.idNumber : localStorage.getItem('uuidString'))
  const [spinner, setSpinner] = useState(false)
  const [errorText, setErrorText] = useState(null)

  const runSearch = () => {
    console.log('runSearch called', searchString)
      setClientData()
    // localStorage.setItem('searchString', searchString);
    getDetails(client, searchString, setClientData, setSpinner, setErrorText)
  }

  // useEffect(() => {
  //   if (searchString) {
  //     getDetails(client, search, setClientData, setSpinner, setErrorText)
  //     // localStorage.setItem('uuidString', search);
  //   }
  // }, [searchString]);


  return (
    <Card title="Enter UUID">
      <Grid container spacing={1} justify="start" align="middle" gutter={16}>
        <Grid item xs={4}>
          <TextInput fullWidth disabled={spinner} defaultValue={searchString} placeholder="Input UUID" id="searchString" onBlur={e => setSearchString(e.target.value)} />
        </Grid>
        <Grid item xs={1}> <Button type="primary" onClick={() => runSearch()} >SEARCH</Button></Grid>
        <Grid item xs={1}> {spinner && <ActivityIndicator center={false} />}</Grid>
      </Grid>
      {errorText}

      {clientData &&
        <>
          <ClientInformation id={clientData.id} clientData={clientData} history={history} />
          <ProductCommunication clientId={clientData.id} />
        </>}
    </Card>

  );
}
export default withApollo(UuidSearch)
