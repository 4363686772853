import React from 'react'
import Product from './Product'
import { Route } from 'react-router-dom'

const Products = () => (
  <div>
    <Route key="products" path='/products/:idNumber?' component={Product} />
  </div>
)

export default Products
