import React, { useState } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    flex: '0 0 auto',
    margin: 0,
    padding: '14px 24px 7px 24px',
  },
  footer: {
    paddingRight: 0,
    paddingBottom: 21
  },
  topScrollPaper: {
    alignItems: 'center',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
}));

const Modal = props => {
  const { open, disabled, fullWidth = true, maxWidth = 'lg', noButtons, title, children, onOk, onCancel, onClose, dialogText, cancelText = 'Cancel', okText = 'Ok', ...rest } = props
  const classes = useStyles()

  return (
    <Dialog aria-labelledby="form-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClose={onClose}
      open={open}
      >
      {title && <DialogTitle className={classes.dialogTitle} id="form-dialog-title">{title}</DialogTitle>}
        <DialogContent>
        <DialogContentText>
          {dialogText}
          </DialogContentText>
        {children}
        </DialogContent>
        {!noButtons && <DialogActions className={classes.footer}>
        {onCancel && <Button onClick={onCancel} color="default" variant="contained" >
          {cancelText}
        </Button>}
        {onOk && <Button disabled={disabled} onClick={onOk} color="primary" variant="contained" style={{ marginRight: 20 }}>
          {okText}
        </Button>}
        </DialogActions>}
    </Dialog>
  )
}

export default Modal

{/* <Button onClick={onCancel} color="default" variant="contained" startIcon={<ThumbDownOutlinedIcon />}> */ }
