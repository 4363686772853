import React, { useState, useEffect } from "react";
import { Subscription } from 'react-apollo';
import gql from 'graphql-tag';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import * as FaIcons from "react-icons/fa";
import { R4_WHATSAPP_NUMBER } from '../../config'
const WHATSAPP_CLOUD_NUMBER = "+27606337528" 

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NEW_COMMUNICATION = gql`
  subscription {
  communicationCreated {
    id
    type
    to
    from
    status
    replyToId
    message
    createdAt
    statusReason
    updatedAt
    deliveredAt
  }
}`;

const AlertSnackbar = ({ open, type, from, snackText }) => { 
  const [openSnack, setOpenSnack] = useState();
  const [types, setTypes] = useState([]);

  useEffect(() => {
    setTypes([...new Set([...types, ...[type]])])
    setOpenSnack(open)
  }, [type, from])

  return (<>
    {types.map(commType => {
      if (commType === 'SMS') {
        return (
          <div style={{ position: 'relative', marginLeft:'.5em', fontSize: 21, cursor: 'pointer' }}>
            <FaIcons.FaSms onClick={() => {
              window.open("/replies", "_blank");
              setTypes(types.filter(t => t !== 'SMS'))
            }} />
          </div>)
      }
      else return (
        <div style={{ position: 'relative', marginLeft: '.5em', fontSize: 21, cursor: 'pointer' }}>
            <FaIcons.FaWhatsapp onClick={() => {
            window.open("/chatadmin", "_blank");
            setTypes(types.filter(t => t !== 'WHATSAPP'))
            }} />
          </div>)
    })}
    <Snackbar open={openSnack} type={type} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={30000} onClose={() => setOpenSnack(false)}>
      <Alert onClick={() => {
        if (type === 'WHATSAPP') {
          window.open("/chatadmin", "_blank");
        }
        else {
          window.open("/replies", "_blank");
        }
      }}
        onClose={() => setOpenSnack(false)} severity={'info'} >
          {snackText}
        </Alert>
      </Snackbar>
  </>);
}

const inGroup = (groupName, user) => {
  return user && user.userGroups && user.userGroups.map(g => g.identifier).includes(groupName)
}

const CommunicationsAlert = (user) => {
  return (
    <Subscription
      subscription={NEW_COMMUNICATION}
    >
    {({ data, loading, ...rest }) => {
        let { communicationCreated } = data || { }
        if (loading) return null;
        if (inGroup('replies', user) && communicationCreated.type === 'SMS' && communicationCreated.status === 'REPLY') {
          return (<AlertSnackbar snackText={`New SMS from ${communicationCreated.from}: ${communicationCreated.message}`} open={true} type={communicationCreated.type} />);
        }
        else if ((!user.userGroups || inGroup('replies', chatadmin)) && communicationCreated.type === 'WHATSAPP' && !communicationCreated.from.includes(R4_WHATSAPP_NUMBER) && !communicationCreated.from.includes(WHATSAPP_CLOUD_NUMBER)) {
          return (<AlertSnackbar snackText={`New WhatsApp from ${communicationCreated.from}: "${communicationCreated.message}"`} open={true} type={communicationCreated.type} />)
        }
        data = null
        communicationCreated = null
        return null
      }
    }
    </Subscription>
  )
}


export default CommunicationsAlert