import React from 'react';
import gql from 'graphql-tag';
import GraphQLTable from '../generic/GraphQLTable';
import { Card, Button } from '../generic'
import moment from 'moment';


export const getAllDCs = gql`
{
 debtcounsellors(limit: 10000) {
   id
   fullName
   description
   shortName
   dcCompany
   dcId
   externalId
   firstPolicySold
   specialRateEnd
   ncrRegistrationNumber
 }
}
 `

const config = {
  query: getAllDCs,
  dataset: data => data.debtcounsellors,
  fields: {
    description: { label: 'Full Name' },
    shortName: { label: 'Name on SMS' },
    fullName: { label: 'Debt Counsellor' },
    externalId: { label: 'Simplicity Id' },
    firstPolicySold: { label: 'First Sale', accessor: row => (row.firstPolicySold ? moment(row.firstPolicySold).format('DD-MM-YYYY') : '-') },
    specialRateEnd: { label: 'Special Rate Ends', accessor: row => (row.specialRateEnd ? moment(row.specialRateEnd).format('DD-MM-YYYY') : '-') },
    ncrRegistrationNumber: { label: 'NCR number' }
  }, defaultSorted: [{ id: 'shortName' }]
}
const DebtCounsellorList = ({ history }) => (
  <Card>
    {console.log('history is ', history)}
    <h1>
      Debt Counsellors

      <Button style={{ marginLeft: '80%', marginTop: 7 }} color="primary" onClick={() => history.push(`/debt-counsellors/new`)}>
        Add Debt Counsellor
      </Button>

    </h1>

    <GraphQLTable
      config={config}
      onRowPress={debtcounsellors => history.push(`/debt-counsellors/${debtcounsellors.id}`)}
    />

  </Card>
);

export default DebtCounsellorList;