import React from 'react';
import Member from './Member';
import Beneficiary from './Beneficiary';
import MemberInformation from './MemberInformation';
import FuneralSearch from './FuneralSearch';
import FuneralList from './FuneralList';
import FuneralInfo from './FuneralInfo';
import Quote from './Quote';
import { Route, Switch } from 'react-router-dom';

const Funerals = () => (
  <div>
    <Switch>
      <Route path='/funeral' exact component={FuneralSearch} />
      <Route path='/allfunerals' exact component={FuneralList} />
      <Route path='/funeral/:id/:edit/:effectiveDate?'
        render={ ({ match, history }) => {
        return (
          <div>
            <FuneralInfo mode={'edit'} id={match.params.id} effectiveDate={match.params.effectiveDate} history={history} />
          </div>
      )}}
      />
      <Route path='/funeral-quote/:id?'
        render={({ match, history }) => {
          return (
            <div>
              <Quote mode={'edit'} id={match.params.id} history={history} />
            </div>
          )
        }}
      />
    </Switch>
  </div>
)

export { Member, Beneficiary, MemberInformation, FuneralList } 

export default Funerals