import React from 'react'
import DashboardCard from './DashboardCard'

const TopNCard = ({ title, data, metric, n, renderItem, renderNoItems, size, onItemPress, goal }) => {
  let showData = data ? data.slice(0).sort((a, b) => (b[metric] - a[metric])).slice(0, n) : []

  let showItems
  if (showData.length === 0) {
    showItems = renderNoItems()
  } else {
    showItems = showData.map(d => renderItem({ data: d, metric: d[metric], goal, onItemPress: onItemPress }))
  }

  return (
    <DashboardCard size={size || 6} title={title}>
      {showItems}
    </DashboardCard>
  )
}

export default TopNCard