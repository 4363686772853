import React, { useState } from 'react';
import gql from 'graphql-tag'
import GraphQLTable from '../generic/GraphQLTable';
import { Card, Button } from '../generic'
import { printCurrency } from '../../lib/helpers';
import moment from 'moment';
import { IdTypes } from '../../variables/IdTypes';
import { AuthConsumer } from 'react-auth-helper';

const statusOptions = [
  { label: 'APPLICATION', value: 'APPLICATION' },
  { label: 'INCOMPLETE', value: 'INCOMPLETE_APPLICATION' },
  { label: 'QUOTE', value: 'QUOTE' },
  { label: "QUOTE_EXB", value: 'QUOTE_EXISTING_BUSINESS' },
  { label: "QUOTE_UPDATE", value: 'QUOTE_UPDATE' },
  { label: 'DECLINED', value: 'DECLINED_APPLICATION' },
  { label: 'ISSUED', value: 'POLICY_ISSUED' },
  { label: "ISSUED_UPDATE", value: 'POLICY_ISSUED_UPDATE' },
  { label: 'NTU', value: 'NOT_TAKEN_UP' },
  { label: 'ON_RISK', value: 'ON_RISK' },
  { label: 'GRACE', value: 'GRACE' },
  { label: 'NO PAY', value: 'TERMINATED_NO_PAYMENTS' },
  { label: 'TERMINATED', value: 'TERMINATED_CLIENT_REQUESTED' },
];


const productTypes = (productType) => {

  switch (productType) {
    case "OPEN_MARKET": return "Open_Market"
    case "THIRD_PARTY_DC": return "Third_Party"
    default: return "Meerkat_DC"
  }
}

const getLabel = (value) => {
  if (value) {
    const label = IdTypes.find(x => x.value === value)
    return label ? label.label : value
  }
  return null
}

const paymentTypes = (paymentType) => {

  switch (paymentType) {
    case "HYPHEN": return "HYPHEN"
    case "DEBI_CHECK": return "DEBI_CHECK"
    case "DO": return "Debit Order"
    case "PERSAL": return "Salary"
    case "PAY_AT": return "Pay@"
    case "FROM_SAVINGS": return "SAVINGS"
    default: return ""
  }
}

/*
          debtCounselling {
            id
            debtCounsellor {
              id
              shortName
            }
          }
*/

//query CreditLife($policyStatuses: [String], $limit: Int) {
// creditlifeByStatus(policyStatuses: $policyStatuses, limit:$limit) {
const config = {
  query: gql` 
    query CreditLifes ($filter: CreditLifeFilter, $limit: Int, $offset:Int) {
			creditlifes (filter: $filter, limit: $limit, offset: $offset) {
				id
				policyNumber
				client {
          id
					firstNames
					surname
					idNumber
          idType
          email
          cellNumbers
				}
        productType
        paymentType
				originalCoverAmount
				coverAmount
				termsVersion
				payment
				policyStatus
        graceDays
        riskPaidUntil
        updatedAt
        createdAt
        actionDay
			}
		}
	`,
  dataset: data => data.creditlifes,
  fields: {
    policyNumber: { label: 'Policy#' },
    'client.idNumber': { label: 'ID Number' },
    'client.idType': { label: 'ID Type', accessor: row => row.client && row.client.idType && getLabel(row.client.idType) },
    clientName: {
      label: 'Name',
      width: '175',
      accessor: row => (row.client ? titleCase(`${row.client.firstNames} ${row.client.surname}`) : null)
    },
    'client.email': { label: 'Email' },
    'client.cellNumbers': {
      label: 'Cell',
      Cell: row => (row.value && row.value.length > 0 ? row.value[0] : '')
    },
    coverAmount: {
      label: 'Cover Amount',
      align: 'right',
      Cell: row => (row.value ? printCurrency(row.value) : '-')
    },
    termsVersion: { label: 'Terms', align: 'center' },
    productType: {
      label: 'Product', align: 'center',
      Cell: row => (row.value ? row.value === "INTERNAL_DEBT_COUNSELLING" ? "CREDIT LIFE" : row.value.replace(/_/g, ' ') : '')
    },
    payment: {
      label: 'Monthly',
      align: 'right',
      Cell: row => (row.value ? printCurrency(row.value) : '-')
    },
    paymentType: {
      label: 'Paid By',
      Cell: row => paymentTypes(row.value)
    },
    policyStatus: {
      label: 'State',
      Cell: row => row.value.replace(/_/g, ' '),
      filter: 'select'
    },
    actionDay: {
      label: 'Action'
    },
    graceDays: {
      label: 'Grace Left',
    },
    //riskPaidUntil: {
    //  label: 'Paid Until',
    //  accessor: row => row.riskPaidUntil && moment(row.riskPaidUntil).format('DD-MM-YYYY'),
    //},
    Updated: {
      label: 'Updated',
      accessor: row => moment(row.updatedAt).format('DD-MM-YYYY'),
    },
    updatedAt: { label: 'Relative', Cell: row => moment(row.value).fromNow() },

    /* sortOrder: {
      hideFilter:true,
      width:1, 
      label: "", 
      Cell: row => '', accessor: row => { return status.indexOf(row.policyStatus) * -1 }
    }
    */
  },
  defaultSorted: [{ id: 'updatedAt', desc: true }]
};

export const titleCase = (str) => {
  if (str) {
    return str.toLowerCase().split(/[\s_]+/).map(function (word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ')
  }

  else return (str)
}

const handleRowPress = (history, { id, policyStatus }) => {
  history.push(`/credit-life/${id}/edit`);
};


/*
const buildFilter = (values) => {
  // left here for when we can handle an OR on the server
  console.log(' buildFilter ', values)
  if (!values) {
    return null
  }
  const statuses = values.split(',')

  console.log(' buildFilter ', statuses)

  if (statuses.length > 1) {
    let ORS = []
    statuses.forEach(status => {
      ORS.push({ policyStatus: status })
    })
    console.log({ "$or": ORS })
    return { "$or": ORS }
  }
  else {
    console.log(` policyStatus:"${statuses[0]}"`)
    return { policyStatus: statuses[0] }
  }
}
*/

const CreditLifeList = ({ history }) => {

  //const [policyStatus, setPolicyStatus] = useState(localStorage.getItem('initialCreditLifeStatuses') || [])
  //const [limit, setLimit] = useState(localStorage.getItem('initialCreditLifeLimit') || 20000)
  const [limit, setLimit] = useState(20000)
  const [offset, setOffset] = useState(localStorage.getItem('initialCreditLifeOffset') || 0)
  const [filter, setFilter] = useState(undefined)

  const changeLimit = (e) => {
    setLimit(e.target.value);
    //localStorage.setItem('initialCreditLifeLimit', e.target.value)
  }

  return (<AuthConsumer>
    {({ inGroup }) => (<>
      <div style={{ position: 'absolute', top: 4, left: '16em', color: '#fff' }}>
        <input style={{ color: '#000', width: 65 }} defaultValue={limit} label="Load" onBlur={changeLimit} onPressEnter={changeLimit} />
        &nbsp; &nbsp;limit
      </div>
      <div style={{ position: 'absolute', paddingTop: 5, left: '48em' }} >
        <Button color="primary" onClick={() => history.push(`/credit-life/openmarket`)} > Open Market </Button>
          </div>

      <Card >
        <GraphQLTable config={config} limit={limit} filter={filter} offset={offset} pageSize={20} onRowPress={creditLife => creditLife.client && handleRowPress(history, creditLife)} />
      </Card>
    </>
    )}
  </AuthConsumer>
  );
}

//const status = ["APPLICATION", "QUOTE", "QUOTE_UPDATE","POLICY_ISSUED_UPDATE", "QUOTE_EXISTING_BUSINESS" ].reverse()

export default CreditLifeList;