import React, { useState, useEffect } from 'react';
import { withApollo, useQuery } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag'
import { Grid, Modal, Button, PdfViewer } from './index'
import { DisplayFile } from './index'
import * as FaIcons from "react-icons/fa";
import { rejected, approved } from '../../assets/images'

const prettyId = (id) => {
  if (id && id.length === 13) {
    return id.substr(0, 6) + ' ' + id.substr(6, 4) + ' ' + id.substr(10, 2) + ' ' + id.substr(12, 1)
  }
  else return id
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    direction: 'column',
    paddingBottom: 7,
    minWidth: '500px'
  },
  valid: {
    fontSize: '30px',
    textShadow: '1px 1px #FF0000',
    color: 'green',
    position: 'absolute',
    bottom: '15%',
    left: '40%',
    // transform: 'rotate(-45deg)'
  },
  inValid: {
    fontSize: '100px',
    textShadow: '1px 1px #FF0000',
    color: 'red',
    position: 'absolute',
    bottom: '15%',
    left: '40%',
    // transform: 'rotate(45deg)',
  },
  footer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-start',
    left: 21,
    bottom: 20,
  },
  image: {
    paddingBottom: 20
  },
}));


const GET_FILE = gql`
  query GetFile( $id: String!) {
    file(id: $id) {
      id
      url
      contentType
      name
  }
  }`

const CREATE_CLIENT_DOCUMENT = gql`
  mutation($input: CreateClientDocumentInput!) {
    createClientDocument(input: $input) {
      id
      verified
      verifiedBy {
          id
          description
        }
  }
  }`;

const UPDATE_CLIENT_DOCUMENT = gql`
  mutation($id: String!, $input: UpdateClientDocumentInput!) {
    updateClientDocument(id: $id, input: $input) {
      id
      verified
      verifiedBy {
          id
          description
        }
  }
  }`;

const UPDATE_CLAIM_DOCUMENT = gql`
  mutation($id: String!, $input: UpdateClaimDocumentInput!) {
    updateClaimDocument(id: $id, input: $input) {
      id
      verified
  }
  }`;

const GET_CLIENT_DOCUMENTS = gql`
  query GetClientDocuments( $filter: ClientDocumentFilter!) {
    clientdocuments(filter: $filter) {
        id    
    }
  }`

const GET_CLAIM_DOCUMENTS = gql`
  query GetClaimDocuments( $filter: ClaimDocumentFilter!) {
    claimdocuments(filter: $filter) {
        id
    }
  }`

let updateClaimDocument = ({ client, id, verified }) => {
  // toggle the document verified
  client
    .mutate({
      mutation: UPDATE_CLAIM_DOCUMENT,
      variables: {
        id,
        input: {
          verified
        }
      },
      //refetchQueries:[{query:GET_SAVINGS_INFO, variables: { id: clientId } }]
    })
    .then((response) => console.log('updateClaimDocument response', response))
};

let updateClientDocument = ({ client, id, verified }) => {
  // toggle the document verified
  client
    .mutate({
      mutation: UPDATE_CLIENT_DOCUMENT,
      variables: {
        id,
        input: {
          verified
        }
      },
    })
    .then((response) => {
      console.log('updateClaimDocument response', response)
    });
};

let getClaimDocuments = ({ client, fileId, verified }) => {
  console.log('getClaimDocuments', fileId)

  client
    .mutate({
      mutation: GET_CLAIM_DOCUMENTS,
      variables: {
        filter: {
          fileId
        }
      },
    })
    .then((response) => {
      console.log('update claim Documents', response, verified)
      if (response.data.claimdocuments && response.data.claimdocuments.length > 0) {
        response.data.claimdocuments.map(doc => {
          updateClaimDocument({ client, id: doc.id, verified })
        })
      }
    });

}

let getClientDocuments = ({ client, clientId, fileId, verified }) => {
  if (fileId) {
  client
    .mutate({
      mutation: GET_CLIENT_DOCUMENTS,
      variables: {
        filter: {
          fileId
        }
      },
    })
    .then((response) => {
      if (response.data.clientdocuments && response.data.clientdocuments.length > 0) {
        response.data.clientdocuments.map(doc => {
          console.log('update client Documents', doc.id, fileId, response, verified)
          updateClientDocument({ client, id: doc.id, verified })
        })
      }
      else {
        console.log('id not found so must be a new file, store under client')
        client
          .mutate({
            mutation: CREATE_CLIENT_DOCUMENT,
            variables: {
              input: {
                clientId,
                fileId,
                verified
              }
            },
          })
      }
      getClaimDocuments({ client, fileId, verified })
    });
  }
}

let VerifyDoc = (props) => {
  const { clientData, file, docId, open, setOpen, client, setVerify } = props;
  const { data, loading, error } = file && useQuery(GET_FILE, { variables: { id: file.id } }) || {}

  if (loading) {
    return <div />
  }

  const updateVerified = (result) => {
    if (docId) {
      updateClientDocument({ client, id: docId, verified: result })
    }
    if (file) {
      // find the client document and set value. Remove after API document enhancements are added
      getClientDocuments({ client, clientId: clientData ? clientData.id : '', fileId: file.id, verified: result })
    }
    if (setVerify) {
      setVerify(result)
    }
    setOpen(false);
  }


  return (<>
    {open && <DisplayFile
      {...props}
      fileId={file.id}
      clientDocumentId={docId}
      onApprove={() => updateVerified(true)}
      onReject={() => updateVerified(false)}
      onChange={(id) => {
        console.log('VerifyDoc crop doc is now', id);
        props.onChange && props.onChange(id);
      }} />}
  </>
  );
}

VerifyDoc = withApollo(VerifyDoc)
export default VerifyDoc

//   < Modal
// title = {`${verified === true ? 'APPROVED' : verified === false ? 'REJECTED' : noButtons ? description : `Please approve or reject the document`} ${clientData ? `for ${prettyId(clientData.idNumber)} ${clientData.firstNames} ${clientData.surname}` : ''} ${verifiedBy && verifiedBy.description ? `by ${verifiedBy.description}` : ''}`}
// okText = "Approve"
// cancelText = "Reject"
// open = { fales && open}
// onClose = {() => setOpen(false)}
// onOk = {() => updateVerified(true)}
// onCancel = {() => updateVerified(false)}
// fullWidth = { false}
// maxWidth = 'lg'
// noButtons = { noButtons }
//   >
//   <Grid container className={classes.root} key={id || docId} >
//     <Grid xs={12} item align="flex-end" className={classes.image}>
//       {isPdf ? <PdfViewer file={url} /> : <picture><img alt="document" style={{ width: '100%' }} src={url} /></picture>}
//       <div className={verified ? classes.valid : classes.inValid} > {verified === true ? <img src={approved} /> : verified === false ? <img src={rejected} /> : ' '}</div>
//     </Grid>
//     <div className={classes.footer}>
//       <a href={url} target="_blank" rel="noopener noreferrer" download>
//         <Button size='small' style={{ marginRight: 21 }}>
//           <FaIcons.FaDownload />&nbsp; Save
//         </Button>
//       </a>
//       <Button style={{ marginRight: 21 }} size='small' onClick={() => setIsPdf(!isPdf)}><FaIcons.FaSearchPlus /> &nbsp; {isPdf ? ' Image' : ' PDF'}</Button>
//       <Button size='small' onClick={() => setShowCrop(true)}><FaIcons.FaCrop />&nbsp; Crop</Button>
//     </div>
//   </Grid>
   
//     </Modal >