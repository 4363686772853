import React from 'react'
import { Switch, Route } from 'react-router-dom'
import SavingsList from './SavingsList'
import SavingsInfo from './SavingsInfo'

export default function Savings() {
  return (
    <Switch>
      <Route
        path='/savings'
        exact
        component={SavingsList}
      />
      <Route
        path='/savings/:id/:edit?'
        render={ ({ match, history }) =>
          <SavingsInfo
            mode={match.params.edit ? 'edit' : 'display'}
            id={match.params.id}
            history={history}
          />
        }
      />
    </Switch>
  )
}