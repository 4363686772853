import React from "react";
import { Parser } from 'html-to-react';
import moment from "moment";

export const nilStr = (input) => {
    if (input === null) return true;
    if (input === undefined) return true;
    if (input === "") return true;

    return false;
};

export const nil = (input) => {
    if (input === null) return true;
    if (input === undefined) return true;

    return false;
};


export const parseToReact = (input = '') => {
    let htmlParser = new Parser();
    let element = null;
    try {
        element = htmlParser.parse(input);
    } catch (e) {}

    return element;
};

export const SHORT_DATE_FORMAT = 'DD-MM-YYYY';
export const SHORT_TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm';

export const prettyMoment = ( someDate = moment(), format=null, theNullText = 'NOT AVAILABLE') => {
    let m = moment(someDate);
    if(moment.isMoment(m)){
        return format === null ? m.toISOString() : m.format(format);
    } else {
        return theNullText;
    }
};

const numbersOnlyChars = "0123456789.";
export const numbersOnly = ( input ) => {
    let newInput = '';
    input.toString().split('').map((ch) => {
        if(numbersOnlyChars.indexOf(ch) !== -1) {
            newInput+=`${ch}`;
        }
    });
    return newInput;
};

const emailRegexp = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,}/;
const phoneRegexp = /\(?\+[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(\w{1,10}\s?\d{1,6})?/;
const smsRegexp = /\(?\+[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(\w{1,10}\s?\d{1,6})?/;
export const validateEmail = (email) => ( emailRegexp.test(email) );
export const validatePhone = (phone) => ( phoneRegexp.test(phone) );
export const validateSms = (phone) => ( smsRegexp.test(phone));


export const escapeRegExp = (str) => {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
};
