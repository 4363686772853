import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Grid, Alert, Radio, Button, Modal, Select } from "../generic";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { withApollo, graphql } from "react-apollo";
import { flowRight as compose } from 'lodash';
import { ApolloClient } from 'apollo-client';
import {nil, nilStr} from "../../Helpers";
import moment from "moment";
import _ from 'lodash';
import { NavLink, withRouter} from "react-router-dom";
import {
    QuestionSetModel,
    QuestionModel,
    QuestionTypes
} from './model';
const Option = Select.Option


class QuestionSet extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            questionSetForEdit: new QuestionSetModel(props.questionSet),
            questionForEdit: new QuestionModel(),
            mode: props.mode,
            searchQuestion: '',
            searchResults: [],
            showQuestionModal: false
        };
        this.update = this.update.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.renderQuestionSetComponent = this.renderQuestionSetComponent.bind(this);
        this.renderResponse = this.renderResponse.bind(this);
        this.renderQuestionModal = this.renderQuestionModal.bind(this);
        this.saveQuestion = this.saveQuestion.bind(this);
    }

    componentWillReceiveProps(props) {
        const {questionSetForEdit} = this.state;
        if (nil(props.questionSet) === false && props.questionSet.id !== questionSetForEdit.id && props.data.loading === false) {
            this.setState({...this.state, questionSetForEdit: props.questionSet});
        }
    }


    update(changes, cb = () => {
    }) {
        this.setState({...this.state, ...changes}, cb);
    }

    closeModal() {
        this.update({preview: false});
    }

    saveQuestion() {
        const that = this;
        const {questionForEdit, questionSetForEdit} = this.state;
        const {mode} = this.props;

        const client = that.props.client;
        if (client instanceof ApolloClient) {
            let isNew = nilStr(questionForEdit.id) === true;

            let variables = {
                input: {...questionForEdit.toJSON(isNew === true ? QuestionModel.formatters._toJSON.create : QuestionModel.formatters._toJSON.update)}
            };

            variables = isNew === false ? {...variables, id: questionForEdit.id} : variables;

            client.mutate({
                mutation: isNew === true ? QuestionModel.gqlConfig.create : QuestionModel.gqlConfig.update,
                variables,
                refetchQueries: ['QuestionSetList']
            }).then((result) => {
                let response = isNew === false ? result.data.updateQuestion : result.data.createQuestion;
                if (nil(response) === false) {
                    if (nilStr(response.id) === false) {
                        questionForEdit.id = response.id;

                        if (isNew === true) {
                            questionSetForEdit.questions.push(questionForEdit);
                        } else {
                            questionSetForEdit.questions.map((q) => {
                                if (q.id === questionForEdit.id) {
                                    q = questionForEdit
                                }
                            })
                        }

                        that.update({
                            saved: true,
                            saveError: null,
                            saveResponse: `Successfully ${ mode === 'edit' ? 'updated' : 'created' } question '${ questionForEdit.question }'`,
                            questionForEdit: new QuestionModel(),
                            questionSetForEdit: questionSetForEdit
                        });
                    } else {
                        that.update({
                            saved: true,
                            saveError: 'Server did not respond as expected',
                            saveResponse: `Could not persist the changes for the questionSet`,
                            questionForEdit: questionForEdit,
                            questionSetForEdit: questionSetForEdit
                        });
                    }
                } else {
                    that.update({
                        saved: true,
                        saveError: 'Server accepted the query but did not respond correctly',
                        saveResponse: `The update was accepted but the server returned an error`,
                        questionForEdit: questionForEdit,
                        questionSetForEdit: questionSetForEdit
                    });
                }
            }).catch((error) => {
                that.update({
                    saved: false,
                    saveError: error,
                    saveResponse: `Could not successfully persist the data to the server`,
                    questionForEdit: questionForEdit,
                    questionSetForEdit: questionSetForEdit
                });
            });
        } else {
            throw new Error('ApolloClient object expected')
        }

    }

    renderQuestionSetComponent() {
        const that = this;
        const {mode} = this.props;
        const {questionSetForEdit, questionForEdit} = this.state;
        const disableFields = mode.toLowerCase() === 'display';
        let questionRows = [];

        const patchQuestionSetForEdit = () => (that.update({questionSetForEdit}));

        const patchQuestionForEdit = () => (that.update({questionForEdit}));

        const saveQuestionSet = () => {
            const client = that.props.client;
            if (client instanceof ApolloClient) {
                let mutationFormatter = mode === "new" ? QuestionSetModel.formatters._toJSON.create : QuestionSetModel.formatters._toJSON.update;
                let variables = {
                    input: {...questionSetForEdit.toJSON(mutationFormatter)}
                };

                variables = mode === 'edit' ? {...variables, id: questionSetForEdit.id} : variables;

                client.mutate({
                    mutation: nilStr(questionSetForEdit.id) === true || questionSetForEdit.id === 'new' ? QuestionSetModel.gqlConfig.create : QuestionSetModel.gqlConfig.update,
                    variables,
                    refetchQueries: ['QuestionSetList', 'questionsets']
                }).then((result) => {
                    let response = mode === 'edit' ? result.data.updateQuestionSet : result.data.createQuestionSet;
                    if (nil(response) === false) {
                        if (nilStr(response.id) === false) {
                            questionSetForEdit.id = response.id;
                            questionSetForEdit.createdAt = moment(response.createdAt);
                            questionSetForEdit.updatedAt = moment(response.updatedAt);
                            that.update({
                                saved: true,
                                saveError: null,
                                saveResponse: `Successfully ${ mode === 'edit' ? 'updated' : 'created' } question set '${ questionSetForEdit.description }'`,
                                questionSetForEdit: questionSetForEdit
                            });
                        } else {
                            that.update({
                                saved: true,
                                saveError: 'Server did not respond as expected',
                                saveResponse: `Could not persist the changes for the question`,
                                questionForEdit: questionForEdit
                            });
                        }
                    } else {
                        that.update({
                            saved: true,
                            saveError: 'Server accepted the query but did not respond correctly',
                            saveResponse: `The update was accepted but the server returned an error`,
                            questionSetForEdit: questionForEdit
                        });
                    }
                }).catch((error) => {
                    that.update({
                        saved: false,
                        saveError: error,
                        saveResponse: `Could not successfully persist the data to the server`,
                        questionSetForEdit: questionSetForEdit
                    });
                });
            } else {
                throw new Error('ApolloClient object expected')
            }
        };

        const searchQuestion = () => {

        };

        const addNewQuestionClicked = (e) => {
            that.update({showQuestionModal: true})
        };

        const onQuestionSetDescriptionChanged = (e) => {
            questionSetForEdit.description = e.target.value;
        };

        const onQuestionTextChanged = (e) => {
            questionForEdit.question = e.target.value;
            patchQuestionForEdit();
        };

        const onQuestionTypeChanged = (questionType) => {
            questionForEdit.questionType = questionType;
            patchQuestionForEdit();
        };

        const onSearchQuestionChanged = (e) => {
            let searchText = e.target.value;
            const doSearch = _.throttle(() => {
                if (searchText.length > 5) {
                    const client = that.props.client;
                    client.query({
                        query: QuestionModel.gqlConfig.search,
                        fetchPolicy: 'network-only',
                        variables: {searchFor: searchText}
                    }).then((response) => {
                        let questions = [];
                        response.data.searchQuestion.map((questionProps) => (questions.push(new QuestionModel(questionProps))));
                        that.setState({...that.state, searchResults: questions, searching: false, showResults: true});
                    }).catch((error) => {
                        that.setState({
                            ...that.state,
                            searchResults: [],
                            searching: false,
                            showResults: true,
                            searchError: error
                        });
                    });
                }
            }, 900, {'trailing': true, 'leading': false});
            that.setState({...that.state, searchQuestion: searchText}, doSearch);
        };

        questionSetForEdit.questions.map((question, index) => {

            const removeQuestion = () => {
                let updatedQuestions = [];

                questionSetForEdit.questions.map((q, i) => {
                    if (q.id !== question.id)
                        updatedQuestions.push((q));
                });

                questionSetForEdit.questions = updatedQuestions;
                patchQuestionSetForEdit();
            };

            const editQuestion = () => {
                that.update({questionForEdit: question, showQuestionModal: true});
            };

            const updateQuestionText = (e) => {
                let updatedQuestions = [];
                let questionText = e.target.value;
                questionSetForEdit.questions.map((q, i) => {
                    if (q.id === question.id)
                        q.question = questionText;
                    updatedQuestions.push((q));
                });

                questionSetForEdit.questions = updatedQuestions;
                patchQuestionSetForEdit();
            };


            const isEditing = (disableFields === false && question.id === questionForEdit.id);

            questionRows.push((
                <Card>
                    <input disabled={disableFields === true || isEditing === false}
                                 value={question.question} onChange={updateQuestionText}/>
                        {isEditing === false ?
                            <Button onClick={removeQuestion} disabled={mode === 'display'} type='danger'>Delete</Button> : null}
                        {isEditing === false ?
                            <Button onClick={editQuestion} disabled={mode === 'display'} >Edit</Button> : null}
                        {isEditing === true ? <Button onClick={that.saveQuestion} bsStyle={'primary'}>Save</Button> : null}
                </Card>
            ));
        });


        return (
            <div style={{marginTop: "20px", marginBottom: "20px"}}>
                <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                                    {
                                        this.props.mode !== 'display' ?
                                            (<NavLink to={`/questions/${this.props.questionSet.id || ''}`}
                                                      className={'btn btn-default'}><i
                                                className="fa fa-close"> </i>&nbsp;
                                                CANCEL</NavLink> ) :
                                            (<NavLink to={`/questions`} >BACK TO LIST</NavLink> )
                                    }
                                    {
                                        this.props.mode === 'display' ?
                                            (<NavLink to={`/questions/${this.props.questionSet.id}/edit`}
                                                      className={'btn btn-primary'}>&nbsp;
                                                EDIT</NavLink> ) :
                                            (<Button bsStyle='success' onClick={saveQuestionSet}>SAVE</Button>)
                                    }
                    </Grid>
                </Grid>
                <hr/>
                <Card
                    title="Description">
                    <input disabled={disableFields} value={questionSetForEdit.description}
                                 onChange={onQuestionSetDescriptionChanged}/>
                </Card>
                <Grid container spacing={1}>
                    <Grid item span={12}>
                        <h4>Existing Questions</h4>
                        {questionRows}
                    </Grid>

                    {mode !== 'display' ? (
                        <Grid item md={6} sm={12}>
                            <h4>Manage Questions</h4>
                            <Card>
                                <input value={that.state.searchQuestion} onChange={onSearchQuestionChanged} />
                                        <Button onClick={searchQuestion} bsStyle={'default'}
                                                data-tip={'Enter a phrase to search for an existing question.'}><i
                                            className={'fa fa-search'}>&nbsp;</i>&nbsp;Search</Button>
                                        <Button onClick={addNewQuestionClicked} bsStyle={'default'}
                                                data-tip={'Click to add a new question.'}><i
                                            className={'fa fa-plus'}>&nbsp;</i>Add</Button>
                            </Card>

                            <table>
                                <thead>
                                <tr>
                                    <th>Question Type</th>
                                    <th>Question</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                {that.state.searchResults.map((question) => {
                                    const addQuestion = () => {
                                        let found = false;
                                        questionSetForEdit.questions.map((existing) => {
                                            if (existing.id === question.id && found === false) {
                                                found = true;
                                            }
                                        });

                                        if (found === false) {
                                            questionSetForEdit.questions.push(question);
                                            patchQuestionForEdit();
                                        }
                                    };
                                    return (
                                        <tr>
                                            <td>{question.questionType}</td>
                                            <td>{question.question}</td>
                                            <td>
                                                <Button bsStyle={'default'} onClick={addQuestion}>+</Button></td>
                                        </tr>);
                                })}
                                </tbody>
                            </table>
                        </Grid>
                    ) : null}

                </Grid>
            </div>
        )
    }

    static renderErrorComponent(error) {
        if (error === null) return null;
        return (
            <Alert severity='error' message={error} />
        )
    }

    renderResponse() {
        const that = this;
        const {saved, saveResponse, saveError, questionSetForEdit} = this.state;

        const dismissSaved = () => {
            that.update({saved: null, saveResponse: null, saveError: null});
        };

        if (nilStr(saveResponse) === false) {

            setTimeout(() => {
                dismissSaved()
            }, 1500);

            return (
                <Alert severity={saveError === null ? 'success' : 'error'} message={{ saveResponse }} />
            );

        }
        else return null;

    }

    renderQuestionModal() {
        const that = this;
        const {showQuestionModal, questionForEdit} = this.state;

        const patchIt = () => {
            that.update({questionForEdit});
        };

        const hideModal = (e) => {
            that.update({showQuestionModal: false, questionForEdit: new QuestionModel()});
        };


        const onQuestionTypeChanged = (e) => {
            questionForEdit.questionType = e.target.value;
            patchIt();
        };

        const onQuestionTextChanged = (e) => {
            questionForEdit.question = e.target.value;
            patchIt();
        };

        const updateQuestionOptionAtIndex = (answer, index) => {
            questionForEdit.answers[index] = answer;
            patchIt();
        };

        const addNewOption = () => {
            questionForEdit.answers.push("");
            patchIt();
        };

        const deleteOptionFromAnswer = (index) => {
            let answers = [];
            questionForEdit.answers.map((answer, idx) => {
                if (index !== idx) {
                    answers.push(answer);
                }
            });
            questionForEdit.answers = answers;
            patchIt();
        };

        const updateAnswerAttempt = (e) => {
            questionForEdit.answerAttempts = e.target.value;
            patchIt();
        };

        const updateCorrectAnswerText = (e) => {
            questionForEdit.correctAnswerText = e.target.value;
            patchIt();
        };

        const updateCorrectAnswerYesNot = (e) => {
            questionForEdit.correctAnswerYesNo = e.target.value;
            patchIt();
        };

        const updateRequiresCorrectAnswer = (e) => {
            questionForEdit.requiresCorrectAnswer = e.target.value;
            patchIt();
        };

        const updateScore = (e) => {
            if (isNaN(e.target.value) === false) {
                questionForEdit.score = e.target.value * 1;
                patchIt();
            }
        };

        let multipleChoiceOptions = null;

        if (questionForEdit.questionType === QuestionTypes.MULTIPLE_CHOICE.key) {
            multipleChoiceOptions = (
                <Card>
                    <Card>
                        <h2>Multiple Choice Options</h2>
                        <List>
                            {questionForEdit.answers.map((answer, index) => {

                                const optionTextChanged = (e) => {
                                    updateQuestionOptionAtIndex(e.target.value, index);
                                };

                                const deleteOption = (e) => {
                                    deleteOptionFromAnswer(index);
                                };

                                const setCorrectAnswerIndex = (e) => {
                                    questionForEdit.correctAnswerIndex = index;
                                    patchIt();
                                };


                                return (
                                    <List>
                                        <Radio type="radio" checked={index === questionForEdit.correctAnswerIndex} onChange={setCorrectAnswerIndex} aria-label="..." />
                                        <input value={answer} key={index}
                                                         onChange={optionTextChanged}/>
                                            <Button type='danger' onClick={deleteOption}>Delete</Button>
                                    </List>
                                );
                            })}
                        </List>
                        <Button onClick={addNewOption} bsStyle={'default'}>
                            Add Option</Button>
                    </Card>
                </Card>
            );
        }

        return (
            <Modal show={showQuestionModal === true} onHide={hideModal}>
                    <h1>Add a new question</h1>
                <Card>

                    <Select
                        value={questionForEdit.questionType}
                        LABEL="Question Type"
                        onChange={onQuestionTypeChanged}
                        options={[{ value: 'YESNO', label: 'Yes/No' }, { value: 'TEXT', label: 'Text' }, { value: 'MULTIPLE_CHOICE', label: 'Multiple Choice' }]}
                    />
                    </Card>
                    <Card
                        title="Question Text">
                    <input value={questionForEdit.question} onChange={onQuestionTextChanged} />
                        </Card>
                    {questionForEdit.questionType === QuestionTypes.MULTIPLE_CHOICE.key ? multipleChoiceOptions : null}
                    {questionForEdit.questionType === QuestionTypes.TEXT.key ? (
                        <Card
                            title="Correct Answer Text">
                        <input value={questionForEdit.correctAnswerText} onChange={updateCorrectAnswerText} />
                        </Card>
                    ) : null}
                    {questionForEdit.questionType === QuestionTypes.YESNO.key ? (
                    <Card>
                            <Select value={questionForEdit.correctAnswerYesNo}
                            label="Yes / No - Correct Answer"
                            onChange={updateCorrectAnswerYesNot}
                            options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                        />
                        </Card>
                    ) : null}
                    <Card
                        title="Answer Attempts">
                    <input value={questionForEdit.answerAttempts} onChange={updateAnswerAttempt} />
                    </Card>

                    <Card
                        title="Requires Correct Answer">
                        <Select componentClass={'select'} value={questionForEdit.requiresCorrectAnswer}
                                     onChange={updateRequiresCorrectAnswer}>
                            <Option value={true} key={'true'}>Yes</Option>
                            <Option value={false} key={'false'}>No</Option>
                        </Select>
                        </Card>

                    <Card
                        title="Score">
                    <input value={questionForEdit.score} onChange={updateScore} />
                    </Card>

                    <Button  onClick={hideModal}>CANCEL</Button>
                    <Button type='primary' onClick={that.saveQuestion}>SAVE QUESTION</Button>
            </Modal>
        )
    }

    render() {
        const {loading, error} = this.props;

        let questionSetComponent = this.renderQuestionSetComponent();
        let errorComponent = QuestionSet.renderErrorComponent(error);
        let responseComponent = this.renderResponse();
        let newQuestionModal = this.renderQuestionModal();
        return (
            <Grid item xs={6}>
                {loading === true ? <p>Loading QuestionSet, please wait</p> : null}
                {responseComponent}
                {loading === false ? questionSetComponent : null}
                {errorComponent}
                {newQuestionModal}
            </Grid>
        );
    }


}

QuestionSet.propTypes = {
    id: PropTypes.string.isRequired,
    questionSet: PropTypes.instanceOf(QuestionSetModel).isRequired,
    mode: PropTypes.string,
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    client: PropTypes.instanceOf(ApolloClient).isRequired,
};


QuestionSet.defaultProps = {
    id: null,
    loading: true,
    error: null,
    questionSet: new QuestionSetModel()
};

QuestionSet.contextTypes = {
    match: PropTypes.any
};

QuestionSet.mapStateToProps = (state, props) => {
    let inputModelData = props.mode === 'new' ? {...QuestionSet.defaultProps} : props.data.questionset || {...QuestionSet.defaultProps};
    let questionSetModel = new QuestionSetModel(inputModelData);

    return {

        questionSet: questionSetModel,
        loading: nil(props.data) === false ? (props.data.loading || false) : false,
        error: nil(props.data) === false ? (props.data.error || null) : null
    };
};

QuestionSet.mapDispatchToProps = (dispatch) => ({});

export default compose(
    withApollo,
    graphql(QuestionSetModel.gqlConfig.query, {
        options: (props) => ({
            skip: props.id === null || props.mode === 'new',
            variables: {
                id: props.id
            }
        })
    }),
    withRouter,
    connect(QuestionSet.mapStateToProps, QuestionSet.mapDispatchToProps)
)(QuestionSet);
