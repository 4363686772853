import React from 'react'
import { KeyboardDatePicker, DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    marginTop: 0,
    marginBottom: 0,
    "& .MuiInputBase-root": {
      paddingTop: 4,
      paddingBottom: 3,
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingLeft: 10
      },
      "& .MuiInputBase-input": {
        padding: 15,
        paddingLeft: 10
      }
    }
  }
});

function MyDatePicker({ label, id = "date-picker-inline", required, disableFuture = false, ...props }) {
const classes = useStyles();

  return <DatePicker
    {...props}
    className = { classes.root }
    disableFuture={disableFuture}
    autoOk={true}
    // disableToolbar
    fullWidth
    variant="dialog"
    margin="normal"
    id={id}
    label={label}
    format="DD MMMM YYYY"
    inputVariant="outlined"
    value={props.value ? moment(props.value) : null}
    onChange={v => { console.log('DatePicker onChange', v, moment(v).valueOf()); props.onChange(moment(v).valueOf()) }}
    onBlur={v => { console.log('DatePicker onBlur', v, moment(v).valueOf()); props.onBlur && props.onBlur(moment(v).valueOf()) }}
  />
}

// function MyDatePicker({ label, id = "date-picker-inline", required, disableFuture = false, ...props }) {
//   const classes = useStyles();
//   return <KeyboardDatePicker
//     {...props}
//     className={classes.root}
//     disableFuture={disableFuture}
//     autoOk={true}
//     // disableToolbar
//     fullWidth
//     variant="dialog"
//     margin="normal"
//     id={id}
//     label={label}
//     format="DD MMMM YYYY"
//     placeholder="DD MMMM YYYY"
//     inputVariant="outlined"
//     InputAdornmentProps={{ position: "start" }}
//     value={props.value ? moment(props.value) : null}
//     onChange={v => { console.log('DatePicker onChange', v, moment(v).valueOf()); props.onChange(moment(v).valueOf()) }}
//     onBlur={v => { console.log('DatePicker onBlur', v, moment(v).valueOf()); props.onBlur && props.onBlur(moment(v).valueOf()) }}
//     KeyboardButtonProps={{
//       'aria-label': 'change date',
//     }}
//   />
// }

export default MyDatePicker
