import React from 'react'
import gql from 'graphql-tag'
import { GraphQLFormProvider } from 'react-form-helper'
import NewBeneficiary from './NewBeneficiary'
import { BuildLayout, Card, renderInput, renderDisplay, FormModal } from '../generic';

const EditModal = (props) => {
  const { size } = props || 'small'
  return (
    <FormModal
      okText='Ok'
      cancelText='Back'
      width='100%'
      style={{ top: 10 }}
      disableCancel={true}
      disableOk={true}
      size={size}
      componentProps={props}
    />
  )
}

const fields = {
  'beneficiaries[0].payToId': {
    type: 'select',
    options: [],
    label: 'Select a Beneficiary',
        validate: (value) => {
          if (!value) {
            return 'This is Required'
          }
    },
  }
}

const config = {
  name: 'funeral',
  query: gql`
  query FuneralData($id: String!) {
    funeral(id: $id) {
      id
      livesCovered {
        id
        firstNames
        surname
        idNumber
        memberType
        cellNumbers
        email
        relationship
        }
        beneficiaries {
          id
          payToId
          payTo {
            id
            firstNames
            surname
            relationship
          }
          payoutType
          percentage
          
      }
    }
  }`,
updateMutation: gql`
  mutation UpdateFuneral( $id: String!, $input: UpdateFuneralInput!) {
      updateFuneral(id: $id, input: $input) {
        id
        livesCovered {
        id
        firstNames
        surname
        idNumber
        memberType
        cellNumbers
        email
        relationship
        }
        beneficiaries {
          id
          payToId
          payTo {
            id
            firstNames
            surname
            relationship
          }
          payoutType
          percentage
          
      }
    }
  }`
}

function FormLayout(props) {
  const { id, afterSubmit, cancel, mode, data, formName } = props || {}
  const { funeral } = data || {}
  const { livesCovered, beneficiaries } = funeral || {}
  const options = []

  beneficiaries && beneficiaries.length > 0 && beneficiaries.forEach(member => options.push({
    value: member.payTo.id, label: `${member.payTo.firstNames} ${member.payTo.surname} - BENEFICIARY`, key: 'BENEFICIARY'
  }))

  livesCovered && livesCovered.length > 0 && livesCovered.filter(m => m.memberType !== "BENEFICIARY").forEach(member => options.push({
    value: member.id, label: `${member.firstNames} ${member.surname} - ${member.memberType}`, key: member.memberType
  }))

  fields['beneficiaries[0].payToId'].options = options.sort((a, b) => (a.key < b.key) ? 1 : -1)

  const fieldNames = Object.keys(fields)
  console.log('beneficiary FormLayout', props)

    return (
      <Card>
        <h3>Either define a new beneficiary or select from the lives covered on the policy</h3>
        <EditModal Content={NewBeneficiary} heading='Create Beneficiary' text='Add Beneficiary' {...props} mode={"add"} funeralId={id} memberType={'BENEFICIARY'} afterSubmit={afterSubmit} />
        <br />
        <br />
        <BuildLayout formName={formName} fieldNames={fieldNames} mode={mode} fieldConfig={fields} direction='column' spacing={2} cancel={() => cancel()} />
        </Card>
    );
  }

let Beneficiary = (props) => {

  const { id, memberId, cancel, afterSubmit } = props

  return (
    <div>
      <GraphQLFormProvider
        mode={"edit"}
        id={id}
        fields={fields}
        {...config}
        afterSubmit={(props) => { console.log('beneficiary afterSubmit', props); cancel() }}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout {...props} formName={config.name }  memberId={memberId} afterSubmit={afterSubmit} cancel={cancel} />}
        mapInputVariables={input => {
          console.log('Beneficiary mapInputVariables ', input)
          const { beneficiaries } = input
          const cleanBeneficiaries = beneficiaries.map(b => {
            const { payTo, ...clean } = b
            return clean
          })
          return { input: { beneficiaries: cleanBeneficiaries } }
        }}  // defining the input to use the input needed in the update mutation. 
        DisplayFormLayout={FormLayout}
      />
    </div>
  )
}

export default Beneficiary
