import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Card, Modal, GraphQLTable } from '../generic';
import RegisteredUser from './RegisteredUser';

const config = {
  query: gql`
    {
      users(limit:20000) {
        id
        username
        email
        authProvider
        roles
        forClientId
        forClient {
          id
          idNumber
          firstNames
          surname
        }
      }
    }
  `,
  dataset: data => data.users.filter(u => u.forClientId),
  fields: {
    'forClient.surname': { label: 'Last' },
    'forClient.firstNames': { label: 'First' },
    'forClient.idNumber': { label: 'ID' },
    email: { label: 'Email Address' },
    authProvider: { label: 'Authenticator' },
    roles: { label: 'Roles', Cell: row => row.value ? row.value.toString() : '' }
  }
};

const RegisteredUserList = ({ history }) => {
  const [open, setOpen] = useState(false)
  const [clientId, setClientId] = useState(false)
  return (
    < Card >
      <GraphQLTable
        config={config}
          onRowPress={(user) => { setClientId(user.forClientId); setOpen(true) }}
      />

        <Modal
          title="Remove Client Registration"
          open={open}
          noButtons={true}

        >
          <RegisteredUser onCancel={() => setOpen(false)} id={clientId} />

        </Modal>

  </Card >
  )
}

export default RegisteredUserList;