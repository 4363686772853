import React from 'react'
import Button from '@material-ui/core/Button';

// import styled from '@emotion/styled'

// const ButtonText = styled(Text)`
//     font-weight: 800;
//     line-height: 18px;
//     text-align: center;
//     letter-spacing: 2px;
//     text-transform: uppercase;
// `;

const MyButton = (props) => {
  const { variant = 'contained', ...rest } = props
  return <Button  {...rest} variant={variant} />
}

// export {
//   ButtonText
// }
export default MyButton
