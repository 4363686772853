import React from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import { touch } from 'redux-form'
import { makeStyles } from '@material-ui/core/styles';
import { FormField } from 'react-form-helper'
import SubmitFormButton from 'react-form-helper/dist/SubmitFormButton';
import ActivityIndicator from './activityIndicator'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // justifyContent: 'space-evenly',
    paddingBottom: 7,
    // gap: '100px'
  },
  footer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingBottom: 21
  },
  column: {
    minWidth: '80%',
    maxWidth: '80%',
    padding: 0
  },
  row: {
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    padding: 0
  },
  button: {
    minWidth: 100,
    maxWidth: 100,
    textAlign: 'center',
    marginRight: 14
  }
}));


const Layout = ({ setTouched, fieldNames, mode = 'edit', fieldConfig, prefix, wide = false, extraFields, justify = 'flex-start', align = 'left', direction = 'row', required = true, disabled = false, spacing = 2, cancel, push, allowNull = false }) => {
  const classes = useStyles();

  var cols = []

  if (extraFields) {
    fieldNames.unshift(extraFields)
  }

  fieldNames.forEach((field, index) => {

    let name = field
    let label

    if (prefix === 'info') {
      name = `${prefix}.${field.key}`
    }
    else if (prefix === 'documents') {
      name = `${prefix}.config.${field.fileId}`
      label = `${field.description}`
    }
    else {
      label = fieldConfig[field].label
    }

    const disabled = disabled || (fieldConfig && fieldConfig[field] && fieldConfig[field].disabled) || (mode === 'edit' && fieldConfig && fieldConfig[field] && fieldConfig[field].addOnly) || (!allowNull && fieldConfig && fieldConfig[field] && fieldConfig[field].nullOnly)
    if (disabled) {
      fieldConfig[field].disabled = true
    }
    else {
      fieldConfig[field].disabled = false
    }

    // console.log('BuildInput field', field, disabled)
    cols.push(<>
      <Grid item xs={2} className={direction === 'row' ? classes.row : classes.column}>
        <FormField noLabel required={required} key={index} name={name} label={label} />
      </Grid>
    </>
    )
  })

  return (<>
    <Grid
      container
      direction={direction}
      justifyContent={justify}
      alignItems={align}
      spacing={spacing}
      className={classes.root}
    >
        {cols}
      </Grid>

      <br /> <br />
    <SubmitFormButton renderSubmitButton={({ invalid, submit, submitting }) => (
        <Grid container className={classes.footer}>
          <Grid item className={classes.button}>
          <Button fullWidth variant='contained' color='default' disabled={submitting} onClick={() => cancel ? cancel() : push ? push() : ''}>
              Cancel
            </Button>
          </Grid>
        <Grid item className={classes.button} onClick={() => (invalid || submitting) ?  setTouched(fieldNames)  : ''}>
          <Button fullWidth variant='contained'
            color='primary' disabled={invalid || submitting} onClick={() => submit()}>
              Save
            </Button>
        </Grid>
        {submitting && <ActivityIndicator />}
      </Grid>)} />
  </>
  )
}

const mapDispatchToProps = (dispatch, { formName }) => ({
  setTouched: fieldNames => dispatch(touch(formName, ...fieldNames))
})

const BuildLayout = connect(() => ({}), mapDispatchToProps)(Layout)
export default BuildLayout