import React from 'react';
import gql from 'graphql-tag'
import GraphQLTable from '../generic/GraphQLTable';
import { Card } from '../generic'

export const BANK_ACCOUNTS = gql`
{
 bankaccounts(limit:10000) {
   id
   client {
     id
     idNumber
     firstNames
     surname
   }
  bankName
  accountNo
  ownAccount
  accountHolderName
  accountHolderContactNumber
  accountHolderEmail
  accountHolderIdNumber
  accountHolder {
    id
    firstNames
    surname
  }
  ownAccount
 }
}
 `
 //   verificationInfo: AccountVerificationInfo

const config = {
  query: BANK_ACCOUNTS,
  dataset: data => data.bankaccounts,
  fields: {
    name: { label: 'Name', accessor: d => d.client ? `${d.client.firstNames} ${d.client.surname}` : "-" },
    idNumber: { label: 'Id Number', accessor: d => d.client ? `${d.client.idNumber}` : "-" },
    bankName: { label: 'Bank' },
    accountNo: {label: "Account No"},
    ownAccount: { label: "Own Acount" },
    accountHolderName: { label: 'Account Holder' },
    idNumberMatch: { label: 'Id Number Matched', accessor: d => d.idNumberMatch ? "Yes" : "No" },
    nameMatch: { label: 'Name Matched', accessor: d => d.nameMatch ? "Yes" : "No", filter:'select' },
    phoneMatch: { label: 'Phone Matched', accessor: d => d.phoneMatch ? "Yes" : "No" , filter:'select'},
    accountExists: { label: 'Account Exists', accessor: d => d.accountExists ? "Yes" : "No" , filter:'select'},
  }, defaultSorted: [{ name: 'name' }]
}
const BankAccountList = ({ history }) => (
  <Card>
    <h3>Bank Accounts</h3>
    <GraphQLTable config={config} pageSize={30} onRowPress={bank => history.push(`/bankaccounts/${bank.id}`)} />
  </Card>
);

export default BankAccountList;