import React from 'react'
import { format } from 'date-fns'
import { useQuery } from 'react-apollo';
import { MaterialTable } from '../../generic'
import gql from 'graphql-tag';

const GetPayments = gql`
  query Paymentstatuss($filter: PaymentStatusFilter!) {
    paymentstatuss(filter: $filter) {
        id
        paymentMethod
        status
        info
        action
        expectedDate
        externalId
        error
        done
        rawData
        createdAt
     }
    }`

const cols = [
  {
    Header: 'Date',
    accessor: 'paymentstatus.createdAt',
    Cell: ({ value }) => format(value, 'dd-MM-yyyy')
  },
  {
    Header: 'Method',
    accessor: 'paymentMethod',
  },
  {
    Header: 'Status',
    accessor: 'status',
  }
]

const PaymentStatus = ({ id }) => {
  const { data, loading, error } = useQuery(GetPayments, { variables: { filter: { paymentId: id } } }) || {}

  console.log('payment status data', data)
  if (loading || (data.paymentstatuss && data.paymentstatuss.length === 0)) {
    return <div />
  }

  return (
    <>
      <h2>Payment Status</h2>
      {data && <MaterialTable columns={cols} data={data} download={true} />}
    </>
  )
}

export default PaymentStatus
