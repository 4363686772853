import React, { useState, useEffect } from 'react';
import * as FaIcons from "react-icons/fa";
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ActivityIndicator, Button, Card, Modal, Grid, DisplayItem, MaterialTable } from '../generic'
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import { printCurrency } from '../../lib/helpers';
import { API_URL } from '../../config';
import fileDownload from 'js-file-download'
import { SelectTermsVersion, setLoanPackage, EditClientObligationModal } from './CreditLifeQuote';
import { creditLifeInformation } from './CreditLife'
import ChangeLoan from './ChangeLoan'
import './Loans.css'

// Create an editable cell renderer
const EditableCell = (props) => {
  const {
    value: initialValue,
    row: { index },
    column: { id, type, options },
    updateMyData, // This is a custom function that we supplied to our table instance
  } = props

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = e => {
    // console.log('EditableCell onChange ', type === 'select' ? e : e.target.value)
    setValue(type === 'select' ? e : e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = (e) => {
    // run mutation to change description
    updateMyData(index, id, e.target.value)
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // Check to make sure not all columns are editable (only type of select)

  console.log('Material editable cell select', type)
  if (type === 'select') {
    return (
      <Select noLabel value={value} options={options.map(o => ({ value: o, label: o }))} onChange={onChange} onBlur={onBlur} />
    );
  }
  else {
    return (
      <TextField noLabel value={value} onChange={onChange} onBlur={onBlur} />
    );
  }
};


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    fontSize: 21,
  },
  center: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    paddingBottom: 7
  },
  paper: {
    minWidth: 300,
    paddingRight: 7
  },
  buttonRow: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    marginTop: '-3em',
    paddingRight: 21,
  }
}));

const getAgeFromIdNumber = (idNumber) => moment().diff(moment(((idNumber[0] === '0' ? '20' : '19') + idNumber).substring(0, 8), "YYYYMMDD"), "years")

const allLoanType = [
  "PERSONAL_LOAN",
  "STUDENT_LOAN",
  "REVOLVING_LOAN",
  "CREDIT_CARD",
  "OVERDRAFT",
  "AFFORDABLE_HOUSING",
  "VEHICLE_LOAN",
  "HOME_LOAN",
]

const coveredLoanType = [
  "PERSONAL_LOAN",
  "STUDENT_LOAN",
  "REVOLVING_LOAN",
  "CREDIT_CARD"
]

/* Filter from api
  .filter(o => o.accountType && ['C', 'D', 'E', 'G', 'I', 'M', 'N', 'O', 'P', 'R', 'T', 'V', 'Z', 'B', 'Y'].indexOf(o.accountType.toUpperCase()) > -1)
  .filter(obl => {
    if (!unique[obl.reference]) {
      unique[obl.reference] = true
      return (obl.statusCode != 'C' && obl.currentBalance > 0)
    }
*/

const UPDATE_CLIENT_OBLIGATION = gql`
	mutation($id:  String!, $input: UpdateClientObligationInput!) {
		updateClientObligation(id: $id,  input: $input) {
      id
			currentBalance
    }
  }
`;

const POLICY_UPDATE = gql`
  mutation SendPolicyUpdate($id: String, $termsVersion: CreditLifeTermsVersion, $obligations: [ObligationInsuranceInput]) {
    sendCreditLifePolicyUpdate(id: $id, termsVersion: $termsVersion, obligations: $obligations, matchDiscount: 0) {
      id
      termsVersion
      pricingVersion
      payment
      coverAmount
      obligationInsurances {
        id
        package
        rejectReason
        amount
        premiumAmount
        cancelledAt
        statusAt
        status
        obligationId
				obligation {
          id
          externalId
					clientCreditor {
            id
						description
					}
					reference
          inceptionDate
					currentBalance
					insuranceAmount
          creditLifeInsurance
        }
        pricingVersionOverride
      }
      policyStatus
      
    }
  }
`

const request = async ({ id, setShowSpinner, COI, CAR }) => {
  setShowSpinner(true);
  const accessToken = localStorage.getItem('accessToken');
  const response = await fetch(`${API_URL}creditLife/${id}/${COI ? 'coi' : 'car'}`, { headers: { 'Authorization': accessToken ? `JWT ${accessToken}` : null } })
  const data = await response.blob()
  const file = await fileDownload(data, `${COI ? 'COI' : 'CAR'}-${id}.pdf`)
  setShowSpinner(false);
}

const DownloadPDF = ({ id, showSpinner, setShowSpinner, COI, CAR }) => (
  <Button color="primary" variant="outlined" disabled={showSpinner} onClick={() => {
    request({ id, setShowSpinner, COI, CAR })
  }}>
    <FaIcons.FaDownload /> &nbsp; {CAR ? 'CAR' : 'COI'}
  </Button>
)

function Strike({ text, row }) {
  return (
    <div>
      {!row.cancelledAt && row.package !== 'REJECTED' ? <span style={{ userSelect: 'none' }}>{text}</span> : <span style={{ textDecoration: 'line-through' }}>{text}</span>}
    </div>
  )
}

const percentDifference = (newPrice, minPrice) => roundAccurately((minPrice - newPrice) / minPrice * 100, 2)
const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces)

const QuoteSummary = ({ loans, undoQuote, updatePolicy, minPrice, openMarket }) => {
  const classes = useStyles();

  const newPrice = loans.filter(loan => loan.package && loan.package !== "REJECTED" && !loan.cancelledAt && (!openMarket || loan.status !== 'CANCELLED')).reduce((total, loan) => total + (loan.quoteAmount || loan.premiumAmount || 0), 0)
  return loans && loans.filter(loan => loan.quoteAmount).length > 0 && (
    <Card>
      <h2>Updated Quote</h2>

      {newPrice < minPrice &&
        <Grid container className={classes.center}>
          <Grid item >
            <b>{`The quote of ${newPrice} is below the minimum amount of ${minPrice} for this policy (a ${percentDifference(newPrice, minPrice)}% difference) - please reassess`}</b><br />
          </Grid>
        </Grid>}

      <Grid container className={classes.center} spacing={3}>
        <Grid item className={classes.paper} >
          <DisplayItem className={classes.paper} label="New Quote Premium" value={printCurrency(newPrice)} xs={2} />
        </Grid>
        <Grid item >
          <DisplayItem className={classes.paper} label="New Cover Amount" value={printCurrency(loans.filter(loan => loan.package && !loan.cancelledAt && loan.package !== 'REJECTED').reduce((total, loan) => total + (loan.amount || 0), 0))} xs={2} />
        </Grid>
        <Grid item className={classes.paper}>
          <DisplayItem className={classes.paper} label="New Minimum Amount" value={printCurrency(minPrice)} xs={2} />
        </Grid>
      </Grid>
      <br />
      <Grid container className={classes.center} spacing={3}>
        <Grid item>
          <Button onClick={undoQuote} >  Undo Changes </Button>
        </Grid>
        {newPrice >= minPrice &&
          <Grid item>
            <Button color="primary" onClick={updatePolicy} > Save Changes </Button>
          </Grid>}
      </Grid>
    </Card>
  )
}

const buildLoans = (props) => {
  const { loans, obligations, basicListed, creditlife } = props
  //console.log('buildLoans from ', props)
  const result = (loans || []).slice(0)
  const includedIds = {}
  loans && loans.forEach(loan => { includedIds[loan.obligationId] = true })
  obligations.forEach(obligation => {
    //if (!includedIds[obligation.id] && obligation.currentBalance > 0 && (obligation.statusCode === ' ' || obligation.statusCode === 'E') && (creditlife.productType !== 'OPEN_MARKET' || coveredLoanType.includes(obligation.loanType))) {
    if (!includedIds[obligation.id] && obligation.currentBalance > 0 && (creditlife.productType !== 'OPEN_MARKET' || coveredLoanType.includes(obligation.loanType))) {
      let checkPack = setLoanPackage(obligation, basicListed, props.termsVersion, creditlife.productType)
      let reason = checkPack.reason
      let pack = checkPack.package

      result.push({
        inceptionDate: null,
        package: pack,
        rejectReason: reason,
        premiumAmount: null,
        quoteAmount: null,
        obligation,
        pricingVersionOverride: creditlife.productType !== "OPEN_MARKET" ? "CURRENT" : undefined
      })
    }
  })

  console.log('buildLoans result ', result)
  return result

}

const accountHolderText = {
  POLICY_HOLDER: "Policy Holder",
  SECONDARY_APPLICANT: "Spouse",
  JOINT: "Joint"
}

let LoanObligations = (props) => {
  const classes = useStyles();

  const { creditlife, basicListed } = props || {}
  const { id: creditlifeId, productType } = creditlife || {}
  const [loans, setLoans] = useState()
  const [obligations, setObligations] = useState(props.obligations)
  const [termsVersion, setTermsVersion] = useState(props.termsVersion)
  const [currentLoan, setCurrentLoan] = useState()
  const [visible, setVisible] = useState(false)
  const [loanModalVisible, setLoanModalVisible] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showLoanReference, setShowLoanReference] = useState(false)
  const [disablePDF, setDisablePDF] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [loanId, setLoanId] = useState()
  const [minPrice, setMinPrice] = useState()
  const [snackOpen, setSnackOpen] = useState(false)
  const [snackText, setSnackText] = useState()
  const [severity, setSeverity] = useState('success')

  const openMarket = props.creditlife.productType === 'OPEN_MARKET'
  const creditProviderPolicy = props.creditlife.productType === 'CREDIT_PROVIDER'
  const rma = props.creditlife.productType === 'RMA'

  useEffect(() => {
    setLoans(buildLoans(props))
    if (['POLICY_ISSUED_UPDATE', 'QUOTE_UPDATE', 'QUOTE_EXISTING_BUSINESS'].includes(props.creditlife.policyStatus)) {
      reQuote(buildLoans(props), termsVersion)
    }
  }, []) // <-- empty dependency array

  const columns = [
    {
      Header: '',
      id: 'new',
      accessor: (row) => { return (row.premiumAmount === null && row.quoteAmount === null && row.package !== 'REJECTED' ? '*NEW*' : '') }
    },
    {
      Header: 'Creditor',
      id: 'obligation.clientCreditor.description',
      accessor: (row) => <Strike text={row.obligation && row.obligation.clientCreditor.description} row={row} />

    },
    {
      Header: 'Account Holder',
      id: 'obligationAccountHolder',
      accessor: (row) => <Strike text={accountHolderText[row.obligationAccountHolder || "POLICY_HOLDER"]} row={row} />
    },
    {
      Header: 'Status',
      id: 'status',
      hide: true,
      accessor: (row) => <Strike text={row.status} row={row} />
    },
    {
      Header: 'Reference',
      id: 'obligation.reference',
      accessor: (row) => <Strike text={row.obligation ? row.obligation.reference : ''} row={row} />
    },
    {
      Header: 'Type',
      id: 'obligation.accountCode',
      accessor: (row) => <Strike text={props.creditlife.productType !== 'OPEN_MARKET' ? row.obligation && row.obligation.accountCode : row.obligation.loanType ? row.obligation.loanType.replace(/_/g, ' ') : ''} row={row} />
    },
    {
      Header: 'Package',
      id: 'package',
      accessor: (row) => <Strike text={row.package} row={row} />
    },
    {
      Header: 'Reason',
      id: 'rejectReason',
      accessor: (row) => <Strike text={row.package == 'REJECTED' ? row.rejectReason : ''} row={row} />
    },
    {
      Header: 'Inception',
      id: 'obligation.inceptionDate',
      accessor: (row) => <Strike text={row.obligation ? row.obligation.inceptionDate != null ? moment(row.obligation.inceptionDate).format('DD-MM-YYYY') : '' : ''} row={row} />
    },
    {
      Header: 'Previous',
      id: 'obligation.creditLifeInsurance',
      align: 'right',
      accessor: (row) => {
        return <Strike text={!row.obligation.creditLifeInsurance ? row.obligation && !row.obligation.insuranceAmount && row.obligation.insuranceAmount ? '-' : row.obligation && row.obligation.shortTermInsurance !== 0 ? '-' : row.obligation ? printCurrency(row.obligation.insuranceAmount) : '' : printCurrency(row.obligation.creditLifeInsurance)} row={row} />
      }
    },
    {
      Header: 'Cover Amount',
      id: 'amount',
      editable: openMarket ? false : false,
      accessor: (row) => <Strike text={printCurrency(row.amount)} row={row} />
    },
    {
      Header: 'Current Balance',
      accessor: 'obligation.currentBalance',
      // align: 'right',
      Cell: ({ value }) => printCurrency(value)
    },
    {
      Header: 'Balance Date',
      id: 'balanceDate',
      align: 'center',
      accessor: (row) => row.obligation.balanceDate ? moment(row.obligation.balanceDate).format('DD-MM-YYYY') : getBalanceDate(row)
    },
    {
      Header: 'Current Payment',
      id: 'premiumAmount',
      align: 'right',
      accessor: (row) => <Strike text={printCurrency(row.premiumAmount)} row={row} />
    },
    {
      Header: 'Quote',
      id: 'quoteAmount',
      align: 'right',
      accessor: (row) => <Strike text={printCurrency(row.quoteAmount)} row={row} />
    },
    {
      Header: 'Discount',
      id: 'Discount',
      align: 'center',
      accessor: (row) => row.calcPremium ? `${Math.round(((row.calcPremium - row.quoteAmount) / row.calcPremium) * 100)} %` : "",
    }
  ];

  const changeReference = (loan, loanReference) => {
    props.client
      .mutate({
        mutation: UPDATE_CLIENT_OBLIGATION,
        variables: {
          id: loan.obligation.id,
          input: {
            reference: loanReference
          }
        }
      })
      .then(() => {

        setSnackText('The loan reference has been changed')
        setSeverity('success')
        setSnackOpen(true)

        const updatedLoans = loans.map(loan => {
          let result = { ...loan }

          let { obligation, ...rest } = result
          let { reference, id, ...obj } = obligation

          if (currentLoan.obligation && currentLoan.obligation.id === id || currentLoan.obligationId === id) {
            reference = loanReference
          }
          let obl = { ...obj, id, reference }
          let res = { ...rest, obligation: obl }
          return res
        })

        setLoans(updatedLoans)
      })
  }

  const onChangeTermsVersion = (version) => {
    let filteredLoans = ''
    if (version === "BASIC") {
      filteredLoans = loans.map(loan => (loan.package === "EXTRA" ? { ...loan, package: "NORMAL" } : { ...loan }))
      setTermsVersion("BASIC")
      setLoans(filteredLoans)
      reQuote(filteredLoans, version)

    } else {
      filteredLoans = loans.map(loan => (loan.package === 'REJECTED' && loan.rejectReason === 'basic/hazardous' ? { ...loan, package: 'NORMAL', rejectReason: '' } : loan))
      // TODO: What if changed to BASIC then back to CORE
      setTermsVersion("CORE")
      reQuote(filteredLoans, version)
    }
  }

  const setupQuote = (props) => {
    setObligations(props.obligations)
    setTermsVersion(props.termsVersion)
    setLoans(buildLoans(props))
  }

  const undoQuote = () => {
    setupQuote(props)
  }

  const getBalanceDate = (loan) => {
    const { balances, currentBalance } = loan.obligation
    if (balances && balances.length) {
      const sortedDates = balances.slice(0).sort((a, b) => (a.date > b.date) ? 1 : -1)
      const balanceDate = sortedDates[sortedDates.length - 1].date
      return moment(balanceDate).format('DD-MM-YYYY')
    }
    else return ''
  }


  const changeBalance = (loan, balanceDate) => {
    console.log('changeBalance ', loan.obligation)

    const { balances, currentBalance } = loan.obligation
    let newBal = []
    balances.forEach(bal => {
      const { __typename, ...clean } = bal
      newBal.push(clean)
    })

    const length = newBal.length
    const prevBalance = newBal[length - 1].balance
    const prevBalanceDate = newBal[length - 1].date
    newBal.push({ date: balanceDate ? balanceDate : moment().valueOf(), balance: currentBalance, prevBalance, prevBalanceDate, balanceSource: 'ADMIN' })

    console.log('new bal is ', newBal)

    props.client
      .mutate({
        mutation: UPDATE_CLIENT_OBLIGATION,
        variables: {
          id: loan.obligation.id,
          input: {
            currentBalance,
            balanceDate,
            balances: newBal
          }
        },
        refetchQueries: [{ query: creditLifeInformation, variables: { id: props.creditlife.id } }]
      })
      .then((response) => {
        const { data } = response
        setSnackText('The loan current balance has been changed')
        setSeverity('success')
        setSnackOpen(true)

        const updatedLoans = loans.map(loan => {
          let result = { ...loan }

          let { obligation, ...rest } = result
          let { currentBalance: balance, balanceDate: date, id, ...obj } = obligation

          if (currentLoan.obligation && currentLoan.obligation.id === id || currentLoan.obligationId === id) {
            balance = currentBalance
            date = balanceDate
          }
          let obl = { ...obj, id, currentBalance: balance, balanceDate: date }
          let res = { ...rest, obligation: obl }
          return res
        })

        setLoans(updatedLoans)

      })
  }

  const getQuoteViaGraphQL = (obligations, version) => {
    let usetermsVersion = version
    if (!version) { usetermsVersion = termsVersion } // use state if not passed in

    return props.client
      .query({
        query: gql`
          query CreditLifeQuote($productType: CreditLifeProductType, $termsVersion: CreditLifeTermsVersion, $pricingVersion: String $obligations: [ObligationInsuranceInput], $age: Int) {
            getCreditLifeQuote(productType: $productType, termsVersion: $termsVersion, pricingVersion: $pricingVersion, obligations: $obligations, matchDiscount: 0, age: $age) {
              price
              minPrice
              priceBreakdown {
                id
                amount
                premiumAmount
              calcPremium
              }
            }
          }
        `,
        variables: {
          productType: props.creditlife.productType,
          termsVersion: usetermsVersion,
          pricingVersion: openMarket ? 'OPEN_MARKET_RGA' : props.creditlife.pricingVersion,
          obligations,
          age: props.creditlife.client.idType === 'rsa_id' ? getAgeFromIdNumber(props.creditlife.client.idNumber) : null
        },
        fetchPolicy: 'network-only'
      })
  }

  const requoteObligations = (obligations, version, row) => {
    getQuoteViaGraphQL(obligations, version)
      .then(data => {

        const quotedLoans = loans.map(loan => {
          let result = { ...loan }
          if (row && (loan.obligationId || loan.obligation.id) === (row.obligationId || row.obligation.id)) {
            result = { ...result, ...row }
          }

          data.data.getCreditLifeQuote.priceBreakdown.forEach(p => {

            if (loan.obligation && loan.obligation.id === p.id || loan.obligationId === p.id) {

              let ob = obligations.filter(o => (o.obligationId || o.obligation.id) === (result.obligationId || result.obligation.id))

              if (ob && ob[0]) {
                result.status = ob[0].status
                result.package = ob[0].package
                if (result.package === "REJECTED") {
                  result.status = "CANCELLED"
                  if (!result.rejectReason) {
                    result.rejectReason = "admin rejected"
                  }
                }
                if (result.package !== "REJECTED") {
                  result.rejectReason = null
                }
              }
              //result.premiumAmount = result.package === "REJECTED" ? 0 : p.premiumAmount
              result.amount = result.package === "REJECTED" ? 0 : p.amount
            
              result.amount = result.status === "PENDING" ? 0 : p.amount

              if (!p.premiumAmount) {
                result.quoteAmount = 0
              }
              else {
                result.quoteAmount = result.package === "REJECTED" ? 0 : p.premiumAmount
                result.calcPremium = result.package === "REJECTED" ? 0 : p.calcPremium
              }

              if (!result.status && props.creditlife.productType === 'OPEN_MARKET') {
                result.status = 'PENDING'
              }
            }
          })
          return result
        })
        setSnackText('The loans were requoted')
        setSeverity('success')
        setSnackOpen(true)
        console.log('requoteObligations', quotedLoans)
        setLoans(quotedLoans)
        setMinPrice(data.data.getCreditLifeQuote.minPrice)

      })
  }

  const getQuote = (row, newPackage, newStatus) => {
    // console.log('getQuote ', row, newPackage, newStatus, loans)
    const obligations = loans.filter(loan => (loan.obligation.id === row.obligation.id || (loan.cancelledAt === null && loan.package !== 'REJECTED'))).map(loan => {
      return {
        status: loan.obligation.id === row.obligation.id ? newStatus : loan.status,
        obligationId: loan.obligationId || loan.obligation.id,
        id: loan.obligationId || loan.obligation.id,
        amount: loan.amount || loan.obligation.currentBalance,
        previousPremium: loan.obligation ? loan.obligation.creditLifeInsurance && loan.obligation.creditLifeInsurance > 0 ? loan.obligation.creditLifeInsurance : loan.obligation.shortTermInsurance === 0 ? loan.obligation.insuranceAmount : 0 : 0,
        package: loan.obligation.id === row.obligation.id ? newPackage : loan.package,
        rejectReason: loan.rejectReason,
        pricingVersionOverride: loan.pricingVersionOverride,
      }
    })
    console.log('getQuote obligations are', obligations)
    requoteObligations(obligations, termsVersion, row)
  }

  const reQuote = (rows, version) => {
    const obligations = rows.filter(row => (row.cancelled === null || !row.cancelled) && row.status !== 'CANCELLED' && row.package !== 'REJECTED').map(row => {
      return {
        status: row.status,
        obligationId: row.obligation ? row.obligation.id : row.obligationId,
        id: row.obligation ? row.obligation.id : row.obligationId,
        amount: row.amount || row.obligation.currentBalance,
        previousPremium: row.obligation && row.obligation.creditLifeInsurance && row.obligation.creditLifeInsurance > 0 ? row.obligation.creditLifeInsurance : row.obligation && row.obligation.shortTermInsurance === 0 ? row.obligation.insuranceAmount : 0,
        package: row.package,
        rejectReason: row.rejectReason,
        pricingVersionOverride: row.pricingVersionOverride
      }
    })
    requoteObligations(obligations, version)
  }

  const updatePolicy = () => {
    setShowSpinner(true);
    // console.log('updatePolicy', creditlifeId, openMarket)

    props.client.mutate({
      mutation: POLICY_UPDATE,
      variables: {
        id: creditlifeId,
        termsVersion: termsVersion,
        obligations: loans.filter(l => !openMarket || (openMarket && l.status)).map(loan => {
          if (openMarket) {
            return ({
              id: loan.obligation.id,
              obligationId: loan.obligation.id,
              status: loan.status,
              statusAt: loan.statusAt ? loan.statusAt : moment().valueOf(),
              package: loan.package,
              cancelledAt: loan.cancelledAt,
              obligationId: loan.obligation.id,
            })
          }
          else return ({
            amount: loan.amount || loan.obligation.currentBalance,
            package: loan.package, 
            id: loan.obligation.externalId,
            obligationId: loan.obligation.id,
            pricingVersionOverride: openMarket ? 'OPEN_MARKET_RGA' : loan.pricingVersionOverride,
            previousPremium: loan.obligation.creditLifeInsurance && loan.obligation.creditLifeInsurance > 0 ? loan.obligation.creditLifeInsurance : loan.obligation.insuranceAmount,
            cancelledAt: loan.cancelledAt,
            obligationAccountHolder: loan.obligationAccountHolder
          })
        }),
      },
      refetchQueries: [{ query: creditLifeInformation, variables: { id: props.creditlife.id } }]
    })
      .then((result) => {
        // console.log('update policy ', result)
        setShowSpinner(false);
      })
      .catch(error => {
        console.error('Error during update', error);
      });
  }


  const changeLoan = (updatedRow) => {
    setCurrentLoan(updatedRow)
    // console.log('updateMyRow ', updatedRow)
    setLoans(old =>
      old.map((row) => {
        if ((row.obligationId || row.obligation.id) === (updatedRow.obligationId || updatedRow.obligation.id)) {
           return updatedRow
        }
        return row
      })
    )
    // console.log('updateMyRow loans', loans)
  }

  const updateMyData = (rowIndex, columnId, value) => {
    console.log('Editable cell updateMyData ', value)
    setLoans(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }

  const onOk = () => {
    setShowModal(false);
  }

  function selectRow(record, rowIndex) {
    changeLoan(record)
    setShowModal(true)
  }

  if (loans) {

    const hasReplacement = loans && loans.reduce((replace, loan) => replace || (loan.obligation && loan.obligation.insuranceAmount > 0), false)
    const displayCAR = (props.creditlife.policyStatus.indexOf('DECLINED') == -1) && (props.creditlife.policyStatus.indexOf('TERMINATED') == -1)

    return (
      <>
        <h2>Loans</h2>

        <Grid container className={classes.buttonRow} spacing={2}>
          {displayCAR && !rma &&
            <Grid item><DownloadPDF id={props.creditlife.id} showSpinner={showSpinner} setShowSpinner={setShowSpinner} CAR /></Grid>
          }
          {(hasReplacement || openMarket || rma || creditProviderPolicy) &&
            <Grid item >
              <DownloadPDF id={props.creditlife.id} showSpinner={showSpinner} setShowSpinner={setShowSpinner} COI />
            </Grid>
          }
        </Grid> 
        {showSpinner && <ActivityIndicator center={false} />}
        <center>
          <SelectTermsVersion selected={termsVersion} onChange={onChangeTermsVersion} />
        </center>

        <br />

        <MaterialTable
          data={loans}
          columns={openMarket
            ? columns
            : columns.filter(ea => !ea.hide)}
          updateMyData={updateMyData}
          onRowPress={(record, rowIndex) => selectRow(record, rowIndex)}
        />

        {/* (props.creditlife.policyStatus.indexOf("APPLICATION") > -1 || props.creditlife.policyStatus.indexOf("QUOTE") > -1) && */}

        <QuoteSummary loans={loans} undoQuote={undoQuote} updatePolicy={updatePolicy} minPrice={minPrice} openMarket={openMarket} />

        <EditClientObligationModal loanId={loanId} visible={loanModalVisible} setLoanModalVisible={setLoanModalVisible} />

        <Modal
          title="Change The Loan"
          open={showModal}
          noButtons
          maxWidth={openMarket ? "lg" : "md"}
          fullWidth
        >
          <ChangeLoan openMarket={openMarket} creditProviderPolicy={creditProviderPolicy} loan={currentLoan} setLoan={changeLoan} getQuote={getQuote} changeBalance={changeBalance} changeReference={changeReference} setShowModal={setShowModal} />
        </Modal>

        <Snackbar open={snackOpen} autoHideDuration={5000} onClose={() => setSnackOpen(false)}>
          <Alert onClose={() => setSnackOpen(false)} severity={severity}>
            {snackText}
          </Alert>
        </Snackbar>

      </>
    )
  }
  else return <div>Loading</div>
}

const graphqlLoadingHOC = Comp => (props) => {
  //console.log('credit life loans starting props ', props)
  return <Comp {...props} />;
};

LoanObligations = graphqlLoadingHOC(LoanObligations);
export default LoanObligations = withApollo(LoanObligations);