import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class BooleanField extends React.PureComponent {

  handleChange = (event) => {
    this.props.onChange(event.target.checked);
  };

  render() {
    const {
      value,
      disabled = false,
      label,
      color = 'primary'
    } = this.props;

    return (
      <FormControlLabel
        control={
          <Switch
            checked={value ? value : false}
            onChange={this.handleChange}
            value="true"
            disabled={disabled}
            color={color}
          />
        }
        label={label}
      />
    )
  }
}

export default BooleanField;
