import React from 'react';
import { MaterialTable } from '../generic'
import { withRouter } from 'react-router-dom'

let SearchGroups = ({ groupproducts = [] }) => {

  const columns = [
    {
      Header: 'Group Name',
      accessor: row => `${row.group.name}`,
      filter: 'fuzzyText'
    },
    {
      Header: 'Consultant',
      accessor: (row) => (row.group.consultant ? (`${row.group.consultant.first} ${row.group.consultant.last}`) : null),
      filter: 'fuzzyText'
    },
    {
      Header: '# Role holders',
      accessor: (row) => (row.group.roleHolders ? row.group.roleHolders.length : null)
    },
    {
      Header: 'Group Product Number',
      accessor: (row) => row.groupProductNumber,
      disableFilters: true
    },
    {
      Header: 'Old Group Product Number',
      accessor: (row) => row.oldGroupProductNumber,
      disableFilters: true
    }
  ]

  return (
    <MaterialTable columns={columns} data={groupproducts} onRowPress={(row) => {
      window.open(`/groups/${row.group.id}`, "_blank");
    }} />
  )
}
SearchGroups = withRouter(SearchGroups)
export default SearchGroups