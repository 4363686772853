import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline'
import TableContainer from '@material-ui/core/TableContainer'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from './TablePaginationActions'
import TableFooter from '@material-ui/core/TableFooter'
import { useTable, usePagination, useSortBy } from 'react-table'
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import { isDate, format } from 'date-fns'

// .MuiTableCell - root

const StyledTable = withStyles({
  root: {
    "& .MuiTableCell-root": {
      padding: 3,
      borderLeft: 'none',
      borderRight: 'none',
      position:'relative'
    },
    "& .MuiTableFooter-root": {
      padding: '0!important',
    },
  }
})(MaUTable);

const MyTableCell = withStyles({
  root: {
    padding: 7
  }
})(TableCell);

const MyPagination = withStyles({
  root: {
    minWidth: 475,
  }
})(TablePagination);


const parsedData = (data) => {
  return data.map(range => {
    return range.map(d => {
      if (d && Number.isInteger(d) && d.toString().length > 12) {
        let x = new Date(d);
        if (isDate(x)) {
          return format(d, 'dd-MM-yy')
        }
        else return d
      }
      else return d
    })
  })
}

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data: parsedData(data) });
    return new Blob([csvString], { type: "text/csv" });
  }
}

const Table = ({ columns, data, onRowPress, updateMyData, skipPageReset, download, clientData, initialPageSize }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    exportData,
    state: { pageIndex, pageSize }, }
    = useTable({
      columns,
      data,
      getExportFileBlob,
      autoResetPage: !skipPageReset,
      updateMyData,
      clientData,
      initialState: { pageIndex: 0, pageSize: initialPageSize },
    },
      useSortBy,
      usePagination,
      useExportData
    )
  const [initialPageCount, setInitialPageCount] = useState(pageCount)

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value))
  }

  // Render the UI for your table
  return (
    <div  >
    <TableContainer>
        <StyledTable {...getTableProps()} size='small' >
        <TableHead>
          {download && <th style={{ position: 'absolute', left: 300, marginTop: '-33px' }}>
            <div style={{ display: 'flex', flexGrow: 1, alignItems: "center" }}>
              <div style={{ padding: 3, cursor: "pointer" }} onClick={() => exportData("csv", false)} > <i className="fas fa-lg fa-file-csv" /></div>
            </div>
          </th>}
        {headerGroups.map(headerGroup => (
          <TableRow style={{ height: 18 }} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <MyTableCell {...column.getHeaderProps()}>
                {column.render('Header')}
              </MyTableCell>
            ))}
          </TableRow>
        ))}

        </TableHead>
      <TableBody>
          {page.map((row, i) => {
          prepareRow(row)
          return (
            <TableRow {...row.getRowProps({ onClick: () => onRowPress && onRowPress(row.original) })}>
              {row.cells.map(cell => {
                return (
                  <MyTableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </MyTableCell>
                )
              })}
            </TableRow>
          )
        })}
        </TableBody>
        {(true || !initialPageCount || initialPageCount > 1) && <TableFooter>
          <TableRow>
            <MyPagination
              rowsPerPageOptions={[10, 25, 40,
                { label: 'All', value: data.length },
              ]}
              colSpan={5}
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>}
      </StyledTable>
      </TableContainer>
    </div>
  )
}

const MaterialTable = ({ clientData, data, columns, onRowPress, updateMyData, download, initialPageSize = 10 }) => {
  const useColumns = React.useMemo(() => columns)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  return (
    <>
      <CssBaseline />
      <Table
        columns={useColumns}
        data={data}
        updateMyData={updateMyData}
        onRowPress={onRowPress}
        skipPageReset={skipPageReset}
        download={download}
        clientData={clientData}
        initialPageSize={initialPageSize}
      />
    </>
  )
}

export default MaterialTable
