import React, { useState } from 'react'
import { useConfirm } from "material-ui-confirm";
import { withApollo } from 'react-apollo'
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag'
import moment from 'moment'
import { Button, Card, DatePicker, ActivityIndicator, TextField } from '../generic'

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 200,
    maxWidth: 200,
    textAlign: 'center',
    marginRight: 14
  }
}));

const CLAIM = gql`
  query claims($filter: ClaimFilter!) {
    claims(filter: $filter) {
      id
      createdAt
    }
  }`

let Search = ({ client, onOk }) => {
  const confirm = useConfirm();
  const classes = useStyles();
  const [spin, setSpin] = useState(false)
  const [claimNumber, setPolicyNumber] = useState()
  const [policyText, setPolicyText] = useState()
  const [effectiveDate, setEffectiveDate] = useState()

  const getClaim = (claimNumber) => {
    setSpin(true)
    client
      .query({
        query: CLAIM,
        variables: { filter: { claimNumber } }
      })
      .then(result => {
        setSpin(false)
        if (result.data.claims && result.data.claims[0]) {
          const { id, ...policy } = result.data.claims[result.data.claims.length - 1]
          onOk(id)
        }
        else {
          setPolicyText(`Claim ${claimNumber} does not exist`)
        }
      })
  }

  const handleChange = (e) => {
    setPolicyNumber(e.target.value ? e.target.value.toUpperCase() : '')
    setPolicyText()
  }

  return (
    <Card>
      <div style={{ minWidth: 400, padding: 14, paddingRight: 21 }}>
        {spin && <ActivityIndicator />}
        <TextField fullWidth variant="outlined" value={claimNumber} required label='Claim Number' onChange={handleChange} inputProps={{ style: { textTransform: "uppercase" } }} helperText={policyText} />
        <br />
        <br />
        <br />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button disabled={!(claimNumber)} onClick={() => getClaim(claimNumber)} >
            Go
          </Button>
        </div>
      </div >
    </Card>
  )
}

export default Search = withApollo(Search);
