import React, { useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { graphql } from 'react-apollo';
import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'
import matchSorter from 'match-sorter'
import Slider from '@material-ui/core/Slider';
import { ActivityIndicator, Button } from './index'
import { DateFilterItem } from './filter'
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
// import { saveAs } from "file-saver";
// import XlsxPopulate from "xlsx-populate";
import moment from 'moment'
import { format, isDate } from 'date-fns'

function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map((row) => {
    return fields.map((fieldName) => {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

const parsedData = (data) => {
  return data.map(range => {
    return range.map(d => {
      if (d && Number.isInteger(d) && d.toString().length > 12) {
        let x = new Date(d);
        if (isDate(x)) {
          console.log('date is', format(d, 'dd-MM-yyyy'))
          return format(d, 'dd-MM-yy')
        }
        else return d
      }
      else return d
    })
  })
}

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data: parsedData(data) });
    return new Blob([csvString], { type: "text/csv" });
  }
  // else if (fileType === "xlsx") {
  //   XlsxPopulate.fromBlankAsync().then(async (workbook) => {
  //     const sheet1 = workbook.sheet(0);
  //     const header = columns.map((c) => c.label || c.exportValue || c.id);
  //     const sheetData = getSheetData(data, header);
  //     const totalColumns = sheetData[0].length;

  //     sheet1.cell("A1").value(sheetData);
  //     const range = sheet1.usedRange();
  //     const endColumn = String.fromCharCode(64 + totalColumns);
  //     sheet1.row(1).style("bold", true);
  //     sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
  //     range.style("border", true);

  //     // format date cells
  //     //const date = XlsxPopulate.numberToDate(num);


  //     return workbook.outputAsync().then((res) => {
  //       saveAs(res, `${fileName}.xlsx`);
  //     });
  //   });
  // }
}


export const getField = (fields, name) => {
  if (!fields) { return null }
  const tree = name.replace(/\]/g,'').split(/[\.\[]/)
  if (tree.length == 1) {
    return fields[tree[0]]
  } else {
    if (fields[name]) {
      return fields[name]
    } else {
      return getField(fields[tree[0]], tree.slice(1).join('.'))
    }
  }
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values (0 means no filter)
export function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  const step = (max) => {
    let step = 1
    if (max >= 100) step = 10
    if (max >= 500) step = 50
    if (max >= 1000) step = 100
    if (max >= 10000) step = 10000
    return parseInt(step, 10)
  }

  console.log('graphql slider ', max)
  return (
    <Slider
      defaultValue={filterValue || min}
      dots
      marks
      step={step(max)}
      min={0}
      max={max || 10000}
      valueLabelDisplay="auto"
      onChange={(event, value) => {
        console.log('slider value', value)
        if (value === 0) {
        setFilter(undefined)
        }
        else {
          setFilter(parseInt(value, 10))
        }
      }}
      />
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '200px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  )
}


export function DateColumnFilter({ column: { filterValue = [], setFilter, preFilteredRows, id } }) {
  console.log('Date Column FIlter ', filterValue)
  const [date, setDate] = useState()
  const onChange = ({ startDate, endDate }) => {
    setDate({ startDate, endDate })
    setFilter((old = []) => [startDate ? Date.parse(startDate) : undefined, old[1]])
    setFilter((old = []) => [old[0], endDate ? Date.parse(endDate) : undefined])
  }

  const [min, max] = useMemo(() => {

    // Calculate the min and max
    // using the preFilteredRows
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0

    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })

    return [min, max]
  }, [id, preFilteredRows])

  return (
    <DateFilterItem startDate={moment(date && date.startDate || min)} endDate={moment(date && date.endDate || max)} onDatesChange={(startDate, endDate) => { onChange({ startDate, endDate }) }} />
  )
}

// export function DateColumnFilter({column: { filterValue = [], setFilter, preFilteredRows, id } }) {
//     const [date, setDate] = useState()
//   const onChange = ({ startDate, endDate }) => {
//       setDate({startDate, endDate})
//       setFilter((old = []) => [startDate ? Date.parse(startDate) : undefined, old[1]])
//       setFilter((old = []) => [old[0], endDate ? Date.parse(endDate) : undefined])
//     }

//   const [min, max] = useMemo(() => {

//     // Calculate the min and max
//     // using the preFilteredRows
//     let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
//     let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0

//       preFilteredRows.forEach(row => {
//         // min = Math.min(row.values[id], min)
//         if (isDate(row.values[id])) {
//           if (isBefore(parseISO(row.values[id]), parseISO(min))) {
//             min = row.values[id]
//           }
//           // max = Math.max(row.values[id], max)
//           if (isAfter(parseISO(row.values[id]), parseISO(max))) {
//             max = row.values[id]
//           }
//         }
//       })

//     console.log('graphql date max and min ', max, min)
//     if (!min) {
//       min = subYears(new Date().valueOf(), 1)
//     }
//     if (!max) {
//       max = new Date().valueOf()
//     }

//       return [min, max]
//     }, [id, preFilteredRows])

//   console.log('graphql date ', date, max, min)
//       return (
//         <DateFilterItem startDate={moment(date && date.startDate || min)} endDate={moment(date && date.endDate || max)} onDatesChange={(startDate, endDate) => { onChange({ startDate, endDate }) }} />
//       )
// }
// <DateRangeFilter start={min} end={max} onDatesChange={(state) => { onChange(state[0]) }} />

export function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
  }
)


// Define a default UI for filtering
function DefaultColumnFilter(props) {
// set this if you want a default filter. I am setting to none
  //return <div />

  const {column: { filterValue, preFilteredRows, setFilter, hideFilter }  } = props
  const count = preFilteredRows.length
  if (!hideFilter) {
  return (
    <input
      style={{ minWidth: 200 }}
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )}
  else return <div />
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-Select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setSearchString
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const [first, setFirst] = React.useState(true)
  const pathname = window.location.pathname.replace('/', '')

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value);
    setSearchString(value)

    localStorage.setItem(`${pathname}SearchString`, value)
  }, 200)

  if (!value && first) {
    const search = localStorage.getItem(`${pathname}SearchString`)
    if (search) {
      setValue(search)
      onChange(search)
    }
    setFirst(false)
  }

return (
  <input
    value={value}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search ${count} records...`}
        style={{
          width: 500,
          minWidth: 200
        }}
      />
  )
}

let Table = ({ data, columns, onRowPress, defaultPageSize, defaultSorted }) => {

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = useMemo(
    (props) => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        prepareRow,
        allColumns,
        getToggleHideAllColumnsProps,
        visibleColumns,
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
    
        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        exportData,
        state: { pageIndex, pageSize },
      } = useTable(
        {
          initialState: { 
            pageSize: defaultPageSize, 
            sortBy: defaultSorted,
          },
          columns,
          data,
          defaultColumn, // Be sure to pass the defaultColumn option if you need one
          filterTypes,
          getExportFileBlob
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination,
        useExportData
      )

  const [initialPageCount, setInitialPageCount] = useState(pageCount)
  const [showFilters, setShowFilters] = useState(false)
  const [showColumns, setShowColumns] = useState(false)
  const [searchString, setSearchString] = useState(localStorage.getItem('initialSearchString'))

      return (
        <>
            <div key="toggle" style={{ display: showColumns ? 'flex' : 'none',  flexDirection: 'row', alignItems:"center" }}>
              <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> 
              <span style={{ paddingLeft: 7 }}>TOGGLE ALL</span>
            </div>
            <div style={{display: showColumns ? 'flex' : 'none', justifyContent:"left"}}>
            {visibleColumns.map(column => {
              return (
                <div key={column.id} style={{ display: 'flex', flexGrow: 2, alignItems:"center" }}>
                  <input type="checkbox" {...column.getToggleHiddenProps()} />
                  <span style={{ paddingLeft: 7 }}>{column.label ? column.label.toUpperCase() : column.id}</span>
                </div>
              )}
            )}
          </div>
          <table {...getTableProps()} className="zui-table zui-table-rounded">
            <thead>
            <tr> 
              <>
              <th colSpan={visibleColumns.length} style={{ textAlign: 'left' }} >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                  setSearchString={setSearchString}
                />
              </th>
              <th style={{  position: 'absolute', right:30 }}> 
                <div style={{ display: 'flex', flexGrow: 2,  alignItems:"center" }}>
                  <div style={{ padding: 3, cursor:"pointer" }} onClick={() => setShowFilters(!showFilters)} > <i className="fa fa-filter fa-lg" />  </div>
                  <div style={{ padding: 3, cursor:"pointer" }} onClick={() => setShowColumns(!showColumns)} > <i className="fa fa-columns fa-lg" /></div> 
                      {/* <div style={{ padding: 3, cursor: "pointer" }} onClick={() => exportData("xlsx", false)} > <i className="fa fa-lg fa-file-excel-o" /></div> */}
                      <div style={{ padding: 3, cursor: "pointer" }} onClick={() => exportData("csv", false)} > <i className="fas fa-lg fa-file-csv" /></div> 
                </div>              
              </th>
              </>
          </tr>
              {headerGroups.map(headerGroup =>  (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => {
                //console.log('graphqltable column props are ', column)
                return (
                    <>
                    <th style={{width:3}}>{column.isSorted ? <i className={`fas fa-sort-${column.isSortedDesc ? 'down' : 'up'}`} /> : ""} </th>
                    <th style={{width:`${column.width}`}} {...column.getHeaderProps(column.getSortByToggleProps())}>
                     <span style={{textDecoration:column.isSorted ? 'underline' : 'none' }}>{column.render('Header')}</span>
                     <div>{column.canFilter && 1 === 2 ? column.render('Filter') : null}</div>
                    </th>
                    </>
                  )})}
                </tr>
              ))}

                { showFilters && headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <>
                    <th style={{width:3}}></th>
                    <th {...column.getHeaderProps()}>
                      {initialPageCount > 1 && <div> {column.canFilter ? column.render('Filter') : null}</div>}
                    </th>
                    
                    </>

                  ))}
                </tr>
              ))}
            
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row) 
                return  (
                  <tr {...row.getRowProps({ onClick: (e) => onRowPress(row.original, searchString, e, i) })}>
    
                    {row.cells.map(cell => {
                      return <>
                       <td></td><td {...cell.getCellProps({className: cell.column.className})}>{cell.render('Cell')}</td>
                      </>
                    })}
                  </tr>
                )
              })}
            </tbody>
            <tfoot>
            {footerGroups.map(group => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map(column => (
                  <><td></td><td {...column.getFooterProps({className: column.className})}>{column.render('Footer')}</td></>
                ))}
              </tr>
            ))}
          </tfoot>
          </table>
          {/*
            Pagination can be built however you'd like.
            This is just a very basic UI implementation:
          */}
          {initialPageCount > 1 && <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", paddingTop: 3 }} >
            <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </Button>&nbsp;
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </Button>&nbsp;
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </Button>&nbsp;
            <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </Button>&nbsp;
            <span>
              &nbsp;Page&nbsp;
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>&nbsp;
            </span>
            <span >
              &nbsp;| Go to page:&nbsp;
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                style={{ width: '50px' }}
              />
            </span>&nbsp;
            <select
              tyle={{ width: 75, height: "24px" }}
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>}
        </>
      )

}


const columnsFromConfig = (config) =>
  
  Object.keys(config.fields).map((field) => {
      const fieldConfig = config.fields[field];
      const {
        className,
        label,
        width,
        Cell,
        Header = () => !fieldConfig.noHeading && <span style={{ width: '100%', textAlign: fieldConfig.align || 'left', fontWeight: 'bold' }}>{label}</span>,
        style,
        accessor,
        columns,
        SubComponent,
        aggregate,
        Aggregated,
        align,
        filterMethod,
        filtering,
        Filter,
        filter,
        sortMethod,
        filterType,
        hideFilter
      } = fieldConfig;
      const colConfig = {
        id: field,
        Header: props => (
          <div
            style={{
              display: 'flex',
              flexGrow: 1
            }}
          >
            <Header {...props} />
          </div>
        ), // Custom header components!
        style: {textAlign: fieldConfig.align || 'left', ...style},
        filterType
      };
      if (width) {
        colConfig.width = width;
      }
    if (className) {
      colConfig.className = className;
    }
      if (label) {
        colConfig.label = label;
      }
      if (Cell && !aggregate) {
        colConfig.Cell = Cell;
      }
      if (SubComponent) {
        colConfig.SubComponent = SubComponent;
      }
      if (columns) {
        colConfig.columns = columnsFromConfig(columns);
      } else {
        colConfig.accessor = accessor || field;
      }
      if (filtering !== undefined) {
        colConfig.filtering = filtering;
      }
      if (filterMethod) {
        colConfig.filterMethod = filterMethod;
      }
      if (Filter) {
        colConfig.Filter = Filter
      }
      if (filter) {
        colConfig.filter = filter
      }
      if (hideFilter) {
        colConfig.hideFilter = hideFilter
      }
      if (sortMethod) {
        colConfig.sortMethod = sortMethod
      }
      if (align) {
        colConfig.className = align
      }
      if (aggregate) {
        colConfig.aggregate = aggregate;
        if (Aggregated) {
          colConfig.Aggregated = Aggregated;
        } else if (Cell) {
          colConfig.Aggregated = Cell;
        }
      }
      return colConfig;
    });


const getColumnValues = (data, columns) => {
  const colValues = {}
  data.forEach((row) => {
    columns.forEach((column) => {
      if (!colValues[column.id]) {
        colValues[column.id] = new Set()
      }
      let value
      if (column.accessor instanceof Function) {
        value = column.accessor(row)
      } else {
        value = getField(row, column.accessor)
      }
      colValues[column.id].add(value)
    })
  })
  Object.keys(colValues).forEach((id) => {
    colValues[id] = Array.from(colValues[id])
  })
  return colValues
}


const addColumnFilters = (columns) => {
  return columns.map((column) => {

    const filterType = column.filterType || column.filter

    if (!filterType) return column
    
    else if (['dropdown', 'select'].indexOf(filterType.toLowerCase()) > -1) {
        return {
          ...column,
          Filter: SelectColumnFilter,
          filter:'Select'
      }
    }
    else if (filterType === 'equals') {
      return {
        ...column,
        Filter: SliderColumnFilter,
        filter:'equals'
      }
    }
    else if (filterType === 'progress') {
      return {
        ...column,
        Filter: SliderColumnFilter,
        filter:filterGreaterThan
      }
    }
    else if (filterType === 'daterange') {
      return {
        ...column,
        Filter: DateColumnFilter,
        filter:'between'
      }
    }

    else if (filterType === 'between') {
      return {
        ...column,
        Filter: NumberRangeColumnFilter,
        filter:'between'
        }
    }

    else return {
      ...column,
      filter:'fuzzyText'
      }
  })
}


let TheTable = (props) => {

  const { data: { loading, error, ...data }, config, onRowPress } = props

  //comment: Change the options and the query to use Fred's filter

  if (loading) return <ActivityIndicator />

  if (error) return `A table error occurred ${error}`

  console.log('GraphQLTable data ', data)

  if (data) {
    let tableData = config.dataset(data);
    if (!tableData) {
      return 'Please reload'
    }

    let columns = columnsFromConfig(config);
    
    const underfinedSortField = columns[0].id !== 'photo' ? `${columns[0].id}`  : `${columns[1].id}` 
    const undefinedSort = [{id: `${underfinedSortField}`}] 

    const { defaultSorted = undefinedSort } = config;

    columns = useMemo( () => columns )
    //const colUniqueValues = getColumnValues(tableData, columns)
    columns = addColumnFilters(columns)
    
    return <Table key={uuidv4()} data={tableData} columns={columns} onRowPress={onRowPress} defaultPageSize={20} defaultSorted={defaultSorted} />
  }
}

const GraphQLTable = (props) => {

  const { config, options = {}, filter = {} } = props;


  const { query } = config;

  const MyTable = graphql(query, options, filter)(TheTable);

  return <MyTable {...props} />;
};

export default GraphQLTable;
//"react-table": "^7",