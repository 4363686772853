import Numeral from 'numeral'
import 'numeral/locales/en-za';
import moment from 'moment';

// TODO: Perhaps call this formatters?
const printCurrency = amount => amount || amount === 0 ? Numeral(Math.round(amount * 100) /100).format('$ ###,###0.00') : '-'
const printDate = date => date ? moment(date).format('DD-MM-YYYY') : ' '

export {
  printCurrency,
  printDate
}
