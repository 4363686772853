import gql from 'graphql-tag'
import pricing from '../data/pricing.json'
import { getAgeFromID } from './validations'

const GET_QUOTE = gql`
query GetQuote($idNumber: String, $age: Int!, $ageSpouse: Int, $gender: Gender!, $spouse: Boolean, $children: Boolean, $coverAmount: Int!, $parents: [ParentQuoteInput], $extended: [ParentQuoteInput], $income: Float ){
  getPolicyQuote(idNumber: $idNumber, age: $age, ageSpouse: $ageSpouse, gender: $gender,  spouse: $spouse, children: $children, coverAmount: $coverAmount, parents: $parents, extended: $extended, income: $income) {
    premium
    totalCover
  }
}
`

const GET_TOTAL_COVER = gql`
query GetTotalCover($idNumber: String!, $funeralId: String) {
  getTotalCover(idNumber: $idNumber, funeralId: $funeralId) 
}
`

const calculateAnnualPrice = ({ ageSpouse, age, spouse = 'N', children, gender, coverAmount = 10000, paymentPeriod = 10, parents, extended }) => {
  //console.log('calculateAnnualPrice ', ageSpouse, age, spouse, children, gender, coverAmount, paymentPeriod, parents, extended)
  let usePrices
  let useAge

  if (spouse == "Y") {
    useAge = age
    if (useAge < ageSpouse) { useAge = ageSpouse }
    usePrices = pricing['COUPLE']
  } else {
    useAge = age
    if (children == "Y") {
      usePrices = pricing['SINGLE_WITH_CHILDREN'][gender]
    } else {
      usePrices = pricing['SINGLE'][gender]
    }
  }

  const coverPrice = usePrices[paymentPeriod][useAge].rate * 12
  let totalPricePerYear = coverPrice * (coverAmount / 1000) + 180

  if (parents) {
    const parentPrices = pricing['PARENTS']
    Object.keys(parents).forEach(parentType => {
      const parentGender = parentType[0] === 'MALE' ? 'FEMALE' : 'MALE'
      const parentAge = parents[parentType].age
      const parentPrice = parentPrices[parentGender][paymentPeriod][parentAge].rate * 12 * (coverAmount / 2000)
      totalPricePerYear += parentPrice
    })
  }

  // same for extended

  return totalPricePerYear
}


//getPolicyQuote(idNumber: $idNumber, age: $age, ageSpouse: $ageSpouse, gender: $gender, smoker: $smoker, spouseSmoker: $spouseSmoker, spouse: $spouse, children: $children, coverAmount: $coverAmount, parents: $parents, extended: $extended, income: $income) {


const getGender = (p) => {
  if (p.relationship && (p.relationship.toLowerCase().includes('mother') || p.relationship.toLowerCase().includes('sister') || p.relationship.toLowerCase().includes('aunt'))) {
    return 'FEMALE'
  }
  else return 'MALE'
}

const parentData = (data) => {

  if (data) {
    let parentData = data.map(p => ({ gender: p.gender || getGender(p), age: p.age }))
    return parentData
  }
  else return {}
}

const extendedData = (data) => {
  if (data) {
    let extendedData = data.map(p => ({ gender: p.gender || getGender(p), age: p.age }))
    return extendedData
  }
  else return {}
}

const getQuoteVariables = (funeral, livesCovered, coverAmount) => {
  // console.log(' getQuoteAmount getQuoteVariables in CalculatePrice ', livesCovered)

  // query GetQuote($idNumber: String, $age: Int!, $ageSpouse: Int, $gender: Gender!, $spouse: Boolean, $children: Boolean, $coverAmount: Int!, $parents: [ParentQuoteInput], $extended: [ParentQuoteInput], $income: Float){
  const policyHolder = livesCovered.filter(m => m.memberType === 'POLICY_HOLDER')[0]
  const idNumber = policyHolder.idNumber
  const age = policyHolder.age
  const gender = policyHolder.gender
  const income = policyHolder.income || funeral.client.income || policyHolder.estimatedIncome || funeral.client.estimatedIncome
  const spouse = livesCovered.filter(m => m.memberType === 'SPOUSE')[0]
  const ageSpouse = spouse ? spouse.age ? spouse.age : spouse.idNumber && spouse.idType === 'rsa_id' ? getAgeFromID(spouse.idNumber) : undefined : undefined
  const children = livesCovered.filter(m => m.memberType === 'CHILD').length > 0 ? true : false
  const parents = livesCovered.filter(m => m.memberType === 'PARENT')
  const extended = livesCovered.filter(m => m.memberType === 'EXTENDED')

  return ({
    idNumber,
    age,
    ageSpouse,
    gender,
    spouse: spouse ? true : false,
    children,
    coverAmount: coverAmount || funeral.coverAmount,
    income,
    parents: parentData(parents),
    extended: extendedData(extended),
  })
}

const getQuoteAmount = (client, funeral, livesCovered, coverAmount, callback) => {

  if (funeral) { //data not always loaded
    const quoteData = getQuoteVariables(funeral, livesCovered, coverAmount)

    client.query({
      query: GET_QUOTE,
      variables: quoteData
    }).then(d => {
      callback(d.data.getPolicyQuote.premium)
    })
  }
}

const calculatePrice = (props) => {
  //console.log('calculatePrice props', props)
  return calculateAnnualPrice(props) / 12
}

export {
  calculatePrice,
  getQuoteAmount
}