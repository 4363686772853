
import React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

const colorPalette = {
  lightgreen: "#78e193", // FROM PALETTE
  green: "#55A168",
  darkgreen: "#229490", // FROM PALETTE
  yellow: "#ffbb42", // FROM PALETTE
  grey: "#3e4254", // FROM PALETTE
  pink: "#e22660", // FROM PALETTE
  purple: "#120ABF",
  red: "#B31F12",
  darkred: "#8C190E",
  orange: "#FF6940",
  brown: "#803C16",
  lightblue: "#1590B3",
  darkblue: "#0056B3",
  cyan: "#0ABFBD",
  lightgrey: "#c8c8c8"
}

const themeColors = {
  primary: "#046D80",
  buckets: {
    EMERGENCY: colorPalette.red,
    YEAR_END: colorPalette.yellow,
    EDUCATION: colorPalette.darkblue,
    HOLIDAY: colorPalette.purple,
    CAR: colorPalette.cyan,
    HOUSE: colorPalette.lightgreen,
    RETIREMENT: colorPalette.grey,
    HEALTH: colorPalette.darkred,
    FUNERAL: colorPalette.brown
  },
  status: {
    good: colorPalette.green,
    average: colorPalette.orange,
    bad: colorPalette.red,
    unknown: colorPalette.lightgrey
  }
}

const theme = createMuiTheme({
  fontFamily: `Lato, sans-serif`,
  fontFamilyBold: `Lato, sans-serif`,
  // fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif;`,
  // fontFamilyBold: `"Roboto", "Helvetica", "Arial", sans-serif;`,
  colors: {
    primary: "#229490",
    text: "#404357",
    white: "#FFFFFF",
    dark: "#404357",
    grey: "#F9F8F8", // Light Grey
    grey2a: "#ACB0B4", // Grey 2
    grey2: "#4F4F4F",
    grey3: "#828282",
    grey4: "#BDBDBD",
    grey5: "#E0E0E0",
    grey6: "#F2F2F2",
    lightgrey: "#ECE8E8",
    yellow: "#EFDCB7", // Light Sand
    secondary: "#EBD18A",
    darkyellow: "#CBAF88",
    red: "#EE8983", // Peach
    alertAmber: "#FF8E3C",
    alertRed: "#EB5757",
    alertRedBackground: "#EB57571F",
    purple: "#331261",
    alertYellow: "#FFDF2E",
    lightGreen: "#E9F4F4", // Light Green
    topBarColor: "#10262E",
    sideMenuColor: "rgba(0, 0, 0, 0.5)",
    padding: 7,
    marginTop: 14,
    background: "#267E7F",
    marginBottom: 7,
    margin: 7,
    primaryHover: "#046D80",
    actionGradientFrom: "#267E7F",
    actionGradientTo: "#04899F",
    dangerGradientFrom: "#ff1a00",
    dangerGradientTo: "#ff1a00",
    fontFamily: "Lato sans-serif",
    fontSize: window.innerWidth > 1000 ? 14 : 12,
    titleSize: window.innerWidth > 1000 ? 16 : 14,
    color: "#323232",
    colors: themeColors,
    loadFontUrl: "./assets/fonts/Lato sans-serif.ttf"
  }
})

// export const GlobalStyle = () => (
//   <Global styles={css`
//     body {
//       font-family: ${theme.fontFamily};
//     }

//     .MuiDialogTitle-root {
//      flex: 0 0 auto;
//       margin: 0;
//       padding: 7px 24px;
//     }

//     .MuiTextField-root {
//       width: 100% !important;
//       max-width: 400px;
//       min-width: 150px !important;
//     }

//     .MuiMenuItem-root {
//       font-family: ${theme.fontFamily} !important;
//       font-size: 14px !important; 
//       min-height: 40px !important;
//       padding-left: 16px !important;
//       padding-right: 16px !important;

//       ${device.tablet`
//         font-size: 12px !important; 
//         min-height: 32px !important;
//         padding-left: 12px !important;
//         padding-right: 12px !important;
//       `}
//     }
//   `} />
// )

const deviceSizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tabletS: '600px',
  tablet: '820px',
  laptop: '1024px',
  laptopL: '1440px',
  desktopS: '1600px',
  desktop: '2560px'
}

export const device = Object.keys(deviceSizes).reduce((acc, key) => {
  acc[key] = style => `
    @media (max-width: ${deviceSizes[key]}) {
      ${style};
    }
  `
  return acc
}, {})

export default theme