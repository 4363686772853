import styled from '@emotion/styled'

const ImageGridSelectItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 26px;
  padding-bottom: 18px;

  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  box-shadow: ${props => props.selected ? "0px 0px 10px rgba(34, 148, 144, 0.15)" : "inset 0px 0px 10px rgba(64, 67, 87, 0.08)"};

  margin: 4px;

  width: 160px;

  img {
    max-height: 65px;
    margin: 0;
    margin-bottom: 8px;
  }
`

export {
  ImageGridSelectItemContainer
}
