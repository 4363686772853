import React from 'react'
import { withApollo, graphql, useQuery } from 'react-apollo'
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { GraphQLFormProvider } from 'react-form-helper'
import { Card, ActivityIndicator, FormModal, renderInput, renderDisplay, BuildLayout } from '../generic'
import AGENT_TYPES from '../../variables/agentTypes'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '80%',
    marginLeft: '10%',
    paddingRight: '10%'
  }
}));

const EMPLOYEES = gql`
	query Employees {
		employees {
			id
			first
			last
      tenants
      companyId
			salesAgentInfo {
        agentType
        enabled
        isSuperAgent
      }
		}
    companys {
      name
      id
    }
	}
`;


const EditModal = (props) => {
  const { size, width } = props
  return (
    <FormModal
      okText="Ok"
      cancelText="Back"
      width={width}
      disableCancel={true}
      disableOk={true}
      size={size}
      componentProps={props}
    />
  )
}

const LANGUAGES = ["English", "Afrikaans", "Xhosa", "Zulu", "Southern Sotho", "Northern Sotho", "Pedi", "Tsonga", "Vendi", "Swati", "Ndebele", "Shona"]

const fields = (MANAGERS, COMPANIES) => {
  return ({
    photoId: {
      label: 'Upload a Photo id',
      type: 'fileupload',
    },
    employeeNumber: {
      type: 'text',
      label: 'Employee Number *',
      validate: (value) => {
        if (!value) {
          return 'Employee Number is Required'
        }
        if (value.length > 10) {
          return 'Length must be less than 10'
        }
      },
      noAdd: true
    },
    first: {
      type: 'name',
      label: 'First Name *',
      validate: (value) => {
        if (!value) {
          return 'First name is Required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    },
    last: {
      type: 'name',
      label: 'Last Name *',
      validate: (value) => {
        if (!value) {
          return 'Last name is required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    },
    mobileNumber: {
      type: 'text',
      label: 'Mobile Number *',
      validate: (value) => {
        if (!value) {
          return 'Mobile is Required'
        }
        if (value.length !== 10) {
          return 'Phone numbers must be 10 long'
        }
      }
    },
    email: {
      type: 'email',
      label: 'Email *',
      Cell: row => { console.log('email row is ', row) },
      validate: (value) => {
        if (!value) {
          return 'Email is Required'
        }
        else return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? undefined : 'Invalid email address'
      },
    },
    'companyId': {
      type: 'select',
      editable: true,
      autocomplete: true,
      fullWidth: true,
      label: 'Company',
      options: COMPANIES
    },
    'tenants': {
      freeSolo: true,
      autocomplete: true,
      type: 'multiselect',
      multiple: true,
      options: [], // This laster needs to be updated tenants options
      help: 'Select all Tenants',
      blankString: 'None',
      label: 'Tenants',
      editable: true,
      fullWidth: true,
    },
    'salesAgentInfo.agentType': {
      type: 'select',
      editable: true,
      autocomplete: true,
      fullWidth: true,
      label: 'Agent Type',
      options: AGENT_TYPES
    },
    'manager.id': {
      type: 'select',
      editable: true,
      autocomplete: true,
      fullWidth: true,
      label: 'Team Leader',
      options: MANAGERS,
    },
    'salesAgentInfo.sellingLanguages': {
      freeSolo: true,
      autocomplete: true,
      type: 'multiselect',
      multiple: true,
      options: LANGUAGES.map(l => ({ 'value': l, 'label': l })),
      help: 'Select all languages',
      blankString: 'None',
      label: 'Languages',
      editable: true,
      fullWidth: true,
    },
    'salesAgentInfo.enabled': {
      type: 'switch',
      label: 'Enabled',
    },
    'salesAgentInfo.isSuperAgent': {
      type: 'switch',
      label: 'Super Agent',
    },
    'terminatedDate': {
      type: 'date',
      label: 'Terminated Date',
    },
  })
}

function FormLayout({ mode, theFields, history, formName, client }) {
  const classes = useStyles();
  const fieldNames = Object.keys(theFields)

  return (
    <Card>
      <br />
      <h1>{mode === "edit" && "Edit"} Employee</h1>
      <div className={classes.root}>
        <BuildLayout formName={formName} fieldNames={fieldNames} mode={mode} fieldConfig={theFields} direction='column' justify='center' alignItems='center' spacing={2} cancel={() => history.push('/employees')} />
      </div>
    </Card>
  );
}

function Employee({ id, history, client, data }) {

  if (data.loading) {
    return <ActivityIndicator />
  }

  const MANAGERS = data.employees.filter(m => m.salesAgentInfo && m.salesAgentInfo.agentType && m.salesAgentInfo.agentType === "TEAM_LEADER" && m.id !== id).map(({ id, first, last }) => ({ 'value': id, 'label': `${first} ${last}` }))
  const COMPANIES =  data.companys.map(l => ({ 'value': l.id, 'label': l.name })).sort((a, b) => a.label.localeCompare(b.label))
  const theFields = fields(MANAGERS, COMPANIES)

  return (
    <div>
      <GraphQLFormProvider
        mode={id ? "edit" : "add"}
        id={id}
        name={'employee'}
        keepInitialValues
        fields={theFields}
        afterSubmit={() => { history.push('/employees') }}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout {...props} formName={'employee'} history={history} client={client} theFields={theFields} />}
        DisplayFormLayout={FormLayout}
        mapInputVariables={input => {
          const { users, first, last, mobileNumber, email, photoId, salesAgentInfo, manager, terminatedDate, companyId, ...data } = input
          console.log('mapInputVariables ', input)
          const managerId = manager && manager.id ? manager.id : undefined
          return ({ input: { first, last, mobileNumber, email, photoId, salesAgentInfo, managerId, terminatedDate, companyId
          } })
        }}
        query={gql` query EmployeeData($id: String!) {
          employee(id: $id) {
          id
          employeeNumber
          first
          last
          type
          mobileNumber
          email
          jobTitle
          terminatedDate
          tenants
          companyId
          manager {
            id
            email
            first
            last
          }
          salesAgentInfo {
            simplicityId
            simplicityConsultantName
            agentType
            sellingProducts
            sellingLanguages
            enabled
            isSuperAgent
          }
          photoId
          photo {
            id
            url
          }
        }
      }
    `}
        updateMutation={gql`
    mutation updateEmployee($id: String!, $input: UpdateEmployeeInput!) {
      updateEmployee( id:$id, input: $input ) {
        id
        first
        last
        type
        tenants
        companyId
        terminatedDate
        mobileNumber
        email
        manager {
          id
          email
          first
          last
        }
        photoId
        salesAgentInfo {
          simplicityId
          simplicityConsultantName
          agentType
          sellingProducts
          sellingLanguages
          enabled
          isSuperAgent
          }
        }
      }
    `}

        addMutation={gql`
    mutation registerEmployee($input: RegisterEmployee!) {
      registerEmployee(input: $input ) {
        id
        first
        last
        type
        tenants
        companyId
        mobileNumber
        terminatedDate
        email
        photoId
        salesAgentInfo {
          simplicityId
          simplicityConsultantName
          agentType
          sellingProducts
          sellingLanguages
          enabled
          isSuperAgent
          }
        }
      }
    `}

      />
    </div>)
}
Employee = withApollo(Employee)
export default graphql(EMPLOYEES)(Employee);