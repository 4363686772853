import React from 'react'
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core/styles';
import GraphQLTable from '../../generic/GraphQLTable';
import moment from "moment";
import { Card, Button, Grid } from '../../generic'
import { AuthConsumer } from 'react-auth-helper';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        justifyContent: 'flex-start',
        paddingBottom: 5
    },
}))

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    let cap = s.toLowerCase()
    return cap.charAt(0).toUpperCase() + cap.slice(1)
  }

const GET_USERS = gql`query USERS {
    users {
      id
      email
    }
  }`;

const config = {
    query: gql`
    { communicationtemplates(limit:1000) {
        id
        name
        friendlyName
        description
        type
        product
        templateType
        template
        paramKeys
        isAdmin
        hasFreeReply
        updatedBy {
            description
        }
        updatedAt
      }
    }`,
    dataset: data => data.communicationtemplates,
    fields: {
        friendlyName:{ 
            label: 'Name',
            accessor: row => (row && row.friendlyName ? capitalize(row.friendlyName) : capitalize(row.name))
            },
        name:{ label: 'Developer Name'},
        product: {
            label: 'Products'
        },
        description: { label: 'Description'},
        templateType: { label: 'Type'},
        type: { label: 'Message Type' },
        length: { accessor: row => row.type === 'SMS' ? row.template.length : ' ', label: 'Length' },
        isAdmin: { label: 'Admin Only', accessor: row => row.type === 'SMS' ? row.isAdmin ? "YES" : "NO" : '' },
        hasFreeReply: { label: 'Free Reply', accessor: row => row.type === 'SMS' ? row.hasFreeReply ? "YES" : "NO" : '' },
        "updatedBy.description":{label:'UpdatedBy'},
        updatedAt: { label: 'Last Update', Cell: row => row && row.value ? moment(row.value).format('DD-MM-YYYY') : '-' },
    }, defaultSorted: [{ id: 'friendlyName'}]
};

let CommunicationTemplateList = ({ history }) => {
    const classes = useStyles();

    return (
        <AuthConsumer>
            {({ inGroup }) => (
                <Card>
                    <div style={{ position: 'absolute', paddingTop: 5, left: '48em' }} >
                        <Button color="primary" size="small" onClick={() => history.push('/communication-templates/ejs/new')}>NEW TEMPLATE</Button>
                        <Button color="primary" size="small" style={{ marginLeft: 14 }} onClick={() => history.push('/communication-templates/sms/new')}>NEW SMS</Button>
                    </div>


                <GraphQLTable
                        config={config}
                        searchProduct={'template'}
                        onRowPress={(communicationtemplate) => {
                            communicationtemplate.templateType === "STRING" ? history.push(`/communication-templates/sms/${communicationtemplate.id}`)
                                : history.push(`/communication-templates/ejs/${communicationtemplate.id}`)
                        }}
                    />
                </Card>
            )}
        </AuthConsumer>
    )
};

export default CommunicationTemplateList;
