import moment from 'moment'

const processSalesInteractions = salesInteractions => {
  const categoryPoints = {}
  const salesPeople = { '-1': { first: 'Unknown', last: 'Sales Person' } }
  const startTimePerDay = {}
  const endTimePerDay = {}
  const salesPerHour = {}
  const premiumsPerHour = {}
  const salesPerDay = {}
  const premiumsPerDay = {}
  let totalSales = 0
  let totalHours = 0
  let totalInteractions = 0
  let totalPremiums = 0
  let lastTimestamp = 0
  let lastLocation

  salesInteractions.forEach(salesInteraction => {
    if (!categoryPoints[salesInteraction.result]) {
      categoryPoints[salesInteraction.result] = []
    }
    categoryPoints[salesInteraction.result].push(salesInteraction)
    if (salesInteraction.salesPerson && !salesPeople[salesInteraction.salesPerson.id]) {
      salesPeople[salesInteraction.salesPerson.id] = salesInteraction.salesPerson
    }
    const hour = moment(salesInteraction.timestamp).format('DD-MM-YYYY HH:00')
    const date = moment(salesInteraction.timestamp).format('DD-MM-YYYY')
    if (salesInteraction.result === 'SIGNEDUP') {
      if (!salesPerDay[date]) {
        salesPerDay[date] = 0
        premiumsPerDay[date] = 0
      }
      if (!salesPerHour[hour]) {
        salesPerHour[hour] = 0
        premiumsPerHour[hour] = 0
      }

      totalSales += 1
      salesPerHour[hour] += 1
      salesPerDay[date] += 1

      if (salesInteraction.funeral) {
        const amount = salesInteraction.funeral.currentPremiumTotal || 0
        totalPremiums += amount
        premiumsPerHour[hour] += amount
        premiumsPerDay[date] += amount
      }
    }
    if (!startTimePerDay[date] || startTimePerDay[date] > salesInteraction.timestamp) {
      startTimePerDay[date] = salesInteraction.timestamp
    }
    if (!endTimePerDay[date] || endTimePerDay[date] < salesInteraction.timestamp) {
      endTimePerDay[date] = salesInteraction.timestamp
    }
    if (salesInteraction.salesActivities && salesInteraction.salesActivities.length > 0) {
      /*const { begin, end } = startEndActivity(salesInteraction.salesActivities);
      const beginDate = moment(begin).format('DD-MM-YYYY');
      const endDate = moment(end).format('DD-MM-YYYY');
      if (beginDate === endDate) {
        if (!startTimePerDay[beginDate] || startTimePerDay[beginDate] > begin) {
          startTimePerDay[beginDate] = begin;
        }
        if (!endTimePerDay[endDate] || endTimePerDay[endDate] < end) {
          endTimePerDay[endDate] = end;
        }
      }*/
    }
    if (lastTimestamp < salesInteraction.timestamp) {
      lastTimestamp = salesInteraction.timestamp
      lastLocation = salesInteraction.location
    }

    totalInteractions += 1
  })

  Object.keys(startTimePerDay).forEach(day => {
    if (endTimePerDay[day]) {
      totalHours += (endTimePerDay[day] - startTimePerDay[day]) / 1000 / 3600
    }
  })

  return {
    categoryPoints,
    salesPeople,
    totalSales,
    totalHours,
    totalInteractions,
    totalPremiums,
    lastTimestamp,
    lastLocation,
    salesPerHour,
    salesPerDay,
    premiumsPerDay
  }
}

export {
  processSalesInteractions
}
