import React, { Component, useState, useEffect } from "react";
import { useQuery, Query, Subscription } from 'react-apollo';
import gql from 'graphql-tag';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import { Switch, Grid, Card } from '../generic'
// import { format } from 'date-fns'
import WhatsAppChat from './WhatsAppChat'
const images = require('../../assets/images')
import { R4_WHATSAPP_NUMBER } from '../../config'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingRight: 10,
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    "& .MuiItem-root": {
      padding: "0!important",
      paddingLeft: "7px!important",
    },
    "& .MuiListItem-root": {
      padding: "0!important",
      paddingLeft: "7px!important",
    },
  },
  list: {
    minHeight: '80vh',
    width: '100%',
    // maxWidth: 360,
    overflow: 'auto',
    border: '1px solid #D1DBE3!important',
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    width: '100%',
    marginTop: 10,
    border: '1px solid #D1DBE3',
    backgroundColor: '#F6FBFF',
  },
  needsReply: {
    "& .MuiTypography-root": {
      fontWeight: '600!important',
    }
  },
  bold: {
    "& .MuiTypography-root": {
      fontWeight: '600!important',
    }
  },
  active: {
    backgroundColor: '#C6E3FA!important'
  },
  read: {
    backgroundColor: theme.palette.background.paper,
    fontWeight: '400!important',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    padding: 3
  },
}));


const COMMUNICATIONS = gql`
  query Communications($filter: CommunicationFilter!) {
   communications(filter: $filter, limit: 1000) {
    id
    client {
      id
      firstNames
      surname
      idNumber
      cellNumbers
    }
    type
    to
    from
    name
    replyToId
    message
    createdAt
    statusReason
    updatedAt
    deliveredAt
    attachments {
      fileName
      fileId
      file {
        id
        url
        contentType
      }
    }
  }
}`;

//"react-apollo": "^1.4.15",
const NEW_COMMUNICATION = gql`
  subscription {
  communicationCreated {
    id
    client {
      id
      firstNames
      surname
      idNumber
      cellNumbers
    }
    type
    to
    from
    name
    replyToId
    message
    createdAt
    statusReason
    updatedAt
    deliveredAt
    attachments {
      fileName
      fileId
      file {
        id
        url
        contentType
      }
    }
  }
}`;

const WHATSAPP_CLOUD_NUMBER = "+27606337528" 

let WhatsAppChats = ({ communications }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState({})
  const [from, setFrom] = useState('')
  const [unreadOnly, setUnreadOnly] = useState(true)
  const [allChats, setAllChats] = useState([])
  const [chats, setChats] = useState([])

//   useEffect(() => {
//     setAllChats(communications && communications.length > 0 ? [...new Map(communications.filter(c => c.type === 'WHATSAPP' && c.from )
//     .sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1)
//     // .map(c => c.from && c.from.startsWith('+0') ? { ...c, from: c.from.replace('+0', '+27') } : c)
//      .map((item) => [ item.from && (item.from.includes('meerkat') || item.from.includes(R4_WHATSAPP_NUMBER) || item.from.includes(WHATSAPP_CLOUD_NUMBER)) ? item["to"] : item["from"], item]))
//     .values()] : [])
// }, [communications])

useEffect(() => {
  // console.log("Updating all chats")
  setAllChats(communications && communications.length > 0 ? [...new Map(communications.filter(c => c.type === 'WHATSAPP')
    .sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1)
    // .map(c => c.from && c.from.startsWith('+0') ? { ...c, from: c.from.replace('+0', '+27') } : c)
    .filter(c => c.message && c.message.length > 0 && c.from)
    .map(c => ({ ...c, number: c.from.includes('meerkat') || c.from.includes(R4_WHATSAPP_NUMBER) || c.from.includes(WHATSAPP_CLOUD_NUMBER) ? c.to : c.from }))
    .map((item) => [item.number, item])).values()] : [])
}, [communications])

useEffect(() => {
  // console.log("Updating list")
  if (unreadOnly) {
    setChats(allChats.filter(c => unread(communications, c.number) && (c.number != WHATSAPP_CLOUD_NUMBER) && (c.number != R4_WHATSAPP_NUMBER)))
  } else {
    setChats(allChats.filter(c => c.number != WHATSAPP_CLOUD_NUMBER && c.number != R4_WHATSAPP_NUMBER))
  }
}, [allChats, unreadOnly])

  useEffect(() => {
    // setFrom(selected && selected.from ? selected.from.replace(/^(0\.)/, "").replace("+27", "") : '')
    setFrom(selected && selected.number ? selected.number : '')
  }, [selected])

  // console.log('WhatsAppChats', allChats, from)

  const unread = (checkCommunications, from) => {
    const lastReply = checkCommunications.find(c => c.to === from)
    const lastReceipt = checkCommunications.find(c => c.from == from)
    /*if (from === "+27764553979") {
      console.log('last reply', from, moment(lastReply.createdAt).format("YYYY-MM-DD HH:mm"))
      console.log('last receipt', from, moment(lastReceipt.createdAt).format("YYYY-MM-DD HH:mm"))
      console.log((!lastReply || !lastReceipt || lastReply.createdAt < lastReceipt.createdAt))
    }*/
    return (!lastReply || !lastReceipt || lastReply.createdAt < lastReceipt.createdAt)
  }

  return (
    <Card>
      <Grid container className={classes.root}>
        <Grid item xs={3}>
          {/* <List className={classes.top}> */}
          {/* <ListItem key={uuidv4()} > */}
          {/* <ListItemAvatar>
                  <Avatar src={images.mokuAvatar} className={classes.small} />
                </ListItemAvatar> */}
          <Switch value={unreadOnly} onChange={() => setUnreadOnly(!unreadOnly)} label={unreadOnly ? `Unreplied Chats (${chats.length})` : `All chats (${allChats.length})`} />
          {/* </ListItem> */}
          {/* </List> */}
        </Grid>
        <Grid item xs={9}  >
          {selected.number && <List className={classes.top}>
            <ListItem >
              <ListItemAvatar>
                <Avatar>
                  <ImageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${selected.name} ${selected.idNumber} ${selected.id}`} />
            </ListItem>
          </List>}
        </Grid>
        <Grid item xs={3} >
          <List style={{ maxHeight: '300px', overflow: 'auto' }} className={classes.list}>
            {chats && chats.length > 0 && chats.map(c => {
              const noReply = unread(communications, c.number)
              return (
                <ListItem key={c.number}
                  className={noReply ? classes.needsReply : classes.read}
                  onClick={() => setSelected({ ...c, name: c.client ? `${c.client.firstNames} ${c.client.surname}` : '', idNumber: c.client ? c.client.idNumber : '', id: c.client ? c.client.id : '' })} selected={c.id === selected.id} >
                  <ListItemText primary={`${c.client ? `${c.number ? c.number : ''} ${c.client.firstNames} ${c.client.surname}` : `${c.number ? c.number : ''} ${c.name ? c.name : 'No Name Provided'}`} ${noReply ? '*' : ''}`} />
                </ListItem>
              )
            })}
          </List>
        </Grid>
        <Grid item xs={9}>
          {selected.number && <WhatsAppChat clientId={selected.client ? selected.id : undefined} clientInfo={selected.client ? selected.client : undefined} from={selected.number} clientName={selected.client ? selected.client.firstNames : selected.name}
            messages={communications.filter(c => (c.to && c.to.includes(from)) || (c.from && c.from.includes(from)))
              .sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1)} />}
        </Grid>
      </Grid>
    </Card>
  )
}

const ChatAdmin = () => {
  const {
    data,
    loading,
    error,
    subscribeToMore
  } = useQuery(COMMUNICATIONS, { variables: { filter: { type: "WHATSAPP" } } });

  useEffect(() => {
    if (data) {
      const unsubscribe = subscribeToMore({
        document: NEW_COMMUNICATION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newChat = subscriptionData.data.communicationCreated;
          return {
            ...prev,
            communications: [newChat, ...prev.communications],
          };
        },
      });
      return () => unsubscribe();
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>`Error! ${error.message}`</p>;

  return (
    <div>
      <WhatsAppChats communications={data.communications} />
    </div>
  );
};

export default ChatAdmin