import { getField } from 'react-form-helper/dist/utils'
import moment from 'moment'

export const getDOBFromID = (idNumber, idType) => {

  //console.log('getDOBFromID ', idNumber, idType )
  if (!idType) idType = 'rsa_id'

  if (idNumber && idNumber.length === 13 && idType === 'rsa_id') {
    var yy = idNumber.slice(0, 2)
    var dob = `19${idNumber.slice(0, 6)}`
    if (yy < 25) {
      dob = `20${idNumber.slice(0, 6)}`
    }
    var birthday = moment(dob, 'YYYY MM DD')
    //console.log('getDOBFromID birthday', moment(birthday).format('DD-MM-YYYY'))
    return birthday;
  }
  return undefined;
}

export const getAgeFromDOB = (dob) => {
  return moment().diff(dob, 'years');
}

export const getAgeFromID = (idNumber, idType) => {

  console.log('getAgeFromID ', idNumber, idType)

  if (idNumber && idType === 'rsa_id') {
    const DOBFromIdNumber = idNumber.substr(0, 2) < 35 ? moment(`20${idNumber.slice(0, 6)}`, 'YYYY MM DD') : moment(`19${idNumber.slice(0, 6)}`, 'YYYY MM DD');
    return getAgeFromDOB(DOBFromIdNumber);
  }
  return undefined;
};

const getFieldPrefix = name => {
  console.log('getFieldPrefix', name, name && name.split('.').slice(0, -1).join('.'))

  return name && name.split('.').slice(0, -1).join('.')
}

const getIndex = (name) => {
  if (name) {
    var openBracket = name.indexOf('[')
    var closeBracket = name.indexOf(']')
    var index = name.substr(openBracket + 1, (closeBracket - openBracket - 1))
    console.log('the member index is ', index)
    return index
  }
  else return 0
}

export const isRequired = (value) => {
  return (value || value === false ? undefined : 'This field is required')
}


export const isRequiredForForeignID = (value, allValues, props, name) => {
  console.log('isRequiredForForeignID', value, allValues, props, name)
  const { idType, idNumber } = getFieldPrefix(name) ? getField(allValues, getFieldPrefix(name)) : allValues

  if (idType && idType !== "rsa_id") {
    if (!value && idNumber) {
      console.log("isRequiredForForeignID for non-South Africans")
      return "Required for non-South Africans"
    }
  }

  return undefined
}


export const forMemberTypes = (memberTypes, validators) => (value, allFields, props, name) => {
  console.log('forMemberTypes ', name, memberTypes, allFields, validators)
  if (name && allFields.members && allFields.members.length > 0) {
    const index = getIndex(name)
    const memberType = allFields.members[index] && allFields.members[index].memberType
    console.log(allFields.members[index], memberType)
    if (memberTypes.includes(memberType)) {
      return validators instanceof Array ? validators.map(validator => validator(value, allFields, props, name)) : validators(value, allFields, props, name)
    }
  }
  return undefined
}

export const isValidIdNumber = (value, allFields, props, name) => {
  console.log('isValidIdNumber for ', value, allFields, name)

  const { idType } = getFieldPrefix(name) ? getField(allFields, getFieldPrefix(name)) : allFields

  console.log('isValidIdNumber for ', value, idType)

  if (!idType || ["RSA_ID", "SOUTH AFRICAN", "SOUTH AFRICA"].indexOf(idType.toUpperCase()) > -1) {
    return isValidRsaIdNumber(value)
  }

  return undefined
};

// Adapted from the solution here: http://geekswithblogs.net/willemf/archive/2005/10/30/58561.aspx
export const isValidRsaIdNumber = value => {
  if (!value) {
    return undefined;
  }
  if (value.length !== 13) {
    console.log('isValidRSAIdNumber not 13 ', value.length)
    return 'ID Numbers are 13 long'
  }

  if (value == '0000000000000') return 'Invalid ID number';
  if (!/^\d{10}[0-1]\d{2}$/.test(value)) return 'Invalid ID number';
  let n = value;
  let p1 =
    parseInt(n[0], 10) +
    parseInt(n[2], 10) +
    parseInt(n[4], 10) +
    parseInt(n[6], 10) +
    parseInt(n[8], 10) +
    parseInt(n[10], 10);
  let p2 = (
    parseInt(n[1] + n[3] + n[5] + n[7] + n[9] + n[11], 10) * 2
  ).toString();
  let p3 = 0;
  for (let i = 0; i < p2.length; i++) {
    p3 += parseInt(p2[i]);
  }
  let check = 10 - (p1 + p3).toString()[(p1 + p3).toString().length - 1];
  let check_char = check > 9 ? check.toString()[1] : check.toString();
  if (check_char != value[12]) return 'Invalid ID number';
  return undefined;
};

export const isValidCellphoneNumber = value => {
  return !value ||
    value.length === 0 ||
    allowedPrefixes
    .map(prefix => new RegExp('^' + prefix + '.{8}$'))
      .some(rx => rx.test(value))
    ? undefined
    : 'Invalid cell phone number';
};
export const isInteger = value =>
  value && !/^\d+$/.test(value) ? 'Whole numbers only' : undefined;

export const isValidEmail = value => {
  return ( value && value.length > 0 && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? undefined : 'Invalid email address' )
}

//export const isValidAge = value => value && (value >= 18 && value <= 60)

const ageCheck = (value, min, max) => {
  if (value < min || value > max) {
    return `Age must be between ${min} and ${max}`;
  } else {
    return undefined;
  }
};

const allowedPrefixes = [
  '06',
  '07',
  '08',
  '05',
  '04',
  '01',
  '03',
  '02'
];
