import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { printCurrency } from '../../lib/helpers';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    fontSize: 21,
    paddingBottom: 14,
  },
  table: {
    minWidth: 650,
  },
  buttonRow: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingTop: 14,
    paddingBottom: 14,
  }
});

function ClaimLoans({ forObligations, selectRow }) {
  const classes = useStyles();
  // console.log('claim loans', forObligations)

  return (<>
    <br />
    <h2>Loans</h2 >
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="balance table">
        <TableHead>
          <TableRow>
            <TableCell>Creditor</TableCell>
            <TableCell>Reference</TableCell>
            <TableCell>Bank Account</TableCell>
            <TableCell>Payment Reference</TableCell>
            <TableCell>Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {forObligations.map((row) => {
            const { clientCreditor, reference, claimPaymentBankAccount, claimPaymentReference, currentBalance } = row
            const { bankName, accountNo } = claimPaymentBankAccount || {}
            // console.log('claim loans row', row)
            return (
              <TableRow key={reference} onClick={() => selectRow(row)}>
                <TableCell component="th" scope="row">{clientCreditor ? clientCreditor.description : '-'} </TableCell>
                <TableCell>{reference}</TableCell>
                <TableCell>{`${bankName}  ${accountNo}`}</TableCell>
                <TableCell>{<span style={{ textDecoration: "underline" }}>{claimPaymentReference ? claimPaymentReference : "not defined"} </span>}</TableCell>
                <TableCell>{printCurrency(currentBalance)}</TableCell>
              </TableRow>
            )
          }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </>
  );
}

export default ClaimLoans
