import styled from '@emotion/styled'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'

const StyledMenuItem = styled(MenuItem)
const StyledMenuItemText = styled(ListItemText)

export {
  StyledMenuItem,
  StyledMenuItemText
}