import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Reports from './Reports'

const ReportsMenu = () => (
  <div>
    <Switch>
      <Route path='/report' exact render={() => <Reports />} />
    </Switch>
  </div>
);

export default ReportsMenu
