import React from 'react'
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag'
import { GraphQLFormProvider } from 'react-form-helper'
import { BuildLayout, renderInput, renderDisplay } from '../../generic';
import { DOCUMENT_TYPES } from '../../../variables/documentTypes'

const config = {
  name: 'clientdocument',
  fields: {
    name: {
      label: 'Name',
      type: 'text',
      options: [],
    },
    type: {
      label: 'Type',
      type: 'select',
      options: DOCUMENT_TYPES,
      validate: (value, row) => {
        if (!value) {
          return 'Document type is required'
        }
      }
    },
    description: {
      type: 'text',
      label: 'Description',
    },
    verified: {
      type: 'switch',
      label: 'Approved',
      noLabel: true
    }
  },
  query: gql`
    query ClientDocument($id: String!) {
      clientdocument(id: $id) {
        id
        name
        type
        description
        verified
      }
    }`,
  updateMutation: gql`
    mutation UpdateDocument($input: UpdateClientDocumentInput!, $id: String!) {
      updateClientDocument(id: $id, input: $input) {
        id
        name
        type
        description
        verified
        verifiedBy {
          id
          description
        }
      }
  }`
}

const FormLayout = (props) => {

  let { mode, submit, cancel, formName } = props;

  const fieldNames = Object.keys(config.fields)

  return <BuildLayout formName={formName} fieldNames={fieldNames} fieldConfig={config.fields} mode={mode} spacing={2} cancel={cancel} submit={cancel} direction='column' justify='center' alignItems='center' />
}

let EditClientDocument = ({ id, cancel, history, save }) => {
  return (
    <GraphQLFormProvider
      initialValuesFromData={(data) => data.clientdocument}
      mode='edit'
      id={id}
      {...config}
      afterSubmit={({ data }) => {
        console.log('after submit', data)
        save(data.updateClientDocument)
      }
      }
      renderDisplay={renderDisplay}
      renderInput={renderInput}
      InputFormLayout={(props) => <FormLayout {...props} formName={config.name} cancel={cancel} history={history} />}
      DisplayFormLayout={FormLayout}
    />
  )
}

EditClientDocument = withApollo(EditClientDocument);
export default EditClientDocument
