import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    flex: '0 0 auto',
    margin: 0,
    padding: '14px 24px 7px 24px',
  },
  footer: {
    paddingRight: 0,
    paddingBottom: 21
  },
  dialog: {
    minWidth: "50%",
  }
}));

const Confirm = props => {
  const classes = useStyles()
  const { title, description, onOk, onCancel, open } = props
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => onCancel()}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText> {description} </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button onClick={() => onCancel()} color="default" variant="contained" >
            Cancel
          </Button>
          <Button onClick={() => onOk()} color="primary" variant="contained" style={{ marginRight: 20 }}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Confirm

{/* <Button onClick={onCancel} color="default" variant="contained" startIcon={<ThumbDownOutlinedIcon />}> */ }
