import React, { useState, useEffect } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { onBasicList, searchList } from '../../lib/fuzzy';
// import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag'
import GraphQLTable from '../generic/GraphQLTable'
import { useConfirm } from "material-ui-confirm";
import { Card, Grid, Button, Modal, TextInput, Switch } from '../generic'

const TheList = gql`
	query Company {
		companys (limit:10000) {
			id
			name
			sector
			basicListed
		}
	}
`;

const config = {
  query: gql`
    {
      companys (limit:10000) {
        id
        name
        employerType
        sector
        basicListed
      }
    }
  `,
  dataset: data => data.companys,
  fields: {
    name: { label: 'Name' },
    basicListed: { label: 'Basic Listed', accessor: row => (row.basicListed ? 'basicListed' : '') }
  }
}

const updateMutation = gql`
  mutation($input: UpdateCompanyInput!, $id: String!) {
    updateCompany(id: $id, input: $input) {
      id
      name
      basicListed
      updatedAt
    }
  }
`

const createMutation = gql`
  mutation($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
      name
      basicListed
      updatedAt
    }
  }
`
const removeMutation = gql`
  mutation($id: String!) {
    removeCompany(id: $id)
  }
`

let CompanyList = (props) => {
  const confirm = useConfirm();
  const { data: { loading, error, companys } } = props;
  const [basicList, setBasicList] = useState()
  const [showEditModal, setShowEditModal] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [updateName, setUpdateName] = useState(0)
  const [updateBasic, setUpdateBasic] = useState()
  const [updateId, setUpdateId] = useState()
  const [createNewCompanyParams, setCreateNewCompanyParams] = useState({
    name: '',
    basicListed: false,
  })
  const [threshold, setThreshold] = useState(0.2)

  useEffect(() => {
    if (companys && !basicList) {
      setBasicList(searchList(companys))
    }
  }, [companys]);


  if (loading) {
    return <p>Loading ...</p>;
  }
  else if (error) {
    return <p>Error, graphgql error! {error}</p>;
  }

  const handleToggleEditModal = data => {
    if (data) {
      setShowEditModal(!showEditModal)
      setUpdateId(data.id)
      setUpdateName(data.name)
      setUpdateBasic(data.basicListed)
    } else {
      setShowEditModal(!showEditModal)
    }
  }

  const handleNameChange = e => {
    setUpdateName(e.target.value)
    setUpdateBasic(onBasicList(basicList, e.target.value, null, threshold))
  }

  const handleSave = () => {
    props.client
      .mutate({
        mutation: updateMutation,
        variables: {
          input: {
            name: updateName,
            basicListed: updateBasic
          },
          id: updateId
        }
      })
      .then(res => {
      })
      .catch(err => {
        // TODO: Improve error handling
        console.log('error=', err)
      })
    handleToggleEditModal()
  }

  const handleUpdateParams = (key, value) => {
    const currentNewCompanyParams = Object.assign({}, createNewCompanyParams)
    currentNewCompanyParams[key] = value
    setCreateNewCompanyParams(currentNewCompanyParams)
  }

  const handleUpdateCreateValue = e => {
    const value = e.target.value
    setCreateNewCompanyParams({ name: value, basicListed: onBasicList(basicList, value, null, threshold) })
  }

  const handleCreate = () => {
    props.client
      .mutate({
        mutation: createMutation,
        variables: {
          input: {
            name: createNewCompanyParams.name,
            basicListed: createNewCompanyParams.basicListed
          }
        }
      })
      .then(res => {
       // console.log('result=', res)
      })
      .catch(err => {
        // TODO: Improve error handling
        console.log('error=', err)
      })
    setShowCreateModal(!showCreateModal)()
  }

  const onDeleteConfirm = () => {
   confirm({
     title: 'Delete Company',
     content: 'Are you sure you want to Delete the company?',
     onCancel: () => {},
     onOk: () => { deleteCompany(); },
      });
  }

  const deleteCompany = () => {
    props.client
     .mutate({
       mutation: removeMutation,
       variables: {
         id: updateId
       },
       refetchQueries: [{ query: config.query }]
     })
      .then((res) => {
     }
   )
    handleToggleEditModal()

  }

    return (
      <div className="content">
        <Card>
          <h3>Companies</h3>
          <Grid container justifyContent='flex-end' spacing={1}>
            <Grid item xs={5} align="left">
              <Button color="primary" size="small" onClick={() => setShowCreateModal(true)}>
                  Add New Company
                </Button>
            </Grid>
          </Grid>
          <GraphQLTable config={config} onRowPress={data => handleToggleEditModal(data)} />
        </Card>

        <Modal title="Add New Company" open={showCreateModal} onOk={handleCreate} onClose={() => setShowCreateModal(!showCreateModal)} onCancel={() => setShowCreateModal(!showCreateModal)}>
          <input key={"Company Name"} placeholder="Company Name" type="text" onChange={d => handleUpdateCreateValue(d)} style={{ width: 250, marginRight: 10, minHeight: 31 }} />
          <Switch checked={createNewCompanyParams.basicListed} onChange={d => handleUpdateParams('basicListed', d)} />
    
          <TextInput defaultValue={0.2} step={0.1} min={0} max={1} onChange={threshold => {
            // console.log('onBasicList fuse name ', threshold, createNewCompanyParams);
            setThreshold(threshold);
            handleUpdateParams('basicListed', onBasicList(basicList, createNewCompanyParams.name, null, threshold))
              }}
            style={{ width: 100, marginRight: 10, minHeight: 31 }} />
        </Modal>

        <Modal title="Update Company Value" open={showEditModal} onOk={handleSave} onCancel={handleToggleEditModal} onClose={handleToggleEditModal} okText='Save' cancelText='Cancel'>
          <input type="text" value={updateName} onChange={handleNameChange} style={{ width: 250, marginRight: 10, minHeight: 31 }} />
          <Switch label='On Basic List' value={updateBasic} onChange={d => { setUpdateBasic(d.target.checked) }} />
          {/* <center style={{ marginTop: 7 }}>
            <Button type='danger' onClick={onDeleteConfirm}>
              Delete
            </Button>
          </center> */}
        </Modal>
      </div>
    )
}
CompanyList = graphql(TheList)(CompanyList);
export default withApollo(CompanyList)