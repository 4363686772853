import React from 'react'
import EmployeeList from './EmployeeList'
import Employee from './Employee'
import { Route, Switch } from 'react-router-dom'

const Employees = () => (
  <div>
    <Route path='/employees' exact component={EmployeeList} />
    <Switch>
      <Route path='/employees/new' exact render={({ history }) => <Employee history={history} mode={'add'} />} />
      <Route path='/employees/:id/:edit?' render={({ match, history }) => <div><Employee history={history} mode={match.params.edit ? 'edit' : 'display'} id={match.params.id} /></div>} />
    </Switch>
  </div>
)

export default Employees