import React from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { CheckboxContainer } from './Checkbox.style'

function MyCheckbox({ label, value, onChange }) {
  if (label) {
    return <CheckboxContainer>
      <FormControlLabel
        control={<Checkbox checked={value} onChange={onChange} color="primary" />}
        label={label}
      />
    </CheckboxContainer>
  } 
  return <CheckboxContainer>
    <Checkbox checked={value} onChange={onChange} color="primary" />
  </CheckboxContainer>
}

MyCheckbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string
}

export default MyCheckbox
