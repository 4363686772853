import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { EditModal } from './FuneralInfo'
import Member from './Member'
import { Confirm, DisplayItem } from '../generic';
import { capitalize } from '../../lib/utils'
import { getAgeFromDOB } from '../../lib/validations'
import { ActivityIndicator } from '../generic'
import { funeralInformation } from './FuneralInfo';
import { differenceInDays } from 'date-fns'
import Numeral from 'numeral'
import moment from 'moment';
import 'numeral/locales/en-za.js'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    paddingBottom: 14,
  },
  table: {
    minWidth: 650,
    fontSize: 15,
  },
  head: {
    paddingTop: 7,
    paddingBottom: 7,
    fontWeight: 400,
  },
  button: {
    '&:hover': {
      backgroundColor: '#f00f15',
      color: '#fff',
    }
  },
  cell: {
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 14,
    flexGrow: 1,
    alignContent: 'flex-start'
  }
});

const REMOVE_MEMBER = gql`
	mutation RemoveFuneralMember($id: ObjID!, $reason: String) {
		removeFuneralMember(id: $id, reason: $reason) {
      id
        clientId
        firstNames
        surname
        idNumber
        idType
        memberType
        relationship
        currentPremium
        coverAmount
        DOB
        age
        ageAtSignup
        cellNumbers
        email
        income
        estimatedIncome
        incomeBand
        confidence
        state
        smoker
        createdAt
        dead,
        dateOfDeath,
		}
	}
`;

const LIVES_COVERED = gql`
  query Funeral($id: String!, $effectiveDate: Float) {
    funeral(id: $id) {
      id
      livesCovered {
        id
        clientId
        firstNames
        surname
        idNumber
        idType
        memberType
        relationship
        currentPremium
        coverAmount
        DOB
        age
        ageAtSignup
        cellNumbers
        email
        income
        estimatedIncome
        incomeBand
        confidence
        state
        versionOnDate(date: $effectiveDate) {
          state
        }
        smoker
        createdAt
        dead,
        dateOfDeath,
        numPayments
      }
    }
  }`

export const actualCover = (memberType, coverAmount, DOB, funeralCoverAmount, ageAtSignup = 0, createdAt) => {

  if (coverAmount) {
    return coverAmount
  }

  let amount = funeralCoverAmount

  if (memberType === "PARENT") { amount = amount / 2 }
  if (memberType === "EXTENDED") { amount = amount / 2 }
  if (memberType === "CHILD") {
    let currentAge
    if (DOB) {
      currentAge = moment().diff(DOB, 'years')
    }
    else {
      currentAge = ageAtSignup + moment().diff(createdAt, 'years')
    }

    if (currentAge <= 5) amount = amount / 4
    if (currentAge > 5 && currentAge <= 13) amount = amount / 2
    if (currentAge > 13 && currentAge <= 23) amount = amount
    if (currentAge > 23) amount = 0
  }
  return amount
}

const validChildrenAge = (dob) => {
  if (getAgeFromDOB(dob) < 18) {
    return true
  }
  else return false
}

const memberIncludeStates = ['IN_WAITING_PERIOD', 'COVERED', 'CLAIM_NOTIFIED', 'CLAIMED', 'INITIAL', 'POLICY_LAPSED', 'POLICY_FROZEN']

function LivesCovered({ funeral, history, effectiveDate, client }) {
  const classes = useStyles();
  const { data, loading, error } = useQuery(LIVES_COVERED, { variables: { id: funeral.id, effectiveDate } }) || {}
  const [open, setOpen] = useState(false)
  const [member, setMember] = useState()

  if (loading) {
    return <ActivityIndicator />
  }

  const { id: funeralId, coverAmount: funeralCover, state, createdAt, policyType, versionOnDate, policyNumber, clientId, pricingVersion } = funeral;

  const { funeral: funeralData } = data || {};
  const { livesCovered: lives } = funeralData || [];
  const freedom = !policyNumber.includes('MF')
  const indfun = freedom && pricingVersion && pricingVersion.indexOf('INDFUN') > -1 ? true : false
  const famfun = freedom && !indfun
  const showVersion = effectiveDate && (differenceInDays(new Date(), new Date(effectiveDate * 1)) !== 0) && versionOnDate && versionOnDate.state ? true : false
  // console.log('versionOnDate showVersion', showVersion, effectiveDate, versionOnDate)
  const childrenMax = freedom ? 6 : 5
  const extendedMax = freedom ? 6 : 20
  const maxChildrenNotReached = lives && lives.filter(l => l.memberType === 'CHILD' && memberIncludeStates.indexOf(l.state) > -1 && (!l.DOB || validChildrenAge(l.DOB))).length < childrenMax
  const maxExtendedNotReached = lives && lives.filter(l => l.memberType === 'CHILD' && memberIncludeStates.indexOf(l.state) > -1 && (!l.DOB || validChildrenAge(l.DOB))).length < extendedMax
  
  const canAddSpouse = (['INITIAL', 'INFO_MISSING', 'DORMANT'].indexOf(state) > -1 || (state !== 'CANCELLED' && famfun)) && ( lives && lives.filter(l => l.memberType === 'SPOUSE' && l.state !== 'NO_COVER_MEMBER_REMOVED').length === 0)
  const canAddChild = (['INITIAL', 'INFO_MISSING', 'DORMANT'].indexOf(state) > -1 || (state !== 'CANCELLED' && ((!indfun && !freedom) || famfun))) && maxChildrenNotReached
  const canAddExtended = (['INITIAL', 'INFO_MISSING', 'DORMANT'].indexOf(state) > -1 || (state !== 'CANCELLED' && famfun)) && maxExtendedNotReached

  let options = []
  if (canAddSpouse) {
    options.push('SPOUSE')
  }

  if (canAddChild) {
    options.push('CHILD')
  }

  if (canAddExtended) {
    options.push('EXTENDED')
  }

  const removeMember = () => {
    client
      .mutate({
        mutation: REMOVE_MEMBER,
        variables: {
          id: member.id,
          input: { reason: 'Client requested' },
        },
        refetchQueries: [{ query: LIVES_COVERED, variables: { id: funeral.id, effectiveDate } }, { query: funeralInformation, variables: { id: funeral.id, effectiveDate } }]
      })
      .then(response => {
        console.log('after remove ', response)
        setMember()
      }
      )
      .catch(err => {
        console.log('remove member error  ', err)
      });
  }

  const sortedLives = (lives) => lives && lives.map(m => {
    if (m.memberType === 'POLICY_HOLDER') {
      m.sortOrder = 0
    }
    else if (m.memberType === 'SPOUSE') {
      m.sortOrder = 2
    }
    else if (m.memberType === 'PARENT') {
      m.sortOrder = 3
    }
    else if (m.memberType === 'CHILD') {
      m.sortOrder = 4
    }
    else {
      m.sortOrder = 5
    }
    // m.ageAtSignup = (m.idNumber && m.idType === 'rsa_id') ? getAgeFromID(m.idNumber, m.idType) : getAgeFromDOB(m.DOB)
    // m.currentPremium = 0
    return m
  }).sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1)

  return (<>
    {((canAddChild || canAddSpouse || freedom) && options.length > 0)
      ? <EditModal Content={Member} fontSize={18} noButton heading={"Add Life Covered"} text="Lives Covered" options={options} mode='add' funeralId={funeralId} coverAmount={funeralCover} icon='add' />
      : <h3>Lives Covered</h3>}

    <TableContainer size='small' component={Paper}>
      <Table className={classes.table} aria-label="lives table">
        <TableHead >
          <TableRow>
            <TableCell className={classes.head}>Member</TableCell>
            <TableCell className={classes.head}>Type</TableCell>
            <TableCell className={classes.head}>ID / DOB</TableCell>
            <TableCell className={classes.head}>Relationship</TableCell>
            <TableCell className={classes.head}>Date_Added</TableCell>
            <TableCell className={classes.head}>Mobile</TableCell>
            <TableCell className={classes.head}>SignupAge</TableCell>
            {/* <TableCell className={classes.head}>Smoker</TableCell> */}
            <TableCell className={classes.head}>Income</TableCell>
            {/* <TableCell className={classes.head}>Estimated</TableCell> */}
            <TableCell className={classes.head}>Coverage</TableCell>
            <TableCell className={classes.head}>Premium</TableCell>
            {showVersion && <TableCell className={classes.head}>Effective State</TableCell>}
            <TableCell className={classes.head}>{showVersion && versionOnDate ? 'Current State' : 'State'}</TableCell>
            <TableCell className={classes.head} />

          </TableRow>
        </TableHead>
        <TableBody>
          {lives && sortedLives(lives).map((row) => {
            const { id, memberType, firstNames, surname, relationship, cellNumbers, ageAtSignup, smoker, income, idNumber, coverAmount, DOB, currentPremium, state, createdAt, versionOnDate } = row
            const { state: versionState } = versionOnDate || {}
            console.log('version on state', versionState)
            if (memberType === 'BENEFICIARY') {
              return <div />
            }
            else return (
              <TableRow key={id} >
                <TableCell className={classes.cell} component="th" scope="row"><EditModal Content={Member} fontSize={14} fontWeight={400} noButton heading={`Edit Member of type ${capitalize(memberType)}`} size="xsmall" mode='edit' text={`${firstNames} ${surname}`} funeralId={funeralId} coverAmount={funeralCover} id={id} memberType={memberType} /></TableCell>
                <TableCell className={classes.cell}>{memberType}</TableCell>
                <TableCell className={classes.cell}>{
                  idNumber
                    ? history
                      ? <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => { localStorage.setItem('searchString', idNumber); history.push(`/products/${idNumber}`) }}> {idNumber} </span>
                      : idNumber
                    : DOB
                      ? moment(DOB).format("DD-MM-YYYY") : ''}
                </TableCell>
                <TableCell className={classes.cell}>{relationship}</TableCell>
                <TableCell className={classes.cell}>{createdAt ? moment(createdAt).format('DD-MM-YYYY') : ' '}</TableCell>
                <TableCell className={classes.cell}>{cellNumbers && cellNumbers[0] ? <DisplayItem fontSize={14} borderBottomStyle='none' type='mobile' value={cellNumbers[0]} clientId={clientId} /> : ' '}</TableCell>
                <TableCell className={classes.cell}>{ageAtSignup >= 0 ? ageAtSignup : "ERROR"}</TableCell>
                {/* <TableCell className={classes.cell}>{smoker != null ? (smoker ? 'Yes' : 'No') : 'Unknown'} </TableCell> */}
                <TableCell className={classes.cell}>{memberType == "POLICY_HOLDER" ? Numeral(income).format('$ ##,###0') : " "} </TableCell>
                {/* <TableCell className={classes.cell}>{memberType == "POLICY_HOLDER" ? Numeral(estimatedIncome).format('$ ##,###0') : " "}</TableCell> */}
                <TableCell className={classes.cell}> {Numeral(actualCover(memberType, coverAmount, DOB, funeralCover, ageAtSignup, createdAt)).format('$ ##,###0')}</TableCell>
                <TableCell className={classes.cell}>{currentPremium ? Numeral(currentPremium).format('$ ##,###0.00') : '-'}</TableCell>
                {showVersion && <TableCell className={classes.cell}>{versionState}</TableCell>}
                <TableCell className={classes.cell}>{state}</TableCell>
                <TableCell className={classes.cell}>
                  {memberType !== 'POLICY_HOLDER' && state !== 'NO_COVER_MEMBER_REMOVED' && !(memberType === 'CHILD' && indfun) && <IconButton aria-label="edit" className={classes.button} onClick={() => { setMember(row); setOpen(true) }} >
                    <RemoveCircleOutlineIcon style={{ color: "#D5D5D5", size: 14 }} />
                  </IconButton>}
                </TableCell>
              </TableRow>
            )
          }
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <Confirm
      open={open}
      title='Remove life covered'
      description={member ? `Press OK to remove ${member.firstNames} ${member.surname} from the policy` : 'Press OK to remove'}
      onOk={() => {
        setOpen(false);
        removeMember()
      }} onCancel={() => setOpen(false)} />
  </>
  );
}

export default withApollo(LivesCovered)
