import React, { useState, useEffect } from 'react';
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag'
import Claim from './Claim';
import ClaimLoans from './ClaimLoans';
import { MemberInformation } from '../funeral';
import { ActivityIndicator, Card, FormModal, Button, DisplayItem, Grid, Modal } from '../generic'
import { ClientInformation, ClientNotes} from '../shared/client'
import ProductCommunication from '../communication/ProductCommunication'
import PolicyStatusHistory from '../credit-life/PolicyStatusHistory'
import PaymentHistory from '../credit-life/PaymentHistory'
import CreditLife from '../credit-life/CreditLife'
import ClientObligation from '../clientObligation/ClientObligation'
// import Policies from './Policies'
// import ClaimantClaims from './ClaimantClaims'
import FraudChecks from './FraudChecks'
import moment from 'moment'
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'

// switch between locales
Numeral.locale('en-za')

const EditModal = (props) => {
  return (
    <FormModal
      okText="Ok"
      cancelText="Back"
      width='85%'
      disableCancel={true}
      disableOk={true}
      componentProps={props}
    />
    )
  }

// TODO: Add claims {} so can check if going to edit- needs backend call
// TODO: Add any info you want in ClientInfo

export const obligationOptions = (forObligations) => {
  let options = []
  forObligations && forObligations.forEach(ob => {
    options.push({ value: ob.id, label: `${ob.clientCreditor.description} ${ob.reference} ` })
  })
  return options
}

export const claimInformation = gql`
  query claimInformation($id: String!) {
    claim(id: $id) {
    id
    claimPaymentCounter
    claimant {
      id
      idNumber
      cellNumbers
      email
    }
    client {
      id
      firstNames
      surname
      cellNumbers
      email
      idNumber
    }
    claimNumber
    state
    relationship
    cancelReason
    claimReason
    relationship
    effectiveDate
    forMembersIds
    forFuneral {
      id
      livesCovered {
        id
        idNumber
        memberType
      }
      policyNumber
      state
      versionOnDate {
        state
      }  
      payments {
        id
        paymentFor
        timestamp
        amount
        reversalReason
      }
      policyStatusChanges {
        statusAfter
        updatedAt
      }
    }
    fraudChecks {
      id
      type
      outcome
      description
      checkedAt
      checkedBySystem
      overriddenAt
    }
    forCreditLife {
      id
      policyNumber
      policyStatus
      versionOnDate {
          policyStatus
        }
      payments {
        id
        paymentFor
        timestamp
        amount
        reversalReason
       }
      policyStatusChanges {
        statusAfter
        updatedAt
      }
    }
    forObligations {
      id
      reference
      currentBalance
      claimPaymentBankAccountId
      claimPaymentBankAccount {
        bankName
        accountNo
      }
      claimPaymentReference
      clientCreditor {
        id
        description
        creditor {
          id
          bankAccount {
            bankName
            accountNo
          }
          contactDetails {
            type
            attention
            value
            purpose
          }
          defaultClaimPaymentDetails {
            bankAccountType
            referenceType
            customReference
            bankName
            branchCode
          }
        }
      }
    }
    amountClaimed
    claimType
    productType
    createdAt
    createdBy {
      description
    }
  }
  }
`;

let ClaimAdmin = (props) => {
  const { id, data, match, history, cancel, client } = props

  const { claim, loading, error } = data || {}
  const [row, setRow] = useState()
  const [viewPolicy, setViewPolicy] = useState(false)
  const [open, setOpen] = useState(false)
  const [clientObligationId, setClientObligationId] = useState()

  if (loading) {
    return <ActivityIndicator />;
  }
  if (error) {
    return <Card>An error occurred ...</Card>;
  }

  if (!claim) { return <div /> }

  const { forObligations, forCreditLife, forFuneral, claimType, forMembersIds, relationship, claimNumber, fraudChecks } = claim || {}
  console.log('claim is', claim)
  const livesCovered = forFuneral && forFuneral.livesCovered
  const forMemberId = forMembersIds && forMembersIds.length > 0 ? forMembersIds[0] : undefined
  // const forClient = claim.client
  // const forClaimant = claim.claimant
  const policyNumber = forCreditLife ? forCreditLife.policyNumber : forFuneral ? forFuneral.policyNumber : ''
  const claimId = match && match.params && match.params.id ? match.params.id : id
  const lifeCovered = forFuneral && livesCovered.find(l => l.memberType === 'POLICY_HOLDER')
  // const forMember = forFuneral && livesCovered.find(l => l.id === forMemberId)
  const policyHolderIslifeClaimed = (forFuneral && lifeCovered && lifeCovered.id === forMemberId)

  const handleClose = () => {
    setOpen(false);
  }

  const selectRow = (row) => {
    setRow(row)
    setClientObligationId(row.id)
    setOpen(true)
  }

    return (
      <Card>
        <Grid container justifyContent='flex-start' spacing={1}>
          <DisplayItem label="Claim Number" value={claimNumber} xs={2} />
          <DisplayItem label="Policy" value={policyNumber} xs={2} />
          <DisplayItem label="Claim Type" value={claim.claimType && claim.claimType.indexOf('COMPULSORY') > -1 ? "COMPULSORY UNPAID LEAVE" : claim.claimType} xs={2} />
          <DisplayItem label="Claim State" value={claim.state} xs={2} />
          <DisplayItem label="Amount Claimed" value={Numeral(claim.amountClaimed).format('$ ##,###0.00')} xs={2} />
          <DisplayItem label="Claim Initiated" value={moment(claim.createdAt).format("Do MMM YYYY DD:MM:ss")} xs={2} />
          <DisplayItem label="Captured By" value={claim.createdBy ? claim.createdBy.description : ''} xs={2} />
          <DisplayItem label="Effective Date" value={moment(claim.effectiveDate).format("Do MMM YYYY")} xs={2} />
          <DisplayItem label="Effective Status" value={
            claim.forCreditLife
              ? claim.forCreditLife.versionOnDate && claim.forCreditLife.versionOnDate.policyStatus ? claim.forCreditLife.versionOnDate.policyStatus : claim.forCreditLife.policyStatus
              : forFuneral ? forFuneral.versionOnDate && forFuneral.versionOnDate.state ? forFuneral.versionOnDate.state : forFuneral.state : ''
          } xs={2} />
          {claim.claimReason != claim.claimReason && <DisplayItem disabled label="Reason" value={claim.claimReason} xs={2} />}
          {claim.cancelReason && <DisplayItem label="Reason" value={claim.cancelReason} xs={2} />}

          {/* <DisplayItem label="Policy Number" onClick={() => setViewPolicy(true)} value={policyNumber} /> */}
        </Grid>
        <br />
        {claim.claimant && <ClientInformation id={claim.claimant.id} clientType='CLAIMANT' title="Claimant Info" refetchQuery={claimInformation} cancel={cancel} history={history} productId={forCreditLife ? forCreditLife.id : forCreditLife ? forFuneral.id : ''} forFuneral={forFuneral ? true : false} relationship={relationship} claimId={claim.id} />}

        <br />
        {forMemberId && <MemberInformation id={forMemberId} title="Life Claimed" notForPolicyHolder={true} />}

        {!policyHolderIslifeClaimed && <ClientInformation id={claim.client.id} title="Policy Holder" clientType='Savings' refetchQuery={claimInformation} cancel={cancel} history={history} productId={forCreditLife ? forCreditLife.id : forCreditLife ? forFuneral.id : ''} forFuneral={forFuneral ? true : false} />}

        {forCreditLife && <ClaimLoans forObligations={forObligations} selectRow={selectRow} />}

        <Claim mode={['INITIATED', 'AWAITING_VALIDATION', 'NEEDS_INFORMATION', 'CLIENT_INFO_REQUIRED', 'APPROVED_RECURRING_PAYMENT', 'APPROVED_PENDING_PAYMENT'].includes(claim.state) ? "edit" : "display"} id={claim.id} claimantId={claim.claimant ? claim.claimant.id : ''} clientInfo={claim.client} clientId={claim.client.id} creditLifeId={claim.creditLife && claim.creditLife.id || ''} funeralId={claim.funeralPolicy && claim.funeralPolicy.id || ''} forObligations={forObligations} claimState={claim.state} productType={claim.productType} claimType={claim.claimType} notes={claim.client.notes} forFuneral={forFuneral} />

        <FraudChecks fraudChecks={fraudChecks} />

        {cancel && <Grid container>
          <Grid item ><br /><Button color="primary" onClick={cancel}>Back</Button></Grid>
        </Grid>}

        <br />

        <Grid container spacing={1}>
          <Grid item xs={8}> <PaymentHistory history={forFuneral ? forFuneral.payments : forCreditLife.payments} /></Grid>
          <Grid item xs={4}><PolicyStatusHistory history={forFuneral ? forFuneral.policyStatusChanges : forCreditLife.policyStatusChanges} /></Grid>
        </Grid>

        {claim.claimant && <ClientNotes clientId={claim.claimant.id} productId={claimId} type={"Claims"} />}

        {claim.claimant && <ProductCommunication clientId={claim.claimant.id} productId={claim.id} type={"CLAIM"} />}

        <Modal
          title={"Edit Creditor Payment Info"}
          open={open}
          onClose={handleClose}
          noButtons
        >
          <ClientObligation loan={row} claimId={claimId} clientObligationId={clientObligationId} afterSubmit={handleClose} cancel={handleClose} />

        </Modal>


    <Modal
        title={'Display Policy for Claim'}
        okText="Ok"
          cancelText="Back" 
          open={viewPolicy && (policyNumber && (policyNumber.startsWith('MK') || policyNumber.startsWith('YL')))}
          onOk={() => setViewPolicy(false)}
          onCancel={() => setViewPolicy(false)}
      >
          {forCreditLife && <CreditLife id={forCreditLife.id} />}

    </Modal>

      </Card >
    );
}

ClaimAdmin = withApollo(ClaimAdmin);
ClaimAdmin = graphql(claimInformation, {
options: ({ match, id  }) => ({ variables: { id: match && match.params ? match.params.id : id } })
})(ClaimAdmin)

export default ClaimAdmin;