import React from 'react'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { GraphQLFormProvider } from 'react-form-helper'
import { isInteger } from '../../lib/validations'
import { renderInput, BuildLayout, renderDisplay } from '../generic';

const GROUP_FUNERAL_PRODUCT_TYPES = ['GROUP_FUNERAL_BSSP_LOW_RISK','GROUP_FUNERAL_BSSP_HIGH_RISK','GROUP_FUNERAL_BSSP_STANDARD_RISK','GROUP_FUNERAL_GFS']

const config = {
  name: 'groupProduct',
  fields: {
    'paymentDueDay': {
      type: 'text',
      label: 'Payment Due Day ',
      validate: (value) => {
        if (!value) {
          return 'Day of the month is required'
        }
        else if (value && isInteger(value)) {
          return 'Must be a day of the month'
        } else if (value && (value > 31 || value < 1)) {
          return 'Must be a day of the month'
        }
      }
    },
    'paymentMethod': {
      type: 'select',
      label: 'Payment Method',
      options: ['DEBI_CHECK', 'DO', 'EFT'].map(e => ({ label: e, value: e })).sort((a, b) => (a <= b ? -1 : 1))
    },
    'productType': {
      type: 'select',
      label: 'Product Type',
      options: GROUP_FUNERAL_PRODUCT_TYPES.map(e => ({ label: e, value: e })).sort((a, b) => (a <= b ? -1 : 1)),
      validate: (value) => {
        if (!value) {
          return 'Product Type is required'
        }
      }
    },
    'onboarding.preExistingGroup': {
      type: 'switch',
      label: 'PreExisting Group',
    },
    'onboarding.lastYearClaimsAmount': {
      type: 'text',
      label: 'Last Year Claims Amount',
    },
    "onboarding.lastYearPremiumAmount": {
      type: 'text',
      label: 'Last Year Premium Amount',
    },
    'onboarding.previousInsurer': {
      type: 'text',
      label: 'Previous Insurer'
    },
    'onboarding.applicationReason': {
      type: 'text',
      label: 'Application Reason',
    },
  },
  query: gql`
    query GroupProduct($id: String!) {
      groupproduct(id: $id) {
        id
        group {
          id
          name
          postalCode
          email
        }
        onboarding {
          preExistingGroup 
          lastYearClaimsAmount
          lastYearPremiumAmount
          previousInsurer
          applicationReason
        }
        paymentMethod
        paymentDueDay
        groupProductNumber
        productType
        state
        processedUntil
        applicationDate
        policyReadyDate
        pendingLapsedAt
        pendingExitAt
        lapsedAt
        cancelledAt
        cancelReason
        numFullPayments
      }
    }`,
  updateMutation: gql`
    mutation GroupProduct($input: UpdateGroupProductInput!, $id: String!) {
      updateGroupProduct(id: $id, input: $input) {
        id
        group {
          id
          name
          postalCode
          email
        }
        onboarding {
          preExistingGroup 
          lastYearClaimsAmount
          lastYearPremiumAmount
          previousInsurer
          applicationReason
        }
        paymentMethod
        paymentDueDay
        productType
        groupProductNumber
        productType
        state
        processedUntil
        applicationDate
        policyReadyDate
        pendingLapsedAt
        pendingExitAt
        lapsedAt
        cancelledAt
        cancelReason
        numFullPayments
      }
  }`,
}

const FormLayout = (props) => {
  const { cancel, history, data, formName } = props;
  return (<BuildLayout formName={formName} fieldNames={Object.keys(config.fields)} fieldConfig={config.fields} direction='column' spacing={2} align='center' justify='center' cancel={cancel} push={() => history.push('/bankaccounts')} />);
}

let EditGroupProduct = (props) => {
  const { cancel, history, id } = props;
  
  return (
    <div>
      <GraphQLFormProvider
        mode="edit"
        id={id}
        {...config}
        afterSubmit={({ data }) => { cancel() }}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout {...props} formName={config.name} cancel={cancel} history={history} />}
        DisplayFormLayout={FormLayout}
        initialValuesFromData={(data) => {
          const { ...rest } = data.groupproduct || {}
          return ({  ...rest })
        }}
        mapInputVariables={(props) => {
          const { onboarding, group, ...rest } = props;
          return ({ input: { ...rest, onboarding } })
        }}
        keepInitialValues
      />
    </div>
  )
}
EditGroupProduct = withApollo(EditGroupProduct);
export default EditGroupProduct