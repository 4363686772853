import React from "react";
import { Editor } from '@tinymce/tinymce-react'; // version 5

const config = {
  height: 600,
  entity_encoding: "raw",
  menubar: 'file edit insert view format table help tools',
  //images_upload_handler:imageUploadHandler,
  element_format : 'html',
  //verify_html : false,
  //valid_children:"hr[class|width|size|noshade],font[face|size|color|style],span[class|align|style],img[href|src|name|title|onclick|align|alt|title|width|height|vspace|hspace],iframe[id|class|width|size|noshade|src|height|frameborder|border|marginwidth|marginheight|target|scrolling|allowtransparency],style[type]",
  valid_children:"+body[style]",
  removed_menuitems: 'newdocument',
  style_formats_autohide: true,
  content_style:"@import url('https://fonts.googleapis.com/css?family=Lato:300italic,300,700,700italic&display=swap'); body { font-family: 'Lato', sans-serif; } h1,h2,h3,h4 { font-family: 'Lato', sans-serif; } .footer {background-color: 'red'}  ",
  block_formats:'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3;',
  style_formats: [
    { title: 'Footer', block:'footer', styles: { display: 'inline-block', backgroundColor: 'red', color: 'white', textAlign: 'center',   } },
    {
      title: 'Line height',
      items: [
        {
          title: 'Default',
          inline: 'span',
          // `inline-block` because CSS `line-height` doesn't affect multiline `span`
          styles: { 'line-height': 'normal', display: 'inline-block' }
        },
        {
          title: '1.15',
          inline: 'span',
          styles: { 'line-height': '1.25em', display: 'inline-block' }
        },
        {
          title: '1.5',
          inline: 'span',
          styles: { 'line-height': '1.5em', display: 'inline-block' }
        },
        {
          title: '2',
          inline: 'span',
          styles: { 'line-height': '2em', display: 'inline-block' }
        },
        // add as more as you need
      ]
    }, {
      title: 'Text', items: [
        { title: 'Bold', format: 'bold' },
        { title: 'Italic', format: 'italic' },
        { title: 'Underline', format: 'underline' },
        { title: 'Strikethrough', format: 'strikethrough' },
        { title: 'Superscript', format: 'superscript' },
        { title: 'Subscript', format: 'subscript' },
        { title: 'Code', format: 'code' },
        { title: 'Badge', inline: 'span', styles: { display: 'inline-block', border: '1px solid #000', 'border-radius': '5px', padding: '3px 7px', margin: '0 2px', color: '#000' } },
      ]
    }
  ],
  font_formats:
    "Arial=arial; Arial Black=arial black,avant garde; Courier New=courier new,courier; Lato=lato; Roboto=roboto;",
  paste_data_images: true,
  file_picker_types: 'image',
    /* and here's our custom image picker*/
    file_picker_callback: function (cb, value, meta) {
      var input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
  
      /*
        Note: In modern browsers input[type="file"] is functional without
        even adding it to the DOM, but that might not be the case in some older
        or quirky browsers like IE, so you might want to add it to the DOM
        just in case, and visually hide it. And do not forget do remove it
        once you do not need it anymore.
      */
  
      input.onchange = function () {
        var file = this.files[0];
  
        var reader = new FileReader();
        reader.onload = function () {
          /*
            Note: Now we need to register the blob in TinyMCEs image blob
            registry. In the next release this part hopefully won't be
            necessary, as we are looking to handle it internally.
          */
          var id = 'blobid' + (new Date()).getTime();
          var blobCache =  window.tinymce.activeEditor.editorUpload.blobCache;
          var base64 = reader.result.split(',')[1];
          var blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);
  
          /* call the callback and populate the Title field with the file name */
          cb(blobInfo.blobUri(), { title: file.name });
        };
        reader.readAsDataURL(file);
      };
  
      input.click();
    },
    lineheight_formats: "8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 36pt",
    content_css: "/my-styles.css",
    importcss_append: true,
    external_plugins: {
      'variables': '/plugins/variables/plugin.min.js',
    },
  plugins: ["image", "code", "table", "link", "media", "codesample"],
  variable_mapper: {
    firstNames: "First",
    surname: "Last",
    email: "E-mail address"
  },
  toolbar:
    'undo redo | fontselect fontsizeselect formatselect | variables codesample| bold italic backcolor emoticons hr| \
    alignleft aligncenter alignright alignjustify | \
    bullist numlist outdent indent | removeformat | toc |fullscreen help',
  templates: [
      {title: 'full name', description: 'Client full name', content: '<<%= firstNames =>> <<%= surname =>>'},
      {title: 'first name', description: 'Client first name', content: '<<%= firstNames =>>'},
      {title: 'surname', description: 'Client last name', content: '<<%= surname =>>'},
      {title: 'header', description: 'Client last name', content: '<div id="paheHeader">Insert Header here</div>'},
      {title: 'footer', description: 'Client last name', content: "<div id='pageFooter' style={{position: fixed, left: 0, bottom: 0, width: 100%, background-color: red,color: white, text-align: center;}}>add footer here</div>"},
      {title: 'Modified date', description: 'Document last changed', content: '<p class="mdate">This will be replaced with the date modified</p>'}
    ],
    readonly: 1
}

let TinyMCE = ({value, isReadOnly, onChange}) => {
  return <Editor
    value={value}
      init={config}
      disabled={isReadOnly}
      apiKey="a3p000k4ca2o44rt2c41qm963tpoqbgy2sxsf5fzynezr7ef"
      onEditorChange={(content) => onChange(content)}
  />
}

export default TinyMCE;

// [
//   'code advlist autolink lists link image charmap print preview anchor',
//   'searchreplace visualblocks  fullscreen',
//   'insertdatetime media table paste help wordcount codesample emoticons toc hr template variables'
// ],