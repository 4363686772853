
import React from "react";
import * as FaIcons from "react-icons/fa";
import IncompleteFunerals from "../funeral/IncompleteFunerals";
import Products from "../products";
import Companies from "../company";
import Funerals, { FuneralList } from "../funeral";
import Quote from "../funeral/Quote";
import Savings from "../savings";
import CreditLifes from "../credit-life";
import ClearanceCertificates from "../clearance-certificate";
import Claims from "../claims";
import BankAccounts from "../bankAccount";
import Creditors from "../creditors";
import Leads from "../leads";
import Approvals from "../approvals";
import ReportsMenu from "../reports";
import Payments from "../payments";
import Nedbank from "../nedbank";
import Sales from "../../views/Sales";
import Campaigns from "../../views/Campaigns";
import { toLocalizedDateString } from "react-dates";
import Targets from "../targets";
import Employees from "../employees";
import { RegisteredUsers, SearchByUuid, Users } from "../users";
import DebtCounsellors from "../debt-counsellors";
import SalesTeams from "../sales-teams";
import QuestionSet from "../questions/QuestionSet";
import QuestionSets from "../questions";
import UserGroups from "../usergroups";
import CommunicationTemplateList from "../communication/templates/TemplateList";
import Templates from "../communication/templates";
import CommunicationsList from "../communication/CommunicationsList";
import SmsReplies from "../communication/SmsReplies";
import { NewDocuments } from "../shared";
import ChatAdmin from "../communication/ChatAdmin";
import WhatsAppChat from "../communication/WhatsAppChat";
import { logout } from "../../services/auth";
import Groups from "../groups"


const sidebarOptions = [
  {
    key: 'products',
    label: 'Search',
    title: 'Client Product Search',
    disableIcon: <FaIcons.FaSearch />,
    component: Products
  },
  {
    key: 'funeral',
    label: 'Funeral',
    title: 'Funeral',
    disableIcon: <FaIcons.FaHeartBroken />,
    component: Funerals
  },
  {
    key: 'funeral-quote',
    label: 'Funeral Quote',
    title: 'Funeral Quote',
    disableIcon: <FaIcons.FaHeart />,
    component: Quote
  },
  {
    key: 'incomplete-funerals',
    title: 'Incomplete Funeral Applications',
    label: 'Incomplete',
    disableIcon: <FaIcons.FaHeartBroken />,
    component: IncompleteFunerals
  },
  {
    key: 'allfunerals',
    label: 'All Funerals',
    title: 'Funeral List',
    disableIcon: <FaIcons.FaHeartBroken />,
    component: FuneralList
  },
  {
    key: 'savings',
    label: 'Savings',
    disableIcon: <FaIcons.FaPiggyBank />,
    component: Savings
  },
  {
    key: 'credit-life',
    label: 'Credit Life',
    disableIcon: <FaIcons.FaUmbrella />,
    component: CreditLifes
  },
  {
    key: 'clearance-certificate',
    label: 'Clearance Certificate',
    disableIcon: <FaIcons.FaUmbrella />,
    component: ClearanceCertificates
  },
  {
    key: 'claims',
    label: 'Claims',
    disableIcon: <FaIcons.FaMoneyBillWave />,
    component: Claims
  },
  {
    key: 'bankaccounts',
    label: 'Bank Accounts',
    disableIcon: 'piggy-bank',
    component: BankAccounts
  },
  {
    key: 'creditors',
    label: 'Creditors',
    disableIcon: <FaIcons.FaPersonBooth />,
    component: Creditors
  },
  {
    key: 'leads',
    label: 'Leads',
    disableIcon: <FaIcons.FaPhoneAlt />,
    component: Leads
  },
  {
    key: 'approvals',
    label: 'Approvals',
    disableIcon: <FaIcons.FaThumbsUp />,
    component: Approvals
  },
  {
    key: 'report',
    label: 'Reports',
    disableIcon: <FaIcons.FaTrashAlt />,
    component: ReportsMenu
  },
  {
    key: 'payment',
    label: 'Payments',
    disableIcon: <FaIcons.FaDollarSign />,
    component: Payments
  },
  {
    key: 'nedbank',
    label: 'Nedbank',
    disableIcon: <FaIcons.FaCarCrash />,
    component: Nedbank
  },
  {
    key: 'dashboard',
    label: 'Sales',
    disableIcon: <FaIcons.FaChartLine />,
    component: Sales
  },
  {
    key: 'campaigns',
    label: 'Campaigns',
    disableIcon: <FaIcons.FaBowlingBall />,
    component: Campaigns
  },
  {
    key: 'targets',
    label: 'Targets',
    disableIcon: 'chart-bar',
    class: 'nav-link',
    component: Targets
  },
  {
    key: 'employees',
    label: 'Employees',
    disableIcon: 'user-circle',
    component: Employees
  },
  {
    key: 'users',
    label: 'Users',
    disableIcon: 'users',
    component: Users
  },
  {
    key: 'company',
    label: 'Companies',
    disableIcon: 'coffee',
    class: 'nav-link',
    component: Companies
  },
  {
    key: 'debt-counsellors',
    label: 'DC',
    disableIcon: 'stethoscope',
    component: DebtCounsellors
  },
  {
    key: 'complaints',
    label: 'Complaints',
    disableIcon: 'smile',
    component: Sales
  },
  {
    key: 'sales-teams',
    label: 'Sales Team',
    disableIcon: 'building',
    component: SalesTeams
  },
  {
    key: 'questions',
    label: 'Questions',
    disableIcon: 'question-circle',
    component: QuestionSets
  },

  {
    key: 'uuid',
    label: 'UUID Search',
    title: 'Client Search by UUID',
    disableIcon: 'users',
    component: SearchByUuid
  },
  {
    key: 'registered',
    label: 'Registered Users',
    disableIcon: <FaIcons.FaUnlink />,
    component: RegisteredUsers
  },
  {
    key: 'usergroups',
    label: 'User Groups',
    disableIcon: 'users',
    component: UserGroups
  },
  {
    key: 'communication-templates',
    label: 'Templates',
    disableIcon: 'marker',
    title: 'Communications Templates',
    component: Templates
  },
  {
    key: 'communications',
    label: 'Correspondence',
    title: 'Communications History',
    disableIcon: 'paper-plane',
    component: CommunicationsList
  },
  {
    key: 'replies',
    label: 'SMSes',
    title: "Received SMS's",
    disableIcon: 'paper-plane',
    component: SmsReplies
  },
  {
    key: 'uploads',
    label: 'Uploads',
    title: 'Unreviewed Documents',
    disableIcon: 'paper-plane',
    component: NewDocuments
  },
  {
    key: 'whatsapp',
    label: 'WhatsApp',
    title: 'WhatsApp Chat',
    disableIcon: 'paper-plane',
    component: WhatsAppChat
  },
  {
    key: 'chatadmin',
    label: 'WhatsApp',
    title: 'WhatsApp Conversations',
    disableIcon: 'paper-plane',
    component: ChatAdmin
  },
  {
    key: 'pdfTest',
    label: 'pdfTest',
    disableIcon: 'file-pdf'
  },

  {
    key: 'login',
    label: 'Login'
  },
  {
    key: 'groups',
    label: 'Groups',
    title: 'Groups',
    disableIcon: 'paper-plane',
    component: Groups
  }
]

export default sidebarOptions;