export const styles = (theme) => ({
  root: {
    // width: 1000,
    // display: 'flex',
    // flex: '1',
    // alignItems: 'center',
    // justifyContent: 'center',
    // flexDirection: 'column',
    background: '#EBE6EC'
  },
  margin: {
    height: theme.spacing(3),
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 400,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 800,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
    minWidth: 75
  },
  topControls: {
    padding: 16,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  bottomControls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    flexShrink: 0,
    marginLeft: 16,
    minWidth: 250,
    textAlign: 'center',
  },
  sliderLabel: {
    minWidth: 50,
    paddingRight: '1em',
    fontSize: 12
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
    image: {
      height: '100%',
    },
  },
  uploadControl: {
    width: 120,
  }
})
