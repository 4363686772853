import React from 'react'
import { withRouter } from 'react-router-dom'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Card, Grid, DisplayItem, FormModal } from '../generic'
import moment from 'moment';
import { printCurrency } from '../../lib/helpers'
import Member from '../funeral/Member';

const EditModal = (props) => {
  const { size } = props
  return (
    <FormModal
      okText="Ok"
      cancelText="Back"
      width='90%'
      disableCancel={true}
      disableOk={true}
      size={size}
      componentProps={props}
    />
  )
}
export const GET_MEMBER = gql`
	query Member($id: String!) {
		member(id: $id) {
      id
      firstNames
      surname
      idNumber
      idType
      memberType
      coverAmount
      ageAtSignup
      DOB
      email
      cellNumbers
      income
      createdAt
      dead
      dateOfDeath
      relationship
    }
}`

let MemberInformation = (props) => {

  const { id, notForPolicyHolder, data, title, cancel, history, ...rest } = props
  const { firstNames,
    clientId,
    surname,
    idNumber,
    idType,
    ageAtSignup,
    DOB,
    email,
    cellNumbers,
    memberType,
    dead,
    dateOfDeath,
    createdAt
  } = data.member || {}

  // if (notForPolicyHolder && memberType === 'POLICY_HOLDER') {
  //   return <h2>Life claimed is the policy holder</h2>
  // }

  // console.log('MemberInformation', props)

  const goToClient = (idNumber) => {
    if (cancel) {
      cancel()
    }
    else {
      localStorage.setItem('searchString', idNumber);
      history.push(`/products/${idNumber}`)
    }
  }

  return (
    <>
      <Grid container justifyContent='flex-start' textAlign='center' spacing={2}>
        <Grid item xs={3} alignItems='center'>
          <EditModal Content={Member} noButton heading={`Change ${title || "Policy Holder"} Details`} text={title || "Life Covered"} id={id} refetch={GET_MEMBER} />
        </Grid>
      </Grid>

      <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
        <DisplayItem label="First Name" value={firstNames} />
        <DisplayItem label="Surname" value={surname} />
        <DisplayItem label="Id Number" value={`${idNumber ? idNumber : moment(DOB).format("Do MMM YYYY")}`} style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => goToClient(idNumber)} xs={1} />
        {/* <DisplayItem label="Id Number" value={`${idType && idType !== 'rsa_id' ? idType : ''} ${idNumber ? idNumber : moment(DOB).format("Do MMM YYYY")} `} style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => goToClient(idNumber)} xs={1} /> */}
        <DisplayItem label="Type" value={memberType} xs={2} />
        <DisplayItem label="Age@Signup" value={ageAtSignup ? ageAtSignup : ageAtSignup === 0 ? 0 : ' '} xs={1} />
        <DisplayItem type='mobile' label="Mobile" clientId={clientId} value={cellNumbers ? cellNumbers[0] ? cellNumbers[0] : '-' : ''} />
        <DisplayItem label="Email" value={email ? email : ' '} xs={email ? 2 : 1} />
        <DisplayItem label="Created" value={moment(createdAt).format("Do MMM YYYY")} />
        <DisplayItem label="DHA Dead" value={dead ? dead : '-'} />
        <DisplayItem label="DHA Date" value={dateOfDeath ? moment(dateOfDeath).format("Do MMM YYYY") : '-'} />
        {/* <DisplayItem label="Income" value={income ? printCurrency(income) : "-"} xs={2} /> */}
      </Grid>
    </>)
}

const graphqlLoadingHOC = Comp => props => {

  if (props.data.loading) {
    return <div />
    // return <ActivityIndicator />
  }

  if (props.data.error) {
    return <h1>{props.data.error.toString()}</h1>
  }

  //console.log('CreditLife graphqlLoadingHOC props ', props.data)

  return <Comp {...props} />
}
MemberInformation = withRouter(MemberInformation)
MemberInformation = graphqlLoadingHOC(MemberInformation)
MemberInformation = graphql(GET_MEMBER, {
  options: ({ match, id }) => ({ variables: { id: match && match.params ? match.params.id : id } })
})(MemberInformation)
export default MemberInformation;
