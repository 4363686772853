// export const RELATIONSHIPS = [
//   { value: 'FATHER', label: 'Father' },
//   { value: 'FATHER_IN_LAW', label: 'Father_In_Law' },
//   { value: 'MOTHER', label: 'Mother' },
//   { value: 'OTHER', label: 'Other' },
//   { value: 'GREAT_GRANDPARENTS', label: 'Great_Grandparents' },
//   { value: 'GREAT_GRANDPARENTS_IN_LAW', label: 'Grand_Parnets_in_Law' },
//   { value: 'GRANDPARENTS', label: 'Father_In_Law' },
//   { value: 'GRANDPARENTS_IN_LAW', label: 'Mother' },
//   { value: 'AUNT', label: 'Other' },
//   { value: 'UNCLE', label: 'Great_Grandparents' },
//   { value: 'BROTHER', label: 'Father' },
//   { value: 'BROTHER_IN_LAW', label: 'Father_In_Law' },
//   { value: 'SISTER', label: 'Mother' },
//   { value: 'SISTER_IN_LAW', label: 'Other' },
//   { value: 'FIRST_COUSIN', label: 'Great_Grandparents' },
//   { value: 'SON', label: 'Father' },
//   { value: 'SON_IN_LAW', label: 'Father_In_Law' },
//   { value: 'DAUGHTER', label: 'Mother' },
//   { value: 'DAUGHTER_IN_LAW', label: 'Other' },
//   { value: 'NEPHEW', label: 'Great_Grandparents' },
//   { value: 'NIECE', label: 'Father_In_Law' },
//   { value: 'FRIEND', label: 'Mother' },
//   { value: 'FAMILY', label: 'Other' },
//   { value: 'FAMILY_FRIEND', label: 'Other' },
//   { value: 'SPOUSE', label: 'Great_Grandparents' },
// ]

const RELATIONSHIPS = [
  'FATHER', 'FATHER_IN_LAW', 'MOTHER_IN_LAW', 'MOTHER', 'OTHER', 'GREAT_GRANDPARENTS', 'GREAT_GRANDPARENTS_IN_LAW', 'GRANDPARENTS', 'GRANDPARENTS_IN_LAW', 'AUNT', 'UNCLE', 'BROTHER', 'BROTHER_IN_LAW', 'SISTER_IN_LAW', 'SISTER', , 'FIRST_COUSIN', 'SON', 'SON_IN_LAW', 'DAUGHTER', 'DAUGHTER_IN_LAW', 'NEPHEW', 'NIECE', 'FRIEND', 'FAMILY_FRIEND', 'SPOUSE', 'GRANDSON', 'GRANDDAUGHTER'
]

export default RELATIONSHIPS
