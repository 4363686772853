import React from 'react'
import SalesTeamList from './SalesTeamList'
import SalesTeam from './SalesTeam'
import { Route, Switch } from 'react-router-dom'

const SalesTeams = () => (
  <div>
    <Route path='/sales-teams' exact component={SalesTeamList} />
    <Route path='/sales-teams/new' exact render={({ history }) => <SalesTeam history={history} mode={'add'} />} />
    <Route path='/sales-teams/:id/:edit?' render={({ match, history }) => <div><SalesTeam history={history} mode={match.params.edit ? 'edit' : 'display'} id={match.params.id} /></div>} />
  </div>
)

export default SalesTeams
