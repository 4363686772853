import React, { useState } from 'react'
import gql from 'graphql-tag'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import GraphQLTable from '../generic/GraphQLTable'
import { Card, Button } from '../generic'
import { printCurrency } from '../../lib/helpers';
import moment from 'moment';
import Numeral from 'numeral'
import { AuthConsumer } from 'react-auth-helper';
import 'numeral/locales/en-za.js'

// switch between locales
Numeral.locale('en-za')

const useStyles = makeStyles((theme) => ({
  footer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingTop: 21,
    paddingBottom: 21
  },
  button: {
    minWidth: 200,
    maxWidth: 200,
    textAlign: 'center',
    marginRight: 14
  },
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
}));

const config = (state) => {
return ({
  query: gql`
    query ClearanceCertificates  {
      clearancecertificates(limit: 10000) {
        id
        client {
          id
					firstNames
					surname
					idNumber
          idType
          email
          cellNumbers
				}
        paymentDay
        paymentBankAccountId
        debitOrderId
        # clearanceCertificate {
        #   id
        #   name
        #   file {
        #     id
        #     url
        #   }
        #   fromSimplicity
        #   verified
        # }
        state
        # signature {
        #  id
        #  url
        #}
        applicationDate
        previousDebtCounsellor {
          id
          fullName
        }
        certificateNumber
        amount
        amountPaid
        dcTransferStartedAt
        dcTransferCompletedAt
        dcTransferRejectedAt
        settlementLettersRequested
        signupSalesInteraction {
          id
          salesPerson {
            id
            first
            last
            email
          }
        }
        createdAt
        state
      }
    }

  `,
  dataset: data => data.clearancecertificates.filter(d=> !state || d.state === state),
  fields: {
    certificateNumber: {
      label: 'Certificate#'
    },
    'client.firstNames': {
      label: 'First_Names',
    },
    'client.surname': {
      label: 'Surname'
    },
    'client.idNumber': { label: 'Id_Number' },
    // clearanceCertificate: {
    //   label: '# Certificates',
    //   accessor: row => (row.clearanceCertificate ? row.clearanceCertificate.length : 0),
    //   filterType: 'dropdown'
    // },
    createdAt: {
      label: 'Sold',
      filter: 'daterange',
      type: 'date',
      Cell: ({ value }) => value ? moment(value).format('DD-MM-YYYY') : '',
    },
    'signupSalesInteraction.salesPerson': {
      label: 'Sold_By',
      filter: 'select',
      accessor: d => d.signupSalesInteraction ? d.signupSalesInteraction.salesPerson ? `${d.signupSalesInteraction.salesPerson.first} ${d.signupSalesInteraction.salesPerson.last}` : 'DIGITAL' : d.accountingIdentifier ? d.accountingIdentifier : d.createdBy ? d.createdBy.description : 'DIGITAL SALE',
    },
    state: {
      label: 'State',
      filter: 'select',
      Cell: ({ value }) => (value ? value : '-')
    }
  },
  defaultSorted: [{ id: 'createdAt', desc: true }]
  })
}

function ClearanceCertificateList({ history }) {
  const classes = useStyles();
  const [open, setOpen] = useState()
  const [state, setState] = useState(undefined)
  
  const theConfig = config(state)

  return (
    <AuthConsumer>
      {({ inGroup }) => (
        <Card>
          {/* <div style={{ position: 'absolute', paddingTop: 5, left: '48em' }} >
            <Button color="primary" size="small" onClick={() => setOpen(true)}>New</Button>
          </div> */}

          <div style={{ position: 'absolute', paddingTop: 5, left: '48em' }} >
            <Button color="primary" size="small" onClick={() => setState(state ? undefined : "AWAITING_DOCUMENT_VERIFICATION" )}> {state? "Show All Certificates": "Certificates Needing Validation" }</Button>
          </div>

          <GraphQLTable config={theConfig} onRowPress={cc => {
            console.log(`clearance-certificate`, { ...cc });
            history.push(`/clearance-certificate/${cc.id}/edit`)
          }
          } />

          <Dialog
            title="Create a new certificate for testing"
            open={open}
            onClose={() => setOpen(false)}
            scroll="paper"
            maxWidth='md'
            classes={{
              scrollPaper: classes.topScrollPaper,
              paperScrollBody: classes.topPaperScrollBody,
            }}
            aria-labelledby="funeral-dialog-title"
          >
            <DialogTitle id="simple-dialog-title">Enter Policy Number & Effective Date</DialogTitle>
            {/* <Search onOk={(funeralId, effectiveDate) => history.push(`/funeral/${funeralId}/edit/${effectiveDate}`)} /> */}
          </Dialog>
        </Card>
      )}
    </AuthConsumer>
  );
}

export default ClearanceCertificateList
