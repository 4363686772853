import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Modal, DisplayItem } from '../index'

const useStyles = makeStyles((theme) => ({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  button: {
    // color: '#046D80',
    padding: '0',
    '&:hover': {
      backgroundColor: '#046D80',
      color: '#fff',
    }
  },
}));


export default function FormModal(props) {
  const classes = useStyles()

  const { componentProps } = props
  const { size = 'small', disabled = false, displayItem, allowEdit = true, noButton, underlineColor, text, heading, title, dialogText, Content, fontSize = 21, fontWeight = 400, maxWidth = 'lg', fullWidth = true, icon = 'edit', ...theProps } = componentProps
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    console.log('close modal')
    setOpen(false);
  };

  return (
    <>
      {displayItem ? <>
        <DisplayItem label={heading} value={text} xs={2} onClick={() => setOpen(true)} />
        {allowEdit ? icon === 'add' ? <AddCircleOutlineIcon color='primary' /> : <EditIcon /> : ''}
      </>
        : noButton ?
          <div style={{ textTransform: 'capitalize', fontSize, fontWeight, textAlign: 'start' }}>
            {text}
            &nbsp;
            {allowEdit && <IconButton aria-label="edit" className={classes.button} onClick={() => setOpen(true)} >
              {icon === 'add' ? <AddCircleOutlineIcon style={{ fontSize }} /> : <EditIcon style={{ fontSize }} />}
            </IconButton>}
          </div>
          : <>
            {allowEdit ? <Button disabled={disabled} size={size} color="default" onClick={() => setOpen(true)}>{text}</Button> : <div />}
          </>
      }
      <Modal
        title={heading || title}
        dialogText={dialogText}
        open={open && !(allowEdit === false)}
        onClose={handleClose}
        onOk={handleClose}
        onCancel={handleClose}
        noButtons={true}
        scroll="paper"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
        aria-labelledby="form-dialog-title"
        maxWidth={maxWidth}
        fullWidth={fullWidth}
      >
        <Content afterSubmit={handleClose} cancel={handleClose} {...theProps} />
      </Modal>
    </>
  );
}