import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export default function Alert({ severity = 'info', message, onClose, children, vertical = 'bottom',
  horizontal = 'center' }) {
  return (
    <Snackbar open={true} autoHideDuration={6000} onClose={onClose} anchorOrigin={{ vertical, horizontal }} >
      <MuiAlert elevation={6} variant="filled" severity={severity}>
        {message}
        {children}
      </MuiAlert>
    </Snackbar >)
}
