export const DOCUMENT_TYPES = [
  { label: 'Bank Statement', value: 'BANK' },
  { label: 'COB', value: 'COB' },
  { label: 'Credit Life Advice Record', value: 'CREDIT_LIFE_CAR' },
  { label: 'Credit Life Schedule', value: 'CREDIT_LIFE_SCHEDULE' },
  { label: 'DebitOrderCancellation & POASBSA', value: 'POASBSA' },
  { label: 'ID', value: 'ID' },
  { label: 'ID and Payslip', value: 'ID&PAYSLIP' },
  { label: 'Funeral Schedule', value: 'FUNERAL_SCHEDULE' },
  { label: 'Form 16A', value: 'F16A' },
  { label: 'Form16A Combined', value: 'F16ACOMBINED' },
  { label: 'Form16A + StarterPack', value: 'F16ASTARTER' },
  { label: 'Loan Statement', value: 'LOAN_STATEMENT' },
  { label: 'Novation Document', value: 'NOVATION_DOCUMENT' },
  { label: 'Passport', value: 'PASSPORT' },
  { label: 'Payslip', value: 'PAYSLIP' },
  { label: 'Power of Attorney', value: 'POWEROFATTORNEY' },
  { label: 'Proof Of Address', value: 'ADDRESSPROOF' },
  { label: 'Selfie', value: 'SELFIE' },
  { label: 'T&C', value: 'Terms and Conditions' },
  { label: 'Vaccination Certificate', value: 'VACCINATION' },
  { label: 'Other', value: 'OTHER' }
]