import React from 'react'
import { v4 as uuidv4 } from 'uuid';
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo'
import { GraphQLFormProvider, FormField, FieldValue } from 'react-form-helper'
import ClaimBeneficiary from './ClaimBeneficiary'
import { BuildLayout, Card, renderInput, renderDisplay, FormModal, ActivityIndicator } from '../generic';
import { claimInformation } from './ClaimAdmin'

const EditModal = (props) => {
  const { size } = props || 'small'
  return (
    <FormModal
      okText='Ok'
      cancelText='Back'
      width='100%'
      style={{ top: 10 }}
      disableCancel={true}
      disableOk={true}
      size={size}
      componentProps={props}
    />
  )
}

const FUNERAL = gql`
query FUNERAL($id: String!) {
  funeral(id: $id) {
    id
    livesCovered {
      id
      firstNames
      surname
      idNumber
      memberType
      cellNumbers
      email
      relationship
    }
    beneficiaries {
      id
      payToId
      payTo {
        id
        firstNames
        surname
        relationship
      }
    }
  }  
}`

const fields = {
  'beneficiaries[0].payToId': {
    type: 'select',
    options: [],
    label: 'Select a Beneficiary',
    validate: (value) => {
      if (!value) {
        return 'This is Required'
      }
    },
  }
}

const config = {
  name: 'claimbenefit',
  query: gql`
  query ClaimData($id: String!) {
    claim(id: $id) {
      id
      beneficiaries {
        id
        percentage
        payToId
        payTo {
          id
          idNumber
          clientId  
          firstNames
          surname
          relationship
          cellNumbers
        }
      }
    }
  }`,
  updateMutation: gql`
  mutation UpdateClaim( $id: String!, $input: UpdateClaimInput!) {
      updateClaim(id: $id, input: $input) {
        id
        beneficiaries {
          id
          percentage
          payTo {
            id
            idNumber
            clientId
            firstNames
            surname
            relationship
            cellNumbers
          }
        }
       }
      }`
}

function FormLayout(props) {
  const { id, afterSubmit, cancel, mode, data, funeral, formName } = props || {}
  const { beneficiaries: claimBeneficiaries } = data.claim || {}
  const { livesCovered, beneficiaries } = funeral || {}
  const options = []

  // console.log('claimBeneficiaries', claimBeneficiaries, beneficiaries)

  claimBeneficiaries && claimBeneficiaries.length > 0 && claimBeneficiaries.filter(member => member.payToId).forEach(member => options.push({
    value: member.payTo.id, label: `${member.payTo.firstNames} ${member.payTo.surname} - CLAIM BENEFICIARY`, key: 'BENEFICIARY'
  }))

  beneficiaries && beneficiaries.length > 0 && beneficiaries.filter(member => member.payToId).forEach(member => options.push({
    value: member.payTo.id, label: `${member.payTo.firstNames} ${member.payTo.surname} - POLICY BENEFICIARY`, key: 'BENEFICIARY'
  }))

  livesCovered && livesCovered.length > 0 && livesCovered.filter(m => m.memberType !== "BENEFICIARY").forEach(member => options.push({
    value: member.id, label: `${member.firstNames} ${member.surname} - ${member.memberType}`, key: member.memberType
  }))

  fields['beneficiaries[0].payToId'].options = options.sort((a, b) => (a.key < b.key) ? 1 : -1)

  const fieldNames = Object.keys(fields)

  return (
    <Card>
      <h3>Either define a new claim beneficiary or select from the lives covered on the policy</h3>
      <EditModal Content={ClaimBeneficiary} heading='Create Claim Beneficiary' text='Add Beneficiary' {...props} mode={"add"} claimId={id} beneficiaries={beneficiaries} afterSubmit={afterSubmit} />
      <br />
      <BuildLayout formName={formName} fieldNames={fieldNames} mode={mode} fieldConfig={fields} direction='column' spacing={2} cancel={() => cancel()} />
    </Card>
  );
}

let NewClaimBeneficiary = (props) => {
  const { id, funeralId, cancel, afterSubmit, setBeneficiaries } = props
  const { data, loading, error } = useQuery(FUNERAL, { variables: { id: funeralId } })

  if (loading) {
    return <ActivityIndicator />
  }

  return (
    <div>
      <GraphQLFormProvider
        mode={"edit"}
        id={id}
        fields={fields}
        {...config}
        initialValues={{ 'beneficiaries[0].percentage': 100 }}

        afterSubmit={(props) => {
          // console.log('beneficiary afterSubmit', props);
          cancel();
          setBeneficiaries(props.data.updateClaim.beneficiaries)
        }}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout {...props} formName={config.name} afterSubmit={afterSubmit} cancel={cancel} funeral={data.funeral} />}
        mapInputVariables={input => {
          // console.log('ClaimBeneficiary mapInputVariables ', input)
          const { beneficiaries } = input
          const cleanBeneficiaries = beneficiaries.map(b => {
            let { payTo, ...clean } = b
            if (!clean.id) {
              clean.id = uuidv4()
            }
            return clean
          })
          // console.log('ClaimBeneficiary mapInputVariables out', { input: { beneficiaries: cleanBeneficiaries } })
          return { input: { beneficiaries: cleanBeneficiaries } }
        }}  // defining the input to use the input needed in the update mutation. 
        DisplayFormLayout={FormLayout}
        refetchQueries={[{ query: claimInformation, variables: { id } }]}
      />
    </div>
  )
}

export default NewClaimBeneficiary
