import React from 'react'
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

export default function MyMenu({ menuItems }) {
  console.log('MyMenu ', menuItems)
  return <MenuList>
    {menuItems && menuItems.length > 0
      ? menuItems.map(item => (
        <MenuItem onClick={item.onClick} key={item.key}>
          {item.value}
        </MenuItem>
      ))
      : <div />}
  </MenuList >
}
