import React from 'react';
import gql from 'graphql-tag';
import { GraphQLTable, Card } from '../generic';
import { printCurrency } from '../../lib/helpers'
import { IdTypes } from '../../variables/IdTypes'
import { AuthConsumer } from 'react-auth-helper';
import moment from 'moment';

const getLabel = (value) => {
  if (value) {
    const label = IdTypes.find(x => x.value === value)
    return label ? label.label : value
  }
  return null
}

const config = {
  query: gql`{
    savingss (limit:10000) {
      id
      client {
        id
        firstNames
        surname
        idType
        idNumber
        email
        cellNumbers
      }
      totalUnits {
        unitType
        amount
        value
      }
      updatedAt
      }
    }
  `,
  dataset: data => data.savingss,
  fields: {
    'client.firstNames': {
      label: 'First Name',
     },
     'client.surname': {
      label: 'Surname',
    },
    "client.cellNumbers[0]": {
      label: "Cell",
    },
    "client.email": {
      label: "email"
    },
    "client.idType": {
      label: "Nationality",
      accessor: row => row.client && row.client.idType && getLabel(row.client.idType) ,
    },
    "client.idNumber": {
      label: "ID Number"
    },
    totalUnits: {
      label: "Total",
      accessor: row => row.totalUnits.reduce((total, u) => total + (u.value || 0), 0),
      Cell: ({row}) => row.original ? printCurrency( row.original.totalUnits.reduce((total, u) => total + (u.value || 0), 0)) : "R 0,00" 
    },
    liquidUnits: {
      label: "Liquid Fund",
      accessor: row => row.totalUnits.filter(u => u.unitType === "LIQUID_FUND").reduce((total, u) => total + (u.value || 0), 0),
      Cell: ({row}) => row.original ? printCurrency(row.original.totalUnits.filter(u => u.unitType === "LIQUID_FUND").reduce((total, u) => total + (u.value || 0), 0)): "R 0,00"
    },
    cashUnits: {
      label: "Cash Fund",
      accessor: row => row.totalUnits.filter(u => u.unitType === "CASH_FUND").reduce((total, u) => total + (u.value || 0), 0),
      Cell: ({row}) => row.original ? printCurrency(row.original.totalUnits.filter(u => u.unitType === "CASH_FUND").reduce((total, u) => total + (u.value || 0), 0)): "R 0,00"
    },
    updatedAt: {
      label: 'Updated',
      Cell: ({ value }) => moment(value).format('DD-MM-YYYY'),
      filter: 'daterange'
    },
  },
  defaultSorted: [{ id: 'UpdatedAt', desc: false }]
};

const SavingsList = ({ history }) => {
  console.log('SavingsList called')
  return (
  <AuthConsumer>
    {({ inGroup }) => (
      <Card>
          <GraphQLTable
            config={config}
            onRowPress={employee => history.push(`/savings/${employee.id}`)}
        />
      </Card>
    )}
  </AuthConsumer>
  )
};

export default SavingsList;