import React from 'react';
import gql from 'graphql-tag';
import GraphQLTable from '../generic/GraphQLTable';
import { Card, Button, Modal, Grid } from '../generic'
import moment from 'moment';
import { useState } from 'react';
import TextField from '@material-ui/core/TextField'
import NewGroup from './NewGroup';

export const getAllGroups = gql`
  {
    groups(limit: 30000) {
      id
      name
      postalCode
      roleHolders {
        role
        idDocumentId
      }
      consultant {
        id
        first
        last
      }
      products {
        state
        groupProductNumber
        oldGroupProductNumber
        funerals {
          id
          policyNumber
          livesCoveredIds
        }
      }
    }
  }
`



const config = {
  query: getAllGroups,
  dataset: (data) => data.groups,
  fields: {
    name: { label: 'Group Name' },
    consultantName: {
      label: 'Consultant',
      accessor: (row) => (row.consultant ? (`${row.consultant.first} ${row.consultant.last}`) : null)
    },
    numRoleHolders: {
      label: '# Role holders',
      accessor: (row) => (row.roleHolders ? row.roleHolders.length : null)
    },
    numMembers: {
      label: '# Members',
      accessor: (row) => row.products && row.products[0] && row.products[0].funerals.length
    },
    livesCovered: {
      label: '# Lives Covered',
      accessor: (row) => row.products && row.products[0] && row.products[0].funerals.reduce((total, funeral) => total + ((funeral.livesCoveredIds || []).length), 0)
    },
    'products[0].groupProductNumber': { label: 'Group Product Number' },
    'products[0].oldGroupProductNumber': { label: 'Old Group Product Number' },
    postalCode: { label: 'Postal Code' },
    'products[0].state': { label: 'State' }
  },
  defaultSorted: [{ id: 'name' }]
}

const GroupsList = ({history, mode}) => {
  const [open, setOpen] = useState(false);
  
  return (
    <Card>
      {console.log('history is ', history)}
      <h1>
        All Groups
        <Button style={{ marginLeft: '80%', marginTop: 7 }} color="primary" onClick={() => setOpen(true)}>
          Add Group
        </Button>
      </h1>
      <NewGroup open={open} setOpen={setOpen} />
      <GraphQLTable config={config} onRowPress={(groups) => history.push(`/groups/${groups.id}`)} />
    </Card>
  )
}

export default GroupsList;