import React, { Component } from 'react';
import gql from 'graphql-tag'
import GraphQLTable from '../generic/GraphQLTable';
import 'font-awesome/css/font-awesome.css'
import {Card} from '../generic'
import Approve from './Approve'
import ApprovalModal from './ApprovalModal';
import moment from 'moment'

const config = {
  query: gql`
    {
      approvals {
        id
        associatedClaim {
          claimNumber
        }
        canApprove
        approvalType
        approved
        rejected
        cancelled
        cancelledAt
        description
        approvedBy {
          description
        }
        approvedAt
        rejectedBy {
          description
        }
        rejectedAt
        approvers {
          description
        }
        createdAt
        updatedAt
      }
    }
  `,
  dataset: data => data.approvals,
  fields: {
    "associatedClaim.claimNumber": {
      label:"Claim"
    },
    approvalType: {
      label: "Type"
    },
    description: {
      label: "Description"
    },
    updatedAt: {
      label:"When",
      Cell: ({ row }) => row.original.updatedAt ? moment(row.original.updatedAt).format('DD-MM-YYYY') : moment(row.original.createdAt).format('DD-MM-YYYY'),
    },
    canApprove: {
      label: "Status",
      Cell: ({row}) => <Approve id={row.original.id} />
    }
  },
  defaultSorted: [{id: 'updatedAt', desc: true}]
};

class ApprovalList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false
    }
  }

  openModal = (approval) => {
    this.setState({ visible: true, approval })
  }

  closeModal = () => {
    this.setState({ visible: false })
  }

  render() {
    return (
      <Card title="Approvals">
        <GraphQLTable config={config} onRowPress={(approval) => this.openModal(approval)} />
        <ApprovalModal visible={this.state.visible} approval={this.state.approval} closeModal={this.closeModal} />
      </Card>
    )
  }
}

export default ApprovalList;