import styled from '@emotion/styled'

let LabelContainer = styled.div`
  padding: 6px;
  padding-right: 16px;
  padding-left: 16px;
  background: ${props => props.inverted ? "none" : `${props.theme.colors[props.color || 'primary']}${props.textColor && props.color && props.color === props.textColor ? "32" : "80"}`};
  ${props => props.inverted && `border: 1px solid ${props.theme.colors[props.color || 'primary']}19`};
  border-radius: 32px;
  min-width: 96px;
  text-align: center;
`
export { LabelContainer }
