import React from 'react';
import ClearanceCertificateList from './ClearanceCertificateList';
import ClearanceCertificateInfo from './ClearanceCertificateInfo';
import { Route, Switch } from 'react-router-dom';

const ClearanceCertificates = () => (
  <div>
    <Switch>
      <Route path='/clearance-certificate' exact component={ClearanceCertificateList} />
      <Route path='/clearance-certificate/:id/:edit'
        render={({ match, history }) => {
          return (
            <div>
              <ClearanceCertificateInfo mode={'edit'} id={match.params.id} history={history} />
            </div>
          )
        }}
      />
    </Switch>
  </div>
)

export { ClearanceCertificateList }

export default ClearanceCertificates