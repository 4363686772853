import React, { useEffect } from 'react';
import {Route, Switch, Redirect } from 'react-router-dom';
import Login from './components/Login';
import { sidebarOptions } from './components/Sidebar';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { v4 as uuidv4 } from 'uuid';
 import Unavailable from './components/Unavailable';

const USER_INFO = gql`{
	me {
		id
		first
    last
	}
  currentUser {
    id
    roles
		email
    userGroups {
      id
      name
      identifier
    }
  }
}`
 
function PrivateRoutes() {
  const { data } = useQuery(USER_INFO) || {}
  const { currentUser } = data || {}
  useEffect(() => {
  }, [currentUser]);

  const inGroup = (groupName) => {
    return currentUser && currentUser.userGroups && currentUser.userGroups.map(g => g.identifier).includes(groupName)
  }

  const getMenuItem = ({ singleOption }) => {
    const { key, component } = singleOption;
    if (component) {
      return (
        <Route key={uuidv4()} path={`/${key}`} component={component} />
      )
    }
  };


  return (

    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/unavailable" component={Unavailable} />
      {sidebarOptions.filter(option => { return inGroup(option.key) }).map(singleOption => getMenuItem({ singleOption }))}
      <Route
        path="/logout"
        render={({ history }) => {
          localStorage.removeItem('accessToken');
          history.push('/login');
        }}
      />
      <Route path='/' component={Unavailable}/>
    </Switch>

  )
}


export default PrivateRoutes
