import React from 'react'
import { MaterialTable } from '../generic'
import { format } from 'date-fns'

const cols = [
  { Header: 'Status After', accessor: 'statusAfter' },
  {
    Header: 'Date',
    accessor: 'updatedAt',
    Cell: ({ value }) => value ? format(value, 'yyyy-LL-dd HH:mm bb') : ''
  }
]

const PolicyStatusHistory = ({ history }) => {
  const pagination = history && history.length > 10 ? { showSizeChanger: true, responsive: true } : false
  const data = history && history.length > 0 ? history.slice().sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1) : history
  // console.log('PolicyStatusHistory ', data)

  return (
    <>
      <div style={{ fontVariant: 'small-caps', fontSize: 21, fontWeight: 400 }}>
        Policy Status History
      </div>
      <MaterialTable columns={cols} data={data} />
    </>
)}

export default PolicyStatusHistory
