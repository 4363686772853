import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'

import { Button, Modal, Grid, DatePicker, DisplayItem } from '../generic';
import { printCurrency } from '../../lib/helpers';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    fontSize: 21,
    paddingBottom: 14,
  },
  table: {
    minWidth: 650,
  },
  buttonRow: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingTop: 14,
    paddingBottom: 14,
  }
});

function Balances({ rows }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="balance table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="right">Balance</TableCell>
            <TableCell align="right">Previous Balance</TableCell>
            <TableCell align="right">Previous Balance Date</TableCell>
            <TableCell align="right">Balance Source</TableCell>
            <TableCell align="right">Estimated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            const { date, balance, prevBalance, prevBalanceDate, balanceSource, estimated } = row
            return (
              <TableRow key={date}>
                <TableCell component="th" scope="row">{date ? moment(date).format('DD-MM-YYYY') : '-'} </TableCell>
                <TableCell align="right">{balance}</TableCell>
                <TableCell align="right">{printCurrency(prevBalance)}</TableCell>
                <TableCell align="right">{prevBalanceDate ? moment(prevBalanceDate).format('DD-MM-YYYY') : '-'}</TableCell>
                <TableCell align="right">{balanceSource}</TableCell>
                <TableCell align="right">{estimated}</TableCell>
              </TableRow>
            )
          }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


const Content = ({ loan, currentBalance, setCurrentBalance, cancel, afterSubmit }) => {
  const classes = useStyles();
  const [newBalance, setNewBalance] = useState(currentBalance);
  const [balanceDate, setBalanceDate] = useState(moment());

  let { obligation } = loan
  let balances = obligation.balances || []

  return (<>
    <Grid container className={classes.root} spacing={1} >
      <Grid item xs={3}>
        <TextField disabled variant="outlined" label="Creditor" value={obligation.clientCreditor.description} />
      </Grid>
      <Grid item>
        <TextField disabled variant="outlined" label="Reference" value={obligation.reference} />
      </Grid>
      <Grid item>
        <TextField variant="outlined" label="Current Balance" value={newBalance} onChange={(e) => setNewBalance(e.target.value)} />
      </Grid>
      <Grid item xs={3}>
        <DatePicker id="claimdatepicker" disableFuture autoOk required inputVariant="outlined" label="Balance Date" value={balanceDate} onChange={setBalanceDate} />
      </Grid>
    </Grid>

    <Balances rows={balances.slice().sort((a, b) => (a.date > b.date) ? -1 : 1)} />

    <Grid container className={classes.buttonRow} spacing={2} >
      <Grid item>
          <Button color="secondary" onClick={cancel}>
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button color="primary" disabled={newBalance === currentBalance} onClick={() => {
          setCurrentBalance(newBalance)
          afterSubmit(newBalance, balanceDate.valueOf())
        }}>
          Save
        </Button>
      </Grid>
    </Grid>
  </>
  );
};


export default function ChangeCurrentBalance(props) {

  const { loan, currentBalance, rows, changeBalance, setCurrentBalance, showCurrentBalance, setShowCurrentBalance, disableOk = true, disableCancel = true, style, width = "75%", size = "small", ...rest } = props
  const { obligation } = loan

  const handleClose = (balance, balanceDate) => {
    const newObligation = { ...obligation, currentBalance: balance }
    const row = { ...loan, obligation: newObligation }

    console.log('changeCurrentBalance', balance, balanceDate)
    
    changeBalance(row, balanceDate)
    setShowCurrentBalance(false);
  };

  const { } = props
  return (
    <Fragment>
      <Modal
        title='Edit Current Balance'
        open={showCurrentBalance}
        width={"75%"}
        closeable={false}
        onOk={handleClose}
        onClose={handleClose}
        onCancel={() => { setShowCurrentBalance(false) }}
        noButtons
      >
        <Content cancel={() => setShowCurrentBalance(false)} afterSubmit={handleClose} loan={loan} currentBalance={currentBalance} setCurrentBalance={setCurrentBalance} rows={rows} />
      </Modal>
    </Fragment>
  );
}