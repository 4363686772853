import React, { useState } from 'react';
import gql from 'graphql-tag'
import { useConfirm } from "material-ui-confirm";
import EditIcon from '@material-ui/icons/Edit';
import { creditLifeInformation } from './CreditLife'
import NextOfKin from './NextOfKin'
import EditCreditLife from './EditCreditLife'
import EditEmployer from './EditEmployer'
import { Card, Grid, DisplayItem, Modal, FormModal } from '../generic'

const EditModal = (props) => {
  const { size } = props || 'small'
  return (
    <FormModal
      okText='Ok'
      cancelText='Back'
      width='100%'
      disableCancel={true}
      disableOk={true}
      size={size}
      componentProps={props}
    />
  )
}

const toggleCompanyBlack = gql`
	mutation($input: UpdateCompanyInput!, $id: String!) {
		updateCompany(id: $id, input: $input) {
      id
      name
			basicListed
		}
	}
`;

const createMutation = gql`
  mutation($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
      name
      basicListed
    }
  }
`

const UPDATE_CREDIT_LIFE = gql`
	mutation($input: UpdateCreditLifeInput!, $id: String!) {
		updateCreditLife(id: $id, input: $input) {
      id
		}
	}
`;

const UPDATE_CLIENT = gql`
	mutation($input: UpdateClientInput!, $id: String!) {
		updateClient(id: $id, input: $input) {
      id
      firstNames
      surname
      idType
      passportExpiresDate
      email
      cellNumbers
		}
	}
`;

const employers = (companys) => companys.map(comp => comp.name).sort((a, b) => (a <= b ? -1 : 1))

let Information = (props) => {
  const confirm = useConfirm();
  const { id, clientData, nextOfKin, employer, employmentStatus, debtCounselling, actionDay, paymentDay, policyStatus, reason, policyNumber, updatedAt, riskPaidUntil, productType, companys, basicListed, setBasicListed } = props
  const { payTo, isNextOfKin } = nextOfKin || {}
  const { id: beneficiaryId,
    firstNames,
    surname,
    idType,
    idNumber,
    cellNumbers,
    email,
    relationship } = payTo || {}

  // const [basicListed, setBasicListed] = useState(props.basicListed || false)
  const [onHazardList, setOnHazardList] = useState(props.onHazardList || false)
  const [open, setOpen] = useState(false);

  const openMarket = productType === 'OPEN_MARKET'
  const RMA = productType === 'RMA'
  const isCreditLifeProduct = productType === 'CREDIT_LIFE'
  const creditProviderPolicy = productType === 'CREDIT_PROVIDER'

  const handleClick = () => {
    confirm({
      description: basicListed ? " Remove company from Basic list? " : "  Add company to Basic list? ",
      title: `Basic Listed`,
      cancellationButtonProps: { variant: 'contained', color: 'default' }, confirmationButtonProps: { variant: 'contained', color: 'primary' }
    }).then(() => {
      // props.toggleBasicList()
      setBasicListed(!basicListed)
      toggleBlack() // need to add change of terms based on this as well
    })
  }

  {/* <EditModal Content={EditCreditLife} small heading="Change Payment Day" text={paymentDay ? paymentDay : actionDay ? actionDay : 25} id={id} refetch={creditLifeInformation} noButton /> */ }


  const updateClient = (input) => {
    props.client
      .mutate({
        mutation: UPDATE_CLIENT,
        variables: {
          input: { ...input },
          id: clientData.id
        }
      })
      .then(res => {
        console.log('update client result is ', res);
      })
      .catch(err => {
        // TODO: Improve error handling
        // statusMessage.info('Client update failed ', 2)
        console.log('update client error is ', err);
      });
  }

  const updateCreditLife = (input) => {
    props.client
      .mutate({
        mutation: UPDATE_CREDIT_LIFE,
        variables: {
          input,
          id
        },
        refetchQueries: [{ query: creditLifeInformation, variables: { id: props.id } }]
      })
  }

  const toggleBlack = () => {
    let name = props.employer.companyName
    let companyList = companys

    let thisCompany = companyList.find((element) => {
      return element.name === name;
    });


    if (thisCompany) {
      let blackListToggle = !thisCompany.basicListed;

      statusMessage.loading(blackListToggle ? 'Adding ' + thisCompany.name + ' to basic list' : 'Removing ' + thisCompany.name + ' from basic list', 2)
      props.client
        .mutate({
          mutation: toggleCompanyBlack,
          variables: {
            input: {
              basicListed: blackListToggle
            },
            id: thisCompany.id
          },
          refetchQueries: [{ query: creditLifeInformation, variables: { id: this.props.id } }]
        })
        .then(res => {
          statusMessage.info(blackListToggle ? 'Company added to basic list' : 'Company removed from basic list', 1)
          statusMessage.info('Policy being requoted', 2)
          return true;
        })
        .catch(err => {
          // TODO: Improve error handling
          statusMessage.info('Company basic list update failed with error: ' + err.toString(), 2)
          return false;
        });
    } else {
      if (name) {

        props.client
          .mutate({
            mutation: createMutation,
            variables: {
              input: {
                name: name,
                basicListed: true
              }
            }
          })
          .then(res => {
            return true;
          })
          .catch(err => {
            // TODO: Improve error handling
            console.log('error=', err);
            return false;
          });
      }
    }
  };

  return (
    <>
      <h2>{(openMarket || isCreditLifeProduct)
        ? <EditModal Content={EditEmployer} heading="Credit Life Information" text="Credit Life Information" id={clientData.id} creditLifeId={id} refetch={creditLifeInformation} noButton employers={employers(companys)} />
        : 'Credit Life Information'}</h2>

      <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
        <DisplayItem onClick={() => employer ? handleClick() : ''} label="Employer" value={employer ? `${employer.companyName} ${basicListed ? '*Basic*' : ''}` : ''} xs={3} />
        <DisplayItem label="Occupation" value={employer ? employer.occupation : "-"} xs={2} />
        <DisplayItem label="Full/Part" value={employer != null ? employer.isPartTime ? 'Part Time' : 'Full Time' : '-'} xs={1} />
        <DisplayItem label="Employment Status" value={employmentStatus ? employmentStatus.replace(/_/g, ' ') : '_'} xs={2} />
        <DisplayItem type='mobile' clientId={clientData.id} label="Work Phone" value={clientData.workPhone ? clientData.workPhone : ' '} xs={clientData.workPhone ? 2 : 1} />
        {(openMarket || RMA || creditProviderPolicy) && <><DisplayItem onClick={() => setOpen(true)} style={{ fontSize: 12, cursor: 'pointer' }} label="Payment Day" value={paymentDay ? paymentDay : actionDay} /><EditIcon style={{ fontSize: 12, cursor: 'pointer' }} onClick={() => setOpen(true)} />  </>}
        {debtCounselling && <> {
          debtCounselling.proposalStatus
            ? <DisplayItem label="DC Status" value={debtCounselling.proposalStatus.toUpperCase()} xs={2} />
            : '-'}
        </>}
        {debtCounselling && <> {
          debtCounselling.debtCounsellor && debtCounselling.debtCounsellor.shortName.length > 1
            ? <DisplayItem label="DC Status" value={debtCounselling.debtCounsellor.shortName} xs={2} />
            : '-'}
        </>}
      </Grid>

      <h2><EditModal Content={NextOfKin} noButton heading="Define the Next Of Kin" text="Next Of Kin" mode={nextOfKin ? "edit" : "add"} creditLifeId={id} id={beneficiaryId} kin={isNextOfKin} /></h2>
      <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
        <DisplayItem label="Name" value={`${firstNames ? firstNames : 'No next of kin defined'} ${surname ? surname : ' '}`} xs={2} />
        <DisplayItem type='mobile' label="Mobile" clientId={clientData.id} value={cellNumbers && cellNumbers.length > 0 ? cellNumbers[0] : ' '} xs={2} />
        <DisplayItem label="Email" value={email ? email : ' '} xs={email ? 2 : 1} />
        <DisplayItem label="Relationship" value={relationship ? relationship : ' '} xs={relationship ? 2 : 1} />
      </Grid>

      <Modal
        title="Payment Day"
        open={open}
        onClose={() => setOpen(false)}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        noButtons={true}
      >
        <EditCreditLife refetch={creditLifeInformation} afterSubmit={() => setOpen(false)} cancel={() => setOpen(false)} id={id} />
      </Modal>
    </>
  )
}

export default Information;