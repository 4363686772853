import React from 'react'
import CreditLifeList from './CreditLifeList'
import CreditLife from './CreditLife'
import OpenMarketPrompt from './OpenMarketPrompt'
import OpenMarket from './OpenMarket'
import { Route } from 'react-router-dom'

const CreditLifes = () => (
  <div>
    <Route path='/credit-life' exact component={CreditLifeList} />
    <Route path='/credit-life/openmarket' exact component={OpenMarketPrompt} />
    <Route path='/credit-life/openmarket/new/:id/:idNumber' exact component={OpenMarket} />
    <Route path='/credit-life/:id/edit' exact component={CreditLife} />

  </div>
)

export default CreditLifes
