export const CLI_PAYMENT_TYPES = [
  "DO",
  "HYPHEN",
  "DEBI_CHECK",
  "EASYPAY",
]

export const FUNERAL_PAYMENT_TYPES = [
  "DO",
  "DEBI_CHECK",
  "EASYPAY",
  "MONEYMARKET"
]

//full list 
// export const PAYMENT_TYPES = [
//   "PAY_AT",
//   "NAEDO",
//   "DO",
//   "FROM_SAVINGS",
//   "PERSAL",
//   "HYPHEN",
//   "DEBIT_ORDER",
//   "EFT",
//   "SDV",
//   "ONEDAY",
//   "TWODAY",
//   "SAMEDAY",
//   "DEBI_CHECK",
//   "EMPLOYER",
//   "EASYPAY",
//   "MONEYMARKET"
// ]