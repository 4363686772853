import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { GraphQLFormProvider, FormField } from 'react-form-helper'
import {withRouter} from 'react-router'
import { connect } from 'react-redux'
import { isInvalid, Field } from 'redux-form'
import { renderInput, renderDisplay, Card, Grid, Button } from '../generic';

const config = {
  name: 'clientobligation',
  fields: {

    inceptionDate: {
      type: 'date',
      label: 'Inception *',
      validate: (value) => {
        if (!value) {
          return 'Enter a date'
        }
      }
    },
    reference: {
      type: 'text',
      label: 'Reference *',
      validate: (value) => {
        if (!value) {
          return 'Reference is required'
        }
      }
    },
    accountCode: {
      type: 'text',
      label: 'Type'
    },
    currentBalance: {
      type: 'text',
      label: 'Loan Value',
      validate: (value) => {
        if (!value ) {
          return 'Enter a value'
        }
      }
    },
    "originalPayment.rate": {
      type: 'text',
      label: 'Interest%',
      validate: (value) => {
        if (!value )  {
          return 'Rate is required'
        }
      }
    },
    shortTermInsurance: {
      type: 'text',
      label: 'STI Previous Premium'
    },
    creditLifeInsurance: {
      type: 'text',
      label: 'CL Previous Premium'
    },
    insuranceAmount: {
      type: 'text',
      label: 'Previous Premium'
    }

  },
  query: gql`
    query ClientObligation($id: String!) {
      clientobligation(id: $id) {
        id
        reference
        accountCode
        inceptionDate
        currentBalance
        insuranceAmount
        creditLifeInsurance
        shortTermInsurance
        creditLifeInsurance
        removedAt
        originalPayment {
          installment
          rate
        }
      }
	}
  `,
  updateMutation: gql`
    mutation UpdateClientObligation($input: UpdateClientObligationInput!, $id: String!) {
      updateClientObligation(id: $id, input: $input) {
      id
      reference
      accountCode
      inceptionDate
      currentBalance
      insuranceAmount
      creditLifeInsurance
      shortTermInsurance
      creditLifeInsurance
      removedAt
      originalPayment {
        installment
        rate
      }
      removedAt
    }
    }
  `,

  addMutation: gql`
    mutation CreateClientObligation($input: CreateClientObligationInput!) {
      createClientObligation(input : $input) {
      id
    }
    }
  `
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fall UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log('There was an error ', error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

let SubmitButton = ({ invalid, submit }) => (
  <Button size='large' color="primary" style={{ display: 'none' }} disabled={invalid} onClick={() => { submit() }}>
    Save
  </Button>
)

const mapStateToProps = (state) => ({
  invalid: isInvalid('clientobligation')(state)
})

SubmitButton = connect(mapStateToProps)(SubmitButton)


class FormLayout extends Component {

  buildRowsandColumns(fieldNames) {
    var rows = []
    var cols = []

    // NOTE: the value of the span * rowCount, must be 24
    var span = 4
    var rowCount = 4
    var fieldCount = 1

    fieldNames.forEach((field, index) => {

        cols.push(
          <Grid item xs={4} style={{ display: 'block' }}>
          <FormField key={index} name={field} />
          </Grid>
      )

      if (((fieldCount) % rowCount) == 0 || index === fieldNames.length - 1) {
      rows.push(
        <Grid container spacing={1} > {cols}</Grid>)
        cols = []
      }
        fieldCount++

    })
    return rows
  }

  render() {
    const { mode, setMode, submit } = this.context;
    const id = this.props.match.params.id

    return (
      <Card>
        <h1>{mode === "edit" ? "Edit" : "New "} Client</h1>

        <br /><br />

        {this.buildRowsandColumns(Object.keys(config.fields)
          .filter(fieldName => (mode) === 'edit' || !config.fields[fieldName].noAdd))
        }

        <Grid container style={{ marginTop: 21 }} >
          <Grid item xs={2}>
            * Required Field <br/>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 21 }} justifyContent="flex-end" spacing={1}>
          <Grid item xs={1}>
            <Button size='large' style={{ display: 'none' }} onClick={() => { this.props.onDone() }}>
              Back
            </Button>
          </Grid>
          <Grid item xs={1}>
            <SubmitButton   submit={submit} history={this.props.history} />
          </Grid>
        </Grid>
      </Card>
    );
  }
}


FormLayout.contextTypes = {
  mode: PropTypes.string,
  setMode: PropTypes.func,
  submit: PropTypes.func,
  invalid: PropTypes.bool
};
FormLayout = withRouter(FormLayout)


class ClientObligations extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.id !== nextProps.id || this.props.mode !== nextProps.mode
  }

  render() {
    const { mode, id, history } = this.props

    return (
      <div>
        <GraphQLFormProvider
          mode={mode==="add"?"add":"edit"}
          id={id}
          {...config}
          renderDisplay={renderDisplay}
          renderInput={renderInput}
          InputFormLayout={FormLayout}
          DisplayFormLayout={FormLayout}
        />
      </div>
    )
  }
}
export default ClientObligations
