import React from 'react'

function AudioPlayer({ file }) {

  return (
      <audio controls>
        {file.contentType.includes("ogg") && <source src={file.url} type="audio/ogg"></source>}
        {file.contentType.includes("mpeg") && <source src={file.url} type="audio/mpeg"></source>}
        Your browser does not support the audio element.
      </audio>
  )
}


export default AudioPlayer
