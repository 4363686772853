import React from 'react'
import DashboardCard from './DashboardCard'
import {Icon} from '../generic';

const MetricCard = ({ size, name, label, value, warning }) => (
  <DashboardCard size={size || 4} title={label}>
    <center><h1><b>{value || '-'}</b></h1></center>
    { (value && warning !== undefined) ? 
        warning ? 
          <div style={{ position: 'absolute', right: 14, bottom: 7 }}><Icon icon="exclamation-triangle" style={{ color: 'red' }} /></div> 
        : 
          <div style={{ position: 'absolute', right: 14, bottom: 7 }}><Icon icon="check-circle" style={{ color: 'green' }} /></div>  
      : <div />
    }
  </DashboardCard>
)

export default MetricCard
