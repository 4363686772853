import React, { useState } from 'react'
import { useConfirm } from "material-ui-confirm";
import { withApollo } from 'react-apollo'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import gql from 'graphql-tag'
import { Grid, Button, Card, DatePicker, ActivityIndicator, TextField } from '../generic'
import ClientFuneral from './ClientFuneral'
import ClientProducts from './ClientProducts'
import FuneralInfo from './FuneralInfo'

const useStyles = makeStyles((theme) => ({
  center: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    paddingTop: 7,
    paddingBottom: 7
  },
  button: {
    minWidth: 200,
    maxWidth: 200,
    textAlign: 'center',
    marginRight: 14
  },
  footer: {
    paddingRight: 0,
    paddingBottom: 21
  },
}));

const GET_MEMBERS = gql`
  query Members($filter: MemberFilter!) {
    members(filter: $filter) {
      id
      idNumber
      funerals {
        id
        policyNumber
      }
    }
  }`

const GET_FUNERALS = gql`
  query funerals($filter: FuneralFilter!) {
    funerals(filter: $filter) {
         id
          policyNumber
          policyType
          paymentMethod
          paymentDueDay
          currentPremiumTotal
          coverAmount
          createdAt
          updatedAt
          state
          livesCovered {
            id
            firstNames
            surname
            idNumber
            memberType
            currentPremium
            coverAmount
            ageAtSignup
            cellNumbers
            email
            memberType
            currentPremium
            coverAmount
            income
            estimatedIncome
            incomeBand
            confidence
          }
    }
  }`

const GET_CLIENTS = gql`
  query GetClientDetails( $filter: ClientFilter!) {
    clients(filter: $filter, limit:1000) {
      id
      firstNames
      surname
      idType
      idNumber
      passportExpiresDate
      email
      cellNumbers
      smoker
      occupation
      acceptedTerms
      createdAt
      income
      estimatedIncome
      cellNumbers
      funeralPolicies { 
        id
        policyNumber
       }
      claims {
        id
      }
    }
	}
`;

let FuneralSearch = ({ client, history }) => {
  const confirm = useConfirm();
  const classes = useStyles();
  const [spin, setSpin] = useState(false)
  const [policyNumber, setPolicyNumber] = useState()
  const [idNumber, setIdNumber] = useState()
  const [beneficiaryIdNumber, setBeneficiaryIdNumber] = useState()
  const [email, setEmail] = useState()
  const [cellNumbers, setCellNumbers] = useState()
  const [surname, setSurname] = useState()
  const [clients, setClients] = useState()
  const [policyText, setPolicyText] = useState()
  const [idText, setIdText] = useState()
  const [beneficiaryText, setBeneficiaryText] = useState()
  const [emailText, setEmailText] = useState()
  const [cellNumbersText, setCellNumbersText] = useState()
  const [surnameText, setSurnameText] = useState()
  const [funeralPolicies, setFuneralPolicies] = useState()
  const [effectiveDate, setEffectiveDate] = useState(new Date().valueOf())
  const [funeralId, setFuneralId] = useState()
  const [open, setOpen] = useState(false)


  const getClient = () => {
    setFuneralPolicies()
    setClients()
    setIdText()
    setEmailText()
    setCellNumbersText()
    setSurnameText()
    setSpin(true)
    const filter = {}
    if (idNumber) {
      filter.idNumber = idNumber
    }
    if (surname) {
      filter.surname = surname
    }
    if (email) {
      filter.email = email
    }
    if (cellNumbers) {
      filter.cellNumbers = cellNumbers
    }
    client
      .query({
        query: GET_CLIENTS,
        variables: { filter }
      })
      .then(result => {
        setSpin(false)
        let clientsWithFunerals = []
        if (result.data.clients && result.data.clients.length > 0) {
          clientsWithFunerals = result.data.clients.filter(c => c.funeralPolicies.length > 0)
        }
        if (clientsWithFunerals.length > 0) {
          if (clientsWithFunerals.length === 1) {
            getFuneral({ clientId: clientsWithFunerals[0].id })
          }
          else {
            setClients(clientsWithFunerals)
          }
        }
        else {
          if (filter.idNumber) {
            setIdText(`Not found`)
            // try find the idNumber as a Member
            getMember({ idNumber: filter.idNumber })
          }
          if (filter.cellNumbers) {
            setCellNumbersText(`None found`)
          }
          if (filter.cellNumbers) {
            setEmailText(`None found`)
          }
          if (filter.surname) {
            setSurnameText(`None found`)
          }
        }
      })
  }

  const getFuneral = (filter) => {
    setFuneralPolicies()
    setSpin(true)
    // const filter = {}
    // if (policyNumber) {
    //   filter.policyNumber = policyNumber
    // }
    // if (clientId) {
    //   filter.clientId = clientId
    // }
    client
      .query({
        query: GET_FUNERALS,
        variables: { filter }
      })
      .then(result => {
        setSpin(false)
        if (result.data.funerals && result.data.funerals.length > 0) {
          setPolicyText()
          if (result.data.funerals.length === 1) {
            const { createdAt, ...policy } = result.data.funerals[0]
            if (effectiveDate < createdAt) {
              setEffectiveDate(createdAt)
            }
            setFuneralId(result.data.funerals[0].id)
            // setOpen(true)
            history.push(`/funeral/${result.data.funerals[0].id}/edit/${effectiveDate}`)
            console.log('funeral search', result.data.funerals[0])
          }
          else {
            setFuneralPolicies(result.data.funerals)
          }
        }
        else {
          setPolicyText(`No policies found`)
        }
      })
  }

  const getMember = (filter) => {
    setFuneralPolicies()
    setSpin(true)
    client
      .query({
        query: GET_MEMBERS,
        variables: { filter }
      })
      .then(result => {
        setSpin(false)
        if (result.data.members && result.data.members.length > 0) {
          setIdText()
          setPolicyText()
          if (result.data.members.length === 1) {
            const { funerals, createdAt, ...policy } = result.data.members[0]
            if (funerals && funerals.length > 0) {
              setFuneralPolicies(funerals.id)
            }
            if (effectiveDate < createdAt) {
              setEffectiveDate(createdAt)
            }
            setFuneralId(funerals[0].id)
            // setOpen(true)
            history.push(`/funeral/${funerals[0].id}/edit/${effectiveDate}`)
            console.log('member search', result.data.members[0])
          } else {
            setPolicyText(`No policies found`)
          }
        }
        else {
          setPolicyText(`No policies found`)
        }
      })
  }

  return (
    <Card>
      <Grid container className={classes.center} spacing={1}>
        <Grid item>
          <TextField fullWidth variant="outlined" value={policyNumber} label='Policy Number'
            onChange={(e) => setPolicyNumber(e.target.value.trim())} inputProps={{ style: { textTransform: "uppercase" } }} helperText={policyText}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                policyNumber ? getFuneral({ policyNumber }) : getClient();
              }
            }}
          // onKeyPress={(e) => {
          //   console.log('key pressed is ', e.key, e.target.value)
          //   if (e.key === "Enter") {
          //     policyNumber ? getFuneral({ policyNumber }) : getClient();
          //   }
          // }}
          />
        </Grid>
        <Grid item xs={1}>
          <Button disabled={!(policyNumber || idNumber || email || surname || cellNumbers)} onClick={() => policyNumber ? getFuneral({ policyNumber }) : getClient()} >
            Search
          </Button>
        </Grid>
        <Grid item xs={2} >
          <TextField fullWidth variant="outlined" value={idNumber} label='Id Number' onChange={(e) => setIdNumber(e.target.value)} helperText={idText} />
        </Grid>
        {/* <Grid item xs={2} >
          <TextField fullWidth variant="outlined" value={beneficiaryIdNumber}   label='Beneficiary Id' onChange={(e) => setBeneficiaryIdNumber(e.target.value)} helperText={beneficiaryText} />
        </Grid> */}
        <Grid item xs={2}>
          <TextField fullWidth variant="outlined" value={surname} label='Surname' onChange={(e) => setSurname(e.target.value)} helperText={surnameText} />
        </Grid>
        <Grid item xs={1}>
          <TextField fullWidth variant="outlined" value={cellNumbers} label='Cell' onChange={(e) => setCellNumbers(e.target.value)} helperText={cellNumbersText} />
        </Grid>
        <Grid item>
          <TextField fullWidth variant="outlined" value={email} label='Email' onChange={(e) => setEmail(e.target.value)} helperText={emailText} />
        </Grid>
        <Grid item xs={2}>
          <DatePicker value={effectiveDate} disableFuture autoOk label="EffectiveDate" onChange={(v) => { console.log('DatePicker setEffectiveDate', v); setEffectiveDate(v) }} />
          {/* KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }} */}
        </Grid>

      </Grid >
      {spin && <ActivityIndicator />}
      <br /><br />
      {clients && <ClientProducts clients={clients} />}
      {funeralPolicies && <ClientFuneral funeralPolicies={funeralPolicies} />}
      <Dialog aria-labelledby="form-dialog-title"
        fullScreen
        onClose={() => setOpen(false)}
        open={open}
      >
        <DialogContent>
          <FuneralInfo id={funeralId} effectiveDate={effectiveDate} />
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button onClick={() => setOpen(false)} color="primary" variant="contained" style={{ marginRight: 20 }}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default FuneralSearch = withApollo(FuneralSearch);
