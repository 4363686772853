import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { GraphQLFormProvider } from 'react-form-helper';
import { renderInput, renderDisplay, Card, BuildLayout, Select, Button } from '../generic';
import { withRouter } from 'react-router'
import { getAllDCs } from './DebtCounsellorsList'
import BANKS from '../../variables/banks'

const emailValid = (value) => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
const simplicityValid = (value) => value.match(/^(\d{3}-\d{4})$/i)

const config = {
  name: 'debtcounsellor',
  fields: {
    description: {
      type: 'name',
      label: 'Full Name',
      // validate: (value) => {
      //   if (!value) {
      //     return 'Description is required'
      //   }
      // }
    },
    shortName: {
      type: 'text',
      label: 'Name on SMS',
      // validate: (value) => {
      //   if (!value) {
      //     return 'SMS name required'
      //   }
      // }
    },
    fullName: {
      type: 'name',
      label: 'Name of Debt Counsellor *',
      validate: (value) => {
        if (!value) {
          return 'Full name is required'
        }
      }
    },
    phoneNumber: {
      type: 'text',
      label: 'Phone',
      validate: (value) => {
        if (value && value.length != 10) {
          return 'Phone numbers are 10 long'
        }
      }
    },
    cellNumber: {
      type: 'text',
      label: 'Mobile',
      validate: (value) => {
        if (value && value.length != 10) {
          return 'Phone numbers are 10 long'
        }
      }
    },
    email: {
      type: 'email',
      label: 'Email',
      validate: (value) => {
        if (value && !emailValid(value)) {
          return 'Email is invalid'
        }
      }
    },
    'bankDetails.branchCode': {
      type: 'select',
      label: 'Name of Bank',
      options: BANKS,
      // validate: (value) => {
      //   if (!value) {
      //     return 'Bank is required'
      //   }
      // }
    },
    'bankDetails.accountNo': {
      type: 'text',
      label: 'Account No',
      // validate: (value, allValues) => {
      //   if (!value) {
      //     return 'account number required'
      //   }
      // }
    },
    // finalRegistrationDate: {
    //   type: 'date',
    //   label: 'Final Registration Date'
    // },
    ncrRegistrationNumber: {
      type: 'text',
      label: 'NCR Registration'
    },
    externalId: {
      type: 'text',
      label: 'Simplicity DC code',
      // validate: (value) => {
      //   if (!value) {
      //     return 'required'
      //   }
      //   if (!simplicityValid(value)) {
      //     return 'Simplicity codes are xxx-xxxx'
      //   }
      // }
    },
    dcCompany: {
      type: 'text',
      label: 'Company Nbr'
    },
    dcId: {
      type: 'text',
      label: 'DC Nbr',
      // validate: (value) => {
      //   if (!value) {
      //     return 'required'
      //   }
      //   if (value.length != 4) {
      //     return 'Hyphen DC Numbers are 4 long'
      //   }
      // }
    },
    vatNumber: {
      type: 'text',
      label: 'VAT Number'
    },
    // 'physicalAddress.street': {
    //   type: 'text',
    //   label: 'Physical Steet Address'
    // },
    // 'physicalAddress.city': {
    //   type: 'text',
    //   label: 'Physical City'
    // },
    // 'physicalAddress.zip': {
    //   type: 'text',
    //   label: 'Physical Zip'
    // },
    'postalAddress.street': {
      type: 'text',
      label: 'Postal Steet Address'
    },
    'postalAddress.city': {
      type: 'text',
      label: 'Postal City'
    },
    'postalAddress.zip': {
      type: 'text',
      label: 'Postal Zip'
    },
    // town: {
    //   type: 'text',
    //   label: 'Town'
    // },
    ncrRegistered: {
      type: 'boolean',
      label: 'NCR Registered'
    },
    agreementStartDate: {
      type: 'date',
      label: 'Agreement Start Date'
    },
    agreementEndDate: {
      type: 'date',
      label: 'Agreement End Date'
    },
    firstPolicySold: {
      type: 'date',
      label: 'First Policy Sold'
    },
    specialRateEnd: {
      type: 'date',
      label: 'Special Rate Ends'
    },
    transferEmailAddress: {
      type: 'text',
      label: 'DC transfer email address'
    },
    demandsFeesOnClearance: {
      type: 'switch',
      label: 'Demands Fees On Clearance'
    }
  },
  query: gql`
    query DebtCounsellorData($id: String!) {
      debtcounsellor(id: $id) {
        id
        transferEmailAddress
        demandsFeesOnClearance
        externalId
        companyId
        fullName
        description
        shortName
        phoneNumber
        cellNumber
        email
        # finalRegistrationDate
        ncrRegistrationNumber
        dcCompany
        dcId
        bankDetails {
          bankName
          accountNo
          branchCode
        }
        # physicalAddress {
        #   street
        #   city
        #   zip
        # }
        postalAddress {
          street
          city
          zip
        }
        # town
        ncrRegistered
        agreementStartDate
        agreementEndDate
        vatNumber
        firstPolicySold
        specialRateEnd
      }
    }
  `,
  updateMutation: gql`
    mutation UpdateDebtCounsellor($input: UpdateDebtCounsellorInput!, $id: String!) {
      updateDebtCounsellor(id: $id, input: $input) {
        id
        transferEmailAddress
        demandsFeesOnClearance
        externalId
        companyId
        fullName
        description
        shortName
        phoneNumber
        cellNumber
        email
        # finalRegistrationDate
        ncrRegistrationNumber
        dcCompany
        dcId
        bankDetails {
          bankName
          accountNo
          branchCode
        }
        # physicalAddress {
        #   street
        #   city
        #   zip
        # }
        postalAddress {
          street
          city
          zip
        }
        # town
        ncrRegistered
        agreementStartDate
        agreementEndDate
        vatNumber
        firstPolicySold
        specialRateEnd
      }
    }
  `,
  addMutation: gql`
    mutation CreateDebtCounsellor($input: CreateDebtCounsellorInput!) {
      createDebtCounsellor(input: $input) {
        id
        transferEmailAddress
        demandsFeesOnClearance
        externalId
        companyId
        fullName
        description
        shortName
        phoneNumber
        cellNumber
        email
        # finalRegistrationDate
        ncrRegistrationNumber
        dcCompany
        dcId
        bankDetails {
          bankName
          accountNo
          branchCode
        }
        # physicalAddress {
        #   street
        #   city
        #   zip
        # }
        postalAddress {
          street
          city
          zip
        }
        # town
        ncrRegistered
        agreementStartDate
        agreementEndDate
        vatNumber
      }
    }
  `
}

let DebtCounsellorFormLayout = (props) => {
  console.log('DebtCounsellorFormLayout', props)
  const { mode, history, formName } = props;

    return (
      <Card>
        <h1>{mode === "edit" && "Edit"} Debt Counsellor</h1>

        <BuildLayout formName={formName} fieldNames={Object.keys(config.fields)
          .filter(fieldName => (mode) === 'edit' || !config.fields[fieldName].noAdd)} mode={mode} fieldConfig={config.fields} direction='column' spacing={2} cancel={() => history.push('/debt-counsellors')} />

        </Card>
    );
}

DebtCounsellorFormLayout = withRouter(DebtCounsellorFormLayout)

const DebtCounsellor = (props) => {
  const { mode, id, history } = props;
    return (
      <div>
        <GraphQLFormProvider
          mode={mode==="add"?"add":"edit"}
          id={id}
          {...config}
          afterSubmit={() => { history.push('/debt-counsellors') }}
          renderDisplay={renderDisplay}
          renderInput={renderInput}
          InputFormLayout={(props) => <DebtCounsellorFormLayout {...props}  formName={config.name } /> }
          DisplayFormLayout={DebtCounsellorFormLayout}
          refetchQueries={[{ query: getAllDCs }]}
        />
      </div>
    );
}

export default DebtCounsellor;
