import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Modal, Tooltip } from '../generic'
import ReactAudioPlayer from 'react-audio-player'
import moment from 'moment'
import Waveform from './Waveform'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

const getSalesActivities = gql`
  query($id: String!) {
    salesinteraction(id: $id) {
      salesActivities {
        id
        timestamp
        activityType
      }
      recording {
        id
        url
      }
      timestamp
      result
    }
  }
`

const EVENTS = [
  { description: 'Questions', timestamp: 10 },
  { description: 'Quote', timestamp: 20 },
  { description: 'Acceptance', timestamp: 30 }
]

const propTypes = {
  onClosePlaybackModal: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  timestamp: PropTypes.number.isRequired
}

const src = 'https://ia902606.us.archive.org/35/items/shortpoetry_047_librivox/song_cjrg_teasdale_64kb.mp3'

const getEvents = (duration, { timestamp, salesActivities, result }) => {
  const endTimestamp = timestamp
  const startTimestamp = salesActivities.filter(s => s.activityType === 'START_INTERACTION')[0].timestamp
  const questionAnswers = salesActivities.filter(s => s.activityType === 'ANSWER_QUESTION').map(s => s.timestamp)
  const quoteAnswers = salesActivities.filter(s => s.activityType === 'CHANGE_QUOTE_PARAM').map(s => s.timestamp)


  let lastQuestionAnswered = Math.max(...questionAnswers)
  let lastQuoteAnswer = quoteAnswers.length > 0 ? Math.max(...quoteAnswers) : null
  
  const timeFactor = duration / (endTimestamp - startTimestamp)

  if (result === 'NOT_INTERESTED') {
    return [
      { description: 'Questions - Not Interested', timestamp: duration / 2 }
    ]
  }

  if (result === 'QUOTE_NOT_INTERESTED') {
    return [
      { description: 'Questions', timestamp: (lastQuestionAnswered - startTimestamp) / 2 * timeFactor },
      { description: 'Quote - Not Interested', timestamp: (lastQuestionAnswered - startTimestamp + ((endTimestamp - lastQuestionAnswered) / 2)) * timeFactor }
    ]
  }

  if (!lastQuoteAnswer) {
    const delta = (endTimestamp - lastQuestionAnswered) / 3 
    lastQuoteAnswer = endTimestamp - delta
  }

  return [
    { description: 'Questions', timestamp: (lastQuestionAnswered - startTimestamp) / 2 * timeFactor },
    { description: 'Quote', timestamp: (lastQuestionAnswered - startTimestamp + ((lastQuoteAnswer - lastQuestionAnswered) / 2)) * timeFactor },
    { description: 'Acceptance', timestamp: (lastQuoteAnswer - startTimestamp + ((endTimestamp - lastQuoteAnswer) / 2)) * timeFactor }
  ]
}

class PlaybackModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      duration: null
    }
  }
  
  getMarkerPosition(t) {
    const duration = this.player.audioEl.duration
    const visWidth = this.waveformWrapper.clientWidth
    const pixelsPerSecond = visWidth / duration
    return t * pixelsPerSecond
  }

  render() {
    const { timestamp, show, onClosePlaybackModal, data } = this.props
    const { loading, error, salesinteraction } = data || {}
    console.log(salesinteraction)
    return (
      <Modal bsSize="large" show={show} onHide={onClosePlaybackModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            { moment(timestamp).toString() }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            loading ?
              <div>Loading ...</div>
            : error ?
              <div>An error occurred</div>
            :
              <Fragment>
                <div ref={(input) => { this.waveformWrapper = input }}>
                  {
                      this.state.duration && getEvents(this.state.duration, salesinteraction).map((event, idx) => <Tooltip description={description} />) 
                  }
                  <Waveform src={src} />
                </div>
                <ReactAudioPlayer
                  onLoadedMetadata={() => this.setState({ duration: this.player.audioEl.duration })}
                  ref={(element) => { this.player = element }}
                  src={salesinteraction.recording ? salesinteraction.recording.url || src : src}
                  autoPlay
                  controls
                  style={{ width: '100%' }}
                />
              </Fragment>
          }
        </Modal.Body>
      </Modal>
    )
  }
}

PlaybackModal.propTypes = propTypes


PlaybackModal = graphql(getSalesActivities, { options: ({ id }) => ({ variables: { id }}) })(PlaybackModal)

export default PlaybackModal
