import React from 'react'
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag'
import moment from 'moment';
import { GraphQLFormProvider } from 'react-form-helper'
import { isValidCellphoneNumber, getAgeFromDOB, getAgeFromID, isValidEmail, isValidRsaIdNumber, isInteger } from '../../lib/validations'
import { funeralInformation } from './FuneralInfo'
import { IdTypes, RELATIONSHIPS } from '../../variables'
import { renderInput, renderDisplay } from '../generic';
import { BuildLayout } from '../generic';
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'

// switch between locales
Numeral.locale('en-za')

const UPDATE_CLIENT = gql`
	mutation($input: UpdateClientInput!, $id: String!) {
		updateClient(id: $id, input: $input) {
      id
      firstNames
      surname
      gender
      idType
      passportExpiresDate
      email
      cellNumbers
      smoker
		}
	}
`;
// 0 - 4 5 6 - 12 13 14 - 20 21
// 12.5 % 25 % 25 % 50 %
//   So Freedom extended child is limited to R30k on a 75. 10, 20, 30, 50, 75.  Under 13 max is 25 % of coverage.Under 5  12.5 %

const config = {
  name: 'member',
  fields: {
    memberType: {
      label: 'Member Type',
      type: 'select',
      options: [],
      nullOnly: true,
      validate: (value, row) => {
        if (!value) {
          return 'Please select a member type'
        }
      }
    },
    idNumber: {
      label: 'Id Number',
      type: 'text',
      label: 'Id Number',
      nullOnly: true,
      validate: (value, row) => {
        if (!row.DOB && !value) {
          return 'Either ID Number or Date of Birth is required'
        }
        else if (row.idType && row.idType === 'rsa_id' && isValidRsaIdNumber(value)) {
          return 'ID Number is invalid'
        }
      }
    },
    idType: {
      type: 'select',
      options: IdTypes,
      nullOnly: true,
      label: 'ID Country',
      validate: (value, row) => {
        if (!row.DOB && row.idNumber && !value) {
          return 'ID type is Required'
        }
      }
    },

    firstNames: {
      type: 'text',
      label: 'First Name *',
      noLabel: true,
      validate: (value) => {
        if (!value) {
          return 'First name is Required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    },
    surname: {
      type: 'text',
      label: 'Last Name *',
      validate: (value) => {
        if (!value) {
          return 'Last name is required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    },
    'gender': {
      type: 'select',
      excludeMemberTypes: ['BENEFICIARY'],
      options: ['FEMALE', 'MALE'].map(g => ({ value: g, label: g })),
      label: 'Gender',
      validate: (value, row) => {
        if (!value) {
          return 'Gender is required'
        }
      }
    },
    ageAtSignup: {
      type: 'text',
      label: 'Age at Signup*',
      addOnly: true,
      excludeMemberTypes: ['BENEFICIARY'],
      validate: (value, row) => {
        if (!value) {
          return 'Age is required'
        }
        else if (isInteger(value)) {
          return 'Age is invalid'
        }
        else if (value && row.memberType === 'CHILD' && (value < 0 || value > 25)) {
          return 'Children must be under 25'
        }
        else if (value && row.memberType === 'SPOUSE' && !(value >= 18 && value <= 60)) {
          return 'Spouse must be between 18 and 60'
        }
        else if (value && row.memberType === 'PARENT' && !(value >= 18 && value <= 75)) {
          return 'Parents must be between 18 and 75'
        }
        else if (value && row.memberType === 'EXTENDED' && !(value >= 0 && value <= 75)) {
          return 'Extended family members max age is 75'
        }
        else if (value && value && row.DOB) {
          let currentAge = getAgeFromDOB(row.DOB)
          console.log('current age ', currentAge, value)
          if (currentAge != value) {
            return 'Date of Birth does not match the age'
          }
        }
        else if (value && row.idNumber && row.idType && row.idType === 'rsa_id') {
          let currentAge = getAgeFromID(row.idNumber, row.idType)
          console.log('current age ', currentAge, value)
          if (currentAge != value) {
            return 'Age does not match the Id Number'
          }
        }
      }
    },
    DOB: {
      label: 'Date of Birth',
      excludeMemberTypes: ['BENEFICIARY'],
      type: 'date',
      fullwidth: true,
      disableFutureDates: true,
      validate: (value, row) => {
        console.log('Date of Birth value, row ', value, row)
        if (!value) {
          return 'Date of birth is required'
        }

      }
    },
    coverAmount: {
      type: 'select',
      forMemberTypes: ['NO_BODY_EXTENDED'],
      label: 'Coverage Amount',
      options: [],
      disabled: false,
      validate: (value) => {
        if (!value) {
          return 'Cover amount is required'
        }
      }
    },
    'cellNumbers[0]': {
      type: 'text',
      forMemberTypes: ['POLICYHOLDER', 'SPOUSE', 'BENEFICIARY'],
      label: 'Mobile Number',
      validate: (value) => {
        if (value && isValidCellphoneNumber(value)) {
          return 'enter a valid cell number'
        }
      }
    },
    email: {
      type: 'email',
      label: 'Email ',
      forMemberTypes: ['POLICYHOLDER', 'SPOUSE', 'BENEFICIARY'],
      validate: (value) => {
        if (value && isValidEmail(value)) {
          return 'Email not valid'
        }
      }
    },
    relationship: {
      label: "Relationship",
      type: "select",
      forMemberTypes: ['BENEFICIARY', 'EXTENDED', 'PARENT'],
      options: RELATIONSHIPS.sort((a, b) => (a > b) ? 1 : -1).map(a => ({ value: a, label: a })),
      validate: (value, row) => {
        if (['BENEFICIARY', 'EXTENDED', 'PARENT'].indexOf(row.memberType) > -1 && !value) {
          return 'Relationship is Required'
        }
      }
    },
    income: {
      label: 'Income',
      forMemberTypes: ['POLICY_HOLDER', 'SPOUSE'],
      type: 'text',
      validate: (value) => {
        if (value && isInteger(value)) {
          return 'Enter a number'
        }
      }
    },
    smoker: {
      forMemberTypes: ['POLICY_HOLDER', 'SPOUSE', 'PARENT', 'EXTENDED'],
      label: 'Smoker',
      type: 'switch'
    }
  },
  query: gql`
    query MemberData($id: String!) {
      member(id: $id) {
        id
        firstNames
        surname
        idNumber
        idType
        clientId
        memberType
        coverAmount
        age
        gender
        ageAtSignup
        DOB
        email
        cellNumbers
        relationship
        dead
        dateOfDeath
        smoker
        coverAmount
        createdAt
      }
    }`,
  updateMutation: gql`
    mutation UpdateMember($input: UpdateMemberInput!, $id: String!) {
      updateMember(id: $id, input: $input) {
        id
        firstNames
        surname
        idNumber
        idType
        clientId
        DOB
        age
        gender
        ageAtSignup
        memberType
        coverAmount
        email
        cellNumbers
        relationship
        smoker
        income
        coverAmount
      }
  }`,
  addMutation: gql`
  mutation CreateMember($input: CreateMemberInput!) {
    createMember(input: $input) {
      id
      firstNames
      age
      ageAtSignup
      surname
      idNumber
      idType
      DOB
      memberType
      coverAmount
      email
      cellNumbers
      smoker
      relationship
      gender
      income
      coverAmount
    }
  }`
}

const FormLayout = (props) => {

  let { mode, submit, cancel, data, coverAmount, formName } = props;
  const { member } = data || {}

  const { memberType, clientId } = member || {}

  const fieldNames = Object.keys(config.fields).filter(fieldName => (!config.fields[fieldName].excludeMemberTypes || config.fields[fieldName].excludeMemberTypes.indexOf(memberType) === -1) && (!config.fields[fieldName].forMemberTypes || config.fields[fieldName].forMemberTypes.indexOf(memberType) > -1) && ((mode === 'add' && !config.fields[fieldName].noAdd) || mode === 'edit' && !config.fields[fieldName].addOnly))

  return <BuildLayout formName={formName} fieldNames={fieldNames} fieldConfig={config.fields} direction='column' mode={mode} spacing={1} align='center' justify='center' cancel={cancel} submit={submit} allowNull={!clientId} />
}

let Member = (props) => {
  const { mode, id, history, funeralId, coverAmount, memberType, options = ['CHILD'], cancel } = props

  if (mode === 'add' || !memberType) {
    config.fields['memberType'].options = options.sort((a, b) => (a > b) ? 1 : -1).map(m => ({ value: m, label: m }))
  }

  const coverage = []
  if (memberType === 'NO_BODY_EXTENDED') {
    let i = 0
    // valid extended coverages (excluding children) are 5000, 10000, 15000, 25000)
    const validCovers = [5000, 10000, 15000, 25000]
    while (validCovers[i] <= (coverAmount / 2)) {
      coverage.push(validCovers[i]);
      i++;
    }
    config.fields['coverAmount'].options = coverage.map(a => ({
      value: a, label: Numeral(a).format('$ ##,###0.')
    }))
  }

  return (
    <div>
      <GraphQLFormProvider
        initialValues={{ memberType: options.length === 1 ? options[0] : undefined, funeralsIds: funeralId ? [funeralId] : [] }}
        initialValuesFromData={(data) => {
          const { member } = data || {}
          if (member) {
            if (member.income && member.income.toString().indexOf(".") > -1) {
              member.income = member.income.toFixed(0)
            }
            if (member.idNumber && !member.idType) {
              member.idType = 'rsa_id'
            }
            // if (!member.ageAtSignup) {
            //   if (member.DOB) {
            //     member.ageAtSignup = moment(member.createdAt).diff(moment(member.DOB), 'years')
            //   }
            // }
            // console.log('member data is now ', member)
            // if (moment().diff(member.DOB, 'years') < 5) {
            //   console.log('initialValuesFromData member age less than 5', moment().diff(member.DOB, 'years'))
            //   config.fields['coverAmount'].options = coverage.map(a => ({
            //     value: (a / 4), label: Numeral(a / 4).format('$ ##,###0.')
            //   }))
            // }
            // else if (moment().diff(member.DOB, 'years') < 13) {
            //   console.log('initialValuesFromData member age less than 13', moment().diff(member.DOB, 'years'))
            //   config.fields['coverAmount'].options = coverage.map(a => ({
            //     value: (a / 2), label: Numeral(a / 2).format('$ ##,###0.')
            //   }))
            // }
            console.log('initialValuesFromData member data out', member)
          }
          return member
        }}
        mapInputVariables={props => {
          console.log('mapInputVariables Member IN ', props)
          const { income, ...input } = props
          if (income) {
            console.log('mapInputVariables delete income', input.income)
            input.income = income
          }
          if (!input.age && input.ageAtSignup) {
            input.age = input.ageAtSignup
          }
          if (input.cellNumbers && input.cellNumbers.length > 1) {
            const cell = input.cellNumbers[0]
            input.cellNumbers = [cell]
          }
          console.log('mapInputVariables Member out', input)
          return ({ input })
        }}  // defining the input to use the input needed in the update mutation. 
        name='member'
        mode={id ? 'edit' : 'add'}
        id={id}
        {...config}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout {...props} formName={config.name} cancel={cancel} history={history} />}
        refetchQueries={funeralId ? [{ query: funeralInformation, variables: { id: funeralId } }] : []}
        DisplayFormLayout={FormLayout}
        afterSubmit={({ data }) => { cancel() }}
      />
    </div>
  )
}

Member = withApollo(Member);
export default Member
