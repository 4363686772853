import React from 'react'
import { Card, Grid } from './index';

const DashboardCard = ({ size, children, title, noPadding, onExpandCard }) => (
  <Grid container >
    <Grid item xs={size} onClick={onExpandCard} style={{ backgroundColor: 'white', padding: noPadding ? 0 : 7 }}>
    <Card title ={title && <div style={{ paddingBottom: 10, paddingTop: 3 }}><span style={{ fontSize: 14 }} >{title}</span></div>} > 
      {children}
      </Card>
    </Grid>
  </Grid>
)

export default DashboardCard
