import fetch from 'isomorphic-fetch'
import { API_URL, API_SUBSCRIPTION_URL, API_AUTH_ID } from './config'
import { getAuthAud } from "./services/auth"
import introspectionQueryResultData from './fragmentTypes.json'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';

const accessToken = localStorage.getItem('accessToken');

// const httpLink = createUploadLink({
//     uri: `${API_URL}graphql`,
// });

// Create an http link:
const httpLink = new HttpLink({
    uri: `${API_URL}graphql`,
});


// Create a WebSocket link:
const wsLink = new WebSocketLink({
    uri: API_SUBSCRIPTION_URL,
    options: {
        reconnect: true,
        connectionParams: {
            authToken: accessToken
        }
    }
});

const middlewareLink = setContext(() => ({
    headers: {
        Authorization: `JWT ${accessToken || null}`,
        "X-App-Client": `admin-${API_AUTH_ID}`,
        "X-App-Version": `${process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : 'Development'}`,
        "X-App-Device": getAuthAud()
    }
}));

// use with apollo-client
const httpLinkWithAuth = middlewareLink.concat(httpLink);

function getDataIdFromObject(result) {
    if (result.__typename && result.__typename !== "ClaimBreakdown" && result.__typename !== "ClaimDocument") {
        if (result.id !== undefined) {
            return `${result.__typename}:${result.id}`;
        }
        if (result._id !== undefined) {
            return `${result.__typename}:${result._id}`;
        }
    }
    return null;
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
    // split based on operation type
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLinkWithAuth,
);

const client = new ApolloClient({
    fetch,
    link,
    cache: new InMemoryCache({
        fragmentMatcher,
        dataIdFromObject: getDataIdFromObject
    })
});

export default client;