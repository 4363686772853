import React, { useState, useEffect } from 'react'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { GraphQLFormProvider, FieldValue } from 'react-form-helper'
import {withRouter} from 'react-router'
import { BuildLayout, renderDisplay, renderInput } from '../generic'
import BANKS from '../../variables/banks'
import { IdTypes } from '../../variables/IdTypes'
import { isValidRsaIdNumber, isValidEmail, isValidCellphoneNumber } from '../../lib/validations';

const findBank = async (branchCode) => BANKS.filter(bank => bank.value === branchCode)[0].label

const config = {
  name:'bankaccount',
  fields: {
    branchCode: {
      type: 'select',
      label: 'Bank Name *',
      options: BANKS,
      // addOnly: true
    },
  accountNo: {
      type: 'text',
      label: 'Account Number *',
      validate: (value) => {
        if (!value) {
          return 'Account number is required'
        }
        if (value.length > 15) {
          return 'Length must be less than 15'
        }
    },
    // addOnly: true
  },
    ownAccount: {
      label: 'Own Account',
      type: 'switch'
    },
    accountHolderId: {
      label: 'Account Holder Id',
      type: 'text',
      neverShow: true,
    },
    accountHolderIdType: {
      type: 'select',
      hide: true,
      options: IdTypes,
      label: 'Account Holder ID Type',
      validate: (value, row) => {
        if (!row.ownAccount && !value) {
          return 'ID type is Required'
        }
      }
    },
    accountHolderIdNumber: {
      label: 'Account Holder Id Number',
      type: 'text',
      hide: true,
      validate: (value, row) => {
        if (!row.ownAccount && !value) {
          return 'Account holder ID Number required '
        }
        if ((!row.accountHolderIdType || row.accountHolderIdType === 'rsa_id') && isValidRsaIdNumber(value)) {
          return isValidRsaIdNumber(value)
        }
      }
    },
  accountHolderName: {
    label: 'Account Holder Name',
    type: 'text',
    hide: true,
      validate: (value, row) => {
        if (!row.ownAccount && !row.accountHolderId) {
          if (!value)
            return 'Account holder name is required'
        }
      }
    },
  accountHolderEmail: {
    label: 'Account Holder Email',
    type: 'text',
    hide: true,
      validate: (value, row) => {
        if (!row.ownAccount && !row.accountHolderId) {
          if (value && isValidEmail(value))
            return 'Email is required'
        }
      }
    },
  accountHolderContactNumber: {
    label: 'Account Holder Cell',
    type: 'text',
    hide: true,
    validate: (value, row) => {
      if (!row.ownAccount && !value) {
        return 'Account holder mobile is required'
      } else if (isValidCellphoneNumber(value)) {
        return 'Cell number is invalid'
      }
    }
  }
},
  query: gql`
    query BankAccountData($id: String!) {
      bankaccount(id: $id) {
        id
        bankName
        branchCode
        accountNo
        accountHolderId
        accountHolder {
          idType
          idNumber
          firstNames
          surname
          cellNumbers
          email
        }
        accountHolderName
        accountHolderContactNumber
        accountHolderEmail
        accountHolderIdType
        accountHolderIdNumber
        ownAccount
        idNumberMatch
        nameMatch
        phoneMatch
        accountExists
        avsRawData
      }
    }`,
  updateMutation: gql`
    mutation UpdateBankAccount($input: UpdateBankAccountInput!, $id: String!) {
      updateBankAccount(id: $id, input: $input) {
        id
        bankName
        branchCode
        accountNo
        accountHolderId
        accountHolderName
        accountHolderContactNumber
        accountHolderEmail
        accountHolderIdType
        accountHolderIdNumber
        ownAccount
      }
    }`,
    addMutation: gql`
    mutation CreateBankAccount($input: CreateBankAccountInput!) {
      createBankAccount(input: $input) {
        id
        bankName
        branchCode
        accountNo
        accountHolderId
        accountHolderName
        accountHolderEmail
        accountHolderContactNumber
        accountHolderIdType
        accountHolderIdNumber
        ownAccount
      }
    }`
}

let FormLayout =(props) => {

  const { mode, history, cancel, fields, data, formName, ...rest } = props
  const [ownAccount, setOwnAcount] = useState(true)
  const [disableFields, setDisableFields] = useState(false)

  useEffect(() => {
    if (data && data.bankaccount && data.bankaccount.accountHolderId) {
      setDisableFields(true)
    }
  }, [data]);

  console.log('bankinfo rest', rest)

  // if (disableFields) {
  //   ['accountHolderName',
  //     'accountHolderEmail',
  //     'accountHolderContactNumber',
  //     'accountHolderIdType',
  //     'accountHolderIdNumber'].forEach(field => config.fields[field].addOnly = true)
  // }

  const filtered = (Object.keys(fields).filter(fieldName => !fields[fieldName].neverShow && (!fields[fieldName].hide || (fields[fieldName].hide && !ownAccount))))

  return (
    <>
      <FieldValue name={`ownAccount`} renderField={({ input, value }) => {
        setOwnAcount(input.value || value)
        return (<div />)
      }} />

      <BuildLayout formName={formName} fieldNames={filtered} mode={mode} fieldConfig={config.fields} direction='column' spacing={2} align='center' justify='center' cancel={cancel} push={() => history.push('/bankaccounts')} />

    </>
    );
}


FormLayout = withRouter(FormLayout)

let BankInfo = (props) => {
  const { id, clientId, groupId, cancel, setBank } = props
  const mode = id ? 'edit' : 'add'

  let initialValues
  if (mode === 'add') {
    initialValues = {
      ownAccount: true
    }
  }

  return (
    <div>
      <GraphQLFormProvider
        mode={mode}
        id={id}
        {...config}
        afterSubmit={(data) => {
          if (!id && data.data.createBankAccount) {
            setBank({ ...data.data.createBankAccount })
          } 
          cancel()
          }
        }
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout formName={config.name} {...props} cancel={cancel} />}
        DisplayFormLayout={FormLayout}
        initialValuesFromData={data => {
          const { bankaccount } = data || {}
          if (bankaccount && bankaccount.accountHolderIdType == null) {
            bankaccount.accountHolderIdType = 'rsa_id' 
          } 
          return (bankaccount)
        }}
        initialValues={initialValues}

        mapInputVariables={async input => {

          let { idNumberMatch, nameMatch, phoneMatch, accountExists, client, ...data } = input

          if (data.branchCode) {
            data.bankName = await findBank(data.branchCode)
          }

          if (data.ownAccount) {
            data.accountHolderIdType = null
            data.accountHolderIdNumber = null
            data.accountHolderName = null
            data.accountHolderContactNumber = null
            data.accountHolderEmail = null
          }
          

          if (data.accountHolderIdNumber == null) {
            data.ownAccount = true
          }

          return ({ input: { ...data, clientId, groupId } })
        }}  // defining the input to use the input needed in the update mutation. 
      />
    </div>
  )
}

BankInfo = withApollo(BankInfo);
export default BankInfo
