import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, withApollo } from 'react-apollo';
import { GraphQLFormProvider } from 'react-form-helper'
import { ActivityIndicator, Card, renderInput, renderDisplay, BuildLayout } from '../generic';
import { makeStyles } from '@material-ui/core/styles';
import { isValidCellphoneNumber, isValidEmail, isValidIdNumber, isInteger, isRequiredForForeignID, isRequired, getDOBFromID } from '../../lib/validations'
import gql from 'graphql-tag';
import { GET_GROUP } from './Group'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    paddingBottom: 7
  },
  paper: {
    minWidth: 300
  },
  card: {
    maxWidth: 800,
    marginLeft: '20%',
  }
}));

const config = {
  name: 'roleholder',
  fields: {
    idNumber: {
      type: 'text',
      label: 'ID Number *',
      addOnly: true,
      validate: [isRequired, isValidIdNumber],
    },
    firstNames: {
      type: 'name',
      label: 'First Name *',
      validate: (value) => {
        if (!value) {
          return 'First name is Required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    },
    surname: {
      type: 'name',
      label: 'Last Name *',
      validate: (value) => {
        if (!value) {
          return 'Last name is required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    }, 
    email: {
      type: 'text',
      label: 'Email *',
      validate: [isValidEmail]
    }, 
    'cellNumbers[0]': {
      type: 'text',
      label: 'Cell Number *',
      validate: [isValidCellphoneNumber]
    },
    whatsAppNumber: {
      type: 'text',
      label: 'Whatsapp Number',
      validate: [isValidCellphoneNumber]
    },
    preferredLanguage: {
      label: "Preferred Language *",
        type: "select",
        options: [{ value: "English", label: "English" },
          { value: "Afrikaans", label: "Afrikaans" },
          { value: "Zulu", label: "Zulu" },
          { value: "Xhosa", label: "Xhosa" },
          { value: "Northern Sotho", label: "Northern Sotho" },
          { value: "Tswana", label: "Tswana" },
          { value: "Sotho", label: "Sotho" },
          { value: "Tsonga", label: "Tsonga" },
          { value: "Swati", label: "Swati" },
          { value: "Venda", label: "Venda" },
          { value: "Ndebele", label: "Ndebele" }],
          validate: (value) => {
            if (!value) {
              return 'Preferred language is required'
            }
          }
    },
    "gender": {
      label: "Gender *",
      type: "select",
      options: [{ value: "MALE", label: "MALE" }, { value: "FEMALE", label: "FEMALE" }],
    },
    "birthDate": {
      type: 'date', 
      label: 'Birth Date *',
      validate: [isRequired]
    },
  },
  query: gql`
    query GetClient($id: String!) {
      client(id: $id) {
      id
      idNumber
      idType
      firstNames
      surname
      cellNumbers
      whatsAppNumber
      email
      preferredLanguage
      gender
      birthDate
    }
	}
  `,
  updateMutation: gql`
    mutation updateClient($id:String!, $input: UpdateClientInput!) {
      updateClient(id: $id, input: $input) {
        id
        idNumber
        firstNames
        surname
        birthDate
        preferredLanguage
        cellNumbers
        whatsAppNumber
        email
        gender
      }
    }
  `,
  addMutation: gql`
    mutation createClient($id:String!, $input: CreateClientInput!) {
      createClient(id: $id, input: $input) {
        id
        idNumber
        firstNames
        surname
        birthDate
        preferredLanguage
        cellNumbers
        whatsAppNumber
        email
        gender       
      }
    }
  `
}

const ADD_ROLEHOLDER = gql`
	mutation($groupId: ObjID!, $roleHolder: GroupRoleHolderInput!) {
		addRoleHolderToGroup(groupId: $groupId, roleHolder: $roleHolder ) {
      id
      name
      roleHolders {
        clientId
        role
        idDocumentId
        selfieId
        signatureId
        client {
          id
          idNumber
          firstNames
          surname
          birthDate
          preferredLanguage
          cellNumbers
          whatsAppNumber
          email
          gender
        }
      }
      products {
        id
      }
    }
	}
`;

let FormLayout = (props) => {
  const { mode, history, data, apolloClient, formName } = props
  const classes = useStyles();

  const { loading, client } = data || {}

  if (loading) {
    return <ActivityIndicator />;
  }
  
  return (
    <Card className={classes.card} >
        <br />
      <BuildLayout formName={formName} fieldNames={Object.keys(config.fields)} cancel={() => history.push('/groups')} mode={mode} fieldConfig={config.fields} direction='column' spacing={2} />
        <br />
    </Card>
  )
}

let RoleHolder = (props) => {
  const { history, match } = props
  let { idNumber, clientId, groupId, role } = match && match.params || {}
  const [ addRoleHolder ] = useMutation(ADD_ROLEHOLDER)
  const birthDate = getDOBFromID(idNumber)

  return (
    <GraphQLFormProvider
      mode={clientId ? "edit" : "add"}
      id={clientId}
      {...config}
      initialValues={{ idNumber, birthDate }}
      initialValuesFromData={data => {
        console.log('Roleholder data', data);
        return data.client;
      }}
      renderDisplay={renderDisplay}
      renderInput={renderInput}
      InputFormLayout={(layoutProps) => <FormLayout {...props} {...layoutProps} formName={config.name} />}
      DisplayFormLayout={FormLayout}
      afterSubmit={data => {
        const clientId = data.data.updateClient.id
        addRoleHolder({variables: 
          { 
            groupId, 
            roleHolder: 
              {
                clientId,
                role
              } 
          }, refetchQueries: [{ query: GET_GROUP, variables: { id: groupId } }]
        })
        history.push(`/groups/${groupId}`)
      }}
    />
  )
}
RoleHolder = withApollo(RoleHolder);
export default RoleHolder