import React, { Component } from 'react'
import gql from 'graphql-tag'
import { GraphQLFormProvider, FormField } from 'react-form-helper'
import { withRouter } from 'react-router'
import { BuildLayout, Card, renderInput, renderDisplay } from '../generic';

const config = {
  name: 'lead',
  fields: {
    idNumber: {
      type: 'text',
      label: 'ID Number'
    },
    campaignDescription:{ 
      type: 'text',
      label: 'Campaign'},
    firstName: {
      type: 'name',
      label: 'First Name'
    },
    surname: {
      type: 'name',
      label: 'Last Name'
    },
    cellNumber: {
      type: 'text',
      label: 'Cell'
    },
    email: {
      type: 'text',
      label: 'email'
    },
    contacted: {
      type: 'switch',
      label: 'Contacted'
    },
  },
  query: gql`
    query Lead($id: String!) {
      lead(id: $id) {
    id
    idNumber
    firstName
    surname
    cellNumber
    email
    campaignDescription
    leadMessageSent
    contacted
    updatedAt
    clientId
    }
	}
  `,
  updateMutation: gql`
    mutation UpdateLead($input: UpdateLeadInput!, $id: String!) {
      updateLead(id: $id, input: $input) {
        id
    idNumber
    firstName
    surname
    cellNumber
    email
    campaignDescription
    leadMessageSent
    contacted
    updatedAt
    clientId
    }
    }
  `,

  addMutation: gql`
    mutation CreateLead($input: CreateLeadInput!) {
      createLead(input:$input ) {
      id
    }
    }
  `
}


function FormLayout(props) {
  const { mode, history, formName } = props

    return (
      <Card>
        <BuildLayout formName={formName} fieldNames={Object.keys(config.fields).filter(fieldName => (mode) === 'edit' || !config.fields[fieldName].noAdd)} mode={mode} fieldConfig={config.fields} cancel={() => history.push('/leads')} direction='column' spacing={2} />

      </Card>
    );
}

FormLayout = withRouter(FormLayout);

let Lead = (props) => {

  const { params, history } = props.match
    const {id} = params

    return (
      <div>
        <GraphQLFormProvider
          mode="edit"
          id={id}
          {...config}
          afterSubmit={(data) => { history.push('/leads') }}
          renderDisplay={renderDisplay}
          renderInput={renderInput}
          InputFormLayout={(props) => <FormLayout {...props} formName={config.name} /> }
          DisplayFormLayout={FormLayout}
        />
      </div>
    )
}
export default Lead
