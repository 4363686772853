import React, { Component, Fragment } from 'react'
import gql from 'graphql-tag'
import 'numeral/locales/en-za'
import moment from 'moment'
import Numeral from 'numeral'
import { printCurrency } from '../../lib/helpers'
import Selector from '../generic/Selector'
import { Card, ActivityIndicator, Button, Modal, Grid, Select, Confirm } from '../generic'
import ClientObligations from './ClientObligations'
import { connect } from 'react-redux'
import { isInvalid, submit } from 'redux-form'
import { creditLifeInformation } from './CreditLife'

Numeral.locale('en-za') // switch between locales

const coveredLoanType = [
  "PERSONAL_LOAN",
  "STUDENT_LOAN",
  "REVOLVING_LOAN",
  "CREDIT_CARD"
]

const getAgeFromIdNumber = (idNumber) => moment().diff(moment(((idNumber[0] === '0' ? '20' : '19') + idNumber).substring(0,8), "YYYYMMDD"), "years")

const SEND_QUOTE = gql`
  mutation SendQuote($id: String, $productType: CreditLifeProductType, $termsVersion: CreditLifeTermsVersion, $obligations: [ObligationInsuranceInput]) {
    sendCreditLifeQuote(id: $id, termsVersion: $termsVersion, productType: $productType, obligations: $obligations, matchDiscount: 0) {
      id
      policyStatus
      productType
      termsVersion
    }
  }
`

const REJECT_QUOTE = gql`
  mutation RejectQuote($id: String, $reason: String) {
    rejectCreditLifeQuote(id: $id, reason: $reason) {
      id
      policyStatus
      reason
    }
  }
`

const SEND_TERMS = gql`
  mutation sendTerms($input: CreateClientInput!, $id: String, $productType: String, $productId: String) {
    sendTerms(input: $input, id: $id, productType: $productType, productId: $productId)
  }
`

const CLI_PACKAGES = [
  { value: "NORMAL", label: "NORMAL" },
  { value: "REJECTED", label: "REJECTED" }
]

const AFRICAN_BANK_PACKAGES = [
  { value: "EXTRA", label: "EXTRA" },
  { value: "NORMAL", label: "NORMAL" },
  { value: "REJECTED", label: "REJECTED" }
]

const YALU_PACKAGES = [
  { value: "PLAN_A", label: "PLAN_A" },
  { value: "PLAN_B", label: "PLAN_B" },
  { value: "PLAN_C", label: "PLAN_C" },
  { value: "PLAN_D", label: "PLAN_D" },
  { value: "REJECTED", label: "REJECTED" }
]

const OBLIGATION_INSURANCE_STATUS = [
  { value: "PENDING", label: "PENDING" },
  { value: "COVERED", label: "COVERED" },
]

const LoanRow = ({ loan, onPackageChange, onStatusChange, termsVersion, productType, editLoan, editable }) => (
  <tr>
    <td style={{ textAlign: 'left', width: 10 }}>
      <input
        type="checkbox"
        checked={loan.selectedPackage !== 'REJECTED' && loan.currentBalance != 0}
        onChange={() => {
          if (loan.selectedPackage !== 'REJECTED') {
            onPackageChange('REJECTED' )
          } else {
            onPackageChange('NORMAL' )
          }
        }}
      />
    </td>
    <td style={{ textAlign: 'left' }}>{loan.clientCreditor.description}</td>
    <td style={{ textAlign: 'left' }}>
      {loan.status}
      {/* <StatusOptions
        selected={loan.status}
        onChange={onStatusChange}
        productType={productType}
      /> */}
    </td>
    <td style={{ textAlign: 'right' }}>{printCurrency(loan.currentBalance)}</td>
    <td style={{ textAlign: 'right' }}>{loan.originalPayment.rate.toFixed(2)}%</td>
    <td style={{ textAlign: 'center' }}>{(loan.originalPayment.installment)}</td>
    <td style={{ textAlign: 'left' }}>{moment(loan.inceptionDate).format('DD-MM-YYYY')}</td>
    {/* <td style={{ textAlign: 'left' }}>{loan.loanType}</td> */}
    <td style={{ textAlign: 'left'}}>{loan.reference}</td>

    <td style={{ textAlign: 'center', width :150}}>
      <PackageOptions
        selected={loan.selectedPackage}
        onChange={onPackageChange}
        loanAmount={loan.currentBalance}
        options={productType === 'OPEN_MARKET' ? YALU_PACKAGES : termsVersion === 'BASIC' ? [{ 'label': 'NORMAL', 'value': 'NORMAL' }] : [{ 'label': 'NORMAL', 'value': 'NORMAL' }, { 'label': 'EXTRA', 'value': 'EXTRA' }]}
      />
    </td>
    <td style={{ textAlign: 'right' }}>{loan.selectedPackage == 'REJECTED' || loan.currentBalance == 0 ? loan.rejectedReason != '' ? loan.rejectedReason : loan.currentBalance === 0 ? 'zero balance' : 'admin rejected' : ''} </td>
    <td style={{ textAlign: 'center' }}>{printCurrency(loan.previousPremium)}</td>
    <td style={{ textAlign: 'center' }}>{printCurrency(loan.calcPremium)}</td>
    <td style={{ textAlign: 'center' }}>{printCurrency(loan.quote)}</td>
    <td style={{ textAlign: 'center' }}>{loan.quote ? Math.round(((loan.calcPremium - loan.quote) / loan.calcPremium) * 100) : "-"} %</td>
    <td>
      {(1 != 1) && <Button style={{ display: editable ? 'inline' : 'none' }} onClick={() => editLoan(loan.id)}>Edit</Button>}
    </td>
  </tr>
)

export const SelectTermsVersion = ({ selected, onChange }) => <Selector options={['BASIC', 'CORE']} onChange={onChange} selected={selected} />

const PackageOptions = ({ onChange, selected, loanAmount, options, }) => {
  return ( selected === 'REJECTED' || loanAmount == 0) 
  ? (  <span>REJECTED</span> ) 
  : options.length === 1 
  ? ( <span>{selected || 'NORMAL'}</span> ) 
  : ( <div>
        <Select
            value={selected ? `${selected}` : 'NORMAL' }
            onChange={(e) => onChange(e.target.value)}
            options={options}
          />
        </div>
      )
}

const StatusOptions = ({ onChange, selected, productType }) => {
  // console.log('SelectOptions', selected, productType)
  if (productType !== 'OPEN_MARKET') {
    return null
  }
  else return <Select
    isClearable={false}
    value={selected ? `${selected}` : 'PENDING'}
    onChange={(e) => onChange(e.target.value)}
    options={OBLIGATION_INSURANCE_STATUS}
  />
}

const LoanSummary = ({ loans, onPackageChange, onStatusChange, termsVersion, productType, editLoan, editable }) => (
  <div>
    <table style={{ width: '100%', marginTop: 21 }}  key={loans.reference}>
      <thead>
        <tr style={{ color: '#404357', height: 21 }}>
          <th />
          <th style={{ textAlign: 'left' }}>Creditor</th>
          <th style={{ textAlign: 'left' }}>Status</th>
          <th style={{ textAlign: 'right' }}>Loan Value</th>
          <th style={{ textAlign: 'right' }}>Interest</th>
          <th style={{ textAlign: 'center' }}>Monthly Payment</th>
          <th style={{ textAlign: 'left' }}>Inception</th>
          {/* <th style={{ textAlign: 'left' }}>Type</th> */}
          <th style={{ textAlign: 'left' }}>Reference</th>
          <th style={{ textAlign: 'center' }}>Package</th>
          <th style={{ textAlign: 'center' }}>Reason</th>
          <th style={{ textAlign: 'right' }}>Previous</th>
          <th style={{ textAlign: 'right' }}>Calculated</th>
          <th style={{ textAlign: 'center' }}>Quote</th>
          <th style={{ textAlign: 'right' }}>Discount</th>
        </tr>
      </thead>
      <tbody>
        {loans.map((loan, index) => <LoanRow termsVersion={termsVersion} productType={productType} loan={loan} editLoan={editLoan} editable={editable} onPackageChange={pkg => onPackageChange(loan, pkg)} onStatusChange={status => onStatusChange(loan, status)} />)}
      </tbody>
    </table>
  </div>
)

export const setLoanPackage = (loan, basicListed, termsVersion, productType) => {

  if (productType === "OPEN_MARKET") {
    let loanPackage = "PLAN_C"
    let reason

    if (loan.loanType === 'PERSONAL_LOAN') {
      loanPackage = "PLAN_A"
      reason = "PERSONAL LOANS"
      if (loan.clientCreditor && loan.clientCreditor.description) {
        switch (loan.clientCreditor.description.toUpperCase()) {
          case "ABSA" ||
            "1ST FOR WOMEN" ||
            "AFRICAN BANK" ||
            "BAYPORT" ||
            "BUDGET INSURANCE" ||
            "CLIENTELE" ||
            "DIRECTAXIS" ||
            "FNB" ||
            "IZWE LOANS" ||
            "MAKRO" ||
            "OLD MUTUAL FINANCE" ||
            "RCS" ||
            "SANLAM" ||
            "UBANK" ||
            "WESBANK" ||
            "WOOLWORTHS":
            loanPackage = "PLAN_A"
            break
          case 'CAPITEC' || 'NEDBANK':
            loanPackage = "PLAN_B"
            break
          case 'STANDARD BANK':
            loanPackage = "PLAN_B"
            break
          default:
            break
        }
      }
    }
    else if (loan.loanType === 'STUDENT_LOAN') {
      reason = "STUDENT LOANS"
      if (loan.clientCreditor && loan.clientCreditor.description) {
        switch (loan.clientCreditor.description.toUpperCase()) {
          case "ABSA" ||
            "1ST FOR WOMEN" ||
            "FNB" ||
            "NEDBANK" ||
            "FUNDI CAPITAL" ||
            "NATIONAL STUDENT FINANCIAL AID SCHEME":
            loanPackage = "PLAN_A"
            break
          case 'STANDARD BANK':
            loanPackage = "PLAN_B"
            break
          default:
            loanPackage = "PLAN_A"
            break
        }
      }
    }
    else if (loan.loanType === 'REVOLVING_LOAN') {
      reason = "REVOLVING_LOAN"
      if (loan.clientCreditor && loan.clientCreditor.description) {
        switch (loan.clientCreditor.description.toUpperCase()) {
          case "ABSA" ||
            "1ST FOR WOMEN" ||
            "AFRICAN BANK" ||
            "BAYPORT" ||
            "BUDGET INSURANCE" ||
            "CLIENTELE" ||
            "DIRECTAXIS" ||
            "FNB" ||
            "IZWE LOANS" ||
            "MAKRO" ||
            "NEDBANK" ||
            "OLD MUTUAL FINANCE" ||
            "RCS" ||
            "SANLAM" ||
            "UBANK" ||
            "WESBANK" ||
            "WOOLWORTHS":
            loanPackage = "PLAN_C"
            break
          case 'CAPITEC':
            loanPackage = "PLAN_D"
            break
          case 'STANDARD BANK':
            loanPackage = "PLAN_D"
            break
          default:
            loanPackage = "PLAN_C"
            break
        }
      }
    }

    else if (loan.loanType === 'CREDIT_CARD') {
      reason = "CREDIT_CARD"
      if (loan.clientCreditor && loan.clientCreditor.description) {
        switch (loan.clientCreditor.description.toUpperCase()) {
          case "ABSA" ||
            "AFRICAN BANK" ||
            "FNB" ||
            "MAKRO" ||
            "NEDBANK" ||
            "RCS" ||
            "UBANK" ||
            "WESBANK" ||
            "WOOLWORTHS":
            loanPackage = "PLAN_C"
            break
          case "CAPITEC":
            loanPackage = "PLAN_D"
            break
          default:
            loanPackage = "PLAN_C"
            break
        }
      }
    }

    // loan type 

    return { package: loanPackage, reason }
  }

  else {

    if (loan.package === "REJECTED") {
      let reason = loan.reason
      if (!reason) { reason = "admin rejected" }
      return { package: "REJECTED", reason }
    }

    if (loan.accountCode && loan.accountCode === 'SM') {
      return {package: "REJECTED", reason:"Home Loan"}
    }

    /* Reject African Bank with previous insurance prior to March 2018
    if (loan.clientCreditor.description && loan.clientCreditor.description.toLowerCase().indexOf('african bank') > -1 && previousPremium != 0 && loan.inceptionDate < moment("2018/04/01").valueOf()) {
      console.log('before 2018/04/01')
      return {package: "REJECTED", reason:"previous < 2018/04/01"}
    }*/

    if (loan.clientCreditor.description && loan.clientCreditor.description.toLowerCase().indexOf('african bank') > -1) {
      return {package: "EXTRA", reason:"African Bank"}
    }

    // Check for previous insurance
    let previousPremium = 0
    if (loan.creditLifeInsurance && loan.creditLifeInsurance > 0) {
      previousPremium = loan.creditLifeInsurance
    }
      else if (loan.shortTermInsurance === 0) { previousPremium = loan.insuranceAmount }

    // Reject Lewis stores with previuos insurance
    if (loan.clientCreditor.description && loan.clientCreditor.description.toLowerCase().indexOf('lewis') > -1 && previousPremium != 0 && loan.inceptionDate > moment("2018/07/31").valueOf()) {
      //console.log('before 2018/07/31')
      return {package: "REJECTED", reason:"previous > 2018/07/31"}
    }

    // Reject Capitec with start date before 2016/05/06 moment(loan.inceptionDate).format('DD-MM-YYYY') < "2016/05/06"
    if (loan.clientCreditor.description && loan.clientCreditor.description.toLowerCase().indexOf('capitec') > -1 && loan.inceptionDate < moment("2016/05/06").valueOf() && previousPremium != 0) {
      return {package: "REJECTED", reason:"previous < 2016/05/06"}
    }

    // Reject Bayport with start date before 2016/02/01
    if (loan.clientCreditor.description && loan.clientCreditor.description.toLowerCase().indexOf('bayport') > -1 && loan.inceptionDate > moment("2016/02/01").valueOf() && previousPremium != 0) {
      return {package: "REJECTED", reason:"previous > 2016/02/01"}
    }

    // Reject direct axis with start date before 2011/10/01
    if (loan.clientCreditor.description && loan.clientCreditor.description.toLowerCase().indexOf('direct axis') > -1 && loan.inceptionDate > moment("2011/10/01").valueOf() && previousPremium != 0) {
      //console.log('Rejected Direct Axis')
      return {package: "REJECTED", reason:"previous > 2011/10/01"}
    }

    // Reject loans with previous insurance which are on Basic for excluded industried / employers

    if (previousPremium != 0 && basicListed && termsVersion == 'BASIC') {
      //console.log(' Basic listed: this should be rejected')
      return {package: "REJECTED", reason:"basic/hazardous"}
    }

    return {package: "NORMAL", reason:""}
  }
}

const setPackage = (obligation, termsVersion, premiumAmount) => {

  let result = { ...obligation }

  /*if (obligation.previousPremium && premiumAmount > obligation.previousPremium) {
    result.package = "REJECTED"
    result.rejectReason = "current is lower"
  }*/
 if (obligation.previousPremium != 0 ) {
    if (termsVersion == 'BASIC') {
    // Reject loans with previous insurance which are on Basic for excluded industried / employers
      result.package = "REJECTED"
      result.rejectReason = "basic/hazardous"
  } else if (result.rejectReason == "basic/hazardous") {
    // reset package if previously rejected due to hazardous and BASIC
    //console.log('Basic listed core so not rejected '+ termsVersion)
    result.package = 'NORMAL'
    result.rejectReason = ''
    }
  }
  // console.log('setPackage 2 ', result.id)

  return result
}

export class EditClientObligationModal extends Component {
  /*shouldComponentUpdate(nextProps) {
    return (nextProps.open !== this.props.open) || (this.props.loanId !== nextProps.loanId)
  }*/

  render() {
    return (
      <Modal
        title="Edit Client Obligation"
        width='90%'
        okText='Save Changes'
        cancelText='Cancel Changes!'
        wrapClassName="vertical-center-modal"
        open={this.props.open}
        onOk={() => {
          this.props.submit();
          this.props.setLoanModalVisible(false)
        }}
        okButtonProps={{ disabled: this.props.invalid, icon: "save", shape: "round" }}
        cancelButtonProps={{ type:'danger',icon: "rollback", shape:"round" }}
        onCancel={() => this.props.setLoanModalVisible(false)}
        onClose={() => this.props.setLoanModalVisible(false)}
      >
       <ClientObligations id={this.props.loanId} mode={'edit'} />
      </Modal>
    )
    }
}

EditClientObligationModal = connect((state) => ({
  invalid: isInvalid('clientobligation')(state)
}), (dispatch) => ({ submit: () => { console.log('submitting'); dispatch(submit('clientobligation')) } }))(EditClientObligationModal)


export default class CreditLifeQuote extends Component {

  constructor(props) {

    super(props)
    this.state = {
      showConfirm: false,
      acceptedTerms: this.props.acceptedTerms, 
      showActivity: false,
      clientId: props.clientId,
      smoker: props.smoker,
      quote: [],
      calcPremiums: [],
      termsVersion: props.termsVersion ? props.termsVersion : props.basicListed ? 'BASIC' : 'CORE',
      productType: props.productType,
      basicListed: props.basicListed || false,
      totalAmount: null,
      loading: false,
      open: false,
      modal2Visible: false,
      loanModalVisible: false,
      loanId:'',
      disableClick : false,
      declinedReason:'Client Affordability',
      obligations: props.loans.filter(l => (l.currentBalance > 0 && (props.productType !== 'OPEN_MARKET' || (l.loanType && coveredLoanType.includes(l.loanType))))).map(loan => ({
        amount: loan.currentBalance,
        package: setLoanPackage(loan, props.basicListed || false, props.basicListed ? 'BASIC' : 'CORE', props.productType).package,
        rejectReason: setLoanPackage(loan, props.basicListed || false, props.basicListed ? 'BASIC' : 'CORE', props.productType).reason,
        status: this.props.productType === 'OPEN_MARKET' ? "COVERED" : null,
        id: loan.externalId || loan.id,
        obligationId: loan.id,
        previousPremium: loan.creditLifeInsurance && loan.creditLifeInsurance > 0 ? loan.creditLifeInsurance : loan.shortTermInsurance === 0 ? loan.insuranceAmount : 0
      }))
    }
  }


  componentDidMount() {
    this.getQuote()
  }

  sendQuote() {
    this.setState({ showActivity: true, showConfirm: false })
    // console.log('sendQuote ', this.state.obligations, this.props.productType, this.state.obligations, this.state.termsVersion)
    this.props.client
      .mutate({
        mutation: SEND_QUOTE,
        variables: {
          id: this.props.creditLifeId,
          termsVersion: this.state.termsVersion,
          obligations: this.state.obligations,
          productType: this.props.productType
        },
        refetchQueries: [{ query: creditLifeInformation, variables: { id: this.props.creditLifeId } }]
      })
      .then(() => {
        this.setState({ showActivity: false })
      })
  }

  getQuote() {
    // console.log('getQuote 1 ', this.state.productType, this.state.termsVersion, this.state.obligations, this.state.productType === 'OPEN_MARKET' ? 'OPEN_MARKET_RGA' : this.props.pricingVersion, this.props.idType === 'rsa_id' ? getAgeFromIdNumber(this.props.idNumber) : null)

    //const quoteObligations = this.state.obligations.filter(o => this.state.productType !== 'OPEN_MARKET' || o.status === 'COVERED')
    const quoteObligations = this.state.obligations

    if (quoteObligations.length > 0) {
    this.props.client
      .query({
        query: gql`
          query CreditLifeQuote($productType: CreditLifeProductType, $termsVersion: CreditLifeTermsVersion, $pricingVersion: String $obligations: [ObligationInsuranceInput], $age: Int) {
            getCreditLifeQuote(productType: $productType, termsVersion: $termsVersion, pricingVersion: $pricingVersion, obligations: $obligations, matchDiscount: 0, age: $age) {
              priceBreakdown {
                id
                amount
                calcPremium
                premiumAmount
                matchPremium
                matchPremiumDiscount
              }
              price
              minPrice
            }
          }
        `,
        variables: {
          productType: this.state.productType,
          termsVersion: this.state.termsVersion,
          obligations: quoteObligations,
          pricingVersion: this.state.productType === 'OPEN_MARKET' ? 'OPEN_MARKET_RGA' : this.props.pricingVersion,
          age: this.props.idType === 'rsa_id' ? getAgeFromIdNumber(this.props.idNumber) : null
        }
      })
      .then(data => {
        // console.log('getQuote 2', data.data.getCreditLifeQuote, quoteObligations, this.state.obligations)
        this.setState(
          {
            quote: this.state.obligations.map(
              (obligation, index) => {
                const breakDown = data.data.getCreditLifeQuote.priceBreakdown.filter(pb => obligation.id.toString() === pb.id.toString())
                if (breakDown[0] && (!obligation.previousPremium || breakDown[0].premiumAmount <= obligation.previousPremium)) {
                  return breakDown[0].premiumAmount
                } else return 0
              }
            ),
            calcPremiums: this.state.obligations.map(
              (obligation) => {
                const breakDown = data.data.getCreditLifeQuote.priceBreakdown.filter(pb => obligation.id.toString() === pb.id.toString())
                if (breakDown[0]) {
                  return breakDown[0].calcPremium
                } else return 0
              }
            ),
            obligations: this.state.obligations.map(
              (obligation, index) => {
                const breakDown = data.data.getCreditLifeQuote.priceBreakdown.filter(pb => obligation.id.toString() === pb.id.toString())
                if (breakDown[0]) {
                  return setPackage(obligation, this.state.termsVersion, breakDown[0].premiumAmount)
                } else return obligation
              }
            ),
            totalAmount: data.data.getCreditLifeQuote.price - quoteObligations.reduce((total, obligation, index) => total + (obligation.previousPremium && data.data.getCreditLifeQuote.priceBreakdown[index].premiumAmount > obligation.previousPremium ? data.data.getCreditLifeQuote.priceBreakdown[index].premiumAmount : 0), 0),
            minPrice: data.data.getCreditLifeQuote.minPrice
          }
        )
      })
    }
    else {
      this.setState(
        {
          totalAmount: 0,
          minPrice: 0
        })
    }
  }
  changeLoanPackage(loan, value) {
    this.setState(
      {
        obligations: this.state.obligations.map((obligation, idx) => 
          obligation.obligationId.toString() === loan.id.toString() ? { ...obligation, package: value, rejectReason: value == 'REJECTED' ? 'admin rejected' : '' } : obligation
        )
      },
      () => this.getQuote()
    )
  }

  changeLoanStatus(loan, status) {
    this.setState(
      {
        obligations: this.state.obligations.map((obligation, idx) =>
          obligation.obligationId.toString() === loan.id.toString() ? { ...obligation, status } : obligation
        )
      },
      () => this.getQuote()
    )
  }

  changeTermsVersion(termsVersion) {
    //console.log('termsVersion ', termsVersion )
    this.setState(
      {
        termsVersion,
        obligations:
          termsVersion === 'BASIC'
            ? this.state.obligations.map(
                obligation => (obligation.package === 'EXTRA' ? { ...obligation, package: 'NORMAL' } : obligation)
              )
            : this.state.obligations.map(
              obligation => (obligation.package === 'REJECTED' && obligation.rejectReason === 'basic/hazardous' ? { ...obligation, package: 'NORMAL', rejectReason: '' } : obligation)
            )
      },
      () => this.getQuote()
    )
  }


  setModal2Visible(modal2Visible) {
    this.setState({ modal2Visible });
  }

  editLoan = (loanId) => {
    console.log('edit loan called')
    this.setState({ loanModalVisible: true, loanId})
  }

  sendAcceptTerms() {
    this.setState({ disableClick: true })
    // message.loading('sending T&C LINK via SMS', 5);
    //console.log('send poa for client ' , this.state.clientId)
    this.props.client
      .mutate({
        mutation: SEND_TERMS,
        variables: {
          input: {},
          id: this.state.clientId,
          productType: "CREDIT_LIFE",
          productId: this.props.creditLifeId
        }
      })
      .then(() => this.setState({ disableClick: false })
    )
  }

  rejectTheQuote(declinedReason) {
    // message.loading('declining', 2);
    this.props.client
      .mutate({
        mutation: REJECT_QUOTE,
        variables: {
          id: this.props.creditLifeId,
          reason: declinedReason || 'Client Affordability'
        }
      })
      // .then(() => message.info('Appliction declined', 1) )
  }

  changeSmoker = v => {
    this.setState({ smoker: v === 'Yes' ? true : v === '?' ? null : false })
  }

  setLoanModalVisible = (open) => {
    this.setState({ loanModalVisible: open }, () => { console.log('updated') })
  }

  getStatus = (loan) => {
    // console.log('getStatus', this.state.obligations, loan)
    const obli = this.state.obligations.filter(o => o.obligationId.toString() === loan.id.toString())
    if (obli.length > 0) {
      if (obli[0].status) {
        return obli[0].status
      }
    }
    return loan.status ? loan.status : this.props.productType === 'OPEN_MARKET' ? "PENDING" : null
  }

  render() {
    const { loading, acceptedTerms } = this.state
    if (loading) return <h2> loading ... </h2>

    console.log('quote props ', this.props)
    console.log('quote obligations ', this.state.obligations)

    return (
      <Card>
        <center style={{ paddingBottom: 7 }}>
          {acceptedTerms ? this.props.loans.length > 0 &&

            <Fragment>
              <h2>QUOTE</h2>

              <div style={{ fontSize: 24, fontWeight: 'bold' }}>
                {printCurrency(this.state.totalAmount)}
              </div>

              <SelectTermsVersion
                selected={this.state.termsVersion}
                onChange={termsVersion => this.changeTermsVersion(termsVersion)}
              />

              <LoanSummary
                loans={this.props.loans.filter(l => (l.currentBalance > 0 && (this.props.productType !== 'OPEN_MARKET' || (l.loanType && coveredLoanType.includes(l.loanType))))).map((loan, index) => {
                  console.log('LoanSummary loan is', loan);
                  return ({
                    ...loan,
                    previousPremium: loan.creditLifeInsurance && loan.creditLifeInsurance >= 0 ? loan.creditLifeInsurance : loan.insuranceAmount,
                    calcPremium: this.state.calcPremiums[index],
                    selectedPackage: this.state.obligations[index].package,
                    rejectedReason: this.state.obligations[index].rejectReason,
                    quote: this.state.quote[index],
                    status: this.getStatus(loan),
                  })
                }
                )}
                onPackageChange={(loan, pkg) => this.changeLoanPackage(loan, pkg)}
                onStatusChange={(loan, status) => this.changeLoanStatus(loan, status)}
                termsVersion={this.state.termsVersion}
                productType={this.state.productType}
                editLoan={this.editLoan}
                editable={this.props.productType && this.props.productType.toUpperCase() == 'OPEN_MARKET'}
              />

              <table style={{ width: '83%', textAlign: 'left', marginTop:14, marginBottom:14 }}>
                <thead>
                  <tr style={{ color: '#404357', height: 30 }}>
                    <th style={{ textAlign: 'right'}}>Total Cover</th>
                    <th style={{ textAlign: 'right'}}>Total Previous Insurance</th>
                    <th style={{ textAlign: 'right'}}>Minumum Allowed Quote</th>
                  </tr>
                  <tr style={{ height: 20 }}>
                    <td style={{ textAlign: 'right'}}>
                      {printCurrency(
                        this.state.obligations.reduce(
                          (total, obligation) => total + (obligation.package === 'REJECTED' ? 0 : obligation.amount || 0),
                          0
                        )
                      )}
                    </td>
                    <td style={{ textAlign: 'right'}}>
                      {printCurrency(
                        this.state.obligations.reduce((total, obligation) => total + obligation.previousPremium || 0, 0)
                      )}
                    </td>
                    <td style={{ textAlign: 'right'}}>
                        {printCurrency(this.state.minPrice)}
                    </td>
                  </tr>
                </thead>
              </table>
            </Fragment>

            : <Card> <h1>QUOTE</h1>
              <h2>Client must accept T&C's before loans can be retrieved</h2></Card>}

          {this.state.minPrice > this.state.totalAmount && <p>
            <h3><b>The quote can not proceed as the total discount to match is too high - please review</b></h3>
          </p>}

          <Grid container style={{ flexGrow: 1, justifyContent: 'center' }} spacing={1}>
            <Grid item xs={2} align="middle">
              <Button color="secondary" onClick={() => this.setModal2Visible(true)}>
                Decline
            </Button>
            </Grid>

            {acceptedTerms && this.state.minPrice <= this.state.totalAmount && <Grid item xs={2} align="middle"><Button color="primary" small disabled={this.state.totalAmount < 1} onClick={() => this.setState({ showConfirm: true })}>
              Send Quote
            </Button>
            </Grid>}
            {!acceptedTerms && <Grid item xs={2} align="middle">
              <Button color="primary" disabled={this.state.disableClick || this.state.showActivity} onClick={() => this.sendAcceptTerms()}>
                Send T&C SMS
            </Button>
            </Grid>}
          </Grid>

          <Confirm open={this.state.showConfirm} title={this.state.totalAmount < 50 ? 'Warning: Premium too low' : 'Want to send a Quote?'} description={this.state.totalAmount < 50 ? 'The premium amount is below the R50 minimum' : 'Are you sure?'} onOk={() => { this.setState({ showConfirm: false }); this.sendQuote() }} onCancel={() => this.setState({ showConfirm: false })} />

          {this.state.showActivity && <ActivityIndicator center={false} />}
        </center>

        <Modal
          title="Do you want to decline the Application?"
          okText='Decline Application'
          cancelText='No, Go Back!'
          wrapClassName="vertical-center-modal"
          open={this.state.modal2Visible}
          onOk={() => {
            this.setModal2Visible(false)
            this.rejectTheQuote(this.state.declinedReason)
          }}
          onCancel={() => this.setModal2Visible(false)}
          onClose={() => this.setModal2Visible(false)}
          okButtonProps={{ shape: "round" }}
          cancelButtonProps={{ shape: "round" }}
        >
          Why are you declining this?&nbsp;
        <Select
            style={{ width: 200 }}
            options={rejectReasons}
            onChange={(v) => this.setState({ declinedReason: v.target.value })}
            placeholder="Select a reason"
          />
        </Modal>

        <EditClientObligationModal loanId={this.state.loanId} open={this.state.loanModalVisible} setLoanModalVisible={this.setLoanModalVisible} />
      </Card>
    )
  }
}
const rejectReasons = [{ value: "Client Affordability", label: "Client Affordability" }, { value: "Client Age", label: "Client Age" }, { value: "Below minimum premium", label: "Below minimum premium" }, { value: "Other", label: "Other" }]
