import React, { useState } from 'react'
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core/styles';
import { ActivityIndicator, Button, Card, Grid, TextField } from '../generic';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // justifyContent: 'space-evenly',
    padding: 21,
  }
}));


const GET_CLIENT = gql`
  query GetClientDetails( $filter: ClientFilter!) {
    clients(filter: $filter) {
      id
      acceptedTerms
  }
}
`
let OpenMarketPrompt = ({ history, client }) => {
  const classes = useStyles();

  const [idNumber, setIdNumber] = useState()
  const [spin, setSpin] = useState(false)

  const getClient = () => {
    setSpin(true)
    if (idNumber) {
      client.query({
        query: GET_CLIENT,
        variables: {
          filter: { idNumber }
        }
      })
        .then((result) => {
          setSpin(false)
          const { id } = result.data.clients && result.data.clients[result.data.clients.length - 1] || {}
          history.push(`/credit-life/openmarket/new/${id}/${idNumber}`)
        })
    }
  }

  return (
    <Card className={classes.root} >
      <h1>Create New Open Market Policy</h1>

      <br />
      <Grid container className={classes.root}>
        <Grid item xs={4}>
          <TextField label="Enter Id Number" key='idNumber' value={idNumber} onChange={(e) => setIdNumber(e.target.value)} />
        </Grid>

        <Grid item xs={1}>
          <Button color='secondary' onClick={() => history.push('/credit-life')}>Cancel</Button>
        </Grid>
        <Grid item xs={1}>
          <Button color="primary" onClick={getClient} >Start</Button>
        </Grid>
      </Grid>
      {spin && <ActivityIndicator />}
    </Card >
  );
}


OpenMarketPrompt = withRouter(OpenMarketPrompt);
export default OpenMarketPrompt = withApollo(OpenMarketPrompt);
