import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '../generic'

const useStyles = makeStyles({
  active: {
    fontWeight: 'bold',
    border: '1px solid #000'
  },
  not: {
    fontWeight: 'normal',
  },
});

const Selector = ({ selected, onChange, options }) => {
  const classes = useStyles();

  return (
    options.map(o => (
      <Button className={o === selected ? classes.active : classes.not} onClick={() => onChange && onChange(o)} >
        {o}
      </Button >
    )
    ))
}


export default Selector
