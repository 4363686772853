import React from 'react'
import TemplateList from './TemplateList'
import SmsTemplate from './SmsTemplate'
import EjsTemplate from './EjsTemplate'
import { Route, Switch } from 'react-router-dom'

const Templates = () => {
  return (
  <div>
    <Switch>
    <Route path="/communication-templates/:search?" exact component={TemplateList} />
        <Route path="/communication-templates/sms/new" exact render={() => <SmsTemplate mode={'new'} />} />
        <Route path="/communication-templates/ejs/new" exact render={({history}) => <EjsTemplate history={history} mode={'new'} />} />
        <Route path="/communication-templates/ejs/:id?" render={({ match, history }) => <EjsTemplate history={history} id={match.params.id} />} />
        <Route path="/communication-templates/sms/:id?" render={({ match }) => <SmsTemplate mode={'edit'} id={match.params.id} />} />
    </Switch>
  </div>
)}

export default Templates