import React, { useState } from 'react';
import styled from "@emotion/styled";
import { NavLink, withRouter } from 'react-router-dom';
// import Logo from '../Logo';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import menuOptions from './sidebarOptions';
import { logoutBlack } from '../../assets/images'

const SidebarNav = styled.nav`
	background:  #E9F4F4;
	padding-top:3px;
	width: 14rem;
	height: 100vh;
	display: flex;
	justify-content: center;
	position: fixed;
	top: 0;
	left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
	transition: 350ms;
	z-index: 10;
`;

const SidebarWrap = styled.div`
width: 100%;
`;


let Sidebar = ({ sidebar, showSidebar, currentUser, location }) => {

	const activeRoute = (routeName) => {
		return location.pathname.includes(routeName)
	}

	const getMenuItem = ({ singleOption }) => {
		const { key, label, title, leftIcon, icon } = singleOption;

		return (
			<li style={{ listStyleType: "none", marginLeft: "1em", height: 21, marginBottom: 7 }} key={key} >
				<NavLink to={`/${key}`} >
					<div style={{ radius: '16', fontSize: '1rem', fontWeight: activeRoute(`/${key}`) ? 600 : 300, color: "#404357" }} onClick={showSidebar}>
						{icon}
						&nbsp;&nbsp;{label}
					</div>
				</NavLink>
			</li>
		);
	};

	const inGroup = (groupName, user) => {
		return user && user.userGroups.map(g => g.identifier).includes(groupName)
	}

	return (<>
		<SidebarNav sidebar={sidebar}>
			<SidebarWrap>
				<IconButton onClick={showSidebar} >
					<CloseIcon />
				</IconButton>
				<br />
				{menuOptions.filter(option => { return inGroup(option.key, currentUser) }).map(singleOption => getMenuItem({ singleOption }))}
				<br />
				<li style={{ listStyleType: "none", marginLeft: "1em", height: 21, marginBottom: 7 }} key={'logout'} >
					<NavLink to="/logout" >
						<div style={{
							radius: '16', fontSize: '1rem', fontWeight: 300, color: "#404357",
						}} onClick={() => { props.history.push("/logout"); showSidebar() }}>
							Logout
							&nbsp;
							<img height="20px" src={logoutBlack} alt={'logout'} />
						</div>
					</NavLink>
				</li>
			</SidebarWrap>
		</SidebarNav>
	</>)
}

export default withRouter(Sidebar) 