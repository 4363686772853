import React from 'react';
import gql from 'graphql-tag';
import GraphQLTable from '../generic/GraphQLTable';
import { Card } from '../generic';

const config = {
  query: gql`
    {
      users (limit:10000 ) {
        id
        username
        email
        authProvider
        forEmployee {
          id
          first
          last
        }
      }
    }
  `,
  dataset: data => data.users,
  fields: {
    email: { label: 'Email Address' },
    forEmployee: { label: 'Employee', Cell: row => (row.value ? `${row.value.last}, ${row.value.first} ` : '-')  },
    username: { label: 'Username' },
    authProvider: { label: 'Authentication Provider' }
  },
  defaultSorted: [{ id: 'email' }]
};


const UserList = ({ history }) => (

  <Card>
    <div className="content">
      <GraphQLTable
        config={config}
        onRowPress={user => history.push(`/users/${user.id}`)}
      />
    </div>
  </Card>
);

export default UserList;
