import React from 'react'
import LeadsList from './LeadsList'
import Lead from './Lead'
import { Route } from 'react-router-dom'

const Leads = () => (
  <div>
    <Route path='/leads' exact component={LeadsList} />
    <Route path='/leads/:id/edit' exact component={Lead} />

  </div>
)

export default Leads
