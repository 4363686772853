import React, { useState } from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag'
import { v4 as uuidv4 } from 'uuid';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import SmsIcon from '@material-ui/icons/Sms';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Modal } from '.'
import { isStaging } from '../../config'

const DarkerDisabledTextField = withStyles({
  root: {
    minWidth: 120,
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.99)", // (default alpha is 0.38)
      padding: 0,
    },
    "& .MuiInputLabel-root": {
      padding: 0,
      fontSize: 20 // (default is 16)
    },
  }
})(TextField);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GET_CLIENT = gql`
  query Client($id: String!) {
    client(id: $id) {
      id
      cellNumbers
      firstNames
  }
  }`;

const SMS_CLIENT = gql`
  mutation CreateCommunication($input: CreateCommunicationInput!) {
    createCommunication(input: $input) {
      id
  }
  }`;

function EasyPayField(props) {
  const [message, setMessage] = useState()
  const [open, setOpen] = useState(false)
  const [snackOpen, setSnackOpen] = useState(false)
  const [mobile, setMobile] = useState()

  console.log('Environment isStaging ', isStaging())

  const Component = props.disabled ? DarkerDisabledTextField : TextField

  const smsClient = () => {
    setOpen(false)
    props.client
      .query({
        query: GET_CLIENT,
        variables: {
          id: props.clientId
        },
      })
      .then((response) => {
        setMobile(isStaging() ? '0736017306' : response.data.client.cellNumbers && response.data.client.cellNumbers.length > 0 ? response.data.client.cellNumbers[0] : undefined);
        setMessage(`Hi ${response.data.client.firstNames}, your EasyPay number is ${props.value}. Regards, Meerkat`)
        setOpen(true)
      })
  }

  const sendSms = () => {
    if (mobile) {
      const input = { to: mobile, message, type: "SMS", clientId: props.clientId }
      props.client
        .mutate({
          mutation: SMS_CLIENT,
          variables: {
            input
          },
        })
        .then(() => {
          setSnackOpen(true);
          setOpen(false)
        }
        )
        .catch(error => console.error('Error sending SMS:', error));
    }
  }

  const { value } = props;


  return (<>
    <Component
      {...props}
      id={uuidv4()}
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            {value && <SmsIcon fontSize="small" style={{ cursor: 'pointer', fontSize: 14, marginRight: 3, marginLeft: -7 }}
              onClick={() => smsClient()} />}
          </InputAdornment>
        )
      }}
    />

    <Modal
      title={`Do you want to sms the EasyPay number to ${mobile}?`}
      okText="Yes"
      cancelText="No"
      open={open}
      onClose={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      onOk={() => sendSms()}
    >
      <TextField fullWidth variant="outlined" value={message} required label='Message to SMS' onChange={(e) => setMessage(e.target.value)} />
    </Modal>

    <Snackbar open={snackOpen} anchorOrigin={{ vertical: 'center', horizontal: 'center' }} autoHideDuration={6000} onClose={() => setSnackOpen(false)}>
      <Alert onClose={() => setSnackOpen(false)} severity='success' >
        {`The EasyPay number was sent to the client at ${mobile}`}
      </Alert>
    </Snackbar>
  </>
  );
}

export default withApollo(EasyPayField);

