import React from 'react'
import PaymentStatusList from './PaymentStatusList'
import PaymentHistory from './PaymentHistory'
import { Route } from 'react-router-dom'

const Payments = () => (
  <div>
    <Route path='/payment' render={({ history }) => <PaymentStatusList history={history}  />} />
  </div>
)

export default Payments
