import React from 'react';
import { Text } from '../generic';
import { mokuPopup } from '../../assets/images';

const unavailableStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  minHeight: '500px',
  height: '100%',
  padding: '30px',
}

function Unavailable() {
  const msg =
    <>
      The page you are trying to access is either not available or was not accessed correctly <br /> <br />
      Try routing to your page using the Menu bar on the top left of the screen
    </>

  return (
    <div style={unavailableStyle}>
      <img height="100px" src={mokuPopup} alt={'logout'} />
      <Text style={{ maxWidth: '654px', fontWeight: 'bold' }} size={24} color="primary">
        {msg}
      </Text>
    </div>
  )
}

export default Unavailable