/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import gql from 'graphql-tag';
import { withApollo, useQuery, Subscription } from 'react-apollo';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

function AutocompleteWithAdd(props) {
  const { options: initialOptions = [], value: initialvalue, handleCreate, apolloClient } = props
  const [value, setValue] = useState(initialvalue);
  const [dialogValue, setDialogValue] = useState({
    value: '',
    label: '',
  });

  const [open, toggleOpen] = useState(false);
  const [options, setOptions] = useState(initialOptions);

  const onAdd = (value) => {
    options.push({ value, label: value })
    setOptions(options.sort((a, b) => (a.label > b.label) ? 1 : -1))
    handleCreate({ value, apolloClient })
    toggleOpen(false)
    setValue(value);
  };

  const handleClose = () => {
    setDialogValue({
      value: '',
      label: '',
    });

    toggleOpen(false);
  };

  return (
    <>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                label: newValue,
                value: '',
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              label: newValue.inputValue,
              value: '',
            });
          } else {
            setValue(newValue);
          }
        }}

        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              label: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={options}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.label;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.label}
        fullWidth
        freeSolo
        renderInput={(params) => (
          <TextField {...params} {...props} variant="outlined" />
        )}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-label">
        <DialogTitle id="form-dialog-label">Add a new option</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Did you wanto to add this? Please, check the details then Press add!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={dialogValue.label}
            onChange={(event) => setDialogValue({ ...dialogValue, label: event.target.value })}
            label="New Value"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary" onClick={() => onAdd(dialogValue.label)}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withApollo(AutocompleteWithAdd);
