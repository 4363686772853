import React, { useState } from 'react'
import { Card, Grid } from '../generic'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import GraceDaysUsed from './GraceDaysUsed';

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

function GraceDays({ graceDaysUsedAt, riskPaidUntil }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false)

  return (
    <Card>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <h2>Grace Days Used</h2>
          <div style={{ cursor: 'pointer' }} onClick={() => graceDaysUsedAt && graceDaysUsedAt.length > 0 ? setOpen(true) : {}} >
            {graceDaysUsedAt ? graceDaysUsedAt.length : 0}
          </div>
          <GraceDaysUsed graceDaysUsedAt={graceDaysUsedAt} open={open} onClose={() => setOpen(false)} />
        </Grid>
        <Grid item xs={6}>
          <h2>Risk Paid Until</h2>
          {riskPaidUntil ? moment(riskPaidUntil).format('DD-MM-YYYY') : "-"}
        </Grid>
      </Grid>
    </Card>
  )
}

export default GraceDays