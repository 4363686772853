import React from 'react'
import StatusList from './StatusList'
import History from './History'
import { Route } from 'react-router-dom'

const Nedbank = () => (
  <div>
    <Route path='/nedbank' render={({ history }) => <StatusList history={history}  />} />
  </div>
)

export default Nedbank
