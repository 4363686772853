import React, { Component } from 'react'
import gql from 'graphql-tag'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Select, GraphQLTable } from '../generic'
import moment from 'moment'
import { Card, Modal } from '../generic'

const config = {
  query: gql`
    {
      paymentstatuss(limit: 10000) {
        id
        creditLife {
          id
          policyNumber
          policyStatus
          client {
            idNumber
            firstNames
            surname
            debtCounselling {
              debtCounsellor {
                shortName
              }
            }
          }
        }
        info
        action
        status
        error
        done
        payment {
          amount
        }
        updatedAt
        expectedDate
      }
    }
  `,
  dataset: data => data.paymentstatuss,
  fields: {
    'creditLife.policyNumber': { label: 'Policy',accessor: row => row.creditLife ? row.creditLife.policyNumber : null },
    'creditLife.policyStatus': { label: 'Status', accessor: row => row.creditLife ? row.creditLife.policyStatus : null},
    'creditLife.client.idNumber': { label: 'ID Number' },
    clientName: {
      label: 'Name',
      accessor: row =>
      row.creditLife && row.creditLife.client ? `${row.creditLife.client.firstNames} ${row.creditLife.client.surname}` : null
    },
    'creditLife.client.debtCounselling.debtCounsellor.shortName': {
      label: 'DC',
      //Cell: row => productTypes(row.value)
    },
    action: { label: 'Action', width: 250, align: 'center' },
    status: {
      label: 'Status',
      align: 'right'
    },
    info: {
      label: 'Nedbank Message',
      align: 'right',
      width: 600,
    },
    //expectedDate: { label: 'Expected', width: 50, align: 'center', Cell: row => row.value ? moment(row.value).format('DD-MM-YYYY') :'-' },
    error: {
      label: 'Errors?',
      align: 'center'
    },
    done: {
      label: 'Given Up?',
      align: 'center'
    },
    //'payment.amount': { label: 'Amount', width: 35,
    //  Cell: row => (row.value ? printCurrency(row.value) : '-')
    //},
    Updated: {
      label: 'Update',
      align: 'center',
      accessor: row => moment(row.updatedAt).format('DD-MM-YYYY')
    },
    updatedAt: { label: 'Relative', Cell: row => moment(row.value).fromNow() }
  },
  defaultSorted: [{ id: 'updatedAt', desc: true }]
}

const resubmitPayment = gql`
  mutation resubmitPayment(
    $firstNames: String
    $surname: String
    $idNumber: String
    $email: String
    $company: String
    $monthlyIncome: Float
    $cellNumber: String
    $leadMessage: String
    $leadInfo: [KeyValueInput]
    $bestContactTime: String
  ) {
    createChatBotLead(
      firstNames: $firstNames
      surname: $surname
      idNumber: $idNumber
      email: $email
      company: $company
      monthlyIncome: $monthlyIncome
      cellNumber: $cellNumber
      leadMessage: $leadMessage
      leadInfo: $leadInfo
      bestContactTime: $bestContactTime
    ) {
      id
    }
  }
`

const submitReasons = [
  { value: 'No idea', label: 'No idea' },
  { value: 'who knows', label: 'Who knows' },
  { value: 'Hope', label: 'Hoping something happens' },
  { value: 'David', label: 'David said so' }
]



const PaymentStatusList = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [submitReason, setSubmitReason] = React.useState(null);
  const [creditLife, setCreditLife] = React.useState(null);
  const [funeral, setFuneral] = React.useState(null);
  const [history, setHistory] = React.useState(null);

  const resubmitThePayment = () => {
    /*<Mutation mutation={createLead} >
      {(createChatBotLead, { loading, error, data, called }) => {
        if (!called) {
          createChatBotLead({ variables: { firstNames, surname, idNumber, email, monthlyIncome, cellNumber, leadMessage, bestContactTime } })
          console.log('resubmitting props are  ', this.props.steps)
            return <Spin tip="saving the conversation ..." />
          } else {
            return (
              <div>
                {loading && <Spin style={{ color: '#fff' }}  tip="saving conversation ..." />}
                {error && <ChatButton {...this.props} button={{
                  value: 999,
                  trigger: 'saveLeadError',
                  text: 'Oh dear, a problem'
                }} />}
              </div>
            )
          }
      }}
    </Mutation>
    */
  }

    return (

      <Card title="Nedbank Outgoing Payments">
          <GraphQLTable
            config={config}
            contextMenuId={"rightClick"}
          onRowPress={(row, string, event) => { console.log('onRowPress', event.currentTarget); setAnchorEl(event.currentTarget) }}
          />

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => { console.log(' display id ' + this.state.creditLife.id); this.props.history.push(`/credit-life/${this.state.creditLife.id}`) }}>Display Policy</MenuItem>
          <MenuItem onClick={() => { this.toggleModal(this.state.creditLife) }}>Resubmit Payment</MenuItem>
        </Menu>

        <Modal
          width="900px"
          style={{ top: 20 }}
          title="Do you want to resubmit the payment?"
          okText="Yes, send to Nedbank"
          cancelText="No, Go Back!"
          open={modalVisible}
          wrapClassName="vertical-center-modal"
          cancelButtonProps={{ shape: "round" }}
          okButtonProps={{ shape: "round" }}
          onOk={() => {
            setModalVisible(false)
            resubmitThePayment(submitReason)
          }}
          onClose={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
        >
          &nbsp;
          <Select
            label='Why are you resubmitting this?'
            style={{ width: 200 }}
            options={submitReasons}
            onChange={v => setSubmitReason(v.target.value)}
          />
        </Modal>
      </Card>
    )
}

export default PaymentStatusList
