import React, { useState, useEffect } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { change } from 'redux-form'
import { useConfirm } from "material-ui-confirm";
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag'
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
import { Button, Card, Select, ActivityIndicator, DisplayItem, Grid, FormModal, Switch } from '../generic'
import { isValidRsaIdNumber, isValidEmail } from '../../lib/validations'
import { IdTypes } from "../../variables/IdTypes"
import { RELATIONSHIPS } from "../../variables"
import { capitalize } from '../../lib/utils'
import Member from '../funeral/Member'

// switch between locales
Numeral.locale('en-za')

const useStyles = makeStyles((theme) => ({
  footer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingTop: 21,
    paddingBottom: 21
  },
  button: {
    minWidth: 200,
    maxWidth: 200,
    textAlign: 'center',
    marginRight: 14
  }
}));

const EditModal = (props) => {
  const { size, width = "xl" } = props
  return (
    <FormModal
      okText="Ok"
      cancelText="Back"
      width={width}
      disableCancel={true}
      disableOk={true}
      size={size}
      componentProps={props}
    />
  )
}

export const friendlyType = (type) => type ? type.replace(/_/g, " ").replace(/AND/g, "+").replace(/WITH/g, "+") : ''

export const translateState = (value) => {
  switch (value) {
    case 'INFO_MISSING' || 'MISING_INFO': return 'MISSING INFO'
      break
    case 'DORMANT': return "POLICY ISSUED"
      break
    case 'NOT_TAKEN_UP': return 'NOT TAKEN UP'
      break
    case 'LAPSED': return 'LAPSED'
      break
    case 'ACTIVE': return 'ON RISK'
      break
    case 'GRACE': return "ON RISK GRACE"
      break
      // case 'ON_RISK_GRACE': return "ON RISK GRACE"
      break
    case 'CLAIMED': return 'CLAIMED'
      break
    case 'CANCELLED': return 'CANCELLED '
      break
    default: return 'UNKNOWN'
      break
  }
}

const CREATE_BENEFIT_CLAIM = gql`
  mutation CreateClaim($input: CreateClaimForBenefit) {
    createBenefitClaim(input: $input)
    }`

const CREATE_CLAIMANT = gql`
  mutation CreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      id
    }
  }`


const GET_POLICY = gql`
  query GetPolicy($id: String!, $effectiveDate: Float) {
    funeral(id: $id) {
      id
      policyNumber
      state
      versionOnDate(date: $effectiveDate) {
        state
      }
      policyType
      paymentMethod
      paymentDueDay
      currentPremiumTotal
      coverAmount
      numFullPayments
      riskPaidUntil
      doMandate
      debitOrderId
      beneficiaries {
        id
        payoutType
        percentage
        payToId
        payTo {
            id
            clientId
            firstNames
            surname
            idNumber
            idType
            cellNumbers
            email
            relationship
          }
        bankAccount {
          id
          bankName
          accountNo
          associatedWith {
            id
            type
            reference
          }
          }
        }
      livesCovered {
        id
        clientId
        firstNames
        surname
        idNumber
        idType
        gender
        memberType
        coverAmount
        DOB
        ageAtSignup
        cellNumbers
        email
        state
        versionOnDate(date: $effectiveDate) {
          state
        }
        dead
        dateOfDeath
      }
      client {
        id
        firstNames
        surname
        email
        idType
        idNumber
        cellNumbers
        dead
        verifiedDead
        dateOfDeath
      }
      cancelReason
      cancelledAt
      createdAt
    }
    creditlife(id: $id) {
      id
      policyNumber
      termsVersion
      pricingVersion
      payment
      coverAmount
      paymentStartDate
      productType
      actionDay
      paymentDay
      hasSignedDO
      beneficiaries {
        payTo {
          id
          clientId
          firstNames,
          surname,
          idType,
          idNumber,
          cellNumbers,
          email,
          relationship
        }
      }
      client {
        id
        firstNames
        surname
        idType
        idNumber
        cellNumbers
        email
      }
      reason
      policyStatus
      versionOnDate(date: $effectiveDate) {
        policyStatus
      }
      quoteAccepted
      quoteAcceptedDate
      createdAt
      updatedAt
      graceDays
      graceDaysUsedAt
      riskPaidUntil
    }
  }
`

const GET_REQUIREMENTS = gql`
  query GetClaimRequirements($claimType: ClaimType, $products: [ProductType]) {
    getClaimRequirements(claimType: $claimType, products:$products  ) {
      id
      documents {
        id
        requirementId
        documentType
        description
        required
        requireCertified
        maximumAgeDays
      }
      info {
        name
        key
        type
        options
      }
    }
  }
`

const GET_CLAIMANT = gql`
  query clients($filter: ClientFilter!) {
    clients(filter: $filter) {
      id
      idNumber
      idType
      firstNames
      surname
      cellNumbers
      email
      selfieId
      idCardId
      documents {
        id
        type
        verified
        file {
          id
          url
        }
      }
    }
  }
`

const PolicyInfo = ({ funeral, cli, effectiveDate }) => {
  if (!funeral && !cli) {
    return <div />
  }
  const { versionOnDate, policyNumber, coverAmount, policyType, productType, createdAt, cancelledAt, client } = funeral || cli || {};
  const { state, policyStatus } = versionOnDate || {};
  const { firstNames, surname, idType, idNumber, email, cellNumbers } = client

  return <>
    <Grid container justifyContent="space-between" alignItems="center" spacing={1} >
      <DisplayItem label="Policy Number" value={policyNumber} xs={2} />
      <DisplayItem label="Policy Holder" value={`${firstNames} ${surname}`} xs={2} />
      <DisplayItem label="Id Number" value={`${idNumber} ${idType !== 'rsa_id' ? idType : ' '}`} xs={2} />
      <DisplayItem label="Effective" value={moment(effectiveDate).format('DD-MM-YYYY')} xs={2} />
      <DisplayItem label="Type" value={friendlyType(policyType ? policyType : productType)} xs={3} />
      <DisplayItem label="Amount" value={Numeral(coverAmount).format('$ ##,###0')} xs={1} />
      <DisplayItem label="Created" value={moment(createdAt).format('DD-MM-YYYY')} xs={1} />
      <DisplayItem label="Current Policy State" value={funeral && funeral.state ? translateState(funeral.state) : cli.policyStatus} xs={2} />
      <DisplayItem label="State at Claim Date" value={state ? translateState(state) : policyStatus ? policyStatus : 'Undefined'} xs={2} />
      {(state === 'CANCELLED' || policyStatus === 'CANCELLED') && <DisplayItem label='Policy Cancelled' value={moment(cancelledAt).format('DD-MM-YYYY')} />}
      {(state === 'CANCELLED' || policyStatus === 'CANCELLED') && <DisplayItem label='Reason' value={funeral ? funeral.cancelReason : cli.cancelReason} />}
    </Grid>
  </>
}


const fileInfo = (field, type, clientDocuments) => {
  if (clientDocuments) {
    const doc = clientDocuments.filter(d => (d.type && (d.type.toUpperCase()) === type.toUpperCase()) && d.file && d.verified === true).slice(-1)[0]
    if (doc && doc.file) {
      return doc.file.id
    }
  }
  else return undefined
}

let getClaimant = ({ idType, idNumber, client, setSpin, setClaimant }) => {
  if (idNumber.length > 10) {
    setSpin(true)
    client
      .query({
        query: GET_CLAIMANT,
        variables: { filter: { idType, idNumber } }
      })
      .then(result => {
        setSpin(false)
        if (result.data.clients && result.data.clients[0]) {
          const { id, idType, firstNames, surname, email, cellNumbers, documents } = result.data.clients[result.data.clients.length - 1]
          setClaimant({ id, idType, idNumber, firstNames, surname, email, cellNumbers: cellNumbers[0], documents })
        }
        else {
          setClaimant({ ...initialClaimant, idType, idNumber })
        }
      })
  }
}

const actualCover = ({ memberType, coverAmount, DOB, funeralCover, ageAtSignup = 0, createdAt }) => {

  if (coverAmount) {
    return coverAmount
  }

  let amount = funeralCover
  if (memberType === "PARENT") { amount = amount / 2 }
  else if (memberType === "EXTENDED") { amount = amount / 2 }
  else if (memberType === "CHILD") {
    let currentAge
    if (DOB) {
      currentAge = moment().diff(DOB, 'years')
    }
    else {
      currentAge = ageAtSignup + moment().diff(createdAt, 'years')
    }

    if (currentAge <= 5) amount = amount / 4
    if (currentAge > 5 && currentAge <= 13) amount = amount / 2
    if (currentAge > 13 && currentAge <= 23) amount = amount
    if (currentAge > 23) amount = 0
  }
  else if (memberType === "STILLBORN") {
    amount = Math.round(funeralCover * 0.125, 2)
  }

  return amount
}

const memberClaimStatus = ({ state }) => {
  const coverAllowed = [
    { state: 'INITIAL', message: 'Member not active', coverTypes: ['NONE'] },
    { state: 'IN_WAITING_PERIOD', message: 'Accidental cover only', coverTypes: ['ACCIDENT'] },
    { state: 'POLICY_LAPSED', message: 'Policy lapsed', coverTypes: ['NONE'] },
    { state: 'COVERED', message: 'All reasons covered', coverTypes: ['ACCIDENT', 'SUICIDE', 'NATURAL', 'STILLBORN'] },
    { state: 'ON_RISK', message: 'All reasons covered', coverTypes: ['ACCIDENT', 'SUICIDE', 'NATURAL', 'STILLBORN'] },
    { state: 'CLAIM_NOTIFIED', message: 'Claim in process', coverTypes: ['NONE', 'STILLBORN'] },
    { state: 'POLICY_CANCELLED', message: 'Policy was cancelled', coverTypes: ['NONE'] },
    { state: 'POLICY_FROZEN', message: 'Policy was frozen, no cover', coverTypes: ['NONE'] },
    { state: 'NO_COVER_AGE', message: 'Member not covered due to age', coverTypes: ['NONE'] },
    { state: 'NO_COVER_MEMBER_REMOVED', message: 'Member was removed', coverTypes: ['NONE'] },
    { state: 'CLAIMED', message: 'Already claimed', coverTypes: ['NONE'] },
  ]
  // console.log('benefitbutton memberClaimStatus', state, coverAllowed)

  const actualCovererage = coverAllowed.filter(cover => cover.state === state)

  // console.log('benefitbutton actualCovererage', actualCovererage)

  return actualCovererage.length > 0 ? actualCovererage[0] : { state: 'UNKNOWN', message: 'Member has unknown state', coverTypes: ['NONE'] }
}

const initialClaimant = {
  clientId: '',
  id: '',
  firstNames: '',
  surname: '',
  cellNumbers: [],
  email: '',
  idType: 'rsa_id',
  idNumber: '',
  documents: [],
  relationship: ''
}

const ClaimantIdNumber = ({ setClaimant, claimant, client, setSpin, disabled }) => {
  const [idNumber, setIdNumber] = useState(claimant && claimant.idNumber)
  const [helperText, setHelperText] = useState('')
  const [idType, setIdType] = useState(claimant && claimant.idType)

  const onChange = (e) => {
    setIdNumber(e.target.value)
    setClaimant({ ...claimant, idNumber: e.target.value })
    setHelperText('')
    if ((!idType || idType === 'rsa_id') && isValidRsaIdNumber(e.target.value)) {
      setHelperText('Invalid Id Number')
    }
    else {
      getClaimant({ idType, idNumber: e.target.value, client, setClaimant, setSpin, getClaimant })
    }
  }

  return (<>
    <Grid item ><Select disabled={disabled} fullWidth id="idtypeselect" required value={idType} options={IdTypes} label='Identity' onChange={(e) => { setIdType(e.target.value); setClaimant({ ...claimant, idType: e.target.value }) }} /></Grid>
    <Grid item ><TextField InputLabelProps={{ shrink: true }} disabled={disabled} id="idnumberinput" variant="outlined" required label="Id Number" name="idNumber" value={idNumber} onBlur={onChange} onChange={onChange} error={helperText.length === 0 ? false : true} helperText={helperText} /></Grid>
  </>
  )
}

let ClaimBenefit = (props) => {
  const confirm = useConfirm();
  const classes = useStyles();

  const { data, history, client, match } = props || {}
  const { loading, error, funeral, creditlife } = data || {}
  const { beneficiaries, client: claimFor } = funeral || creditlife || []
  const { payTo } = beneficiaries ? beneficiaries[0] : {}
  const { params } = match || {}
  const { claimType, productType, effectiveDate, id } = params
  const [claimant, setClaimant] = useState()
  const [livesCovered, setLivesCovered] = useState()
  const [spin, setSpin] = useState(false)
  const [isBeneficiary, setIsBeneficiary] = useState(false)
  const [emailHelper, setEmailHelper] = useState()

  useEffect(() => {
    if (funeral) {
      setLivesCovered(funeral.livesCovered)
      setClaimant(payTo ? payTo : initialClaimant)
      // setIsBeneficiary(payTo ? true : false)
    }
  }, [funeral, creditlife]);


  if (loading) {
    return <ActivityIndicator />
  }
  if (error) {
    return <div>Oh dear, an error has occurred ... {error}</div>
  }

  const confirmContinue = () => {
    confirm({
      title: 'Go to Claim?',
      description: `Continue or go to the Claim?`,
      cancellationButtonProps: { variant: 'contained', color: 'default' },
      confirmationButtonProps: { variant: 'contained', color: 'primary' },
      cancellationText: 'Create Another',
      confirmationText: 'Go To Claims'
    })
      .then(() => history.push('/claims'))
  }

  const updateLifeCovered = ({ life, input }) => {
    setLivesCovered(old =>
      old.map((row) => {
        if (row.id.toString() == life.id.toString()) {
          return {
            ...row,
            ...input,
          };
        }
        return row;
      })
    );
  }


  const assignClaimantDocumentsToClaim = (requiredDocs) => {
    const updatedDocs = requiredDocs.map(doc => {
      if (doc.description.toUpperCase().includes('CLAIMANT')) {
        const found = claimant && claimant.documents && claimant.documents.find(d => d.type === doc.documentType)
        if (found) {
          doc.fileId = found.file ? found.file.id : ''
        }
      }
      const { description, requirementId, fileId, ...rest } = doc
      return ({ description, requirementId, fileId })
    })
    return updatedDocs
  }


  const claimInput = (life, benefit, reason, info, requiredDocuments) => {
    let input = { claimReason: reason, claimType: benefit, effectiveDate, documents: requiredDocuments, claimType: benefit }

    const { id, __typename, clientId, documents: claimantDocuments, ...claimantDetail } = claimant

    console.log('claimInput', claimant, life)
    input.claimant = claimantDetail

    if (creditlife) {
      input.forCreditLifeId = creditlife.id
      input.claimForIdNumber = life.idNumber
      input.claimForIdType = life.idType
      input.forMembersIds = undefined
    }
    else if (life) {
      input.forMembersIds = [life.id]
    }

    console.log('claim input is ', input)
    return input
  }
  const buildBenefitClaim = (life, benefit, reason) => {

    client
      .query({
        query: GET_REQUIREMENTS,
        variables: { claimType: benefit, products: [productType] }
      })
      .then(result => {
        setSpin(false)
        if (result.data.getClaimRequirements && result.data.getClaimRequirements[0]) {
          const { info, documents: requiredDocs } = result.data.getClaimRequirements[0]
          const requiredDocuments = assignClaimantDocumentsToClaim(requiredDocs)
          console.log('requiredDocuments', requiredDocuments)
          const input = claimInput(life, benefit, reason, info, requiredDocuments )
          if (input.claimantId) {
            createBenefitClaim(input, life)
          }
          else {
            createClaimant(input, life)
          }
        }
      })
  }

  const createClaimant = (input, life) => {
    console.log('createClaimant', input)

    const { idNumber, idType, firstNames, surname, cellNumbers } = input.claimant
    client
      .mutate({
        mutation: CREATE_CLAIMANT,
        variables: {
          input: {
            idNumber, idType, firstNames, surname, cellNumbers
          }
        },
      })
      .then(result => {
        input.claimantId = result.data.createClient.id
        delete input.claimant
        console.log("createClaimant result", result)
        createBenefitClaim(input, life)
      }
      )
  };


  const createBenefitClaim = (input, life) => {
    // construct the claim
    setSpin(true)

    if (input) {
      client
        .mutate({
          mutation: CREATE_BENEFIT_CLAIM,
          variables: {
            input
          },
        })
        .then(result => {
          setSpin(false)
          if (productType === 'CREDIT_LIFE') {
            history.push(`/claims`)
          }
          else {
            updateLifeCovered({ life, input: { state: `CLAIM_NOTIFIED ${result.data.createBenefitClaim}`, versionOnDate: ({ state: 'CLAIM_NOTIFIED' }) } })
            confirmContinue()
          }
        }
        )
    }
  };

  const confirmPrompt = ({ life, benefit, reason }) => {
    confirm({
      title: 'Create a new Claim?',
      description: `Are you sure you want to create a new ${!reason ? reason === 'ACCIDENT' ? 'accidental' : reason === 'NATURAL' ? 'natural causes' : reason === 'SUICIDE' ? 'suicide' : '' : ''} ${benefit.toLowerCase()} claim?`,
      cancellationButtonProps: { variant: 'contained', color: 'default' },
      confirmationButtonProps: { variant: 'contained', color: 'primary' }
    })
      .then(() => buildBenefitClaim(life, benefit, reason))
  }

  const benefitButton = ({ benefit, versionState, state, life, freedom }) => {

    const effectiveState = () => {
      if (freedom) {
        if (versionState === 'COVERED') {
          return versionState
        }
        else {
          return versionState ? versionState : state
        }
      }
      else {
        return versionState ? versionState : state
      }
    }

    const disabled = memberClaimStatus({ state: effectiveState() }).coverTypes.filter(b => b !== 'NONE').indexOf(benefit) === -1
    // const disabled = memberClaimStatus({ state: versionState && versionState !== 'UNKNOWN' ? versionState : state }).coverTypes.filter(b => b !== 'NONE').indexOf(benefit) === -1 //
    // console.log('benefitButton', disabled, benefit, versionState, state, life)
    return <Button color='primary' disabled={(!freedom && disabled) || spin} onClick={() => confirmPrompt({ life, reason: benefit, benefit: benefit === 'STILLBORN' ? 'STILLBORN' : 'DEATH' })}>{benefit}</Button>
  }

  const LivesCovered = ({ livesCovered, policyType, funeralCover, createdAt, policyNumber }) => {
    const freedom = !policyNumber.startsWith('MF')
    return (<>
      <Grid container justifyContent='flex-start' alignItems="center" spacing={1} >
        {
          livesCovered.map(life => {
            const { id, dateOfDeath, memberType, idType, idNumber, firstNames, surname, ageAtSignup, DOB, coverAmount, state, versionOnDate, gender } = life
            const { state: versionState } = versionOnDate || {}
            console.log('version on state', versionState)
            return (
              <Card key={id}>
              MemberType: <EditModal Content={Member} noButton fontSize={12} underlineColor='blue' heading="Edit Member" text={memberType} id={life.id} memberType={life.memberType} afterSubmit={(data) => { updateLifeCovered({ life, input: data }) }} /> <br />
              Identity: {`${idNumber} ${idType && idType !== 'rsa_id' ? idType : ''}`} <br />
              Name: {`${firstNames} ${surname}`} <br />
              Cover: {actualCover({ memberType, coverAmount, DOB, funeralCover, ageAtSignup, createdAt })} <br />
              State: {versionState && versionState !== 'UNKNOWN' ? versionState : state} <br />
              <Grid container spacing={1} style={{ marginTop: 7 }}>
                  <Grid item>{benefitButton({ life, benefit: 'ACCIDENT', versionState, state, freedom })}</Grid>
                  <Grid item>{benefitButton({ life, benefit: 'NATURAL', versionState, state, freedom })}</Grid>
                  <Grid item>{benefitButton({ life, benefit: 'SUICIDE', versionState, state, freedom })}</Grid>
                  {['SPOUSE', 'POLICY_HOLDER'].includes(memberType) && gender === 'FEMALE' && <Grid item> {benefitButton({ life, benefit: 'STILLBORN', versionState, state, freedom })}</Grid>}
              </Grid>
            </Card>)
          })}
      </Grid>
    </>)
  }
  const handleEmailChange = (e) => {
    setClaimant({ ...claimant, email: e.target.value })
  }

  const handleEmailBlur = (e) => {
    if (!claimant.email || !isValidEmail(claimant.email)) {
      setEmailHelper('')
    }
    else {
      setEmailHelper('Invalid email')
    }
  }

  if (funeral && !claimant) {
    return <div>Claimant is null</div>
  }

  const noErrors = (claimant && claimant.relationship && claimant.firstNames && claimant.surname && !emailHelper && claimant.idNumber && claimant.cellNumbers && claimant.cellNumbers.length > 0) ? true : false
  const { idNumber, firstNames, surname, cellNumbers, email, relationship } = claimant || {}

  return (
    <Card>
      <h2>Create a {capitalize(friendlyType(productType))} {productType !== 'FUNERAL' && capitalize(claimType)} Claim</h2>
      {spin && <center><ActivityIndicator /></center>}

      <PolicyInfo funeral={funeral} cli={creditlife} effectiveDate={parseInt(effectiveDate)} />
      <br />

      <h3>Claimant Information</h3>
      {!creditlife && payTo && <>
        <Switch label="Claimant is the Beneficiary" value={isBeneficiary} onChange={() => { setIsBeneficiary(!isBeneficiary); setClaimant(isBeneficiary ? initialClaimant : payTo ? payTo : initialClaimant) }} /> 
      </>}
      <br />

      <Grid container spacing={1} justify="flex-start" alignItems='flex-end'>

        {/* {isBeneficiary ? <>
          <Grid item ><Select fullWidth id="idtypeselect2" required value={claimant.idType} options={IdTypes} label='Identity' onChange={(v) => setClaimant({ ...claimant, idType: v })} /></Grid>
          <Grid item > <TextField id="nameinput" variant="outlined" required label="Id Number" name="idnumber" value={claimant.idNumber} onChange={(e) => setClaimant({ ...claimant, idNumber: e.target.value })} /></Grid>
        </> : */}
        <ClaimantIdNumber disabled={idNumber && isBeneficiary} claimant={claimant} setClaimant={setClaimant} client={client} setSpin={setSpin} />
        <Grid item ><TextField id="nameinput" variant="outlined" required label="First Name" name="firstNames" value={firstNames} InputLabelProps={{ shrink: true }} onChange={(e) => setClaimant({ ...claimant, firstNames: e.target.value })} /></Grid>
        <Grid item ><TextField id="surnameinput" variant="outlined" required label="Surname" name="surname" value={surname} InputLabelProps={{ shrink: true }} onChange={(e) => setClaimant({ ...claimant, surname: e.target.value })} /></Grid>
        <Grid item ><TextField id="mobileinput" variant="outlined" required label="Mobile" name="mobile" value={cellNumbers} InputLabelProps={{ shrink: true }} onChange={(e) => setClaimant({ ...claimant, cellNumbers: e.target.value })} /></Grid>
        <Grid item ><TextField id="emailinput" variant="outlined" helperText={emailHelper} label="Email" name="email" value={email} InputLabelProps={{ shrink: true }} onChange={handleEmailChange} onBlur={handleEmailBlur} /></Grid>
        <Grid item ><Select fullWidth id="relationselect" required value={relationship} options={RELATIONSHIPS.sort((a, b) => (a > b) ? 1 : -1).map(a => ({ value: a, label: a }))} label='Relationship' InputLabelProps={{ shrink: true }} onChange={(v) => setClaimant({ ...claimant, relationship: v.target.value })} /></Grid>
      </Grid>

      {funeral && noErrors && <>
        <br />
        <h3>Lives Covered</h3>
        <LivesCovered livesCovered={livesCovered} policyType={funeral.policyType} funeralCover={funeral.coverAmount} createdAt={funeral.createdAt} policyNumber={funeral.policyNumber} />
      </>}

      <Grid container className={classes.footer}>
        <Grid item className={classes.button}>
          <Button color='default' onClick={() => history.push('/claims')}>Back to Claims</Button>
        </Grid>
        {(creditlife) && // display button if Errors so admin can see there is missing info
          <Grid item className={classes.button}>
            <Button color='primary' disabled={!noErrors} onClick={() => confirmPrompt({ life: claimFor, creditlife, benefit: claimType })}>Create Claim</Button>
        </Grid>}
      </Grid>
    </Card>
  )
}

ClaimBenefit = withApollo(ClaimBenefit);
export default graphql(GET_POLICY, {
  options: ({ match }) => {
    const { id, effectiveDate } = match.params
    return ({ variables: { id, effectiveDate } })
  }
})(ClaimBenefit)
