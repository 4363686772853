import React, { useState } from 'react'
import gql from 'graphql-tag'
import { GraphQLFormProvider, FormField } from 'react-form-helper'
import { isInteger } from '../../lib/validations'
import { creditLifeInformation } from './CreditLife'
import { renderInput, renderDisplay, BuildLayout } from '../generic';

const config = {
  name: 'creditlife',
  fields: {
    'paymentDay': {
      label: 'Payment day *',
      type: "string",
      validate: (value) => {
        if (!value) {
          return 'Payment is Required'
        }
        else if (isInteger()) {
          return 'Numbers only'
        }
        else if (value < 0 || value > 31) {
          return 'Must abe a day of the month'
        }
      }
    }
  },
  query: gql`
    query CreditLifeData($id: String!) {
      creditlife(id: $id) {
        id
        paymentDay
      }
    }`,
  updateMutation: gql`
    mutation UpdateCreditLife($input: UpdateCreditLifeInput!, $id: String!) {
      updateCreditLife(id: $id, input: $input) {
        id
        paymentDay
      }
  }`,
  addMutation: gql`
  mutation CreateCreditLife($input: CreateCreditLifeInput!) {
    createCreditLife(input: $input) {
      id
      paymentDay
    }
  }`
}


const FormLayout = (props) => {

  let { mode, cancel, formName } = props;

  return (
    <BuildLayout formName={formName} fieldNames={Object.keys(config.fields)} mode={mode} fieldConfig={config.fields} direction='column' spacing={2} align='center' justify='center' cancel={cancel} />
  );
}


let EditCreditLife = (props) => {

  const { cancel, id } = props

  return (
    <div>
      <GraphQLFormProvider
        mode={id ? "edit" : "add"}
        id={id}
        {...config}
        afterSubmit={() => cancel()}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout {...props} formName={config.name}  cancel={cancel} />}
        DisplayFormLayout={FormLayout}
        initialValuesFromData={data => data.creditlife}
        refetchQueries={[{ query: creditLifeInformation, variables: { id } }]}
      />
    </div>
  )
}

export default EditCreditLife