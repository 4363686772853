import React, { useState } from 'react';
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { GraphQLFormProvider, FormField} from 'react-form-helper';
import { Card, Button, Select, renderDisplay, renderInput, Grid } from '../generic'
import { withRouter } from 'react-router'
import SubmitFormButton from 'react-form-helper/dist/SubmitFormButton';

const GET_EMPLOYEES = gql`
  query getEmployees {
    employees(limit:1000) {
      id
      first
      last
      mobileNumber
      email
      manager {
        id
        first
        last
        email
      }
      photo {
        id
        url
      }
    }
  }
`

const myRenderInputField = (type, options) => (props) => {
  
  if (type === 'employee') {

    const [manager, setManager] = useState(props.input.value)

    console.log('myRenderInputField options are ', props, props.input.value, manager)
      return (
          <Select
            isClearable={false}
            value={options.filter(v => v.value === manager)}
            onChange={(v) => {setManager(v.value); props.input.onChange(v.value)}}
            options={options}
          >
        </Select>
      )
  } else {
    //console.log('myRenderInputField text value props are ', props)
    const { value } = props.input
    return <input style={{ minHeight: 38 }} autosize={false} onChange={(v) => { console.log('myRenderInputField text onChange ', v); props.input.onChange(v) }}
    value={value} {...props} />
  }
}


const config = {
  name: 'salesteam',
  fields: {
    name: {
      type: 'text',
      label: 'Team Name *',
      validate: (value) => {
        if (!value) {
          return 'Team Name is required';
        }
      }
    },
    managerOfTeamId: {
      type: 'employee',
      label: 'Manager *',
      employees:(data) => data,
      validate: (value) => {
        if (!value) {
          return 'Manager is required';
        }
      }
    }
  },
  query: gql`
		query SalesTeam($id: String!) {
			salesteam(id: $id) {
        id
        name
        managerOfTeamId
        managerOfTeam {
          id
          first
          last
        }
      }
    }
	`,
  updateMutation: gql`
		mutation UpdateSalesTeam( $id: String!, $input: UpdateSalesTeamInput!) {
			updateSalesTeam(id: $id, input: $input) {
        id
      }
    }`,
  addMutation: gql`
		mutation CreateSalesTeam( $input: CreateSalesTeamInput!) {
			createSalesTeam(input: $input) {
        id
		}
  }`
};


let FormLayout = ({mode, setMode, invalid, submit, id, history, ...props}) => {

  function buildRowsandColumns(fieldNames) {
    var rows = []
    var cols = []

    // NOTE: the value of the span * rowCount, must be 24
    var span = 8
    var rowCount = 2
    var fieldCount = 1

    fieldNames.forEach((field, index) => {
      console.log('fieldName is ', field, index)
      // if field not on our ignore list then add to screen
        cols.push(
          <Grid item xs={1} key={index} span={span} style={{ display: 'block' }}>
          <FormField key={index} name={field} />
          </Grid>
      )

      if (((fieldCount) % rowCount) == 0 || index === fieldNames.length - 1) {
      rows.push(
        <Grid container spacing={1} key={rowCount} style={{ marginTop: 21, marginRight: 21 }} align="center" justify="start" gutter={8}> {cols}</Grid>)
        cols = []
      }
        fieldCount++
    })

    return rows

  }

    //const { mode, setMode, invalid, submit } = context;
    console.log('formlayout id is ', props, mode, setMode, invalid, submit, id )
    
    return (
      <Card>
        <h1>{mode === "edit" ? "Edit" : "New"} Sales Team</h1>
        <br />
        {buildRowsandColumns(Object.keys(config.fields).filter(fieldName => (mode) === 'edit' || !config.fields[fieldName].noAdd))}
        <br />
        <br />
        <Grid container spacing={1} justifyContent="end"> 

          <SubmitFormButton renderSubmitButton={({ invalid, submit, submitting }) => (
            <Grid container spacing={1} gutter={16} justify="end">
              <Grid item xs={1}><Button color="secondary" style={{ width: 100 }} onClick={() => history.push('/sales-teams')}>Cancel</Button></Grid>
              <Grid item xs={1}><Button color="primary" style={{ width: 100 }} disabled={invalid || submitting} onClick={() => { submit() }}>Save</Button></Grid>
            </Grid>
          )} />

        </Grid>

        <p><b>*</b> Required Field</p>

        </Card>
    );
}

FormLayout = withRouter(FormLayout)

let SalesTeam = ({ mode, id, history, data }) => {

    console.log('sales team data', data)
    
    if (data.loading) {
      return <Card title="loading" />;
    } 

    else {

      console.log('Sales Team id is ', id)

      const employees = data.employees
      console.log('employees are', employees)
      let options = []
      employees.map(e => options.push({value:e.id,label:`${e.first} ${e.last} ${e.email}`}) )
  

      return (
        <div>
          <GraphQLFormProvider
            name="salesteam"
            mode={id === "new" ? "add" : "edit" }
            id={id ==="new" ? null : id}
            {...config}
            initialValuesFromData={ data => data.salesteam}
            renderDisplay={renderDisplay}
            renderInput={renderInput}
            InputFormLayout={FormLayout}
            DisplayFormLayout={FormLayout}
            afterSubmit={() => { history.push('/sales-teams') }}
          />
        </div>
      );
    }
}

SalesTeam = withApollo(SalesTeam)
export default SalesTeam = graphql(GET_EMPLOYEES)(SalesTeam)
