import React from 'react';
import { MaterialTable, SelectColumnFilter } from '../generic'
import moment from 'moment';
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
import { withRouter } from 'react-router-dom'

// switch between locales
Numeral.locale('en-za')

let ClientClaims = ({ claims, history }) => {

  const columns = [ {
    Header: 'Claim Number',
    accessor: 'claimNumber',
    filter:'fuzzyText'
  },
  {
    Header: 'Claim Type',
    id: 'claimType',
    accessor: d => d.claimType,
    Filter: SelectColumnFilter,
    filter:'Select'
  },
  {
    Header: 'Product Type',
    id:'productType',
    accessor: d => d.productType,
    Filter: SelectColumnFilter,
    filter:'Select'
  },
  {
    Header: 'Amount',
    id:'amountClaimed',
    accessor: d => Numeral(d.amountClaimed).format('$ ##,###0'),
    disableFilters:true
  },
  {
      Header: 'Created',
      id: 'createdAt',
      assessor: 'createdAt',
    accessor: (d) => moment(d.createdAt).format('DD-MM-YYYY'),
      disableFilters: true
    },
    {
      Header: 'Effective Date',
      id: 'effectiveDate',
      assessor: 'effectiveDate',
      accessor: (d) => moment(d.effectiveDate).format('DD-MM-YYYY'),
      disableFilters: true
    }
  ]
return (
  <MaterialTable columns={columns} data={claims} onRowPress={(row) => { history.push(`/claims/${row.id}/edit`) }} />
  )
}

export default ClientClaims = withRouter(ClientClaims)