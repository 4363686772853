import React from 'react'
import ApprovalList from './ApprovalList'
import { Route } from 'react-router-dom'

const Approvals = () => (
  <div>
    <Route path='/approvals' exact component={ApprovalList} />
  </div>
)

export default Approvals
