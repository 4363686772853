import React from 'react'
import { ValueItemContainer, ValueItemLabel, ValueItemAction, ValueItemValue } from './ValueItem.style'
import Text from '../Text'
import { Row } from '../grid'

function ValueItem({ label, actionText, onAction, value, color, large }) {
  return <ValueItemContainer>
    <Row>
      <ValueItemLabel><Text size={12}>{label}</Text></ValueItemLabel>
      {actionText && <ValueItemAction onClick={onAction}><Text size={12}>{actionText}</Text></ValueItemAction>}
    </Row>
    <ValueItemValue><Text size={large ? 21 : 16} bold color={color}>{value}</Text></ValueItemValue>
  </ValueItemContainer>
}

export default ValueItem
