import React, { useState } from 'react';
import gql from 'graphql-tag';
import GraphQLTable from '../generic/GraphQLTable';
import moment from 'moment';
import Communication from './Communication'
import { Card } from '../generic'

const toHHMMSS = (value) => {
  var sec_num = parseInt(value, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes + ':' + seconds;
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-Select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

const returnedInfo = (info) => {
  return JSON.parse(info)
}

const sendEvents = {
  "processed": "Preparing for delivery",
  "dropped": "Error: Couldn't deliver message",
  "deferred": "Still trying",
  "bounce": "Error: Undeliverable, check address",
  "delivered": "Delivered to remote server",
  "open": "Mail was opened",
  "click": "Link was clicked",
  "spam": "Error: Mail was flagged as SPAM",
  "unsubscribe": "Error: Recipient unsubscribed",
  "pending": "PENDING",
  "blist": "Error: Number is black listed",
  "delivrd": "Message delivered",
  "expired": "Error: Delivery timed-out",
  "undeliv": "Error: Undeliverable, check number",
  "read": "Read"
}

const trimStringOnSpace = (string, length) => {
  //trim the string to the maximum length
  let yourString = string + " "
  var trimmedString = yourString.substr(0, length);

  //re-trim if we are in the middle of a word
  return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
}

const config = {
  query: gql`{
    communications (limit:5000) {
      id
      client {
        idNumber
        firstNames
        surname
      }
      type
      to
      from
      cc
      message
      subject
      when
      createdAt
      deliveredAt
      status
      statusReason
      deliveredRawInfo
      rawInfo
      attachments {
        fileName
        fileId
        file {
          url
        }
      }
    }
  }
  `,
  dataset: data => data.communications.filter(m => m.to && !m.to.includes('it@')),
  fields: {
    message: { 
      label: 'Message',
      Cell: ({ value }) => (typeof value === 'string' && value.match(/<\/?[a-z][\s\S]*>/)) ? <div dangerouslySetInnerHTML={{ __html: value }} /> : value
    },
    client: { label: 'Client', accessor: row => row.client ? `${row.client.idNumber} ${row.client.firstNames} ${row.client.surname}` : '' },
    subject: { label: 'Subject' },
    to: { label: 'To' },
    type: { label: 'Type' },
    createdAt: { label: 'Sent', Cell: row => moment(row.value).format('DD-MM-YYYY hh:mm:ss') },
    status: {
      label: 'Status',
    },
    reason: {
      label: 'Expanded',
      accessor: row => {
        return (row.statusReason ? row.statusReason : row.deliveredRawInfo ? sendEvents[(returnedInfo(row.deliveredRawInfo).Status || '').toLowerCase()] : row.rawInfo && returnedInfo(row.rawInfo).errors ? trimStringOnSpace(returnedInfo(row.rawInfo).errors[0].errorMessage, 75) : row.status ? sendEvents[row.status.toLowerCase()] : row.status)
      },
      Cell: ({ value }) => {
        const Status = ''
        return (
          <span className={value && ['error', 'pending', 'message delivered', 'preparing for delivery', 'delivered to remote serve', 'mail was opened', 'link was clicked'].indexOf(value.toLowerCase()) > -1 ? "medium" : "bold medium"}>
            {value}
          </span>
        )
      }
    },
    deliveredAt: { label: 'Delivered', Cell: row => row.value ? moment(row.value).format('DD-MM-YYYY hh:mm:ss') : '' },
    elapsed: {
      label: 'Elapsed Time',
      id: 'elapsed',
      accessor: d => d.deliveredAt && d.createdAt ? toHHMMSS((d.deliveredAt - d.createdAt) / 1000) : '',
    }
  },
  defaultSorted: [{ id: 'createdAt', desc: true }]
};

const CommunicationsList = ({ history }) => {
  const [open, setOpen] = useState(false);
  const [communication, setCommunication] = useState({});

  return (
    <Card>
      <GraphQLTable config={config} onRowPress={data => {
        // if (data && data.client) {
        //   localStorage.setItem('searchString', data.client.idNumber);
        //   data.client && history.push(`/products/${data.client.idNumber}`)
        // }
        // else {
          setCommunication(data)
          setOpen(true)
        // }
        }
      } />
      <Communication communication={communication} open={open} setOpen={setOpen} history={history} />
    </Card>
);}

export default CommunicationsList;