import React, { useState } from 'react'
import gql from 'graphql-tag'
import GraphQLTable from '../generic/GraphQLTable'
import moment from 'moment'
import { Card, Button, Modal, Select } from '../generic'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const config = {
  query: gql`
    {
      paymentstatuss(limit: 10000) {
        id
       
        creditLife {
          policyNumber
          policyStatus
          client {
            idNumber
            firstNames
            surname
            debtCounselling {
              debtCounsellor {
                shortName
              }
            }
          }
        }
        info
        action
        status
        error
        done
        payment {
          amount
        }
        updatedAt
        expectedDate
      }
    }
  `,
  dataset: data => data.paymentstatuss,
  fields: {
    'creditLife.policyNumber': { label: 'Policy',accessor: row =>
    row.creditLife ? row.creditLife.policyNumber : null },
    'creditLife.policyStatus': { label: 'Status',accessor: row =>
    row.creditLife ? row.creditLife.policyStatus : null},
    'creditLife.client.idNumber': { label: 'ID Number',accessor: row =>
    row.creditLife ? row.creditLife.client.idNumber : null },
    clientName: {
      label: 'Name',width:130,
      accessor: row =>
        row.creditLife ? `${row.creditLife.client.firstNames} ${row.creditLife.client.surname}` : null
    },
    'creditLife.client.debtCounselling.debtCounsellor.shortName': {
      label: 'DC',
      accessor: row =>
        row.creditLife ? row.creditLife.client.debtCounselling.debtCounsellor.shortName : null
    },
    action: { label: 'Action', align: 'center' },
    status: {
      label: 'Status',
      align: 'right'
    },
    info: {
      label: 'Nedbank Message',width: 350,
      align: 'left'
    },
    //expectedDate: { label: 'Expected', width: 50, align: 'center', Cell: row => row.value ? moment(row.value).format('DD-MM-YYYY') :'-' },

    //'payment.amount': { label: 'Amount', width: 35,
    //  Cell: row => (row.value ? printCurrency(row.value) : '-')
    //},
    Updated: {
      label: 'Update',
      align: 'center',
      accessor: row => moment(row.updatedAt).format('DD-MM-YYYY')
    },
    updatedAt: { label: 'Relative', Cell: row => moment(row.value).fromNow() }
  },
  defaultSorted: [{ id: 'updatedAt', desc: true }]
}

const resubmitPayment = gql`
  mutation resubmitPayment(
    $firstNames: String
    $surname: String
    $idNumber: String
    $email: String
    $company: String
    $monthlyIncome: Float
    $cellNumber: String
    $leadMessage: String
    $leadInfo: [KeyValueInput]
    $bestContactTime: String
  ) {
    createChatBotLead(
      firstNames: $firstNames
      surname: $surname
      idNumber: $idNumber
      email: $email
      company: $company
      monthlyIncome: $monthlyIncome
      cellNumber: $cellNumber
      leadMessage: $leadMessage
      leadInfo: $leadInfo
      bestContactTime: $bestContactTime
    ) {
      id
    }
  }
`

const submitReasons = [
  { value: 'No idea', label: 'No idea' },
  { value: 'who knows', label: 'Who knows' },
  { value: 'Hope', label: 'Hoping something happens' },
  { value: 'David', label: 'David said so' }
]



const StatusList = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [submitReason, setSubmitReason] = React.useState(null);
  const [creditLife, setCreditLife] = React.useState(null);
  const [funeral, setFuneral] = React.useState(null);
  const [history, setHistory] = React.useState(null);

  const resubmitThePayment = () => {
    /*<Mutation mutation={createLead} >
      {(createChatBotLead, { loading, error, data, called }) => {
        if (!called) {
          createChatBotLead({ variables: { firstNames, surname, idNumber, email, monthlyIncome, cellNumber, leadMessage, bestContactTime } })
          console.log('resubmitting props are  ', props.steps)
            return <Spin tip="saving the conversation ..." />
          } else {
            return (
              <div>
                {loading && <Spin style={{ color: '#fff' }}  tip="saving conversation ..." />}
                {error && <ChatButton {...props} button={{
                  value: 999,
                  trigger: 'saveLeadError',
                  label: 'Oh dear, a problem'
                }} />}
              </div>
            )
          }
      }}
    </Mutation>
    */
  }

  
  console.log(' Status list ', props)

    return (

      <Card>
        <div className="content">
          <h1>Nedbank Communications History</h1>

          <GraphQLTable
            config={config}
            contextMenuId={"rightClick"}
            onRowPress={(row, string, event) => { console.log('onRowPress', event.currentTarget); setAnchorEl(event.currentTarget) }}
          />
        </div>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => (creditLife ? props.history.push(`/credit-life/${creditLife.id}`) : funeral ? props.history.push(`/funeral/${funeral.id}`) : null)} >
            Display Policy
          </MenuItem>
          <MenuItem onClick={() => setCreditLife(!creditLife)}>Resubmit Payment</MenuItem>
        </Menu>

        <Modal
          title="Do you want to resubmit the payment?"
          okText="Yes, send to Nedbank"
          cancelText="No, Go Back!"
          open={modalVisible}
          wrapClassName="vertical-center-modal"
          onOk={() => {
            setModalVisible(false)
            resubmitThePayment(submitReason)
          }}
          onCancel={() => setModalVisible(false)}
          onClose={() => setModalVisible(false)}
        >
          <Select
            label='Why are you resubmitting this?'
            style={{ width: 200 }}
            options={submitReasons}
            onChange={v => setSubmitReason(v.target.value)}
            placeholder="Select a reason"
            defaultValue="Client Affordability"
          />
        </Modal>
      </Card>
    )
}

export default StatusList
