import React from 'react'
import { GraphQLFormProvider, FormField, FieldValue, FieldLabel } from 'react-form-helper'
import { withRouter } from 'react-router-dom'
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { ActivityIndicator, BuildLayout, Card, renderInput, renderDisplay } from '../generic'

const GET_USERS = gql`
	query Users {
		users(limit:10000) {
			id
    	username
    	email
      forEmployeeId
			description
  	}
  }`

const fields = {
  name: {
    type: 'text',
    label: 'Name',
    addOnly: true
  },
  usersIds: {
    label: 'Select Authorised Users',
    options: [],
    autocomplete: true,
    type: 'multiselect',
    multiple: true,
    blankString: 'None',
  }
}

function FormLayout(props) {
  const { mode, options, cancel,formName } = props

  fields['usersIds'].options = options.sort((a, b) => (a.label > b.label) ? 1 : -1)

  return (
    <Card>
      <br />
      <BuildLayout formName={formName} fieldNames={Object.keys(fields)} mode={mode} fieldConfig={fields} spacing={3} cancel={cancel} />
    </Card>
  );
}

function UserGroupEdit({ mode, id, cancel, afterSubmit, data }) {

  const { loading, error } = data
    
  if (loading || error) return <ActivityIndicator />

  const THEUSERS = data.users.filter(u => u.email && u.description.indexOf('Unknown') === -1 && u.description.indexOf('undefined') === -1).map(e => ({ label: `${e.description && e.description} ${e.email}`, value: e.id }))

    return (
        <GraphQLFormProvider
          name="usergroup" // important to give the form a unique name
          mode="edit"           // can be "edit", "display" or "add". Normally passed in as a prop or derived if there is an id or not.
          fields={fields}       // See field constant for definitions. All fields defined on the update or add mutation will be added to the form however
          id={id}
          query={gql`query UserGroup($id: String!) {
            usergroup(id:$id) {
              id
              name
              identifier
              description
              usersIds
            }
            }`
          }
          // Remove unwanted fields as they will be added to the form and could override the value stored as they will not be set.
          // This mutation is a custom one with it's onwn input. The input is therefore defined in mapInputVariables below
          // we have no add mutation (but if you need one define it below)
          updateMutation={gql`
            mutation UpdateUserGroup($id:String!, $input: UpdateUserGroupInput!) {
              updateUserGroup(id:$id, input: $input) {
                id
                name
                description
                usersIds
              }
            }`
          }
          initialValuesFromData={ data => data.usergroup}  // make sure the values from the query are loaded into the form
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout {...props} formName={'usergroup'} cancel={afterSubmit} options={THEUSERS} />}
        afterSubmit={() => afterSubmit()}
        />
    )
  }

  UserGroupEdit = withApollo(UserGroupEdit);
UserGroupEdit = graphql(GET_USERS)(UserGroupEdit);
  export default withRouter(UserGroupEdit)
