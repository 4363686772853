import React, { useState } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getAccessToken } from '../../services/auth'
import { API_URL } from '../../config'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  input: {
    display: 'none'
  }
}));

const FileUpload = ({ file, setIsUploading, setFile, setFileId }) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);

  const uploadClicked = event => {
    console.log('fileupload file is', file)
  
    const accessToken = localStorage.getItem('accessToken') || getAccessToken()
    
    const formData = new FormData();
    formData.append('file', file);

    axios.post(`${API_URL}upload`, formData, {
      headers: {
        'Authorization': `JWT ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => {
        setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      }
    })
      .then(response => {
        console.log('file upload response is', response);
        setProgress(0);
        setFileId(response.data[0].id);
        setFile(null);
        setIsUploading(false)
      })
      .catch(error => {
        setError(error);
        setProgress(0);
        setIsUploading(false)
      });
  };

  return (<>
    <div className={classes.root}>
      {file && <p>{file.name}</p>}
      {error && <p>{error.message}</p>}
      {progress > 0 && progress < 100 && (
        <LinearProgress variant="determinate" value={progress} />
      )}
      {progress === 100 && (
        <p>Upload successful!</p>
      )}
    </div>
    <Button variant="contained" onClick={uploadClicked} color="primary" disabled={progress > 0}>
      Upload
    </Button>
  </>
  );
};

export default FileUpload;
