import styled from '@emotion/styled'

const SliderContainer = styled.div`
  padding-top: 14px;
  width: 100%;

  .MuiSlider-root {
    color: ${props => props.theme.colors.primary};
  }
  
  .PrivateValueLabel-circle {
    width: 50px;
    height: 50px;
  }

  .MuiSlider-thumb {
    width: 24px;
    height: 24px;
    box-shadow: 0px 4px 4px 2px rgba(230, 230, 230, 0.5);
  }  

  .MuiSlider-rail {
    margin-top: 3px;
    background-color: ${props => props.theme.colors.grey5};
    border-radius: 8px;
    height: 8px;
  }

  .MuiSlider-track {
    margin-top: 3px;
    border-radius: 8px;
    height: 8px;
  }

  .MuiInputBase-input {
    text-align: right;
    font-weight: 700;
    font-size: 16px;
  }
`

const CircleButton = styled.div`
  background-color: ${props => props.theme.colors.primary};
  width: 74px;
  height: 74px;
  border-radius: 37px;
`

const SliderTitleContainer = styled.div`
  margin-bottom: 8px
`

export {
  SliderContainer,
  CircleButton,
  SliderTitleContainer
}