import React from 'react';
import gql from 'graphql-tag';
import GraphQLTable from '../generic/GraphQLTable';
import { Card, Button } from '../generic'

export const getAllCreditors = gql`
{
 creditors(limit:10000) {
  id
  name
  source
  fspNumber
  bankAccount{
    id
    bankName
    accountNo
  }
  defaultClaimPaymentDetails {
    bankAccountType
    referenceType
    customReference
    bankName
    branchCode
  }
  contactDetails {
    type
    attention
    value
    purpose
  }
 }
}
 `

const config = {
  query: getAllCreditors,
  dataset: data => data.creditors,
  fields: {
    name: { label: 'Name' },
    "contactDetails": { label: 'Contact Details', Cell: ({ value }) => value ? value.map((contactDetail) => contactDetail.value).join(', ') : 'None defined' },
    "bankAccount.bankName": { label: 'Bank Name' },
    "bankAccount.accountNo": { label: 'Account No' },
    "defaultClaimPaymentDetails.bankAccountType": { label: 'Account Type' },
    "fspNumber": { label: 'FSP Number' },
    "defaultClaimPaymentDetails.referenceType": { label: 'Reference Type' },
    "defaultClaimPaymentDetails.customReference": { label: 'Customer Reference' },
  },
  defaultSorted: [{ id: 'name', desc: false }]
}
const CreditorsList = ({ history }) => (
  <Card>
    <div style={{ position: 'absolute', paddingTop: 5, left: '48em' }} >
      <Button color="primary" onClick={() => history.push(`/creditors/new`)}>Creditor</Button>
    </div>
    <GraphQLTable config={config} pageSize={30} onRowPress={creditor => history.push(`/creditors/${creditor.id}/edit`)}
    />

  </Card>
);

export default CreditorsList;