import React, { useState } from 'react'
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom'
import { ActivityIndicator, Grid, DisplayItem, FormModal, Button, Snackbar, Alert, Modal } from '../generic'
import moment from 'moment';
import { useConfirm } from "material-ui-confirm";
import EditIcon from '@material-ui/icons/Edit';
import EditGroupProduct from '../groups/EditGroupProduct';
import SalesInteractionUpdate from '../sales-interactions/SalesInteractionUpdate';
import { printCurrency } from '../../lib/utils';


export const GET_PRODUCT = gql`
	query GroupProduct($id: String!) {
		groupproduct(id: $id) {
    id
    group {
      id
      name
      postalCode
      email
      consultant {
        id
        first
        last
      }
    }
    funerals {
      id
      currentPremiumTotal
      state
    }
    groupProductNumber
    oldGroupProductNumber
    productType
    state
    processedUntil
    applicationDate
    paymentMethod
    paymentDueDay
    policyReadyDate
    pendingLapsedAt
    pendingExitAt
    lapsedAt
    cancelledAt
    cancelReason
    numFullPayments
  }
}`

const NOVATE_GROUP = gql`
	mutation($groupProductId: String) {
		novateGroupPolicy(groupProductId: $groupProductId) {
      id
		}
	}
`;

const EditModal = (props) => {
  const { size } = props
  return (
    <FormModal
      okText="Ok"
      cancelText="Back"
      width='90%'
      disableCancel={true}
      disableOk={true}
      size={size}
      componentProps={props}
    />
  )
}


let GroupFuneralInfo = (props) => {
  const { id, data, client: apolloClient } = props
  const { group, funerals, groupProductNumber, oldGroupProductNumber, productType, state, processedUntil, applicationDate, policyReadyDate, paymentDueDay, paymentMethod, pendingLapsedAt, pendingExitAt, lapsedAt, cancelledAt, cancelReason, numFullPayments } = data.groupproduct || {}
  const { first: groupConsultantFirstName, last: groupConsultantLastName, id: consultantId} = group.consultant || {}
  const totalPremiums = (funerals || []).filter(funeral => ['ACTIVE','DORMANT'].includes(funeral.state)).reduce((total, funeral) => total + (funeral.currentPremiumTotal || 0), 0)
  const confirm = useConfirm();
  const [snackOpen, setSnackOpen] = useState(false)
  const [consultantInteractionModal, setConsultantInteractionModal] = useState(false)
  const [snackSeverity, setSnackSeverity] = useState()
  const [snackText, setSnackText] = useState()

  const novateGroupProduct = (groupProductId) => {
    confirm({
      title: 'Novate Group',
      cancellationButtonProps: { variant: 'contained', color: 'default' },
      confirmationButtonProps: { variant: 'contained', color: 'primary' },
      description: `Press 'OK' to go ahead with Group Product Novation, press 'CANCEL' stop the operation.`
    }).then(() => {
      apolloClient
        .mutate({
          mutation: NOVATE_GROUP,
          variables: {
            groupProductId,
          }
        }).then(() => {
          setSnackSeverity('success')
          setSnackOpen(true);
          setSnackText('Group Novated');
        })
    })
  }

  return (
    <>
      <Grid container justifyContent='flex-start' textAlign='center' spacing={2}>
        <Grid item xs={3} alignItems='center' display="flex" justifyContent='flex-start'>
          <div style={{ display: "flex" }}>
            <h2> Group Product Details: </h2> 
            <EditModal Content={EditGroupProduct} noButton heading={`Change Group Product Details`} id={id} refetch={GET_PRODUCT} />
        </div>
        </Grid>
        <Grid item xs={6}>
        </Grid>
        <Grid item xs={3} >
        {!groupProductNumber && oldGroupProductNumber && <Button color='primary' onClick={() => novateGroupProduct(id)}> Novate Product </Button> }
        </Grid>
      </Grid>
      <Grid container justifyContent='flex-start' alignItems='center' spacing={2}>
        <DisplayItem label="Group Product Number" value={groupProductNumber} xs={3} />
        <DisplayItem label="Product Type" value={productType} xs={3} />
        <DisplayItem label="Total Premiums" value={printCurrency(totalPremiums)} xs={3} /> 
        <DisplayItem label="Product State" value={state} xs={2} />
        <> <DisplayItem label="Group Consultant" value={`${groupConsultantFirstName || ""} ${groupConsultantLastName || ""}`} xs={2} /> <EditIcon style={{ fontSize: 12, cursor: 'pointer' }} onClick={() => setConsultantInteractionModal(true)} />  </>
        <DisplayItem label="Payment Method" value={paymentMethod} xs={2} />
        <DisplayItem label="Payment Due Day" value={paymentDueDay} xs={2} />
        <DisplayItem label="Group Consultant" value={`${groupConsultantFirstName || ""} ${groupConsultantLastName || ""}`} xs={2} />
        <DisplayItem label="Group Name" value={group.name} xs={2} />
        <DisplayItem label="Group Email" value={group.email} xs={2} />
        <DisplayItem label="Group PostalCode" value={group.postalCode} xs={2} />
        <DisplayItem label="Policy Start Date" value={policyReadyDate && moment(policyReadyDate).format("Do MMM YYYY")} xs={2} />
        <DisplayItem label="Valid Until" value={processedUntil && moment(processedUntil).format("Do MMM YYYY")} xs={2} />
        { lapsedAt && <DisplayItem label="Lapsed At" value={lapsedAt && moment(lapsedAt).format("Do MMM YYYY")} xs={2} />}
        { cancelledAt && <DisplayItem label="Cancelled At" value={cancelledAt && moment(cancelledAt).format("Do MMM YYYY")} xs={2} />}
      </Grid>

      <Modal title={'Update Sales Person'}  okText="Ok" cancelText="Back" open={consultantInteractionModal}  onCancel={() => setConsultantInteractionModal(false)} maxWidth='md'>
      <SalesInteractionUpdate refetchQuery={GET_PRODUCT} refetchQueryId={id} product={{id: group.id, type: "GROUP_PRODUCT"}} setOpen={setConsultantInteractionModal} {...props}  />
      </Modal>

      <Snackbar open={snackOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={() => setSnackOpen(false)}>
        <Alert onClose={() => setSnackOpen(false)} severity={snackSeverity} >
          {snackText}
        </Alert>
      </Snackbar>
    </>)
}

const graphqlLoadingHOC = Comp => props => {

  if (props.data.loading) {
    return <ActivityIndicator />
  }

  if (props.data.error) {
    return <h1>{props.data.error.toString()}</h1>
  }

  return <Comp {...props} />
}

GroupFuneralInfo = withRouter(GroupFuneralInfo)
GroupFuneralInfo = withApollo(GroupFuneralInfo)
GroupFuneralInfo = graphqlLoadingHOC(GroupFuneralInfo)
GroupFuneralInfo = graphql(GET_PRODUCT, {
  options: ({ match, id }) => ({ variables: { id: match && match.params ? match.params.id : id } })
})(GroupFuneralInfo)
export default GroupFuneralInfo;
