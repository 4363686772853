import styled from '@emotion/styled'

const CheckboxContainer = styled.div`
  .MuiTypography-body1 {
    font-family: ${props => props.theme.fontFamily};
  }

  .MuiCheckbox-colorPrimary {
    color: ${props => props.theme.colors.primary} !important;
    
    .Mui-checked {
      color: ${props => props.theme.colors.primary} !important;
    }
  }

  .MuiIconButton-colorPrimary {
    color: ${props => props.theme.colors.primary};
  }

  margin-left: 14px;
`

export {
  CheckboxContainer
}