import React from 'react';
import { MaterialTable, SelectColumnFilter } from '../generic'
import moment from 'moment';
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
import { translateState } from '../funeral/FuneralInfo'
import { withRouter } from 'react-router-dom'


// switch between locales
Numeral.locale('en-za')

let ClientFuneral = ({ funeralPolicies, history }) => {

  const columns = [{
    Header: 'Policy Number',
    accessor: 'policyNumber',
    filter: 'fuzzyText'
  },
  {
    Header: 'Policy Type',
    id: 'policyType',
    accessor: d => (d.policyType),
    Filter: SelectColumnFilter,
    filter: 'Select'
  },
  {
    Header: 'Cover Amount',
    id: 'coverAmount',
    accessor: d => Numeral(d.coverAmount).format('$ ##,###0'),
    disableFilters: true
  },
  {
    Header: 'Premium',
    id: 'currentPremiumTotal',
    accessor: d => Numeral(d.currentPremiumTotal).format('$ ##,###0'),
    disableFilters: true
  },
  {
    Header: 'Created At',
    id: 'createdAt',
    assessor: 'createdAt',
    Cell: ({ value }) => moment(value).format('DD-MM-YYYY'),
    disableFilters: true
  },
  {
    Header: 'State',
    id: 'state',
    accessor: d => translateState(d.state),
    Filter: SelectColumnFilter,
    filter: 'Select',
    disableFilters: true
  }
  ]


  return (
    <MaterialTable columns={columns} data={funeralPolicies} onRowPress={(row) => history.push(`/funeral/${row.id}/edit`)} />
  )
}
ClientFuneral = withRouter(ClientFuneral)
export default ClientFuneral