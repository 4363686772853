import React from 'react';
import { MaterialTable } from '../generic'
import moment from 'moment';
import Numeral from 'numeral'
import { withRouter } from 'react-router-dom'


// switch between locales
Numeral.locale('en-za')

let ClientProducts = ({ clients }) => {

  console.log('ClientProducts', clients)
  const columns = [
    {
      Header: 'Funeral Policies',
      id: 'funeralPolicies',
      accessor: row => (row.funeralPolicies && row.funeralPolicies.length > 0 ? row.funeralPolicies.map(f => `${f.policyNumber} `) : 0),
      disableFilters: true
    },
    {
      Header: 'Id Number',
      accessor: row => `${row.idType === 'rsa_id' ? '' : row.idNumber ? row.idType : ''}${row.idNumber ? row.idNumber : ''}`,
      filter: 'fuzzyText'
    },
    {
      Header: 'Client Name',
      accessor: row => `${row.firstNames} ${row.surname}`,
      filter: 'fuzzyText'
    },
    {
      Header: 'Cell Number',
      id: 'cellNumbers',
      accessor: row => row.cellNumbers ? row.cellNumbers.join(', ') : '',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Created At',
      id: 'createdAt',
      assessor: 'createdAt',
      Cell: ({ value }) => moment(value).format('DD-MM-YYYY'),
      disableFilters: true
    },
    {
      Header: 'Claims',
      id: 'claims',
      accessor: row => (row.claims ? row.claims.length : 0),
      disableFilters: true
    },
  ]

  return (
    <>
      {`${clients.length} clients found`}
      <MaterialTable columns={columns} data={clients} onRowPress={(row) => {
        if (row.funeralPolicies.length > 1) {
          window.open(`/products/${row.idNumber}/edit`, "_blank");
        }
        else {
          window.open(`/funeral/${row.funeralPolicies[0].id}/edit`, "_blank");
        }

        // runSearch(row.idNumber)
      }} />
    </>
  )
}
ClientProducts = withRouter(ClientProducts)
export default ClientProducts

//history.push(`/products/${row.idNumber}`)}