import React, { useState, useEffect } from 'react'
import { useQuery, withApollo } from 'react-apollo';
import { makeStyles } from '@material-ui/core';
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import LoadedFile from './LoadedFile'
import { trash, pdf, tiff } from '../../../assets/images'
import { Button, Confirm } from '..';

const useStyles = makeStyles({
  button: {
    minWidth:130,
  }
})

const GET_FILE_INFO = gql`
query File ($id: String!) {
  file(id: $id) {
    id
    name
    url
    contentType
  }
}`

const UPDATE_CLIENT_DOCUMENT = gql`
mutation ($id: String!, $input: UpdateClientDocumentInput!) {
  updateClientDocument(id: $id, input: $input) {
    id
    type
    description
    verified
    verifiedBy {
      id
      description
    }
    file {
      id
      name
      url
      contentType
    }
  }
}`

const DeleteIcon = styled.img`
  height:18px;
  cursor:pointer;
  margin-left: 7px;
`
const DisplayIcon = styled.img`
  cursor:pointer;
  height:30px;
  max-width: 80px;
`

let updateClientDocument = (client, id, verified) => {
  // toggle the document verified
  if (id) {
    client
      .mutate({
        mutation: UPDATE_CLIENT_DOCUMENT,
        variables: {
          id,
          input: {
            verified
          }
        },
      })
  }
};

function DisplayFile(props) {
  const { fileId, clientDocumentId, documentType, onChange, displayIfValid, verified, disabled, noButtons, showLarge, client, noDelete } = props
  let { isPdf, isTiff } = props
  const { data, loading, error } = useQuery(GET_FILE_INFO, { variables: { id: fileId }, skip: !fileId }) 
  const [file, setFile] = useState({})
  const [open, setOpen] = useState(showLarge)
  const [showConfirm, setShowConfirm] = useState(false)
  const classes = useStyles();

  useEffect(() => {
    if (data) {
      if (fileId) {
        setFile(data.file || {})
      }
      else { setFile({}) }
    }
  }, [data, fileId]);

  const onDelete = () => { 
    onChange(null); 
    updateClientDocument(client, clientDocumentId, false); 
    setFile({})
  }

  if (loading) return <div>Loading...</div>

  if ((!open && !showLarge && (file && file.url) && (displayIfValid && verified !== false || !displayIfValid)) || showConfirm) {
    isPdf = isPdf || (file && file.contentType && file.contentType.toLowerCase().indexOf('pdf') > -1)
    return <>
      <Button className={ classes.button} variant="outlined" onClick={() => setOpen(true)} component="label" size='large'>
        <DisplayIcon alt={documentType} src={isPdf ? pdf : isTiff ? tiff : file.url} />
        {!disabled && !noButtons && !noDelete && <DeleteIcon src={trash} onClick={() => setShowConfirm(true) } />}
        <Confirm open={showConfirm} title='Delete file' description='Press OK to delete the attached file' onOk={() => { setShowConfirm(false); onDelete() }} onCancel={() => setShowConfirm(false)} />
      </Button>
    </>
  }
  else {
    return <LoadedFile {...props} file={file} url={file ? file.url : ''} onClose={() => { setOpen(false) }} />
  }
}

export default withApollo(DisplayFile)
