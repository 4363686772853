import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

function MyRadio({ options, value, onChange }) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  let radios = options
  if (radios && !radios[0].label) {
    radios = options.map(o => ({ value: o, label: o }))
  }

  console.log('options are ', options, radios)

  return <FormControl component="fieldset">
    <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
      {radios && radios.map(radio =>
        <FormControlLabel value={radio.value} control={<Radio />} label={radio.label} />
      )}
    </RadioGroup>
  </FormControl>
}

export default MyRadio
