import React, { Component } from 'react'
import gql from 'graphql-tag'
import { GraphQLFormProvider } from 'react-form-helper'
import { RELATIONSHIPS } from '../../variables'
import { renderInput } from '../generic';
import { BuildLayout } from '../generic';

const renderDisplayField = (type) => ({ field, value, input }) => {
  return (
    <DisplayField style={{ fontWeight: 'bold' }}>{value || input.value || '-'}</DisplayField>
  )
}
const config = {
  name: 'claimant',
  fields: {
    relationship: {
      label: 'Claimant relationship to Member',
      type: "select",
      options: RELATIONSHIPS.sort((a, b) => (a > b) ? 1 : -1).map(a => ({ value: a, label: a })),
      validate: (value) => {
        if (!value) {
          return 'Relationship is Required'
        }
      }
    }
  },
  query: gql`
    query ClaimData($id: String!) {
      claim(id: $id) {
        id
        relationship
      }
    }`,
  updateMutation: gql`
    mutation UpdateClaim($input: UpdateClaimInput!, $id: String!) {
      updateClaim(id: $id, input: $input) {
        id
        relationship
      }
  }`
}

const FormLayout = (props) => {

  let { mode, submit, cancel, formName } = props;

  const fieldNames = Object.keys(config.fields)
  return <BuildLayout formName={formName} fieldNames={fieldNames} fieldConfig={config.fields} direction='column' mode={mode} spacing={2} align='center' justify='center' cancel={cancel} submit={submit} />
}


let EditClaimant = (props) => {
  const { id, history, cancel, afterSubmit } = props

  return (
    <div>
      <GraphQLFormProvider
        initialValuesFromData={(data) => data.claim}
      
        mode={'edit'}
        id={id}
        {...config}
        afterSubmit={cancel}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout {...props} formName={config.name} cancel={cancel} history={history} />}
      />
    </div>
  )
}

export default EditClaimant
