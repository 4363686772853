import React from 'react'
import { StyledText, StyledSpan } from './Text.style'

function Text(props) {
  if (props.span) {
    return <StyledSpan {...props} />
  }
  return <StyledText {...props} />
}

export default Text
