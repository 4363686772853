import React, { useState } from 'react'
import { withApollo } from 'react-apollo'
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag'
import { GraphQLFormProvider, FormField, FormFieldArray, FieldValue } from 'react-form-helper'
import SubmitFormButton from 'react-form-helper/dist/SubmitFormButton';
import { renderInput, DisplayFile, renderDisplay, Grid } from '../generic';
import { ccInformation } from './ClearanceCertificateInfo'
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'

// switch between locales
Numeral.locale('en-za')

const useStyles = makeStyles((theme) => ({
  even: {
    flexGrow: 1,
    justifyContent: 'center',
    paddingBottom: 7
  },
  start: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 7
  },
  documents: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 7,
    paddingLeft: 7,
  },
  column: {
    minWidth: 300,
    padding: 0,
  },
  paper: {
    flex: 1,
    padding: theme.spacing(1),
    textAlign: 'center',
  },
}));

const config = {
  name: 'clearancecertificate',
  fields: {
    forObligations: {
      label: 'Loans',
      type: 'fieldarray',
      config: {
        "settlementLetters.fileId": {
          label: 'Settlement Letter',
          type: 'fileupload',
          validate: (value) => {
            if (!value) {
              return 'settlement letter required'
            }
          }
        },
      }
    }
  },
  query: gql`
    query ClearanceCertificate($id: String!) {
      clearancecertificate(id: $id) {
        id
        client {
          id
          firstNames
          surname
          idNumber
        }
        forObligations {
          id
          obligationId
          statusAt
          statusReason
          obligationId
          obligation {
            id
            reference
            settlementLetters {
              id
              fileId
              file {
                id
                url
                }
              }
            }
          }
        dcTransferStartedAt
        dcTransferCompletedAt
        dcTransferRejectedAt
      }
    }`,
  updateMutation: gql`
    mutation UpdateClearanceCertificate($input: UpdateClearanceCertificateInput!, $id: String!) {
      updateClearanceCertificate(id: $id, input: $input) {
          id
        clientId
        paymentDay
        paymentBankAccountId
        debitOrderId
         forObligations {
          id
          obligationId
          statusAt
          statusReason
          obligationId
          obligation {
            id
            reference
            settlementLetters {
              id
              fileId
              file {
                id
                url
                }
              }
            }
          }
        clearanceCertificate {
          id
          name
          file {
            id
            url
          }
          fromSimplicity
          verified
        }
        state
        signature {
          id
          url
        }
        applicationDate
        previousDebtCounsellor {
          id
          fullName
        }
        certificateNumber
        amount
        amountPaid
        dcTransferStartedAt
        dcTransferCompletedAt
        dcTransferRejectedAt
        settlementLettersRequested
        signupSalesInteraction {
          id
          salesPerson {
            id
            first
            last
            email
          }
        }
        createdAt
        state
      }
  }`,
  addMutation: gql`
  mutation CreateClearanceCertificate($input: CreateClearanceCertificateInput!) {
    createClearanceCertificate(input: $input) {
        id
        clientId
        paymentDay
        paymentBankAccountId
        debitOrderId
         forObligations {
          id
          obligationId
          statusAt
          statusReason
          obligationId
          obligation {
            id
            reference
            settlementLetters {
              id
              fileId
              file {
                id
                url
                }
              }
            }
          }
        clearanceCertificate {
          id
          name
          file {
            id
            url
          }
          fromSimplicity
          verified
        }
        state
        signature {
          id
          url
        }
        applicationDate
        previousDebtCounsellor {
          id
          fullName
        }
        certificateNumber
        amount
        amountPaid
        dcTransferStartedAt
        dcTransferCompletedAt
        dcTransferRejectedAt
        settlementLettersRequested
        signupSalesInteraction {
          id
          salesPerson {
            id
            first
            last
            email
          }
        }
        createdAt
        state
    }
  }`
}

const Documents = ({ field, index, claimPaymentCounter, changeFieldValue, mode, fields }) => {
  let verified
  let description
  return (
    <Grid container className={classes.documents} spacing={1}>
      {claimPaymentCounter > 0 && <Grid item xs={1} >
        <FieldValue noLabel mode='display' name={`${field}.month`} renderField={({ input, value }) => {
          const month = input ? input.value : value
          return (month ? month : latestMonth)
        }}
        />
      </Grid>}

      <Grid justifyContent='flex-start' item xs={7} >
        <FieldValue noLabel mode='display' name={`${field}.requirementId`} renderField={({ input, value }) => {
          const requirementId = input ? input.value : value
          return <>
            <FieldValue variant='standard' noLabel mode={requirementId ? 'display' : 'edit'} name={`${field}.description`} renderField={({ input, value }) => {
              description = input ? input.value : value
              return expandDocumentDescription(description)
            }} />
            <FieldValue noLabel variant='standard' mode={requirementId ? 'display' : 'edit'} name={`${field}.requirement.documentType`} renderField={({ input, value }) => {
              const documentType = input ? input.value : value
              return documentType ? ` - ${documentType}` : ''
            }} />
          </>
        }} />
      </Grid>

      <Grid item xs={1}>
        <FieldValue noLabel mode='display' name={`${field}.verified`} renderField={({ input, value }) => {
          verified = input ? input.value : value
          return (verified === true ? 'APPROVED' : verified === false ? 'REJECTED' : ' ')
        }}
        />
      </Grid>

      <Grid item xs={1} >
        <FieldValue noLabel mode='display' name={`${field}.id`} renderField={({ input, value }) => {
          const claimDocumentId = input ? input.value : value
          return <FieldValue noLabel variant='standard' mode='display' name={`${field}.fileId`} renderField={({ input, value }) => {
            let fileId = input ? input.value : value
            return <DisplayFile key={claimDocumentId} disabled={mode === 'display'} fileId={fileId} claimDocumentId={claimDocumentId} clientInfo={clientInfo} verified={verified} description={description}
              onChange={(id) => {
                input.onChange(id);
                if (!id) {
                  changeFieldValue(`${field}.verified`, null)
                } else {
                  changeFieldValue(`${field}.verified`, true)
                }
              }}
              setVerify={(result) => changeFieldValue(`${field}.verified`, result)}
            />
          }} />
        }} />
      </Grid>

      {mode !== 'display' && (
        <Grid item >
          <FieldValue name={`${field}.fileId`} renderField={({ meta }) => {
            if (meta && meta.error) {
              return <span style={{ color: 'red', fontWeight: 700 }}>{meta.error}</span>
            }
            else if (meta && meta.warning) {
              return <span >{meta.warning}</span>
            }
            else return <div />
          }}
          />
        </Grid>
      )}

    </Grid>)
}

const FormLayout = (props) => {
  const classes = useStyles();
  let { cancel, history, data } = props;
  let { clearancecertificate } = data || {};
  let { client: clientInfo, forObligations } = clearancecertificate || 0;
  console.log('edit clearance certificate forObligations', forObligations)

  const stellementLetters = false && forObligations && forObligations.length > 0 ? forObligations.filter(o => o && o.obligation && o.obligation.settlementLetters) : [
    {
      "id": "22dbc0a7-5f6d-4b3c-aae7-8f1360e23e20XX",
      "obligation": {
        id: "624eb0d0812853001cb00bef",
        settlementLetters: { id: "624eb0d0812853001cb00bef" }
      }
    },
    {
      "id": "624eb0d0812853001cb00beeXX",
      "obligationId": "624eb0d0812853001cb00bee",
    },
    {
      "id": "624eb0d0812853001cb00bedXX",
      "obligationId": "624eb0d0812853001cb00bed",
    },
    {
      "id": "22dbc0a7-5f6d-4b3c-aae7-8f1360e23e20",
      "obligationId": "624eb0d0812853001cb00bef",
    }
  ].filter(o => o && o.obligation && o.obligation.settlementLetters);

  console.log('clearance certificate stellementLetters', stellementLetters)

  return (
    <Grid container className={classes.documents} spacing={1}>
      <h2>Setllement Letters</h2>
      <FormFieldArray name='forObligations' renderFieldArray={({ fields }) => {
        return fields.map((field, index) => <FieldValue mode='display' name={`${field}.obligationId`} renderField={({ input, value }) => {
          const description = input ? input.value : value
          { console.log('clearance certificate fieldarray', field, description) }
          return (
            // <Documents claimantDocs={true} index={index} field={field}  changeFieldValue={changeFieldValue} mode={'edit'} documents={documents} />
            <>
              <Grid item xs={12} >
                {description}
              </Grid>
            </>
          )
        }} />)
      }} />
    </Grid>)

  return (<>
    {stellementLetters.map((o, i) => {
      const field = `obligation[${i}].settlementLetters.id`
      return <Grid item xs={6} >
        Settlement letter
        <FieldValue noLabel mode='display' name={`${field}`} renderField={({ input, value }) => {
          const claimDocumentId = input ? input.value : value
          return <FieldValue noLabel variant='standard' mode='display' name={`${field}.fileId`} renderField={({ input, value }) => {
            let fileId = input ? input.value : value
            return <DisplayFile key={fileId} fileId={fileId} clientInfo={clientInfo} description={'Settlement letter'}
              onChange={(id) => {
                input.onChange(id);
                if (!id) {
                  changeFieldValue(`${field}.verified`, null)
                } else {
                  changeFieldValue(`${field}.verified`, true)
                }
              }}
              setVerify={(result) => changeFieldValue(`${field}.verified`, result)}
            />
          }} />
        }} />
      </Grid>
    })
    }
    <br /> <br />
    <SubmitFormButton renderSubmitButton={({ hasChanges, invalid, submit, submitting }) => {
      if (hasChanges) {
        return (<Grid container className={classes.footer}>
          <Grid item className={classes.button}>
            <Button fullWidth variant='contained' color='default' onClick={() => cancel ? cancel() : push ? push() : ''}>
              Cancel
            </Button>
          </Grid>
          <Grid item className={classes.button}>
            <Button fullWidth variant='contained' color='primary' disabled={invalid || submitting} onClick={() => submit()}>
              Save
            </Button>
          </Grid>
        </Grid>)
      }
      else {
        return <div />
      }
    }} />
  </>);
}

let EditCC = (props) => {

  const { cancel, id, history } = props

  return (
    <div>
      <GraphQLFormProvider
        mode="edit"
        id={id}
        {...config}
        name="clearancecertificate"
        afterSubmit={({ data }) => { cancel() }}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout {...props} cancel={cancel} history={history} />}
        DisplayFormLayout={FormLayout}
        initialValuesFromData={data => data.clearancecertificate}
        refetchQueries={[{ query: ccInformation, variables: { id } }]}
      // mapInputVariables={props => {
      //   let { firstNames, surname, ...input } = props
      //   if (firstNames) {
      //     input.firstNames = nameCase(firstNames)
      //   }
      //   if (surname) {
      //     input.surname = nameCase(surname)
      //   }
      //   return ({ input })
      // }
      // defining the input to use the input needed in the update mutation.
      />
    </div>
  )
}

EditCC = withApollo(EditCC);
export default EditCC