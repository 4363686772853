import React, {Fragment,  useState, Component } from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
import SubmitFormButton from 'react-form-helper/dist/SubmitFormButton';
import { GraphQLFormProvider, FormField, FormFieldArray } from 'react-form-helper'
import { Button, Card, Grid, renderInput, renderDisplay } from '../generic';
import { withRouter } from 'react-router-dom'

const PaymentTypes = ["INCOME_BASED","LUMP_SUM"]

const fields = {
  nextPaymentBreakdown: {
    label: 'Payment Breakdown',
    type: 'fieldarray',
    config: {
        id: {
          type: 'text',
          label: 'ID',
        },
        type: {
          type: 'text',
          label: 'Type',
        },
        "obligation.clientCreditor.description": {
        type: 'text',
        label: 'Creditor',
        strip:true,
        },
        amount: {
          type: 'text',
          label: 'Amount '
        },
        month: {
          type: 'text',
        },
        claimPaymentType: {
          type: 'select',
          label: 'Payment Type',
          options:PaymentTypes
        }
    }
  }
}

const renderDisplayField = (type) => ({value, input}) => {
  const displayValue = input && input.value || value
  return (
    <DisplayField style={{ fontWeight: "bold" }}>{displayValue || "-"}</DisplayField>
  )
}


function FormLayout(props) {
  const { cancel, data } = props
  const { nextPaymentBreakdown } = data.claim
  const claimTotal = nextPaymentBreakdown.reduce((total, p) => total + (p.amount || 0), 0)

  return (

    <FormFieldArray name='nextPaymentBreakdown' renderFieldArray={({ fields }) => {

      return (
        <Card>

          <Grid container spacing={1}>
            <Grid item xs={3} align="right"><b>Total Payments: {Numeral(claimTotal).format('$ ##,###0.00')}</b></Grid>
          </Grid>

          <Grid container spacing={1} >
            <Grid item xs={4}>Description</Grid>
            <Grid item xs={2}>Type</Grid>
            <Grid item xs={2} >Month</Grid>
            <Grid item xs={2} >Payment Type</Grid>
            <Grid item xs={2} align={"right"}>Amount</Grid>
          </Grid>

          { fields.map((field, index) => {
        return (
          <Grid conatiner spacing={1}>
            <Grid item xs={4}><FormField nolabel name={`${field}.obligation.clientCreditor.description`} displayOnly={true} /></Grid>
            <Grid item xs={2}><FormField nolabel name={`${field}.type`} displayOnly={true} /></Grid>
            <Grid item xs={2}><FormField nolabel name={`${field}.month`} displayOnly={true} /></Grid>
            <Grid item xs={2}><FormField nolabel name={`${field}.claimPaymentType`} /></Grid>
            <Grid item xs={2} align="right"><FormField nolabel name={`${field}.amount`} /></Grid>
          </Grid>
        )})}

          <Grid container spacing={1}>
            <Grid item xs={2}></Grid>
          </Grid>

          <SubmitFormButton renderSubmitButton={({ invalid, submit, submitting }) => (
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <Button color="secondary" style={{ width: 100 }} onClick={() => cancel()}>Cancel
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button color="primary" style={{ width: 100 }} disabled={invalid || submitting} submitting={submitting} onClick={() => { submit(); }}>Save</Button>
              </Grid>
            </Grid>
          )} />
    </Card>
    )}} />

  );
}

FormLayout = withRouter(FormLayout)

function ClaimPayments({ mode, id, cancel, afterSubmit }) {
    return (
        <GraphQLFormProvider
          name="claimPayments" // important to give the form a unique name
          mode="edit"           // can be "edit", "display" or "add". Normally passed in as a prop or derived if there is an id or not.
          fields={fields}       // See field constant for definitions. All fields defined on the update or add mutation will be added to the form however
          id={id}
          query={gql`query Claim($id: String!) {
            claim(id: $id) {
              id
              nextPaymentBreakdown {
                id
                type
                obligation {
                  clientCreditor {
                    description
                    }
                  }
                amount
                claimPaymentType
                month
                }
              }
            }`
          }
          // Remove unwanted fields as they will be added to the form and could override the value stored as they will not be set.
          // This mutation is a custom one with it's onwn input. The input is therefore defined in mapInputVariables below
          // we have no add mutation (but if you need one define it below)
          updateMutation={gql`
            mutation updateFutureClaimBreakdown($id: String!, $input: [ClaimBreakdownInput]) {
              updateFutureClaimBreakdown(id: $id, input: $input) {
                id
                amountClaimed
                nextPaymentBreakdown {
                  id
                  type
                  obligation {
                    id
                    clientCreditor {
                      description
                      }

                  amount
                  month
                  claimPaymentType
                }
              }
            }`
          }
        initialValuesFromData={data => data.claim}  // make sure the values from the query are loaded into the form
        mapInputVariables={input => ({ input: input.nextPaymentBreakdown.map(o => { const { obligation, ...data } = o; return data }) })}  // defining the input to use the input needed in the update mutation. We strip month and obligation (both defined in fields)
        renderDisplay={renderDisplay}
        renderInput={renderInput}
          InputFormLayout={ (props) => <FormLayout {...props} cancel={cancel}  />   }
          afterSubmit={() => afterSubmit() }
        />
    )
  }

export default withRouter(ClaimPayments)