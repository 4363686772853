import React from 'react';
import { v4 as uuidv4 } from 'uuid';
//import Input from '@material-ui/core/Input';
import { TextField as MuText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputRoot: {
    '&$disabled': {
      color: "rgba(0, 0, 0, 0.99)"
    },
  },
  disabled: {}
}));

const styles = () => ({
  formControl: {
    marginRight: 8,
    color: "rgba(0, 0, 0, 1)",
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.99)" // (default alpha is 0.38)
    },
    "& .MuiInputLabel-root": {
      fontSize: 31 // (default is 16)
    }
  }
});

function TextField(props) {
  const classes = useStyles();

  const {
    err,
    maxLength,
    disabled,
    fullWidth,
    type,
    label,
    multiline,
    rows,
    rowsMax,
    value,
    helperText,
  } = props;


  return (
    <MuText
      {...props}
      label={label}
      id={uuidv4()}
      InputProps={{
        maxLength: maxLength,
        classes: {
          root: classes.inputRoot,
          disabled: classes.disabled
        }
      }}
      onChange={props.onChange}
      onBlur={props.onBlur}
      value={value}
      disabled={disabled}
      fullWidth={fullWidth}
      type={type}
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
      helperText={helperText}
    />
  );
}

export default TextField
  // = withStyles(styles)(TextField);

