import React, { useEffect, useState } from 'react'
import Slider from '@material-ui/core/Slider'
import { SliderContainer, SliderTitleContainer } from './Slider.style'
import Text from '../Text'
import TextInput from '../TextInput'
import { printCurrency } from '../../../lib/utils'
import InputAdornment from '@material-ui/core/InputAdornment';

function MySlider({ label, required, noInput, maxValue, minValue, step, value, onChange, onBlur, endText = "pm", startText = "R", error, helperText }) {
  const [sliderValue, setSliderValue] = useState(value)

  useEffect(() => setSliderValue(value), [value])  

  cosole.log('slider range is ', minValue, maxValue)

  return <SliderContainer>
    <Text size={13} padBottom="2px">{label} {required && <Text span primary size={20}>*</Text>}</Text>
    {!noInput && <TextInput value={sliderValue} onChange={e => setSliderValue(e.target.value)} onBlur={(e) => onChange(e.target.value)} InputProps={{ endAdornment: endText && <InputAdornment position="end">{endText}</InputAdornment>, startAdornment: startText && <InputAdornment position="start">{startText}</InputAdornment> }} error={error} helperText={helperText} />}
    <Slider 
      step={step}  
      min={minValue}
      max={maxValue}
      value={sliderValue}
      onChange={(e, v) => setSliderValue(v)}
      onChangeCommitted={() => onChange(sliderValue)}
    />
  </SliderContainer>
}

export default MySlider
