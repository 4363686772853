import React from 'react'
import styled from '@emotion/styled'

const CardContainer = styled.div`
  box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
  background-color: #FAFAFA;
  margin-top: 8px;
  padding:10px 10px 10px 21px;
`

function Card({ children, className, onClick }) {
  return (
    <CardContainer className={className} onClick={onClick}>
      {children}
    </CardContainer>
  )
}

export default Card