import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
//import Select from 'react-select';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import AutoCompleteSelect from './autocomplete-select';

const styles = (theme) => ({
  input: {
    display: 'flex',
    padding: 0,
    minWidth: 240, // A good default for most labels
    height: 'auto',
  },
  root: {
    // Allow for help icon to be placed on right of field
    display: 'inline-flex',
  },
  fullWidth: {
    width: '100%',
  },
  inputRoot: {
    '&$disabled': {
      color: "rgba(0, 0, 0, 0.99)"
    },
  },
  disabled: {
    color: "rgba(0, 0, 0, 0.99)"
  },
  formControl: {
    marginRight: 8,
    color: "rgba(0, 0, 0, 1)",
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.99)" // (default alpha is 0.38)
    },
    "& .MuiInputLabel-root": {
      fontSize: 16, // (default is 16)
    }
  }
});

function SelectField(props) {

  function handleChange(value) {
    props.onChange(value);
  }

  function handleAutocompleteChange(value) {
    const { multiple } = props;
    if (multiple) {
      handleChange(value ? value.map((val) => val.value) : null);
    } else {
      handleChange(value && (value.value ? value.value : null));
    }
  }


  function renderOptions() {
    const { options, blankString, value, multiple } = props;

    if (options) {
      let opts = [];

      if (!multiple && blankString) {
        // Note: the blankString doesn't make sense when we allow multiple selections
        opts.push(
          <MenuItem value="" key="">
            <em>{blankString}</em>
          </MenuItem>
        );
      }

      options.forEach((option) => {
        if (multiple) {
          const checked = value ? value.indexOf(option.value) !== -1 : false;
          opts.push(
            <MenuItem value={option.value} key={option.value}>
              <Checkbox checked={checked} />
              <ListItemText primary={option.label} />
            </MenuItem>
          );
        } else {
          opts.push(
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          );
        }
      });

      return opts;
    }
  }


    const {
      value,
      err,
      touched,
      disabled,
      fullWidth = true,
      classes = { chip: "Select-chip-55", formControl: "Select-formControl-54" },
      multiple,
      accessEditable,
      useDisplayValue,
      options,
      label,
      freeSolo,
      autocomplete, ...rest
    } = props;

    const dis = accessEditable === false || disabled;

    let fieldValue = multiple ? [] : '';
    if (value) {
      fieldValue = value;
    }

    let input = undefined;
    let renderValue = undefined;
    if (multiple) {
      input = <Input />;

      renderValue = (selected) => (
        <div className={classes.chips}>
          {selected.map((value) => (
            <Chip
              key={value}
              label={label}
              className={classes.chip}
            />
          ))}
        </div>
      );
    }

    const optionalProps = {};

  const getOptionLabel = (value) => {
      const label = options.find(o => o.value === value)
      return label ? label.label : value
    }

    const isValueBlank = (value) => !value || value === null

    let fld = null;
    const ariaLabel = label;

      if (autocomplete) {
        let autocompleteValue = null;

        if (multiple) {
          autocompleteValue = fieldValue.map((value) => ({
            value: value,
            label: getOptionLabel(value),
          }));
        } else {
          autocompleteValue = {
            value: fieldValue,
            label: getOptionLabel(fieldValue),
          };
        }

        // Shrink the label?
        if (!isValueBlank(fieldValue)) {
          optionalProps.shrinkLabel = true;
        }

        return (
          <>
            <InputLabel id="multi-select-label" style={{ fontSize: 16 }}>{label}</InputLabel>
            <AutoCompleteSelect
              id={uuidv4()}
              options={options}
              isClearable={true}
              placeholder=""
              onChange={(value) => handleAutocompleteChange(value)}
              value={autocompleteValue}
              isDisabled={dis}
              isMulti={multiple}
              freeSolo={freeSolo}
              fullWidth={fullWidth}
            />
          </>
        );
      } else {
        return (
          <>
            <InputLabel id="select-label" style={{ fontSize: 16 }}>{label}</InputLabel>
            <Select
              id={uuidv4()}
              className={classNames(classes.root, fullWidth && classes.fullWidth)}
              classes={classes}
              multiple={multiple}
              error={touched && err ? true : false}
              onChange={(event) => handleChange(event.target.value)}
              input={input}
              renderValue={renderValue}
              value={fieldValue}
              disabled={dis}
              label={label}
              variant="outlined"
            >
              {renderOptions()}
            </Select>
          </>
        );
      }

}

export default withStyles(styles)(SelectField);
