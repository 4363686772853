import React, { Component } from 'react'
import { compose, withProps, lifecycle } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, OverlayView } from 'react-google-maps'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import ProfilePicture from '../components/generic/ProfilePicture'

import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer'
import { FilterBar, /*SelectTreeFilterItem, LocationFilterItem, */DateFilterItem } from '../components/generic/filter'
import { DashboardGraph } from '../components/generic/graphs'
import MetricCard from '../components/generic/MetricCard'
import DashboardCard from '../components/generic/DashboardCard'
import { DashboardRow } from '../components/generic/grid'
import { Card, Col, Row} from '../components/generic'
import OverlayViewMarker from '../components/generic/OverlayViewMarker'
import { processSalesInteractions } from '../lib/salesStatistics'
import PlaybackModal from '../components/recordings/PlaybackModal'

import moment from 'moment'

import SalesInteractionFeed from '../components/sales-interactions/SalesInteractionFeed'
import TopNCard from '../components/generic/TopNCard'

import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
import PropTypes from 'prop-types'
/* global google */

// switch between locales
Numeral.locale('en-za')

const getSalesInteractions = gql`
  {
    salesinteractions(limit: 10000) {
      id
      location {
        latitude
        longitude
      }
      result
      salesPerson {
        id
        first
        last
        photo {
          id
          url
        }
      }
      recording {
        id
        url
      }
      funeral {
        id
        currentPremiumTotal
      }
      timestamp
    }
    salesteams {
      id
      name
      managerOfTeam {
        id
        first
        last
      }
      teams {
        id
      }
      members {
        id
        first
        last
      }
    }
    targets {
      metric
      frequency
      level
      value
    }
  }
`

const subscribeToSalesInteractions = gql`
  subscription {
    salesinteractionCreated {
      id
      location {
        latitude
        longitude
      }
      result
      salesPerson {
        id
        first
        last
      }
      recording {
        id
        url
      }
      funeral {
        id
        currentPremiumTotal
      }
      timestamp
    }
  }
`

const targetsToGoals = (targets) => {
  const result = {}
  targets && targets.forEach(target => {
    if (target.level === 'SALES_PERSON') {
      if (!result[target.metric]) {result[target.metric] = {} }
      result[target.metric][target.frequency] = target.value
    }
  })
  return result
}

const startEndActivity = salesActivities => {
  let start, end

  salesActivities.forEach(salesActivity => {
    if (!start || salesActivity.timestamp > start) {
      start = salesActivity.timestamp
    }
    if (!end || salesActivity.timestamp < end) {
      end = salesActivity.timestamp
    }
  })

  return {
    start,
    end
  }
}
const teamTreeNode = (team, exclude = []) => {
  const node = [
    {
      label: `${team.managerOfTeam.first} ${team.managerOfTeam.last} (Manager)`,
      value: team.managerOfTeam.id
    }
  ]
  team.members.filter(member => !exclude.includes(member.id)).forEach(member => {
    node.push({
      label: `${member.first} ${member.last}`,
      value: member.id
    })
  })
  return node
}

const buildFilterNodeTree = (nodes, children, exclude) => {
  return children.map(id => {
    const child = nodes[id]
    if (child.teams.length > 0) {
      return {
        label: child.name,
        value: child.id,
        children: [
          ...teamTreeNode(child, exclude),
          ...buildFilterNodeTree(nodes, child.teams.map(team => team.id), exclude)
        ]
      }
    } else {
      return {
        label: child.name,
        value: child.id,
        children: teamTreeNode(child, exclude)
      }
    }
  })
}

const calculateFilterName = (salesTeamNodes, rootNodes, filterSelected) => {
  let filterName = ''
  let teamName
  let fullMatches = 0
  rootNodes.forEach(id => {
    let memberName
    const salesTeam = salesTeamNodes[id]
    const hasAllMembers =
      !salesTeam.members ||
      salesTeam.members.reduce((result, member) => {
        if (filterSelected.includes(member.id)) {
          if (!memberName) {
            memberName = `${member.first} ${member.last}`
          } else {
            memberName = 'Multiple sales people'
          }
          return result
        } else {
          return false
        }
      }, true)
    const hasManager = filterSelected.includes(salesTeam.managerOfTeam.id)
    if (hasManager) {
      if (!memberName) {
        memberName = `${salesTeam.managerOfTeam.first} ${salesTeam.managerOfTeam.last}`
      } else {
        memberName = 'Multiple sales people'
      }
    }
    if (hasAllMembers && hasManager) {
      if (salesTeam.teams.length > 0) {
        const result = calculateFilterName(salesTeamNodes, salesTeam.teams.map(team => team.id), filterSelected)
        if (result === 'All Sales Teams') {
          fullMatches += 1
          if (!teamName) {
            teamName = salesTeam.name
          } else {
            teamName = 'Multiple sales teams'
          }
        }
      } else {
        fullMatches += 1
        if (!teamName) {
          teamName = salesTeam.name
        } else {
          teamName = 'Multiple sales teams'
        }
      }
    } else {
      filterName = memberName
    }
  })
  if (fullMatches === rootNodes.length) {
    return 'All Sales Teams'
  }
  return filterName || teamName || 'All Sales Teams'
}

const processSalesTeams = (salesTeams, selected = ['all'], loading) => {
  if (loading) {
    return {
      filterNodes: [
        {
          label: 'All Sales Teams',
          value: 'all'
        }
      ],
      rootNodes: [],
      filterSelected: []
    }
  }

  const salesTeamNodes = {}
  const isChild = []
  const isManager = []
  const filterSelected = []
  salesTeams.forEach(salesTeam => {
    salesTeamNodes[salesTeam.id] = salesTeam
    if (salesTeam.teams.length > 0) {
      salesTeam.teams.forEach(team => {
        isChild.push(team.id)
      })
      isManager.push(salesTeam.managerOfTeam.id)
    }
    if (selected.includes(salesTeam.managerOfTeam.id) || selected.includes('all')) {
      filterSelected.push(salesTeam.managerOfTeam.id)
    }
    salesTeam.members.forEach(member => {
      if (selected.includes(member.id) || selected.includes('all') || selected.includes(salesTeam.id)) {
        filterSelected.push(member.id)
      }
    })
  })

  const rootNodes = Object.keys(salesTeamNodes).filter(id => !isChild.includes(id))

  const filterName = calculateFilterName(salesTeamNodes, rootNodes, filterSelected)

  const filterNodes = [
    {
      label: 'All Sales Teams',
      value: 'all',
      children: buildFilterNodeTree(salesTeamNodes, rootNodes, isManager)
    }
  ]
  return {
    filterNodes,
    filterName,
    filterSelected
  }
}

const processSalesPeopleStats = salesPersonStats => {
  let activeSalesPeopleStats = {}
  Object.keys(salesPersonStats)
    .filter(id => id !== '-1')
    .map(id => {
      const diff = moment().diff(moment(salesPersonStats[id].lastTimestamp), 'minutes')
      if (diff <= 15) {
        activeSalesPeopleStats[id] = salesPersonStats[id]
      }
    })
  return {
    activeSalesPeopleStats
  }
}

const calculateSalesPeopleStats = (salesInteractions, salesPeople) => {
  const salesPeopleStats = {}
  Object.keys(salesPeople).forEach(salesPersonId => {
    const {
      totalSales,
      totalHours,
      totalInteractions,
      lastTimestamp,
      lastLocation,
      salesPerDay,
      salesPerHour,
      premiumsPerDay
    } = processSalesInteractions(
      salesInteractions.filter(
        salesInteraction =>
          (salesInteraction.salesPerson && salesInteraction.salesPerson.id === salesPersonId) ||
          (!salesInteraction.salesPerson && salesPersonId == -1)
      )
    )
    salesPeopleStats[salesPersonId] = {
      totalSales,
      totalHours,
      totalInteractions,
      totalUnsuccessful: totalInteractions - totalSales,
      successRate: totalSales / totalHours,
      lastTimestamp,
      lastLocation,
      salesPerDay,
      salesPerHour,
      premiumsPerDay
    }
  })
  return salesPeopleStats
}

const objToArray = (obj, keyName) =>
  Object.keys(obj)
    .sort((a, b) => (a <= b ? -1 : 1))
    .map(key => ({ [keyName]: key, ...obj[key] }))

const combineDataToArray = (data, sub, subKeyName, ignore = ['-1']) => {
  const result = {}
  Object.keys(data).forEach(key => {
    if (!ignore.includes(key)) {
      Object.keys(data[key][sub]).forEach(key2 => {
        if (!result[key2]) {
          result[key2] = {}
        }
        result[key2][key] = data[key][sub][key2]
      })
    }
  })
  return objToArray(result, 'day')
}

const cumulativeData = (points, pointKey, valueKey, cumulativeKey, startAt = 0) => {
  let total = startAt || 0
  const result = []
  Object.keys(points)
    .sort((a, b) => (a <= b ? -1 : 1))
    .forEach(key => {
      total += points[key] || 0
      result.push({
        [valueKey]: points[key],
        [cumulativeKey]: total,
        [pointKey]: key
      })
    })
  return result
}

const getCenterLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: 30000,
        maximumAge: 600000
      })
    } else {
      resolve({ latitude: -34.033264, longitude: 23.049452 })
    }
  })
}

const SalesPersonItem = ({ data, metric, onItemPress, goal }) => (
  <div key={data.id} style={{ flexGrow: 1, flexDirection: 'row', display: 'flex' }}>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div>
        <ProfilePicture photo={data.photo} size={20} circle style={{ marginTop: 2, marginBottom: 2, marginRight: 7 }} />
        <a href="#" onClick={() => onItemPress && onItemPress(data)}><span style={{ fontSize: 16 }}>{`${data.first} ${data.last}`}</span></a>
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end'
      }}
    >
      <div style={{ paddingTop: 3, paddingRight: 3, fontSize: 16 }}>{metric.toFixed(2)}</div>
      {goal && <div><i className={`fa ${goal > metric ? "fa-exclamation-triange" : "fa-check-circle" }`} style={{ color: goal > metric ? "red" : "green" }} /></div>}
    </div>
  </div>
)

// fetchingLocation: true

const MapWithAMarkerClusterer = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyC9LiFI-yW4bqRKeW5PCULScZOIXCrklHQ&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `50vh` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={10}
    defaultCenter={{
      lat: props.centerMap ? props.centerMap.latitude : -34.033264,
      lng: props.centerMap ? props.centerMap.longitude : 23.049452
    }}
    center={{
      lat: props.centerMap ? props.centerMap.latitude : -34.033264,
      lng: props.centerMap ? props.centerMap.longitude : 23.049452
    }}
  >
    <div
      onClick={props.gotoCurrentLocation}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 25,
        height: 25,
        backgroundColor: 'white',
        position: 'absolute',
        top: 45,
        right: 21
      }}
    >
      <i className={'fa fa-location-arrow'} />
    </div>
    <MarkerClusterer
      averageCenter
      enableRetinaIcons
      gridSize={60}
      styles={[
        {
          url: require('../assets/images/markers/successful.png'),
          height: 65,
          width: 59,
          anchorText: [-7, -10],
          textColor: '#ffffff',
          textSize: 10,
          iconAnchor: [-32, -30]
        }
      ]}
    >
      {props.categoryPoints &&
        props.categoryPoints['SIGNEDUP'] &&
        props.categoryPoints['SIGNEDUP'].map(marker => {
          return (
            <Marker
              key={marker.id}
              position={{
                lat: parseFloat(marker.location.latitude),
                lng: parseFloat(marker.location.longitude)
              }}
              icon={require('../assets/images/markers/successful.png')}
            />
          )
        })}
    </MarkerClusterer>
    <MarkerClusterer
      averageCenter
      enableRetinaIcons
      gridSize={60}
      styles={[
        {
          url: require('../assets/images/markers/unsuccessful.png'),
          height: 65,
          width: 59,
          anchorText: [13, 6],
          textColor: '#ffffff',
          textSize: 10,
          iconAnchor: [-32, -30]
        }
      ]}
    >
      {props.categoryPoints &&
        [
          ...(props.categoryPoints['NOT_INTERESTED'] ? props.categoryPoints['NOT_INTERESTED'] : [null]),
          ...(props.categoryPoints['QUOTE_NOT_INTERESTED'] ? props.categoryPoints['QUOTE_NOT_INTERESTED'] : [null])
        ]
          .filter(marker => marker)
          .map(marker => {
            return (
              <Marker
                key={marker.id + '-2'}
                position={{
                  lat: parseFloat(marker.location.latitude),
                  lng: parseFloat(marker.location.longitude)
                }}
                icon={require('../assets/images/markers/unsuccessful.png')}
              />
            )
          })}
    </MarkerClusterer>
    <MarkerClusterer
      averageCenter
      enableRetinaIcons
      gridSize={60}
      styles={[
        {
          url: require('../assets/images/markers/salesperson.png'),
          height: 65,
          width: 59,
          anchorText: [-7, 16],
          textColor: '#ffffff',
          textSize: 10,
          iconAnchor: [-32, -30]
        }
      ]}
    >
      {Object.keys(props.salesPeopleStats)
        .filter(id => id !== '-1')
        .map(id => {
          const marker = props.salesPeopleStats[id]
          return (
            <OverlayViewMarker
              key={id + '-2'}
              position={{
                lat: parseFloat(marker.lastLocation.latitude),
                lng: parseFloat(marker.lastLocation.longitude)
              }}
            >
              <div>
                <ProfilePicture
                  photo={props.salesPeople[id].photo}
                  size={30}
                  circle
                  style={{ border: '1px solid #3e4254' }}
                />
              </div>
            </OverlayViewMarker>
          )
        })}
    </MarkerClusterer>
  </GoogleMap>
))

class Sales extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedSalesPeople: [],
      startDate: moment().subtract(1, 'month'),
      endDate: null,
      centerMap: null,
      showPlaybackModal: false,
      playbackTimestamp: null
    }
  }

  componentWillMount() {
    /*!this.centerMap &&
      getCenterLocation().then(location => {
        this.centerMap = location.coords;
        this.setState({ fetchingLocation: false });
      });*/
    this.props.data.subscribeToMore({
      document: subscribeToSalesInteractions,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev
        }
        const newSalesInteraction = subscriptionData.data.salesinteractionCreated
        // don't double add the message
        if (!prev.salesinteractions.find(d => d.id === newSalesInteraction.id)) {
          return Object.assign({}, prev, {
            salesinteractions: [...prev.salesinteractions, newSalesInteraction],
            salesteams: this.props.data.salesteams,
            targets: this.props.targets
          })
        } else {
          return prev
        }
      }
    })
  }

  componentDidMount() {
    this.handleGotoCurrentLocation()
  }

  handleGotoCurrentLocation = () => {
    getCenterLocation().then(location => {
      this.setState({ centerMap: location.coords })
    })
  }

  render() {
    if (this.props.data.error) {
      return <h1>An error occurred</h1>
    } else {
      const goals = targetsToGoals(this.props.data.targets)
      const { filterNodes, filterName, filterSelected } = processSalesTeams(
        this.props.data.salesteams,
        this.state.selectedSalesPeople,
        this.props.data.loading
      )
      const filteredSalesInteractions = this.props.data.loading
        ? []
        : this.props.data.salesinteractions.filter(
            d => {
              console.log('filter', d);
            return (
              (!this.state.startDate ||
                moment(d.timestamp).format('YYYYMMDD') >= moment(this.state.startDate).format('YYYYMMDD')) &&
              (!this.state.endDate ||
                moment(d.timestamp).format('YYYYMMDD') <= moment(this.state.endDate).format('YYYYMMDD')) &&
              (this.state.selectedSalesPeople.length === 0 || (d.salesPerson && this.state.selectedSalesPeople.includes(d.salesPerson.id)) )
            )}
          )
      const {
        categoryPoints,
        salesPeople,
        totalSales,
        totalHours,
        totalInteractions,
        totalPremiums,
        salesPerDay,
        premiumsPerDay
      } = processSalesInteractions(filteredSalesInteractions)
      const salesPeopleStats = calculateSalesPeopleStats(filteredSalesInteractions, salesPeople)
      const { activeSalesPeopleStats } = processSalesPeopleStats(salesPeopleStats)

      return (
        <div className="MapDisplay" style={{ backgroundColor: '#e8e8e8' }}>
          {
            this.state.showPlaybackModal && <PlaybackModal id={this.state.showPlaybackId} show title={this.state.playbackTimestamp} onClosePlaybackModal={() => this.setState({ showPlaybackModal: false })} />
          }
          <FilterBar>
             
      {/*<SelectTreeFilterItem
              name="salesPeople"
              value={filterName}
              icon="fa-id-badge"
              nodes={filterNodes}
              loading={this.props.data && this.props.data.loading}
              selected={this.state.selectedSalesPeople}
              updateSelected={selectedSalesPeople => {
                this.setState({ selectedSalesPeople })
              }}
            />
            <LocationFilterItem
              name="location"
              value="Knysna"
              icon="fa-location-arrow"
              loading={this.props.data.loading}
            />
        */}

            <DateFilterItem
              name="duration"
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onDatesChange={(startDate, endDate) => {
                let date1
                let date2
                if (startDate && !endDate) {
                  if (startDate < this.state.startDate) {
                    date1 = startDate
                    date2 = this.state.startDate
                  } else {
                    date1 = startDate
                    date2 = this.state.endDate || moment()
                  }
                } else {
                  date1 = startDate || this.state.startDate
                  date2 = endDate || this.state.endDate || moment()
                }
                this.setState({ startDate: date1 < date2 ? date1 : date2, endDate: date1 > date2 ? date1 : date2 })
              }}
              loading={this.props.data.loading}
              right
            />
          </FilterBar>
          <Card>
            <DashboardRow>
              <MetricCard
                label={'Sales People'}
                value={!this.props.data.loading && Object.keys(salesPeople).length - 1}
              />
              <MetricCard label={'Successful Sales'} value={totalSales} />
              <MetricCard
                label={'Total Premiums Sold'}
                value={!this.props.data.loading && Numeral(totalPremiums).format('$black##,###0')}
              />
              <MetricCard label={'Hours Worked'} value={!this.props.data.loading && totalHours.toFixed(2)} />
              <MetricCard
                label={'Premiums per Hour'}
                value={!this.props.data.loading && Numeral(totalPremiums / totalHours).format('$black##,##0.##')}
                warning={totalPremiums && totalHours && (totalPremiums / totalHours) < goals && goals['premiums'] ? goals['premiums']['HOURLY'] : 0}
              />
              <MetricCard
                label={'Interactions per Hour'}
                value={!this.props.data.loading  && (totalInteractions / totalHours).toFixed(2)}
              />
            </DashboardRow>
            <DashboardRow>
              <MetricCard
                label={'Active Sales People'}
                value={!this.props.data.loading && Object.keys(activeSalesPeopleStats).length}
              />
              <MetricCard
                label={'Success Rate'}
                value={!this.props.data.loading && (totalSales / totalHours).toFixed(2)}
                warning={totalSales && totalHours && (totalSales / totalHours) <  goals && goals['sales'] ? goals['sales']['HOURLY'] : 0}
              />
              <MetricCard
                label={'Unsuccessful Sales'}
                value={!this.props.data.loading && totalInteractions - totalSales}
              />
              <MetricCard
                label={'Interactions per Sale'}
                value={!this.props.data.loading && totalSales && (totalInteractions / totalSales).toFixed(2)}
              />
              <MetricCard
                label={'Premiums Per Interaction'}
                value={!this.props.data.loading && Numeral(totalPremiums / totalInteractions).format('$black##,##0.##')}
              />
              <MetricCard
                label={'Average Premium Per Sale'}
                value={!this.props.data.loading && Numeral(totalPremiums / totalSales).format('$black##,##0.##')}
              />
            </DashboardRow>
            <DashboardRow justify="center">
              <DashboardCard size={23} noPadding>
                <MapWithAMarkerClusterer
                  loading={this.props.data.loading}
                  categoryPoints={categoryPoints}
                  salesPeople={salesPeople}
                  salesPeopleStats={salesPeopleStats}
                  gotoCurrentLocation={this.handleGotoCurrentLocation}
                  centerMap={this.state.centerMap}
                />
              </DashboardCard>
            </DashboardRow>
            <DashboardRow>
              <DashboardGraph
                title={'Sales Person New Premiums Per Day'}
                size={6}
                data={!this.props.data.loading && combineDataToArray(salesPeopleStats, 'salesPerDay', 'day')}
                x={'day'}
                y={Object.keys(salesPeople).filter(id => id !== '-1')}
                label={y => `${salesPeople[y].first} ${salesPeople[y].last}`}
                goalLine={!this.props.data.loading && goals && goals['sales'] && goals['sales']['DAILY']}
              />
              <DashboardGraph
                title={'Daily Sales'}
                size={6}
                data={!this.props.data.loading && cumulativeData(salesPerDay, 'day', 'Daily', 'Cumulative')}
                x={'day'}
                y={['Daily', 'Cumulative']}
                goalLine={!this.props.data.loading && goals && goals['sales'] && goals['sales']['DAILY'] * Object.keys(salesPeople).length}
              />
              <DashboardGraph
                title={'Sales Person Sales Value Per Day'}
                size={6}
                data={!this.props.data.loading && combineDataToArray(salesPeopleStats, 'premiumsPerDay', 'day')}
                x={'day'}
                y={Object.keys(salesPeople).filter(id => id !== '-1')}
                label={y => `${salesPeople[y].first} ${salesPeople[y].last}`}
                goalLine={!this.props.data.loading && goals && goals['premiums'] && goals['premiums']['DAILY']}
              />
              <DashboardGraph
                title={'Daily Sales Value'}
                size={6}
                data={!this.props.data.loading && cumulativeData(premiumsPerDay, 'day', 'Daily', 'Cumulative')}
                x={'day'}
                y={['Daily', 'Cumulative']}
                goalLine={!this.props.data.loading && goals && goals['premiums'] && (goals['premiums']['DAILY'] * Object.keys(salesPeople).length)}
              />
            </DashboardRow>
            <DashboardRow>
              <TopNCard
                n={10}
                data={
                  !this.props.data.loading &&
                  Object.keys(salesPeopleStats)
                    .filter(id => id !== '-1')
                    .map(id => ({ ...salesPeopleStats[id], ...salesPeople[id] }))
                }
                metric="successRate"
                goal={!this.props.data.loading && goals && goals['sales'] && goals['sales']['HOURLY']}
                title="Top 10 Sales People"
                renderItem={SalesPersonItem}
                onItemPress={data => this.setState({ selectedSalesPeople: [data.id] })}
                renderNoItems={() => <span>None</span>}
              />
              <SalesInteractionFeed
                salesInteractions={!this.props.data.loading && filteredSalesInteractions}
                limit={15}
                onSalesPersonPress={id => this.setState({ selectedSalesPeople: [id] })}
                onLocationPress={customLocation =>
                  this.setState({
                    centerMap: { latitude: customLocation.latitude, longitude: customLocation.longitude }
                  })
                }
                onPlaybackPress={(salesInteraction, timestamp) =>  this.setState({ playbackTimestamp: timestamp, showPlaybackModal: !this.state.showPlaybackModal, showPlaybackId: salesInteraction.id })}
              />
            </DashboardRow>
          </Card>
          <div style={{ height: '100px' }} />
        </div>
      )
    }
  }
}

Sales = graphql(getSalesInteractions, { options: { fetchPolicy: 'cache-first' } })(Sales)
export default Sales
