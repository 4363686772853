import { css } from '@emotion/core'

function getPaddingFromProps({ pad, padHorizontal, padVertical, padLeft, padRight, padTop, padBottom }) {
  const cssText = []

  if (pad) {
    cssText.push(`padding: ${pad};`)
  }

  if (padHorizontal) {
    cssText.push(`padding-left: ${padHorizontal};`)
    cssText.push(`padding-right: ${padHorizontal};`)
  }

  if (padVertical) {
    cssText.push(`padding-top: ${padVertical};`)
    cssText.push(`padding-bottom: ${padVertical};`)
  }

  if (padLeft) {
    cssText.push(`padding-left: ${padLeft};`)
  }

  if (padRight) {
    cssText.push(`padding-right: ${padRight};`)
  }

  if (padTop) {
    cssText.push(`padding-top: ${padTop};`)
  }

  if (padBottom) {
    cssText.push(`padding-bottom: ${padBottom};`)
  }

  return css`${cssText.join('\n')}`
}

export {
  getPaddingFromProps
}