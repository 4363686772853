import React from 'react'
import renderInput from './renderInput'

const renderDisplay = (type) => ({ input, value, ...props }) => {
  const Input = renderInput(type)
  return (
    <Input
      {...props}
      input={input}
      value={value ? value : input ? input.value : ''}
      disabled
    />
  )
}

export default renderDisplay