import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import WaveSurfer from 'wavesurfer.js'

const propTypes = {
  src: PropTypes.string.isRequired
}

export default class Waveform extends Component {
  componentDidMount() {
    this.$el = ReactDOM.findDOMNode(this)
    this.$waveform = this.$el.querySelector('.wave')
    this.wavesurfer = WaveSurfer.create({
      container: this.$waveform,
      waveColor: '#5D86F2',
      progressColor: 'purple',
      interact: false,
      cursorWidth: 0,
      normalize: true
    })
    this.wavesurfer.load(this.props.src)
  }
  componentWillUnmount() {
    // TODO: Release resources?
  }
  render() {
    return (
      <div className="waveform">
        <div className="wave" />
      </div>
    )
  }
}

Waveform.propTypes = propTypes
