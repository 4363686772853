import React from 'react';
import { MaterialTable, SelectColumnFilter } from '../generic'
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
import { withRouter } from 'react-router-dom'
import ClearanceCertificateInfo from '../clearance-certificate/ClearanceCertificateInfo';

// switch between locales
Numeral.locale('en-za')

let CreditCertificate = ({ clearanceCertificates, history }) => {

  const columns = [{
    Header: 'Certificate Number',
    accessor: 'certificateNumber',
    filter: 'fuzzyText'
  },
  // {
  //   Header: 'Payment Type',
  //   id: 'paymentType',
  //   accessor: d => (d.paymentType),
  //   Filter: SelectColumnFilter,
  //   filter: 'Select'
  // },
  {
    Header: 'Amount',
    id: 'amount',
    accessor: d => Numeral(d.coverAmount).format('$ ##,###0'),
    disableFilters: true
  },
  {
    Header: 'Status',
    id: 'state',
    accessor: d => (d.policyStatus),
    Filter: SelectColumnFilter,
    filter: 'Select'
  }
  ]

  return (
    <MaterialTable columns={columns} data={clearanceCertificates} onRowPress={(row) => { console.log('credit life pressed row', row); history.push(`/clearance-certificate/${row.id}/edit`) }} />
  )
}
CreditCertificate = withRouter(CreditCertificate)
export default CreditCertificate