import React, { useState } from 'react'
import FileInput from '../FileInput'
import FileUploadCrop from '../FileUploadCrop'
import axios from 'axios'
import UploadedFile from '../UploadedFile.js'
import { API_URL } from '../../../config'
import { getAccessToken } from '../../../services/auth'

function FileUpload(props) {
  const { onChange, onDelete, prompt, value, docId, renderFileInput, inverted, disabled, accept, capture, image, smallIcon, setVerify, verified } = props
  const [progress, setProgress] = useState(0)
  const [uploading, setUploading] = useState(false)

  const accessToken = localStorage.getItem('accessToken') || getAccessToken()

  const uploadDocumentRequest = (e) => {

    if (e.target && e.target.files[0]) {
      let data = new FormData();
      const { name } = e.target.files[0]
      data.append('name', name);
      data.append('file', e.target.files[0])

      const config = {
        method: "POST",
        headers: {
          'Authorization': `JWT ${accessToken}`
        },
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setProgress(percentCompleted)
        }
      }

      setUploading(true)
      axios.put(`${API_URL}upload`,data, config)
        .then(result => {
          setUploading(false)
          props.onChange && props.onChange(result.data[0].id)
        })
        .catch(console.error)
    }
  }
  if (value) {
    return <UploadedFile {...props} small={smallIcon} docId={docId} id={value} prompt={prompt} onChange={onDelete} verified={verified} setVerify={setVerify} disabled={disabled} />
  }

  if (renderFileInput) {
    const MyFileInput = renderFileInput
    return <MyFileInput onChange={uploadDocumentRequest} progress={progress} prompt={prompt} accept={accept} />
  } else {

    return (<>
      <div>
        {false && !uploading && <FileInput onChange={uploadDocumentRequest} prompt={prompt} inverted={inverted} disabled={disabled} accept={accept} capture={capture} image={image} smallIcon={smallIcon} />}
        <FileUploadCrop {...props} id={docId} />
        {prompt}
      </div>
    </>)
  }
}

export default FileUpload
