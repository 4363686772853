import React from 'react'
import DebtCounsellorsList from './DebtCounsellorsList'
import DebtCounsellor from './DebtCounsellor'
import LicenseFees from './DCLicenseFees'
import NewLicenseFee from './DCNewFee'

import { Route, Switch } from 'react-router-dom'

const DebtCounsellors = () => (
  <div>
    <Switch>
      <Route path='/debt-counsellors' exact component={DebtCounsellorsList} />
      <Route path='/debt-counsellors/:id/fees/new' render={({match}) => <NewLicenseFee id={match.params.id} />} />
      <Route path='/debt-counsellors/:id/fees' render={({match}) => <LicenseFees id={match.params.id} />} />
      <Route path='/debt-counsellors/new' exact render={({ history }) => <DebtCounsellor history={history} mode={'add'} />} />
      <Route path='/debt-counsellors/:id/:edit?' render={({ match, history }) => <div><DebtCounsellor history={history} mode={match.params.edit ? 'edit' : 'display'} id={match.params.id} /></div>} />
    </Switch>
  </div>
)

export default DebtCounsellors
