import React, { useState } from 'react'
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core/styles';
import { ActivityIndicator, Button, Card, Grid, TextField, Select } from '../generic';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    padding: 21,
  }
}));

const GET_CLIENT = gql`
  query GetClientDetails( $filter: ClientFilter!) {
    clients(filter: $filter) {
      id
      acceptedTerms
  }
}
`

let RoleHolderPrompt = ({ history, client, match }) => {
  const classes = useStyles();
  let { groupId } = match && match.params || {}
  const [idNumber, setIdNumber] = useState()
  const [role, setRole] = useState("")
  const [spin, setSpin] = useState(false)

  const getClient = () => {
    setSpin(true)
    if (idNumber) {
      client.query({
        query: GET_CLIENT,
        variables: {
          filter: { idNumber }
        }
      })
        .then((result) => {
          setSpin(false)
          const { id } = result.data.clients && result.data.clients[result.data.clients.length - 1] || {}
          const clientId = id
          if (clientId) {
            history.push(`/groups/${groupId}/${role}/client/${clientId}/add`)
          } else {
            history.push(`/groups/${groupId}/${role}/new/${idNumber}/add`)
          }
        })
    }
  }

  return (
    <Card className={classes.root} >
      <h1>Add New Role Holder</h1>

      <br />
      <Grid container className={classes.root}>
        <Grid item xs={2}>
          <TextField label="Enter Id Number" key='idNumber' value={idNumber} onChange={(e) => setIdNumber(e.target.value)} />
        </Grid>
        <Grid item xs={2}>
          <Select
            options={[
              { value: "CHAIRPERSON", label: "CHAIRPERSON" },
              { value: "TREASURER", label: "TREASURER" },
              { value: "SECRETARY", label: "SECRETARY" }
            ]}
            label="Select new role holder's role"
            onChange={(v) => setRole(v.target.value)}
            placeholder="Role"
            value={role}
            />
        </Grid>
        <Grid item xs={1}>
          <Button color="primary" onClick={getClient} > Continue </Button>
        </Grid>
        <Grid item xs={1}>
          <Button color='secondary' onClick={() => history.push(`/groups`)}>Cancel</Button>
        </Grid>
      </Grid>
      {spin && <ActivityIndicator />}
    </Card >
  );
}

RoleHolderPrompt = withRouter(RoleHolderPrompt);
export default RoleHolderPrompt = withApollo(RoleHolderPrompt);



  