import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withApollo } from 'react-apollo'
import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import moment from 'moment'
import { Card, Modal, Dropdown, Alert, Menu, Button } from '../generic'
import GraphQLTable from '../generic/GraphQLTable'

const printTargetDescription = row => {
  const frequency = row.frequency
    ? row.frequency.charAt(0) + row.frequency.substring(1, row.frequency.length).toLowerCase()
    : ''
  const metric = row.metric ? row.metric : ''
  const level = row.level ? `for ${row.level.replace('_', ' ').toLowerCase()}` : ''
  return `${frequency} ${metric} ${level}`
}

const printCell = cellInfo => {
  // Add custom editable component here
  return cellInfo.value
}

const config = {
  query: gql`
    {
      targets {
        id
        forFilter {
          key
          value
        }
        metric
        frequency
        level
        value
        updatedAt
      }
    }
  `,
  dataset: data => data.targets,
  fields: {
    'forFilter.key': { label: 'Filter Key' },
    'forFilter.value': { label: 'Filter Value' },
    targetDescription: {
      label: 'Target Description',
      accessor: row => printTargetDescription(row)
    },
    value: {
      label: 'Value',
      Cell: cellInfo => printCell(cellInfo)
    },
    updatedAt: { label: 'Updated At', Cell: row => moment(row.value).format('Do MMM YYYY, H:mm') }
  }
}

const updateMutation = gql`
  mutation($input: UpdateTargetInput!, $id: String!) {
    updateTarget(id: $id, input: $input) {
      id
      value
      updatedAt
    }
  }
`

const createMutation = gql`
  mutation($input: CreateTargetInput!) {
    createTarget(input: $input) {
      id
      metric
      frequency
      level
      value
      updatedAt
    }
  }
`

const renderUpdateModal = (value, onChange, onToggle, onSave) => (
  <div className="static-modal">
    <Modal   onHide={onToggle}>
        <h1>Update Target Value</h1>
        <input type="text" value={value} onChange={onChange} />
        <Button onClick={onToggle}>Cancel</Button>
      <Button color="primary" onClick={onSave}>
          Update
        </Button>
    </Modal>
  </div>
)

const renderCreateModal = (createNewTargetParams, onUpdateParams, onToggle, onCreate, value, onChange) => (
  <div className="static-modal">
    <Modal show bsSize="large" onHide={onToggle}>
      <h1>Add New Target</h1>
        <div style={{ flexDirection: 'row' }}>
          {Object.keys(PARAMS).map((param, i) => {
            if (PARAMS[param].length > 0) {
              return renderDropdown(param, i, onUpdateParams, createNewTargetParams[param])
            } else {
              return <input key={i} placeholder="Value" type="text" value={value} onChange={onChange} />
            }
          })}
        </div>
        <Button onClick={onToggle}>Cancel</Button>
      <Button color="primary" onClick={onCreate}>
          Add
        </Button>
    </Modal>
  </div>
)

const renderDropdown = (item, i, onUpdateParams, createNewTargetParams) => {
  console.log('createNewTargetParams=', createNewTargetParams)
  return (
    <Menu menuItems={PARAMS[item].map((item2, index) => ({
      onClick: (d) => onUpdateParams(item, d),
      key: index,
      value: item2
    }))}
    />)
} 

const renderErrorAlert = err => (
  <Alert message={`Error updating value!<p>${err}</p>`} severity='warning' />
)

const PARAMS = {
  metric: ['sales', 'premiums'],
  frequency: ['HOURLY', 'DAILY', 'MONTHLY'],
  level: ['sales person'],
  value: []
}

const propTypes = {
  client: PropTypes.instanceOf(ApolloClient).isRequired
}

class TargetList extends Component {
  constructor() {
    super()
    this.state = {
      showEditModal: false,
      showCreateModal: false,
      updateValue: 0,
      updateId: null,
      createNewTargetParams: {
        metric: 'sales',
        frequency: 'DAILY',
        level: 'sales person',
        value: 10
      }
    }
  }
  handleToggleEditModal = data => {
    if (data) {
      this.setState({ showEditModal: !this.state.showEditModal, updateId: data.id, updateValue: data.value })
    } else {
      this.setState({ showEditModal: !this.state.showEditModal })
    }
  }
  handleToggleCreateModal = () => {
    this.setState({ showCreateModal: !this.state.showCreateModal })
  }
  handleChange = e => {
    this.setState({ updateValue: e.target.value })

  }
  handleSave = () => {
    this.props.client
      .mutate({
        mutation: updateMutation,
        variables: {
          input: {
            value: this.state.updateValue
          },
          id: this.state.updateId
        }
      })
      .then(res => {
        console.log('result=', res)
      })
      .catch(err => {
        // TODO: Improve error handling
        console.log('error=', err)
      })
    this.handleToggleEditModal()
  }
  handleUpdateParams = (key, value) => {
    const currentNewTargetParams = Object.assign({}, this.state.createNewTargetParams)
    currentNewTargetParams[key] = value
    this.setState({ createNewTargetParams: currentNewTargetParams }, () =>
      console.log('createNewTargetParams=', this.state.createNewTargetParams)
    )
  }
  handleUpdateCreateValue = e => {
    const d = Object.assign({}, this.state.createNewTargetParams)
    d.value = e.target.value
    this.setState({ createNewTargetParams: d })
  }
  handleCreate = () => {
    this.props.client
      .mutate({
        mutation: createMutation,
        variables: {
          input: {
            value: this.state.createNewTargetParams.value,
            metric: this.state.createNewTargetParams.metric,
            frequency: this.state.createNewTargetParams.frequency,
            level: this.state.createNewTargetParams.level
          }
        }
      })
      .then(res => {
        console.log('result=', res)
      })
      .catch(err => {
        // TODO: Improve error handling
        console.log('error=', err)
      })
    this.handleToggleCreateModal()
  }
  render() {
    return (
      <div className="content">
        {this.state.showCreateModal &&
          renderCreateModal(
            this.state.createNewTargetParams,
            this.handleUpdateParams,
            this.handleToggleCreateModal,
            this.handleCreate,
            this.state.createNewTargetParams.value,
            this.handleUpdateCreateValue
          )}
        {this.state.showEditModal &&
          renderUpdateModal(this.state.updateValue, this.handleChange, this.handleToggleEditModal, this.handleSave)}
        <div className="col-md-12">
          <h1 style={{ marginTop:'7px', marginLeft:'10px' }}>Funeral Sales Targets</h1>
          <br />
          <Card
            contentClass="table-responsive"
            content={
              <Fragment>
                <GraphQLTable config={config} onRowPress={data => this.handleToggleEditModal(data)} />
                <Button onClick={() => this.setState({ showCreateModal: !this.state.showCreateModal })}>
                  Add New Target
                </Button>
              </Fragment>
            }
          />
        </div>
      </div>
    )
  }
}

export default withApollo(TargetList)
