import React from 'react'
import { ImageGridSelectItemContainer } from './ImageGridSelectItem.style'
import Text from '../../generic/Text'

function ImageGridSelectItem({ label, image, selected, onClick } ) {
  return <ImageGridSelectItemContainer selected={selected} onClick={onClick}>
    <img src={image} />
    <Text size={14}>{label}</Text>
  </ImageGridSelectItemContainer>
}

export default ImageGridSelectItem
