import React from 'react';
import gql from 'graphql-tag';
import GraphQLTable from '../generic/GraphQLTable';
import { blankProfile } from '../../assets/images';
import { prettyMoment, DATE_TIME_FORMAT } from '../../Helpers';
import { Card, Button } from '../generic'

const config = {
  query: gql`
    {
      employees(limit: 1000) {
        id
        employeeNumber
        first
        last
        mobileNumber
        email
        manager {
          id
          first
          last
        }
        photo {
          id
          url
        }
        salesAgentInfo {
          agentType
          enabled
          isSuperAgent
        }
        users {
          email
          roles
          authProvider
          lastActivity {
            lastSeen
            client
          }
        }
      }
    }
  `,
  dataset: data => data.employees,
  fields: {
    photo: {
      label: 'Profile Picture',
      noHeading: true,
      Cell: row => {
        if (row.value && row.value.url) {
          return (
            <img
              className="img-circle"
              style={{ width: 30, height: 30 }}
              src={row.value.url}
            />
          );
        } else
          return (
            <img
              className="img-circle"
              style={{ width: 30, height: 30 }}
              src={blankProfile}
            />
          );
      },
      width: 15
    },
    name: {
      label: 'Name',
      Cell: row => {
        if (row.value != null) {
          return row.value;
        } else return "Hasn't logged in yet";
      },
      accessor: row => row.last ? `${row.last}, ${row.first}` : null,
      width: 50,
    },
    email: { label: 'email', width: 75 },
    mobileNumber: { label: 'Mobile', width: 75 },
    "manager.id": { label: 'Manager', width: 150, accessor: row => row.salesAgentInfo && row.salesAgentInfo.agentType ? row.manager ? `${row.manager.first}  ${row.manager.last}` : " " : " " },
    "users[0].email": { label: 'Associated User', width: 150, Cell: row => row.value ? row.value : "NONE"},
    // "users[0].roles": { label: 'User Roles', width: 150, Cell: row => row.value && row.value.length > 0 ? row.value.map((r, index) => index < row.value.length - 1 ? `${r}, ` : `${r}`) : null },
    "users[0].authProvider": { label: 'Authority', width: 50 }, 
    "salesAgentInfo.isSuperAgent": { label: 'Super' },
    "salesAgentInfo.enabled": { label: 'Enabled' },
    "users[0].lastActivity": {
      label: 'Last Seen',
      Cell: row => row.value ? (row.value.lastSeen && row.value.client ? `${prettyMoment(row.value.lastSeen, DATE_TIME_FORMAT)} on ${row.value.client}` : row.value.lastSeen ? prettyMoment(row.value.lastSeen, DATE_TIME_FORMAT, '-') : '-') : '-'
    }
  },
  defaultSorted: [{ id: 'name' }]
};

const EmployeeList = ({ history }) => (
  <Card>
    <GraphQLTable config={config} onRowPress={employee => history.push(`/employees/${employee.id}`)} />
  </Card>
);

export default EmployeeList;
