import Fuse from 'fuse.js';

const HAZARDOUS =
  [{ name: "chair lift operator" },
  { name: "crane operator" },
  { name: "foreman" },
  { name: "med board pension" },
  { name: "miner" },
  { name: "mining operator" },
  { name: "operator" },
  { name: "owner" },
  { name: "pensioner" },
  { name: "private pensioner" },
  { name: "rock drill operator" },
  { name: "safety officer" },
  { name: "self employed" },
  { name: "electrician" },
  { name: "minesupervisor" }
  ]

const COMMON = ['office', 'company', 'bank', 'limited', 'services', 'division', 'pty', 'ltd', 'sa', 'south', 'africa', 'african']

const removeCommonNames = (string) => {
  if (string) {
    COMMON.forEach(n => {
      const regex = new RegExp(`\\b${n}\\b`,'gi');
      string = string.replace(regex, '')
    })
  }
  return string
}

const OPTS = (threshold = 0.3) => ({
  minMatchCharLength: 4,
  threshold,
  includeScore: true,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  includeMatches: true,
    keys: ['name']
  });

// TODO: Perhaps call this formatters?
const searchList = (companys) => {
  let basic = []
  companys && companys.length > 0 && companys.filter(c => c.basicListed).forEach(c => basic.push({ name: removeCommonNames(c.name).substring(0, 64) }))
  return basic
}

const onHazardList = (occupation, threshold) => {

  if (!occupation || occupation.length < 3) {
    return false
  }

  // Fuzzy logic search
  var fuse = new Fuse(HAZARDOUS, OPTS(threshold))
  var res = fuse.search(occupation.substring(0, 32))

  if (res.length > 0) {
    return true
  }

  return false
}

const onBasicList = (basicList, companyName, occupation, threshold) => {
  if (!companyName || !companys) {
    return false
  }
  // Fuzzy logic search
  var fuse = new Fuse(basicList, OPTS(threshold));
  var res = fuse.search(removeCommonNames(companyName));

  console.log('fuse.. res is ', removeCommonNames(companyName), threshold, res);
  if (res.length > 0) return true;

  // check case of self employed
  if (companyName.indexOf('self') > -1) return true;

  if (occupation && occupation !== ' ' && onHazardList(occupation, threshold)) {
    return true
  }

  return false;
}

export {
  searchList,
  onBasicList
}
