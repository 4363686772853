import React, { Component, Fragment, useState } from 'react'
import gql from 'graphql-tag'
import { GraphQLFormProvider } from 'react-form-helper'
import { renderInput, renderDisplay, Card, BuildLayout, Select, Button, Modal, Grid, Radio, Switch } from '../generic'
import { withRouter } from 'react-router'
import { getAllGroups } from './GroupsList'
import TextField from '@material-ui/core/TextField'

const config = {
  name: 'group',
  fields: {
    name: {
      type: 'name',
      label: 'Group Name',
      validate: (value) => {
        if (!value) {
          return 'Name required'
        }
      }
    },
    postalCode: {
      type: 'text',
      label: 'Postal Code',
      validate: (value) => {
        if (!value) {
          return 'Postal Code required'
        }
      }
    }
    // roleHolders: {
    //   type: 'text',
    //   label: 'Consultant',
    //  },
  },

  query: gql`
    query GroupData($id: String!) {
      group(id: $id) {
        id
        name
        postalCode
        roleHolders {
          role
          idDocumentId
        }
        # products
      }
    }
  `,
  updateMutation: gql`
    mutation UpdateGroup($input: UpdateGroupInput!, $id: String!) {
      updateGroup(id: $id, input: $input) {
        id
        name
        postalCode
        roleHolders {
          role
          idDocumentId
        }
        # products
      }
    }
  `,
  addMutation: gql`
    mutation CreateGroup($input: CreateGroupInput!) {
      createGroup(input: $input) {
        id
        name
        postalCode
        # roleHolders
      }
    }
  `
}

let GroupFormLayout = (props) => {
  console.log('GroupFormLayout', props)
  const { mode, history, formName } = props
  // const [open, setOpen] = useState(false)
  // const [idNumber, setIdNumber] = useState()
  // const [isPreExisting, setIsPreExisting] = useState(true)
  // const [role, setRole] = useState('')
  // const [openProd, setOpenProd] = useState(false)
  // const [totalClaims, setTotalClaims] = useState('R')
  // const [totalPremiums, setTotalPremiums] = useState('R')

//   const onSubmit = () => {
//     console.log('Roleholder: ', role, idNumber)
//     setOpen(false)
//   }

//   const onSubmitProd = () => {
//     console.log('Total premiums: ', totalPremiums)
//     console.log('Total Claims: ', totalClaims)
//     console.log('Pre-existing? ', isPreExisting)
//     setOpenProd(false)
//   }

  return (
    <Card>
      <BuildLayout
        fieldNames={Object.keys(config.fields)}
        mode={mode}
        fieldConfig={config.fields}
        direction="column"
        spacing={2}
        cancel={() => history.push('/groups')}
      />
    </Card>
  )
}

GroupFormLayout = withRouter(GroupFormLayout)

const NewGroup = (props) => {
  const { mode, id, history, open, setOpen } = props

  return (
    <div>
      <Modal
        title="Add New Group"
        open={open}
        // onClose={() => setOpen(false)}
        // onOk={() => onSubmit('role')}
        // onCancel={() => setOpen(false)}
        fullWidth={true}
        maxWidth="md"
        okText="Continue"
        cancelText="Cancel"
      >
        <GraphQLFormProvider
          mode={'add'}
          id={id}
          {...config}
          afterSubmit={(data) => {
            console.log(">>>>>DATA: ", data)
            history.push(`/groups/${data.id}`)
          }}
          renderDisplay={renderDisplay}
          renderInput={renderInput}
          InputFormLayout={(props) => <GroupFormLayout {...props} formName={config.name} />}
          DisplayFormLayout={GroupFormLayout}
          refetchQueries={[{ query: getAllGroups }]}
        />
      </Modal>
    </div>
  )
}

export default NewGroup
