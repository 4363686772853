import React from 'react'
import TextField from '@material-ui/core/TextField'
import { Grid, MobileField, EasyPayField } from '../index'
import { withStyles } from '@material-ui/core/styles';

const DarkerDisabledTextField = withStyles({
  root: {
    minWidth: 120,
    marginRight: 10,
    paddingTop: 3,
    marginTop: '5px!important',
    marginBottom: '5px!important',
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.99)" // (default alpha is 0.38)
    },
    "& .MuiInputLabel-root": {
      fontSize: 20 // (default is 16)
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.75)!important"
    },
  }
})(TextField);

function DisplayItem(props) {
  const { margin = 'dense', label, key, xs, type } = props

  if (type === 'mobile') { // disable for now
    return <MobileField disabled {...props} />
  }
  else if (type === 'easypay') {
    return <EasyPayField disabled {...props} />
  }
  else return (
    <Grid item xs={xs} >
      <DarkerDisabledTextField
        disabled {...props}
        margin={margin} fullWidth placeholder={label || key}
      />
    </Grid >)
}

export default DisplayItem
