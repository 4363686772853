import React, { useState } from 'react'
import gql from 'graphql-tag'
import { makeStyles } from '@material-ui/core/styles';
import { useConfirm } from "material-ui-confirm";
import { GraphQLFormProvider, FormField, FieldValue } from 'react-form-helper'
import { withRouter } from 'react-router-dom'
import { Card, Grid, Button, TextInput, Switch, renderInput, renderDisplay } from '../../generic'
import SubmitFormButton from 'react-form-helper/dist/SubmitFormButton';
import PRODUCT_TYPES from '../../../variables/productTypes'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // justifyContent: 'space-evenly',
    paddingBottom: 7
  },
  footer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingBottom: 21
  },
  paper: {
    minWidth: '80%',
    maxWidth: '80%',
  },
  button: {
    minWidth: 100,
    maxWidth: 100,
    textAlign: 'center',
    marginRight: 14
  },
  end: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingTop: 7,
    paddingBottom: 7
  },
}));


const fields = {
  name: {
    type: 'text',
    label: 'Unique Name*',
    filter: 'fuzzyText',
    validate: (value) => {
      if (!value) {
        return 'name is Required'
      }
      else if (value.indexOf(' ') > -1) {
        return 'No spaces allowed in the name'
      }
    },
  },
  template: {
    type: 'text',
    label: 'Message Text (define vairable as ${name})',
    multiline: true,
    validate: (value) => {
      if (!value) {
        return 'Text is Required'
      }
      else {
        if (value.length > 459) {
          return 'Text should be less than 460 characters'
        }
      }
    }
  },
  product: {
    type: "select",
    options: PRODUCT_TYPES,
    label: "Products"
  },
  isAdmin: {
    label: "Not a marketing message",
    type: 'switch'
  },
  hasFreeReply: {
    label: "Replies are free",
    type: 'switch'
  }
}

const config = {
  name: 'htmlTemplate',
  query: gql`
    query TemplateData($id: String!) {
      communicationtemplate(id: $id){
        id
        name
        friendlyName
        description
        from
        subject
        type
        product
        templateType
        template
        headerId
        footerId
        isReadOnly
        hasFreeReply
        isAdmin
      }
    }`,
  updateMutation: gql`
    mutation UpdateCommunicationTemplate($id: String!, $input: UpdateCommunicationTemplateInput!) {
      updateCommunicationTemplate(id: $id, input: $input){
        id
        name
        friendlyName
        description
        from
        subject
        type
        product
        templateType
        template
        headerId
        footerId
        isReadOnly
        hasFreeReply
        isAdmin
    }
  }`,
  addMutation: gql`
  mutation CreateCommunicationTemplate($input: CreateCommunicationTemplateInput!){
    createCommunicationTemplate(input: $input){
        id
        name
        friendlyName
        description
        from
        subject
        type
        product
        templateType
        template
        headerId
        footerId
        isReadOnly
        hasFreeReply
        isAdmin
    }
  }
  `
}

function FormLayout(props) {
  const classes = useStyles()
  const confirm = useConfirm()
  const { history, mode, changeFieldValue, data } = props
  const { name, description } = data && data.communicationtemplate || {}

  return (<div style={{ width: '80%' }}>
    <Card>
      <h2>SMS Communications Template</h2>
      <br />

      <Grid container justifyContent="flex-start" alignItems='center' spacing={2}>
        <Grid item xs={4}>
          <FormField noLabel fullWidth name='name' />
        </Grid>

        <Grid item xs={4}>
          <FormField noLabel fullWidth name='product' />
        </Grid>
      </Grid>
      <br />
      <br />

      <Grid container justifyContent="flex-start" alignItems='center' spacing={1}>

        <Grid item xs={11}>
          <FormField noLabel fullWidth name='template' />
        </Grid>
        <Grid item xs={1}>
          <FieldValue name='template'
            renderField={({ input, value }) => {
              const smsText = input ? input.value : value
              return (<div>({smsText.length})</div>)
            }} />
        </Grid>

      </Grid>
      <br />

      <Grid container justifyContent="flex-start" alignItems='center' spacing={1}>
        <Grid item xs={6}>
          <FormField noLabel name='isAdmin' />
        </Grid>
        <Grid item xs={6}>
          <FormField noLabel name='hasFreeReply' />
        </Grid>
      </Grid>

      <br /><br />

      <Grid container justifyContent="flex-start" alignItems='center' spacing={2}>
        <SubmitFormButton renderSubmitButton={({ invalid, submit, submitting }) => (
          <Grid container className={classes.footer}>
            <Grid item className={classes.button}>
              <Button fullWidth variant='contained' color='default' onClick={() => history.push("/communication-templates")}>
                Cancel
              </Button>
            </Grid>
            <Grid item className={classes.button}>
              <Button fullWidth variant='contained' color='primary' disabled={invalid || submitting} onClick={() => submit()}>
                Save
              </Button>
            </Grid>
          </Grid>)} />
      </Grid>

      <br />

    </Card>
  </div>);
}
FormLayout = withRouter(FormLayout)

let SmsTemplate = ({ id, cancel, history, data }) => {
  if (id) {
    fields['name'].disabled = true
  }

  return <GraphQLFormProvider
    name={id ? id : "htmlTemplate"}
    mode={id ? "edit" : "add"}
    initialValues={{ templateType: "STRING", type: "SMS", }}
    initialValuesFromData={data => { return data.communicationtemplate }}
    id={id}
    {...config}
    fields={fields}
    renderDisplay={renderDisplay}
    renderInput={renderInput}
    InputFormLayout={(props) => <FormLayout cancel={cancel} {...props} />}
    DisplayFormLayout={FormLayout}
    mapInputVariables={async (input) => {
      return { input }
    }}
    afterSubmit={() => history.push("/communication-templates")}
  />

}

export default withRouter(SmsTemplate)
