import React, { useState } from 'react';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import { Button, Card, GraphQLTable, TextInput, Modal, VerifyDoc } from '../../generic';

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingRight: 0,
    paddingBottom: 21
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CREATE_COMMUNICATION = gql`
	mutation CreateCommunication($input: CreateCommunicationInput!) {
		createCommunication(input: $input) {
			id
		}
	}
`
const MARK_REPLIED = gql`
	mutation MarkReplied($id:  String!, $input: UpdateCommunicationInput!) {
		updateCommunication(id: $id, input: $input) {
        id
        statusReason
      }
    }
`;

const toHHMMSS = (value) => {
  var sec_num = parseInt(value, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes + ':' + seconds;
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-Select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

const returnedInfo = (info) => {
  return JSON.parse(info)
}

const sendEvents = {
  "processed": "Preparing for delivery",
  "dropped": "Error: Couldn't deliver message",
  "deferred": "Still trying",
  "bounce": "Error: Undeliverable, check address",
  "delivered": "Delivered to remote server",
  "open": "Mail was opened",
  "click": "Link was clicked",
  "spam": "Error: Mail was flagged as SPAM",
  "unsubscribe": "Error: Recipient unsubscribed",
  "pending": "PENDING",
  "blist": "Error: Number is black listed",
  "delivrd": "Message delivered",
  "expired": "Error: Delivery timed-out",
  "undeliv": "Error: Undeliverable, check number",
  "read": "Read"
}

const trimStringOnSpace = (string, length) => {
  //trim the string to the maximum length
  let yourString = string + " "
  var trimmedString = yourString.substr(0, length);

  //re-trim if we are in the middle of a word
  return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
}

const config = {
  query: gql`{
    # clientdocuments (filter:{verifiedById:null },limit:1000) {
    clientdocuments (limit:1000) {
        id
        client {
          id
          firstNames
          surname
          idNumber
        }
        name
        type
        description
        file {
          id
          url
          contentType
        }
        verified
        verifiedBy {
          id
          description
        }
        createdAt
      }
  }
  `,
  dataset: data => data.clientdocuments,
  fields: {
    type: {
      label: 'Type',
    },
    client: {
      label: 'Client', accessor: row => row.client ? `${row.client.idNumber} ${row.client.firstNames} ${row.client.surname}` : ''
    },
    verified: { label: 'Verified', accessor: row => !row.verified && row.verified === false ? 'rejected' : row.verified === true ? 'approved' : '' },
    verifiedBy: { label: 'By', accessor: row => row.verifiedBy ? row.verifiedBy.description : '' },
    createdAt: { label: 'Received', Cell: row => moment(row.value).format('DD-MM-YYYY hh:mm:ss') },
  },
  defaultSorted: [{ id: 'createdAt', desc: true }]
};

const Replies = ({ client, history }) => {
  const [open, setOpen] = useState(false)
  const [showSMS, setShowSMS] = useState(false)
  const [clientId, setClientId] = useState()
  const [id, setId] = useState()
  const [row, setRow] = useState()
  const classes = useStyles();
  const [message, setMessage] = useState()

  const markReplied = (input) => {
    client
      .mutate({
        mutation: MARK_REPLIED,
        variables: {
          id,
          input
        }
      })
  }

  const createCommunication = (input) => {
    client.mutate({
      mutation: CREATE_COMMUNICATION,
      variables: {
        input
      }
    })
      .then(response => {
        markReplied({ statusReason: 'replied' });
        setOpen(false);
      })
      .catch(error => {
        console.log('there was an error sending the query', error)
      })
  }

  const onSend = (row) => {
    <Snackbar open={true} autoHideDuration={6000} >
      <Alert severity="info">
        `Sending ${row.type} to ${row.from}`
      </Alert>
    </Snackbar >

    const { id, replyToId, clientId, creditLifeId, savingsId, funeralId, from, type, message, ...rest } = row
    createCommunication({ clientId, creditLifeId, savingsId, funeralId, type, message, to: from })
  }

  return (
    <Card>
      <GraphQLTable config={config}
        onRowPress={(data) => {
          setClientId(data.clientId);
          setRow(data);
          setId(data.id);
          setOpen(true);
        }
        } />
      {/* <Dialog aria-labelledby="form-dialog-title"
        fullScreen
        onClose={() => setOpen(false)}
        open={open}
      >
        <DialogContent>
          <Products clientId={clientId} />
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button onClick={() => setOpen(false)} color="secondary" variant="contained" style={{ marginRight: 20 }}>
            Back
          </Button>
          <Button color="default" onClick={() => setShowSMS(true)}> Send Reply </Button>
          <Button onClick={() => {
            setOpen(false);
            markReplied({ statusReason: 'replied' });
          }}
            color="primary" variant="contained" style={{ marginRight: 20 }}>
            Mark as Done
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* <Modal
        open={showSMS}
        title={`Reply to ${row ? row.from : ''}`}
        okText="Send"
        cancelText="Back"
        onOk={() => {
          onSend({ ...row, message })
          setShowSMS(false)
        }}
        onCancel={() => setShowSMS(false)}
        onClose={() => setShowSMS(false)}
      >
        <TextInput label='SMS message text' fullWidth value={message} placeholder="Enter SMS message text" id="searchString"
          onChange={e => setMessage(e.target.value)} />
      </Modal> */}

      {open && <VerifyDoc
        description={row.description || row.type || 'Please verify this document'}
        file={row.file}
        setOpen={setOpen}
        open={open}
        clientData={row.client}
        verified={row.verified}
        verifiedBy={row.verifiedBy}
        setVerify={(result) => console.log('verified returned', result)}
      />}

    </Card>
  );
}
export default withApollo(Replies);