import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import moment from 'moment'
import { Menu, Card, DatePicker, MaterialTable, Select, ActivityIndicator, Modal, Alert } from '../generic'
import { API_URL } from '../../config'
import fileDownload from 'js-file-download'

const importBordereauxAPI = 'https://script.google.com/macros/s/AKfycbz4Xyo1-FRwEA3GIDD9gRScggQ9O3MnSzSc7swsPhogrEciv_o/exec';

const timeInWords = (timeTaken) => {

  if (timeTaken == 1) {
    return '1 second'
  }

  let timeText = `${timeTaken} seconds`

  if (timeTaken >= 60) {
    timeText = "minutes"
    let minutes = Math.floor(timeTaken / 60)
    let seconds = timeTaken - (minutes * 60)
    let secondsText = seconds > 0  ? secondsText = ` and ${seconds} seconds` : ' '
    let minuteText = minutes > 1 ?  ' minutes' : ' minute'
    timeText = minutes + minuteText + secondsText
  }
  return timeText
}

class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openKeys: ['sub1','sub2','sub4','sub5'],
      showSpinner: false,
      resultText: null,
      severity: 'info',
      disabledStartDate: false,
      startValue: moment().startOf('year'),
      endValue: moment(new Date()),
      rangeValue: [ ],
      rangeModalVisible: false,
      monthModalVisible: false,
      dateModalVisible: false,
      dcModalVisible: false,
      dcSelectVisible: false,
      showTable: false,
      dataSource: "",
      blob: "",
      report: "",
      api:"",
      parms:"",
      allocation:moment(new Date()),
      api: null,
      data: [],
      dc: "576-1251  MyMeerkat (Pty) Ltd. - The Manor",
      dcID: "5b1131e703105c8cee41c025",
      alertTitle: "",
      alertMessage: "",
      fetching: false
    };
  }

  // submenu keys of first level
  rootSubmenuKeys = ['sub1', 'sub2', 'sub4', 'sub5'];

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  }

  onStartChange = (value) => {
    this.onChange('startValue', value);
  }

  onEndChange = (value) => {
    this.onChange('endValue', value);
  }

  onRangeChange = (value) => {
    this.onChange('rangeValue', value);
  }

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  }

  onAllocationChange = (value) => {
    this.onChange('allocation', value);
  }


  getFromAndToMonth = (api) => {
    this.setState({ monthModalVisible: true, disabledStartDate: false, api})
  }


  getDateRange = (api) => {
    this.setState({ rangeModalVisible: true, api })
  }


  getToDate = (api) => {
    this.setState({ dateModalVisible: true, api })
  }


  async getReportAPI(report) {

    this.setState({ showSpinner: true, alertTitle: 'Building ' + report + ' report'  });

    try {

      let parms = ""
      let timeTaken = moment()

      if (report === "policyBuildup" || report === "policyBuildupInfo" || report === "loansCategory" || report === "claimPayments") {
        parms = ""
        this.setState({ alertMessage: "This might take a few minutes. Good time for a cuppa!" });
      }
      else if (report === "inForce" || report === "savingsReport") {
        parms = moment(this.state.endValue).format('DD-MM-YYYY')
        this.setState({ alertMessage: "This takes a good few minutes. Good time for a cuppa!" });
      }
      else if (report === "debtCounsellor" ) {
        parms = this.state.dc + '/' + moment(this.state.allocation).format('YYYY-MM')
        this.setState({ alertMessage: "This shouldn't take too long" });
      }

      else if (["mokuInteraction", "thirdPartySales", "mokuLoans" ].indexOf(report) > -1) {
        parms = moment(this.state.rangeValue[0]).startOf("day").valueOf() + '/' + moment(this.state.rangeValue[1]).endOf("day").valueOf()
        console.log('Moku report ' + parms)
        this.setState({ alertMessage: "Slow if you take a wide range, otherwise not too long." });
      }

      else {
        parms = moment(this.state.startValue).format('YYYY-MM') + '/' + moment(this.state.endValue).format('YYYY-MM')
        this.setState({ alertMessage: "This can take a while if you selected a wide date range." });
      }

      const accessToken = localStorage.getItem('accessToken');

      let api = `${API_URL}${report}${parms.length > 0 ? "/" + parms : ""}`

      this.setState({ api })
      console.log('api call is ' + api)

      if (report === 'thirdPartySales' ) {
        api += '/JSON'

        const dataResponse = await fetch(api, { headers: { 'Authorization': accessToken ? `JWT ${accessToken}` : null } })
        const data = await dataResponse.json()

        this.setState({ showSpinner: false });
        this.setState({ alertTitle: '' });

        console.log('data is ', data)
        if (data.data.length > 0) {
          this.setState({ showTable: true, dataSource: data, report, parms })
        }
        else {
          this.setState({ resultText: `Nothing to display`, severity: 'error' })
        }

      }
      else {
        api += '/EXCEL'
        console.log('api call is ' + api)
        const response = await fetch(api, { headers: { 'Authorization': accessToken ? `JWT ${accessToken}` : null } })
        const blob = await response.blob()
        const file = fileDownload(blob, `${report}${parms}.xlsx`)
        let timeText = timeInWords(moment().diff(timeTaken, 'seconds'))

        this.setState({ showSpinner: false });
        this.setState({ alertTitle: '' });

        this.setState({ resultText: `${report} report complete. Time taken was ${timeText}`, severity: 'success' })
      }

    } catch (error) {
      this.setState({ resultText: `${error.toString()}. Refresh screen and try again.`, severity: 'error' })
    }

  }

  async saveFile() {
    this.state.api += '/EXCEL'
    console.log('api call is ' + this.state.api)
    const accessToken = localStorage.getItem('accessToken');
    const response = await fetch(this.state.api, { headers: { 'Authorization': accessToken ? `JWT ${accessToken}` : null } })
    const blob = await response.blob()
    const file = fileDownload(blob, `${this.state.report}${this.state.parms}.xlsx`)
    this.setState({ resultText: this.state.report, severity: 'success' })
  }

  async sendMail(letterType) {
    this.setState({ showSpinner: true });
    this.setState({ alertTitle: 'Sending email' });
    this.setState({ alertMessage: "This should take about 10 seconds" });

    let timeTaken = moment()

    try {

      const accessToken = localStorage.getItem('accessToken');

      let id = "5b5b1e2942bb5c001a584836"
      let api = `${API_URL}creditLife/${letterType}/${id}`
      console.log('api call is ' + api)

      const result = await fetch(api, { headers: { 'Authorization': accessToken ? `JWT ${accessToken}` : null } })
      const resultText = await result.text()

      this.setState({ showSpinner: false });
      this.setState({ alertTitle: '' });

      let timeText = timeInWords(moment().diff(timeTaken, 'seconds'))

      if (resultText.toLowerCase().indexOf("error") > -1) {
        this.setState({ resultText, severity: 'error' })
      } else {
        this.setState({ resultText: `${resultText}.Time taken was ${timeText}`, severity: 'success' })
      }

    } catch (error) {
      this.setState({ resultText: `${error.toString()}. Refresh screen and try again.`, severity: 'error' })
    }

  }
  //app.use('/creditLife/:id/ra', (req, res, next) => {

  async getAPI(api) {

    this.setState({ showSpinner: true });
    this.setState({ alertTitle: 'Importing bordereaux files' });
    this.setState({ alertMessage: "This can take a few minutes" });

    let timeTaken = moment()

    try {

      console.log('api call is ' + api)

      const result = await fetch(api);
      const resultText = await result.text()

      this.setState({ showSpinner: false });
      this.setState({ alertTitle: '' });

      let timeText = timeInWords(moment().diff(timeTaken, 'seconds'))

      if (resultText.toLowerCase().indexOf("error") > -1) {
        this.setState({ resultText: `${resultText} report complete.Time taken was ${timeText}`, severity: 'error' })
      } else {
        this.setState({ resultText: `${resultText}.Time taken was ${timeText}`, severity: 'success' })
      }

    } catch (error) {
      this.setState({ resultText: `${error.toString()}. Refresh screen and try again.`, severity: 'error' })
    }

  }

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  }

  //openKeys={this.state.openKeys} onOpenChange={this.onOpenChange}
  menuItems = [
    { key: '1', onClick: () => this.getFromAndToMonth("trialBalance"), value: 'Trial Balance' },
    { key: '2', onClick: () => this.getFromAndToMonth("cashFlow"), value: 'Policy Cash Flow' },
    { key: '3', onClick: () => this.getFromAndToMonth("loanCash"), value: 'Loan Cash Flow' },
    { key: '4', onClick: () => this.getToDate("inForce"), value: 'In Force Policy' },
    { key: '5', onClick: () => this.getReportAPI("policyBuildup"), value: 'Policy Buildup' },
    { key: '6', onClick: () => this.getReportAPI("policyBuildupInfo"), value: 'Policy Buildup Info' },
    { key: '7', onClick: () => this.getToDate("savingsReport"), value: 'Savings Report' },
    { key: '8', onClick: () => this.getReportAPI("claimPayments"), value: 'Claim Payments' },
    { key: '9', onClick: () => this.getFromAndToMonth("trialBalance"), value: 'Policy Cash Flow' },
    { key: '10', onClick: () => this.getFromAndToMonth("trialBalance"), value: 'Policy Cash Flow' },

    { key: '11', onClick: () => this.setState({ dcModalVisible: true, dcSelectVisible: false }), value: 'Monthly allocations report' },
    { key: '12', onClick: () => this.setState({ dcModalVisible: true, dcSelectVisible: true }), value: 'DC Monthly allocation report' },
    { key: '13', onClick: () => this.getDateRange("mokuLoans"), value: 'Moku Loans from Credit Check' },
    { key: '14', onClick: () => this.getDateRange("thirdPartySales"), value: 'Telephone numbers for 3rd Party Sales' },
    { key: '15', onClick: () => this.getReportAPI("loansCategory"), value: 'Loans by Category from Credit Check' },
    { key: '16', onClick: () => this.sendMail("ra"), value: 'Email Retrenchment Approval' },
    { key: '17', onClick: () => this.sendMail("ro"), value: 'Email Retrenchment Claim Recieved Outstanding' },
    { key: '18', onClick: () => this.sendMail("rno"), value: 'Email Retrenchment Claim Recieved No Outstanding' },
  ] 

  menuContainer = () => (<>
    <Menu menuItems={this.menuItems} />
  </>
  )

  setDC = (value) => {
    this.setState({ dc: value, dcID: value.key });
  }

  render() {

    const { fetching, data, value } = this.state;
    let dcData = this.props.data.debtcounsellors
    //this.setState({ rangeValue: [moment().format("YYYY-MM-01"), (moment().format("YYYY-MM-") + moment().daysInMonth())]})

    return (
    <Card>
        <h1>Credit Life Reports</h1>

        {this.state.showSpinner && <center><ActivityIndicator /></center>}

        {this.state.resultText && <Alert vertical='top' onClose={() => this.setState({ resultText: null, showSpinner: false })} severity={this.state.severity} message={this.state.resultText} />}

        {this.menuContainer()}

        <Modal
          title={this.state.disabledStartDate ? 'Select End Month' : 'Select Start and End Months'}
          okText='Ok'
          cancelText='Cancel'
          wrapClassName="vertical-center-modal"
          open={this.state.monthModalVisible}
          onOk={() => (
            this.setState({ monthModalVisible: false }),
            this.getReportAPI(this.state.api)
          )}
          onCancel={() => this.setState({ monthModalVisible: false })}
        >
          <DatePicker
            style={{ display: this.state.disabledStartDate ? 'none' : '' }}
            disabled={this.state.disabledStartDate}
            value={this.state.startValue}
            format="YYYY-MM"
            placeholder="Start"
            onChange={this.onStartChange}
            onOpenChange={this.handleStartOpenChange}
          />

          <DatePicker
            style={{ marginLeft:14 }}
            format="YYYY-MM"
            placeholder="End"
            value = {this.state.endValue}
            onChange={this.onEndChange}
            onOpenChange={this.handleEndOpenChange}
          />

        </Modal>

        <Modal
          title={'Select In Force Date'}
          okText='Ok'
          style={{marginTop:175}}
          cancelText='Cancel'
          wrapClassName="vertical-center-modal"
          open={this.state.dateModalVisible}
          onOk={() => (
            this.setState({ dateModalVisible: false }),
            this.getReportAPI(this.state.api)
          )}
          onCancel={() => this.setState({ dateModalVisible: false })}
        >
          <DatePicker
            format="DD-MM-YYYY"
            value = {this.state.endValue}
            onChange={this.onEndChange}
          />

        </Modal>

        <Modal
          title={'Select Date Range'}
          okText='Ok'
          style={{marginTop:175}}
          cancelText='Cancel'
          wrapClassName="vertical-center-modal"
          open={this.state.rangeModalVisible}
          onOk={() => (
            this.setState({ rangeModalVisible: false }),
            this.getReportAPI(this.state.api)
          )}
          onCancel={() => this.setState({ rangeModalVisible: false })}
        >
          <DatePicker
            format="DD-MM-YYYY"
            value = {this.state.rangeValue}
            onChange={this.onRangeChange}
          />

        </Modal>

        <Modal
          title={'Select Debt Counsellor and Allocations Month'}
          okText='Ok'
          cancelText='Cancel'
          wrapClassName="vertical-center-modal"
          open={this.state.dcModalVisible}
          onOk={() => (
            this.setState({ dcModalVisible: false }),
            this.getReportAPI('debtCounsellor')
          )}
          onCancel={() => this.setState({ dcModalVisible: false })}
        >

          <center>
            <Select
              defaultValue="599-1273  The Debt Mentor"
              value={this.state.dc}
              onSelect={this.setDC}
              style={{ width: '70%', display: this.state.dcSelectVisible ? "inherit" : "none" }}
            >
              {dcData && dcData.slice(0).sort(function (a, b) {
                return a.description < b.description ? -1 : 1;
              }).map(d => <Option key={d.id} value={d.id} >{d.externalId + '  ' + d.description}</Option>)}
            </Select>


            <DatePicker
              value={this.state.allocation}
              format="YYYY-MM"
              placeholder="Month"
              onChange={this.onAllocationChange}
              style={{ width: '30%' }}
            />
          </center>

        </Modal>

        <Modal
          title={'Report results'}
          okText='Save as Excel'
          width={'99%'}
          cancelText='Close'
          wrapClassName="vertical-center-modal"
          open={this.state.showTable}
          onOk={() => (
            this.setState({ showTable: false }),
            this.saveFile()
          )}
          onCancel={() => this.setState({ showTable: false })}
        >
          <MaterialTable
            data={this.state.dataSource.data}
            columns={this.state.dataSource.columns && this.state.dataSource.columns.map(column => ({ ...column, sorter: (a, b) => a[column.dataIndex] < b[column.dataIndex] ? -1 : 1 }))}
          />

        </Modal>

        </Card>
  );
}
}

const debtCounsellors = gql`
  {
    debtcounsellors {
      id
      externalId
      description
      firstPolicySold
    }
  }`


export default Reports = graphql(debtCounsellors)(Reports)
