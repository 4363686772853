import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, TextInput } from '../generic'

const useStyles = makeStyles(() => ({
  titles: {
    color: "black",
    fontFamily: "Calibri,sans-serif"
  },
  email: {
    border: "1px solid #ccc",
    borderRadius: "15px",
    padding: 14,
    fontFamily: "Calibri,sans-serif"
  },
  sms: {
    border: "1px solid #ccc",
    borderRadius: "15px",
    padding: 14,
    marginTop:10,
    fontFamily: "Calibri,sans-serif"
  },
  imagePreview: {
    objectFit: 'cover',
    border: "1px solid #ccc",
    borderRadius: "15px",
    padding: 14,
    maxWidth: 500,
  },
}));

function ImageLink({ filename, imgSrc }) {
  const [showDialog, setShowDialog] = useState(false);
  const classes = useStyles();

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  return (
    <>
      <a href="#" onClick={toggleDialog}>
        {filename}
      </a>
      {showDialog && (
        <div  onClick={toggleDialog}>
          <img className={classes.imagePreview} src={imgSrc} alt={filename} />
        </div>
      )}
    </>
  );
}

const Communication = ({ communication, open, setOpen, history, email, cellNumbers, onSend }) => {
  const classes = useStyles();
  const { client, subject, attachments, from, to, type, message,cc } = communication || {};

  const handleClose = (confirm) => {
    setOpen(false);
    if (confirm) {
      setOpen(true);
    } else {
      // Perform the confirmation action here
    }
  };

  const resendMessage = (row, email, cellNumbers) => {  
    let to = row.type == 'SMS' ? cellNumbers && cellNumbers.length > 0 ? cellNumbers[0] : cellNumbers : email
    to = to || communication.to
    const [show, setShow] = useState(false)
    const [showSMS, setShowSMS] = useState(false)
    const [message, setMessage] = useState()
  
    return (
      <Fragment>
        {!(row.from && row.replyToId) && row.type !== 'PHONE' && <Button color="default" onClick={() => setShow(true)}> Resend </Button>}
        {(row.from && row.replyToId) && row.type === 'SMS' && <Button color="default" onClick={() => setShowSMS(true)}> Reply </Button>}
  
        <Modal
          open={show}
          title={`Confirm resend of the following ${row.type} to ${to}`}
          okText="Send"
          cancelText="Back"
          onOk={() => {
            onSend(to, row)
            setShow(false)
          }}
          onCancel={() => setShow(false)}
          onClose={() => setShow(false)}
        >
          {row.type === 'SMS' ? <div style={{ inlineSize: "fit-content", overflowWrap: "normal" }}> {row.message} </div> : <div dangerouslySetInnerHTML={{ __html: row.message }} />}
        </Modal>
  
        <Modal
          open={showSMS}
          title={`Reply to ${to}`}
          okText="Send"
          cancelText="Back"
          onOk={() => {
            onSend(to, { ...row, message })
            setShowSMS(false)
          }}
          onCancel={() => setShowSMS(false)}
          onClose={() => setShowSMS(false)}
        >
          <TextInput label='SMS message text' fullWidth value={message} placeholder="Enter SMS message text" id="searchString"
            onChange={e => setMessage(e.target.value)} />
        </Modal>
  
      </Fragment>
    )
  }  

  return (
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        onClose={() => handleClose(false)}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
      <DialogTitle id="confirmation-dialog-title">{subject}</DialogTitle>
        <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {from && <><span className={ classes.titles } >From: </span>{from}<br /></>}
          <span className={ classes.titles } >To:</span> {to}<br />
          {cc && <><span className={classes.titles} >CC:</span> {cc}<br /></>}
          {attachments && attachments.length > 0 && <><span className={classes.titles} >Attachments:</span> {
            attachments.map(a => <ImageLink filename={a.fileName} imgSrc={a.file.url} />  )
          }<br /><br /></>}
          
          {typeof message === 'string' && message.match(/<\/?[a-z][\s\S]*>/) ? <div className={classes.email} dangerouslySetInnerHTML={{ __html: message }} /> : <div className={classes.sms}> {message} </div>}
        </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => handleClose(false)} color="primary">
            Close
        </Button>
        {resendMessage(communication, email, cellNumbers)},
        {history && client && <Button variant="contained" onClick={() => history.push(`/products/${client.idNumber}`) } color="primary">
          Display Client
        </Button>}
        </DialogActions>
      </Dialog>
  );
};

export default Communication;
