import React from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom'
import { GraphQLFormProvider, FormField, FormFieldArray } from 'react-form-helper';
import { getAllCreditors } from './CreditorsList'
import { ClaimBankAccountType, ClaimPaymentReferenceType, LoanType, LoanPackage } from '../../variables/claimReferenceTypes'
import { Card, Grid, BuildLayout, renderDisplay, Button, TextField, renderInput } from '../generic'
import BankInfo from '../bankAccount/BankInfo';
import { makeStyles } from '@material-ui/core/styles';

const COMMUNICATION_TYPES = [{ value: 'EMAIL', label: 'Email' }, { value: 'PHONE', label: 'Phone' }, { value: 'WHATSAPP', label: 'WHATSAPP' }]

const UpdateCreditor = gql`
mutation UpdateCreditor( $id: String!, $input: UpdateCreditorInput!) {
  updateCreditor(id: $id, input: $input) {
    id
    name
    comparativeRate
    companyRegistrationNumber
    fspNumber    
    bankAccount {
      id
      bankName
      accountNo
      accountHolderName
      accountHolderContactNumber
    }
    defaultClaimPaymentDetails { 
      bankAccountType
      referenceType
      customReference
      bankName
      branchCode
    }
    contactDetails {
      type
      attention
      value
      purpose
    }
  }
}`


const config = {
  name: 'creditor',
  fields: {
    name: {
      type: 'text',
      label: 'Creditor Name',
      hide: false,
    },
    contactDetails: {
      type: 'fieldarray',
      label: 'Contact Details',
      hide: true,
      config: {
        'type': {
          type: 'select',
          label: 'Contact Type',
          options: COMMUNICATION_TYPES,
          required: true,
        },
        'value': {
          type: 'text',
          label: 'Contact Value',
          required: true,
          validate: (value) => {
            if (!value) {
              return 'Contact is required';
            }
          }
        },
        'attention': {
          type: 'text',
          label: 'Attention',
        },
        'purpose': {
          type: 'select',
          options: [{ value: "SETTLEMENT_LETTER", label: "Get Settlement Letter" }, { value: "PRESCRIPTION", label: "Prescription Notification" }],
          label: 'Purpose*',
          validate: (value) => {
            if (!value) {
              return 'Purpose is required';
            }
          }
        },
      },
    },
    openMarketPackages: {
      type: 'fieldarray',
      label: 'Loan Packages',
      hide: true,
      config: {
        'loanType': {
          type: 'text',
          type: 'select',
          label: 'Loan Type',
          options: LoanType.map(o => ({ "value": o, "label": o })),
          validate: (value, row) => {
            if (!value && row.accountNo) {
              return 'Select a Loan';
            }
          }
        },
        'package': {
          type: 'text',
          type: 'select',
          label: 'Loan Package',
          options: LoanPackage.map(o => ({ "value": o.value, "label": o.label })),
          validate: (value, row) => {
            if (!value && row.accountNo) {
              return 'Select a Package';
            }
          }
        },
      },
    },
    'bankAccountType': {
      type: 'text',
      type: 'select',
      label: 'Account Type',
      options: ClaimBankAccountType.map(o => ({ value: o, label: o })),
      validate: (value, row) => {
        if (!value && row.accountNo) {
          return 'Enter Account number or bank account details';
        }
      }
    },
    'referenceType': {
      type: 'text',
      type: 'select',
      label: 'Reference Type',
      options: ClaimPaymentReferenceType.map(o => ({ "value": o, "label": o })),
      validate: (value, row) => {
        if (!value && row.accountNo) {
          return 'Enter reference number for bank transactions';
        }
      }
    },
    'companyRegistrationNumber': {
      type: 'text',
      label: 'Company Registration Number'
    },
    'fspNumber': {
      type: 'text',
      label: 'FSP Number'
    },
    'customReference': {
      type: 'text',
      label: 'Customer Reference',
      validate: (value, row) => {
        if (!value && row.accountNo) {
          return 'Enter reference for customer bank transactions';
        }
      }
    },
    'comparativeRate': {
      type: 'text',
      label: 'Comparative Rate'
    },
  },
  query: gql`
		query Creditor($id: String!) {
			creditor(id: $id) {   
        id
        name
        comparativeRate
        companyRegistrationNumber
        fspNumber 
        openMarketPackages {
          loanType
          package
        }
        contactDetails {
          attention
          type
          value
          purpose
        }
        bankAccount {
          id
          bankName
          accountNo
          accountHolderName
          accountHolderContactNumber
        }
        defaultClaimPaymentDetails { 
          bankAccountType
          referenceType
          customReference
          bankName
          branchCode
        }
      }
    }
  `,
  updateMutation: gql`
		mutation UpdateCreditor( $id: String!, $input: UpdateCreditorInput!) {
			updateCreditor(id: $id, input: $input) {
          id
          name
          comparativeRate
          companyRegistrationNumber
          fspNumber 
          openMarketPackages {
            loanType
            package
          }
          contactDetails {
            attention
            type
            value
            purpose
          }
          bankAccount {
            id
            bankName
            accountNo
            accountHolderName
          accountHolderContactNumber
          }
          defaultClaimPaymentDetails { 
            bankAccountType
            referenceType
            customReference
            bankName
            branchCode
          }
      }
    }`,
  addMutation: gql`
		mutation CreateCreditor($input: CreateCreditorInput!) {
			createCreditor(input: $input) {
        id
        name
        comparativeRate
        companyRegistrationNumber
        fspNumber 
        openMarketPackages {
          loanType
          package
        }
          contactDetails {
          attention
          type
          value
          purpose
        }
        bankAccount {
          id
          bankName
          accountNo
          accountHolderName
          accountHolderContactNumber
        }
        defaultClaimPaymentDetails { 
          bankAccountType
          referenceType
          customReference
          bankName
          branchCode
        }
      }
    }`
};

const style = {
  minWidth: '80%',
  maxWidth: '80%',
  padding: 10
}

let FormLayout = (props) => {
  const useStyles = makeStyles((theme) => ({
    field: {
      display: "flex",
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: "column"
    },
    flexStart: {
      flexGrow: 1,
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    flexSpace: {
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'space-around',
    },
    root: {
      flexGrow: 1,
      justifyContent: 'center',
      paddingBottom: 7,
    },
    footer: {
      flexGrow: 1,
      justifyContent: 'flex-end',
      paddingBottom: 21
    },
    column: {
      minWidth: '80%',
      maxWidth: '80%',
      padding: 0
    },
    row: {
      alignContent: 'flex-start',
      justifyContent: 'flex-start',
      padding: 0
    },
    button: {
      color: '#262626',
      padding: '0',
      minWidth: 100,
      maxWidth: 100,
      textAlign: 'center',
      marginRight: 14
    }
  }));
  const classes = useStyles()

  const { mode, id, history, data, client, formName, ...rest } = props
  const { creditor } = data || {}
  const { name, bankAccount } = creditor || {}
  console.log('creditor form props', props)

  const attachBank = (bank) => {
    const bankAccountId = bank.id
    client.mutate({
      mutation: UpdateCreditor,
      variables: {
        id,
        input: {
          bankAccountId
        }
      }
    })
}

  return (
    <Card>
      <h1>{mode === "edit" && "Edit"} Creditor</h1>

      <TextField disabled={true} id="creditorName" variant='standard' value={name} />
      <br />
      <br />
      <h2>Contact Details</h2>
      <FormFieldArray name='contactDetails' renderFieldArray={({ fields }) => {
        return (
          <>
            {fields.map((field, index) => {
              console.log(`${field}+${index}`);
              return <>
                <Grid container
                  direction={"row"}
                  justifyContent={'centre'}
                  alignItems={'centre'}
                  spacing={2}
                  className={classes.root} >

                  <Grid item xs={2} className={classes.column}>
                    <FormField id={`${field}+1`} noLabel name={`${field}.type`} />
                  </Grid>
                  <Grid item xs={2} style={style} >
                    <FormField id={`${field}+2`} noLabel name={`${field}.value`} />
                  </Grid>
                  <Grid item xs={2} style={style} >
                    <FormField id={`${field}+3`} noLabel name={`${field}.attention`} />
                  </Grid>
                  <Grid item xs={2} style={style} >
                    <FormField id={`${field}+3`} noLabel name={`${field}.purpose`} />
                  </Grid>
                </Grid>
                <br />
                <br />
              </>
            })}

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button style={{
                minWidth: 100,
                maxWidth: 100,
                textAlign: 'center',
                marginRight: 14
              }} onClick={() => {
                fields.push({ type: 'EMAIL', value: '' })
              }}> New  </Button>
            </div>
          </>
        )
      }
      } />
      < br />
      <br />
      <h2>Loan Packages</h2>
      <FormFieldArray name='openMarketPackages' renderFieldArray={({ fields }) => {
        return (
          <>
            {fields.map((field, index) => {
              console.log(`${field}+${index}`);
              return <>
                <Grid container
                  direction={"row"}
                  justifyContent={'centre'}
                  alignItems={'centre'}
                  spacing={2}
                  className={classes.root} >

                  <Grid item xs={2} className={classes.column}>
                    <FormField id={`${field}+1`} noLabel name={`${field}.loanType`} />
                  </Grid>
                  <Grid item xs={2} style={style} >
                    <FormField id={`${field}+2`} noLabel name={`${field}.package`} />
                  </Grid>
                </Grid>
                <br />
                <br />
              </>
            })}

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button style={{
                minWidth: 100,
                maxWidth: 100,
                textAlign: 'center',
                marginRight: 14
              }} onClick={() => {
                fields.push({ loanType: '', package: '' })
              }}> New </Button>
            </div>
          </>
        )
      }
      } />
      <br />
      <br />
      <h2>Creditor Details</h2>
      < br />
      <BuildLayout formName={formName} direction='column' spacing={2} align='center' justify='center' fieldNames={Object.keys(config.fields)
        .filter(fieldName => !config.fields[fieldName].hide && ((mode) === 'edit' || !config.fields[fieldName].noAdd))} mode={mode} fieldConfig={config.fields} cancel={() => history.push('/creditors')}  />
      <br />
      <h2>Bank Account Details</h2>
      <br />

      <BankInfo id={bankAccount && bankAccount.id} setBank={attachBank} />
    </Card >
  );
}
FormLayout = withRouter(FormLayout);

let CreditorBank = (props) => {
  const { id, client } = props;
  let mode = id ? 'edit' : 'add'
  return (
    <div>
      <GraphQLFormProvider
        name="creditor"
        mode={mode}
        id={id}
        {...config}
        initialValuesFromData={(data) => {
          const { defaultClaimPaymentDetails, bankAccount, openMarketPackages, ...rest } = data.creditor || {}
          let bankName, bankAccountType, referenceType, customReference, companyRegistrationNumber, fspNumber, branchCode
          if (defaultClaimPaymentDetails) {
            if (!bankName) bankName = defaultClaimPaymentDetails.bankName
            bankAccountType = defaultClaimPaymentDetails
            referenceType = defaultClaimPaymentDetails
            customReference = defaultClaimPaymentDetails
            companyRegistrationNumber = defaultClaimPaymentDetails
            fspNumber = defaultClaimPaymentDetails
            branchCode = defaultClaimPaymentDetails.branchCode
          }
          return ({ ...defaultClaimPaymentDetails, ...bankAccount, ...rest })
        }
        }
        mapInputVariables={async (input) => {
          console.log('mapInputVariables', input)
          let { bankAccount, comparativeRate, companyRegistrationNumber, fspNumber, branchCode, accountHolderName, accountHolderContactNumber, accountNo, bankAccountType, customReference, referenceType, contactDetails, openMarketPackages, ...rest } = input
            const defaultClaimPaymentDetails = { bankAccountType, customReference, referenceType }
          if (contactDetails && contactDetails.length > 0) {
            // strip out nulls
            input.contactDetails = contactDetails.filter(c => c.value)
            return { input: { contactDetails: input.contactDetails, defaultClaimPaymentDetails, comparativeRate, companyRegistrationNumber, fspNumber, name: input.name, openMarketPackages: input.openMarketPackages,  } }
          }
          console.log('mapInputVariables', { input: { bankAccount, defaultClaimPaymentDetails: input.defaultClaimPaymentDetails, comparativeRate, ...rest} })
          return { input: {contactDetails: input.contactDetails, defaultClaimPaymentDetails, comparativeRate, companyRegistrationNumber, fspNumber, name: input.name } }
        }}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(props) => <FormLayout client={client} {...props} formName={config.name} />}
        DisplayFormLayout={FormLayout}
        refetchQueries={[{ query: getAllCreditors }]}
        afterSubmit={({ data }) => {
          console.log('creditor afterSubmit', data);
        }}
        keepInitialValues
      />
    </div>
  );
}

export default withApollo(CreditorBank);