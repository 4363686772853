import React from 'react';
import UuidSearch from './UuidSearch';
import UserList from './UserList';
import RegisteredUser from './RegisteredUser'
import RegisteredUserList from './RegisteredUserList'

import User from './User';
import { Route, Switch } from 'react-router-dom';

const Users = () => (
  <div>
    <Route path='/users' exact component={UserList} />
    <Switch>
      <Route
        path='/users/new'
        exact
        render={(history) =>
          <User mode="add" history={history}/>
        }
      />
      <Route
        path='/users/:id/:edit?'
        render={({ match, history }) =>
          <div>
            <User
              mode={"edit"}
              id={match.params.id}
              history={history}
            />
          </div>
        }
      />
    </Switch>
  </div>
)

const RegisteredUsers = (history) => (
  <div>
    <Route path='/registered/' exact component={RegisteredUserList} />
    <Route
      path='/registered/:id/:edit?'
      render={({ match }) =>
        <div>
          <RegisteredUser
            mode={"edit"}
            id={match.params.id}
            history={history}
          />
        </div>
      }
    />
  </div>
)

const SearchByUuid = (history) => (
  <div>
    <Route path='/uuid/' exact component={UuidSearch} />
    <Route
      path='/uuid/:uuid/:edit?'
      render={({ match }) =>
        <div>
          <UuidSearch
            mode={"edit"}
            uuid={match.params.uuid}
            history={history}
          />
        </div>
      }
    />
  </div>
)

export {
  Users,
  RegisteredUsers,
  SearchByUuid
}