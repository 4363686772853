import React, { useEffect, useState } from 'react'
import { useQuery, Subscription } from 'react-apollo';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import gql from 'graphql-tag';
import { GraphQLFormProvider, FormField, FieldValue } from 'react-form-helper'
import { makeStyles } from '@material-ui/core/styles';
import { isValidCellphoneNumber, isValidEmail, isValidIdNumber, isInteger, isRequiredForForeignID, isRequired } from '../../lib/validations'
import { IdTypes } from "../../variables/IdTypes"
import { capitalize } from '../../lib/utils'
import { ActivityIndicator, Button, Card, renderInput, renderDisplay, BuildLayout, Grid } from '../generic';

// const useStyles = makeStyles(theme => ({
//   inputRoot: {
//     '&$disabled': {
//       color: "rgba(0, 0, 0, 0.99)"
//     },
//   },
//   disabled: {}
// }));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // justifyContent: 'space-evenly',
    paddingBottom: 7
  },
  paper: {
    minWidth: 300
  },
  card: {
    maxWidth: 800,
    marginLeft: '20%',
  }
}));

const COMPANYS = gql` {
  companys {
    id
    name
  }
}`;

//"react-apollo": "^1.4.15",
const CLIENT_ACCEPTED = gql`
  subscription ClientAccepted {
    clientUpdated {
      idNumber
      acceptedTerms
      smoker
    }
  }
`

const createMutation = gql`
  mutation($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
      name
      basicListed
      updatedAt
    }
  }
`
function handleCreate(props) {
  const { value, apolloClient } = props
  apolloClient
    .mutate({
      mutation: createMutation,
      variables: {
        input: { name: value }
      }
    })
}

const config = {
  name: 'openmarket',
  fields: {
    idNumber: {
      type: 'text',
      label: 'ID Number *',
      addOnly: true,
      validate: [isRequired, isValidIdNumber],
    },
    idType: {
      type: "select",
      label: 'Id Type',
      autocomplete: true,
      fullWidth: true,
      options: IdTypes,
      addOnly: true,
      label: "Identity Type",
      validate: [isRequired]
    },
    firstNames: {
      type: 'name',
      label: 'First Name *',
      validate: (value) => {
        if (!value) {
          return 'First name is Required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    },
    surname: {
      type: 'name',
      label: 'Last Name *',
      validate: (value) => {
        if (!value) {
          return 'Last name is required'
        }
        if (value.length > 35) {
          return 'Length must be less than 35'
        }
      }
    },

    "cellNumbers[0]": {
      type: 'text',
      label: 'Mobile Number*',
      validate: (value) => {
        if (!value) {
          return 'Cell is required'
        }
        if (isValidCellphoneNumber(value)) {
          return 'enter a valid cell number'
        }
      }
    },
    email: {
      type: 'text',
      label: 'Email*',
      validate: (value) => {
        if (value && isValidEmail(value)) {
          return 'Email not valid'
        }
      }
    }, 
    smoker: {
      type: 'switch',
      label: 'Smoker',
    },
    "employers[0].companyName": {
      type: 'addselect',
      handleCreate,
      apolloClient: {},
      fullWidth: true,
      autocomplete: true,
      label: 'Employer *',
      freeSolo: true,
      options: [],
      validate: (value) => {
        if (!value)  {
          return 'Employer is required'
        }
      }
    },
    occupation: {
      type: 'text',
      options: [],
      freeSolo: true,
      autocomplete: true,
      label: 'Occupation *',
      validate: (value) => {
        if (!value)  {
          return 'Occupation is required'
        }
      }
    },
    employmentStatus: {
      type: 'select',
      label: 'Employment Status',
      options: ['PERMANENT_EMPLOYMENT', 'UNEMPLOYED', 'PENSIONER', 'OTHER'].map(o => ({ label: o, value: o })),
      validate: (value) => {
        if (!value) {
          return 'Employment Status is required'
        }
      }
    },
    paymentDay: {
      type: 'text',
      label: 'Payment Day',
      validate: (value) => {
        if (!value) {
          return 'Payment day is required'
        }
        else if (isInteger(value)) {
          return 'Enter a valid number'
        }
        else if (value < 1 || value > 31) {
          return 'Payment day must be a valid day'
        }
      }
    },
    acceptedTerms: {
      type: 'switch',
      label: 'T&C Signed *',
    },
  },
  query: gql`
    query OpenMarketClient($id: String!) {
      client(id: $id) {
      id
      idNumber
      idType
      occupation
      employmentStatus
      firstNames
      surname
      cellNumbers
      email
      smoker
      acceptedTerms
      employers {
        companyName
        occupation
        startDate
        isPartTime
      }
      financialSummary {
        incomes {
          amount
          spouseAmount
        }
      }
    }
	}
  `,
  updateMutation: gql`
  mutation createOpenMarketCreditLife($id:String, $input: CreateClientInput!, $paymentDay: Int) {
    createOpenMarketCreditLife(id:$id, input : $input, paymentDay: $paymentDay) {
    id
    client {
      id
      idNumber
      acceptedTerms
    }
  }
}
`,
  addMutation: gql`
    mutation createOpenMarketCreditLife($id:String,$input: CreateClientInput!, $paymentDay: Int) {
      createOpenMarketCreditLife(id:$id, input : $input, paymentDay: $paymentDay ) {
      id
      client {
        id
        idNumber
        acceptedTerms
    }
  }
}
`
}

const SEND_TERMS = gql`
  mutation sendTerms($input: CreateClientInput!, $id:String, $productType: String, $productId: String) {
    sendTerms(input: $input, id:$id, productType: $productType, productId: $productId)
  }
`

let sendAcceptTerms = (input, id, creditlifeId, client) => {
  client
    .mutate({
      mutation: SEND_TERMS,
      variables: {
        input,
        id,
        productType: "CREDIT_LIFE",
        productId: creditlifeId
      }
    })
}

let FormLayout = (props) => {
  const { mode, history, data, companys, apolloClient, formName } = props
  const classes = useStyles();

  const { loading, client } = data || {}

  if (loading) {
    return <ActivityIndicator />;
  }

  const options = new Set(companys && companys.map(e => ({ value: e.id, label: capitalize(e.name) })))
  config.fields['employers[0].companyName'].options = [...options].sort((a, b) => (a.label > b.label) ? 1 : -1)
  config.fields['employers[0].companyName'].apolloClient = apolloClient
  //config.fields['occupation'].options = employers.map(e => ({ value: e.occupation, label: e.occupation }))

  return (
    <Card className={classes.card} >
        <br />
      <BuildLayout formName={formName} fieldNames={Object.keys(config.fields)} cancel={() => history.push('/credit-life')} mode={mode} fieldConfig={config.fields} direction='column' spacing={2} />
        <br />

        <FieldValue name={`acceptedTerms`} renderField={({ input, value }) => {
          if (input.value && input.value === true) {
            return <div />
          }
          else return (<Grid container direction='row' justifyContent='flex-start' className={classes.root}>
            <Grid item xs={6}>
              Note: An SMS to approve our T&C will automatically be sent to the client.
            </Grid>
          </Grid>)
      }} />
      </Card>
  )
}


let WaitForApproval = ({ id, creditlifeId, acceptedTerms, history, client }) => {
  console.log('waiting for approval ', id, creditlifeId, acceptedTerms)

  useEffect(() => {
    if (!acceptedTerms) {
      //sendAcceptTerms({ acceptedTerms }, id, creditlifeId, client)
    }
  }, []);

  const isAccepted = ({ data }) => {
    console.log('isAccepted Data=', data)
    if (!data || !data.clientUpdated) {
      return false
    }
    return data.clientUpdated.acceptedTerms
  }

  if (acceptedTerms) {
    return (
      <Card>
          <div style={{ minHeight: 600, marginTop: 7, marginBottom: 21, flexDirection: "column", display: "flex", alignItems: "center", justifyContent: 'center' }}>
          <div style={{ fontWeight: 'bold' }}>  Terms have been accepted </div>
          <div style={{ height: 35 }} />
          <div>
            <CheckCircleOutlineIcon style={{ color: "green", fontSize: 128 }} onClick={() => history.push(`/credit-life/${creditlifeId}/edit`)} />
          </div>
          <div style={{ height: 35 }} />
          <div><Button color="primary" onClick={() => history.push(`/credit-life/${creditlifeId}/edit`)}> Create OPM Policy</Button></div>
        </div>
      </Card>
    )
  }

  else return (<Subscription subscription={CLIENT_ACCEPTED} creditlifeId={creditlifeId}>
    {data => {
      return (
        <Card>
          <div style={{ padding: 21 }}>
            <h1>Create New Open Market Policy</h1>
            <div style={{ minHeight: 600, marginTop: 7, marginBottom: 21, flexDirection: "column", display: "flex", alignItems: "center", justifyContent: 'center' }} onClick={() => history.push(`/credit-life/${creditlifeId}/edit`)}>
              <div style={{ fontWeight: 'bold' }}>
                {isAccepted(data) ? "Terms have been accepted" : "Waiting for the client to accept the Terms & Conditions"}
              </div>
              <div style={{ height: 35 }} />
              <div >{isAccepted(data)
                ? <CheckCircleOutlineIcon style={{ color: "green", fontSize: 64 }} onClick={() => history.push(`/credit-life/${creditlifeId}/edit`)} />
                : <CircularProgress color='secondary' />}
              </div>
              <div style={{ height: 35 }} />
              <div><Button color="primary" > {isAccepted(data) ? "Continue" : "Skip T&C "}</Button></div>
            </div>
          </div>
        </Card>
      )
    }}
  </Subscription>)
}

let OpenMarket = (props) => {
  const { history, match } = props
  let { idNumber, id } = match && match.params || {}
  console.log('openMarket props', props)
  const [creditlifeId, setCreditlifeId] = useState()
  const [acceptedTerms, setAcceptedTerms] = useState()
  const [clientId, setClientId] = useState(id)

  const { data, loading, error } = useQuery(COMPANYS)
  if (loading) {
    return <ActivityIndicator />
  }

  if (id === 'undefined') {
    id = undefined
  }

  if (!creditlifeId) {
    return (
      <GraphQLFormProvider
        mode={id ? "edit" : "add"}
        id={id}
        {...config}
        initialValues={{ idNumber, idType: "rsa_id" }}
        mapInputVariables={(props) => {
          let {  paymentDay, id: inputId, ...input } = props
          return ({ id, paymentDay, input })
        }}
        initialValuesFromData={data => {
          console.log('OpenMarket data', data.client);
          return data.client;
        }}
        renderDisplay={renderDisplay}
        renderInput={renderInput}
        InputFormLayout={(layoutProps) => <FormLayout {...props} {...layoutProps} formName={config.name} creditlifeId={creditlifeId} setAcceptedTerms={setAcceptedTerms} companys={data && data.companys} />}
        DisplayFormLayout={FormLayout}
        afterSubmit={data => {
          setCreditlifeId(data.data.createOpenMarketCreditLife.id);
          setClientId(data.data.createOpenMarketCreditLife.client.id);
          setAcceptedTerms(data.data.createOpenMarketCreditLife.client.acceptedTerms)
        }}
      />
    )
  }
  else {
    return (<WaitForApproval id={clientId} creditlifeId={creditlifeId} acceptedTerms={acceptedTerms} history={history} />)
  }
}

export default OpenMarket