import React from 'react'
import DashboardCard from '../generic/DashboardCard'
import SalesInteractionItem from './SalesInteractionItem'

// TODO: Use card component
const SalesInteractionFeed = ({ salesInteractions, limit = 10, size, onSalesPersonPress, onLocationPress, onPlaybackPress }) => {
  let salesFeed
  let showSalesInteractions = salesInteractions ? salesInteractions.slice(0).sort((a, b) => (b.timestamp - a.timestamp)).slice(0, limit) : []
  if (showSalesInteractions.length === 0) {
    salesFeed = <span>None</span>
  } else {
    salesFeed = showSalesInteractions.map(salesInteraction => <SalesInteractionItem key={salesInteraction.id} salesInteraction={salesInteraction} onSalesPersonPress={onSalesPersonPress} onLocationPress={onLocationPress} onPlaybackPress={onPlaybackPress} />)
  }

  return (
    <DashboardCard size={size || 6} title={'Recent Sales Interactions'}>
      {salesFeed}
    </DashboardCard>
  )
}

export default SalesInteractionFeed
