import { createStore } from 'redux'
import reducer from '../reducers'
import { isStaging } from '../config'


function configureStore(initialState) {
  const windowGlobal = typeof window !== 'undefined' && window

  const devtools =
    isStaging && windowGlobal.devToolsExtension
      ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f;

  return createStore(reducer, initialState, devtools)
}

const store = (initialState = {}) => configureStore(initialState)

export default store


// import { createStore, applyMiddleware } from 'redux'
// import { flowRight as compose } from 'lodash';
// import thunkMiddleware from 'redux-thunk'
// import reducer from '../reducers'

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// function configureStore(initialState) {

//   let wares = [thunkMiddleware];
//   const enhancer = composeEnhancers(
//     applyMiddleware(...wares)
//   );
//   return createStore(reducer, initialState, enhancer)
// }

// const store = (initialState = {}) => configureStore(initialState)

// export default store