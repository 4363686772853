import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { sidebarOptions } from '../Sidebar';
import { ActivityIndicator, Button, FormModal, Card, Grid } from '../generic'
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import UserGroupEdit from './UserGroupEdit'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		// justifyContent: 'space-evenly',
		paddingBottom: 7
	},
	footer: {
		flexGrow: 1,
		justifyContent: 'flex-end',
		paddingBottom: 21
	},
	paper: {
		minWidth: 300,
		maxWidth: '60%',
	},
	button: {
		minWidth: 100,
		maxWidth: 100,
		textAlign: 'center',
		marginRight: 14
	}
}));

const USERGROUP = gql`
	query Usergroup($id:String!) {
		usergroup(id:$id) {
			id
    	name
			description
    	identifier
    	users {
				email
				username
				description
				}
  		}
	}`;

const EditModal = (props) => {
	const {size} = props
	return (
	  <FormModal
		okText="Ok"
		cancelText="Back"
			width='95%'
		disableCancel={true}
		disableOk={true}
		size={size}
		componentProps={props}
	  />
	  )
	}

function UserGroup(props) {
	const classes = useStyles();

	const { id, users, data, client, history } = props
	let { cancel } = props
	const [userMenuOptions, setUserMenuOptions] = useState()
	const {loading, usergroup} = data
	const ids = users && users.map(u => u.id) || []
	
	if (!cancel && history) { cancel = () => history.history.push('/usergroups')}

	const menuItem = (identifier) => {
		const menuItems = sidebarOptions && sidebarOptions.filter(u => u.key === identifier)
		const result = (Array.isArray(menuItems) && menuItems.length && menuItems[0] != [] ? 'True' : 'False' )
		return result
	  }
	
	
	if (loading) return <ActivityIndicator />


	return ( 
		<Card>
			<h2>Group Permissions</h2>
			<Grid className={classes.root}>

				<Card>
					<Grid container >
						<Grid item xs={4}>{usergroup && usergroup.identifier && menuItem(usergroup.identifier) ? "MENU OPTION" : "SYSTEM FUNCTION"} {usergroup ? usergroup.name : '-'}</Grid>
						<Grid item xs={8}>{usergroup && usergroup.description}</Grid>
					</Grid>
				</Card>

				<br />
				<div style={{ marginLeft: 14 }}>
					<Grid container >
						<Grid item>THE FOLLOWING USERS HAVE AUTHORITY</Grid>
					</Grid>
					<br />
					{usergroup && usergroup.users && usergroup.users.map(user => {
						if (user) return (
							<div style={{ marginLeft: 14 }} key={user.identifier} justify="left" gutter={[8, 8]} >
								{user && user.description ? user.description : '-'}
							</div>
						)
						else return <div />
					})}
					<br />
				</div>
			</Grid>

			<Grid container className={classes.footer}>
				<Grid item className={classes.button}>
					<Button fullWidth variant='contained' color='secondary' onClick={() => { setUserMenuOptions(null); cancel() }}>Back</Button>
				</Grid>
				<Grid item className={classes.button}>
					<EditModal Content={UserGroupEdit} title={"Edit User Group"} text="Change" mode='edit' id={id} afterSubmit={cancel} />
				</Grid>
			</Grid>

		</Card>
		);
}

UserGroup = withApollo(UserGroup);
UserGroup = graphql(USERGROUP, { options: ({ match, id }) =>  ({ variables: { id: match && match.params ? match.params.id : id }}) })(UserGroup);
export default UserGroup