import React, { Fragment, useState, useEffect } from 'react';
import { useConfirm } from "material-ui-confirm";
import { makeStyles } from '@material-ui/core/styles';
import { Card, Button, FormModal, ActivityIndicator, DisplayItem, Grid } from '../generic'
import { withApollo, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import Numeral, { nullFormat } from 'numeral'
import 'numeral/locales/en-za.js'
import { AuthConsumer } from 'react-auth-helper';

// switch between locales
Numeral.locale('en-za')

const useStyles = makeStyles((theme) => ({
  footer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    padding: 21,
  },
}));


const USERS = gql`
	query Users($filter: UserFilter!) {
  users(filter:$filter)  {
    id
    email
    mobileNumber
    authProvider 
    lastLogin
    userType
    roles
    forClient {
      id
      idNumber
      firstNames
      surname
    }
  }
}`


const UPDATE_USER = gql`
  mutation UpdateUser($id: String!, $input: UpdateUserInput!, ) {
    updateUser(id: $id, input: $input) {
    id
    forClientId
  }
}
`

export const EditModal = (props) => {
  console.log(' EditModal called with props', props)
  return (
    <FormModal
      okText="Ok"
      cancelText="Back"
      width='99%'
      disableCancel={true}
      disableOk={true}
      componentProps={props}
      style={{ top: 10 }}
      small
    />
  )
}


const getUsers = (client, clientId, setUsers) => {
  client
    .query({ query: USERS, variables: { filter: { forClientId: clientId } } })
    .then(data => {
      console.log('TOTALCOVER returned the following ', data.data.users)
      if (data.data.users) {
        setUsers(data.data.users)
      }
    })
}

let RegisteredUser = ({ client, data, onCancel }) => {
  const classes = useStyles();

  const confirm = useConfirm();
  const [users, setUsers] = useState()

  useEffect(() => {
    setUsers(data.users)
  }, [data]);

  console.log('Starting registered-users')


  const confirmRemoveUser = (id) => {
    confirm({
      description: 'Are you sure, this will force the client to re-register?'
    })
      .then(() => updateUser(id))
  }

  const updateUser = (id) => {
    console.log('Update User', id)

    client.mutate({
      mutation: UPDATE_USER,
      variables: {
        id,
        input: { forClientId: null }
      }
    })
      .then(() => {
        console.log('remove user from array ')
        setUsers(users.filter(u => u.id !== id))
        onCancel()
      })
  }

  if (data.loading) {
    return <ActivityIndicator center={false} />
  }

  return (
    <Card> 
      <AuthConsumer>
        {({ inGroup }) => {
          if (inGroup('registered')) {
            return (
              <Fragment>
                {users && users.length > 1 ?
                  users.map(u => {
                    return (<>
                      <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
                        <DisplayItem xs={3} label="Registered Email" value={u.email} />
                        <DisplayItem xs={2} label="Name" value={u.forClient ? `${u.forClient.firstNames} ${u.forClient.firstNames}` : ' '} />
                        <DisplayItem xs={2} label="Registered Mobile" value={u.mobileNumber} />
                        <DisplayItem xs={2} label="Authority" value={u.authProvider} />
                        <DisplayItem xs={2} label="Roles" value={u.roles ? u.roles.toString() : ' '} />
                      </Grid>
                      <Grid container alignItems='center' spacing={2} className={classes.footer}>
                        <Grid item xs={1}><Button color="secondary" onClick={onCancel}>Cancel</Button></Grid>
                        <Grid item xs={1}><Button color="primary" onClick={() => confirmRemoveUser(u.id)}>Remove</Button></Grid>
                      </Grid>
                    </>
                    )
                  })
                  : users && users.length === 1 ?
                    <>
                      <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
                        <DisplayItem xs={3} label="Registered Email" value={users[0].email} />
                        <DisplayItem xs={2} label="Name" value={users[0].forClient ? `${users[0].forClient.firstNames} ${users[0].forClient.firstNames}` : ' '} />
                        <DisplayItem xs={2} label="Registered Mobile" value={users[0].mobileNumber} />
                        <DisplayItem xs={2} label="Authority" value={users[0].authProvider} />
                        <DisplayItem xs={2} label="Roles" value={users[0].roles ? users[0].roles.toString() : ' '} />
                      </Grid>
                      <Grid container className={classes.footer} alignItems='center' spacing={2}>
                        <Grid item xs={1}><Button color="secondary" onClick={onCancel}>Cancel</Button></Grid>
                        <Grid item xs={1}><Button color="primary" onClick={() => confirmRemoveUser(users[0].id)}>Remove</Button></Grid>
                      </Grid>
                    </>
                    : <h2>Client is not a registered user</h2>
                }
              </Fragment>)
          }
          else {
            return <center><br /><br /><br /><br /><h1>Checking authority - Refresh or ask for authority if nothing happens</h1><br /><br /></center>
          }
        }}
      </AuthConsumer>
    </Card>
  );
}

RegisteredUser = withApollo(RegisteredUser);
RegisteredUser = graphql(USERS, {
  options: ({ id }) => ({ variables: { filter: { forClientId: id } } })
})(RegisteredUser);

export default RegisteredUser;
