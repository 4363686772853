import React, { useState, useEffect } from 'react';
import fileDownload from 'js-file-download'
import Axios from 'axios';
import { Button } from '../'
import { tiff } from '../../../assets/images'

function TiffViewer({ file, name }) {
  const [img, setImg] = useState();
  const [blob, setBlob] = useState();

  useEffect(() => {
    if (file) {
      fetchUrl(file);
    }
  }, [file]);

  function download(filename) {
    fileDownload(blob, filename);
  }

  function fetchUrl(file) {
    Axios.get(file, {
      responseType: 'blob',
    }).then(res => {
      setBlob(res.data);
    });
  }
  return (
    <center>
      <img src={img ? img : tiff} width={75} alt="loading" />
      &nbsp;&nbsp;
      <Button disabled={!blob} id="myLink"
        onClick={() => download(`${name ? name : 'document.tiff'}`)}>Download to Save</Button>
    </center>
  );
}

export default TiffViewer
