import React from 'react'
import CreditorsList from './CreditorsList'
import Creditor from './Creditor'

import { Route, Switch } from 'react-router-dom'

const Creditors = () => (
  <div>
    <Switch>
      <Route path='/creditors' exact component={CreditorsList} />
      <Route path='/creditors/new' exact render={({ history }) => <Creditor history={history} mode={'add'} />} />
      <Route path='/creditors/:id/:edit?' render={({ match, history }) => <div><Creditor history={history} mode={match.params.edit ? 'edit' : 'display'} id={match.params.id} /></div>} />
    </Switch>
  </div>
)

export default Creditors
