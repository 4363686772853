const ClaimBankAccountType = [
  "CREDITOR",
  "CLIENT_OBLIGATION_REFERENCE"
]

const ClaimPaymentReferenceType = [
  "ID_NUMBER",
  "REFERENCE",
  "SUB_REFERENCE",
  "CLAIM_NUMBER",
  "POLICY_NUMBER",
  "CUSTOM"
]

const LoanType = [
  "PERSONAL_LOAN",
  "STUDENT_LOAN",
  "REVOLVING_LOAN",
  "CREDIT_CARD",
  "OVERDRAFT",
  "AFFORDABLE_HOUSING",
  "VEHICLE_LOAN",
  "HOME_LOAN",
]

const LoanPackage = [
  { value: "PLAN_A", label: "PLAN A" },
  { value: "PLAN_B", label: "PLAN B" },
  { value: "PLAN_C", label: "PLAN C" },
  { value: "PLAN_D", label: "PLAN D" },
  { value: "REJECTED", label: "REJECTED" }
]

export { ClaimBankAccountType, ClaimPaymentReferenceType, LoanType, LoanPackage }