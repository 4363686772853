import React from 'react';
import gql from 'graphql-tag';
import GraphQLTable from '../generic/GraphQLTable';
import { Card } from '../generic';
import { printCurrency } from '../../lib/helpers';
import moment from 'moment';

const config = {
  query: gql`
    {
      paymentpendings(limit: 10000) {
      creditLife {
        policyNumber
        policyStatus
        client {
          idNumber
          firstNames
          surname
        }
      }
      paymentType
      paymentFor
      amount
      referenceNumber
      paymentCreatedBy {
        forEmployee {
          first
          last
        }
        username
      }
      createdAt
      }
    }
  `,
  dataset: data => data.paymentpendings,
  fields: {
    'creditLife.policyNumber': { label: 'Policy', width: 30 },
    'creditLife.policyStatus': { label: 'Status', width: 50 },
    'creditLife.client.idNumber': { label: 'ID Number', width: 50 },
    clientName: {
      label: 'Name',
      width: 90,
      accessor: row =>
        row.creditLife.client ? `${row.creditLife.client.firstNames} ${row.creditLife.client.surname}` : null
    },
    paymentType: { label: 'Type', width: 50, align: 'center' },
    paymentFor: {
      label: 'For',
      width: 50
    },
    amount: {
      label: 'Amount',
      width: 35,
      align: 'right',
      Cell: ({ value }) => (value ? printCurrency(value) : '-')
    },
    CreatedBy: { label: 'Created', width: 90,
    accessor: row => row.paymentCreatedBy.forEmployee ? `${row.paymentCreatedBy.forEmployee.first} ${row.paymentCreatedBy.forEmployee.last}` : row.paymentCreatedBy.username ? row.paymentCreatedBy.username :null },
    created: {
      label: 'Created',
      width: 50,
      align: 'center',
      accessor: row => moment(row.updatedAt).format('DD-MM-YYYY')
    },
    createdAt: { label: 'Relative', width: 50, Cell: row => moment(row.value).fromNow() }
  },
  defaultSorted: [{ id: 'createdAt', desc: true }]
}

/*
 const handleRowPress = (history, { id, policyStatus }) => {
  history.push(`/credit-life/${id}`);
};

<GraphQLTable config={config} onRowPress={creditLife => handleRowPress(history, creditLife)} />

*/

const PaymentHistory = ({ history }) => (
  <Card>
    <div className="content">
      <h1>Payments for Nedbank</h1>

      <GraphQLTable config={config}  />

    </div>
  </Card>
);


export default PaymentHistory;
