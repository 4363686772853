import React from 'react';
import UserGroup from './UserGroup';
import UserGroupList from './UserGroupList';
import { Route, Switch } from 'react-router-dom';

const UserGroups = (history) => (
  <div>
    <Route path='/usergroups' exact component={UserGroupList} />
    <Switch>
      <Route
        path='/usergroups/new'
        exact
        render={ () =>
          <UserGroup mode="add" history={history}/>
        }
      />
      <Route
        path='/usergroups/:id/:edit?' render={ ({ match }) => <UserGroup mode={"edit"} id={match.params.id} history={history} />
        }
      />
    </Switch>
  </div>
)

export default UserGroups