import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment'
import { Button } from '../generic';

export default function GraceDaysUsed(props) {
  const { graceDaysUsedAt, onClose, open } = props;

  return (
    <Dialog onClose={() => onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Grace Days Usage</DialogTitle>
      <List>
        {graceDaysUsedAt && graceDaysUsedAt.map((day, index) => (
          <ListItem key={day}>
            <ListItemText primary={index + 1} />
            <ListItemText primary={day ? moment(day).format('DD-MM-YYYY') : "-"} />
          </ListItem>
        ))}
      </List>
      <Button onClick={() => onClose()} color="primary" autoFocus><span>Close</span></Button>
    </Dialog>
  );
}

GraceDaysUsed.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
